<!-- price-lists.component.html -->
<div class="dialog-container">
    <div class="sticky-container" style="margin-bottom: 20px; margin-top: 10px">

            <categorieCard class="col-12 col-md-12 menuItem" style="width: 16%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                           [nome]="'Aggiungi Listino'"
                           altezza="55"
                           [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7' "
                           [bg]="'#33bfa3'"
                           (click)="startEditing()">
            </categorieCard>


    </div>

    <div class="content-container">
        <!-- Lista dei listini esistenti -->
        <div class="menus-list" *ngIf="!editingList">
            <fieldset class="custom-fieldset" *ngFor="let list of priceLists">
                <legend>Listino: {{list.name}}</legend>
                <div class="menu-item">
                    <div class="price-list-info">
                        <div class="settings-grid">
                            <div class="setting-item">
                                <label>IVA Counter: {{list.settings.counter.vatId === '1' ? '22%' : '10%'}}</label>
                            </div>
                            <div class="setting-item">
                                <label>IVA Table: {{list.settings.table.vatId === '1' ? '22%' : '10%'}}</label>
                            </div>
                            <div class="setting-item">
                                <label>IVA Takeaway: {{list.settings.takeaway.vatId === '1' ? '22%' : '10%'}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="price-list-actions">
                        <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                             style="width: 30px; aspect-ratio: 1 / 1;"
                             title="Modifica Listino"
                             (click)="startEditing(list)">
                        <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                             style="width: 30px; aspect-ratio: 1 / 1;"
                             title="Elimina Listino"
                             (click)="deletePriceList(list.id)">
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- Form modifica/creazione listino -->
        <div *ngIf="editingList">
            <fieldset class="custom-fieldset">
                <legend>{{editingList.id ? 'Modifica' : 'Nuovo'}} Listino</legend>
                <div class="chart-container">
                    <input placeholder="Nome Listino"
                           class="custom-input mb-3"
                           [(ngModel)]="editingList.name"
                           (ngModelChange)="onFieldChange()">

                    <div class="settings-grid">
                        <div class="setting-item">
                            <label>IVA Counter</label>
                            <mat-select class="custom-input"
                                        [(ngModel)]="editingList.settings.counter.vatId"
                                        (selectionChange)="onFieldChange()">
                                <mat-option value="1">22% IVA</mat-option>
                                <mat-option value="2">10% IVA</mat-option>
                            </mat-select>
                        </div>

                        <div class="setting-item">
                            <label>IVA Table</label>
                            <mat-select class="custom-input"
                                        [(ngModel)]="editingList.settings.table.vatId"
                                        (selectionChange)="onFieldChange()">
                                <mat-option value="1">22% IVA</mat-option>
                                <mat-option value="2">10% IVA</mat-option>
                            </mat-select>
                        </div>

                        <div class="setting-item">
                            <label>IVA Takeaway</label>
                            <mat-select class="custom-input"
                                        [(ngModel)]="editingList.settings.takeaway.vatId"
                                        (selectionChange)="onFieldChange()">
                                <mat-option value="1">22% IVA</mat-option>
                                <mat-option value="2">10% IVA</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="buttons-container mt-3">
                        <button type="button"
                                class="save-button"
                                (click)="savePriceList()"
                                [disabled]="!editingList.name">
                            Salva Listino
                        </button>
                        <button type="button"
                                class="cancel-button"
                                (click)="cancelEdit()">
                            Annulla
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>