import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { TavoliService } from '../../../shared/services/tavoli.service';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { TavoloDialogComponent } from "./edit-tavolo/tavolo-dialog.component";

@Component({
  selector: 'tavoli',
  templateUrl: './tavoli.html',
  styleUrls: ['./tavoli.css']
})
export class Tavoli implements OnInit {
  tavoli: Array<any> = [];
  unisciTavoli = false;
  isDragging = false;
  private dragStartTime: number = 0;

  constructor(
      public dialog: MatDialog,
      private tavoliService: TavoliService,
      private dataService: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    if(this.dataService.getRistorante1() == 'demo_1') {
      this.dataService.loadSelectedMenuFromFirebase().then((ristorante) => {
        this.tavoliService.getTavoli(this.dataService.getRistorante1()).subscribe((tavoli) => {
          this.tavoli = tavoli;
        });
      })
    } else {
      this.tavoliService.getTavoli(this.dataService.getRistorante1()).subscribe((tavoli) => {
        this.tavoli = tavoli;
      });
    }
  }

  aggiungiTavolo(): void {
    const dialogRef = this.dialog.open(TavoloDialogComponent, {
      width: '250px',
      data: { numero: null, coperti: null, isEdit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.delete) {
        // Aggiungi il nuovo tavolo all'array locale
        this.tavoli.push({
          posizione: { x: 100, y: 100 },  // Posizione di default
          numero: result.numero,
          coperti: result.coperti,
          occupato: 0
        });

        // Aggiorna Firebase con l'intero array
        this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1()).then(() => {
          console.log("Tavoli aggiornati su Firebase");
        });
      }
    });
  }

  uniscitavoli() {
    this.unisciTavoli = !this.unisciTavoli;
    if (!this.unisciTavoli) { // Se stiamo confermando l'unione
      let sum = 0;
      let indice = 0;
      let indici: number[] = [];

      this.tavoli.forEach((tavolo, i) => {
        if (tavolo.unito === true) {
          if (sum === 0) {
            indice = i;
            sum = sum + tavolo.coperti;
            tavolo.unito = false;
          } else {
            sum = sum + tavolo.coperti;
            indici.push(i);
          }
        }
      });

      if (sum > 0) {
        this.tavoli[indice].coperti = sum;
      }

      // Rimuovi i tavoli uniti
      for (let i = indici.length - 1; i >= 0; i--) {
        this.tavoli.splice(indici[i], 1);
      }

      // Aggiorna Firebase
      this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1()).then(() => {
        console.log("Tavoli uniti aggiornati su Firebase");
      });
    }
  }

  onDragStarted(event: CdkDragStart): void {
    this.isDragging = true;
    this.dragStartTime = Date.now();
  }

  dropped(event: CdkDragEnd, tavolo: any): void {
    const dragEndTime = Date.now();
    const dragDuration = dragEndTime - this.dragStartTime;

    // Update position only if actually dragged
    if (dragDuration > 100) {
      const newPosition = event.source.getFreeDragPosition();
      tavolo.posizione = newPosition;

      this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1()).then(() => {
        console.log("Posizione aggiornata su Firebase");
      });
    }

    // Reset dragging state after a short delay
    setTimeout(() => {
      this.isDragging = false;
    }, 100);
  }

  handleTableClick(tavolo: any): void {
    if (!this.isDragging) {
      if (this.unisciTavoli) {
        tavolo.unito = !tavolo.unito;
      } else {
        this.modificaTavolo(tavolo);
      }
    }
  }

  modificaTavolo(tavolo: any): void {
    const dialogRef = this.dialog.open(TavoloDialogComponent, {
      width: '250px',
      data: {
        numero: tavolo.numero,
        coperti: tavolo.coperti,
        isEdit: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          // Elimina il tavolo dall'array
          this.tavoli = this.tavoli.filter(t => t !== tavolo);
        } else {
          // Aggiorna i dati del tavolo
          tavolo.numero = result.numero;
          tavolo.coperti = result.coperti;
        }

        // Aggiorna Firebase con l'intero array
        this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1()).then(() => {
          console.log("Tavoli aggiornati su Firebase");
        });
      }
    });
  }
}