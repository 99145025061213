import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'categorieCard2',
  styles: [`


    .block {
      width: 100%;
      height: 65px;
      margin: 0px;
      margin-bottom: 2px;
      display: inline-flex;
      background: rgba(215, 215, 215, 0.1);
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 10px;
      border: solid 1px #e3e2e2;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      -webkit-box-shadow: 0px 4px 4px RGBA(220, 220, 220, 0.5);
      -o-box-shadow: 0px 1px 6px RGBA(181, 181, 181, 0.3);

       */


    }

    #store-locator {
      color: black;
    }

    .store {
      position: relative;
      background-size: 10% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
    }

    .details-column {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      width: 50%;
    }

    .title {
      font-weight: bold;
      color: inherit;
    }

    .additions {
      color: green;
      font-size: 12px;
      font-weight: normal;
    }

    .removals {
      color: red;
      font-size: 12px;
      font-weight: normal;
    }

    .note {
      color: blue;
      font-size: 12px;
      font-weight: normal;
      font-style: italic;
    }

    .variants {
      color: rgb(229, 89, 51);
      font-size: 12px;
      font-weight: normal;
    }

    

  `],
  template: `

    <div id="store-locator" class="block" [ngStyle]="{
      'border': border !== undefined ? 'solid 1px ' + border : null, 
      'background': selezionato > 0 && selezionato !== undefined ? 'rgba(52, 191, 163, 0.16)' : 'rgba(215, 215, 215, 0.1)'
    }">
      <div *ngIf="!isSingoloSelected" style="margin-right: 5px; margin-left: 10px; text-align: center">
        {{quantita}}x
      </div>
      <div *ngIf="isSingoloSelected" style="margin-right: 5px; margin-left: 10px; text-align: center">
        1x
      </div>

      <img class="store" style="background-size: 5% auto; transform: scale(0.7)"
           [src]="this.link"
           onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';">

      <div class="details-column">
        <div class="title" [ngStyle]="{'color': border !== undefined ? border : null}">
          {{this.nome}}
        </div>

        <div *ngIf="aggiunte && aggiunte.length > 0" class="additions">
          Aggiunte: {{aggiunte.join(', ')}}
        </div>

        <div *ngIf="rimozioni && rimozioni.length > 0" class="removals">
          Rimozioni: {{rimozioni.join(', ')}}
        </div>

        <div *ngIf="nota" class="note">
          Nota: {{nota}}
        </div>

        <div *ngIf="variants && variants.length > 0">
          <div *ngFor="let variant of variants" class="variants">
            {{variant.variantTitle}}: {{variant.name}} {{variant.priceModifier > 0 ? ' + ' + variant.priceModifier.toFixed(2) + '€' : ''}}
          </div>
        </div>
      </div>
      <div style="margin-right: 5px; text-align: center; display: flex; justify-content: flex-end">
        {{costo.toFixed(2)}}€
      </div>
    </div>
  `
})
export class CategorieCard2 {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() stato ;
  @Input() border ;
  @Input() quantita ;
  @Input() selezionato;
  @Input() aggiunte: string[];
  @Input() rimozioni: string[];
  @Input() nota: string;
  @Input() isSingoloSelected: boolean | false;
  @Input() variants: any[];


  @Output()
  quantitaOut = new EventEmitter<number>();


  aggiungiProdotto() {
    this.quantita = this.quantita + 1;
    this.quantitaOut.emit(this.quantita);
  }

  rimuoviProdotto() {
    if(this.quantita > 0)
    {
      this.quantita = this.quantita - 1
      this.quantitaOut.emit(this.quantita);
    }
  }


  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
