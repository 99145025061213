import {Component, Input} from "@angular/core";

@Component({
    selector: 'order-detail-popup',
    template: `
    <div class="popup-overlay">
      <div class="popup-content">
        <div class="popup-header">
          <h2>Ordine #{{order.idOrdine}}</h2>
          <div class="status-tag" [ngClass]="{
            'status-confirmed': order.stato === 'confermato',
            'status-partial': order.stato === 'confermato_parziale',
            'status-processing': order.scheduledTime !== null,
            'status-unpaid': !order.stato && !order.scheduledTime
          }">
            {{getOrderStatus(order)}}
          </div>
        </div>

        <div class="order-info-section">
          <div class="info-row">
            <span class="info-label">Ristorante:</span>
            <span class="info-value">{{order.ristorante}}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Data:</span>
            <span class="info-value">{{order.data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</span>
          </div>
        </div>

        <div class="products-section">
          <h3>Prodotti Ordinati</h3>
          <div class="product-list">
            <div class="product-item" *ngFor="let item of order.carrelloMagazzino">
              <div class="product-quantity">
                {{item.Quantita}}x {{item.UM}}
              </div>
              <div class="product-details">
                <div class="product-name">{{item.Titolo}}</div>
                <div class="product-supplier">{{item.Fornitore}} - {{item.Categoria}}</div>
                <div class="product-info">
                  <span class="product-code" *ngIf="item.CodiceFornitore">Cod: {{item.CodiceFornitore}}</span>
                  <span class="expiry-date" *ngIf="item.Scadenza">Scad: {{item.Scadenza}}</span>
                </div>
              </div>
              <div class="product-price">{{(parseFloat(item.Prezzo)).toFixed(2)}} €</div>
            </div>
          </div>
        </div>

        <div class="total-section">
          <span class="total-label">Totale Ordine</span>
          <span class="total-amount">{{calcolaTotaleOrdine(order)}} €</span>
        </div>
      </div>
    </div>
  `,
    styles: [`
    .popup-overlay {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 0 4px 20px rgba(0,0,0,0.15);
      z-index: 1000;
      width: 90%;
      max-width: 600px;
      max-height: 90vh;
      overflow-y: auto;
    }

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #eee;
    }

    .popup-header h2 {
      margin: 0;
      font-size: 1.5rem;
      color: #333;
    }

    .order-info-section {
      margin-bottom: 20px;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .info-label {
      color: #666;
      font-size: 14px;
    }

    .info-value {
      font-weight: 500;
      color: #333;
    }

    .products-section {
      margin-bottom: 20px;
    }

    .products-section h3 {
      margin: 0 0 15px 0;
      font-size: 1.1rem;
      color: #333;
    }

    .product-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .product-item {
      display: flex;
      align-items: flex-start;
      padding: 12px;
      background: #f8f9fa;
      border-radius: 8px;
    }

    .product-quantity {
      background: #e9ecef;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 14px;
      color: #495057;
      min-width: 80px;
      text-align: center;
    }

    .product-details {
      flex: 1;
      margin: 0 15px;
    }

    .product-name {
      font-weight: 500;
      color: #333;
    }

    .product-supplier {
      font-size: 12px;
      color: #666;
      margin-top: 2px;
    }

    .product-info {
      display: flex;
      gap: 15px;
      margin-top: 4px;
      font-size: 12px;
      color: #666;
    }

    .product-price {
      font-weight: 500;
      color: #333;
      min-width: 80px;
      text-align: right;
    }

    .total-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid #eee;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .total-amount {
      color: #E55933;
    }
  `]
})
export class OrderDetailPopupComponent {
    @Input() order: any;

    getOrderStatus(order: any): string {
        if (order.stato === 'confermato') {
            return 'Confermato';
        } else if (order.stato === 'confermato_parziale') {
            return 'Confermato Parzialmente';
        } else if (order.scheduledTime !== null) {
            return 'In preparazione';
        } else {
            return 'In attesa di conferma';
        }
    }

    calcolaTotaleOrdine(order: any): string {
        if (!order || !order.carrelloMagazzino) return "0.00";
        const totale = order.carrelloMagazzino.reduce((acc: number, item: any) => {
            const prezzo = parseFloat(item.Prezzo) || 0;
            return acc + prezzo;
        }, 0);
        return totale.toFixed(2);
    }

    protected readonly parseFloat = parseFloat;
}