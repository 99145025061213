import { Component } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'navbar-impostazioni',
  styles: [`

    .menuItem{
      cursor: pointer !important;
    }

    .menuItem:hover {
      background-color: rgba(181,181,181,0.2) !important;
    }

    .sidebar {
      margin: 0;
      padding: 0;
      margin-top: 1%;
      margin-left: 1%;
      width: 220px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      height: 96%;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
      /*
      height: 100vh;
       */

    }

    .closed{
      width: 70px !important;
    }

    /* Sidebar links */
    .sidebar a {
      display: block;
      color: black;
      padding: 16px;
      text-decoration: none;
    }

    .sidebar b {
      display: block;
      color: #E65934;
      text-decoration: none;
      width: 220px;
      text-align: center;
      padding-top: 15%;
      padding-bottom: 30%;
      font-size: 30px;
    }

    /* Active/current link */
    .sidebar a.active {
      background-color: #FBFBFB;
      color: white;
    }

    /* Links on mouse-over */
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white;
    }

    /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
    div.content {
      margin-left: 200px;
      padding: 1px 16px;
      height: 1000px;
    }


    .bottom-elements {
      position: absolute;
      bottom: 0;
      margin-bottom: 7.5%;
      display: flex;
      width: 100%;
    }

    .block {
      width: calc(100% - 20px);
      height: 65px;
      margin: 2px 10px;
      display: inline-flex;
      border-radius: 20px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 580;
      color: #969696;

    //border-top: 1px solid #e3e2e2;


      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */


    }
    .cardSVG{
      margin-left: 10%;
      margin-bottom: 5px;
    }

    .selected {
      font-weight: 600;
      background-color: rgba(181,181,181,0.2) !important;
    //color: rgb(255, 102, 95);
      color: black;
    }

    .imgSelected{
    //filter: invert(66%) sepia(48%) saturate(1547%) hue-rotate(330deg) brightness(92%) contrast(88%);
      filter: brightness(0%);
    }

    .notSelected {
      color: #969696;
      fill: #969696;
    }

    /* On screens that are less than 700px wide, make the sidebar into a topbar */
    @media screen and (max-width: 700px) {
      .sidebar {
        width: 100%;
        height: auto;
        position: relative;
      }
      .sidebar a {float: left;}
      .sidebar b {
        float: left;
        width: 100%;
      }

      div.content {margin-left: 0;}
    }
    .reversed-a {
      display: inline-block;
      transform: rotate(180deg);
    }

    /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
    @media screen and (max-width: 400px) {
      .sidebar a {
        text-align: center;
        float: none;
      }
    }







  ` ],
  template: `
    <div class="sidebar shadow-sm" scroll="no" [ngClass]="{'closed': aperto === false}">


      <categorieCard class="col-12 col-md-12 " style="display: block; width: calc(100% - 20px); margin-bottom: 30%; margin-left: 10px; margin-top: 10px; cursor: pointer"
                     [nome]="'Home'"
                     [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-back-arrow-96.png?alt=media&token=dd7434c9-74f8-426a-8118-175603db884a'"
                     [border]="'#e55933'"
                     (click)="cambiaRoute('magazzino')"
      >

      </categorieCard>

      <!--div class="menuItem"  [ngClass]="{'selected': this.posizione === 0, 'block': true}" (click)="cambiaRoute('home')">
        <svg class="cardSVG" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
          <g id="Iconly_Bold_Home" data-name="Iconly/Bold/Home" transform="translate(-2.5 -2)">
            <g id="Home">
              <path [ngClass]="{'selected': this.posizione === 0}" id="Vector" d="M6.635,18.773V15.716A1.419,1.419,0,0,1,8.058,14.3h2.874a1.429,1.429,0,0,1,1.007.414,1.408,1.408,0,0,1,.417,1v3.058a1.213,1.213,0,0,0,.356.867,1.231,1.231,0,0,0,.871.36h1.961a3.46,3.46,0,0,0,2.443-1A3.41,3.41,0,0,0,19,16.578V7.867a2.473,2.473,0,0,0-.9-1.9L11.434.676A3.1,3.1,0,0,0,7.485.747L.967,5.965A2.474,2.474,0,0,0,0,7.867v8.7A3.444,3.444,0,0,0,3.456,20H5.372a1.231,1.231,0,0,0,1.236-1.218Z" transform="translate(2.5 2)" fill="#969696"/>
            </g>
          </g>
        </svg>

        <div style="padding-left: 25px">Home</div>
      </div-->

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 0, 'block': true}" (click)="cambiaRoute('impostazioni')">
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 0}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-analyze-90.png?alt=media&token=9c297611-ad39-4f77-8109-8b98afd7b322" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


        <div style="padding-left: 25px">Account</div>
      </div>


      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 1, 'block': true}" (click)="cambiaRoute('tavoli')">
        <img [ngClass]="{'imgSelected': this.posizione === 1}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-analyze-90.png?alt=media&token=9c297611-ad39-4f77-8109-8b98afd7b322" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">

        <div style="padding-left: 25px">Tavoli</div>
      </div>


      <div  class="menuItem"  [ngClass]="{'selected': this.posizione === 2, 'block': true}" (click)="cambiaRoute('impostazioni-costi')">
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 2}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-cost-90.png?alt=media&token=cc570195-548e-4f78-8e2f-e03c5b67bb92" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Costi</div>
      </div>

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 3, 'block': true}" (click)="cambiaRoute('permessi')">

        <img [ngClass]="{'imgSelected': this.posizione === 3}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-trolley-90.png?alt=media&token=d4c46b80-9b04-4f10-92e1-27f8e55dea7d" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


        <div style="padding-left: 25px">Permessi</div>
      </div>

      <!--  #nonav   -->
      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 4, 'block': true}" (click)="cambiaRoute('impostazioni-cassa')" >
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 4}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-data-90.png?alt=media&token=eee807ba-c6bc-45f2-be7d-adfee6cf08ba" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Cassa</div>
      </div>

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 5, 'block': true}" (click)="cambiaRoute('impostazioni-totem')" >
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 5}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-data-90.png?alt=media&token=eee807ba-c6bc-45f2-be7d-adfee6cf08ba" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Totem</div>
      </div>


    </div>
  `
})
export class NavbarImpostazioniComponent {

  aperto = true;

  posizione: any;

  private readonly canGoBack: boolean;

  constructor(
    // public authService: AuthService,
    private router: Router,
    private _location: Location,
    private  route: ActivatedRoute,

  ) {

    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);

    router.events.subscribe((val) => {
      // see also
      if ('url' in val)
      {
        //console.log("hdhdhdhdhdhdhhdhd", val.url);
        if (val.url.includes('impostazioni'))
        {
          this.posizione = 0;
          //console.log('home');
        }
        if (val.url.includes('tavoli'))
        {
          this.posizione = 1;
          //console.log('home');
        }
        if (val.url.includes('foodcost'))
        {
          this.posizione = 2;
          //console.log('home');
        }
        if (val.url.includes('operativita'))
        {
          this.posizione = 3;
          //console.log('home');
        }
        if (val.url.includes('incassi'))
        {
          this.posizione = 4;
          //console.log('home');
        }
      }
    });

  }

  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout() {
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }

  back()
  {
      this._location.back();
  }

  cambiaRoute(route): void {

    this.router.navigateByUrl(route);

  }
}
