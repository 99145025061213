<div class="settings-container">
    <div class="settings-header">
        <h2>Modifica Prodotto</h2>
        <div class="header-actions">
            <button class="icon-button delete" *ngIf="item?.id" (click)="deleteItem()" title="Elimina" style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                elimina
            </button>
            <button class="icon-button save" (click)="saveChanges()" title="Salva" style="background-color: rgb(52 152 219); border: 1px solid rgb(52 152 219); color: white">
                salva
            </button>
        </div>
    </div>

    <div class="settings-content">
        <div class="settings-section">
            <div class="section-header">
                <h3>Dettagli Prodotto</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Nome Prodotto</div>
                <div class="setting-field">
                    <input [(ngModel)]="title" placeholder="Nome prodotto" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Prezzo <p style="color: red">(Deprecato usare LISTINI)</p></div>
                <div class="setting-field">
                    <input [(ngModel)]="price" placeholder="0.00" class="custom-input">
                </div>
            </div>
            <div class="settings-row">
                <div class="setting-label">IVA/Reparto <p style="color: red">(Deprecato usare LISTINI)</p></div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="reparto" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>
            </div>
            <div class="settings-row">
                <div class="setting-label">Categoria</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedCategory" class="custom-input">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Immagine</div>
                <div class="setting-field image-upload">
                    <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Image" class="preview-image">
                    <div class="upload-actions">
                        <button class="action-button" *ngIf="!selectedImage" (click)="fileInput.click()">
                            Scegli immagine
                        </button>
                        <button class="elimina-button" *ngIf="selectedImage" (click)="removeImage()">
                            Elimina immagine
                        </button>
                        <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none">
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Ingredienti</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedIngredients" multiple class="custom-input">
                        <mat-option *ngFor="let ingredient of ingredients" [value]="ingredient">
                            {{ingredient}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Allergeni</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedAllergens" multiple class="custom-input">
                        <mat-option *ngFor="let allergen of allergens" [value]="allergen">
                            {{allergen}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Varianti</h3>
            </div>
            <div class="variants-container">
                <mat-select [(ngModel)]="selectedVariantIds" multiple class="custom-input">
                    <mat-option *ngFor="let variant of variants" [value]="variant.id">
                        {{variant.name}}
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <!-- Aggiungi dopo la sezione delle varianti esistente -->
        <div class="settings-row" style="margin-top: 16px">
            <div class="setting-field">
                <button class="action-button" (click)="addCustomVariant()" *ngIf="!showNewVariantForm">
                    + Aggiungi Nuova Variante
                </button>
            </div>
        </div>

        <!-- Form per nuova variante -->
        <div class="variant-form" *ngIf="showNewVariantForm">
            <div class="settings-row">
                <div class="setting-label">Nome Variante</div>
                <div class="setting-field">
                    <input [(ngModel)]="newVariant.name" placeholder="Nome variante" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Prezzo Base</div>
                <div class="setting-field">
                    <input type="number" [(ngModel)]="newVariant.price" step="0.10" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Obbligatoria</div>
                <div class="setting-field">
                    <div class="checkbox-wrapper-26">
                        <input type="checkbox" [id]="'checkbox-required'" [(ngModel)]="newVariant.isRequired">
                        <label [for]="'checkbox-required'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Massimo Selezionabili</div>
                <div class="setting-field">
                    <input type="number" [(ngModel)]="newVariant.maxSelections" min="1" class="custom-input">
                </div>
            </div>

            <!-- Opzioni della variante -->
            <div class="variant-options">
                <h4>Opzioni</h4>
                <div class="option-list">
                    <div class="option-item" *ngFor="let option of newVariant.options; let i = index">
                        <input [(ngModel)]="option.name" placeholder="Nome opzione" class="custom-input">
                        <input type="number" [(ngModel)]="option.priceModifier" step="0.10" placeholder="Modifica prezzo" class="custom-input">
                        <button class="elimina-button" (click)="removeOptionFromVariant(i)">
                            Rimuovi
                        </button>
                    </div>
                </div>
                <button class="action-button" (click)="addOptionToNewVariant()">
                    + Aggiungi Opzione
                </button>
            </div>

            <div class="button-group">
                <button class="action-button" (click)="saveNewVariant()">Salva Variante</button>
                <button class="elimina-button" (click)="cancelNewVariant()">Annulla</button>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Listino Prezzi</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Listino</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedPriceListId"
                                (selectionChange)="onPriceListChange($event)"
                                class="custom-input">
                        <mat-option *ngFor="let list of priceLists" [value]="list.id">
                            {{list.name}}
                        </mat-option>
                        <mat-option (click)="isCreatingNewList = true">
                            + Crea nuovo listino
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="price-grid" *ngIf="selectedPriceListId && !isCreatingNewList">
                <div class="price-type">
                    <label>Prezzo Counter</label>
                    <input type="number" [(ngModel)]="currentPrices.counter.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.counter.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>

                <div class="price-type">
                    <label>Prezzo Table</label>
                    <input type="number" [(ngModel)]="currentPrices.table.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.table.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>

                <div class="price-type">
                    <label>Prezzo Takeaway</label>
                    <input type="number" [(ngModel)]="currentPrices.takeaway.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.takeaway.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="new-price-list-form" *ngIf="isCreatingNewList">
            <div class="settings-row">
                <div class="setting-label">Nome Listino</div>
                <div class="setting-field">
                    <input [(ngModel)]="newList.name" placeholder="Nome listino" class="custom-input">
                </div>
            </div>

            <div class="settings-section">
                <h4>Impostazioni IVA</h4>
                <div class="settings-row">
                    <div class="setting-label">IVA Counter</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.counter.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">IVA Table</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.table.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">IVA Takeaway</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.takeaway.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="button-group">
                <button class="action-button" (click)="saveNewPriceList()">Salva Listino</button>
                <button class="elimina-button" (click)="cancelNewList()">Annulla</button>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Impostazioni</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Destinazione di stampa</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedPrintDestination" class="custom-input">
                        <mat-option value="cucina">Cucina</mat-option>
                        <mat-option value="bar">Bar</mat-option>
                        <mat-option value="pizzeria">Pizzeria</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="actions-container">
                <div class="action-item">
                    <span class="action-label">Menu</span>
                    <div class="checkbox-wrapper-26">
                        <input type="checkbox" [id]="'checkbox-menu'" [(ngModel)]="isMenuChecked">
                        <label [for]="'checkbox-menu'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>


                <div class="action-item">
                    <span class="action-label">Disponibile</span>
                    <div class="checkbox_item citem_1">
                        <label class="checkbox_wrap">
                            <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isDisponibileChecked">
                            <span class="checkbox_mark"></span>
                        </label>
                    </div>
                </div>
            </div>

            <!-- Sostituisci la sezione menu esistente con questa -->
            <div class="menu-config" *ngIf="isMenuChecked">
                <div class="settings-section">
                    <div class="section-header">
                        <h3>Configurazione Menu</h3>
                        <button class="action-button" (click)="addMenuSection()" *ngIf="menuConfig.sections.length < 2">
                            + Aggiungi Sezione
                        </button>
                    </div>

                    <div class="menu-sections">
                        <div class="menu-section" *ngFor="let section of menuConfig.sections; let i = index">
                            <div class="menu-section-content">
                                <div class="menu-section-row">
                                    <div class="menu-section-label">Nome Sezione</div>
                                    <div class="menu-section-field">
                                        <input [(ngModel)]="section.title"
                                               placeholder="Nome sezione"
                                               class="menu-input">
                                        <button class="menu-remove-button" (click)="removeMenuSection(i)">
                                            Rimuovi
                                        </button>
                                    </div>
                                </div>

                                <div class="menu-section-row">
                                    <div class="menu-section-label">Categorie Menu</div>
                                    <div class="menu-section-field">
                                        <mat-select [(ngModel)]="section.categories" multiple class="menu-select">
                                            <mat-option *ngFor="let category of categories" [value]="category">
                                                {{category}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="menu-section-row">
                                    <div class="menu-section-label">Prodotti Menu</div>
                                    <div class="menu-section-field">
                                        <mat-select [(ngModel)]="section.products" multiple class="menu-select">
                                            <mat-option *ngFor="let product of menuProducts" [value]="product.id">
                                                {{product.title}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Prezzi menu per listino -->
                    <div class="settings-section">
                        <div class="section-header">
                            <h3>Prezzi Menu</h3>
                        </div>

                        <div class="settings-row">
                            <div class="setting-label">Listino</div>
                            <div class="setting-field">
                                <mat-select [(ngModel)]="selectedMenuPriceList"
                                            (selectionChange)="onMenuPriceListChange($event)"
                                            class="custom-input">
                                    <mat-option *ngFor="let list of priceLists" [value]="list.id">
                                        {{list.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="price-grid" *ngIf="selectedMenuPriceList">
                            <div class="price-type">
                                <label>Prezzo Menu Counter</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].counter.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].counter.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>

                            <div class="price-type">
                                <label>Prezzo Menu Table</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].table.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].table.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>

                            <div class="price-type">
                                <label>Prezzo Menu Takeaway</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].takeaway.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].takeaway.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>