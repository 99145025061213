import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component allows dynamic background, border, height, and content management.
 * The image will be centered horizontally if 'nome' is empty.
 */
@Component({
  selector: 'categorieCard',
  styles: [`
    .block {
      width: 100%;
      margin: 0px;
      display: inline-flex;
      background: white;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;
      border-radius: 20px;
      border: solid 1px #e3e2e2;
      transition: 0.3s;
    }

    #store-locator {
      color: black;
    }

    .store {
      background-size: 10% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
    }

    .filtered-img {
      filter: brightness(0) invert(1);
    }

    .centered {
      position: relative;
      left: 5%;
      transform: translateX(-50%);
    }

    .offset {
      position: relative;
      margin-left: 10px;
    }
  `],
  template: `
    <div id="store-locator" class="block"
         [ngStyle]="{
        'border': bg !== undefined ? 'solid 1px ' + bg : (border !== undefined ? 'solid 1px ' + border : null),
        'background-color': bg !== undefined ? bg : null,
        'height': altezza !== undefined ? altezza+'px' : '65px'
      }">
      <img class="store"
           [ngClass]="{
          'filtered-img': bg !== undefined,
          'centered': nome === undefined || nome === '',
          'offset': nome !== undefined && nome !== ''
        }"
           style="background-size: 5% auto; transform: scale(0.7)"
           [src]="link"
           >
      <div *ngIf="nome && nome !== ''" style="padding: 0px 10px; max-width: 80%"
           [ngStyle]="{'color': bg !== undefined ? 'white' : (border !== undefined ? border : 'black')}">
        {{ nome }}
      </div>
    </div>
  `
})
export class CategorieCard {
  @Input() nome: string;
  @Input() link: string;
  @Input() border: string;
  @Input() bg: string;
  @Input() altezza: string;  // Nuovo input per l'altezza dinamica



  constructor(private router: Router) {

  }

  clicco(): void {
    console.log('Clicked on', this.nome);
  }

  logout(): void {
    this.router.navigateByUrl('login');
  }
}
