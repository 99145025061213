import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Subscription } from 'rxjs';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { trigger, transition, style, animate } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-user-selection',
  template: `
    <div class="fixed inset-0 bg-black p-4">
      <div class="bg-white rounded-3xl w-full h-full flex flex-col overflow-hidden relative" style="background: #fbfbfb">
        <div class="container">
          <div class="header">
            <h1>Seleziona Utente</h1>
            <div class="subtitle">Tocca un profilo per accedere</div>
          </div>

          <div class="users-grid">
            <div class="user-card"
                 *ngFor="let user of users"
                 (click)="selectUser(user)"
                 [class.selected-user]="isSelectedUser(user)">
              <div class="avatar">
                <div class="avatar-initials">{{getInitials(user.username)}}</div>
              </div>
              <div class="user-info">
                <div class="username">{{user.username}}</div>
                <div class="role">{{user.ruolo}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- PIN Dialog Template -->
    <ng-template #pinDialogTpl>
      <div class="pin-dialog">
        <div class="pin-header">
          <h2>Inserisci PIN</h2>
          <div class="user-selected">
            <div class="mini-avatar">{{getInitials(selectedUser?.username)}}</div>
            <div class="mini-username">{{selectedUser?.username}}</div>
          </div>
        </div>

        <div class="pin-display">
          <div class="pin-dots">
            <div class="pin-dot" *ngFor="let dot of [0,1,2,3]"
                 [class.filled]="pin.length > dot"></div>
          </div>
          <div *ngIf="showPinError" class="error-message">
            PIN non corretto
          </div>
        </div>

        <div class="pin-keypad">
          <div class="keypad-row" *ngFor="let row of keypadRows">
            <button class="keypad-button"
                    *ngFor="let key of row"
                    (click)="addPinDigit(key)"
                    [disabled]="key === null">
              {{key !== null ? key : ''}}
            </button>
          </div>

          <div class="keypad-row">
            <button class="keypad-button cancel-button" (click)="dialogRef.close(null)">
              Annulla
            </button>
            <button class="keypad-button" (click)="addPinDigit(0)">0</button>
            <button class="keypad-button backspace-button" (click)="removePinDigit()">
              <mat-icon>backspace</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: [`
    :host {
      display: block;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', sans-serif;
    }

    .fixed {
      position: fixed;
    }

    .inset-0 {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .bg-black {
      background-color: #fbfbfb;
    }

    .p-4 {
      padding: 1rem;
    }

    .rounded-3xl {
      border-radius: 1.5rem;
    }

    .w-full {
      width: 100%;
    }

    .h-full {
      height: 100%;
    }

    .flex {
      display: flex;
    }

    .flex-col {
      flex-direction: column;
    }

    .overflow-hidden {
      overflow: hidden;
    }

    .relative {
      position: relative;
    }

    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 4rem 2rem 2rem;
      height: calc(100vh - 4rem);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .header {
      text-align: center;
      margin-bottom: 3rem;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      margin: 0;
      color: #333;
    }

    .subtitle {
      font-size: 1.1rem;
      color: #666;
      margin-top: 0.5rem;
    }

    .users-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;
      padding: 1rem;
      overflow-y: auto;
      flex: 1;
    }

    .user-card {
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
      padding: 2rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 2px solid transparent;
      width: 220px;
      margin: 0 10px 20px;
    }

    .user-card:hover {
      transform: translateY(-4px);
      box-shadow: 0 6px 25px rgba(0, 0, 0, 0.12);
    }

    .selected-user {
      border-color: #353555;
      background-color: rgba(53, 53, 85, 0.08);
    }

    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: linear-gradient(135deg, #353555, #4a4a7a);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      box-shadow: 0 4px 10px rgba(53, 53, 85, 0.2);
    }

    .avatar-initials {
      font-size: 2rem;
      font-weight: 600;
    }

    .user-info {
      text-align: center;
      width: 100%;
    }

    .username {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      color: #333;
    }

    .role {
      font-size: 0.9rem;
      color: #666;
      font-weight: 500;
    }

    /* PIN Dialog Styles */
    .pin-dialog {
      background-color: #fff;
      border-radius: 24px;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .pin-header {
      text-align: center;
      margin-bottom: 1.5rem;
      width: 100%;
    }

    .pin-header h2 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0 0 1rem 0;
      color: #333;
    }

    .user-selected {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mini-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: linear-gradient(135deg, #353555, #4a4a7a);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      margin-right: 0.75rem;
    }

    .mini-username {
      font-size: 1.1rem;
      font-weight: 500;
    }

    .pin-display {
      margin-bottom: 2rem;
      text-align: center;
    }

    .pin-dots {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-bottom: 1rem;
    }

    .pin-dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid #ddd;
      background-color: transparent;
      transition: all 0.2s ease;
    }

    .pin-dot.filled {
      background-color: #353555;
      border-color: #353555;
    }

    .error-message {
      color: #e53935;
      font-size: 0.9rem;
      margin-top: 0.75rem;
      font-weight: 500;
    }

    .pin-keypad {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }

    .keypad-row {
      display: flex;
      justify-content: space-between;
      gap: 12px;
    }

    .keypad-button {
      flex: 1;
      height: 65px;
      border-radius: 16px;
      border: none;
      background-color: #f5f5f7;
      font-size: 1.5rem;
      font-weight: 600;
      color: #333;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .keypad-button:hover:not(:disabled) {
      background-color: #e5e5ea;
    }

    .keypad-button:active:not(:disabled) {
      transform: scale(0.95);
      background-color: #d1d1d6;
    }

    .cancel-button {
      font-size: 1rem;
      color: #e53935;
    }

    .backspace-button {
      color: #333;
    }
  `],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
    ]),
  ]
})
export class UserSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('pinDialogTpl') pinDialog!: TemplateRef<any>;
  @ViewChild('adminAuthDialogTpl') adminAuthDialog!: TemplateRef<any>;

  users: any[] = [];
  pin: string = '';
  private userSubscription: Subscription;
  dialogRef: MatDialogRef<any>;
  selectedUser: any;
  showPinError: boolean = false;
  currentUser: any;

  // Keypad configuration
  keypadRows = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9]
  ];

  // Per gestire la selezione admin
  adminUsers: any[] = [];
  tempSelectedAdmin: any = null;
  adminSelected: boolean = false;

  constructor(
      private dialog: MatDialog,
      private dataService: DataServiceGenerico,
      private router: Router,
      private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Prima carica i ristoranti disponibili
          await this.dataService.loadAvailableRestaurants();

          // Ora che abbiamo il ristorante, possiamo sottoscriverci ai tavoli
          const ristoranteId = this.dataService.getRistorante1();
          if (ristoranteId) {
            this.loadUsers();
            this.currentUser = this.dataService.getUserLocale();
          } else {
            this.showNotification('ID ristorante non disponibile');
          }
        } catch (error) {
          console.error('Errore durante l\'inizializzazione:', error);
          this.showNotification('Errore durante l\'inizializzazione');
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  isSelectedUser(user: any): boolean {
    return this.currentUser && this.currentUser.username === user.username;
  }

  getInitials(name: string): string {
    if (!name) return '';

    const parts = name.split(' ');
    if (parts.length > 1) {
      return (parts[0][0] + parts[1][0]).toUpperCase();
    }
    return name.substring(0, 2).toUpperCase();
  }
  openAdminAuthentication(callback: (success: boolean, adminUser: any) => void): void {
    this.adminSelected = false;
    this.tempSelectedAdmin = null;
    this.pin = '';
    this.showPinError = false;

    // Apri il dialog per la selezione dell'admin e l'inserimento del PIN
    this.dialogRef = this.dialog.open(this.adminAuthDialog, {
      width: '400px',
      panelClass: 'pin-dialog-container',
      disableClose: true
    });

    // Salva il metodo originale
    const originalConfirmPin = this.confirmPin.bind(this);

    // Override temporaneo del metodo
    this.confirmPin = () => {
      if (this.tempSelectedAdmin && this.tempSelectedAdmin.passwordCassa === this.pin) {
        this.dialogRef.close();
        callback(true, this.tempSelectedAdmin);
      } else {
        this.showPinError = true;
        this.pin = '';
      }
    };

    // Ripristina il metodo originale dopo la chiusura
    this.dialogRef.afterClosed().subscribe(() => {
      this.confirmPin = originalConfirmPin;
      if (!this.adminSelected || this.showPinError) {
        callback(false, null);
      }
    });
  }

  selectAdminForAuth(admin: any): void {
    this.tempSelectedAdmin = admin;
    this.adminSelected = true;
    this.pin = '';
    this.showPinError = false;
  }

  private loadUsers(): void {
    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();

    if (!ristoranteId) {
      console.error('ID ristorante non disponibile');
      return;
    }

    this.userSubscription = new Subscription();

    const docListener = db.collection('ristoranti')
        .doc(ristoranteId)
        .onSnapshot(
            (docSnapshot) => {
              if (docSnapshot.exists) {
                const data = docSnapshot.data();
                if (data && data.organico) {
                  this.users = data.organico;

                  // Filtra gli utenti admin
                  this.adminUsers = this.users.filter(user =>
                      user.ruolo === 'Admin' ||
                      user.ruolo === 'admin' ||
                      user.ruolo === 'Amministratore' ||
                      user.ruolo === 'amministratore' ||
                      user.ruolo === 'Manager'
                  );
                }
              }
            },
            (error) => {
              console.error('Errore nel caricamento degli utenti:', error);
            }
        );

    this.userSubscription.add(() => docListener());
  }

  selectUser(user: any): void {
    this.selectedUser = user;

    if (user.passwordCassa) {
      this.showPinError = false;
      this.pin = '';
      this.dialogRef = this.dialog.open(this.pinDialog, {
        width: '360px',
        panelClass: 'pin-dialog-container',
        disableClose: true
      });
    } else {
      this.confirmUserSelection(user);
    }
  }

  addPinDigit(digit: number): void {
    if (this.pin.length < 4) {
      this.pin += digit.toString();

      if (this.pin.length === 4) {
        // Automatically check the PIN when it reaches 4 digits
        setTimeout(() => this.confirmPin(), 300);
      }
    }
  }

  removePinDigit(): void {
    if (this.pin.length > 0) {
      this.pin = this.pin.slice(0, -1);
      this.showPinError = false;
    }
  }

  confirmPin(): void {
    // Verifica il PIN
    if (this.selectedUser.passwordCassa === this.pin) {
      this.dialogRef.close();
      this.confirmUserSelection(this.selectedUser);
    } else {
      this.showPinError = true;
      this.pin = '';
    }
  }

  private confirmUserSelection(user: any): void {
    this.dataService.setUserLocale(user);
    console.log("user settato: ", user);

    // Show confirmation notification
    this.showNotification(`Benvenuto, ${user.username}!`);

    // Controllo se il dispositivo è mobile
    const isMobile = this.isMobileDevice();

    // Reindirizza in base al tipo di dispositivo
    if (isMobile) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/cassa'], { fragment: 'nonav' });
    }
  }

// Aggiungi questo nuovo metodo per rilevare dispositivi mobili
  private isMobileDevice(): boolean {
    return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
        (window.innerWidth <= 768)
    );
  }

  private showNotification(message: string): void {
    this.snackBar.open(message, 'Chiudi', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['notification-snackbar']
    });
  }
}