<div class="row" style="height: 95vh; width:calc(100vw - 570px); margin-top: 15px; border-radius: 20px;; overflow: scroll">

  <div style="">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Ruoli</div>
    <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">

          <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
            <!--div style="width: 27%; margin-right: 1%;">
              <div class="sb-example-1 shadow" style="margin-left: 10px; width: 98%">
                <div class="search" >
                  <button type="submit" class="search-button" style="margin-top: 5px !important">
                    <i class="fa fa-search"></i>
                  </button>
                  <input style="margin-left: 10px; background-color: white" type="text" class="searchTerm" placeholder="Cerca">
                </div>
              </div>
            </div-->
            <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
              <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             [nome]="'Aggiungi Ruolo'"
                             altezza="50"
                             [link]="'assets/icons/icons8-add-90.png'">
              </categorieCard>
            </div>


          </div>

      <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">

        <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="ruoli" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

            <ng-container matColumnDef="nome" style="width: 50%">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let user"> {{user.nome}} </td>
            </ng-container>

            <ng-container matColumnDef="permessi" style="width: 50%">
                <th mat-header-cell *matHeaderCellDef> Permessi </th>
                <td mat-cell *matCellDef="let user">
                    <mat-select placeholder="Seleziona Categoria" multiple class="custom-input" [(ngModel)]="user.permessi">
                        <mat-option *ngFor="let Categoria of permessi" [value]="Categoria">
                            {{Categoria}}
                        </mat-option>
                    </mat-select>

                </td>
            </ng-container>



          <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


        </table>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
        Salva Modifiche
      </div>

    </div>

  </div>


  <div style="margin-bottom: 100px">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Dipendenti</div>
    <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">

      <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
        <!--div style="width: 27%; margin-right: 1%;">
          <div class="sb-example-1 shadow" style="margin-left: 10px; width: 98%">
            <div class="search" >
              <button type="submit" class="search-button" style="margin-top: 5px !important">
                <i class="fa fa-search"></i>
              </button>
              <input style="margin-left: 10px; background-color: white" type="text" class="searchTerm" placeholder="Cerca">
            </div>
          </div>
        </div-->
        <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
          <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                         [nome]="'Aggiungi Dipendente'"
                         altezza="50"
                         [link]="'assets/icons/icons8-add-90.png'">
          </categorieCard>
        </div>


      </div>

      <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">

        <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: auto; display: table !important; border-radius: 20px; height: 300px !important; max-height: 300px !important">


            <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
                <mat-header-cell *matHeaderCellDef>{{ column.field.toUpperCase()  }}
                    <mat-menu #menu>
                        <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
                        <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
                    </mat-menu>

                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <span *ngIf="column.field !== 'permessi'" >{{ row[column.field] }}</span>
                    <span *ngIf="column.field == 'permessi'" >
                          <mat-select placeholder="Seleziona Categoria" multiple class="custom-input" [(ngModel)]="row[column.field]">
                                <mat-option *ngFor="let Categoria of permessi" [value]="Categoria">
                                    {{Categoria}}
                                </mat-option>
                          </mat-select>
                    </span>

                </mat-cell>
            </ng-container>

            <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>

            <!-- Group header -->
            <ng-container matColumnDef="groupHeader">
                <mat-cell colspan="999" *matCellDef="let group">
                    <mat-icon *ngIf="group.expanded"></mat-icon>
                    <mat-icon *ngIf="!group.expanded"></mat-icon>
                    <strong >{{group[groupByColumns[group.level-1]]}}</strong>
                </mat-cell>
            </ng-container>

            <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>


        </mat-table>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);" (click)="addCose()">
        Salva Modifiche
      </div>

    </div>

  </div>


</div>


