import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { CommonService } from "../../../shared/services/firestore/common.service";

@Component({
  selector: 'edit-sconti',
  templateUrl: './edit-sconti.html',
  styleUrls: ['./edit-sconti.css'],
})
export class EditSconti implements OnInit, OnDestroy {
  scontoForm: FormGroup;
  isLoading = false;
  uploadProgress = 0;
  currentImageFile: File | null = null;
  menu: any[] = [];
  ristorante = 'menu';

  discountTypes = [
    { id: 'always', label: 'Sempre valido' },
    { id: 'timeRange', label: 'Orari specifici' },
    { id: 'specificDays', label: 'Giorni specifici' },
    { id: 'dateRange', label: 'Intervallo date' }
  ];

  giorni = [
    { id: 'monday', label: 'Lunedì' },
    { id: 'tuesday', label: 'Martedì' },
    { id: 'wednesday', label: 'Mercoledì' },
    { id: 'thursday', label: 'Giovedì' },
    { id: 'friday', label: 'Venerdì' },
    { id: 'saturday', label: 'Sabato' },
    { id: 'sunday', label: 'Domenica' }
  ];

  fidelityLevels = [
    { value: 1, label: 'Livello 1' },
    { value: 2, label: 'Livello 2' },
    { value: 3, label: 'Livello 3' },
    { value: 4, label: 'Livello 4' },
    { value: 5, label: 'Livello 5' }
  ];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cdRef: ChangeDetectorRef,
      private dialogRef: MatDialogRef<EditSconti>,
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initForm();
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  private initForm(): void {
    this.scontoForm = this.fb.group({
      nome: ['', Validators.required],
      punti: [0, [Validators.required, Validators.min(0)]],
      isMenuChecked: [false],
      isSingleChecked: [false],
      sconto: ['0€'],
      selectedProducts: [[]],
      discountType: ['always'],
      timeRange: this.fb.group({
        startTime: ['09:00'],
        endTime: ['18:00']
      }),
      specificDays: this.fb.group({
        monday: [true],
        tuesday: [true],
        wednesday: [true],
        thursday: [true],
        friday: [true],
        saturday: [true],
        sunday: [true]
      }),
      dataInizio: [''],
      dataFine: [''],
      descrizione: [''],
      imageUrl: [''],
      imageFile: [null],
      isAccountDiscount: [false],
      isAllLevels: [true],
      minimumLevel: [{value: 1, disabled: true}]
    });

    // React to isMenuChecked changes
    this.scontoForm.get('isMenuChecked').valueChanges.subscribe(isChecked => {
      if (isChecked) {
        this.scontoForm.get('sconto').disable();
        this.scontoForm.get('selectedProducts').enable();
      } else {
        this.scontoForm.get('sconto').enable();
        this.scontoForm.get('selectedProducts').disable();
      }
    });

    // React to isAllLevels changes
    this.scontoForm.get('isAllLevels').valueChanges.subscribe(isAll => {
      if (isAll) {
        this.scontoForm.get('minimumLevel').disable();
      } else {
        this.scontoForm.get('minimumLevel').enable();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    await this.fetchDataFromFirestore();

    if (this.data) {
      this.scontoForm.patchValue({
        nome: this.data.nome || '',
        punti: this.data.punti || 0,
        sconto: this.data.sconto || '0€',
        selectedProducts: this.data.hasOwnProperty('prodotto') ? this.data.prodotto : [],
        isMenuChecked: this.data.hasOwnProperty('prodotto'),
        isSingleChecked: this.data.riutilizzabile || false,
        discountType: this.data.tipoValidita || 'always',
        timeRange: this.data.timeRange || { startTime: '09:00', endTime: '18:00' },
        specificDays: this.data.giorniSettimana || {
          monday: true, tuesday: true, wednesday: true,
          thursday: true, friday: true, saturday: true, sunday: true
        },
        dataInizio: this.data.dataInizio || '',
        dataFine: this.data.dataFine || '',
        descrizione: this.data.descrizione || '',
        imageUrl: this.data.imageUrl || '',
        isAccountDiscount: this.data.isAccountDiscount || false,
        isAllLevels: this.data.isAllLevels ?? true,
        minimumLevel: this.data.minimumLevel ?? 1
      });

      if (this.data.isAccountDiscount) {
        this.scontoForm.get('nome').disable();
        this.scontoForm.get('punti').disable();
        this.scontoForm.get('isMenuChecked').disable();
      }
    }

    this.cdRef.detectChanges();
  }

  async fetchDataFromFirestore(): Promise<void> {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    try {
      const doc = await menuRef.get();
      if (doc.exists) {
        const data = doc.data();
        this.menu = data?.items || [];
        this.cdRef.detectChanges();
      }
    } catch (error) {
      console.error('Error fetching data from Firestore:', error);
    }
  }

  async onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.currentImageFile = file;
      this.scontoForm.patchValue({
        imageFile: file,
        imageUrl: ''
      });
    }
  }

  async uploadImage(file: File): Promise<string> {
    const restaurantId = this.dataService.getRistorante1();
    if (!restaurantId || !file) throw new Error('Missing restaurant ID or file');

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`restaurants/${restaurantId}/sconti/${file.name}`);

    const uploadTask = imageRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
          (snapshot) => {
            this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => reject(error),
          async () => {
            const downloadUrl = await imageRef.getDownloadURL();
            resolve(downloadUrl);
          }
      );
    });
  }

  async saveSconto() {
    if (this.scontoForm.invalid) {
      console.error('Form non valido');
      return;
    }

    this.isLoading = true;
    try {
      const formValue = this.scontoForm.value;
      let imageUrl = formValue.imageUrl;

      if (this.currentImageFile) {
        imageUrl = await this.uploadImage(this.currentImageFile);
      }

      const scontoData: any = {
        tipoValidita: formValue.discountType,
        descrizione: formValue.descrizione,
        imageUrl: imageUrl,
        isAllLevels: formValue.isAllLevels,
        minimumLevel: formValue.isAllLevels ? null : formValue.minimumLevel
      };

      // Se è uno sconto account, aggiungiamo solo i campi necessari
      if (formValue.isAccountDiscount) {
        scontoData.isAccountDiscount = true;
        scontoData.nome = 'Sconto Account';  // Nome fisso per sconto account
        scontoData.punti = 0;  // Punti fissi a 0 per sconto account
        scontoData.sconto = formValue.sconto;
      } else {
        // Per gli sconti normali, aggiungiamo tutti i campi
        scontoData.nome = formValue.nome;
        scontoData.punti = formValue.punti;
        scontoData.riutilizzabile = formValue.isSingleChecked;

        if (!formValue.isMenuChecked) {
          scontoData.sconto = formValue.sconto;
        } else {
          scontoData.prodotto = formValue.selectedProducts;
        }
      }

      // Aggiungiamo i campi di validità in base al tipo selezionato
      if (formValue.discountType === 'timeRange') {
        scontoData.timeRange = formValue.timeRange;
      } else if (formValue.discountType === 'specificDays') {
        scontoData.giorniSettimana = formValue.specificDays;
      } else if (formValue.discountType === 'dateRange') {
        scontoData.dataInizio = formValue.dataInizio;
        scontoData.dataFine = formValue.dataFine;
      }

      this.dialogRef.close(scontoData);
    } catch (error) {
      console.error('Error saving sconto:', error);
    } finally {
      this.isLoading = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {}

  async deleteSconto() {
    if (confirm('Sei sicuro di voler eliminare questo sconto?')) {
      this.dialogRef.close({ delete: true, id: this.data.id });
    }
  }
}