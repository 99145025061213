<div class="settings-container">
    <div class="settings-header">
        <h2>{{ fornitore?.id ? 'Modifica Fornitore' : 'Nuovo Fornitore' }}</h2>
        <div class="header-actions">
            <button class="icon-button delete" *ngIf="fornitore?.id" (click)="deleteFornitore()" title="Elimina" style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                elimina
            </button>
            <button class="icon-button delete" *ngIf="!fornitore?.id" (click)="deleteFornitore()" title="Elimina" style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                Annulla
            </button>
            <button class="icon-button save" (click)="saveChanges()" title="Salva" style="background-color: rgb(52 152 219); border: 1px solid rgb(52 152 219); color: white">
                salva
            </button>
        </div>
    </div>

    <div class="settings-content">
        <!-- Dati principali del fornitore -->
        <div class="settings-section">
            <div class="section-header">
                <h3>Dati Fornitore</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Nome/Ragione Sociale*</div>
                <div class="setting-field">
                    <input [(ngModel)]="nome" placeholder="Nome o ragione sociale" class="custom-input" required>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Partita IVA</div>
                <div class="setting-field">
                    <input [(ngModel)]="partitaIva" placeholder="Partita IVA" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Indirizzo</div>
                <div class="setting-field">
                    <input [(ngModel)]="indirizzo" placeholder="Indirizzo" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-field" style="display: flex; gap: 16px;">
                    <div style="flex: 1;">
                        <div class="setting-label">CAP</div>
                        <input [(ngModel)]="cap" placeholder="CAP" class="custom-input">
                    </div>
                    <div style="flex: 2;">
                        <div class="setting-label">Città</div>
                        <input [(ngModel)]="citta" placeholder="Città" class="custom-input">
                    </div>
                    <div style="flex: 1;">
                        <div class="setting-label">Provincia</div>
                        <input [(ngModel)]="provincia" placeholder="Provincia" class="custom-input">
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Nazione</div>
                <div class="setting-field">
                    <input [(ngModel)]="nazione" placeholder="Nazione" class="custom-input" value="Italia">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Telefono</div>
                <div class="setting-field">
                    <input [(ngModel)]="telefono" placeholder="Telefono" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Email</div>
                <div class="setting-field">
                    <input [(ngModel)]="email" placeholder="Email" class="custom-input" type="email">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Sito Web</div>
                <div class="setting-field">
                    <input [(ngModel)]="sitoWeb" placeholder="Sito Web" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Note</div>
                <div class="setting-field">
                    <textarea [(ngModel)]="note" placeholder="Note aggiuntive" class="custom-input" rows="3"></textarea>
                </div>
            </div>
        </div>

        <!-- Sezione Contatti -->
        <div class="settings-section">
            <div class="section-header">
                <h3>Contatti</h3>
            </div>

            <!-- Form per aggiungere nuovo contatto -->
            <div class="settings-row">
                <div class="setting-label">Nome*</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoContatto.nome" placeholder="Nome del contatto" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Ruolo</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoContatto.ruolo" placeholder="Ruolo o posizione" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Telefono</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoContatto.telefono" placeholder="Telefono del contatto" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Email</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoContatto.email" placeholder="Email del contatto" class="custom-input" type="email">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-field">
                    <button class="action-button" (click)="addContatto()">
                        Aggiungi Contatto
                    </button>
                </div>
            </div>

            <!-- Lista dei contatti -->
            <div class="settings-row" *ngIf="contatti.length > 0">
                <div class="setting-field">
                    <table style="width: 100%; border-collapse: collapse;">
                        <thead>
                        <tr>
                            <th style="text-align: left; padding: 8px;">Nome</th>
                            <th style="text-align: left; padding: 8px;">Ruolo</th>
                            <th style="text-align: left; padding: 8px;">Telefono</th>
                            <th style="text-align: left; padding: 8px;">Email</th>
                            <th style="text-align: center; padding: 8px;">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let contatto of contatti; let i = index" style="border-bottom: 1px solid rgba(0,0,0,0.12);">
                            <td style="padding: 8px;">{{ contatto.nome }}</td>
                            <td style="padding: 8px;">{{ contatto.ruolo }}</td>
                            <td style="padding: 8px;">{{ contatto.telefono }}</td>
                            <td style="padding: 8px;">{{ contatto.email }}</td>
                            <td style="text-align: center; padding: 8px;">
                                <button class="elimina-button" (click)="removeContatto(i)" style="padding: 4px 8px; height: 35px;">
                                    Rimuovi
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="settings-row" *ngIf="contatti.length === 0">
                <div class="setting-field" style="text-align: center; padding: 20px; color: #666;">
                    Nessun contatto aggiunto
                </div>
            </div>
        </div>

        <!-- Sezione Prodotti Associati -->
        <div class="settings-section">
            <div class="section-header">
                <h3>Prodotti Associati</h3>
            </div>

            <!-- Form per aggiungere nuovo prodotto associato -->
            <div class="settings-row">
                <div class="setting-label">Prodotto*</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="nuovoProdotto.prodottoId" placeholder="Seleziona prodotto" class="custom-input">
                        <mat-option *ngFor="let prodotto of prodottiDisponibiliNonAssociati" [value]="prodotto.id">
                            {{ prodotto.title }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Prezzo di Acquisto*</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoProdotto.prezzoAcquisto"
                           placeholder="Prezzo di acquisto"
                           class="custom-input"
                           type="number"
                           step="0.01"
                           min="0.01">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Tempo di Consegna</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoProdotto.tempoDiConsegna"
                           placeholder="Tempo di consegna (giorni)"
                           class="custom-input"
                           type="number"
                           min="1">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Ordine Minimo</div>
                <div class="setting-field">
                    <input [(ngModel)]="nuovoProdotto.minOrdine"
                           placeholder="Quantità minima ordinabile"
                           class="custom-input"
                           type="number"
                           min="1">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Note</div>
                <div class="setting-field">
                    <textarea [(ngModel)]="nuovoProdotto.note" placeholder="Note sul prodotto" class="custom-input" rows="2"></textarea>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-field">
                    <button class="action-button" (click)="addProdottoAssociato()">
                        Associa Prodotto
                    </button>
                </div>
            </div>

            <!-- Lista dei prodotti associati -->
            <div class="settings-row" *ngIf="prodottiAssociati.length > 0">
                <div class="setting-field">
                    <table style="width: 100%; border-collapse: collapse;">
                        <thead>
                        <tr>
                            <th style="text-align: left; padding: 8px;">Prodotto</th>
                            <th style="text-align: right; padding: 8px;">Prezzo Acquisto</th>
                            <th style="text-align: center; padding: 8px;">Tempo Consegna</th>
                            <th style="text-align: center; padding: 8px;">Ordine Min.</th>
                            <th style="text-align: left; padding: 8px;">Note</th>
                            <th style="text-align: center; padding: 8px;">Azioni</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let prodotto of prodottiAssociati; let i = index" style="border-bottom: 1px solid rgba(0,0,0,0.12);">
                            <td style="padding: 8px;">{{ getProdottoNome(prodotto.prodottoId) }}</td>
                            <td style="text-align: right; padding: 8px;">{{ prodotto.prezzoAcquisto | number:'1.2-2' }} €</td>
                            <td style="text-align: center; padding: 8px;">{{ prodotto.tempoDiConsegna }} giorni</td>
                            <td style="text-align: center; padding: 8px;">{{ prodotto.minOrdine }}</td>
                            <td style="padding: 8px;">{{ prodotto.note }}</td>
                            <td style="text-align: center; padding: 8px;">
                                <button class="elimina-button" (click)="removeProdottoAssociato(i)" style="padding: 4px 8px; height: 35px;">
                                    Rimuovi
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="settings-row" *ngIf="prodottiAssociati.length === 0">
                <div class="setting-field" style="text-align: center; padding: 20px; color: #666;">
                    Nessun prodotto associato
                </div>
            </div>
        </div>
    </div>
</div>