<div class="fixed inset-0 bg-black p-4">
    <div class="bg-white rounded-3xl w-full h-full flex flex-col overflow-hidden relative">
        <!-- Header with translucid effect -->
        <div class="sticky top-0 z-50 p-4">
            <div class="bg-white/60 backdrop-blur-lg rounded-2xl shadow-sm border border-white/20 overflow-hidden">
                <div class="flex items-center justify-between p-2">
                    <div class="flex items-center p-1.5 bg-white/80 backdrop-blur-sm rounded-xl gap-1 flex-1 max-w-xl">
                        <button
                                class="px-6 py-2.5 rounded-xl text-sm font-medium transition-all"
                                [ngClass]="{'bg-gradient-to-r from-purple-100 to-pink-100 text-gray-700 shadow-sm': lista === 0, 'text-gray-600 hover:bg-white/80': lista !== 0}"
                                (click)="changeView(0)"
                        >
                            Scontrini
                        </button>
                        <button
                                class="px-6 py-2.5 rounded-xl text-sm font-medium transition-all"
                                [ngClass]="{'bg-gradient-to-r from-blue-100 to-cyan-100 text-gray-700 shadow-sm': lista === 1, 'text-gray-600 hover:bg-white/80': lista !== 1}"
                                (click)="changeView(1)"
                        >
                            Tavoli
                        </button>
                        <button
                                class="px-6 py-2.5 rounded-xl text-sm font-medium transition-all"
                                [ngClass]="{'bg-gradient-to-r from-yellow-100 to-amber-100 text-gray-700 shadow-sm': lista === 2, 'text-gray-600 hover:bg-white/80': lista !== 2}"
                                (click)="changeView(2)"
                        >
                            Sala
                        </button>
                        <button
                                class="px-6 py-2.5 rounded-xl text-sm font-medium transition-all"
                                [ngClass]="{'bg-gradient-to-r from-green-100 to-emerald-100 text-gray-700 shadow-sm': lista === 3, 'text-gray-600 hover:bg-white/80': lista !== 3}"
                                (click)="changeView(3)"
                        >
                            Delivery
                        </button>
                    </div>

                    <div class="flex items-center gap-2 px-2">
                        <button class="p-2.5 rounded-xl bg-gradient-to-r from-gray-100 to-gray-200 hover:from-gray-200 hover:to-gray-300 transition-all" (click)="apricassetto()">
                            <mat-icon style="width: 20px; height: 20px; font-size: 20px; color: #374151;">print</mat-icon>
                        </button>
                        <button
                                class="p-2.5 rounded-xl bg-gradient-to-r from-gray-100 to-gray-200 hover:from-gray-200 hover:to-gray-300 transition-all"
                                (click)="setShowFilters(true)"
                        >
                            <mat-icon style="width: 20px; height: 20px; font-size: 20px; color: #374151;">tune</mat-icon>
                        </button>
                        <button class="p-2.5 rounded-xl bg-gradient-to-r from-gray-100 to-gray-200 hover:from-gray-200 hover:to-gray-300 transition-all" (click)="apriazioniStampanteDialog()">
                            <mat-icon style="width: 20px; height: 20px; font-size: 20px; color: #374151;">settings</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>



        <!-- Main content area -->
        <div class="flex flex-1 min-h-0">
            <!-- Orders list -->
            <div class="w-1/4 border-r border-gray-200 overflow-y-auto bg-[#f8f8f8]">
                <!-- Action button -->
                <div class="p-4">
                    <button class="w-full p-3 rounded-xl bg-gradient-to-r from-purple-50 to-pink-50 hover:from-purple-100 hover:to-pink-100 transition-colors text-left flex items-center gap-2 font-medium" (click)="aggiungiOrdine()">
                        <span class="text-purple-600 text-lg">+</span>
                        Nuovo Ordine
                    </button>
                </div>

                <!-- Orders list -->
                <div class="p-4 space-y-3">
                    <div
                            *ngFor="let order of camerieri"
                            class="bg-white rounded-xl p-4 relative cursor-pointer compact-card"
                            [class.selected]="selectedOrder?.id === order.id"
                            [style]="{'box-shadow': selectedOrder?.id === order.id ? '0 0 0 1px #f0e7ff' : '0 1px 3px rgba(0,0,0,0.05)'}"
                            (click)="openOrderDetail(order)">
                        <div
                                *ngIf="selectedOrder?.id === order.id"
                                class="absolute inset-[-1px] bg-gradient-to-r from-purple-400/40 to-pink-400/40 rounded-xl -z-10"
                        ></div>

                        <div class="flex justify-between mb-1">
                            <h3 class="font-medium" style="margin: 0; padding: 10px !important;">{{order.displayName}}</h3>
                            <h3 class="text-lg font-medium"  style="margin:  0; padding: 10px !important;">€{{calcolaTotaleOrdine(order.ordine)}}</h3>
                        </div>
                        <div class="flex justify-between">
                            <p class="text-sm text-gray-500" style="margin:  0; padding: 10px !important;">{{getFonteText(order.fonte)}}</p>
                            <p class="inline-flex px-3 py-0.5 rounded-full text-sm font-medium" style="margin: 0; padding: 10px !important;"
                                  [ngClass]="{'bg-red-50 text-red-600': order.statoPagato === 1, 'bg-green-50 text-green-600': order.statoPagato === 0}">
            {{order.statoPagato === 0 ? 'Pagato' : 'Non Pagato'}}
        </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Order details -->
            <div *ngIf="selectedOrder" class="flex-1 flex flex-col bg-white">
                <div class="flex-1 overflow-y-auto">
                    <div class="p-6">
                        <div class="flex justify-between items-start mb-6">
                            <div>
                                <div class="flex items-center gap-3">
                                    <h2 class="text-xl font-medium">{{selectedOrder.displayName}}</h2>
                                    <span class="px-3 py-1 rounded-full text-sm font-medium"
                                          [ngClass]="{'bg-red-50 text-red-600': selectedOrder.statoPagato === 1, 'bg-green-50 text-green-600': selectedOrder.statoPagato === 0}">
                                        {{selectedOrder.statoPagato === 0 ? 'Pagato' : 'Non Pagato'}}
                                    </span>
                                </div>
                                <p class="text-sm text-gray-500 mt-1">{{selectedOrder.data | date:'dd/MM/yyyy HH:mm'}}</p>
                            </div>
                        </div>

                        <!-- Linea divisoria dopo l'header dei dettagli -->
                        <div class="border-t border-gray-200 mb-4"></div>

                        <div class="space-y-2">
                            <div *ngFor="let item of getProperties(selectedOrder?.carrello)"
                                 class="bg-gray-50 rounded-xl p-4 flex items-center justify-between hover:bg-gray-100 transition-colors cursor-pointer item-row">
                                <div class="flex items-center gap-3">
                                    <span class="font-medium text-gray-600">{{selectedOrder.carrello[item].quantita}}×</span>
                                    <span>{{selectedOrder.carrello[item].title}}</span>
                                </div>
                                <span class="font-medium">€{{(selectedOrder.carrello[item].prezzo * selectedOrder.carrello[item].quantita).toFixed(2)}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Bottom total and actions -->
                <div class="p-6 border-t border-gray-200">
                    <div class="flex justify-between items-center mb-4">
                        <span class="text-xl font-medium">Totale</span>
                        <span class="text-3xl font-bold">€{{calcolaTotaleOrdine(selectedOrder.ordine)}}</span>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <button class="p-4 rounded-2xl bg-gray-100 text-gray-700 font-medium hover:bg-gray-200 transition-all text-lg note-button">
                            Aggiungi nota
                        </button>
                        <button class="p-4 rounded-2xl bg-viola text-white font-medium hover:bg-black/90 transition-all text-lg payment-button" (click)="cambiaSchermata(selectedOrder.id)">
                            Pagamento
                        </button>
                    </div>
                </div>
            </div>

            <!-- Filters panel -->
            <div
                    class="absolute inset-y-0 right-0 w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out"
                    [ngClass]="{'translate-x-0': showFilters, 'translate-x-full': !showFilters}" *ngIf="showFilters"
            >
                <div class="h-full overflow-y-auto">
                    <div class="p-6">
                        <div class="flex justify-between items-center mb-6">
                            <h3 class="text-lg font-medium">Filtri</h3>
                            <button
                                    class="text-gray-400 hover:text-gray-600"
                                    (click)="setShowFilters(false)"
                            >
                                ✕
                            </button>
                        </div>

                        <div class="space-y-6">
                            <!-- Azioni -->
                            <div>
                                <h4 class="text-sm font-medium text-gray-900 mb-3">Azioni</h4>
                                <div class="space-y-2">
                                    <button class="w-full p-3 rounded-xl bg-gradient-to-r from-purple-50 to-pink-50 hover:from-purple-100 hover:to-pink-100 transition-colors text-left flex items-center gap-2" (click)="aggiungiOrdine()">
                                        <span class="text-purple-600">+</span>
                                        Ordine Banco
                                    </button>
                                    <button class="w-full p-3 rounded-xl bg-gradient-to-r from-blue-50 to-cyan-50 hover:from-blue-100 hover:to-cyan-100 transition-colors text-left flex items-center gap-2" (click)="addTakeawayOrder()">
                                        <span class="text-blue-600">+</span>
                                        Ordine Asporto
                                    </button>
                                </div>
                            </div>

                            <div class="h-px bg-gray-200 w-full"></div>

                            <div>
                                <h4 class="text-sm font-medium text-gray-900 mb-3">Fonte</h4>
                                <div class="space-y-2">
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="source" [checked]="filtroSource === 0" (change)="filtraApp()" class="text-[#FFC72C]">
                                        <span>App</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="source" [checked]="filtroSource === 1" (change)="filtraChiosco()" class="text-[#FFC72C]">
                                        <span>Kiosk</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="source" [checked]="filtroSource === 2" (change)="filtraCassa()" class="text-[#FFC72C]">
                                        <span>Cassa</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="source" [checked]="filtroSource === 3" (change)="filtraTutto()" class="text-[#FFC72C]">
                                        <span>Tutti</span>
                                    </label>
                                </div>
                            </div>

                            <div>
                                <h4 class="text-sm font-medium text-gray-900 mb-3">Stato</h4>
                                <div class="space-y-2">
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="status" [checked]="filtroStato === 0" (change)="filtraPagato()" class="text-[#FFC72C]">
                                        <span>Pagato</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="status" [checked]="filtroStato === 1" (change)="filtraNonPagato()" class="text-[#FFC72C]">
                                        <span>Non Pagato</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="status" [checked]="filtroStato === 2" (change)="filtraStatoTutto()" class="text-[#FFC72C]">
                                        <span>Tutti</span>
                                    </label>
                                    <label class="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-50 cursor-pointer">
                                        <input type="radio" name="status" [checked]="filtroStato === 3" (change)="filtraCompletato()" class="text-[#FFC72C]">
                                        <span>Completato</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>