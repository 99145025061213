<div class="dialog-container">
  <div class="sticky-container">
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Salva Prodotto"
           (click)="saveChangesToFirestore()">
    </div>
    <div class="close-button" style="margin-right: 40px">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Chiudi"
           (click)="closeDialog()">
    </div>
  </div>

  <div style="height: 70vh;">
    <div class="example-container" style="max-width: 100%;">


      <div class="row">
        <div class="column" style="margin-left: -1% !important;">

          <div class="sb-example-1 shadow" style="width: 100%; margin-top: 2vh">
            <div class="search" style="height: 45px !important; margin-bottom: 3px !important;">
              <button type="submit" class="search-button" style="margin-left: 5px !important">
                <i class="fa fa-search"></i>
              </button>
              <input style="margin-left: 10px; height: 30px !important;" type="text" class="searchTerm" placeholder="Cerca" (input)="applyFilter($event.target.value)">
            </div>
          </div>


          <div class="container menu" id="menuListaPiatti">
            <div style="pointer-events: auto; margin-top: 1vh">
              <div style="padding-top: 0%; padding-left: 0px">
                <!-- Contenitore per la tabella con altezza fissa e scorrimento verticale -->
                <div class='table-responsive' style="height: 78vh; overflow-y: auto;">

                  <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

                    <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
                      <mat-header-cell *matHeaderCellDef >{{ column.field.toUpperCase()  }}
                        <mat-menu #menu>
                          <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
                          <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
                        </mat-menu>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <input *ngIf="column.field !== 'Categoria' && column.field !== 'Sync menu' && column.field !== 'Quantità'" style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px " type="text" class="searchTerm"  [(ngModel)]="row[column.field]" >

                        <ng-container *ngIf="column.field === 'Quantità'">
                          <span class="quantity">{{row.Quantita}} x</span>

                        </ng-container>
                        <ng-container *ngIf="column.field === 'Sync menu'">

                          <div class="checkbox_item citem_1" style="width: 30px; margin-left: 20px; margin-right: calc(100% - 130px)">
                            <label class="checkbox_wrap">
                              <input
                                type="checkbox"
                                name="checkbox"
                                class="checkbox_inp"
                                [checked]="row.SyncMenu === '1'"
                                (change)="onSyncMenuChange(row)"
                              >
                              <span class="checkbox_mark"></span>
                            </label>
                          </div>

                          <div style="width: 30px; margin-left: 10px">
                            <button class="add-button" >
                              <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-64.png?alt=media&token=56c275a9-9eb6-4d1e-a1c7-20012437d442" alt="Azione" style="max-width: 15px; max-height: 15px;" (click)="openDialogAdd(row)">
                            </button>
                          </div>
                          <div style="width: 30px; margin-left: 10px">
                            <button class="action-button" >
                              <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-90.png?alt=media&token=3975c982-b1e5-467a-92cd-0f81e5a30c5a" alt="Azione" style="max-width: 15px; max-height: 15px;" (click)="openDialog(row)">
                            </button>
                          </div>
                        </ng-container>
                      </mat-cell>
                    </ng-container>

                    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                    <!-- Group header -->
                    <ng-container matColumnDef="groupHeader">
                      <mat-cell colspan="999" *matCellDef="let group">
                        <strong>{{group[groupByColumns[group.level-1]]}}</strong>

                      </mat-cell>
                    </ng-container>

                    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

                  </mat-table>

                </div>
              </div>
            </div>
          </div>



        </div>
      </div>


    </div>
  </div>
</div>
