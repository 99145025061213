import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../shared/services/firestore/userData.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-test-offline-mode',
  templateUrl: './test-offline-mode.component.html',
  styleUrls: ['./test-offline-mode.component.css']
})
export class TestOfflineModeComponent implements OnInit {
  onlineData$: Observable<any[]>;
  offlineData$: Observable<any[]>;
  areDataEqual: boolean = true;

  constructor(private userDataService: UserDataService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.onlineData$ = this.userDataService.getAllOnline().pipe(
      map(onlineData => {
        this.userDataService.getAllOffline().subscribe(offlineData => {
          this.areDataEqual = this.compareData(onlineData, offlineData);
        });
        return onlineData;
      })
    );
    this.offlineData$ = this.userDataService.getAllOffline();
  }

  forceOfflineMode(): void {
    window.require('electron').ipcRenderer.send('force-offline-mode');
  }

  returnOnlineMode(): void {
    window.require('electron').ipcRenderer.send('return-online-mode');
  }

  compareData(onlineData: any[], offlineData: any[]): boolean {
    const removeIdProperties = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(removeIdProperties);
      } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
          if (key !== '_id' && key !== 'id') {
            acc[key] = removeIdProperties(obj[key]);
          }
          return acc;
        }, {});
      }
      return obj;
    };

    const isEqual = (obj1: any, obj2: any): boolean => {
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 === obj2;
      }

      const obj1Keys = Object.keys(obj1);
      const obj2Keys = Object.keys(obj2);

      if (obj1Keys.length !== obj2Keys.length) {
        return false;
      }

      for (const key of obj1Keys) {
        if (!obj2Keys.includes(key)) {
          return false;
        }
        if (!isEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    };

    for (const onlineUser of onlineData) {
      const offlineUser = offlineData.find(user => user.uid === onlineUser.uid || user._id === onlineUser.uid);
      if (!offlineUser) {
        return false;
      }

      const cleanedOnlineUser = removeIdProperties(onlineUser);
      const cleanedOfflineUser = removeIdProperties(offlineUser);

      if (!isEqual(cleanedOnlineUser, cleanedOfflineUser)) {
        return false;
      }
    }

    return true;
  }
}
