import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  HostListener
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Importazioni RxJS
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Importazioni Material e Dialog
import { MatDialog } from '@angular/material/dialog';

// Importazioni Firebase
import firebase from 'firebase';

// Importazioni componenti di dialogo
import { SelezionaVarianti } from '../../seleziona-varianti/seleziona-varianti';
import { DialogPagamento } from '../../dialogPagamento/dialogPagamento';
import { Fidelity } from '../../fidelity/fidelity';
import { Fatturazione } from '../../fatturazione/fatturazione';
import { DeleteOrderDialogComponent } from '../../../component/delete-order-dialog/delete-order-dialog.component';
import { PagerDialogComponent } from '../../PagerDialogComponent/pagerDialogComponent.component';
import { AddNoteDialogComponent } from '../../addNoteDialog/addNoteDialog.component';
// Aggiungi dopo gli altri import dei componenti dialog
import { CoverDialogComponent } from '../../cover-dialog/cover-dialog.component';

// Importazioni servizi
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { CommonService } from '../../../shared/services/firestore/common.service';
import { IpcService } from '../../../shared/services/ipc.service';
import { PosTransactionService } from '../../../shared/services/pos-transaction.service';
import { SelezionaVariantiSingoloComponent } from "../../seleziona-varianti-singolo/seleziona-varianti-singolo.component";
import { DailyReceiptService } from "../../../shared/services/daily-receipt.service";
import 'hammerjs';

interface MenuItem {
  id: string;
  title: string;
  image_link: string;
  price: number;
  category: string;
  description?: string;
  disponibilita?: boolean;
  priceListPrices?: any;
  variantIds?: string[];
}

interface FavoriteSection {
  name: string;
  items: string[]; // Array di item IDs
}

interface MenuDocument {
  items: MenuItem[];
  favorites: FavoriteSection[];
}

@Component({
  selector: 'cassa-ordine',
  templateUrl: './cassa-ordine.html',
  styleUrls: ['./cassa-ordine.css']
})
export class CassaOrdine implements OnInit, OnDestroy {
  private lastPagerRemoval: { [key: string]: string } = {};

  // Aggiungi questi campi alla classe CassaOrdine bottoni su e giù
  showScrollButtons = false;
  menuContainerElement: HTMLElement | null = null;
  keypadVisible: boolean = false;
  keypadText: string = 'Apri tastierino';

  private barcodeBuffer: string = '';
  private barcodeTimeout: any;
  private readonly BARCODE_TIMEOUT = 50; // 50ms di timeout dopo l'ultima digitazione

  // Variabili di stato principali
  selectedPriceList: string = '';
  selectedPrice: number | null = null;
  selectedQuantity: number | null = null;
  resetCounter = 0;
  variabile_eliminazione = true;
  variabile_singolo = true;

  favorites: FavoriteSection[] = [];
  currentFavoriteSection = 0;

  // Gestione ordine
  currentOrder: any;
  portata = 4;
  sezione = -1;
  menu: any[] = [];
  menuBello: any[] = [];
  imagePaths: { [key: string]: Promise<string> } = {};
  user: any;
  isCategoriesExpanded: boolean = true;

  pendingQuantity: number | null = null;

  orderType: string = 'counter'; // 'counter' per Da Banco, 'takeaway' per Da Asporto


  // Variabili per la ricerca
  searchQuery: string = '';
  searchResults: any[] = [];
  isSearchMode: boolean = false;

  createNewProduct: boolean = false;
  pendingDiscountPercentage: number = null;
  pendingDiscountAmount: number = null;
  pendingDiscountOperation: 'discount' | 'increase' = null;

  // Gestione varianti
  private allVariants: any[] = [];

  // Gestione del ciclo di vita
  private destroy$ = new Subject<void>();
  private posTransactionCleanup: (() => void) | null = null;
  private authUnsubscribe: (() => void) | null = null;
  private subscriptions: Subscription[] = [];
  private orderSubscription: Subscription | null = null;
  private variantsSubscription: Subscription | null = null;
  private menuSubscription: Subscription | null = null;
  private scrollHandler: (() => void) | null = null;
  private coverDialogShown: boolean = false;

// Add this property to your CassaOrdine component class
  private orderItemsContainer: HTMLElement | null = null;


  selectedPortata = 4;

  // Configurazione modale
  modalOptions = {
    backdrop: true,
    keyboard: false,
    focus: true,
    show: false,
    scroll: true,
    ignoreBackdropClick: false,
    class: 'app-modal-window',
    containerClass: '',
    animated: true,
    data: {}
  };

  constructor(
      private router: Router,
      private userService: UserDataService,
      public dialog: MatDialog,
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private route: ActivatedRoute,
      private ngZone: NgZone,
      private dailyReceiptService: DailyReceiptService
  ) {
  }

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Previeni l'elaborazione se siamo in un input
    if (event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement) {
      return;
    }

    // Annulla il timeout precedente
    if (this.barcodeTimeout) {
      clearTimeout(this.barcodeTimeout);
    }

    // Aggiungi il carattere al buffer
    this.barcodeBuffer += event.key;

    // Imposta un nuovo timeout
    this.barcodeTimeout = setTimeout(() => {
      if (this.barcodeBuffer.length > 0) {
        this.findAndAddProductByBarcode(this.barcodeBuffer);
        this.barcodeBuffer = ''; // Reset del buffer dopo la ricerca
      }
    }, this.BARCODE_TIMEOUT);
  }

  // Metodo di cleanup del componente
  ngOnDestroy(): void {
    // Clear barcode timeout if active
    if (this.barcodeTimeout) {
      clearTimeout(this.barcodeTimeout);
      this.barcodeTimeout = null;
    }

    // Rimuovi il listener per lo scroll
    if (this.menuContainerElement && this.scrollHandler) {
      this.menuContainerElement.removeEventListener('scroll', this.scrollHandler);
      this.menuContainerElement = null;
      this.scrollHandler = null;
    }

    // Complete the destroy subject to trigger takeUntil in all subscriptions
    this.destroy$.next();
    this.destroy$.complete();

    // Unsubscribe from all subscriptions in the array
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach(sub => {
        if (sub && !sub.closed) {
          sub.unsubscribe();
        }
      });
      this.subscriptions = [];
    }

    // Explicitly unsubscribe from named subscriptions
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
      this.orderSubscription = null;
    }

    if (this.variantsSubscription) {
      this.variantsSubscription.unsubscribe();
      this.variantsSubscription = null;
    }

    if (this.menuSubscription) {
      this.menuSubscription.unsubscribe();
      this.menuSubscription = null;
    }

    // Clean up POS transaction
    if (this.posTransactionCleanup) {
      this.posTransactionCleanup();
      this.posTransactionCleanup = null;
    }

    // Clean up Firebase auth
    if (this.authUnsubscribe) {
      this.authUnsubscribe();
      this.authUnsubscribe = null;
    }

    // Close secondary display if open
    this.ipcService.updateSecondaryDisplay(undefined);
  }

  // Inizializzazione del componente
  async ngOnInit(): Promise<void> {
    // Subscribe to pager events
    const pagerSubscription = this.ipcService.pagerRemoved$.subscribe(({pagerId, timestamp}) => {
      console.log(`Pager ${pagerId} rimosso dalla base alle ${timestamp}`);
      this.lastPagerRemoval[pagerId] = timestamp;

      console.log('Pager corrente rimosso dalla base');

      // Se è il pager dell'ordine corrente
      if (this.currentOrder?.pager === pagerId) {
        console.log('Pager corrente rimosso dalla base');
        // Qui puoi aggiungere logica specifica per quando
        // il pager dell'ordine corrente viene rimosso
      }
    });
    this.subscriptions.push(pagerSubscription);

    // Initialize menu container and set up scroll listener
    setTimeout(() => {
      this.menuContainerElement = document.querySelector('.menu-items-container');
      if (this.menuContainerElement) {
        this.scrollHandler = this.checkScrollPosition.bind(this);
        this.menuContainerElement.addEventListener('scroll', this.scrollHandler);
        // Verifica iniziale
        this.checkScrollPosition();
      }
    }, 500); // Aspetta che il DOM sia renderizzato

    // Subscribe to user service events
    const userServiceSubscription = this.userService.emitter
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.retriveMenu();
        });
    this.subscriptions.push(userServiceSubscription);

    // Load variants and open secondary display
    this.loadVariants();
    this.initializePortataForItems();
    this.ipcService.openSecondaryDisplay();
    this.subscribeToOrder();
    this.loadWorkstationPrinters();

    // Set up POS transaction listener
    this.posTransactionCleanup = this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        this.currentOrder.statoPagato = 0;
        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });

    // Set up Firebase auth listener
    this.authUnsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveMenu();
        const authSubscription = this.userService.emitter
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.retriveMenu();
              this.loadWorkstationPrinters()
            });
        this.subscriptions.push(authSubscription);
      }
    });

    // Select price list
    try {
      this.selectedPriceList = await this.ipcService.getSelectedPriceList();
    } catch (error) {
      this.selectedPriceList = '5Q1cRLWC2uvW8w0doJQ7';
    }
  }

  toggleCategoriesExpansion(): void {
    this.isCategoriesExpanded = !this.isCategoriesExpanded;
  }

  initializePortataForItems(): void {
    if (!this.currentOrder?.carrello) {
      return;
    }

    // Controlla tutti gli elementi nel carrello
    Object.keys(this.currentOrder.carrello).forEach(key => {
      const item = this.currentOrder.carrello[key];

      // Se l'elemento non ha la proprietà portata, imposta 0 (prima portata)
      if (!item.hasOwnProperty('portata')) {
        item.portata = 0;
      }
    });

    // Aggiorna l'ordine nel database
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

  isTable(): boolean {
    // Controlla se l'ordine è un tavolo basato sulla fonte (1 = tavolo)
    /*if (this.currentOrder?.fonte === 2) {
      console.log('è un tavolo a casissimo');
      return true;
    }

     */

    // Oppure controlla se il displayName inizia con "Tavolo" o "Tav."
    if (this.currentOrder?.displayName) {
      //console.log('è un tavolo display name');
      const name = this.currentOrder.displayName.toLowerCase();
      return name.startsWith('tavolo') || name.startsWith('tav.');
    }

    return false;
  }

  isTakeaway(): boolean {
    // Controlla se l'ordine è un tavolo basato sulla fonte (1 = tavolo)
    /*if (this.currentOrder?.fonte === 2) {
      console.log('è un tavolo a casissimo');
      return true;
    }

     */

    // Oppure controlla se il displayName inizia con "Tavolo" o "Tav."
    if (this.currentOrder?.displayName) {
      //console.log('è un tavolo display name');
      const name = this.currentOrder.displayName.toLowerCase();
      return name.startsWith('asporto') ;
    }

    return false;
  }

  // Imposta la portata selezionata
  setPortata(portataIndex: number) {
    this.selectedPortata = portataIndex;
  }


// Verifica se ci sono già coperti nel carrello
  private hasCoverItem(): boolean {
    if (!this.currentOrder?.carrello) return false;

    return Object.values(this.currentOrder.carrello).some((item: any) =>
        item.title === 'Coperto' || item.title === 'Coperti'
    );
  }


  // Apre il dialog per inserire il numero di coperti
  promptForCoverCount(): void {
    const dialogRef = this.dialog.open(CoverDialogComponent, {
      width: '360px',
      panelClass: 'cover-dialog-container',
      disableClose: true,
      data: {
        tableName: this.currentOrder?.displayName || 'Tavolo',
        orderId: this.currentOrder?.id // Passa l'ID dell'ordine al dialog
      }
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result === 'deleted') {
        // Se il risultato è 'deleted', dobbiamo eliminare l'ordine
        try {
          const orderToDelete = {
            ...this.currentOrder,
            statoPagato: -1,
            motivazione: 'nessuna motivazione richiesta',
            dataEliminazione: new Date().toISOString()
          };

          this.commonService.updateOrdine(this.currentOrder.id, orderToDelete);
          this.router.navigate(['/cassa'], {fragment: 'nonav'});
        } catch (error) {
          console.error('Errore nell\'eliminazione dell\'ordine:', error);
        }
      } else if (result && result > 0) {
        // Altrimenti, se ha inserito il numero di coperti, li aggiungiamo all'ordine
        this.addCoversToOrder(result);
      }
    });
    this.subscriptions.push(dialogSubscription);
  }

// Aggiunge i coperti all'ordine
  private addCoversToOrder(count: number): void {
    if (!this.currentOrder?.carrello) {
      this.currentOrder.carrello = {};
    }

    if (!this.currentOrder.ordineInserimento) {
      this.currentOrder.ordineInserimento = [];
    }

    const copertId = 'Coperti';

    // Crea l'item coperti
    this.currentOrder.carrello[copertId] = {
      title: 'Coperti',
      category: 'Servizio',
      image_link: 'assets/icons/icons8-account-96.png',
      price: 0,
      prezzo: 2,
      quantita: count,
      portata: 0
    };

    // Aggiungi all'ordine di inserimento se non esiste già
    if (!this.currentOrder.ordineInserimento.includes(copertId)) {
      this.currentOrder.ordineInserimento.push(copertId);
    }

    // Salva l'ordine aggiornato
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
    this.ipcService.updateSecondaryDisplay(this.currentOrder);
  }



  // Sottoscrizione all'ordine corrente

  private subscribeToOrder(): void {
    // Reset del flag quando iniziamo una nuova sottoscrizione
    this.coverDialogShown = false;

    const orderId = this.route.snapshot.paramMap.get('id');
    if (orderId) {
      this.orderSubscription = this.commonService.getOrderById(orderId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(order => {
            this.currentOrder = order.payload.data() || {};
            this.currentOrder.carrello = this.currentOrder.carrello || {};
            this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento || [];

            // AGGIUNGI QUESTO BLOCCO:
            // Imposta orderType in base al valore memorizzato nell'ordine o inferito dal displayName
            if (this.currentOrder.orderType) {
              // Se l'orderType è già salvato nell'ordine, usalo
              this.orderType = this.currentOrder.orderType;
            } else {
              // Altrimenti prova a inferirlo dal displayName
              if (this.currentOrder.displayName) {
                const lowerDisplayName = this.currentOrder.displayName.toLowerCase();
                if (lowerDisplayName.includes('asporto')) {
                  this.orderType = 'takeaway';
                } else {
                  // Default è 'counter' (Da Banco)
                  this.orderType = 'counter';
                }
              }

              // Salva il tipo inferito nell'ordine
              this.currentOrder.orderType = this.orderType;
              this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
            }

            this.ipcService.updateSecondaryDisplay(this.currentOrder);

            // Controlla se è un tavolo e non ha ancora i coperti
            // Utilizza il flag per aprire il dialog solo una volta
            if (!this.coverDialogShown && this.isTable() && !this.hasCoverItem()) {
              // Imposta il flag a true prima di aprire il dialog
              this.coverDialogShown = true;

              // Attendiamo un po' per evitare problemi di rendering
              setTimeout(() => {
                this.promptForCoverCount();
              }, 500);
            }
          });

      // Add to subscription array for cleanup
      if (this.orderSubscription) {
        this.subscriptions.push(this.orderSubscription);
      }
    }
  }


  // Aggiungi questo metodo
  setOrderType(type: string): void {
    this.orderType = type;

    // Aggiorna l'ordine corrente solo se esiste
    if (this.currentOrder) {
      // Salva il tipo di ordine
      this.currentOrder.orderType = type;

      // Estrai il numero dell'ordine dal displayName attuale
      let orderNumber = '';
      if (this.currentOrder.displayName) {
        // Cerca il pattern #numero nel displayName
        const match = this.currentOrder.displayName.match(/#(\d+)/);
        if (match && match[1]) {
          orderNumber = match[1];
        }
      }

      // Se non troviamo un numero nel displayName, usa la proprietà ordine
      if (!orderNumber && this.currentOrder.ordine) {
        orderNumber = this.currentOrder.ordine.toString();
      }

      // Aggiorna il displayName in base al tipo di ordine
      if (orderNumber) {
        if (type === 'counter') {
          this.currentOrder.displayName = `Banco #${orderNumber}`;
        } else if (type === 'takeaway') {
          this.currentOrder.displayName = `Asporto #${orderNumber}`;
        }

        // Aggiorna l'ordine nel database
        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);

        // Aggiorna il display secondario
        this.ipcService.updateSecondaryDisplay(this.currentOrder);
      }
    }
  }

  // Funzione per trovare e aggiungere prodotto tramite barcode
  private findAndAddProductByBarcode(barcode: string) {
    if (!barcode || barcode.length < 3) return; // Ignora sequenze troppo corte

    console.log('Cercando prodotto con barcode:', barcode);

    // Cerca il prodotto nel menu con il barcode corrispondente
    const product = this.menuBello.find(item => item.barcode === barcode);

    if (product) {
      // Se il prodotto viene trovato, aggiungilo all'ordine
      this.ngZone.run(() => {
        this.updateOrdine(product);
        // Opzionale: feedback sonoro o visuale
        // this.playBeepSound(); // Se vuoi aggiungere un feedback sonoro
      });
    } else {
      console.log('Prodotto non trovato per il barcode:', barcode);
    }
  }

  // Gestione dello scroll
  checkScrollPosition(): void {
    if (!this.menuContainerElement) return;

    const {scrollTop, scrollHeight, clientHeight} = this.menuContainerElement;

    // Mostra i pulsanti solo se c'è contenuto che può scorrere
    this.showScrollButtons = scrollHeight > clientHeight;

    // Gestire la visibilità individuale dei pulsanti se necessario
    // Esempio: mostra il pulsante su solo se non sei all'inizio, ecc.
  }

  scrollUp(): void {
    const containers = document.getElementsByClassName('menu-items-container');
    if (containers.length > 0) {
      for (let i = 0; i < containers.length; i++) {
        if (containers[i]['offsetParent'] !== null) { // Verifica se è visibile
          containers[i].scrollTop -= 300;
          break;
        }
      }
    }
  }

  scrollDown(): void {
    const containers = document.getElementsByClassName('menu-items-container');
    if (containers.length > 0) {
      for (let i = 0; i < containers.length; i++) {
        if (containers[i]['offsetParent'] !== null) { // Verifica se è visibile
          containers[i].scrollTop += 300;
          break;
        }
      }
    }
  }

  isPagerRemoved(pagerId: string): boolean {
    return !!this.lastPagerRemoval[pagerId];
  }

  getLastRemovalTime(pagerId: string): string | null {
    return this.lastPagerRemoval[pagerId] || null;
  }

  // Nel tuo componente
  async callPager(pagerId: number) {
    try {
      await this.ipcService.callPager(pagerId);
      console.log('Pager chiamato con successo');
    } catch (error) {
      console.error('Errore nella chiamata del pager:', error);
    }
  }

  onSearchChange(): void {
    if (!this.searchQuery || this.searchQuery.trim() === '') {
      this.searchResults = [];
      return;
    }

    const query = this.searchQuery.toLowerCase().trim();

    // Raccogli tutti i prodotti da tutte le categorie
    let allProducts: any[] = [];
    this.menu.forEach(category => {
      if (category.menu && category.menu.length > 0) {
        allProducts = [...allProducts, ...category.menu];
      }
    });

    // Filtra i prodotti in base alla query di ricerca
    this.searchResults = allProducts.filter(item => {
      // Filtra per titolo
      const matchesTitle = item.title && item.title.toLowerCase().includes(query);

      // Filtra per categoria
      const matchesCategory = item.category && item.category.toLowerCase().includes(query);

      // Filtra per descrizione se presente
      const matchesDescription = item.description && item.description.toLowerCase().includes(query);

      // Filtra per prezzo (se la query è un numero)
      let matchesPrice = false;
      if (!isNaN(parseFloat(query))) {
        const queryPrice = parseFloat(query);
        const itemPrice = this.getPriceFromPriceList(item);
        matchesPrice = itemPrice && itemPrice.toString().includes(query);
      }

      return matchesTitle || matchesCategory || matchesDescription || matchesPrice;
    })
        // Filtra solo i prodotti disponibili nel listino prezzi
        .filter(item => this.isPiattoInPriceList(item));
  }

  // Caricamento delle varianti
  private async loadVariants() {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    try {
      const doc = await variantiRef.get();
      if (doc.exists) {
        this.allVariants = doc.data()?.variants || [];
      }
    } catch (error) {
      console.error('Errore nel caricare le varianti:', error);
    }
  }

  private async retriveMenu(): Promise<void> {
    const db = firebase.firestore();
    const restaurantName = await this.dataServiceGenerico.getCurrentRestaurantId();
    const defaultImageUrl = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FFillApp%20solo%20logo%20grigio.png?alt=media&token=6105b54c-35c8-4c16-818c-2cb5548e9f94';

    try {
      // Carica prima i preferiti per mantenere l'ordine corretto
      const menuDoc = await db.collection('menu_test').doc(restaurantName).get();
      if (menuDoc.exists) {
        const data = menuDoc.data() as MenuDocument;
        this.favorites = data.favorites || [{name: 'Preferiti', items: []}];
      }

      // Carica informazioni sul listino prezzi selezionato
      const priceListDoc = await db.collection('price_lists').doc(restaurantName).get();
      const priceListData = priceListDoc.data();
      const selectedList = priceListData?.priceLists?.find(list => list.id === this.selectedPriceList);

      // Recupera l'ordine delle categorie e dei prodotti dal listino selezionato
      const orderCategories = selectedList?.orderCategories || [];
      const orderProducts = selectedList?.orderProducts || [];

      if (menuDoc.exists) {
        const data = menuDoc.data() as MenuDocument;

        this.menuBello = data.items
            .filter(elemento => elemento.disponibilita !== false)
            .map(item => ({
              ...item,
              image_link: item.image_link?.length > 0 ? item.image_link : defaultImageUrl
            }));

        // Ottieni categorie dal menu
        let categorie = this.filterUniqueCategories(this.menuBello);

        // Usa l'ordine delle categorie dal listino se disponibile, altrimenti usa l'ordine predefinito
        if (orderCategories.length > 0) {
          // Ordina le categorie in base all'ordine salvato
          categorie = this.sortArrayByOrderList(categorie, orderCategories);
          console.log('Categorie ordinate secondo il listino:', categorie);
        }

        // Crea la struttura del menu
        this.menu = categorie.map(categoria => {
          // Filtra i prodotti per categoria
          let prodottiCategoria = this.menuBello.filter(prodotto => prodotto.category === categoria);

          // Ordina i prodotti in base all'ordine salvato, se disponibile
          if (orderProducts.length > 0) {
            prodottiCategoria = this.sortArrayByOrder(prodottiCategoria, orderProducts, 'id');
            console.log(`Prodotti ordinati per categoria ${categoria}:`, prodottiCategoria.map(p => p.title));
          }

          return {
            testo: categoria,
            menu: prodottiCategoria
          };
        });

        this.initializeImagePaths();
      }
    } catch (error) {
      console.error('Errore nel recupero del menu da Firebase:', error);
    }
  }

// Metodo per ordinare un array in base a un elenco ordinato
  private sortArrayByOrderList(items: string[], orderArray: string[]): string[] {
    // Crea una mappa dell'ordine per un accesso efficiente
    const orderMap = new Map(orderArray.map((id, index) => [id, index]));

    return [...items].sort((a, b) => {
      // Se entrambi gli elementi sono nell'elenco ordinato, usa quell'ordine
      if (orderMap.has(a) && orderMap.has(b)) {
        return orderMap.get(a) - orderMap.get(b);
      }

      // Se solo un elemento è nell'elenco ordinato, esso viene prima
      if (orderMap.has(a)) return -1;
      if (orderMap.has(b)) return 1;

      // Se nessuno dei due è nell'elenco ordinato, mantieni l'ordine originale
      return 0;
    });
  }

// Metodo per ordinare elementi di un array in base a un array di ID
  private sortArrayByOrder<T>(items: T[], orderArray: string[], idField: string): T[] {
    // Crea una mappa dell'ordine per un accesso efficiente
    const orderMap = new Map(orderArray.map((id, index) => [id, index]));

    return [...items].sort((a, b) => {
      const aId = a[idField];
      const bId = b[idField];

      // Se entrambi gli elementi sono nell'elenco ordinato, usa quell'ordine
      if (orderMap.has(aId) && orderMap.has(bId)) {
        return orderMap.get(aId) - orderMap.get(bId);
      }

      // Se solo un elemento è nell'elenco ordinato, esso viene prima
      if (orderMap.has(aId)) return -1;
      if (orderMap.has(bId)) return 1;

      // Se nessuno dei due è nell'elenco ordinato, mantieni l'ordine originale
      return 0;
    });
  }

  // Inizializzazione dei path delle immagini
  private initializeImagePaths() {
    // Crea un set di URL immagine unici per evitare duplicati
    const uniqueImageUrls = new Set<string>();

    // Raccogli tutti gli URL unici delle immagini dal menu
    this.menu.forEach(categoria => {
      categoria.menu.forEach(item => {
        if (item.image_link) {
          uniqueImageUrls.add(item.image_link);
        }
      });
    });

    // Carica ogni immagine unica solo una volta
    uniqueImageUrls.forEach(imageUrl => {
      // Carica l'immagine una sola volta e memorizzala in cache
      const imagePromise = this.ipcService.getLocalImage(imageUrl);

      // Assegna la stessa promessa a tutti gli elementi del menu con lo stesso URL
      this.menu.forEach(categoria => {
        categoria.menu.forEach(item => {
          if (item.image_link === imageUrl) {
            this.imagePaths[item.title] = imagePromise;
          }
        });
      });
    });

    // Fai lo stesso per i preferiti
    if (this.favorites && this.favorites.length > 0) {
      // Trova tutti gli elementi preferiti
      const favoriteItems = this.menuBello.filter(item =>
          this.favorites.some(section =>
              section.items && section.items.includes(item.id)
          )
      );

      // Assegna le immagini già caricate agli elementi preferiti
      favoriteItems.forEach(item => {
        if (!this.imagePaths[item.title] && item.image_link) {
          // Se l'immagine non è già stata caricata, caricala
          this.imagePaths[item.title] = this.ipcService.getLocalImage(item.image_link);
        }
      });
    }
  }

  // Filtraggio delle categorie univoche
  private filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems
        .filter(item => {
          if (seenCategories.has(item.category)) {
            return false;
          } else {
            seenCategories.add(item.category);
            return true;
          }
        })
        .map(x => x.category)
        .filter(element => element !== undefined);
  }

  // Input della tastiera numerica

  onKeypadValue(event: { type: 'price' | 'quantity', value: number, createNewProduct?: boolean }) {
    if (event.type === 'quantity') {
      // Memorizza la quantità in attesa di essere applicata
      this.pendingQuantity = event.value;
      console.log(`Quantità in attesa: ${this.pendingQuantity}x`);

      // IMPORTANTE: Reset del prezzo selezionato quando si imposta una quantità
      // Questo evita che quando si seleziona "10x" venga anche impostato il prezzo a 10
      this.selectedPrice = null;

      // Non fare nient'altro, applicheremo la quantità solo quando l'utente clicca su un prodotto
      return;
    }

    // Comportamento normale per i prezzi
    if (event.type === 'price') {
      this.selectedPrice = event.value;
      this.createNewProduct = event.createNewProduct || false;
      this.selectedQuantity = null;
    } else {
      // Non impostare selectedQuantity qui, lo faremo al click
      this.selectedQuantity = null;
      this.selectedPrice = null;
    }
  }




  // Funzione per aggiornare la quantità di un prodotto
  updateProductQuantity(productKey: string, quantity: number) {
    if (!this.currentOrder?.carrello) return;

    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      // Aggiorna la quantità
      this.currentOrder.carrello[productKey].quantita = quantity;

      // Se la quantità è 0, rimuovi il prodotto
      if (quantity <= 0) {
        delete this.currentOrder.carrello[productKey];
        if (this.currentOrder.ordineInserimento) {
          this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento.filter(
              id => id !== productKey
          );
        }
      }

      // Aggiorna l'ordine immediatamente
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset dopo l'applicazione
      this.pendingQuantity = null;
    }
  }

  // Nel componente CassaOrdine.ts, modifica il metodo selectProductInCart per fermare la propagazione
  selectProductInCart(productKey: string, event?: Event) {
    // Se c'è un evento, fermiamo la propagazione
    if (event) {
      event.stopPropagation();
    }

    // Verifica se esiste un prodotto nel carrello con questa chiave
    if (this.currentOrder?.carrello?.[productKey]) {

      // Se c'è uno sconto percentuale in attesa, applicalo al prodotto
      if (this.pendingDiscountPercentage !== null) {
        console.log(`Applicazione ${this.pendingDiscountOperation === 'discount' ? 'sconto' : 'maggiorazione'} ${this.pendingDiscountPercentage}% al prodotto ${productKey}`);
        this.applyDiscountToProduct(productKey, 'percentage', this.pendingDiscountPercentage);
        this.pendingDiscountPercentage = null;
        return;
      }

      // Se c'è uno sconto in valore assoluto in attesa, applicalo al prodotto
      if (this.pendingDiscountAmount !== null) {
        console.log(`Applicazione ${this.pendingDiscountOperation === 'discount' ? 'sconto' : 'maggiorazione'} ${this.pendingDiscountAmount}€ al prodotto ${productKey}`);
        this.applyDiscountToProduct(productKey, 'amount', this.pendingDiscountAmount);
        this.pendingDiscountAmount = null;
        return;
      }

      // Se c'è una quantità in attesa, applicala al prodotto selezionato
      if (this.pendingQuantity !== null) {
        console.log(`Aggiornamento quantità prodotto ${productKey} a ${this.pendingQuantity}`);
        this.updateProductQuantity(productKey, this.pendingQuantity);
        return;
      }

      // Se c'è un prezzo selezionato, aggiorna anche il prezzo del prodotto
      if (this.selectedPrice !== null) {
        console.log(`Aggiornamento prezzo prodotto ${productKey} a ${this.selectedPrice}`);

        // Se deve creare un nuovo prodotto invece di aggiornare quello esistente
        if (this.createNewProduct) {
          console.log(`Creazione nuovo prodotto con prezzo ${this.selectedPrice}`);
          this.createProductWithNewPrice(productKey, this.selectedPrice);
        } else {
          this.updateProductPrice(productKey, this.selectedPrice);
        }

        return;
      }
    }
  }


  createProductWithNewPrice(originalProductKey: string, newPrice: number) {
    if (!this.currentOrder?.carrello || !this.currentOrder.carrello[originalProductKey]) return;

    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      // Copia il prodotto originale
      const originalProduct = this.currentOrder.carrello[originalProductKey];

      // Crea un timestamp per rendere l'ID univoco
      const timestamp = Date.now();
      const newProductKey = `${originalProductKey}_price${newPrice}_${timestamp}`;

      // Crea il nuovo prodotto con il nuovo prezzo
      this.currentOrder.carrello[newProductKey] = {
        ...originalProduct,
        prezzo: newPrice,
        price: newPrice,
        quantita: 1 // Inizia con quantità 1
      };

      // Riduci la quantità del prodotto originale di 1
      originalProduct.quantita -= 1;

      // Se la quantità originale arriva a 0, rimuovi il prodotto
      if (originalProduct.quantita <= 0) {
        delete this.currentOrder.carrello[originalProductKey];
        if (this.currentOrder.ordineInserimento) {
          this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento.filter(
              id => id !== originalProductKey
          );
        }
      }

      // Aggiungi il nuovo prodotto all'ordine di inserimento
      if (!this.currentOrder.ordineInserimento) {
        this.currentOrder.ordineInserimento = [];
      }
      this.currentOrder.ordineInserimento.push(newProductKey);

      // Aggiorna l'ordine immediatamente
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset dopo l'applicazione
      this.selectedPrice = null;
      this.createNewProduct = false;
    }
  }


  applyDiscountToProduct(productKey: string, discountType: 'percentage' | 'amount', value: number) {
    if (!this.currentOrder?.carrello || !this.currentOrder.carrello[productKey]) return;

    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      const product = this.currentOrder.carrello[productKey];
      const productTotal = product.prezzo * product.quantita;

      // Calcola il valore dello sconto
      let discountAmount: number;
      let discountLabel: string;
      const operationText = this.pendingDiscountOperation === 'discount' ? 'Sconto' : 'Maggiorazione';

      if (discountType === 'percentage') {
        // Calcola lo sconto come percentuale del totale del prodotto
        discountAmount = (value / 100) * productTotal;
        discountLabel = `${operationText} ${value}% su ${product.title}`;
      } else {
        // Sconto in valore assoluto
        discountAmount = value;
        discountLabel = `${operationText} ${value.toFixed(2)}€ su ${product.title}`;
      }

      // Se è una maggiorazione, invertiamo il segno
      if (this.pendingDiscountOperation === 'increase') {
        discountAmount = -discountAmount; // Negativo per le maggiorazioni (sarà visualizzato come positivo)
      }

      // Arrotonda a due decimali
      discountAmount = Math.round(discountAmount * 100) / 100;

      // Verifica che lo sconto non superi il totale del prodotto
      if (this.pendingDiscountOperation === 'discount' && Math.abs(discountAmount) > productTotal) {
        console.warn('Lo sconto non può superare il totale del prodotto');
        return;
      }

      // Crea un nome univoco per il prodotto sconto
      const timestamp = Date.now();
      const uniqueId = `${operationText}_${productKey}_${timestamp}`;

      // MODIFICA QUI: Usa il vatId del prodotto originale invece di '4' (IVA 0%)
      const vatId = product.vatId || '1'; // Usa vatId del prodotto originale o '1' come fallback

      // Crea il prodotto sconto con prezzo negativo (o positivo per le maggiorazioni)
      this.currentOrder.carrello[uniqueId] = {
        title: discountLabel,
        image_link: this.pendingDiscountOperation === 'discount' ? 'assets/icons/icons8-discount-60.png' : 'assets/icons/icons8-increase-60.png',
        category: this.pendingDiscountOperation === 'discount' ? 'Sconto' : 'Maggiorazione',
        price: -discountAmount, // Prezzo negativo per rappresentare lo sconto, positivo per maggiorazioni
        prezzo: -discountAmount,
        quantita: 1,
        portata: product.portata || (this.portata == 4 ? 0 : this.portata),
        vatId: vatId, // Usa il vatId del prodotto originale
        relatedProductKey: productKey // Riferimento al prodotto originale
      };

      // Aggiungi alla lista di inserimento
      this.currentOrder.ordineInserimento.push(uniqueId);

      // Salva le modifiche
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset
      this.pendingDiscountPercentage = null;
      this.pendingDiscountAmount = null;
      this.pendingDiscountOperation = null;
      this.resetCounter++;
    }
  }


  updateProductPrice(productKey: string, price: number) {
    if (!this.currentOrder?.carrello) return;

    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      // Aggiorna il prezzo
      this.currentOrder.carrello[productKey].prezzo = price;
      this.currentOrder.carrello[productKey].price = price;

      // Aggiorna l'ordine immediatamente
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset dopo l'applicazione
      this.selectedPrice = null;
    }
  }


  // Calcolo del prezzo di un piatto
  getPrezzoPiatto(piatto) {
    const priceListPrice = piatto?.prezzo;
    const basePrice = priceListPrice !== undefined ? priceListPrice : (piatto?.prezzo || piatto?.price || 0);

    if (piatto?.hasOwnProperty('aggiunte')) {
      return basePrice + piatto?.aggiunte.length;
    }
    return basePrice;
  }

  // Verifica delle varianti obbligatorie
  hasRequiredVariants(piatto: any): boolean {
    if (!piatto?.variantIds || piatto.variantIds.length === 0) {
      return false;
    }

    if (!this.allVariants) {
      return false;
    }

    const piattoVariants = this.allVariants.filter(v => piatto.variantIds.includes(v.id));
    return piattoVariants.some(v => v.isRequired);
  }

  // Apertura dialogo delle varianti
  apriVarianti(piatto, productKey?: string) {
    const isEdit = productKey !== undefined;

    if (piatto.variantIds != undefined && piatto.variantIds.length > 0) {
      this.openDialog(
          piatto,
          this.currentOrder,
          this.portata,
          productKey,
          isEdit
      );
    }
  }

  // Apertura dialogo generico
  openDialog(piatto?: any, currentOrder?: any, portata?: any, productKey?: string, isEdit: boolean = false, seleziona_singolo: boolean = false): any {
    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto,
        height: '70vh',
        isEdit: isEdit,
        productKey: productKey
      }
    }

    if (seleziona_singolo) {
      const modalRef = this.dialog.open(SelezionaVarianti, this.modalOptions);
      modalRef.componentInstance.user = this.user;
      modalRef.componentInstance.currentOrder = currentOrder;
      modalRef.componentInstance.portata = portata;
      modalRef.componentInstance.piattoRicevuto = piatto;
      return modalRef;
    } else {
      const modalRef = this.dialog.open(SelezionaVariantiSingoloComponent, this.modalOptions);
      modalRef.componentInstance.user = this.user;
      modalRef.componentInstance.currentOrder = currentOrder;
      modalRef.componentInstance.portata = portata;
      modalRef.componentInstance.piattoRicevuto = piatto;
      return modalRef;
    }
  }

  // Apertura dialogo pagamento
  openDialogPagamento(): any {
    // Get permission status for Preconto but don't prevent dialog from opening
    const hasPrecontoPermission = this.hasPermission('Preconto');
    console.log("hasPermissionPreconto: ", hasPrecontoPermission)
    
    let filteredCarrello = {};

    if (this.currentOrder?.carrello) {
      Object.keys(this.currentOrder.carrello).forEach(key => {
        const keyWithoutSpaces = key.replace(/ /g, '');
        const item = this.currentOrder.carrello[key];

        const paidQuantity = item.isPaidQuantity || 0;
        const remainingQuantity = item.quantita - paidQuantity;

        if (remainingQuantity > 0) {
          filteredCarrello[keyWithoutSpaces] = {
            ...item,
            quantita: remainingQuantity,
            originalQuantity: item.quantita,
            isPaidQuantity: paidQuantity
          };
        }
      });
    }

    this.modalOptions.data = {
      ordine: this.currentOrder.ordine,
      hasPrecontoPermission: hasPrecontoPermission
    };
    
    const modalRef = this.dialog.open(DialogPagamento, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.camerieri = this.currentOrder;
    modalRef.componentInstance.carrello = filteredCarrello;

    // Subscribe to dialog close event and add to subscription list
    const dialogSubscription = modalRef.afterClosed().subscribe(async result => {
      if (result === 'completo') {
        if(this.currentOrder.tavolo) {
          this.router.navigate(['cassa'],{fragment: '#nonav'})
        }else if (this.isTakeaway()){
          //await this.createNewOrder();
          this.selectedPortata = 4;
          this.inviaPortataSelezionata();
          this.router.navigate(['cassa'],{fragment: '#nonav'})
        }

      }
    });
    this.subscriptions.push(dialogSubscription);

    return modalRef;
  }

  private async createNewOrder() {
    const current = new Date();
    const timestamp = current.getTime();
    const psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        await this.dataServiceGenerico.getCurrentRestaurantId()
    );

    const newOrder = {
      ristorante: await this.dataServiceGenerico.getCurrentRestaurantId(),
      id: 'c1' + psw,
      displayName: `Banco #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      selectedPriceListType: 'counter'
    };

    try {
      // Aspetta che l'ordine sia creato
      await this.commonService.updateOrdine(newOrder.id, newOrder);

      // Prima naviga a una route temporanea (questo forza il reset del componente)
      await this.router.navigate(['/cassa'], {fragment: 'nonav'});

      // Poi naviga alla route dell'ordine
      await this.router.navigate(['/cassa/order', newOrder.id], {
        fragment: 'nonav',
        queryParamsHandling: 'preserve'
      });

      // Reset manuale delle variabili di stato
      this.currentOrder = null;
      this.selectedPrice = null;
      this.selectedQuantity = null;
      this.resetCounter = 0;

      // Forza il refresh dei dati
      this.subscribeToOrder();

    } catch (error) {
      console.error('Errore durante la creazione del nuovo ordine:', error);
    }
  }

  // Apertura dialogo Fidelity
  async openDialogFidelity(): Promise<void> {
    const idRistorante = await this.dataServiceGenerico.getCurrentRestaurantId();
    const dialogRef = this.dialog.open(Fidelity, {
      panelClass: 'custom-dialog-container',
      data: {
        restaurantId: this.dataServiceGenerico.getRistorante()
      }
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result && result.user) {
        this.currentOrder.userId = result.user.id;

        if (result.carrelloItem) {
          const itemId = result.carrelloItem.id.replace(/ /g, '');
          this.currentOrder.carrello[itemId] = result.carrelloItem;
          this.currentOrder.ordineInserimento.push(itemId);
        }

        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });
    this.subscriptions.push(dialogSubscription);
  }

  // Calcolo della quantità di un prodotto
  getQuantita(prodotto: any) {
    if (!this.currentOrder?.carrello) {
      return 0;
    }

    const baseName = prodotto.title.replace(/ /g, '');
    let totalQuantity = 0;

    Object.keys(this.currentOrder.carrello).forEach(key => {
      if (key === baseName || key.startsWith(`${baseName}_`)) {
        totalQuantity += this.currentOrder.carrello[key].quantita;
      }
    });

    return totalQuantity;
  }

  // Gestione dell'eliminazione di un elemento
  handleDeleteItem(piatto: string) {
    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      if (this.currentOrder?.carrello[piatto]) {
        delete this.currentOrder.carrello[piatto];

        if (this.currentOrder.ordineInserimento) {
          this.currentOrder.ordineInserimento = this.currentOrder.ordineInserimento.filter(
              item => item !== piatto
          );
        }

        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
        this.ipcService.updateSecondaryDisplay(this.currentOrder);
      }
    }
  }

  // Gestione dell'evento di modifica quantità
  handleEvent(event: number, piatto: any, piatto2?: string) {
    if (this.currentOrder?.statoPagato !== undefined ? this.currentOrder.statoPagato != 0 : true) {
      const nomePiatto = piatto2 || '';

      // Salva il prezzo attuale prima di qualsiasi modifica
      const currentPrice = this.currentOrder.carrello[nomePiatto].prezzo;

      // Aggiorna la quantità
      this.currentOrder.carrello[nomePiatto].quantita = event;

      if (this.currentOrder.carrello[nomePiatto].quantita == 0) {
        delete this.currentOrder.carrello[nomePiatto];
        this.currentOrder['ordineInserimento'] = this.currentOrder['ordineInserimento'].filter(item => item !== nomePiatto);
      } else {
        // Assicurati che il prezzo venga mantenuto
        this.currentOrder.carrello[nomePiatto].prezzo = currentPrice;
        this.currentOrder.carrello[nomePiatto].price = currentPrice;
      }
    }
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
    this.ipcService.updateSecondaryDisplay(this.currentOrder);
  }

  async updateOrdine(piatto) {
    if (!this.currentOrder?.statoPagato || (this.currentOrder.statoPagato !== 0 && this.currentOrder.statoPagato !== 3)) {
      const shouldUseKeypadPrice = this.selectedPrice !== null;

      // Usa pendingQuantity se disponibile
      const quantity = this.pendingQuantity !== null ? this.pendingQuantity : 1;

      const priceListPrices = piatto.priceListPrices?.[this.selectedPriceList];
      let basePrice = shouldUseKeypadPrice ? this.selectedPrice :
          (priceListPrices?.counter?.price || +piatto.price);
      let vatId = priceListPrices?.counter?.vatId || '2';

      if (piatto.variantIds?.length > 0) {
        const db = firebase.firestore();
        const restaurantName = this.dataServiceGenerico.getRistorante();
        const variantiRef = db.collection('varianti').doc(restaurantName);

        try {
          const doc = await variantiRef.get();
          if (doc.exists) {
            const allVariants = doc.data()?.variants || [];
            const piattoVariants = allVariants.filter(v => piatto.variantIds.includes(v.id));
            const hasRequiredVariants = piattoVariants.some(v => v.isRequired);

            if (hasRequiredVariants) {
              this.apriVarianti(piatto);
            } else {
              this.addToCartWithoutVariants(piatto, basePrice, vatId, quantity);
            }
          }
        } catch (error) {
          console.error('Errore nel caricare le varianti:', error);
        }
      } else {
        this.addToCartWithoutVariants(piatto, basePrice, vatId, quantity);
      }

      // Reset dei valori dopo aver aggiunto il prodotto
      this.pendingQuantity = null;
      this.selectedQuantity = null;

      this.ipcService.updateSecondaryDisplay(this.currentOrder);
    }
  }



  private addToCartWithoutVariants(piatto, basePrice, vatId, quantity = 1) {
    const nome = piatto.title.replace(/ /g, '');
    let priceListType = this.currentOrder?.selectedPriceListType || 'counter';
    if(this.isTable()){
      priceListType = this.currentOrder?.selectedPriceListType || 'table';
    }
    if(this.isTakeaway()){
      priceListType =  'takeaway';
    }
    if(this.currentOrder.statoPagato != 0 && this.currentOrder.statoPagato != 3){
      // ID base per il prodotto
      const baseId = this.selectedPrice ? `${nome}_p${this.selectedPrice}` : nome;

      // Creiamo un ID univoco solo se necessario (dopo aver verificato varianti)
      let uniqueId = baseId;

      if (!this.currentOrder.carrello) {
        this.currentOrder.carrello = {};
      }
      if (!this.currentOrder.ordineInserimento) {
        this.currentOrder.ordineInserimento = [];
      }

      const priceListData = piatto.priceListPrices?.[this.selectedPriceList]?.[priceListType];
      const finalPrice = this.selectedPrice || (priceListData?.price ?? basePrice);
      const finalVatId = priceListData?.vatId ?? vatId;

      // Verifica se il prodotto è già nel carrello con le stesse varianti E lo stesso prezzo
      const existingItemKey = this.findMatchingProductKeyWithPrice(piatto, baseId, finalPrice);

      if (existingItemKey) {
        // Se esiste già un prodotto identico (stesso nome, stesse varianti e stesso prezzo), aumenta la quantità
        this.currentOrder.carrello[existingItemKey].quantita += quantity;
      } else {
        // Altrimenti, crea un ID univoco per il nuovo prodotto
        uniqueId = `${baseId}_${Date.now()}`;

        // Aggiungi come nuovo prodotto
        this.currentOrder.carrello[uniqueId] = {
          ...piatto,
          quantita: quantity,
          price: finalPrice,
          prezzo: finalPrice,
          portata: this.selectedPortata === 4 ? 0 : this.selectedPortata,
          vatId: finalVatId
        };
        this.currentOrder.ordineInserimento.push(uniqueId);
      }

      this.selectedPrice = null;
      this.selectedQuantity = null;
      this.resetCounter++;

      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.scrollCartToBottom();
    }

  }

  private scrollCartToBottom() {
    // Get or refresh the order items container reference
    if (!this.orderItemsContainer) {
      this.orderItemsContainer = document.querySelector('.order-items-container');
    }

    // If we found the container, scroll it to the bottom smoothly
    if (this.orderItemsContainer) {
      setTimeout(() => {
        // Current scroll position
        const startPosition = this.orderItemsContainer.scrollTop;
        // Position to scroll to
        const endPosition = this.orderItemsContainer.scrollHeight - this.orderItemsContainer.clientHeight;
        // If already at bottom, no need to animate
        if (startPosition >= endPosition) return;

        // Animation function using smooth scrolling with requestAnimationFrame
        this.smoothScrollTo(this.orderItemsContainer, startPosition, endPosition, 300); // 300ms duration
      }, 100); // Small delay to ensure the DOM has updated
    }
  }

  private smoothScrollTo(element: HTMLElement, start: number, end: number, duration: number) {
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;

      // Calculate next scroll position using easeOutQuad
      const nextScrollPosition = this.easeOutQuad(elapsedTime, start, end - start, duration);

      // Apply the new scroll position
      element.scrollTop = nextScrollPosition;

      // Continue animation if duration hasn't elapsed
      if (elapsedTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    // Start the animation
    requestAnimationFrame(animateScroll);
  }

// Easing function for smooth deceleration
  private easeOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d;
    return -c * t * (t - 2) + b;
  }

// Metodo per trovare un prodotto esistente con le stesse varianti E lo stesso prezzo
  private findMatchingProductKeyWithPrice(piatto, baseId, price): string | null {
    if (!this.currentOrder?.carrello) return null;

    // Controllo se esiste già un prodotto con questo titolo e prezzo
    return Object.keys(this.currentOrder.carrello).find(key => {
      const item = this.currentOrder.carrello[key];

      // Verifica che sia lo stesso prodotto base (titolo)
      if (item.title !== piatto.title) {
        return false;
      }

      // Verifica che il prezzo sia lo stesso
      if (item.prezzo !== price) {
        return false;
      }

      // Se il prodotto ha un nome personalizzato, consideriamolo diverso
      if (item.customName || item.titleChanged) {
        return false;
      }

      // Controlla se entrambi hanno o non hanno varianti
      const itemHasVariants = item.variants && item.variants.length > 0;
      const newItemHasVariants = piatto.variants && piatto.variants.length > 0;

      if (itemHasVariants !== newItemHasVariants) {
        return false; // Uno ha varianti e l'altro no
      }

      // Se entrambi hanno varianti, confrontale
      if (itemHasVariants && newItemHasVariants) {
        // Se il numero di varianti è diverso, consideriamoli diversi
        if (item.variants.length !== piatto.variants.length) {
          return false;
        }

        // Confronta ogni variante
        for (let i = 0; i < item.variants.length; i++) {
          if (item.variants[i].name !== piatto.variants[i].name) {
            return false; // Varianti diverse
          }
        }
      }

      // Controlla aggiunte
      const itemHasAggiunte = item.aggiunte && item.aggiunte.length > 0;
      const newItemHasAggiunte = piatto.aggiunte && piatto.aggiunte.length > 0;

      if (itemHasAggiunte !== newItemHasAggiunte) {
        return false;
      }

      if (itemHasAggiunte && newItemHasAggiunte) {
        if (item.aggiunte.length !== piatto.aggiunte.length) {
          return false;
        }

        for (let i = 0; i < item.aggiunte.length; i++) {
          const aggiunta1 = typeof item.aggiunte[i] === 'string' ? item.aggiunte[i] : item.aggiunte[i].name;
          const aggiunta2 = typeof piatto.aggiunte[i] === 'string' ? piatto.aggiunte[i] : piatto.aggiunte[i].name;

          if (aggiunta1 !== aggiunta2) {
            return false;
          }
        }
      }

      // Controlla rimozioni
      const itemHasRimozioni = item.rimozioni && item.rimozioni.length > 0;
      const newItemHasRimozioni = piatto.rimozioni && piatto.rimozioni.length > 0;

      if (itemHasRimozioni !== newItemHasRimozioni) {
        return false;
      }

      if (itemHasRimozioni && newItemHasRimozioni) {
        if (item.rimozioni.length !== piatto.rimozioni.length) {
          return false;
        }

        for (let i = 0; i < item.rimozioni.length; i++) {
          const rimozione1 = typeof item.rimozioni[i] === 'string' ? item.rimozioni[i] : item.rimozioni[i].name;
          const rimozione2 = typeof piatto.rimozioni[i] === 'string' ? piatto.rimozioni[i] : piatto.rimozioni[i].name;

          if (rimozione1 !== rimozione2) {
            return false;
          }
        }
      }

      // Controlla note
      const itemHasNote = item.note && item.note.trim() !== '';
      const newItemHasNote = piatto.note && piatto.note.trim() !== '';

      if (itemHasNote !== newItemHasNote) {
        return false;
      }

      if (itemHasNote && newItemHasNote && item.note !== piatto.note) {
        return false;
      }

      // Se arriva qui, significa che è lo stesso prodotto con le stesse varianti e lo stesso prezzo
      return true;
    }) || null;
  }

// Metodo per trovare un prodotto esistente con le stesse varianti
  private findMatchingProductKey(piatto, baseId): string | null {
    if (!this.currentOrder?.carrello) return null;

    // Controllo se esiste già un prodotto con questo titolo
    return Object.keys(this.currentOrder.carrello).find(key => {
      const item = this.currentOrder.carrello[key];

      // Verifica che sia lo stesso prodotto base (titolo)
      if (item.title !== piatto.title) {
        return false;
      }

      // Se il prodotto ha un nome personalizzato, consideriamolo diverso
      if (item.customName || item.titleChanged) {
        return false;
      }

      // Controlla se entrambi hanno o non hanno varianti
      const itemHasVariants = item.variants && item.variants.length > 0;
      const newItemHasVariants = piatto.variants && piatto.variants.length > 0;

      if (itemHasVariants !== newItemHasVariants) {
        return false; // Uno ha varianti e l'altro no
      }

      // Se entrambi hanno varianti, confrontale
      if (itemHasVariants && newItemHasVariants) {
        // Se il numero di varianti è diverso, consideriamoli diversi
        if (item.variants.length !== piatto.variants.length) {
          return false;
        }

        // Confronta ogni variante
        // Nota: questa è una semplificazione, potrebbe essere necessario un confronto più dettagliato
        for (let i = 0; i < item.variants.length; i++) {
          if (item.variants[i].name !== piatto.variants[i].name) {
            return false; // Varianti diverse
          }
        }
      }

      // Controlla aggiunte
      const itemHasAggiunte = item.aggiunte && item.aggiunte.length > 0;
      const newItemHasAggiunte = piatto.aggiunte && piatto.aggiunte.length > 0;

      if (itemHasAggiunte !== newItemHasAggiunte) {
        return false;
      }

      if (itemHasAggiunte && newItemHasAggiunte) {
        if (item.aggiunte.length !== piatto.aggiunte.length) {
          return false;
        }

        for (let i = 0; i < item.aggiunte.length; i++) {
          const aggiunta1 = typeof item.aggiunte[i] === 'string' ? item.aggiunte[i] : item.aggiunte[i].name;
          const aggiunta2 = typeof piatto.aggiunte[i] === 'string' ? piatto.aggiunte[i] : piatto.aggiunte[i].name;

          if (aggiunta1 !== aggiunta2) {
            return false;
          }
        }
      }

      // Controlla rimozioni
      const itemHasRimozioni = item.rimozioni && item.rimozioni.length > 0;
      const newItemHasRimozioni = piatto.rimozioni && piatto.rimozioni.length > 0;

      if (itemHasRimozioni !== newItemHasRimozioni) {
        return false;
      }

      if (itemHasRimozioni && newItemHasRimozioni) {
        if (item.rimozioni.length !== piatto.rimozioni.length) {
          return false;
        }

        for (let i = 0; i < item.rimozioni.length; i++) {
          const rimozione1 = typeof item.rimozioni[i] === 'string' ? item.rimozioni[i] : item.rimozioni[i].name;
          const rimozione2 = typeof piatto.rimozioni[i] === 'string' ? piatto.rimozioni[i] : piatto.rimozioni[i].name;

          if (rimozione1 !== rimozione2) {
            return false;
          }
        }
      }

      // Controlla note
      const itemHasNote = item.note && item.note.trim() !== '';
      const newItemHasNote = piatto.note && piatto.note.trim() !== '';

      if (itemHasNote !== newItemHasNote) {
        return false;
      }

      if (itemHasNote && newItemHasNote && item.note !== piatto.note) {
        return false;
      }

      // Se arriva qui, significa che è lo stesso prodotto con le stesse varianti
      return true;
    }) || null;
  }


  // Metodi di utilità per ottenere proprietà
  getProperties(object: any) {
    return object !== undefined ? Object.keys(object) : [];
  }

  // Ottenere proprietà per portata
  getPropertiesPortata(object: any) {
    if (object !== undefined && this.currentOrder?.ordineInserimento) {
      return this.currentOrder.ordineInserimento.filter((piatto) => {
        const piattoObj = this.currentOrder?.carrello[piatto];

        // Verifica che piattoObj esista prima di accedere alle sue proprietà
        if (!piattoObj) {
          return false;
        }

        // Se è selezionata "Tutte le portate" (selectedPortata = 4), mostra tutto
        if (this.selectedPortata === 4) {
          return true;
        }

        // Verifica se l'oggetto ha la proprietà portata e se corrisponde alla portata selezionata
        if (piattoObj.hasOwnProperty('portata')) {
          return piattoObj.portata === this.selectedPortata;
        }

        // Se l'oggetto non ha una portata specificata, consideralo come prima portata (0)
        // o puoi scegliere una logica diversa in base alle tue esigenze
        return this.selectedPortata === 0;
      });
    }
    return [];
  }

  // Ottenere il titolo dell'ordine
  getTitoloOrdine() {
    return this.currentOrder?.displayName;
  }

  // Calcolo del tempo trascorso
  getElapsedTime(orderDate: string, closureOrderTime?: string): string {
    if (!orderDate) return '00:00:00';

    const startTime = new Date(parseInt(orderDate));
    const endTime = closureOrderTime ? new Date(parseInt(closureOrderTime)) : new Date();

    const diff = endTime.getTime() - startTime.getTime();

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // Verifica dell'ultimo prodotto
  checkIfLastProduct(piatto: string, isFullDelete: boolean, eliminazione = false) {
    let totalItems = 0;

    Object.values(this.currentOrder.carrello).forEach(() => {
      totalItems += 1;
    });

    if (totalItems === 1) {
      if (eliminazione) {
        const dialogRef = this.dialog.open(DeleteOrderDialogComponent, {
          width: '400px',
          disableClose: true
        });

        const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.currentOrder.motivazione = result;
            this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
          }
        });
        this.subscriptions.push(dialogSubscription);
      } else {
        this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
      }
    } else {
      if (isFullDelete) {
        this.handleEvent(0, this.currentOrder.carrello[piatto], piatto);
      } else {
        this.handleEvent(this.currentOrder.carrello[piatto].quantita - 1, this.currentOrder.carrello[piatto], piatto);
      }
    }
  }

  // Assegnazione pager
  assignPager(): void {
    const dialogRef = this.dialog.open(PagerDialogComponent, {
      width: '400px',
      disableClose: true
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result && this.currentOrder) {
        this.ngZone.run(() => {
          this.currentOrder = {
            ...this.currentOrder,
            pager: result
          };

          this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
        });
      }
    });
    this.subscriptions.push(dialogSubscription);
  }

  // Verifica se un elemento è stato pagato
  isPaidItem(piatto: string): boolean {
    if (!this.currentOrder?.paidItems) return false;
    return this.currentOrder.paidItems.some(item => item.id === piatto);
  }

  // Calcolo del totale
  calcolaTotale() {
    let totale = 0;
    if (this.currentOrder?.carrello) {
      Object.values(this.currentOrder.carrello).forEach((item: any) => {
        totale += this.getPrezzoPiatto(item) * item.quantita;
      });
    }
    return totale;
  }

  // Metodi per il menu e i prezzi
  getPriceFromPriceList(piatto: any): string {
    if (!piatto.priceListPrices || !piatto.priceListPrices[this.selectedPriceList]) {
      return (+piatto.price || 0).toFixed(2);
    }


    let priceListType = this.currentOrder?.selectedPriceListType || 'counter';
    if(this.isTable()){
      priceListType = 'table'
    }
    if(this.isTakeaway()){
      priceListType = 'takeaway'
    }
    const priceData = piatto.priceListPrices[this.selectedPriceList][priceListType];

    if (!priceData || typeof priceData.price !== 'number') {

      return (+piatto.price || 0).toFixed(2);
    }

    return priceData.price.toFixed(2);
  }

  // Verifica se un piatto è nel price list
  isPiattoInPriceList(piatto: any): boolean {
    return piatto.priceListPrices &&
        piatto.priceListPrices[this.selectedPriceList] &&
        piatto.priceListPrices[this.selectedPriceList].counter &&
        piatto.priceListPrices[this.selectedPriceList].counter.price >= 0;
  }

  // Filtraggio degli elementi del menu
  getFilteredMenuItems(categoryMenu: any[]): any[] {
    if (!categoryMenu) return [];
    return categoryMenu.filter(piatto => this.isPiattoInPriceList(piatto));
  }

  // Verifica se una categoria ha elementi
  hasCategoryItems(categoryMenu: any[]): boolean {
    return this.getFilteredMenuItems(categoryMenu).length > 0;
  }

  getFavoriteItems(): MenuItem[] {
    if (!this.favorites || !this.menuBello || this.sezione >= 0) return [];

    // Calcola l'indice della sezione preferiti corrente dal valore negativo di sezione
    const favoriteIndex = Math.abs(this.sezione + 1);
    const currentSection = this.favorites[favoriteIndex];

    if (!currentSection) return [];

    return this.menuBello.filter(item =>
        currentSection.items.includes(item.id) &&
        this.isPiattoInPriceList(item)
    );
  }

  // Indietro alla schermata precedente
  indietro() {
    this.ipcService.updateSecondaryDisplay(undefined);
    this.router.navigate(['/cassa'], {fragment: 'nonav'});
  }

  handleProductUpdated(updatedOrder: any) {
    // L'ordine è già stato aggiornato nel database dal componente SelezionaVariantiSingoloComponent
    // Quindi potremmo aver bisogno solo di aggiornare la vista locale o ricaricare i dati

    // Aggiorna l'ordine corrente con i dati aggiornati
    this.currentOrder = updatedOrder;


  }

  handleTitleChanged(productKey: string, newTitle: string) {
    // L'ordine è già stato aggiornato in memoria
    // Dobbiamo solo salvarlo nel database
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }


  // Aggiunta nota all'ordine
  aggiungiNota(): void {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      width: '400px',
      data: {existingNote: this.currentOrder?.nota}
    });

    const dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.currentOrder.nota = result;
        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      }
    });
    this.subscriptions.push(dialogSubscription);
  }

  // Apertura tastierino
  apriTastierino(): void {
    //this.ipcService.printReceipt('', 'apriSolo', 'rch');

    this.keypadVisible = !this.keypadVisible;
    this.keypadText = 'Chiudi tastierino';

    // If we're hiding the keypad, reset any selected values
    if (!this.keypadVisible) {
      this.keypadText = 'Apri tastierino';
      this.selectedPrice = null;
      this.selectedQuantity = null;
      this.resetCounter++;
    }
  }

  apricassetto(): void {
    this.ipcService.printReceipt('', 'apriSolo', 'rch');
  }


  addGenericProductWithVat(event: { vatId: number, price: number }) {
    if (!this.currentOrder?.statoPagato || (this.currentOrder.statoPagato !== 0 && this.currentOrder.statoPagato !== 3)) {
      // Mappatura delle percentuali IVA
      const vatPercentages = {
        1: 22, // 22%
        2: 10, // 10%x
        3: 4,  // 4%
        4: 0   // 0%
      };

      // Crea un nome univoco per il prodotto generico
      const vatPercentage = vatPercentages[event.vatId] || 0;
      const productName = `Generico ${vatPercentage}%`;
      const timestamp = Date.now();
      const uniqueId = `${productName.replace(/ /g, '')}_${timestamp}`;

      if (!this.currentOrder.carrello) {
        this.currentOrder.carrello = {};
      }

      if (!this.currentOrder.ordineInserimento) {
        this.currentOrder.ordineInserimento = [];
      }

      // Crea il prodotto generico
      this.currentOrder.carrello[uniqueId] = {
        title: productName,
        image_link: '',
        category: 'Generico',
        price: event.price,
        prezzo: event.price,
        quantita: 1,
        portata: this.portata == 4 ? 0 : this.portata,
        vatId: event.vatId.toString()
      };

      // Aggiungi alla lista di inserimento
      this.currentOrder.ordineInserimento.push(uniqueId);

      // Salva le modifiche
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset della tastiera numerica
      this.selectedPrice = null;
      this.selectedQuantity = null;
      this.resetCounter++;
    }
  }


  // Eliminazione dell'ordine
  // Check if the user has a specific permission
  hasPermission(permissionName: string): boolean {
    const userLocale = this.dataServiceGenerico.getUserLocale() as any;
    if (!userLocale || !userLocale.permessi) {
      return false;
    }
    
    // Case-insensitive permission check
    return userLocale.permessi.some(
      permission => permission.toLowerCase() === permissionName.toLowerCase()
    );
  }

  eliminaOrdine(ordine: any, motivazione = false) {
    // Check if user has permission to delete orders
    if (!this.hasPermission('Elimina Ordine')) {
      console.error('User does not have permission to delete orders');
      return;
    }

    // If motivazione is false but user doesn't have permission to delete without reason
    if (!motivazione && !this.hasPermission('Elimina ordine Senza Motivazione')) {
      // Force motivazione to true to require reason
      motivazione = true;
    }
    
    if (motivazione) {
      const dialogRef = this.dialog.open(DeleteOrderDialogComponent, {
        width: '400px',
        disableClose: true
      });

      const dialogSubscription = dialogRef.afterClosed().subscribe(async result => {
        if (result) {
          const orderToDelete = {
            ...this.currentOrder,
            statoPagato: -1,
            motivazione: result,
            dataEliminazione: new Date().toISOString()
          };

          try {
            await this.commonService.updateOrdine(this.currentOrder.id, orderToDelete);
            this.router.navigate(['/cassa'], {fragment: 'nonav'});
          } catch (error) {
            console.error('Errore nell\'eliminazione dell\'ordine:', error);
          }
        }
      });
      this.subscriptions.push(dialogSubscription);
    } else {
      try {
        const orderToDelete = {
          ...this.currentOrder,
          statoPagato: -1,
          motivazione: 'nessuna motivazione richiesta',
          dataEliminazione: new Date().toISOString()
        };

        this.commonService.updateOrdine(this.currentOrder.id, orderToDelete);
        this.router.navigate(['/cassa'], {fragment: 'nonav'});
      } catch (error) {
        console.error('Errore nell\'eliminazione dell\'ordine:', error);
      }
    }
  }

  // Apertura dialogo fatturazione
  openDialogFatturazione(piatto?: any): any {
    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto
      }
    }

    const modalRef = this.dialog.open(Fatturazione, this.modalOptions);

    // Track dialog subscription
    const dialogSubscription = modalRef.afterClosed().subscribe(result => {
      // Handle fatturazione result if needed
    });
    this.subscriptions.push(dialogSubscription);

    return modalRef;
  }

  // Metodo per stampare lo scontrino
  printReceipt(cassetto: string) {
    let stringa = [];

    this.getProperties(this.currentOrder?.carrello)?.forEach(
        key => stringa.push(this.currentOrder?.carrello[key])
    );

    const scontrino = this.stampaScontrino(stringa);
    this.ipcService.printReceipt(scontrino, cassetto);

    if (cassetto != 'apriSolo' && cassetto != 'nonAprire') {
      this.currentOrder.statoPagato = 1;
    }
    if (cassetto == 'nonAprire') {
      this.currentOrder.statoPagato = 0;
    }

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

  // Metodo per generare lo scontrino
  stampaScontrino(menu: any[]): string {
    const intestazione = 'GELATO SAN LORENZO SRL\n' +
        'VIA TIBURTINA 6\n' +
        '00185 ROMA\n' +
        'P.IVA 15873301004\n \n                   DOCUMENTO GESTIONALE\n \n';

    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28;
    const spaceBetweenFields = ' '.repeat(2);

    let totale = 0;

    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength);
      const quantita = item.quantita.toString().padStart(3);
      const prezzo = totalePrezzo.toFixed(2).padStart(6);
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%';

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `
      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------
      
      
      
         GRAZIE ED ARRIVEDERCI
  `;

    return header + scontrino + footer;
  }


  // Aggiungi questo metodo nella classe CassaOrdine in cassa-ordine.ts

// Aggiungi questa proprietà alla classe CassaOrdine
  workstationPrinters: { name: string, ipAddress: string }[] = [];

  /**
   * Carica le stampanti disponibili dal database
   * Questo metodo deve essere chiamato in ngOnInit
   */
  loadWorkstationPrinters(): void {
    const restaurantId = this.dataServiceGenerico.getRistorante();

    firebase.firestore()
        .collection('workstations')
        .where('ristoranteId', '==', restaurantId)
        .get()
        .then(snapshot => {
          const printers = [];

          snapshot.forEach(doc => {
            const workstation = doc.data();
            const workstationName = workstation.name || 'Workstation';

            if (workstation.printers && Array.isArray(workstation.printers)) {
              workstation.printers.forEach(printer => {
                if (printer.name && printer.ipAddress) {
                  printers.push({
                    name: printer.name,
                    ipAddress: printer.ipAddress,
                    workstation: workstationName
                  });
                }
              });
            }
          });

          this.workstationPrinters = printers;
          console.log('Stampanti caricate:', this.workstationPrinters);
        })
        .catch(error => {
          console.error('Errore nel caricamento delle stampanti:', error);
        });
  }

  /**
   * Ottiene l'indirizzo IP di una stampante dal suo nome
   * @param printerName Nome della stampante
   * @returns Indirizzo IP della stampante o undefined se non trovata
   */
  getPrinterIpAddress(printerName: string): string | undefined {
    if (printerName === 'nessuna') return undefined;

    const printer = this.workstationPrinters.find(p => p.name === printerName);
    return printer?.ipAddress;
  }

  /**
   * Invia le comande a tutte le stampanti con gli articoli in grassetto nella stampante principale
   */
  // Modifica al metodo testComande per cambiare l'ordine di stampa
// Modifica al metodo testComande per cambiare l'ordine di stampa
// Modifica al metodo testComande per cambiare l'ordine di stampa
// Trova questo metodo nella classe CassaOrdine

  // Modifica al metodo testComande per cambiare l'ordine di stampa
// Trova questo metodo nella classe CassaOrdine

  testComande(portataFilter?: number, onlyNew: boolean = true): void {
    if (!this.currentOrder?.carrello) {
      console.log('Nessuna comanda da inviare: carrello vuoto');
      return;
    }

    console.log('Invio comanda:', this.currentOrder);

    // Ottiene tutte le voci del carrello
    const allItems = [];

    // Utilizza ordineInserimento se disponibile per mantenere l'ordine
    if (this.currentOrder.ordineInserimento) {
      this.currentOrder.ordineInserimento.forEach(key => {
        if (this.currentOrder.carrello[key]) {
          const item = this.currentOrder.carrello[key];

          // Esclude i coperti dalla stampa
          if (item.title === 'Coperti' || item.title === 'Coperto') {
            return;
          }

          // Filtra per portata se specificato
          if (portataFilter !== undefined && item.portata !== portataFilter && portataFilter !== 4) {
            return;
          }

          // Filtra per elementi non ancora stampati o parzialmente stampati
          if (onlyNew) {
            const quantityPrinted = item.quantityPrinted || 0;
            if (quantityPrinted >= item.quantita) {
              return; // Salta gli elementi già completamente stampati
            }
          }

          allItems.push({
            ...item,
            key: key // Salva la chiave per riferimenti futuri
          });
        }
      });
    } else {
      // Fallback se ordineInserimento non è disponibile
      Object.keys(this.currentOrder.carrello).forEach(key => {
        const item = this.currentOrder.carrello[key];

        // Esclude i coperti dalla stampa
        if (item.title === 'Coperti' || item.title === 'Coperto') {
          return;
        }

        // Filtra per portata se specificato
        if (portataFilter !== undefined && item.portata !== portataFilter && portataFilter !== 4) {
          return;
        }

        // Filtra per elementi non ancora stampati o parzialmente stampati
        if (onlyNew) {
          const quantityPrinted = item.quantityPrinted || 0;
          if (quantityPrinted >= item.quantita) {
            return; // Salta gli elementi già completamente stampati
          }
        }

        allItems.push({
          ...item,
          key: key
        });
      });
    }

    // Se non ci sono elementi da stampare, mostra un messaggio
    if (allItems.length === 0) {
      console.log('Nessun nuovo piatto da stampare');
      return;
    }

    // Separa gli elementi del bar dagli altri
    const barItems = allItems.filter(item => item.selectedPrintDestination === 'bar');
    const nonBarItems = allItems.filter(item => item.selectedPrintDestination !== 'bar');

    // Raccogli tutti i nomi delle stampanti utilizzate negli articoli
    const printerNames = new Set<string>();

    // Aggiungi le stampanti dai workstation printers, escludendo 'preconto'
    this.workstationPrinters.forEach(printer => {
      if (printer.name && printer.name !== 'preconto') {
        printerNames.add(printer.name);
      }
    });

    // Se non ci sono stampanti specificate, non inviare nulla
    if (printerNames.size === 0) {
      console.log('Nessuna stampante configurata per gli articoli');
      return;
    }

    // Mappa per associare i nomi delle stampanti ai loro indirizzi IP
    const printerIpMap = new Map<string, string>();

    // Popola la mappa con gli indirizzi IP delle stampanti
    printerNames.forEach(printerName => {
      // Escludi esplicitamente la stampante preconto
      if (printerName === 'preconto') {
        return;
      }

      const ipAddress = this.getPrinterIpAddress(printerName);
      if (ipAddress) {
        printerIpMap.set(printerName, ipAddress);
      } else {
        console.warn(`Indirizzo IP non trovato per la stampante: ${printerName}`);
      }
    });

    // Numero del tavolo o identificativo dell'ordine
    const orderIdentifier = this.currentOrder.tavolo
        ? `Tavolo: ${this.currentOrder.tavolo}`
        : `Ordine: ${this.currentOrder.ordine || this.currentOrder.displayName}`;

    // Per ogni stampante, genera e invia la comanda con tutti gli articoli
    printerIpMap.forEach((ipAddress, printerName) => {
      // Costruisci il messaggio della comanda - NUOVO ORDINE
      // Spazio vuoto per il porta comanda (4 righe vuote con un punto in alto)
      let message = `.`;
      message += '\n'; // Riga 1
      message += '\n'; // Riga 2
      message += '\n'; // Riga 3
      message += '\n'; // Riga 4

      // Poi la destinazione (stampante) in grassetto
      message += `<b>${printerName}</b>`;
      message += '\n';

      // Poi il display name in grassetto
      message += `<b>${orderIdentifier}</b>`;
      message += '\n';
      message += '\n';

      // Seleziona gli elementi appropriati per questa stampante
      const itemsToProcess = printerName === 'bar' ? barItems : nonBarItems;

      // Salta se non ci sono elementi per questa stampante
      if (itemsToProcess.length === 0) {
        return;
      }

      // Se stiamo filtrando per una portata specifica, aggiungiamolo al messaggio
      if (portataFilter !== undefined && portataFilter !== 4) {
        const portataNames = ['Prima', 'Seconda', 'Terza', 'Quarta'];
        message += `Portata: <b>${portataNames[portataFilter]}</b>` + '\n' + '\n';

        // Aggiungi gli elementi di questa portata
        this.addItemsToMessage(itemsToProcess.filter(item => item.portata === portataFilter), message, printerName);
      } else {
        // Stiamo stampando tutte le portate, dividiamole per sezione
        const portataNames = ['Prima', 'Seconda', 'Terza', 'Quarta'];

        // Per ogni portata da 0 a 3
        for (let p = 0; p < 4; p++) {
          const portataItems = itemsToProcess.filter(item => item.portata === p);

          if (portataItems.length > 0) {
            message += `<b>${portataNames[p]} PORTATA:</b>` + '\n' + '\n';

            // Aggiungi gli elementi di questa portata
            portataItems.forEach(item => {
              const itemText = this.formatItemText(item);

              // Determina se questo articolo dovrebbe essere in grassetto per questa stampante
              const isBold = item.selectedPrintDestination === printerName;

              // Usa il nuovo metodo per aggiungere il testo formattato
              message = this.addFormattedTextToMessage(message, itemText, isBold);

              // Aggiorna la quantità stampata nell'ordine
              this.updateItemPrintedQuantity(item);
            });

            message += '\n';
          }
        }
      }

      // Aggiungi la nota generale dell'ordine se presente
      if (this.currentOrder.nota && this.currentOrder.nota.trim() !== '') {
        message += '\n' + `Nota ordine: ${this.currentOrder.nota}` + '\n';
      }

      message += '--------------------' + '\n';

      console.log(`Invio comanda alla stampante ${printerName} (${ipAddress}):`, message);

      // Invia alla stampante usando l'ipcService
      this.ipcService.printComanda(message, ipAddress);
    });

    // Aggiorna l'ordine nel database dopo l'invio
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

// Metodo per avanzare alla portata successiva - anch'esso modificato per l'ordinamento
  avanzaPortata(): void {
    if (this.selectedPortata >= 0 && this.selectedPortata < 3) {
      // Prepara un messaggio per avanzare alla portata successiva
      const portataNames = ['Prima', 'Seconda', 'Terza', 'Quarta'];
      const portataCorrenteNome = portataNames[this.selectedPortata];
      const portataSuccessivaNome = portataNames[this.selectedPortata + 1];

      // Raccogli le stampanti da notificare
      const printerNames = new Set<string>();

      // Aggiungi tutte le stampanti configurate
      this.workstationPrinters.forEach(printer => {
        printerNames.add(printer.name);
      });

      // Mappa per associare i nomi delle stampanti ai loro indirizzi IP
      const printerIpMap = new Map<string, string>();

      // Popola la mappa con gli indirizzi IP delle stampanti
      printerNames.forEach(printerName => {
        const ipAddress = this.getPrinterIpAddress(printerName);
        if (ipAddress) {
          printerIpMap.set(printerName, ipAddress);
        }
      });

      // Identifica l'ordine
      const orderIdentifier = this.currentOrder.tavolo
          ? `Tavolo: ${this.currentOrder.tavolo}`
          : `Ordine: ${this.currentOrder.ordine || this.currentOrder.displayName}`;

      // Per ogni stampante, invia la notifica di avanzamento
      printerIpMap.forEach((ipAddress, printerName) => {
        // Costruisci il messaggio della comanda
        // Spazio vuoto per il porta comanda (4 righe vuote con un punto in alto)
        let message = `.`;
        message += '\n'; // Riga 1
        message += '\n'; // Riga 2
        message += '\n'; // Riga 3
        message += '\n'; // Riga 4

        // Poi la destinazione (stampante) in grassetto
        message += `<b>${printerName}</b>`;
        message += '\n';

        // Poi il display name in grassetto
        message += `<b>${orderIdentifier}</b>`;
        message += '\n';
        message += '\n';

        message += `<b>AVVISO DI AVANZAMENTO</b>` + '\n' + '\n';
        message += `Completata: <b>${portataCorrenteNome} portata</b>` + '\n';
        message += `Procedere con: <b>${portataSuccessivaNome} portata</b>` + '\n' + '\n';

        message += '\n' + '--------------------' + '\n';

        console.log(`Invio avanzamento portata alla stampante ${printerName} (${ipAddress}):`, message);

        // Invia alla stampante usando l'ipcService
        this.ipcService.printComanda(message, ipAddress);
      });
    }
  }

  private addFormattedTextToMessage(message: string, itemText: string, isBold: boolean): string {
    if (!isBold) {
      return message + itemText + '\n\n';
    }

    // Se il testo contiene a capo (\n), avvolgi ogni riga in tag bold separati
    if (itemText.includes('\n')) {
      const lines = itemText.split('\n');
      let formattedMessage = '';

      lines.forEach(line => {
        if (line.trim()) {
          formattedMessage += `<b>${line}</b>\n`;
        } else {
          formattedMessage += '\n';
        }
      });

      return message + formattedMessage + '\n';
    } else {
      return message + `<b>${itemText}</b>\n\n`;
    }
  }

// Metodo per formattare il testo di un elemento
  private formatItemText(item): string {
    // Quantità da stampare (solo la differenza tra totale e già stampate)
    const quantityPrinted = item.quantityPrinted || 0;
    const remainingQuantity = item.quantita - quantityPrinted;

    // Crea il testo dell'articolo
    let itemText = `${remainingQuantity} x ${item.title}`;

    // Aggiungi le varianti se presenti
    if (item.variants && item.variants.length > 0) {
      item.variants.forEach(variant => {
        // Verifica se abbiamo un variantTitle (titolo della variante)
        if (variant.variantTitle) {
          //itemText += `\n  ${variant.variantTitle}:`;
        }

        // Controlla se abbiamo selectedOptions
        if (variant.selectedOptions && variant.selectedOptions.length > 0) {
          variant.selectedOptions.forEach(option => {
            const optionName = typeof option === 'string' ? option : (option.name || '');
            if (optionName) {
              itemText += `\n    + ${optionName}`;
            }
          });
        } else if (variant.name) {
          // Se non ci sono selectedOptions ma c'è un name, usalo
          itemText += `\n    + ${variant.name}`;
        }
      });
    }

    // Aggiungi le aggiunte se presenti
    if (item.aggiunte && item.aggiunte.length > 0) {
      itemText += '\n  Aggiunte:';
      item.aggiunte.forEach(aggiunta => {
        const aggiuntaName = typeof aggiunta === 'string' ? aggiunta : (aggiunta.name || '');
        if (aggiuntaName) {
          itemText += `\n    + ${aggiuntaName}`;
        }
      });
    }

    // Aggiungi le rimozioni se presenti
    if (item.rimozioni && item.rimozioni.length > 0) {
      itemText += '\n  Rimozioni:';
      item.rimozioni.forEach(rimozione => {
        const rimozioneNome = typeof rimozione === 'string' ? rimozione : (rimozione.name || '');
        if (rimozioneNome) {
          itemText += `\n    - ${rimozioneNome}`;
        }
      });
    }

    // Aggiungi le note se presenti
    if (item.note && item.note.trim() !== '') {
      itemText += `\n  Note: ${item.note}`;
    }

    return itemText;
  }

// Metodo per aggiornare la quantità stampata di un elemento
  private updateItemPrintedQuantity(item): void {
    if (this.currentOrder.carrello[item.key]) {
      // Inizializza quantityPrinted se non esiste
      if (!this.currentOrder.carrello[item.key].quantityPrinted) {
        this.currentOrder.carrello[item.key].quantityPrinted = 0;
      }

      // Aggiorna la quantità stampata
      this.currentOrder.carrello[item.key].quantityPrinted = item.quantita;

      // Contrassegna l'articolo come inviato in cucina
      this.currentOrder.carrello[item.key].sentToKitchen = true;
    }
  }

// Metodo per aggiungere elementi al messaggio
  private addItemsToMessage(items, message, printerName): void {
    items.forEach(item => {
      const itemText = this.formatItemText(item);

      // Determina se questo articolo dovrebbe essere in grassetto per questa stampante
      const isBold = item.selectedPrintDestination === printerName;

      // Aggiungi il testo formattato alla comanda, con o senza grassetto
      if (isBold) {
        message += `<b>${itemText}</b>\n\n`;
      } else {
        message += `${itemText}\n\n`;
      }

      // Aggiorna la quantità stampata nell'ordine
      this.updateItemPrintedQuantity(item);
    });
  }




  handleDiscount(event: {
    type: 'percentage' | 'amount',
    value: number,
    orderTotal: number,
    target: 'product' | 'total',
    operation: 'discount' | 'increase'
  }) {
    if (!this.currentOrder?.statoPagato || (this.currentOrder.statoPagato !== 0 && this.currentOrder.statoPagato !== 3)) {
      // Calcola il valore dello sconto
      let discountAmount: number;
      let discountLabel: string;
      const operationText = event.operation === 'discount' ? 'Sconto' : 'Maggiorazione';

      if (event.type === 'percentage') {
        // Calcola lo sconto come percentuale del totale dell'ordine o in attesa di un prodotto
        if (event.target === 'total') {
          // Sconto sul totale
          discountAmount = (event.value / 100) * event.orderTotal;
          discountLabel = `${operationText} ${event.value}% sul totale`;
        } else {
          // Memorizza la percentuale per applicazione su prodotto singolo
          this.pendingDiscountPercentage = event.value;
          this.pendingDiscountOperation = event.operation;
          console.log(`${operationText} ${event.value}% in attesa di essere applicato a un prodotto`);
          return; // Usciamo, verrà applicato quando si seleziona un prodotto
        }
      } else {
        // Sconto/maggiorazione in valore assoluto - sempre sul totale, non su prodotto specifico
        discountAmount = event.value;
        discountLabel = `${operationText} ${event.value.toFixed(2)}€`;
      }

      // Se è una maggiorazione, invertiamo il segno
      if (event.operation === 'increase') {
        discountAmount = -discountAmount; // Negativo per le maggiorazioni (sarà visualizzato come positivo)
      }

      // Arrotonda a due decimali
      discountAmount = Math.round(discountAmount * 100) / 100;

      // Verifica che lo sconto non superi il totale dell'ordine
      if (event.operation === 'discount' && Math.abs(discountAmount) > event.orderTotal) {
        console.warn('Lo sconto non può superare il totale dell\'ordine');
        return;
      }

      // Crea un nome univoco per il prodotto sconto
      const timestamp = Date.now();
      const uniqueId = `${operationText}_${timestamp}`;

      if (!this.currentOrder.carrello) {
        this.currentOrder.carrello = {};
      }

      if (!this.currentOrder.ordineInserimento) {
        this.currentOrder.ordineInserimento = [];
      }

      // MODIFICA QUI: Usa vatId: '1' invece di '4' per sconti e maggiorazioni
      // Crea il prodotto sconto con prezzo negativo (o positivo per le maggiorazioni)
      this.currentOrder.carrello[uniqueId] = {
        title: discountLabel,
        image_link: event.operation === 'discount' ? 'assets/icons/icons8-discount-60.png' : 'assets/icons/icons8-increase-60.png',
        category: event.operation === 'discount' ? 'Sconto' : 'Maggiorazione',
        price: -discountAmount, // Prezzo negativo per rappresentare lo sconto, positivo per maggiorazioni
        prezzo: -discountAmount,
        quantita: 1,
        portata: this.portata == 4 ? 0 : this.portata,
        vatId: '2' // Usa l'IVA 22% (vatId: '1') invece di IVA 0% (vatId: '4')
      };

      // Aggiungi alla lista di inserimento
      this.currentOrder.ordineInserimento.push(uniqueId);

      // Salva le modifiche
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
      this.ipcService.updateSecondaryDisplay(this.currentOrder);

      // Reset della tastiera numerica
      this.selectedPrice = null;
      this.selectedQuantity = null;
      this.resetCounter++;
    }
  }

  /**
   * Invia comanda solo per la portata selezionata
   */
  inviaPortataSelezionata(): void {
    this.testComande(this.selectedPortata);
    this.router.navigate(['/cassa'], {fragment: '#nonav'});
  }


  /**
   * Invia comanda per tutte le portate
   */
  inviaTuttePortate(): void {
    this.testComande(4); // 4 = tutte le portate
  }

  resetAllPrintedQuantities(): void {
    if (!this.currentOrder?.carrello) {
      console.log('Nessun prodotto nel carrello da azzerare');
      return;
    }

    let itemsReset = 0;

    // Scorri tutti gli elementi nel carrello
    Object.keys(this.currentOrder.carrello).forEach(key => {
      const item = this.currentOrder.carrello[key];

      // Se l'elemento ha una quantità stampata, resettala a zero
      if (item.hasOwnProperty('quantityPrinted') && item.quantityPrinted > 0) {
        item.quantityPrinted = 0;
        itemsReset++;

        // Opzionale: resetta anche il flag sentToKitchen se esiste
        if (item.hasOwnProperty('sentToKitchen')) {
          item.sentToKitchen = false;
        }
      }
    });

    if (itemsReset > 0) {
      // Aggiorna l'ordine nel database per salvare i cambiamenti
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);

      // Mostra una notifica o un feedback (opzionale)
      console.log(`Azzerate le quantità stampate per ${itemsReset} prodotti`);

      // Puoi anche mostrare un messaggio all'utente usando un toast o un dialog
      // this.showToastMessage(`Comande azzerate per ${itemsReset} prodotti`);
    } else {
      console.log('Nessun prodotto già stampato trovato');
    }
  }


// Aggiungi questo metodo per controllare quali prodotti sono stati inviati in cucina
  hasItemsNotSentToKitchen(): boolean {
    if (!this.currentOrder?.carrello) {
      return false;
    }

    // Controlla se ci sono prodotti nel carrello che non sono stati inviati in cucina
    return Object.values(this.currentOrder.carrello).some(
        (item: any) => item.selectedPrintDestination && !item.sentToKitchen
    );
  }


}

