<div class="dialog-container">
  <div class="sticky-container">
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Elimina Prodotto"
           (click)="deleteItemFromFirestore()">
    </div>
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Salva Prodotto"
           (click)="saveChangesToFirestore()">
    </div>
    <div class="close-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Chiudi"
           (click)="closeDialog()">
    </div>
  </div>
  <div style="height: 80vh; aspect-ratio: 1 / 1;">
    <div class="example-container" style="max-width: 100%;">



      <!-- Campo aggiuntivo: Ricetta -->
      <fieldset class="custom-fieldset">
        <legend>Generico</legend>
        <div class="actions-container">
          <div class="action-item">
            <span>Il prodotto è generico?</span>
            <div class="checkbox_item citem_1" style="width: 30px; margin-left: 20px;">
              <label class="checkbox_wrap">
                <input
                  type="checkbox"
                  name="checkbox"
                  class="checkbox_inp"
                  [(ngModel)]="isGenericoChecked"
                  (change)="onGenericoChange($event)"
                >
                <span class="checkbox_mark"></span>
              </label>
            </div>
          </div>
        </div>
      </fieldset>




      <fieldset class="custom-fieldset">
        <legend>Nome prodotto</legend>
        <div class="chart-container">
          <input placeholder="Nome Prodotto" class="custom-input" [(ngModel)]="Titolo">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Categoria</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona Categoria" class="custom-input" [(ngModel)]="selectedCategoria">
            <mat-option *ngFor="let Categoria of categories" [value]="Categoria">
              {{Categoria}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>

      <!--fieldset class="custom-fieldset">
        <legend>Quantità</legend>
        <div class="chart-container">
          <input placeholder="000" class="custom-input" [(ngModel)]="quantity">
        </div>
      </fieldset-->

      <!--fieldset class="custom-fieldset">
        <legend>UM</legend>
        <div class="chart-container">
          <input placeholder="pz" class="custom-input" [(ngModel)]="um">
        </div>
      </fieldset-->

      <fieldset class="custom-fieldset" *ngIf="isGenericoChecked !== true">
        <legend>Codice Fornitore</legend>
        <div class="chart-container">
          <input placeholder="Codice Fornitore" class="custom-input" [(ngModel)]="supplierCode">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isGenericoChecked !== true">
        <legend>Fornitore</legend>
        <div class="chart-container">
          <input placeholder="Fornitore" class="custom-input" [(ngModel)]="supplier">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isGenericoChecked !== true">
        <legend>Costo unitario</legend>
        <div class="chart-container">
          <input placeholder="Costo" class="custom-input" [(ngModel)]="price">
        </div>
      </fieldset>


      <fieldset class="custom-fieldset" *ngIf="isGenericoChecked !== true">
        <legend>Data di Scadenza</legend>
        <div class="chart-container">
          <input placeholder="Scadenza" class="custom-input" [(ngModel)]="expiryDate">
        </div>
      </fieldset>


      <!-- Campo aggiuntivo: Ricetta -->
      <fieldset class="custom-fieldset" *ngIf="isGenericoChecked !== true">
        <legend>Ricetta</legend>
        <div class="actions-container">
          <div class="action-item">
            <span>Il prodotto è composto da più prodotti?</span>
            <div class="checkbox_item citem_1" style="width: 30px; margin-left: 20px;">
              <label class="checkbox_wrap">
                <input
                  type="checkbox"
                  name="checkbox"
                  class="checkbox_inp"
                  [(ngModel)]="isMenuChecked"
                  (change)="onMenuChange($event)"
                >
                <span class="checkbox_mark"></span>
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isMenuChecked && isGenericoChecked !== true">
        <legend>Ingredienti</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona ingredienti" multiple class="custom-input" [(ngModel)]="selectedIngredients">
            <mat-option *ngFor="let ingredient of ingredients" [value]="ingredient">
              {{ingredient}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>


      <fieldset class="custom-fieldset" *ngIf="!isMenuChecked && isGenericoChecked !== true">
        <legend>Livelli di imballaggio</legend>
        <div class="actions-container">
          <div class="action-item">
            <span>1</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl'"
                [(ngModel)]="isLvl1"
                (change)="onMenuChangeLevel1($event)"
              >
              <label [for]="'checkbox-lvl'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>

          <div class="action-item">
            <span>2</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl2'"
                [(ngModel)]="isLvl2"
                (change)="onMenuChangeLevel2($event)"
              >
              <label [for]="'checkbox-lvl2'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>

          <div class="action-item">
            <span>3</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl3'"
                [(ngModel)]="isLvl3"
                (change)="onMenuChangeLevel3($event)"
              >
              <label [for]="'checkbox-lvl3'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>

          <div class="action-item">
            <span>4</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl4'"
                [(ngModel)]="isLvl4"
                (change)="onMenuChangeLevel4($event)"
              >
              <label [for]="'checkbox-lvl4'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>
        </div>
      </fieldset>


      <fieldset class="custom-fieldset" *ngIf="(isLvl1 || isLvl2 || isLvl3 || isLvl4) && isGenericoChecked !== true">
        <legend>Primo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 30%; margin-left: 1.5%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[0].quantita">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[0].um">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; float: left; margin-top: 8px">
            <div class="chart-container" style="display: flex">
              <img *ngIf="levels[0].barCode " [src]="levels[0].barCode" alt="Codice a Barre" style="max-width: 50px; margin-bottom: 10px;">
              <div style="width: 250px;">
                <categorieCard *ngIf="levels[0].barCode && levels[0].barCode.length > 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'Elimina immagine'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd'"
                               (click)="removeImage(0)">
                </categorieCard>

                <categorieCard *ngIf="!levels[0].barCode || levels[0].barCode.length === 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'bar-code'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"
                               (click)="fileInput.click()">
                </categorieCard>
              </div>
              <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event, 0)">
            </div>
          </div>
        </div>
      </fieldset>



      <fieldset class="custom-fieldset" *ngIf=" (isLvl2 || isLvl3 || isLvl4) && isGenericoChecked !== true">
        <legend>Secondo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 30%; margin-left: 1.5%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[1].quantita">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[1].um">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; float: left; margin-top: 8px">
            <div class="chart-container" style="display: flex">
              <img *ngIf="levels[1].barCode " [src]="levels[1].barCode" alt="Codice a Barre" style="max-width: 50px; margin-bottom: 10px;">
              <div style="width: 250px;">
                <categorieCard *ngIf="levels[0].barCode && levels[1].barCode.length > 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'Elimina immagine'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd'"
                               (click)="removeImage(1)">
                </categorieCard>

                <categorieCard *ngIf="!levels[1].barCode || levels[1].barCode.length === 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'bar-code'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"
                               (click)="fileInput.click()">
                </categorieCard>
              </div>
              <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event, 1)">
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf=" (isLvl3 || isLvl4) && isGenericoChecked !== true">
        <legend>Terzo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 30%; margin-left: 1.5%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[2].quantita">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[2].um">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; float: left; margin-top: 8px">
            <div class="chart-container" style="display: flex">
              <img *ngIf="levels[2].barCode " [src]="levels[2].barCode" alt="Codice a Barre" style="max-width: 50px; margin-bottom: 10px;">
              <div style="width: 250px;">
                <categorieCard *ngIf="levels[2].barCode && levels[2].barCode.length > 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'Elimina immagine'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd'"
                               (click)="removeImage(2)">
                </categorieCard>

                <categorieCard *ngIf="!levels[2].barCode || levels[2].barCode.length === 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'bar-code'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"
                               (click)="fileInput.click()">
                </categorieCard>
              </div>
              <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event, 2)">
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf=" isLvl4 && isGenericoChecked !== true ">
        <legend>Quartp Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 30%; margin-left: 1.5%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[3].quantita">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; margin-right: 3%; float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[3].um">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 30%; float: left; margin-top: 8px">
            <div class="chart-container" style="display: flex">
              <img *ngIf="levels[3].barCode " [src]="levels[3].barCode" alt="Codice a Barre" style="max-width: 50px; margin-bottom: 10px;">
              <div style="width: 250px;">
                <categorieCard *ngIf="levels[3].barCode && levels[0].barCode.length > 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'Elimina immagine'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd'"
                               (click)="removeImage(3)">
                </categorieCard>

                <categorieCard *ngIf="!levels[3].barCode || levels[3].barCode.length === 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                               [nome]="'bar-code'"
                               altezza="45"
                               [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"
                               (click)="fileInput.click()">
                </categorieCard>
              </div>
              <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event, 3)">
            </div>
          </div>
        </div>
      </fieldset>

    </div>
  </div>
</div>
