<div class="row" style="height: 95vh; width: calc(60vw); margin-top: 15px; border-radius: 20px;">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Tavoli</div>

    <div style="width: 100%; height: 80vh; background-color: white; box-shadow: 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px; margin-left: 0%; overflow-y: auto">
        <div class="flex flex-col justify-between gap-2" style="padding: 1rem;">
            <!-- Selettore sale -->
            <div class="sale-selector" style="display: flex; width: 100%; overflow-x: auto; margin-bottom: 20px; border-bottom: 1px solid #eee;">
                <div *ngFor="let sala of sale"
                     [ngClass]="{'sala-tab-active': sala.id === salaSelezionata, 'sala-tab': sala.id !== salaSelezionata}"
                     (click)="cambiareSala(sala.id)"
                     (contextmenu)="modificaSala($event, sala)">
                    {{sala.nome}}
                    <span class="edit-icon" (click)="$event.stopPropagation(); modificaSala($event, sala)">✏️</span>
                </div>
                <div class="sala-tab-new" (click)="aprirDialogNuovaSala()">
                    <span>+</span>
                </div>
            </div>

            <!-- Barra superiore con i pulsanti -->
            <div style="width: 100%; display: flex; margin-top: 1vh">
                <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                    <categorieCard
                            class="col-12 col-md-12 menuItem"
                            style="padding-left: 0px; margin-left: 0px; padding-right: 0px; width:26%; margin-right: 20px"
                            [nome]="'Aggiungi tavolo'"
                            altezza="55"
                            [link]="'assets/icons/icons8-add-90.png'"
                            [bg]="'#34bfa3'"
                            (click)="aggiungiTavolo()"
                            *ngIf="!unisciTavoli"
                    >
                    </categorieCard>

                    <categorieCard
                            class="col-12 col-md-12 menuItem"
                            style="padding-left: 0px; margin-left: 0px; padding-right: 0px; width:26%"
                            [nome]="'Conferma'"
                            altezza="55"
                            [bg]="'#34bfa3'"
                            (click)="uniscitavoli()"
                            *ngIf="unisciTavoli"
                    >
                    </categorieCard>
                </div>
            </div>

            <!-- Area tavoli -->
            <div class="example-boundary">
                <div
                        *ngFor="let tavolo of tavoli"
                        class="example-box"
                        cdkDragBoundary=".example-boundary"
                        cdkDrag
                        [cdkDragFreeDragPosition]="tavolo.posizione"
                        (cdkDragStarted)="onDragStarted($event)"
                        (cdkDragEnded)="dropped($event, tavolo)"
                        [style.border]="tavolo.unito ? '1px solid red': '1px solid #ccc'"
                        (click)="handleTableClick(tavolo)"
                >
                    <app-table-number
                            [tableNumber]="tavolo.numero"
                            [coperti]="tavolo.coperti"
                            [occupato]="tavolo.occupato"
                    >
                    </app-table-number>
                </div>
            </div>
        </div>
    </div>
</div>