import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ElectronService } from 'ngx-electron';
import firebase from "firebase";
import {UserDataService} from "./shared/services/firestore/userData.service";
import {DataServiceGenerico} from "./shared/services/data-service/data.service";

/**
 * The root application component
 */
@Component({
  selector: 'app-root',
  styles: [`
    body {
      margin: 0;
      padding: 0;
      overflow-x: hidden;
    }

    .attiva {
      display: inline;
    }

    .disattiva {
      display: none;
    }

    .screen-body {
      background-color: #fbfbfb;
      margin-left: 250px;
      height: 100%;
      transition: margin-left 0.3s ease;
    }

    @media screen and (max-width: 992px) {
      .screen-body {
        margin-left: 100px !important;
      }
    }

    @media screen and (max-width: 768px) {
      .screen-body {
        margin-left: 0 !important;
        width: 100%;
      }
    }
  `],
  template: `
    <body [ngStyle]="{'background-color': cucina ? 'black !important' : '#fbfbfb'}">
    <div
        name="fieldName" ngDefaultControl
        id="navBarrr"
        [ngClass]="{'disattiva': hideNavbar || report, 'attiva': !hideNavbar}"
    >
      <navbar></navbar>
    </div>

    <div
        name="fieldName" ngDefaultControl
        id="navBarrr"
        [ngClass]="{'disattiva': !report, 'attiva': report}"
    >
      <navbar-report></navbar-report>
    </div>

    <div class="screen-body" id="divvvRouter"
         [ngStyle]="{'margin-left': getMarginLeft()}"
    >
      <router-outlet></router-outlet>
    </div>
    </body>
    <app-snackbar></app-snackbar>
  `
})
export class AppComponent implements OnInit {

  cucina = false;
  hideNavbar = false;
  report = false;
  screenWidth: number;

  disattivate = [
    'menu',
    '/menuCard/scontrini',
    '/scontrini/dettaglioScontrino',
    '/login',
    '/sign-in',
    '/cucina'
  ];

  attiva = false;

  constructor(
      private router: Router,
      private changeDetection: ChangeDetectorRef,
      private breakpointObserver: BreakpointObserver,
      private electronService: ElectronService,
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico
  ) {
    this.attiva = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.changeDetection.detectChanges();
  }

  getMarginLeft(): string {
    if (this.hideNavbar) {
      return '0px';
    }

    if (this.screenWidth <= 768) {
      return '0px';
    } else if (this.screenWidth <= 992) {
      return '70px';
    } else {
      return '220px';
    }
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;

    // Osserva i breakpoint per la responsività
    this.breakpointObserver
        .observe(['(max-width: 768px)', '(max-width: 992px)'])
        .subscribe((state: BreakpointState) => {
          this.changeDetection.detectChanges();
        });

    // Configura i listener IPC
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');

      // Ascolta i comandi di navigazione
      ipcRenderer.on('navigate-to', (_, route: string) => {
        console.log('Navigazione richiesta verso:', route);
        this.router.navigate([route], { fragment: 'nonav' });
      });
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
            () => {
              this.retriveCarrello();
            }
        );
      } else {
        // No user is signed in.
      }
    });

    if (this.electronService.isElectronApp) {
      this.electronService.ipcRenderer.on('remove-navbar', () => {
        this.hideNavbar = true;
        this.changeDetection.detectChanges();
        this.router.navigate(['/cassa'], { fragment: 'nonav' });
      });
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url !== undefined) {
          this.hideNavbar = val.url.includes('#nonav') || val.url === '/login';
          this.cucina = val.url.includes('cucina');
          this.report = val.url.includes('report');

          if(this.report == false)
          {
            this.report = val.url.includes('costi-operativi');
          }
          if(this.report == false)
          {
            this.report = val.url.includes('inventario');
          }
          if(this.report == false)
          {
            this.report = val.url.includes('operativita');
          }
          if(this.report == false)
          {
            this.report = val.url.includes('foodcost');
          }
          if(this.report == false)
          {
            this.report = val.url.includes('incassi');
          }
        }
      }
    });
  }

  user = {}
  retriveCarrello(): void {
    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
        data => {
          if (data.data() !== undefined) {
            this.user = data.data();
            this.dataServiceGenerico.setUser(this.user)
          }
        }
    );
  }
}