import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, of, throwError } from 'rxjs';
import {map, switchMap, tap, catchError, take} from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataServiceGenerico } from "../data-service/data.service";

@Injectable({
    providedIn: 'root'
})
export class InventoryAvailabilityService {
    private _productsAvailability = new BehaviorSubject<{[key: string]: number}>({});
    public productsAvailability$ = this._productsAvailability.asObservable();
    private _isLoaded = new BehaviorSubject<boolean>(false);
    public isLoaded$ = this._isLoaded.asObservable();

    constructor(private dataService: DataServiceGenerico) {
        this.loadAvailability();
    }

    /**
     * Carica la disponibilità di tutti i prodotti dall'inventario
     */
    loadAvailability(): void {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        console.log(`Caricamento disponibilità inventario per ristorante: ${restaurantId}`);

        db.collection('inventario').doc(restaurantId).get()
            .then(doc => {
                if (!doc.exists) {
                    console.log('Nessun inventario trovato per il ristorante, inizializzando disponibilità vuota');
                    this._productsAvailability.next({});
                    this._isLoaded.next(true);
                    return;
                }

                const data = doc.data();
                const items = data.items || [];
                const availability = {};

                console.log(`Trovati ${items.length} prodotti nell'inventario`);

                items.forEach(item => {
                    if (!item.productId) {
                        console.warn('Trovato item senza productId nell\'inventario', item);
                        return;
                    }

                    // Calcola la disponibilità basandosi sul valore attuale nel livello 1
                    const disponibilita = this.calculateAvailability(item);
                    console.log(`Disponibilità per prodotto ${item.productId} (${item.productTitle}): ${disponibilita}`);
                    availability[item.productId] = disponibilita;
                });

                console.log('Disponibilità caricate:', availability);
                this._productsAvailability.next(availability);
                this._isLoaded.next(true);
            })
            .catch(error => {
                console.error('Errore nel caricamento delle disponibilità:', error);
                // Emetti comunque un oggetto vuoto per evitare blocchi
                this._productsAvailability.next({});
                this._isLoaded.next(true);
            });
    }

    /**
     * Calcola la disponibilità effettiva di un prodotto basandosi sulla quantità attuale
     * CORREZIONE: usa direttamente il valore nel primo livello anziché ricalcolare da tutti i movimenti
     */
    private calculateAvailability(item): number {
        if (!item.lvl || item.lvl.length === 0) {
            return 0;
        }

        // Leggiamo direttamente la quantità corrente dal primo livello (livello1)
        // senza ricalcolare da tutti i movimenti storici
        const disponibilita = parseInt(item.lvl[0].quantita || '0', 10);
        return disponibilita;
    }

    /**
     * Verifica la disponibilità di un prodotto
     * @returns la quantità disponibile
     */
    checkAvailability(productId: string): Observable<number> {
        if (!productId) {
            console.warn('Richiesta disponibilità per prodotto senza ID');
            return of(999); // Assumiamo disponibilità alta
        }

        console.log(`Verifica disponibilità per prodotto: ${productId}`);

        return this.isLoaded$.pipe(
            switchMap(isLoaded => {
                if (!isLoaded) {
                    console.log('Disponibilità non ancora caricate, ricarico...');
                    this.loadAvailability();
                    // Assumiamo che il prodotto sia disponibile mentre carichiamo
                    return of(999);
                }

                return this.productsAvailability$.pipe(
                    map(availability => {
                        const qty = availability[productId];
                        console.log(`Disponibilità per ${productId}: ${qty !== undefined ? qty : 'non trovato'}`);
                        // Se il prodotto non è nell'inventario, assumiamo che sia disponibile
                        return qty !== undefined ? qty : 999;
                    })
                );
            })
        );
    }

    /**
     * Verifica se un prodotto è disponibile nella quantità richiesta
     */
    isProductAvailable(productId: string, quantity: number = 1): Observable<boolean> {
        console.log(`Verifico disponibilità prodotto: ${productId}, quantità richiesta: ${quantity}`);

        // Se non c'è un ID prodotto, assumiamo che sia disponibile
        if (!productId) {
            console.warn('Richiesta verifica disponibilità per prodotto senza ID, assumo disponibile');
            return of(true);
        }

        return this.checkAvailability(productId).pipe(
            map(available => {
                const isAvailable = available >= quantity;
                console.log(`Prodotto ${productId}: disponibilità ${available}, richiesto ${quantity}, disponibile: ${isAvailable}`);
                return isAvailable;
            }),
            catchError(err => {
                console.error(`Errore nella verifica disponibilità per ${productId}:`, err);
                // In caso di errore, assumiamo che il prodotto sia disponibile
                // per non bloccare le vendite
                return of(true);
            }),
            // Importante: take(1) garantisce che l'Observable emetta solo un valore e poi completi
            take(1)
        );
    }

    /**
     * Aggiorna temporaneamente la disponibilità locale quando un prodotto viene aggiunto al carrello
     * senza aggiornare il database
     */
    updateLocalAvailability(productId: string, quantity: number): void {
        if (!productId) {
            console.warn('Tentativo di aggiornare disponibilità locale per prodotto senza ID');
            return;
        }

        console.log(`Aggiornamento disponibilità locale per ${productId}: -${quantity}`);

        const currentAvailability = this._productsAvailability.value;
        const updatedAvailability = { ...currentAvailability };

        if (updatedAvailability[productId] !== undefined) {
            updatedAvailability[productId] -= quantity;
            this._productsAvailability.next(updatedAvailability);
        }
    }

    /**
     * Ripristina la disponibilità locale quando un prodotto viene rimosso dal carrello
     * senza aggiornare il database
     */
    restoreLocalAvailability(productId: string, quantity: number): void {
        if (!productId) {
            console.warn('Tentativo di ripristinare disponibilità locale per prodotto senza ID');
            return;
        }

        console.log(`Ripristino disponibilità locale per ${productId}: +${quantity}`);

        const currentAvailability = this._productsAvailability.value;
        const updatedAvailability = { ...currentAvailability };

        if (updatedAvailability[productId] !== undefined) {
            updatedAvailability[productId] += quantity;
            this._productsAvailability.next(updatedAvailability);
        }
    }

    /**
     * Registra direttamente la vendita nell'inventario quando viene completato un ordine in cassa
     * @returns Observable<boolean> che indica se l'operazione è riuscita
     */
    registerSalesInInventory(orderId: string, orderItems: {[key: string]: any}): Observable<boolean> {
        if (!orderId) {
            console.error("registerSalesInInventory: orderId non valido");
            return of(false);
        }

        if (!orderItems || Object.keys(orderItems).length === 0) {
            console.error("registerSalesInInventory: orderItems non validi o vuoti");
            return of(false);
        }

        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        if (!restaurantId) {
            console.error("registerSalesInInventory: restaurantId non valido");
            return of(false);
        }

        console.log(`Registrazione vendite nell'inventario per ordine ${orderId}`);
        console.log(`Restaurant ID: ${restaurantId}`);
        console.log(`Prodotti nell'ordine:`, orderItems);

        const inventoryRef = db.collection('inventario').doc(restaurantId);

        return from(inventoryRef.get()).pipe(
            tap(doc => {
                if (!doc.exists) {
                    console.warn(`Inventario non trovato per il ristorante ${restaurantId}`);
                } else {
                    console.log(`Inventario trovato, contiene ${doc.data().items?.length || 0} prodotti`);
                }
            }),
            switchMap(doc => {
                if (!doc.exists) {
                    console.warn('Inventario non trovato, impossibile registrare vendite');
                    return of(true); // Restituiamo true per non bloccare il flusso
                }

                const data = doc.data();
                let items = data.items || [];
                const updatedItems = [...items];
                const currentDate = new Date().toLocaleString('it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });

                let itemsProcessed = 0;
                let itemsUpdated = 0;

                // Debug info
                console.log(`Totale prodotti nell'inventario: ${items.length}`);
                console.log(`Totale prodotti nell'ordine: ${Object.keys(orderItems).length}`);

                // Aggiorna ogni prodotto venduto
                Object.keys(orderItems).forEach(key => {
                    if (key === 'Sconto') {
                        console.log("Ignoro sconto");
                        return; // Ignora gli sconti
                    }

                    const cartItem = orderItems[key];
                    console.log(`Elaboro prodotto: ${key}`, cartItem);

                    if (!cartItem) {
                        console.warn(`Prodotto nel carrello con chiave ${key} non valido`);
                        return;
                    }

                    const productId = cartItem.id;
                    const quantity = cartItem.quantita || 1;

                    if (!productId) {
                        console.warn('Prodotto nel carrello senza ID, ignoro', cartItem);
                        return;
                    }

                    itemsProcessed++;
                    console.log(`Cerco prodotto ${productId} nell'inventario`);

                    // Trova il prodotto nell'inventario
                    const itemIndex = updatedItems.findIndex(item => item.productId === productId);
                    if (itemIndex === -1) {
                        console.warn(`Prodotto ${productId} non trovato nell'inventario, ignoro`);
                        return;
                    }

                    itemsUpdated++;
                    console.log(`Trovato prodotto ${productId} in posizione ${itemIndex}`);

                    // Calcola le quantità attuali
                    const currentQuantities = {};
                    if (updatedItems[itemIndex].lvl && updatedItems[itemIndex].lvl.length > 0) {
                        updatedItems[itemIndex].lvl.forEach((level, idx) => {
                            currentQuantities[`livello${idx+1}`] = `${level.quantita || 0} ${level.um || ''}`;
                        });
                    } else {
                        console.warn(`Il prodotto ${productId} non ha livelli definiti`);
                        // Crea un livello di default se non esiste
                        updatedItems[itemIndex].lvl = [{
                            quantita: "0",
                            um: "pz"
                        }];
                    }

                    console.log(`Quantità attuali: ${JSON.stringify(currentQuantities)}`);

                    // CORREZIONE: Sottraiamo direttamente la quantità venduta dalla quantità attuale
                    const currentQuantity = parseInt(updatedItems[itemIndex].lvl[0].quantita || '0', 10);
                    const newQuantity = Math.max(0, currentQuantity - quantity);

                    console.log(`Aggiornamento quantità per ${productId}: ${currentQuantity} -> ${newQuantity}`);
                    updatedItems[itemIndex].lvl[0].quantita = newQuantity.toString();

                    // Crea un nuovo movimento per la vendita
                    const movimentoVendita = {
                        data: currentDate,
                        timestamp: Date.now(),
                        quantitaPrec: JSON.stringify(currentQuantities),
                        quantitaAgg: JSON.stringify({ livello1: quantity }),
                        lvl: [
                            {
                                quantita: quantity.toString(),
                                um: updatedItems[itemIndex].lvl[0]?.um || 'pz'
                            }
                        ],
                        modalita: 'vendita',
                        note: `Ordine #${orderId}`
                    };

                    // Aggiungi il movimento all'item
                    if (!updatedItems[itemIndex].movimenti) {
                        updatedItems[itemIndex].movimenti = [];
                    }

                    updatedItems[itemIndex].movimenti.unshift(movimentoVendita);
                    updatedItems[itemIndex].lastUpdated = Date.now();

                    console.log(`Aggiunto movimento di vendita per ${productId}, quantità: ${quantity}`);
                });

                console.log(`Elaborati ${itemsProcessed} prodotti, aggiornati ${itemsUpdated} nell'inventario`);

                // Se non ci sono prodotti da aggiornare, non facciamo l'update
                if (itemsUpdated === 0) {
                    console.log('Nessun prodotto da aggiornare nell\'inventario');
                    return of(true);
                }

                // Aggiorna il database
                console.log('Inizio aggiornamento Firestore...');
                return from(inventoryRef.update({ items: updatedItems })).pipe(
                    tap(() => console.log('Inventario aggiornato con successo')),
                    map(() => true),
                    catchError(error => {
                        console.error('Errore nell\'aggiornamento dell\'inventario:', error);
                        return of(false);
                    })
                );
            }),
            catchError(error => {
                console.error('Errore nel registro delle vendite:', error);
                return of(false);
            }),
            // Importante: take(1) garantisce che l'Observable emetta solo un valore e poi completi
            take(1)
        );
    }
}