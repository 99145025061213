<div class="dialog-container">
  <div class="sticky-container">
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Salva Prodotto"
           (click)="saveChangesToFirestore()">
    </div>
    <div class="close-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Chiudi"
           (click)="closeDialog()">
    </div>
  </div>

  <div style=" aspect-ratio: 1 / 1;">
    <div class="example-container" style="max-width: 100%;">

      <fieldset class="custom-fieldset">
        <legend>Modalità</legend>
        <div class="actions-container">
          <div class="action-item">
            <span>Aggiunta</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl'"
                [(ngModel)]="aggiunta"
                (change)="onModalitaChangeAggiunta($event)"
              >
              <label [for]="'checkbox-lvl'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>


          <div class="action-item">
            <span>Inventario</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl4'"
                [(ngModel)]="inventario"
                (change)="onModalitaChangeInventario($event)"
              >
              <label [for]="'checkbox-lvl4'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>


          <div class="action-item">
            <span>Spreco</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl2'"
                [(ngModel)]="spreco"
                (change)="onModalitaChangeSpreco($event)"
              >
              <label [for]="'checkbox-lvl2'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>

          <div class="action-item">
            <span>Trasferimento</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-lvl3'"
                [(ngModel)]="trasferimento"
                (change)="onModalitaChangeTrasferimento($event)"
              >
              <label [for]="'checkbox-lvl3'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <!-- Sezione livelli di imballaggio -->
      <fieldset class="custom-fieldset" *ngIf="isLvl1 || isLvl2 || isLvl3 || isLvl4 || isMenuChecked">
        <legend>Primo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 47%; margin-left: 1.5%; margin-right: 3%; float: left" *ngIf="isLvl1 || isLvl2 || isLvl3 || isLvl4">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[0].quantita" value="0">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 47%;  float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[0].um" disabled>
            </fieldset>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isLvl2 || isLvl3 || isLvl4">
        <legend>Secondo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 47%; margin-left: 1.5%; margin-right: 3%; float: left" *ngIf="isLvl2 || isLvl3 || isLvl4">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[1].quantita" value="0">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 47%;  float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[1].um" disabled>
            </fieldset>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isLvl3 || isLvl4">
        <legend>Terzo Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 47%; margin-left: 1.5%; margin-right: 3%; float: left" *ngIf="isLvl3 || isLvl4">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[2].quantita" value="0">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 47%;  float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[2].um" disabled>
            </fieldset>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isLvl4">
        <legend>Quarto Livello</legend>
        <div class="chart-container">
          <div class="chart-container" style="width: 47%; margin-left: 1.5%; margin-right: 3%; float: left" *ngIf="isLvl4">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>Quantità</legend>
              <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[3].quantita" value="0">
            </fieldset>
          </div>

          <div class="chart-container" style="width: 47%;  float: left">
            <fieldset class="custom-fieldset" style="border: 1px solid rgba(222,214,214,0.57)">
              <legend>UM</legend>
              <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[3].um" disabled>
            </fieldset>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Data di Scadenza</legend>
        <div class="chart-container">
          <input placeholder="Scadenza" class="custom-input" [(ngModel)]="expiryDate">
        </div>
      </fieldset>

    </div>
  </div>
</div>
