import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {newArray} from '@angular/compiler/src/util';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {map} from 'rxjs/operators';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';
import firebase from 'firebase/app';
import {CommonService} from "../../shared/services/firestore/common.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import { PaymentCashmaticService } from "../../shared/services/payment-cashmatic.service";
import { IpcService } from "../../shared/services/ipc.service";
import { Subscription } from 'rxjs';
import {PosTransactionService} from "../../shared/services/pos-transaction.service";
import {DailyReceiptService} from "../../shared/services/daily-receipt.service";
import {DialogBuoniPasto} from "./dialog/dialog-buoni-pasto.component";
import {AlertDialogComponent} from "./dialog/alert-dialog.component";
import { PagerDialogComponent } from '../PagerDialogComponent/pagerDialogComponent.component';
import { Fidelity } from '../fidelity/fidelity';




@Component({
  selector: 'dialog-pagamento',
  templateUrl: './dialogPagamento.html',
  styleUrls: ['./dialogPagamento.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
  ],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"'
  },
})
export class DialogPagamento implements OnDestroy {

  tipoSconto = false;

  sconto = 0;

  coperti = 1;

  selectedCategory: string = 'Totale';  // Default to Totale

  splitItems: any[] = [];
  originalCarrello: any = {};  // Store original cart
  splitCarrello: any = {};

  hasPager: boolean = false;
  hasFidelity: boolean = false;
  preconto = false;
  hasPrecontoPermission: boolean = true; // Default to true in case not provided

  itemDiscounts: { [key: string]: any } = {}; // Per tenere traccia degli sconti per prodotto
  isDiscountMode: boolean = false; // Per gestire la modalità sconto
  workstationPrinters: { name: string, ipAddress: string }[] = [];
  paymentInProgress = false;
  cassettAutomaticoEnabled: boolean = true;

  quotePagate: number = 0;
  numeroQuote: number = 1;
  quoteSelezionate: { [key: string]: number } = {};
  quoteGenerate: any[] = [];
  importoPerQuotaOriginale: number = 0;

  paymentHistory: any[] = [];
  lockPaymentMode: boolean = false;
  currentCashmaticPayment: any = null;
  currentPosTransaction: any = null;
  totalePagamentoOriginale: number = 0;


  change(qualcose? : any)
  {
    console.log("model change: ", this.sconto)
    console.log("model carrello: ", this.carrello)

    if(this.carrello !== undefined) {
      if (this.sconto == 0 || this.sconto == null) {
        if (this.carrello.hasOwnProperty('Sconto')) {
          delete this.carrello['Sconto']
        } else {

        }
      } else {
        if (this.carrello.hasOwnProperty('Sconto')) {
          this.carrello['Sconto']['prezzo'] = -this.sconto
          this.carrello['Sconto']['price'] = -this.sconto
          this.carrello['Sconto']['tipo'] = this.tipoSconto
        } else {
          this.carrello['Sconto'] = {
            image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
            prezzo: -this.sconto,
            price: -this.sconto,
            tipo: this.tipoSconto,
            title: 'Sconto'
          }
        }
      }
    }
  }

  cambiaSconto() {
    this.tipoSconto = !this.tipoSconto

    if (this.carrello !== undefined) {
      if (this.sconto == 0 || this.sconto == null) {
        if (this.carrello.hasOwnProperty('Sconto')) {
          delete this.carrello['Sconto']
        } else {

        }
      } else {
        if (this.carrello.hasOwnProperty('Sconto')) {
          this.carrello['Sconto']['prezzo'] = -this.sconto
          this.carrello['Sconto']['price'] = -this.sconto
          this.carrello['Sconto']['tipo'] = this.tipoSconto
        } else {
          this.carrello['Sconto'] = {
            image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
            prezzo: -this.sconto,
            price: -this.sconto,
            tipo: this.tipoSconto,
            title: 'Sconto'
          }
        }
      }
    }

    console.log("carrello: ", this.carrello)
  }

  getSconto()
  {
    if(this.tipoSconto == true)
    {
      return '€'
    }
    else
    {
      return '%'
    }
  }

  bucketLvl = 0;
  terzoLivello = false;
  quartoLivello = false;
  quintoLivello = false;

  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  cashmaticAperto = 0;

  @Inject(MAT_DIALOG_DATA) public data: any


  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private menuService: MenuService,
              private carrelloService: FirestoreService, private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<any>,
              public dataService: DataServiceGenerico,
              private paymentCashmaticService: PaymentCashmaticService,
              private ipcService: IpcService,
              private dataServiceGenerico: DataServiceGenerico,
              private posTransactionService: PosTransactionService,
              private dailyReceiptService: DailyReceiptService,
              private dialog: MatDialog,

  ) {
    this.ristorante = 'menu';
    this.route.params.subscribe(params => {
          console.log(params);
          if (params.ristorante) {
            //this.ristorante = params.ristorante;
            this.ristorante = 'menu';
          }
          if (params.tavolo) {
            this.numeroTavolo = params.tavolo;
          }
        }
    );


    this.retriveMenu();

    this.posTransactionService.onTransactionResult((event, result) => {
      console.log("POS Transaction Result:", result);

      // Controlla prima se la risposta contiene indicazione di annullamento
      if (result.data && result.data.toLowerCase().includes('annullata') ||
          (result.message && result.message.toLowerCase().includes('annullata'))) {
        this.posStatus = 'cancelled';
        this.paymentMessage = 'Operazione annullata';
        setTimeout(() => {
          this.pagamentoCartaAperto = 0;
          this.posStatus = 'idle';
        }, 2000);
        return;
      }

      // Gestione degli altri stati
      switch (result.status) {
        case 'progress':
          this.posStatus = 'progress';
          this.paymentMessage = result.message;
          break;

        case 'success':
          this.handleSuccessfulPayment(result);
          break;

        case 'error':
        case 'failure':
          this.posStatus = 'error';
          this.paymentMessage = result.message || 'Errore durante il pagamento';
          setTimeout(() => {
            this.pagamentoCartaAperto = 0;
            this.posStatus = 'idle';
          }, 2000);
          break;
      }
    });
    // this.retriveCarrello();
  }


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passBack() {
    this.passEntry.emit(this.user);
  }

  ngOnDestroy(): any {
   // this.annullaPagamento();
    //this.passBack();
    this.paymentCashmaticService !== undefined? this.paymentCashmaticService.annullaPagamento(): null;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  piattoSelezionato = -1;

  ciao = false;

  ciao2 = false;

  ciao3 = false;
  ciao4 = false;


  clickPiattoNuovo(i){

    this.filtraMenu(this.categoriaSelezionata)[i]
    //this.passEntry.emit(this.filtraMenu(this.categoriaSelezionata)[i]);
    //this.dialogRef.close(false);
  }

  clickPiattoInterno(i)
  {

  }
  showInputForDiners: boolean = false;
  isSingoloSelected: boolean = false;
  selezionati: { [key: string]: number } = {};

  contornoLvl = 0;
  menuSelezionato = 0;

  selezionaMenu(dimensione: any)
  {
    this.piattoSelezionato2['dimensioneMenu'] = dimensione
    this.quartoLivello = true;
  }

  piattoSelezionato2;


  // Versione completa e corretta di handleSuccessfulPayment

  private handleSuccessfulPayment(result: any) {
    this.posStatus = 'success';
    let stringa = [];
    let itemsToPrint = [];
    let quotePagate = [];

    if (this.selectedCategory === 'Singolo') {
      // Codice per singolo
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          item.title = item.title.split('_')[0];
          stringa.push(item);
          itemsToPrint.push(item);
        }
      });

      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key]) {
          delete this.carrello[key];
          delete this.selezionati[key];
        }
      });
    } else if (this.selectedCategory === 'Romana') {
      // Codice per romana con numeroQuote
      let quoteCount = 0;

      Object.keys(this.quoteSelezionate).forEach(key => {
        if (this.quoteSelezionate[key] && this.carrello[key]) {
          stringa.push(this.carrello[key]);
          itemsToPrint.push(this.carrello[key]);
          quotePagate.push(key);
          delete this.carrello[key];
          delete this.quoteSelezionate[key];
          quoteCount++;
        }
      });

      // Se nessuna quota è stata selezionata specificamente, prendi la prima disponibile
      if (quoteCount === 0 && Object.keys(this.carrello).length > 0) {
        let quoteKeys = Object.keys(this.carrello).filter(key => key.startsWith('Quota_'));
        if (quoteKeys.length > 0) {
          const quotaKey = quoteKeys[0];
          stringa.push(this.carrello[quotaKey]);
          itemsToPrint.push(this.carrello[quotaKey]);
          quotePagate.push(quotaKey);
          delete this.carrello[quotaKey];
          quoteCount = 1;
        }
      }

      this.quotePagate += quoteCount;

      // Blocca il cambio di modalità dopo il primo pagamento alla romana
      if (quoteCount > 0) {
        this.lockPaymentMode = true;
      }

      // Aggiorna le informazioni sulla divisione romana nell'ordine
      if (this.camerieri) {
        this.camerieri.romanaPayment = {
          numeroQuote: this.numeroQuote,
          quotePagate: this.quotePagate,
          importoPerQuota: this.importoPerQuotaOriginale,
          quotePagateDettaglio: [
            ...(this.camerieri.romanaPayment?.quotePagateDettaglio || []),
            ...quotePagate
          ]
        };
      }
    } else {
      // Codice per totale
      this.getProperties(this.carrello)?.forEach(key => {
        stringa.push(this.carrello[key]);
        itemsToPrint.push(this.carrello[key]);
      });
    }

    // Genera l'XML dello scontrino
    const receiptXml = this.stampaScontrino(itemsToPrint, 'carta');

    // Crea l'oggetto per la cronologia dei pagamenti
    const paymentRecord = {
      timestamp: new Date().getTime(),
      method: 'carta',
      category: this.selectedCategory,
      items: itemsToPrint,
      receiptXml: receiptXml,
      amount: this.calculatePaymentAmount(itemsToPrint),
      posData: result
    };

    // Aggiungi il record alla cronologia
    if (!this.camerieri.paymentHistory) {
      this.camerieri.paymentHistory = [];
    }
    this.camerieri.paymentHistory.push(paymentRecord);

    // Stampa scontrino
    this.ipcService.PrintFiscalReceipt(receiptXml, this.tipoStampante);

    // Aggiorna lo stato dell'ordine
    if (this.selectedCategory === 'Romana' && this.quotePagate < this.numeroQuote) {
      this.camerieri.statoPagato = 1; // Parzialmente pagato
    } else if (this.selectedCategory === 'Singolo' && Object.keys(this.carrello).length > 1) {
      this.camerieri.statoPagato = 1; // Parzialmente pagato
    } else {
      this.camerieri.statoPagato = 0; // Completamente pagato
      this.camerieri.closureOrderTime = new Date().getTime().toString();
    }

    this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    this.checkOrderComplete();

    setTimeout(() => {
      this.pagamentoCartaAperto = 0;
      this.posStatus = 'idle';
    }, 2000);
  }

  selectCategory(category: string) {
    // Se la modalità è bloccata e stiamo cercando di cambiare da Romana
    if (this.lockPaymentMode && this.selectedCategory === 'Romana' && category !== 'Romana') {
      // Mostra un messaggio all'utente
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: {
          title: 'Cambio modalità non consentito',
          message: 'Hai già effettuato pagamenti alla romana. Non è possibile cambiare modalità di pagamento.',
          showCancel: false
        }
      });
      return;
    }

    if (this.selectedCategory === category) {
      this.selectedCategory = '';
      this.showInputForDiners = false;
      this.restoreOriginalCarrello();
      // Non resettare quotePagate quando si deseleziona Romana se ci sono state quote pagate
      if (category !== 'Romana' || this.quotePagate === 0) {
        this.quotePagate = 0;
      }
      this.quoteGenerate = [];
      this.quoteSelezionate = {};
    } else {
      this.selectedCategory = category;

      // Inizializza sempre quoteSelezionate
      this.quoteSelezionate = this.quoteSelezionate || {};

      if (category === 'Romana') {
        this.showInputForDiners = true;

        // Se non ci sono quote predefinite, usa i coperti
        if (!this.camerieri?.romanaPayment?.numeroQuote) {
          this.numeroQuote = this.coperti || 1; // Assicurati che sia almeno 1
        }

        // Se c'è un importo salvato per la quota, usalo; altrimenti, generane uno nuovo
        if (this.camerieri?.romanaPayment?.importoPerQuota && this.importoPerQuotaOriginale === 0) {
          this.importoPerQuotaOriginale = this.camerieri.romanaPayment.importoPerQuota;
          this.generateQuoteRomana();
        } else if (this.importoPerQuotaOriginale === 0) {
          this.generateQuoteRomana();
        } else {
          // Se abbiamo già un importo e le quote pagate, rigenerare solo le quote rimanenti
          this.generateQuoteRomana();
        }
      } else if (category === 'Singolo') {
        this.showInputForDiners = false;
        this.splitCarrelloItems();
      } else {
        this.showInputForDiners = false;
        this.restoreOriginalCarrello();
      }
    }

    if (category === 'Singolo') {
      this.isSingoloSelected = true;
      this.resetSelezionati();
    } else {
      this.isSingoloSelected = false;
      this.resetSelezionati();
    }
  }

  generateQuoteRomana() {
    // Inizializza quoteSelezionate se undefined
    this.quoteSelezionate = this.quoteSelezionate || {};

    // Calcola il totale attuale del conto
    const totaleAttuale = this.calcolaTotaleBello();

    // Se è la prima volta che generiamo le quote o se non abbiamo un totale originale
    if (this.totalePagamentoOriginale === 0 || this.quotePagate === 0) {
      // Salva il totale originale all'inizio
      this.totalePagamentoOriginale = totaleAttuale;
    }

    // Calcola il numero di quote rimanenti
    const quoteRimanenti = this.numeroQuote - this.quotePagate;

    // Calcola l'importo per quota dividendo il totale ATTUALE per il numero di quote RIMANENTI
    if (quoteRimanenti > 0) {
      this.importoPerQuotaOriginale = totaleAttuale / quoteRimanenti;
    } else {
      this.importoPerQuotaOriginale = 0;
    }

    // Svuota il carrello attuale
    this.carrello = {};
    this.quoteGenerate = [];

    // Genera una quota per ogni persona rimanente
    for (let i = 0; i < quoteRimanenti; i++) {
      const quotaIndex = i + this.quotePagate + 1;
      const quotaKey = `Quota_${quotaIndex}`;

      this.carrello[quotaKey] = {
        image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-money-100.png?alt=media&token=67611b8f-4baf-4bcd-bb5a-83dedb4edd39',
        prezzo: this.importoPerQuotaOriginale,
        price: this.importoPerQuotaOriginale,
        title: `Quota ${quotaIndex}`,
        quantita: 1,
        vatId: 2
      };

      this.quoteGenerate.push(quotaKey);
      this.quoteSelezionate[quotaKey] = 0;
    }

    // Salva le informazioni sulla divisione romana nell'ordine
    if (this.camerieri) {
      this.camerieri.romanaPayment = {
        numeroQuote: this.numeroQuote,
        quotePagate: this.quotePagate,
        importoPerQuota: this.importoPerQuotaOriginale,
        totalePagamentoOriginale: this.totalePagamentoOriginale,
        totaleAttuale: totaleAttuale,
        quotePagateDettaglio: this.camerieri.romanaPayment?.quotePagateDettaglio || []
      };
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    }

    console.log("Quote generate:", {
      totaleAttuale: totaleAttuale,
      totalePagamentoOriginale: this.totalePagamentoOriginale,
      quoteRimanenti: quoteRimanenti,
      importoPerQuota: this.importoPerQuotaOriginale
    });
  }

  calculatePaymentAmount(items: any[]): number {
    let total = 0;
    items.forEach(item => {
      const prezzo = this.getPrezzoPiatto(item);
      const quantita = item.quantita || 1;
      total += prezzo * quantita;
    });
    return total;
  }

  getObjectKeys(obj) {
    if (!obj) return [];
    return Object.keys(obj);
  }

  incrementaQuote() {
    this.numeroQuote++;
    this.generateQuoteRomana();
  }

  decrementaQuote() {
    if (this.numeroQuote > this.quotePagate + 1) { // Non permettere di ridurre sotto il numero di quote già pagate + 1
      this.numeroQuote--;
      this.generateQuoteRomana();
    }
  }


  handleEvent(event: number, piatto: any) {
    if (this.selectedCategory === 'Singolo') {
      // In modalità singolo, rimuovi direttamente l'item se selezionato
      if (this.selezionati[piatto] && event === 0) {
        delete this.carrello[piatto];
        delete this.selezionati[piatto];

        // Aggiorna il carrello originale
        const originalKey = this.carrello[piatto]?.originalKey;
        if (originalKey && this.originalCarrello[originalKey]) {
          this.originalCarrello[originalKey].quantita--;
          if (this.originalCarrello[originalKey].quantita === 0) {
            delete this.originalCarrello[originalKey];
          }
        }
      }
    } else {
      // Comportamento normale per altre modalità
      piatto = piatto.replace(/ /g, '');
      this.carrello[piatto].quantita = event;
      if (this.carrello[piatto].quantita === 0) {
        delete this.carrello[piatto];
      }
    }

    this.dialogRef.componentInstance.passEntry.emit(
        this.selectedCategory === 'Singolo' ? this.originalCarrello : this.carrello
    );
    this.checkOrderComplete();
  }

  loadWorkstationPrinters(): void {
    const restaurantId = this.dataServiceGenerico.getRistorante();

    firebase.firestore()
        .collection('workstations')
        .where('ristoranteId', '==', restaurantId)
        .get()
        .then(snapshot => {
          const printers = [];

          snapshot.forEach(doc => {
            const workstation = doc.data();
            const workstationName = workstation.name || 'Workstation';

            if (workstation.printers && Array.isArray(workstation.printers)) {
              workstation.printers.forEach(printer => {
                if (printer.name && printer.ipAddress) {
                  printers.push({
                    name: printer.name,
                    ipAddress: printer.ipAddress,
                    workstation: workstationName
                  });
                }
              });
            }
          });

          this.workstationPrinters = printers;
          console.log('Stampanti caricate:', this.workstationPrinters);
        })
        .catch(error => {
          console.error('Errore nel caricamento delle stampanti:', error);
        });
  }

  annullaPagamentoCard() {
    this.pagamentoCartaAperto = 0;
    this.posStatus = 'idle';
    this.paymentMessage = '';
  }


  checkOrderComplete() {
    let isComplete = false;

    switch (this.selectedCategory) {
      case 'Totale':
        isComplete = true;
        break;
      case 'Romana':
        isComplete = this.quotePagate >= this.numeroQuote || Object.keys(this.carrello).length === 0;
        break;
      case 'Singolo':
        isComplete = Object.keys(this.carrello).length === 0 ||
            (Object.keys(this.carrello).length === 1 && this.carrello.hasOwnProperty('Sconto'));
        break;
    }

    if (isComplete) {
      if(this.preconto){
        this.camerieri.nota='preconto';
      }
      this.camerieri.statoPagato = 0;
      this.camerieri.closureOrderTime = new Date().getTime().toString();

      // Salva anche l'ultima modalità di pagamento usata e altre info rilevanti
      this.camerieri.lastPaymentInfo = {
        type: this.selectedCategory,
        timestamp: new Date().getTime()
      };

      if (this.selectedCategory === 'Romana') {
        this.camerieri.lastPaymentInfo.romanaDetails = {
          numeroQuote: this.numeroQuote,
          quotePagate: this.quotePagate,
          importoPerQuota: this.importoPerQuotaOriginale
        };
      }

      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
      this.dialogRef.close('completo');
    }
  }

  private async createNewOrder() {


    const current = new Date();
    const timestamp = current.getTime();
    const psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        this.dataServiceGenerico.getRistorante1()
    );

    const newOrder = {
      ristorante: this.dataServiceGenerico.getRistorante1(),
      id: 'c1' + psw,
      displayName: `Banco #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      selectedPriceListType: 'counter'
    };

    this.commonService.updateOrdine(newOrder.id, newOrder);
    this.dialogRef.close('completo'); // Close dialog first
    setTimeout(() => {
      this.router.navigate(['/cassa/order', newOrder.id], { fragment: 'nonav' });
    }, 100);



    this.dialogRef.close('completo');
  }


  // Modifica del template per mostrare le quote pagate
  getButtonText(category: string): string {
    if (category === 'Romana') {
      return `Romana: ${this.importoPerQuotaOriginale.toFixed(2)}€ (${this.quotePagate}/${this.numeroQuote})`;
    }
    return category;
  }



  // Nuovo metodo per splittare gli items
  splitCarrelloItems() {
    // Salva il carrello originale
    this.originalCarrello = { ...this.carrello };

    // Crea nuovo carrello con items splittati
    const newCarrello = {};

    Object.keys(this.carrello).forEach(key => {
      const item = this.carrello[key];

      // Salta lo sconto
      if (key === 'Sconto') {
        newCarrello[key] = item;
        return;
      }

      // Crea un item singolo per ogni quantità
      for (let i = 0; i < item.quantita; i++) {
        const newKey = `${key}_${i}`;
        newCarrello[newKey] = {
          ...item
        };
      }
    });

    this.carrello = newCarrello;
    console.log("singolo: ", this.carrello)
    this.resetSelezionati();
  }

  // Metodo per ripristinare il carrello originale
  restoreOriginalCarrello() {
    if (Object.keys(this.originalCarrello).length > 0) {
      this.carrello = { ...this.originalCarrello };
    }
  }


  // Metodo per resettare tutti i `categorieCard2`
  resetSelezionati() {
    Object.keys(this.selezionati).forEach(key => {
      this.selezionati[key] = 0;  // Imposta selezionato su 0 per tutti
    });
  }
  handleCardClick(piatto: any) {
    if (this.selectedCategory === 'Romana') {
      if (this.quoteSelezionate[piatto] === 1) {
        this.quoteSelezionate[piatto] = 0;
      } else {
        this.quoteSelezionate[piatto] = 1;
      }
    } else if (this.isSingoloSelected) {
      if (this.selezionati[piatto] === 1) {
        this.selezionati[piatto] = 0;
      } else {
        this.selezionati[piatto] = 1;
      }
    }
  }

  calcolaQuoteSelezionate() {
    let totale = 0;

    if (this.quoteSelezionate !== undefined) {
      Object.keys(this.quoteSelezionate).forEach(quota => {
        if (this.quoteSelezionate[quota] && this.carrello[quota]) {
          totale += this.importoPerQuotaOriginale; // Usa l'importo originale invece di getPrezzoPiatto
        }
      });
    }

    return totale;
  }

  isSelectedForPiatto(piatto: any): number {
    if (this.selectedCategory === 'Romana') {
      return this.quoteSelezionate[piatto] || 0;
    } else {
      return this.selezionati[piatto] || 0;
    }
  }

  isSelected(category: string): boolean {
    return this.selectedCategory === category;
  }



  categoriaSelezionata = '';


  ciaociao(i): any {
    console.log("ciaociaociao: ", i);
    this.ciao = !this.ciao;
    this.categoriaSelezionata = i;
  }


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];
  carrello = {}
  camerieri: any = {
    id: "",
    statoPagato: 0,
    carrello: {}
  };
  pagamentoCartaAperto = 0;
  ifCarrello = false;
  carrelloTmp;
  ricerca = '';
  ricercato = false;
  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];

  currentIndex = -1;
  title = '';
  menu = new Array();
  menuFiltrato = new Array();

  user: User;


  isOver = false;


  ristorante = 'ciao';
  numeroTavolo = 0;


  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  nome = 'Marco Polo';

  ingredienti = 'Visualizza Profilo';

  prezzo = 93263269631;

  scrollato = false;

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];

  public scannerEnabled = false;

  public categoria = 0;
  public categoriaBefore = 0;

  public porcodioPrima = 0;
  public porcodioDopo = 0;
  public prece = 0;
  public selez = 0;

  tot = 0;
  numeroElementi = 0;
  tipoStampante='epson';
  posStatus: 'idle' | 'progress' | 'success' | 'error' | 'cancelled' = 'idle';

  @ViewChildren('cacaca') testDiv: QueryList<any>;
  isTestDivScrolledIntoView: boolean;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  currentUser = {}

  fatturazioneData: any = {
    denominazione: '',
    piva: '',
    codiceDestinatario: '',
    indirizzo: ''
  };
  showFatturazioneSection: boolean = false;

  ngOnInit(): void {

    if (this.camerieri && this.camerieri.romanaPayment) {
      this.numeroQuote = this.camerieri.romanaPayment.numeroQuote || this.coperti || 1;
      this.quotePagate = this.camerieri.romanaPayment.quotePagate || 0;
      this.importoPerQuotaOriginale = this.camerieri.romanaPayment.importoPerQuota || 0;
      this.totalePagamentoOriginale = this.camerieri.romanaPayment.totalePagamentoOriginale || 0;

      // Se non c'è un totale originale salvato ma ci sono quote pagate,
      // stima il totale originale in base alle quote già pagate
      if (this.totalePagamentoOriginale === 0 && this.quotePagate > 0 && this.importoPerQuotaOriginale > 0) {
        this.totalePagamentoOriginale = this.importoPerQuotaOriginale * this.numeroQuote;
      }
    }

    this.currentUser = this.dataService.getUserLocale();

    // If data is passed in object form with hasPrecontoPermission property
    console.log("user: ", this.dialogRef.componentInstance.currentUser);
    this.hasPrecontoPermission = this.dialogRef.componentInstance.currentUser?.permessi?.includes("Preconto") || false;

    // Verifica pager e fidelity
    //this.checkPagerAndFidelity();

    console.log("data: ", this.dialogRef.componentInstance.carrello);
    this.carrello = this.dialogRef.componentInstance.carrello;
    if(this.carrello.hasOwnProperty('Sconto')) {
      this.tipoSconto = this.carrello['Sconto']['tipo'];
      this.sconto = -this.carrello['Sconto']['prezzo'];
    }

// Carica i dati esistenti dell'ordine se disponibili
    if (this.camerieri) {
      // Carica la cronologia dei pagamenti se esiste
      this.paymentHistory = this.camerieri.paymentHistory || [];

      // Carica le informazioni sulla divisione alla romana
      if (this.camerieri.romanaPayment) {
        this.numeroQuote = this.camerieri.romanaPayment.numeroQuote || this.coperti || 1;
        this.quotePagate = this.camerieri.romanaPayment.quotePagate || 0;
        this.importoPerQuotaOriginale = this.camerieri.romanaPayment.importoPerQuota || 0;

        // Se c'è già stato un pagamento alla romana, blocca il cambio di modalità
        if (this.quotePagate > 0) {
          this.lockPaymentMode = true;
          this.selectedCategory = 'Romana';
        }
      }
    }

    this.paymentCashmaticService.paymentSuccess$.subscribe(
        (data) => {
          // Se un pagamento è già in corso, ignora questo evento
          if (this.paymentInProgress) return;

          this.paymentInProgress = true;
          this.paymentMessage = 'Pagamento completato con successo!';
          this.cashmaticAperto = 0;

          if(!this.preconto){
            // Salva il record di pagamento completato
            if (this.currentCashmaticPayment) {
              // Aggiorna con le informazioni di completamento
              this.currentCashmaticPayment.cashmaticInfo.completed = true;
              this.currentCashmaticPayment.cashmaticInfo.completionData = data;

              // Aggiungi il record alla cronologia
              if (!this.camerieri.paymentHistory) {
                this.camerieri.paymentHistory = [];
              }
              this.camerieri.paymentHistory.push(this.currentCashmaticPayment);

              // Salva nell'ordine
              if (this.camerieri.id) {
                this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
              }
            }

            // Gestione per pagamento alla romana
            if (this.selectedCategory === 'Romana') {
              let quoteSelezionate = Object.keys(this.quoteSelezionate).filter(key =>
                  this.quoteSelezionate[key] && this.carrello[key]);

              if (quoteSelezionate.length > 0) {
                // Se ci sono quote selezionate, processa quelle
                this.quotePagate += quoteSelezionate.length;

                // Stampa le quote selezionate mantenendo l'importo originale
                let selectedItems = quoteSelezionate.map(key => ({
                  ...this.carrello[key],
                  prezzo: this.importoPerQuotaOriginale,
                  price: this.importoPerQuotaOriginale
                }));

                this.ipcService.PrintFiscalReceipt(
                    this.stampaScontrino(selectedItems, 'contanti'),
                    this.tipoStampante
                );

                // Rimuovi le quote pagate dal carrello
                quoteSelezionate.forEach(key => {
                  delete this.carrello[key];
                  delete this.quoteSelezionate[key];
                });

                // Aggiorna l'informazione delle quote nell'ordine
                if (this.camerieri) {
                  this.camerieri.romanaPayment = {
                    numeroQuote: this.numeroQuote,
                    quotePagate: this.quotePagate,
                    importoPerQuota: this.importoPerQuotaOriginale,
                    quotePagateDettaglio: [
                      ...(this.camerieri.romanaPayment?.quotePagateDettaglio || []),
                      ...quoteSelezionate
                    ]
                  };

                  // Blocca il cambio di modalità
                  this.lockPaymentMode = true;
                }
              } else {
                // Se nessuna quota è specificatamente selezionata, paga una quota singola
                let quoteKeys = Object.keys(this.carrello).filter(key =>
                    key.startsWith('Quota_'));

                if (quoteKeys.length > 0) {
                  // Prendi la prima quota disponibile
                  const quotaKey = quoteKeys[0];
                  const item = {
                    ...this.carrello[quotaKey],
                    prezzo: this.importoPerQuotaOriginale,
                    price: this.importoPerQuotaOriginale
                  };

                  // Stampa la quota con l'importo originale
                  this.ipcService.PrintFiscalReceipt(
                      this.stampaScontrino([item], 'contanti'),
                      this.tipoStampante
                  );

                  // Rimuovi la quota dal carrello
                  delete this.carrello[quotaKey];
                  this.quotePagate++;

                  // Aggiorna l'informazione delle quote nell'ordine
                  if (this.camerieri) {
                    this.camerieri.romanaPayment = {
                      numeroQuote: this.numeroQuote,
                      quotePagate: this.quotePagate,
                      importoPerQuota: this.importoPerQuotaOriginale,
                      quotePagateDettaglio: [
                        ...(this.camerieri.romanaPayment?.quotePagateDettaglio || []),
                        quotaKey
                      ]
                    };

                    // Blocca il cambio di modalità
                    this.lockPaymentMode = true;
                  }
                } else {
                  // Fallback: stampa ricevuta normale
                  this.printReceipt('', 'contanti');
                }
              }

              // Aggiorna lo stato dell'ordine
              if (this.quotePagate < this.numeroQuote) {
                this.camerieri.statoPagato = 1; // Parzialmente pagato
              } else {
                this.camerieri.statoPagato = 0; // Completamente pagato
                this.camerieri.closureOrderTime = new Date().getTime().toString();
              }

              // Aggiorna l'ordine
              if (this.camerieri.id) {
                this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
              }

              // Controlla se l'ordine è completo
              this.checkOrderComplete();
            } else {
              // Comportamento standard per altri tipi di pagamento
              this.printReceipt('', 'contanti');
            }
          } else {
            this.printPreconto('apri', 'contanti', true);
          }

          // Reimposta il flag dopo un breve ritardo
          setTimeout(() => {
            this.paymentInProgress = false;
            this.currentCashmaticPayment = null;
          }, 500);
        }
    );

    this.loadWorkstationPrinters();
    this.loadPredefinedDiscounts();
    this.loadCassettoAutomaticoSettings();
  }

  loadCassettoAutomaticoSettings(): void {
    const restaurantId = this.dataServiceGenerico.getRistorante();

    firebase.firestore()
        .collection('workstations')
        .where('ristoranteId', '==', restaurantId)
        .get()
        .then(snapshot => {
          // Inizializza come false
          this.cassettAutomaticoEnabled = false;

          snapshot.forEach(doc => {
            const workstation = doc.data();
            // Se almeno una workstation ha il cassetto abilitato, consideriamo la funzionalità come abilitata
            if (workstation.cashmatic && workstation.cashmatic.enabled) {
              this.cassettAutomaticoEnabled = true;
            }
          });

          console.log('Cassetto automatico abilitato:', this.cassettAutomaticoEnabled);
        })
        .catch(error => {
          console.error('Errore nel caricamento delle impostazioni del cassetto automatico:', error);
          this.cassettAutomaticoEnabled = false; // Default a disabilitato in caso di errore
        });
  }

  private checkPagerAndFidelity() {
    if (this.camerieri) {
      this.hasPager = !!this.camerieri.pager;
      this.hasFidelity = !!this.camerieri.userId;
    }
  }

  showRequiredItemsDialog() {
    const missingItems = [];
    if (!this.hasPager) missingItems.push('pager');
    if (!this.hasFidelity) missingItems.push('carta fedeltà');

    if (missingItems.length > 0) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: {
          title: 'Elementi mancanti',
          message: `Mancano i seguenti elementi: ${missingItems.join(', ')}. Vuoi aggiungerli ora?`,
          showCancel: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (!this.hasPager) this.assignPager();
          if (!this.hasFidelity) this.openDialogFidelity();
        }
      });
    }
  }

  // Aggiungi questi metodi alla classe DialogPagamento
  assignPager() {
    const dialogRef = this.dialog.open(PagerDialogComponent, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.camerieri = {
          ...this.camerieri,
          pager: result
        };

        this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
        this.hasPager = true;
      }
    });
  }

  async openDialogFidelity(): Promise<void> {
    const restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();
    const dialogRef = this.dialog.open(Fidelity, {
      panelClass: 'custom-dialog-container',
      data: {
        restaurantId: this.dataServiceGenerico.getRistorante()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.user) {
        this.camerieri.userId = result.user.id;

        if (result.carrelloItem) {
          const itemId = result.carrelloItem.id.replace(/ /g,'');
          this.carrello[itemId] = result.carrelloItem;
        }

        this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
        this.hasFidelity = true;
      }
    });
  }


  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  valuechange(newValue): any {
    console.log(this.ricerca);
    this.menuFiltrato = new Array();
    this.menuFiltrato = this.filterByValue(this.menu, newValue);
    console.log('menuCard filtrato');
    console.log(this.menuFiltrato);
    if (newValue.length > 0) {
      this.ricercato = true;
    } else {
      this.ricercato = false;
    }
    // this.scontrini.filter(t => t.nome === newValue);

  }

  filterByValue(array, stringa): any {


    const result = [];

    /*
    result.concat(array.forEach(element => {

      console.log('element');
      console.log(element);
      element.menuCard.filter(o => {
        console.log('o');
        console.log(o);
        try {
          Object.keys(o).some(k => o[k].toLowerCase().includes(stringa.toLowerCase));
        }catch {}
      });

    }));

     */

    array.forEach(element => {
      console.log('element');
      console.log(element.menu);
      console.log('element filtrato');
      const filtrato = this.filterByValueInside(element.menu, stringa);
      console.log(filtrato);
      if (filtrato.length > 0) {
        result.push(filtrato);
      }
    });
    console.log('result');
    console.log(result);
    const risultato = new Array();
    risultato.push(new Array());
    result.forEach(element => risultato[0] = risultato[0].concat(element));
    console.log('risultato');
    console.log(risultato);
    return risultato;
  }

  filterByValueInside(arrayy, stringaa): any {

    console.log('filtro: ', arrayy, stringaa);

    // console.log('array');
    // console.log(array);
    return arrayy.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringaa.toLowerCase())));
  }



  menuBello = []
  categorie = []

  filtraMenu(name: String)
  {
    if(name !== 'Bucket')
    {
      return this.menuBello.filter((value) => value.category == name)
    }
    else
    {
      if((this.bucketLvl == 0))
      {
        return this.menuBello.filter((value) => value.category == name)
      }
      if((this.bucketLvl > 0))
      {
        return this.menuBello.filter((value) => value.category == name).filter((value) => value.lvl == this.bucketLvl)
      }
    }


  }

  getImage(name: String)
  {
    if(this.menuBello.length > 0)
    {
      return this.menuBello[this.menuBello.findIndex((value) => value.category == name)].hasOwnProperty('image_link')?this.menuBello[this.menuBello.findIndex((value) => value.category == name)].image_link: ''
    }
    else
    {
      return '';
    }
  }


  calcolaTotaleBello() {
    let sconto = 0;

    if (this.carrello !== undefined && this.carrello.hasOwnProperty('Sconto')) {
      sconto = -this.carrello['Sconto'].prezzo;
    }

    this.numeroElementi = 0;
    this.tot = 0;

    if (this.carrello !== undefined) {
      this.getProperties(this.carrello).forEach(piatto => {
        if (piatto !== 'Sconto') {
          this.numeroElementi = this.numeroElementi + (this.carrello[piatto].quantita? this.carrello[piatto].quantita: 1);
          let prezzoPiatto = this.getPrezzoPiatto(this.carrello[piatto]);
          this.tot = this.tot + (prezzoPiatto * (this.carrello[piatto].quantita? this.carrello[piatto].quantita: 1));
        }
      });
    }

    return this.tot - sconto;
  }



  testPay(deliveryNote?: string) {
    this.cashmaticAperto = 1;
    let stringa = [];
    let importoDaPagare = 0;
    let itemsToPay = [];
    let quotePagate = [];

    // Salva la nota di delivery nell'ordine se specificata
    if (deliveryNote && this.camerieri) {
      this.camerieri.nota = deliveryNote;
    }

    if (this.selectedCategory === 'Romana') {
      // Codice per pagamento alla romana
      let totalToPay = 0;

      Object.keys(this.quoteSelezionate).forEach(key => {
        if (this.quoteSelezionate[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          itemsToPay.push(item);
          quotePagate.push(key);
          totalToPay += this.importoPerQuotaOriginale; // Usa l'importo originale invece di ricalcolare
        }
      });

      if (itemsToPay.length > 0) {
        importoDaPagare = totalToPay;
        stringa = itemsToPay;
      } else {
        // Se nessun item è selezionato, usa l'importo fisso di una quota singola
        importoDaPagare = this.importoPerQuotaOriginale;

        // Trova la prima quota disponibile
        let quoteKeys = Object.keys(this.carrello).filter(key => key.startsWith('Quota_'));
        if (quoteKeys.length > 0) {
          stringa.push(this.carrello[quoteKeys[0]]);
          itemsToPay.push(this.carrello[quoteKeys[0]]);
          quotePagate.push(quoteKeys[0]);
        } else {
          this.getProperties(this.carrello).forEach(key => {
            stringa.push(this.carrello[key]);
            itemsToPay.push(this.carrello[key]);
          });
        }
      }

      // Blocca il cambio di modalità dopo il primo pagamento alla romana
      if (itemsToPay.length > 0 || quotePagate.length > 0) {
        this.lockPaymentMode = true;
      }
    } else if (this.selectedCategory === 'Singolo') {
      importoDaPagare = this.calcolaSelezionati();
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          stringa.push(this.carrello[key]);
          itemsToPay.push(this.carrello[key]);
        }
      });
    } else {
      importoDaPagare = this.calcolaTotaleBello();
      this.getProperties(this.carrello).forEach(key => {
        stringa.push(this.carrello[key]);
        itemsToPay.push(this.carrello[key]);
      });
    }

    // Salva le informazioni sul pagamento cashmatic
    if (!this.preconto) {
      // Genera l'XML dello scontrino
      const receiptXml = this.stampaScontrino(itemsToPay, 'contanti');

      // Prepara il record di pagamento
      const paymentRecord = {
        timestamp: new Date().getTime(),
        method: 'contanti-automatico',
        category: this.selectedCategory,
        items: itemsToPay,
        receiptXml: receiptXml,
        amount: importoDaPagare,
        cashmaticInfo: {
          requested: importoDaPagare
        },
        quotePagate: this.selectedCategory === 'Romana' ? quotePagate : [],
        nota: deliveryNote // Aggiungi la nota di delivery se presente
      };

      // Salva temporaneamente il record di pagamento in attesa di conferma
      this.currentCashmaticPayment = paymentRecord;
    }

    this.paymentCashmaticService.testPay(importoDaPagare, this.stampaScontrino(stringa, ''));
  }

  testPayWithNote(deliveryPlatform: string) {
    // Salva la piattaforma di delivery nell'ordine per riferimento futuro
    if (this.camerieri) {
      this.camerieri.nota = deliveryPlatform;
    }

    // Procedi con il pagamento tramite cassetto automatico passando la nota
    this.testPay(deliveryPlatform);
  }



  annullaPagamento() {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  getTransaction() {
    this.paymentCashmaticService.getTransaction();
  }

  printReceipt(cassetto: string, pagamento: string): void {
    let itemsToPrint = [];
    let receiptData = null;

    if (this.selectedCategory === 'Singolo') {
      // Codice esistente per il pagamento singolo
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = { ...this.carrello[key] };
          const originalKey = this.removeLastNumberSuffix(key);

          if (this.camerieri.carrello[originalKey]?.isPaid) {
            this.camerieri.carrello[originalKey].isPaidQuantity += this.selezionati[key];
          } else {
            this.camerieri.carrello[originalKey] = {
              ...item,
              isPaid: true,
              isPaidQuantity: this.selezionati[key]
            };
          }

          item.quantita = this.selezionati[key];
          item.title = item.title.split('_')[0];
          itemsToPrint.push(item);

          delete this.carrello[key];
          delete this.selezionati[key];
        }
      });
    } else if (this.selectedCategory === 'Romana') {
      // Codice per il pagamento alla romana
      let quoteCount = 0;
      let quotePagate = [];

      Object.keys(this.quoteSelezionate).forEach(key => {
        if (this.quoteSelezionate[key] && this.carrello[key]) {
          const item = { ...this.carrello[key] };
          item.quantita = 1;  // Ogni quota ha quantità 1
          itemsToPrint.push(item);
          quotePagate.push(key);

          // Rimuovi la quota dal carrello e dalle selezioni
          delete this.carrello[key];
          delete this.quoteSelezionate[key];

          quoteCount++;
        }
      });

      // Se nessuna quota è specificatamente selezionata, paga una quota singola
      if (quoteCount === 0 && Object.keys(this.carrello).length > 0) {
        let quoteKeys = Object.keys(this.carrello).filter(key => key.startsWith('Quota_'));
        if (quoteKeys.length > 0) {
          const quotaKey = quoteKeys[0];
          const item = { ...this.carrello[quotaKey] };
          itemsToPrint.push(item);
          quotePagate.push(quotaKey);
          delete this.carrello[quotaKey];
          quoteCount = 1;
        }
      }

      // Incrementa il contatore delle quote pagate
      this.quotePagate += quoteCount;

      // Blocca il cambio di modalità dopo il primo pagamento alla romana
      if (quoteCount > 0) {
        this.lockPaymentMode = true;
      }

      // Aggiorna le informazioni sulla divisione romana nell'ordine
      if (this.camerieri) {
        this.camerieri.romanaPayment = {
          numeroQuote: this.numeroQuote,
          quotePagate: this.quotePagate,
          importoPerQuota: this.importoPerQuotaOriginale,
          quotePagateDettaglio: [
            ...(this.camerieri.romanaPayment?.quotePagateDettaglio || []),
            ...quotePagate
          ]
        };
      }

      // Se è stata pagata almeno una quota ma non tutte, imposta lo stato a 1 (parzialmente pagato)
      if (quoteCount > 0 && this.quotePagate < this.numeroQuote) {
        this.camerieri.statoPagato = 1;
      }
    } else {
      // Codice per il pagamento totale
      this.getProperties(this.carrello)?.forEach(key => {
        if (key !== 'Sconto') {
          itemsToPrint.push(this.carrello[key]);
        }
      });
    }

// Genera l'XML dello scontrino
    const receiptXml = this.stampaScontrino(itemsToPrint, pagamento);

// Crea l'oggetto per la cronologia dei pagamenti
    const paymentRecord = {
      timestamp: new Date().getTime(),
      method: pagamento,
      category: this.selectedCategory,
      items: itemsToPrint,
      receiptXml: receiptXml,
      amount: this.calculatePaymentAmount(itemsToPrint)
    };

// Aggiungi il record alla cronologia
    if (!this.camerieri.paymentHistory) {
      this.camerieri.paymentHistory = [];
    }
    this.camerieri.paymentHistory.push(paymentRecord);

// Salva il record nell'ordine
    if (this.camerieri.id) {
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    }

    if(this.preconto){
      const printerName = 'preconto';
      const printer = this.workstationPrinters.find(p => p.name === printerName);
      this.ipcService.printPreconto(this.stampaScontrinoPreconto(itemsToPrint), printer);
    } else{
      this.ipcService.PrintFiscalReceipt(
          receiptXml,
          this.tipoStampante
      );
    }

    this.checkOrderComplete();
  }




  // Helper method to handle Preconto button clicks with permission check
  handlePrecontoClick(action: string, type: string, value: boolean): void {
    if (this.hasPrecontoPermission) {
      this.preconto = true;
      if (action === 'printPreconto') {
        this.printPreconto('apri', type, value);
      } else if (action === 'testPay') {
        this.testPay();
      }
    }
  }

  printPreconto(cassetto: string, pagamento: string, pagato): void {
    let itemsToPrint = [];

    if (this.selectedCategory === 'Singolo') {
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = { ...this.carrello[key] };
          const originalKey = this.removeLastNumberSuffix(key);

          if (this.camerieri.carrello[originalKey]?.isPaid) {
            this.camerieri.carrello[originalKey].isPaidQuantity += this.selezionati[key];
          } else {
            this.camerieri.carrello[originalKey] = {
              ...item,
              isPaid: true,
              isPaidQuantity: this.selezionati[key]
            };
          }

          item.quantita = this.selezionati[key];
          item.title = item.title.split('_')[0];
          itemsToPrint.push(item);

          delete this.carrello[key];
          delete this.selezionati[key];
        }
      });
    } else if (this.selectedCategory === 'Romana') {
      this.getProperties(this.carrello)?.forEach(key => {
        if (key !== 'Sconto') {
          const item = { ...this.carrello[key] };
          item.quantita = 1;
          itemsToPrint.push(item);
        }
      });
    } else {
      this.getProperties(this.carrello)?.forEach(key => {
        if (key !== 'Sconto') {
          itemsToPrint.push(this.carrello[key]);
        }
      });
    }

// Genera il testo del preconto
    const precontoText = this.stampaScontrinoPreconto(itemsToPrint);

// Crea l'oggetto per la cronologia dei pagamenti
    const precontoRecord = {
      timestamp: new Date().getTime(),
      method: 'preconto',
      category: this.selectedCategory,
      items: itemsToPrint,
      precontoText: precontoText,
      amount: this.calculatePaymentAmount(itemsToPrint)
    };

// Aggiungi il record alla cronologia
    if (!this.camerieri.precontoHistory) {
      this.camerieri.precontoHistory = [];
    }
    this.camerieri.precontoHistory.push(precontoRecord);

// Salva il record nell'ordine
    if (this.camerieri.id) {
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    }

    const printerName = 'preconto';
    console.log('NAME', this.camerieri.name);
    const printer = this.workstationPrinters.find(p => p.name === printerName);
    this.ipcService.printPreconto(precontoText, printer.ipAddress);

    if (pagato) {
      this.checkOrderComplete();
    }
  }

  removeLastNumberSuffix(key: string): string {

    // Cerca un pattern che corrisponde a _N alla fine della stringa
    const lastUnderscoreIndex = key.lastIndexOf('_');
    if (lastUnderscoreIndex === -1) return key;

    // Verifica se dopo l'ultimo underscore c'è solo un numero
    const lastPart = key.slice(lastUnderscoreIndex + 1);
    if (/^\d+$/.test(lastPart)) {
      // Se è così, ritorna la stringa senza l'ultimo _N
      return key.slice(0, lastUnderscoreIndex);
    }
    return key;
  }

// Add this new method to check requirements before payment
  private checkPaymentRequirements(): boolean {
    if (!this.hasPager) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '400px',
        data: {
          title: 'Pager Richiesto',
          message: 'È necessario assegnare un pager prima di procedere con il pagamento.',
          showCancel: true
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.assignPager();
        }
      });
      return false;
    }
    return true;
  }

// Update sendTransaction method
  sendTransaction() {
    this.pagamentoCartaAperto = 1;
    let stringa = [];
    let itemsToPay = [];
    let quotePagate = [];

    if (this.selectedCategory === 'Singolo') {
      // Codice per singolo
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          item.title = item.title.split('_')[0];
          stringa.push(item);
          itemsToPay.push(item);
        }
      });
      this.posTransactionService.sendTransaction(
          this.calcolaSelezionati(),
          this.stampaScontrino(stringa, 'carta')
      );
    } else if (this.selectedCategory === 'Romana') {
      // Codice per pagamento alla romana con importo originale
      let itemsToPayRomana = [];
      let totalToPay = 0;

      Object.keys(this.quoteSelezionate).forEach(key => {
        if (this.quoteSelezionate[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          itemsToPayRomana.push(item);
          itemsToPay.push(item);
          quotePagate.push(key);
          totalToPay += this.importoPerQuotaOriginale; // Usa l'importo originale
        }
      });

      if (itemsToPayRomana.length > 0) {
        // Crea temporaneamente un record di transazione in attesa
        const pendingTransaction = {
          timestamp: new Date().getTime(),
          method: 'carta-pending',
          category: 'Romana',
          items: itemsToPay,
          amount: totalToPay,
          quotePagate: quotePagate
        };

        // Memorizza per il callback
        this.currentPosTransaction = pendingTransaction;

        this.posTransactionService.sendTransaction(
            totalToPay,
            this.stampaScontrino(itemsToPayRomana, 'carta')
        );
      } else {
        // Se nessuna quota è selezionata, paga una quota singola
        let quoteKeys = Object.keys(this.carrello).filter(key => key.startsWith('Quota_'));
        if (quoteKeys.length > 0) {
          const quotaKey = quoteKeys[0];
          const item = this.carrello[quotaKey];
          itemsToPay.push(item);
          quotePagate.push(quotaKey);

          // Crea temporaneamente un record di transazione in attesa
          const pendingTransaction = {
            timestamp: new Date().getTime(),
            method: 'carta-pending',
            category: 'Romana',
            items: [item],
            amount: this.importoPerQuotaOriginale,
            quotePagate: [quotaKey]
          };

          // Memorizza per il callback
          this.currentPosTransaction = pendingTransaction;

          this.posTransactionService.sendTransaction(
              this.importoPerQuotaOriginale,
              this.stampaScontrino([item], 'carta')
          );
        }
      }
    } else {
      // Codice per totale
      this.getProperties(this.carrello)?.forEach(key => {
        stringa.push(this.carrello[key]);
        itemsToPay.push(this.carrello[key]);
      });

      // Crea temporaneamente un record di transazione in attesa
      const pendingTransaction = {
        timestamp: new Date().getTime(),
        method: 'carta-pending',
        category: 'Totale',
        items: itemsToPay,
        amount: this.calcolaTotaleBello()
      };

      // Memorizza per il callback
      this.currentPosTransaction = pendingTransaction;

      this.posTransactionService.sendTransaction(
          this.calcolaTotaleBello(),
          this.stampaScontrino(stringa, 'carta')
      );
    }
  }

  /*stampaScontrino(menu: any[]): string {
    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;


    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * item.price;
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------

  `;
    console.log(header + scontrino + footer);

    return header + scontrino + footer;
  }

   */

  getPrezzoPiatto(piatto)
  {
    if(piatto?.hasOwnProperty('aggiunte'))
    {
      return piatto?.prezzo + piatto?.aggiunte.length
    }
    else
    {
      return piatto?.prezzo
    }
  }

  getPrezzoScontato(item: any, key: string): number {
    if (this.itemDiscounts[key]) {
      return this.itemDiscounts[key].originalPrice - this.itemDiscounts[key].value;
    }
    return this.getPrezzoPiatto(item);
  }


  stampaScontrino(menu: any[], pagamento: string): string {
    let xml = `<?xml version="1.0" encoding="utf-8"?>
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
<soapenv:Body>
<printerFiscalReceipt>
<beginFiscalReceipt operator="1" />\n`;

    // Calcola il totale direttamente qui
    let totaleArticoli = 0;

    // Aggiungi gli articoli all'XML e calcola il totale
    menu.forEach(item => {
      if (item) {
        const prezzo = this.getPrezzoPiatto(item);
        const quantita = item.quantita ? item.quantita : 1;
        const reparto = item?.hasOwnProperty('vatId') ? item?.vatId : '2';

        if (prezzo > 0) {
          // Articolo normale con prezzo positivo
          xml += `<printRecItem operator="1" description="${item.title}" quantity="${quantita}" unitPrice="${prezzo.toFixed(2)}" department="${reparto}" justification="1" />\n`;

          // Aggiungi al totale
          totaleArticoli += prezzo * quantita;
        }
      }
    });

    // Gestione degli sconti (articoli con prezzo negativo)
    menu.forEach(item => {
      if (item) {
        const prezzo = this.getPrezzoPiatto(item);
        const reparto = item?.hasOwnProperty('vatId') ? item?.vatId : '2';

        if (prezzo < 0) {
          // Sconto/valore negativo - usiamo il valore assoluto per l'amount
          xml += `<printRecItemAdjustment operator="1" adjustmentType="0" description="${item.title}" amount="${Math.abs(prezzo).toFixed(2)}" department="${reparto}" justification="1" />\n`;

          // Gli sconti riducono il totale
          totaleArticoli += prezzo;
        }
      }
    });

    // Se il totale è negativo o zero, impostiamo un valore minimo
    if (totaleArticoli <= 0) {
      totaleArticoli = 0.01;
    }

    // Aggiungiamo il riferimento al cameriere
    if (this.camerieri && this.camerieri.displayName) {
      xml += `<printRecMessage operator="1" message="Riferimento: ${this.camerieri.displayName}" messageType="3" index="1" font="1" />\n`;
    }

    // Gestione dei dati di fatturazione se presenti
    if (this.showFatturazioneSection && this.fatturazioneData) {
      if (this.fatturazioneData.denominazione) {
        xml += `<printRecMessage operator="1" message="Denominazione: ${this.fatturazioneData.denominazione}" messageType="3" index="2" font="1" />\n`;
      }
      if (this.fatturazioneData.piva) {
        xml += `<printRecMessage operator="1" message="P.IVA: ${this.fatturazioneData.piva}" messageType="3" index="3" font="1" />\n`;
      }
      if (this.fatturazioneData.codiceDestinatario) {
        xml += `<printRecMessage operator="1" message="Cod.Dest.: ${this.fatturazioneData.codiceDestinatario}" messageType="3" index="4" font="1" />\n`;
      }
      if (this.fatturazioneData.indirizzo) {
        xml += `<printRecMessage operator="1" message="Indirizzo: ${this.fatturazioneData.indirizzo}" messageType="3" index="5" font="1" />\n`;
      }
    }

    // Stampa del subtotale
    xml += `<printRecSubtotal operator="1" option="1" />\n`;

    // Gestione del pagamento - usiamo il totale calcolato direttamente qui
    if (pagamento === 'carta') {
      xml += `<printRecTotal operator="1" description="Pagamento con carta" payment="${totaleArticoli.toFixed(2)}" paymentType="2" index="1" justification="2" />\n`;
    } else {
      xml += `<printRecTotal operator="1" description="Pagamento in contanti" payment="${totaleArticoli.toFixed(2)}" paymentType="0" index="1" justification="2" />\n`;
    }

    xml += `<endFiscalReceipt operator="1" />
</printerFiscalReceipt>
</soapenv:Body>
</soapenv:Envelope>`;

    console.log("TOTALE CALCOLATO:", totaleArticoli);
    console.log("XML SCONTRINO:", xml);
    return xml;
  }


  printReceiptWithNote(cassetto: string, pagamento: string, deliveryPlatform: string): void {
    // Salva la piattaforma di delivery nell'ordine per riferimento futuro
    if (this.camerieri) {
      this.camerieri.nota = deliveryPlatform;
    }

    // Procedi con la normale stampa, usando la funzione standard
    // Mantenendo "Pagamento Elettronico" come metodo di pagamento sullo scontrino fiscale
    this.printReceipt(cassetto, 'carta');
  }

  stampaScontrinoPreconto(menu: any[]): string {


    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28;
    const spaceBetweenFields = ' '.repeat(2);

    let totale = 0;

    const scontrino = menu.map(item => {
      if (this.getPrezzoPiatto(item) != 0){
        const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
        totale += totalePrezzo;
        const prodotto = item.title.length > maxLength
            ? item.title.slice(0, maxLength)
            : item.title.padEnd(maxLength);
        const quantita = item.quantita.toString().padStart(3);
        const prezzo = totalePrezzo.toFixed(2).padStart(6);
        const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%';

        return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
      }
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;




    const footer = `
      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------
      
  Riferimento: ${this.camerieri.displayName}  
  `;

    return header + scontrino + footer;
  }


  calcolaSelezionati() {
    let totale = 0;

    if (this.selezionati !== undefined) {
      Object.keys(this.selezionati).forEach(piatto => {
        if (this.selezionati[piatto] && this.carrello[piatto]) {
          let prezzoPiatto = this.getPrezzoPiatto(this.carrello[piatto]);
          totale += prezzoPiatto;  // Ogni item ha quantità 1 in modalità singola
        }
      });
    }

    return totale;
  }



  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.menuBello = data.items; // Recupero e assegno i dati del menu
        this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

        console.log('Menu recuperato da Firebase:', this.menuBello);
      } else {
        console.log('Nessun documento trovato su Firebase.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }



  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function( element ) {
      return element !== undefined;
    });
  }

  clickMenu(event): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.router.navigate(['/carrello']);
    event.stopPropagation();
  }

  // tslint:disable-next-line:typedef
  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
        this.items,
        event.previousIndex,
        event.currentIndex
    );
  }

  clicco2(): void {
    this.router.navigate(['/tavoli']);
  }


  clicco3(): void {
    this.router.navigate([this.ristorante + '/menu/modifica/modifica']);
  }

  clickPiatto(piatto?: string): void {
    this.router.navigate([this.ristorante + '/menu/' + piatto]); // , { skipLocationChange: true});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    let offset = 300;
    if (this.ricercato === true) {
      offset = 280;
    } else {
      offset = 310;
    }
    // 350 with search bar
    // 280 without search bar
    if (window.pageYOffset > offset) {
      console.log('scrollato');

      const element3 = document.getElementById('menuListaPiatti');
      element3.classList.add('menuScrolled');
      element3.classList.remove('menu');
      this.scrollato = true;

    } else {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {

        console.log('scrollato2');
      } else {
        console.log('scrollato3');
        this.scrollato = false;
        const element3 = document.getElementById('menuListaPiatti');
        element3.classList.add('menu');
        element3.classList.remove('menuScrolled');
      }
    }

    console.log(this.testDiv);


    if (this.ricercato === false) {

      this.testDiv.forEach((child) => {

        if (child) {
          const test = child.nativeElement.id;
          const rect = child.nativeElement.getBoundingClientRect();
          const topShown = rect.top >= 0;
          const bottomShown = rect.bottom <= window.innerHeight;
          this.isTestDivScrolledIntoView = topShown && bottomShown;


          console.log('top/bottom: ' + (rect.top / rect.bottom));

          if (this.isTestDivScrolledIntoView) {
            this.porcodioPrima = window.pageYOffset;
            this.porcodioDopo = window.pageYOffset;
            this.categoria = test;
            this.categoriaBefore = test;


          }


          this.porcodioDopo = window.pageYOffset;


          if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 0.75)) {
            console.log('altezza su');


            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo((test * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + test);
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                  cathegories => {
                    if (conta !== test) {
                      const elemento = document.getElementById('buttonCategoria' + conta);
                      elemento.classList.remove('categorieVisibile');
                      elemento.classList.add('categorie');
                    }
                    conta++;
                  }
              );

              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');

            }

            const element2 = document.getElementById('buttonCategoria' + (test - 1));
            if (element2 !== null) {


              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          } else if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 1)) {
            console.log('altezza giu');

            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo(((test - 1) * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + (test - 1));
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                  cathegories => {
                    if (conta !== (test - 1)) {
                      const elemento = document.getElementById('buttonCategoria' + (conta));
                      elemento.classList.remove('categorieVisibile');
                      elemento.classList.add('categorie');
                    }
                    conta++;
                  }
              );


              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');
            }

            const element2 = document.getElementById('buttonCategoria' + test);
            if (element2 !== null) {
              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          }


        }


        console.log(this.isTestDivScrolledIntoView);
      });

    }

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);

    const yOffset = -150;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});


    // const el = document.getElementById(id).scrollIntoView();
    // el.scrollIntoView({behavior: 'smooth'});
  }


  rimuoviElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    if (this.carrelloTmp.piatti[i].quantita === 1) {
      this.carrelloTmp.piatti.splice(i, 1);
      console.log('carrelo con rimozione: ', this.carrelloTmp);

    } else {
      this.carrelloTmp.piatti[i].quantita--;
    }

    this.calcolaTotale();

    this.salva();


    event.stopPropagation();
  }

  aggiungiElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    this.carrelloTmp.piatti[i].quantita = this.carrelloTmp.piatti[i].quantita + 1;

    this.calcolaTotale();

    this.salva();

    event.stopPropagation();
  }

  getPrezzo(i): any {
    // console.log('ciaobiuyew giuye231<giue23<y789e23<');
    // console.log("carrello: ", this.carrelloTmp);
    // console.log("index: ", i);


    // console.log('piatto', this.carrelloTmp.piatti[i]);

    let prezzoPiatto = this.carrelloTmp.piatti[i].prezzo;

    if (this.carrelloTmp.piatti[i].opzioni) {
      this.carrelloTmp.piatti[i].opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }

    return prezzoPiatto;
  }


  calcolaTotale(): any {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });

    return this.tot;
  }


  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log('user: ', this.user);

    this.user.carrello = this.carrelloTmp;

    console.log('user da salvare: ', this.user);

    this.carrelloService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.carrelloService.create(userDasalvareSalva, this.user.uid);
    // this.router.navigate(['/menuCard']);
  }



  //sconti

  predefinedDiscounts: any[] = [];
  selectedDiscount: any = null;


  async loadPredefinedDiscounts() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) return;

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      this.predefinedDiscounts = data?.predefinedSconti || [];
    } catch (error) {
      console.error('Error loading predefined discounts:', error);
    }
  }


// Modifica la funzione applyPredefinedDiscount
  applyPredefinedDiscount(discount: any) {
    if (this.selectedCategory === 'Singolo') {
      // Verifica che non sia già applicato lo stesso sconto sugli stessi prodotti
      const selectedProducts = Object.keys(this.selezionati)
          .filter(key => this.selezionati[key] && !key.includes('Sconto'));

      // Rimuovi eventuali sconti precedenti
      if (this.carrello['Sconto']) {
        delete this.carrello['Sconto'];
        delete this.selezionati['Sconto'];
        if (this.camerieri?.carrello) {
          delete this.camerieri.carrello['Sconto'];
          // Rimuovi anche dall'ordineInserimento
          if (this.camerieri.ordineInserimento) {
            this.camerieri.ordineInserimento = this.camerieri.ordineInserimento.filter(item => item !== 'Sconto');
          }
        }
      }

      let totalDiscount = 0;
      let discountedItems = [];

      selectedProducts.forEach(key => {
        const item = this.carrello[key];
        if (item) {
          // Verifica che non sia già stato applicato lo stesso sconto
          if (!item.appliedDiscounts || !item.appliedDiscounts.includes(discount.nome)) {
            const originalPrice = this.getPrezzoPiatto(item);
            let discountValue = discount.tipo === 'percentage'
                ? (originalPrice * discount.valore / 100)
                : discount.valore;

            totalDiscount += discountValue;
            discountedItems.push(item.title);

            // Marca il prodotto come scontato
            if (!item.appliedDiscounts) {
              item.appliedDiscounts = [];
            }
            item.appliedDiscounts.push(discount.nome);
            this.selezionati[key] = 1;
          }
        }
      });

      if (totalDiscount > 0) {
        const scontoKey = 'Sconto';
        const scontoItem = {
          image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
          prezzo: -totalDiscount,
          price: -totalDiscount,
          tipo: true,
          title: `Sconto ${discount.tipo === 'percentage' ? discount.valore + '%' : discount.valore + '€'} su: ${discountedItems.join(', ')}`,
          quantita: 1
        };

        // Aggiungi al carrello del componente
        this.carrello[scontoKey] = scontoItem;

        // Aggiungi ai selezionati
        this.selezionati[scontoKey] = 1;

        // Aggiungi al carrello e ordineInserimento dell'ordine
        if (this.camerieri) {
          if (!this.camerieri.carrello) {
            this.camerieri.carrello = {};
          }
          this.camerieri.carrello[scontoKey] = scontoItem;

          // Aggiungi all'ordineInserimento se non è già presente
          if (!this.camerieri.ordineInserimento) {
            this.camerieri.ordineInserimento = [];
          }
          if (!this.camerieri.ordineInserimento.includes(scontoKey)) {
            this.camerieri.ordineInserimento.push(scontoKey);
          }
        }

        this.selectedDiscount = discount;
      }
    } else {
      // Gestione sconto sul totale
      if (this.selectedDiscount && this.selectedDiscount.nome === discount.nome) {
        this.selectedDiscount = null;
        if (this.carrello.hasOwnProperty('Sconto')) {
          delete this.carrello['Sconto'];
        }
        if (this.camerieri && this.camerieri.carrello && this.camerieri.carrello.hasOwnProperty('Sconto')) {
          delete this.camerieri.carrello['Sconto'];
          // Rimuovi anche dall'ordineInserimento
          if (this.camerieri.ordineInserimento) {
            this.camerieri.ordineInserimento = this.camerieri.ordineInserimento.filter(item => item !== 'Sconto');
          }
        }
        this.sconto = 0;
        return;
      }

      this.selectedDiscount = discount;
      let discountValue = discount.tipo === 'percentage'
          ? (this.calcolaTotaleBello() * discount.valore / 100)
          : discount.valore;

      const scontoItem = {
        image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
        prezzo: -discountValue,
        price: -discountValue,
        tipo: true,
        title: `Sconto ${discount.tipo === 'percentage' ? discount.valore + '%' : discount.valore + '€'} sul totale`,
        quantita: 1
      };

      // Aggiungi al carrello del componente
      this.carrello['Sconto'] = scontoItem;

      // Aggiungi al carrello dell'ordine
      if (this.camerieri) {
        if (!this.camerieri.carrello) {
          this.camerieri.carrello = {};
        }
        this.camerieri.carrello['Sconto'] = scontoItem;

        // Aggiungi all'ordineInserimento se non è già presente
        if (!this.camerieri.ordineInserimento) {
          this.camerieri.ordineInserimento = [];
        }
        if (!this.camerieri.ordineInserimento.includes('Sconto')) {
          this.camerieri.ordineInserimento.push('Sconto');
        }
      }

      this.tipoSconto = true;
      this.sconto = discountValue;
    }

    // Aggiorna il database
    if (this.camerieri && this.camerieri.id) {
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    }

    // Aggiorna il display secondario
    this.ipcService.updateSecondaryDisplay({carrello: this.carrello});
  }


  openBuoniPastoDialog() {
    const dialogRef = this.dialog.open(DialogBuoniPasto, {
      width: '400px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.carrello !== undefined) {
          this.carrello['BuoniPasto'] = {
            image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
            prezzo: -result.value,
            price: -result.value,
            quantita: result.quantity,
            tipo: true,
            title: `Buoni Pasto (${result.quantity}x€${result.value})`
          };

          this.ipcService.updateSecondaryDisplay({carrello: this.carrello});

        }
      }
    });
  }



  // Add these properties to the class DialogPagamento

// Barcode scanning related properties
  private barcodeBuffer: string = '';
  private barcodeTimeout: any;
  private readonly BARCODE_TIMEOUT = 50; // 50ms timeout after last keystroke

  @HostListener('window:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Prevent processing if we're in an input field
    if (event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement) {
      return;
    }

    // Clear previous timeout
    if (this.barcodeTimeout) {
      clearTimeout(this.barcodeTimeout);
    }

    // Add character to buffer
    this.barcodeBuffer += event.key;

    // Set new timeout
    this.barcodeTimeout = setTimeout(() => {
      if (this.barcodeBuffer.length > 0) {
        this.processBarcodeInput(this.barcodeBuffer);
        this.barcodeBuffer = ''; // Reset buffer after processing
      }
    }, this.BARCODE_TIMEOUT);
  }

// Process the barcode input
  private processBarcodeInput(barcode: string) {
    if (!barcode || barcode.length < 3) return; // Ignore sequences too short

    console.log('Processing barcode:', barcode);

    // Check if this is a discount barcode (starts with '222')
    if (barcode.startsWith('222')) {
      this.handleDiscountBarcode(barcode);
      return;
    }
  }

// Handle discount barcodes
  private async handleDiscountBarcode(barcode: string) {
    console.log('Discount barcode detected:', barcode);

    try {
      // Get the restaurant ID
      const restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();

      // Get Firestore instance
      const db = firebase.firestore();

      // Fetch the restaurant document
      const restaurantDoc = await db.collection('ristoranti').doc(restaurantId).get();

      if (!restaurantDoc.exists) {
        console.error('Restaurant document not found!');
        return;
      }

      const restaurantData = restaurantDoc.data();
      const clients = restaurantData?.clienti || [];

      // Find client with matching barcode
      const client = clients.find(person => person.barcodeId === barcode);

      if (client && client.sconto) {
        // Create a discount object compatible with applyPredefinedDiscount
        const discountObject = {
          nome: `Sconto Cliente: ${client.nome}`,
          tipo: 'percentage', // assuming the discount is a percentage
          valore: client.sconto
        };

        // Apply the discount
        this.applyPredefinedDiscount(discountObject);

        // Optional: Show feedback to the user
        console.log(`Discount applied for client: ${client.nome}, Discount: ${client.sconto}%`);
      } else {
        console.error('Client not found or no discount available for barcode:', barcode);
      }
    } catch (error) {
      console.error('Error handling discount barcode:', error);
    }
  }


}



/*
scroll(id: any): void {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  //el.scrollIntoView({behavior: 'smooth'});
  el.scrollIntoView({ behavior: 'smooth' });

}

 */