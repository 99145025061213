import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../../menu/menu";
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";


@Component({
  selector: 'impostazioni-menu',
  templateUrl: './impostazioniMenu.html',
  styleUrls: ['./impostazioniMenu.css']
})
  export class ImpostazioniMenu implements OnInit{


  displayedColumns: string[] = ['menu', 'gruppo', 'ristorante'];

  differente = true;
  ristoranti = []
  gruppi = []

  collegamenti = [

  ]

  compareObjects(o1: any, o2: any): boolean {
    // Verifica che entrambi gli oggetti esistano
    if (o1 && o2) {
      // Confronta i nomi degli oggetti
      return o1.nome === o2.nome;
    }
    // Se uno degli oggetti è null o undefined, considera che non sono uguali
    return false;
  }

  dragPosition = {x: 253, y: 244};

  dragPosition2 = {x: 253, y: 244};


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal,
    public dialog: MatDialog, private ipcService: IpcService, private posTransactionService: PosTransactionService, private dataServiceGenerico: DataServiceGenerico
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }

  dropped(e)
  {
    console.log("droppato: ", e)
    console.log("droppato 2: ", this.dragPosition)

  }

  ngOnInit(): void {
    this.collegamenti = this.dataServiceGenerico.getCollegamenti()
    this.ristoranti = this.dataServiceGenerico.getRistoranti()
    this.gruppi = this.dataServiceGenerico.getGruppi()

  }




}
