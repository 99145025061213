import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ordini-card',
  styles: [`
    .card-scontrino {
      background-color: white;
      border-radius: 20px;
      border: 1px solid #e3e2e2;
      transition: 0.3s;
      margin-top: 10px;
      position: relative;
      overflow: hidden;
      touch-action: pan-x pan-y;
      user-select: none;
    }

    .card-scontrino.selected {
      border-left: 3px solid #353555;
    }

    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .container-scontrino {
      padding: 15px 5px 10px;
      height: 100px;
      width: 100%;
      background-color: white;
      transform: translateX(0);
      will-change: transform;
      transition: transform 0.2s ease-out;
    }

    .order-row {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      float: left;
      display: flex;
      align-items: initial;
      justify-content: left;
      margin-top: 10px;
      clear: left;
      width: 100%;
    }

    .order-number {
      margin-left: 10px;
      max-height: 1000px;
      padding: 0 5px 10px;
    }

    .order-total {
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 10px;
    }

    .status-container {
      font-weight: 600;
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 10px;
      display: grid;
      place-items: center;
    }

    .status-badge {
      padding: 0 5px;
      height: 32px;
      border-radius: 8px;
      display: grid;
      place-items: center;
      font-size: 11px;
    }

    .status-paid {
      color: rgb(8 107 8 / 70%);
      background-color: rgb(42 232 42 / 30%);
    }

    .status-unpaid {
      background-color: rgb(249 94 94 / 50%);
      color: rgb(149 0 0 / 70%);
    }

    .status-completed {
      color: rgba(0,119,255,0.7);
      background-color: rgba(0,119,255,0.32);
    }

    .status-processing {
      color: rgba(150,49,190,0.7);
      background-color: rgba(159,95,213,0.5);
    }

    .status-waiting {
      color: rgba(255, 152, 0, 0.7);
      background-color: rgba(255, 152, 0, 0.3);
    }

    .status-accepted {
      color: rgba(33, 150, 243, 0.7);
      background-color: rgba(33, 150, 243, 0.3);
    }

    .status-dispatched {
      color: rgba(0, 150, 136, 0.7);
      background-color: rgba(0, 150, 136, 0.3);
    }

    .bold {
      font-weight: bold;
    }

    .delete-button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 80px;
      background-color: #ff4444;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.2s ease-out;
    }

    .delete-button.visible {
      opacity: 1;
    }

    .delete-button button {
      background: none;
      border: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
      padding: 10px;
    }
  `],
  template: `
    <div class="card-scontrino" [class.selected]="selected">
      <!--div class="container-scontrino"
           #containerElement
           (touchstart)="onTouchStart($event)"
           (touchmove)="onTouchMove($event)"
           (touchend)="onTouchEnd()"
           (mousedown)="onMouseDown($event)"
           (mousemove)="onMouseMove($event)"
           (mouseup)="onMouseUp($event)"
           (mouseleave)="onMouseUp($event)"-->
        <div class="container-scontrino"
             #containerElement
             >
        <div class="order-row">
          <div class="order-number">
            <b>{{nome}}</b>
          </div>
          <div class="order-total">
            Totale: <span class="bold">{{ totale }}€</span>
          </div>
        </div>
        <div class="order-row">
          <tagComponent [fonte]="fonte"></tagComponent>
          <div class="status-container">
            <div *ngIf="statoPagato == 0 && !isDelivery()"
                 class="status-badge status-paid">
              Pagato
            </div>
            <div *ngIf="statoPagato == 1 && !isDelivery()"
                 class="status-badge status-unpaid">
              Non Pagato
            </div>
            <div *ngIf="statoPagato == 3"
                 class="status-badge status-completed">
              Completato
            </div>
            <div *ngIf="bloccato == true && statoPagato !== 3"
                 class="status-badge status-processing">
              In Preparazione
            </div>
            <div *ngIf="statoPagato == 1 && isDelivery() && stato!='Accettato'"
                 class="status-badge status-waiting">
              In Attesa 
            </div>
            <div *ngIf="isDelivery() && stato=='Accettato'"
                 class="status-badge status-accepted">
              In Lavorazione
            </div>
            <div *ngIf="statoPagato == 0 && isDelivery()"
                 class="status-badge status-dispatched">
              Evaso
            </div>
          </div>
        </div>
      </div>
      <!--div class="delete-button" [class.visible]="isDeleting" (click)="onDeleteClick()">
        <button>Elimina</button>
      </div-->
    </div>
  `
})
export class OrdiniCardComponent {
  @Input() stato: string;
  @Input() statoPagato: number;
  @Input() totale: string;
  @Input() fonte: string;
  @Input() nome: string;
  @Input() numero: string;
  @Input() bloccato: boolean;
  @Input() selected: boolean = false;
  @Output() delete = new EventEmitter<void>();

  @ViewChild('containerElement') containerElement: ElementRef;

  private touchStartX: number = 0;
  public isDeleting: boolean = false;
  private readonly SWIPE_THRESHOLD = 100;

  isDelivery(): boolean {

    return ['Just Eat', 'Deliveroo', 'Uber Eats', 'Glovo'].includes(this.fonte);
  }

  onTouchStart(event: TouchEvent) {
    event.stopPropagation();
    this.touchStartX = event.touches[0].clientX;
    console.log('Touch Start:', this.touchStartX);
  }

  onTouchMove(event: TouchEvent) {
    if (!this.touchStartX) return;
    event.stopPropagation();

    const currentX = event.touches[0].clientX;
    const deltaX = this.touchStartX - currentX;
    console.log('Touch Move, delta:', deltaX);

    if (deltaX > 0) {
      const transform = Math.max(-deltaX, -this.SWIPE_THRESHOLD);
      this.containerElement.nativeElement.style.transform = `translateX(${transform}px)`;
      this.isDeleting = deltaX > this.SWIPE_THRESHOLD / 2;
    }
  }

  onTouchEnd() {
    if (!this.touchStartX) return;

    const transform = this.containerElement.nativeElement.style.transform;
    const match = transform?.match(/translateX\(([-\d.]+)px\)/);
    const currentX = match ? parseFloat(match[1]) : 0;

    console.log('Touch End:', currentX);

    if (Math.abs(currentX) > this.SWIPE_THRESHOLD / 2) {
      this.onDeleteClick();
    } else {
      this.resetPosition();
    }

    this.touchStartX = 0;
  }

  onMouseDown(event: MouseEvent) {
    event.stopPropagation();
    this.touchStartX = event.clientX;
    console.log('Mouse Down:', this.touchStartX);
  }

  onMouseMove(event: MouseEvent) {
    if (!this.touchStartX) return;
    event.stopPropagation();

    const deltaX = this.touchStartX - event.clientX;
    console.log('Mouse Move, delta:', deltaX);

    if (deltaX > 0) {
      const transform = Math.max(-deltaX, -this.SWIPE_THRESHOLD);
      this.containerElement.nativeElement.style.transform = `translateX(${transform}px)`;
      this.isDeleting = deltaX > this.SWIPE_THRESHOLD / 2;
    }
  }

  onMouseUp(event: MouseEvent) {
    if (!this.touchStartX) return;
    event.stopPropagation();

    const transform = this.containerElement.nativeElement.style.transform;
    const match = transform?.match(/translateX\(([-\d.]+)px\)/);
    const currentX = match ? parseFloat(match[1]) : 0;

    console.log('Mouse Up:', currentX);

    if (Math.abs(currentX) > this.SWIPE_THRESHOLD / 2) {
      this.onDeleteClick();
    } else {
      this.resetPosition();
    }

    this.touchStartX = 0;
  }

  onDeleteClick() {
    console.log('Delete clicked');
    if (this.statoPagato == 1) {
      this.delete.emit();
    } else {
      this.resetPosition();
      alert('impossibile eliminare un ordine già pagato!');
    }
  }

  private resetPosition() {
    this.containerElement.nativeElement.style.transform = 'translateX(0)';
    this.isDeleting = false;
  }
}