<div class="row" style="height: 95vh; width:calc(100vw - 570px); margin-top: 15px; border-radius: 20px; overflow: scroll">

  <div style="">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Generali</div>
    <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


      <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">



        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Nome </label>
              <input type="text">
          </div>


        </div>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
        Salva Modifiche
      </div>

    </div>

  </div>

  <div style="">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Dati Aziendali</div>
    <div style="width: 90%; height: 550px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">

      <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Ragione sociale </label>
            <input type="text">
          </div>

          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> P.iva </label>
            <input type="text">
          </div>


        </div>

        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> SDI (solo per aziende italiane) </label>
            <input type="text">
          </div>


        </div>


        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Indirizzo </label>
            <input type="text">
          </div>

          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> CAP </label>
            <input type="text">
          </div>


        </div>

        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Città </label>
            <input type="text">
          </div>

          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Provincia </label>
            <input type="text">
          </div>


        </div>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
        Salva Modifiche
      </div>

    </div>

  </div>



  <div style="padding-bottom: 100px">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Dati Opzionali</div>
    <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">

      <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
            <label class="font-bold text-gray-700 text-heading-sm"> Sito web </label>
            <input type="text">
          </div>


        </div>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
        Salva Modifiche
      </div>

    </div>

  </div>



</div>


