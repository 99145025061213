<div class="container">
    <div class="sticky-container">
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90%20(1).png?alt=media&token=1631fb96-82a9-415e-b43e-3ca3c088e667"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Aggiungi Listino"
                 (click)="addMenu()">
        </div>
        <!--div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Modifiche"
                 (click)="saveMenus()">
        </div-->
    </div>

    <div class="content-container">
        <div class="menus-list">
            <fieldset class="custom-fieldset"
                      *ngFor="let menu of menus; let i = index; trackBy: trackByFn"
                      [attr.id]="'menu-' + i">
                <legend>Listino</legend>
                <div class="menu-item">
                    <input placeholder="Nome Listino"
                           class="custom-input"
                           [ngModel]="menus[i]"
                           (ngModelChange)="updateMenu(i, $event)"
                           #menuInput>
                    <div *ngIf="menu" class="delete-button" (click)="deleteMenu(menu)">
                        <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                             style="width: 24px; aspect-ratio: 1 / 1;"
                             title="Elimina Listino">
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</div>