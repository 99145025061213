<div class="row">

    <div class="calendar-container">
        <div class="orders-sidebar">
            <h2>Cronologia da Pianificare</h2>
            <div
                    cdkDropList
                    #pendingList="cdkDropList"
                    id="pending_list"
                    [cdkDropListData]="pendingOrders"
                    [cdkDropListConnectedTo]="getAllDropListIds()"
                    (cdkDropListDropped)="onDrop($event)"
                    class="orders-list">
                <div
                        *ngFor="let order of pendingOrders"
                        class="card-scontrino"
                        cdkDrag>
                    <div class="container-scontrino"  (mouseenter)="showPopup = true; setOrder(order)"
                         (mouseleave)="showPopup = false; setOrder(order)">
                        <div class="order-row">
                            <div class="order-number">
                               Ordine: <span class="bold-numero">#{{order.idOrdine}}</span>
                            </div>
                        </div>
                        <div class="order-row">
                            <div class="order-info">
                                <tagComponent [fonte]="order.fonte"></tagComponent>
                                <span class="date-info">{{order.data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</span>
                            </div>
                            <div class="status-container">
                                <div class="status-tag" [ngClass]="{
                                    'status-confirmed': order.stato === 'confermato',
                                    'status-partial': order.stato === 'confermato_parziale',
                                    'status-processing': order.scheduledTime !== null,
                                    'status-unpaid': !order.stato && !order.scheduledTime
                                }">
                                    {{getOrderStatus(order)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="calendar-area">
            <div class="calendar-controls">
                <div class="month-controls">
                    <button class="nav-button" (click)="previousMonth()">
                        <span>&#171;</span>
                    </button>
                    <span class="current-month">{{ getCurrentMonthYear() }}</span>
                    <button class="nav-button" (click)="nextMonth()">
                        <span>&#187;</span>
                    </button>
                </div>
                <div class="week-controls">
                    <button class="nav-button" (click)="previousWeek()">
                        <span>&lt;</span>
                    </button>
                    <span class="week-range">
                        {{ formatDate(currentWeek[0]) }} - {{ formatDate(currentWeek[6]) }}
                    </span>
                    <button class="nav-button" (click)="nextWeek()">
                        <span>&gt;</span>
                    </button>
                </div>
            </div>

            <div class="calendar-grid">
                <div class="calendar-header">
                    <div class="time-column">Ora</div>
                    <div
                            *ngFor="let date of currentWeek"
                            class="day-column"
                            [class.today]="isToday(date)">
                        {{ formatDate(date) }}
                    </div>
                </div>

                <div class="calendar-body">
                    <div *ngFor="let timeSlot of timeSlots; let timeIndex = index" class="time-row">
                        <div class="time-label">{{timeSlot}}</div>
                        <div
                                *ngFor="let date of currentWeek; let dayIndex = index"
                                cdkDropList
                                [id]="getDropListId(timeIndex, dayIndex)"
                                [cdkDropListData]="getOrdersForSlot(timeSlot, dayIndex)"
                                [cdkDropListConnectedTo]="getAllDropListIds()"
                                (cdkDropListDropped)="onDrop($event, timeSlot, dayIndex)"
                                class="time-slot"
                                [class.today]="isToday(date)">
                            <div
                                    *ngFor="let order of getOrdersForSlot(timeSlot, dayIndex)"
                                    class="card-scontrino"
                                    cdkDrag>
                                <div class="container-scontrino" (click)="showPopup = true; setOrder(order)">
                                    <div class="order-row">
                                        <div class="order-number">
                                            <span class="bold-numero">#{{order.idOrdine}}</span>
                                        </div>
                                    </div>
                                    <div class="order-row">
                                        <div class="order-info">
                                            <tagComponent [fonte]="order.fonte"></tagComponent>
                                            <span class="date-info">{{order.data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</span>
                                        </div>
                                        <div class="status-container">
                                            <div class="status-tag" [ngClass]="{
                                                'status-confirmed': order.stato === 'confermato',
                                                'status-partial': order.stato === 'confermato_parziale',
                                                'status-processing': order.scheduledTime !== null,
                                                'status-unpaid': !order.stato && !order.scheduledTime
                                            }">
                                                {{getOrderStatus(order)}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<order-detail-popup
        *ngIf="showPopup"
        [order]="order"
        (mouseleave)="showPopup = false">
</order-detail-popup>