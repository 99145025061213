// chat.component.ts
import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from "firebase";
import {CommonService} from "../../../shared/services/firestore/common.service";
import {UserDataService} from "../../../shared/services/firestore/userData.service";

interface ChatMessage {
    id: string;
    text: string;
    sender: string;
    timestamp: firebase.firestore.Timestamp;
    seenBy: string[];
}

@Component({
    selector: 'chat',
    template: `
        <div class="chat-container">
            <div #scrollContainer class="messages" (scroll)="onScroll($event)">
                <div *ngFor="let message of messages$ | async"
                     [ngClass]="{'message-right': message.sender === currentUser, 'message-left': message.sender !== currentUser}">
                    <div class="message-content" [style.background-color]="getUserColor(message.sender)">
                        {{ message.text }}
                        <span *ngIf="message.seenBy.includes(currentUser)">ciaociao</span>
                    </div>
                    <div class="message-info">
                        <span class="sender-id" style="padding-right: 5px">{{ message.sender.substring(0, 10) }}</span>
                        <span class="timestamp">{{ message.timestamp.toDate() | date:'HH:mm' }}</span>
                    </div>
                </div>
            </div>
            <div class="input-container">
                <input [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" placeholder="Scrivi un messaggio...">
                <button (click)="sendMessage()">Invia</button>
            </div>
        </div>
    `,
    styles: [`
        .chat-container {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Arial, sans-serif;
        }
        .messages {
            flex: 1;
            overflow-y: auto;
            padding: 10px;
            background-color: #ECE5DD;
        }
        .message-left, .message-right {
            max-width: 70%;
            padding: 8px 12px;
            margin: 5px;
            border-radius: 15px;
            position: relative;
            clear: both;
        }
        .message-left {
            float: left;
            background-color: #FFFFFF;
        }
        .message-right {
            float: right;
            background-color: #DCF8C6;
        }
        .message-content {
            position: relative;
        }
        .checkmark {
            position: absolute;
            right: -15px;
            bottom: -5px;
            font-size: 0.8em;
            color: #4FC3F7;
        }
        .message-info {
            font-size: 0.75em;
            color: #888;
            margin-top: 4px;
        }
        .input-container {
            display: flex;
            padding: 10px;
            background-color: #F0F0F0;
        }
        input {
            flex: 1;
            padding: 10px;
            border: none;
            border-radius: 20px;
            margin-right: 10px;
        }
        button {
            padding: 10px 20px;
            background-color: #128C7E;
            color: white;
            border: none;
            border-radius: 20px;
            cursor: pointer;
        }
    `]
})
export class ChatComponent implements OnInit, AfterViewChecked {
    @ViewChild('scrollContainer') private scrollContainer: ElementRef;

    messages$: Observable<ChatMessage[]>;
    newMessage: string = '';
    currentUser: string = 'User1'; // Sostituisci con l'ID utente reale
    userColors: {[key: string]: string} = {};

    constructor(private commonService: CommonService, private userService: UserDataService) {}

    ngOnInit() {
        console.log("chat")

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                this.retriveCarrello();
                this.userService.emitter.subscribe(
                    () => {
                        this.retriveCarrello();
                    }
                );
            } else {
                // No user is signed in.
            }
        });

    }

    user = {}

    retriveCarrello(): void {

        this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
            data => {
                if (data.data() !== undefined) {

                    this.user = data.data();
                    this.currentUser = this.user['uid']
                    this.messages$ = this.commonService.getChat()
                }
            }
        );

    }


    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        } catch(err) { }
    }

    sendMessage() {
        if (this.newMessage.trim() === '') return;

        this.commonService.addChat({
            text: this.newMessage,
            sender: this.currentUser,
            timestamp: firebase.firestore.Timestamp.now(),
            seenBy: [this.currentUser]
        });

        this.newMessage = '';
    }


    getUserColor(userId: string): string {
        if (!this.userColors[userId]) {
            this.userColors[userId] = this.getRandomColor();
        }
        return this.userColors[userId];
    }

    getRandomColor(): string {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    }

    onPanStart(event: any, message: ChatMessage) {
        message['startX'] = event.deltaX;
    }

    onPanMove(event: any, message: ChatMessage) {
        const currentX = event.deltaX - message['startX'];
        if (currentX < 0) return; // Impedisce lo scorrimento verso destra
        message['currentX'] = currentX;
        event.target.style.transform = `translateX(${currentX}px)`;
    }

    onPanEnd(event: any, message: ChatMessage) {
        if (message['currentX'] > 50) {
            message['showSeenBy'] = true;
        } else {
            message['showSeenBy'] = false;
        }
        event.target.style.transform = 'translateX(0)';
    }

    onScroll(event: any) {
        // Qui puoi aggiungere la logica per caricare più messaggi quando l'utente scorre verso l'alto
    }
}