<div class="row">
    <div class="column">
        <!-- Barra di navigazione superiore originale -->
        <div style="
      width: 100%;
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 15px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      align-items: center;
        ">
            <div style="width: 70%; height: 100%; margin-left: 10px; display: flex">
                <div [ngStyle]="getTabStyle('Catalogo')"
                     (click)="selezione = 'Catalogo'">
                    Catalogo
                </div>
                <div [ngStyle]="getTabStyle('Cronologia')"
                     (click)="selezione = 'Cronologia'">
                    Cronologia
                </div>
                <div [ngStyle]="getTabStyle('Calendario')"
                     (click)="selezione = 'Calendario'">
                    Calendario
                </div>
            </div>
            <div style="width: calc(30% - 10px);
                        height: 100%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
          ">
                <div class="menu-options">
                    <!-- Selector for warehouses -->
                    <select [(ngModel)]="selectedWarehouse" (change)="onWarehouseChange(selectedWarehouse)" style="height: 40px; border: none; padding: 5px 5px; font-weight: 600; font-size: 15px; font-family: 'Inter', sans-serif">
                        <option *ngFor="let warehouse of warehouses" [value]="warehouse.id">{{warehouse.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- Contenuto Catalogo con due colonne -->
        <div class="content-container" *ngIf="selezione == 'Catalogo'">
            <!-- Colonna sinistra (Tabella dei prodotti) -->
            <div class="content-left">
                <div class="product-list-container">
                    <!-- Search Bar -->
                    <div class="search-container">
                        <div class="search-wrapper">
                            <input
                                    type="text"
                                    placeholder="Cerca prodotti..."
                                    [(ngModel)]="searchTerm"
                                    (keyup)="applyFilter(searchTerm)"
                                    class="search-input">
                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-search-100%20(1).png?alt=media&token=b682cc61-602e-476d-aef0-a4f0f2573632"
                                 class="search-icon" alt="Cerca">
                        </div>
                    </div>

                    <div class="table-container">
                        <table class="product-table">
                            <thead>
                            <tr>
                                <th class="col-titolo">Titolo</th>
                                <th class="col-um">UM</th>
                                <th class="col-prezzo">Prezzo</th>
                                <th class="col-actions"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- Categoria Headers -->
                            <ng-container *ngFor="let item of dataSource.data">
                                <tr *ngIf="isGroup(null, item)" class="category-row" (click)="groupHeaderClick(item)">
                                    <td colspan="4">
                                        <strong>{{item[groupByColumns[item.level-1]]}}</strong>
                                    </td>
                                </tr>
                                <!-- Product Rows -->
                                <tr *ngIf="!isGroup(null, item) && item.visible" class="product-row">
                                    <td class="col-titolo">
                                        <div class="product-title-container">
                                            <img *ngIf="item.image_link" [src]="item.image_link" class="product-image" alt="">
                                            <div class="product-details">
                                                <div class="product-title">{{item.Titolo}}</div>
                                                <div class="product-description">{{item.descrizione}}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="col-um">{{item.UM}}</td>
                                    <td class="col-prezzo">{{item.Prezzo}} €</td>
                                    <td class="col-actions">
                                        <button class="action-button edit-button" *ngIf="cartItems.has(item.id)" (click)="openDialogAdd(item)">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-90.png?alt=media&token=3975c982-b1e5-467a-92cd-0f81e5a30c5a" alt="Modifica">
                                        </button>
                                        <button class="action-button add-button" *ngIf="!cartItems.has(item.id)" (click)="openDialogAdd(item)">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-64.png?alt=media&token=56c275a9-9eb6-4d1e-a1c7-20012437d442" alt="Aggiungi">
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Colonna destra (Riepilogo ordine) Solo nella vista Catalogo -->
            <div class="content-right">
                <div class="cart-container">
                    <div class="cart-header">
                        <h2>Riepilogo Ordine</h2>
                        <div class="cart-total">Totale: {{totalOrderAmount.toFixed(2)}}€</div>
                    </div>

                    <!-- Modifica solo la parte del riepilogo ordine (a destra) -->
                    <div class="cart-items-container">
                        <div *ngIf="cartItemsArray.length === 0" class="empty-cart">
                            Il carrello è vuoto
                        </div>

                        <div *ngFor="let item of getCartItemsArray()" class="cart-item">
                            <div class="cart-item-main">
                                <div class="cart-item-info">
                                    <div class="cart-item-title">{{item.Titolo}}</div>
                                    <div class="cart-item-unit">{{item.UM}}</div>
                                    <div class="cart-item-quantity">
                                        Quantità: <span class="quantity-badge">{{item.quantita}}</span>
                                    </div>
                                    <div class="cart-item-unit-price">
                                        Prezzo unitario: {{(+item.Prezzo).toFixed(2)}}€
                                    </div>
                                </div>
                                <div class="cart-item-price">
                                    {{(+item.Prezzo * +item.quantita).toFixed(2)}}€
                                </div>
                            </div>
                            <div class="cart-item-actions">
                                <button class="remove-button" (click)="removeCartItem(item)">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43" alt="Rimuovi">
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="cart-footer">
                        <button class="send-order-button" [disabled]="cartItemsArray.length === 0" (click)="sendOrder()">
                            Invia Ordine
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contenuto Cronologia a larghezza piena (senza riepilogo) -->
        <div class="full-width-container" *ngIf="selezione == 'Cronologia'">
            <cronologia></cronologia>
        </div>

        <!-- Contenuto Calendario a larghezza piena (senza riepilogo) -->
        <div class="full-width-container" *ngIf="selezione == 'Calendario'">
            <calendario></calendario>
        </div>
    </div>
</div>