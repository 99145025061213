import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { IpcService } from "../../shared/services/ipc.service";
import { AuthService } from "../../shared/services/auth.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-azioni-stampante',
  templateUrl: './azioniStampante.html',
  styleUrls: ['./azioniStampante.css']
})
export class AzioniStampanteComponent implements OnInit, OnDestroy {
  chiusuraGiornaliera: boolean = false;
  annullaScontrino: boolean = false;
  nomeScontrino: string = '';
  chiusuraGiornalieraInput: string = '';
  dataInput: string = '';
  serialeInput: string = '';

  // Nuovi campi per i totali
  totalPreconti: number = 0;
  totalCashPreconti: number = 0;
  totalCashFiscale: number = 0;
  totalElettronici: number = 0;
  totalFiscaleNoPreconti: number = 0;

  // Flag per mostrare i totali
  showTotals: boolean = false;
  showTotalsSection: boolean = false;

  // Flag per l'autorizzazione
  isAuthorized: boolean = false;
  isPinView: boolean = true;
  usersExist: boolean = false;
  userPasswords: string[] = [];

  // Variables for PIN
  pin: string = '';
  showPinError: boolean = false;

  // Keypad configuration
  keypadRows = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9]
  ];

  // Subscription per gestire il cleaning
  private subscriptions: Subscription[] = [];

  constructor(
      public matDialogRef: MatDialogRef<AzioniStampanteComponent>,
      private ipcService: IpcService,
      private router: Router,
      private dataService: DataServiceGenerico,
      private authService: AuthService
  ) {}

  ngOnInit(): void {
    // All'avvio, mostriamo la vista del PIN
    this.isPinView = true;
    this.isAuthorized = false;

    // Verifica se esistono utenti e ottieni le loro password
    this.checkUsersExist();
  }


  hasPermission(permissionName: string): boolean {
    // Never filter 'Cassa' permission
    if (permissionName.toLowerCase() === 'cassa') {
      return true;
    }

    const userLocale = this.dataService.getUserLocale();
    if (!userLocale || !userLocale['permessi']) {
      return false;
    }

    // List of navbar-related permissions
    const navbarPermissions = [
      'Annulla Scontrino',
      "Totali",
      "Chiusura Giornaliera",
    ];

    // Case-insensitive permission check
    const hasPermission = userLocale['permessi'].some(
        permission => permission.toLowerCase() === permissionName.toLowerCase()
    );

    // If it's a navbar permission, check if it exists in user permissions
    if (navbarPermissions.includes(permissionName)) {
      return hasPermission;
    }

    // For all other permissions, return the normal check result
    return hasPermission;
  }


  hasAnyPermission(permissionNames: string[]): boolean {
    // Never filter 'Cassa' permission - returns true if 'cassa' is in requested permissions
    if (permissionNames.some(permission => permission.toLowerCase() === 'cassa')) {
      return true;
    }

    const userLocale = this.dataService.getUserLocale();
    if (!userLocale || !userLocale['permessi']) {
      return false;
    }

    // List of navbar-related permissions
    const navbarPermissions = [
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity',
      'Impostazioni',

      'Annulla Scontrino',
      "Totali",
      "Chiusura Giornaliera",
    ];

    // Check if at least one of the requested permissions exists in user permissions (case-insensitive)
    const userPermissions = userLocale['permessi'].map(p => p.toLowerCase());

    // Special handling for navbar permissions
    const requestedNavbarPermissions = permissionNames.filter(p => navbarPermissions.includes(p));

    // If all requested permissions are navbar permissions, check if user has at least one
    if (requestedNavbarPermissions.length === permissionNames.length) {
      return permissionNames.some(permission =>
          userPermissions.includes(permission.toLowerCase())
      );
    }

    // For non-navbar permissions or mixed permissions, check if user has at least one
    return permissionNames.some(permission =>
        userPermissions.includes(permission.toLowerCase())
    );
  }

  ngOnDestroy(): void {
    // Pulizia delle subscription
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * Verifica se esistono utenti nel ristorante e carica le loro password
   */
  async checkUsersExist(): Promise<void> {
    try {
      const ristoranteId = await this.dataService.getCurrentRestaurantId();

      if (!ristoranteId) {
        console.error('ID ristorante non disponibile');
        // Se non abbiamo un ID ristorante, usiamo solo la password predefinita
        this.usersExist = false;
        return;
      }

      // Accedi a Firestore per verificare se ci sono utenti nel ristorante
      const docSnapshot = await firebase.firestore()
          .collection('ristoranti')
          .doc(ristoranteId)
          .get();

      if (docSnapshot.exists) {
        const data = docSnapshot.data();
        const organico = data && data.organico ? data.organico : [];

        this.usersExist = organico.length > 0;

        if (this.usersExist) {
          // Raccogliamo tutte le password degli utenti
          this.userPasswords = organico
              .filter(user => user.passwordCassa && user.passwordCassa.length > 0)
              .map(user => user.passwordCassa);

          console.log(`Trovati ${this.userPasswords.length} utenti con password`);

          // Se non ci sono password, usiamo comunque la password predefinita
          if (this.userPasswords.length === 0) {
            this.usersExist = false;
          }
        }

        // Se non ci sono utenti o nessun utente ha una password,
        // bypassa completamente la schermata PIN
        if (!this.usersExist) {
          this.isAuthorized = true;
          this.isPinView = false;
          this.loadTotals();
        }
      } else {
        // Se il documento del ristorante non esiste, usiamo la password predefinita
        this.usersExist = false;
        this.isAuthorized = true;
        this.isPinView = false;
        this.loadTotals();
      }
    } catch (error) {
      console.error('Errore nel controllo degli utenti:', error);
      // In caso di errore, mostriamo comunque la vista PIN con password predefinita
      this.usersExist = false;
    }
  }

  // Gestisce l'aggiunta di una cifra al PIN
  addPinDigit(digit: number): void {
    if (this.pin.length < 4) {
      this.pin += digit.toString();

      if (this.pin.length === 4) {
        // Verifica automaticamente quando il PIN raggiunge 4 cifre
        setTimeout(() => this.confirmPin(), 300);
      }
    }
  }

  // Rimuove l'ultima cifra dal PIN
  removePinDigit(): void {
    if (this.pin.length > 0) {
      this.pin = this.pin.slice(0, -1);
      this.showPinError = false;
    }
  }

  // Conferma il PIN
  confirmPin(): void {
    // Se ci sono utenti, verifica se il PIN corrisponde a una delle loro password
    if (this.usersExist) {
      if (this.userPasswords.includes(this.pin)) {
        this.isAuthorized = true;
        this.isPinView = false;
        this.loadTotals();
      } else {
        this.showPinError = true;
        this.pin = '';
      }
    } else {
      // Password fissa "0000" come fallback
      if (this.pin === '0000') {
        this.isAuthorized = true;
        this.isPinView = false;
        this.loadTotals();
      } else {
        this.showPinError = true;
        this.pin = '';
      }
    }
  }

  async loadTotals(): Promise<void> {
    try {
      const resid = await this.dataService.getCurrentRestaurantId();
      const restaurantName = await this.dataService.getRistorante();

      const now = new Date();
      let today = new Date();

      // Se sono tra mezzanotte e l'1:00, considero ancora il giorno precedente
      if (now.getHours() >= 0 && now.getHours() < 1) {
        today.setDate(today.getDate() - 1);
      }

      // Imposta l'inizio a oggi alle 1:00
      const startDate = new Date(today);
      startDate.setHours(1, 0, 0, 0);

      // Imposta la fine a domani alle 1:00
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() + 1);
      endDate.setHours(1, 0, 0, 0);

      const startTimestamp = startDate.getTime().toString();
      const endTimestamp = endDate.getTime().toString();

      console.log('Carico ordini dal:', new Date(parseInt(startTimestamp)).toLocaleString(), 'al:', new Date(parseInt(endTimestamp)).toLocaleString());

      // Carica tutti gli ordini nel range temporale
      const ordersRef = firebase.firestore()
          .collection('ordini')
          .where('ristorante', '==', restaurantName)
          .where('data', '>=', startTimestamp)
          .where('data', '<=', endTimestamp);

      const snapshot = await ordersRef.get();

      // Reset dei totali
      this.totalPreconti = 0;
      this.totalCashPreconti = 0;
      this.totalCashFiscale = 0;
      this.totalElettronici = 0;
      this.totalFiscaleNoPreconti = 0;

      // Calcola i totali
      snapshot.docs.forEach(doc => {
        const order = doc.data();
        const total = this.calculateOrderTotal(order);

        // Controlla se è un preconto (verifica nota:'preconto')
        if (order.nota === 'preconto') {
          this.totalPreconti += total;

          // Se è un preconto in contanti
          if (order.paymentMethod === 'cash' || order.paymentMethod === 'contanti') {
            this.totalCashPreconti += total;
          } else if (order.paymentMethod === 'card' || order.paymentMethod === 'carta' || order.paymentMethod === 'bancomat') {
            this.totalElettronici += total;
          }
        } else {
          // Se non è un preconto
          if (order.paymentMethod === 'cash' || order.paymentMethod === 'contanti') {
            this.totalCashFiscale += total;
          } else if (order.paymentMethod === 'card' || order.paymentMethod === 'carta' || order.paymentMethod === 'bancomat') {
            this.totalElettronici += total;
          }

          this.totalFiscaleNoPreconti += total;
        }
      });

      console.log('Totali calcolati:', {
        totalPreconti: this.totalPreconti,
        totalCashPreconti: this.totalCashPreconti,
        totalCashFiscale: this.totalCashFiscale,
        totalElettronici: this.totalElettronici,
        totalFiscaleNoPreconti: this.totalFiscaleNoPreconti
      });

      // Attiva il flag per mostrare i totali
      this.showTotals = true;

    } catch (error) {
      console.error('Errore nel caricamento dei totali:', error);
    }
  }

  // Metodo per calcolare il totale di un ordine
  private calculateOrderTotal(order: any): number {
    if (!order.carrello) return 0;

    let total = 0;
    // Utilizziamo un ciclo for...in invece di Object.values().reduce()
    for (const key in order.carrello) {
      if (key === 'Sconto') continue; // Salta l'elemento sconto che ridurrebbe il totale

      const item = order.carrello[key];
      if (!item) continue; // Salta elementi nulli/undefined

      const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
      const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
      total += price * quantity;
    }

    return total;
  }

  selezionaChiusuraGiornaliera(): void {
    this.chiusuraGiornaliera = true;
  }

  selezionaAnnullaScontrino(): void {
    this.annullaScontrino = true;
  }

  confermaChiusuraGiornaliera(): void {
    this.zReport();
    this.matDialogRef.close();
  }

  annullaOperazione(): void {
    this.chiusuraGiornaliera = false;
    this.annullaScontrino = false;
  }

  navigateToImpostazioni(): void{
    this.router.navigate(['/dashboard']);
    this.matDialogRef.close();
  }

  logout() {
    this.authService.SignOut();
    this.router.navigate(['/login']);
    this.matDialogRef.close();
  }

  closeDialog() {
    this.matDialogRef.close();
  }

  annullaScontrinoSubmit(): void {
    this.epsonFiscalVoid();
    this.matDialogRef.close();
  }

  zReport(): void {
    let zReportXml = `<?xml version="1.0" encoding="utf-8"?>
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
<soapenv:Body><printerFiscalReport>
<displayText operator="1" data="Chiusura Giornaliera" /><printZReport operator="1" timeout="30000" />
</printerFiscalReport></soapenv:Body>
</soapenv:Envelope>`;
    this.ipcService.PrintFiscalReceipt(zReportXml, 'epson');
    console.log('Chiusura giornaliera avviata');
  }

  epsonFiscalVoid(): void {
    if(!this.dataInput || !this.chiusuraGiornalieraInput || !this.nomeScontrino || !this.serialeInput) {
      console.error('Tutti i campi sono obbligatori');
      return;
    }

    const dataFormattata = this.dataInput.slice(0, 4) + this.dataInput.slice(-2);

    const epsonVoidXml = `<?xml version="1.0" encoding="utf-8"?>
<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">
<soapenv:Body><printerFiscalReceipt>
<displayText operator="1" data="Annullo Scontrino" />
<printRecMessage operator="1" message="VOID ${this.chiusuraGiornalieraInput} ${this.nomeScontrino} ${this.dataInput} ${this.serialeInput}" messageType="4" />
</printerFiscalReceipt></soapenv:Body>
</soapenv:Envelope>`;
    this.ipcService.PrintFiscalReceipt(epsonVoidXml, 'epson');

    console.log('Annullamento scontrino avviato con i dati:', {
      nomeScontrino: this.nomeScontrino,
      chiusuraGiornaliera: this.chiusuraGiornalieraInput,
      data: this.dataInput,
      seriale: this.serialeInput
    });
  }

  // Formatta i numeri come valuta
  formatCurrency(value: number): string {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    }).format(value);
  }
}