// personale.component.ts
import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { IpcService } from "../../../shared/services/ipc.service";
import { PosTransactionService } from "../../../shared/services/pos-transaction.service";
import { MatTableDataSource } from "@angular/material/table";
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { Subscription } from 'rxjs';
import {AddRoleDialogComponent} from "./dialog/add-role-dialog.component";
import {AddEmployeeDialogComponent} from "./dialog/add-employee-dialog.component";

@Component({
  selector: 'personale',
  templateUrl: './personale.html',
  styleUrls: ['./personale.css']
})
export class Personale implements OnInit, OnDestroy {
  displayedColumns = ['nome', 'permessi'];
  dragPosition = {x: 253, y: 244};
  dragPosition2 = {x: 253, y: 244};
  private personnelSubscription: Subscription;

  ruoli = [
    {
      nome: 'Admin',
      permessi: [
        'Cassa',
        'Report',
        'Menu',
        'Magazzino',
        'Impostazioni Ristorante',
        'Impostazioni Personali',
      ]
    },
    {
      nome: 'Cassiere',
      permessi: [
        'Cassa',
        'Impostazioni Personali'
      ]
    }
  ];

  permessi = [
    'Cassa',
    'Report',
    'Menu',
    'Magazzino',
    'Impostazioni Ristorante',
    'Impostazioni Personali',
  ];

  organico = [
  ];

  dataSource = new MatTableDataSource<any>([]);
  immagini = {};

  columns = [
    { field: 'nome' },
    { field: 'cognome' },
    { field: 'mail' },
    { field: 'ruolo' },
    { field: 'permessi' }
  ];

  displayedColumns2: string[] = this.columns.map(column => column.field);
  groupByColumns: string[] = ['ruolo'];

  constructor(
      private router: Router,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private dataService: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    this.immagini = this.dataService.getImmagini();

    if (this.dataService.getRistorante1() === 'demo_1') {
      this.dataService.loadSelectedMenuFromFirebase().then(() => {
        this.setupPersonnelListener();
      });
    } else {
      this.setupPersonnelListener();
    }
  }

  ngOnDestroy(): void {
    if (this.personnelSubscription) {
      this.personnelSubscription.unsubscribe();
    }
  }

  private setupPersonnelListener(): void {
    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();
    const ristoranteRef = db.collection('ristoranti').doc(ristoranteId);

    this.personnelSubscription = new Subscription();

    const docListener = ristoranteRef.onSnapshot(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data();
            if (data) {
              this.ruoli = data.ruoli || this.ruoli;
              this.organico = data.organico || this.organico;
              this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);
            }
          }
        },
        (error) => {
          console.error('Errore nel listener dei dati del personale:', error);
        }
    );

    this.personnelSubscription.add(() => docListener());
  }

   async savePersonnelData(): Promise<void> {
    if (!this.dataService.getRistorante1()) {
      console.error('ID ristorante non disponibile');
      return;
    }

    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();
    const ristoranteRef = db.collection('ristoranti').doc(ristoranteId);

    try {
      // Crea l'array di uid dagli utenti dell'organico
      const utentiUids = this.organico.map(dipendente => dipendente.uid);

      // Aggiorna entrambi gli array
      await ristoranteRef.set({
        ruoli: this.ruoli,
        organico: this.organico,
        utenti: utentiUids, // Array di uid per le ricerche
        lastUpdateOrganico: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true });
    } catch (error) {
      console.error('Errore nel salvataggio dei dati del personale:', error);
      throw error;
    }
  }


  openAddRoleDialog(): void {
    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const roleExists = this.ruoli.some(role =>
              role.nome.toLowerCase() === result.nome.toLowerCase()
          );

          if (roleExists) {
            throw new Error('Esiste già un ruolo con questo nome');
          }

          this.ruoli.push(result);
          await this.savePersonnelData();
        } catch (error) {
          console.error('Errore nella creazione del ruolo:', error);
        }
      }
    });
  }

  openAddEmployeeDialog(): void {
    const dialogRef = this.dialog.open(AddEmployeeDialogComponent, {
      width: '600px',
      height: '80vh',
      panelClass: 'custom-dialog',
      disableClose: true
    });

    const employeeDialog = dialogRef.componentInstance;
    employeeDialog.setRuoli(this.ruoli);

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const emailExists = this.organico.some(emp =>
              emp.mail.toLowerCase() === result.email.toLowerCase()
          );

          if (emailExists) {
            throw new Error('Esiste già un dipendente con questa email');
          }

          const secondaryApp = firebase.initializeApp({
            apiKey: "AIzaSyBYzhvaoK3MPFvrKSkEqTe8HTiLwcd-Pzo",
            authDomain: "deweats.firebaseapp.com",
            databaseURL: "https://deweats-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "deweats",
            storageBucket: "deweats.appspot.com",
            messagingSenderId: "157089671742",
            appId: "1:157089671742:web:899765e7a64cf907e03f6f",
            measurementId: "G-KCGE5JVRDK"          }, 'secondary');

          const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(
              result.email,
              result.password
          );

          const employeeData = {
            nome: result.nome,
            cognome: result.cognome,
            mail: result.email,
            ruolo: result.ruolo,
            uid: userCredential.user.uid,
            permessi: [],
            ristorante: this.dataService.getRistorante1()
          };

          // Aggiungi il dipendente all'organico
          this.organico.push(employeeData);
          await this.savePersonnelData();
          await secondaryApp.delete();

        } catch (error) {
          console.error('Errore nella creazione del dipendente:', error);
          throw error;
        }
      }
    });
  }



  groupBy(event: Event, column: any) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field: string, add: boolean) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event: Event, column: any) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) {
        return false;
      }
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row: Group) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
        data.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a: any[], key: (item: any) => string) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item.level;
  }

  async updatePermissions(user: any): Promise<void> {
    try {
      const index = this.organico.findIndex(u => u.mail === user.mail);
      if (index !== -1) {
        // Aggiorna solo i permessi nell'array
        this.organico[index].permessi = Array.isArray(user.permessi) ? user.permessi : [];
        await this.savePersonnelData();
      }
    } catch (error) {
      console.error('Errore nell\'aggiornamento dei permessi:', error);
      throw error;
    }
  }
}

class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;

  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false;
    this.totalCounts = 0;
  }
}