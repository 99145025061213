import {
  Component,
  OnInit, ViewChild, OnDestroy
} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CarrelloMagazzino} from "../carrelloMagazzino/carrelloMagazzino";
import {PartialConfirmDialogComponent} from "./dialog/partial-confirm-dialog.component";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'cronologia',
  templateUrl: './cronologia.html',
  styleUrls: ['./cronologia.css'],
})
export class Cronologia implements OnInit, OnDestroy {
  // Variabili esistenti
  partialConfirmationDetails: {
    name: string;
    photo: File | null;
  } | null = null;

  selectedOrder: any = null;
  ordiniMagazzino: any[] = [];
  isConfirmingPartial: boolean = false;
  partialQuantities: { [key: string]: number } = {};

  // Stato di caricamento
  isLoading: boolean = true;
  errorMessage: string = '';

  // Flag per verificare il montaggio/smontaggio
  private isComponentMounted: boolean = true;
  private authListener: any;
  private restaurantId: string = '';

  findCarrelloMagazzinoConsegnato(selectedOrder: any, item: any) {
    return selectedOrder.carrelloMagazzinoConsegnato?.find((p) => p.titolo === item.id)?.QuantitaConsegnata;
  }

  getOrderStatus(): string {
    if (!this.selectedOrder) return 'In attesa';

    if (this.selectedOrder.stato === 'confermato') {
      return 'Confermato';
    } else if (this.selectedOrder.stato === 'confermato_parziale') {
      return 'Confermato Parzialmente';
    } else if (this.selectedOrder.scheduledTime !== null) {
      return 'In preparazione';
    } else {
      return 'In attesa di conferma';
    }
  }

  openOrderDetail(order: any) {
    this.selectedOrder = order;
    this.isConfirmingPartial = false;
    this.resetPartialQuantities();

    // Debug
    this.logOrderDetails(order);
  }

  resetPartialQuantities() {
    this.partialQuantities = {};
    if (this.selectedOrder?.carrelloMagazzinoConsegnato) {
      this.selectedOrder.carrelloMagazzinoConsegnato.forEach((item: any) => {
        this.partialQuantities[item.Titolo] = parseInt(item.QuantitaConsegnata);
      });
    }
    else {
      if (this.selectedOrder?.carrelloMagazzino) {
        this.selectedOrder.carrelloMagazzino.forEach((item: any) => {
          this.partialQuantities[item.Titolo] = parseInt(item.Quantita || item.quantita || '0');
        });
      }
    }
  }

  startPartialConfirmation() {
    this.isConfirmingPartial = true;
    this.resetPartialQuantities();
  }

  cancelPartialConfirmation() {
    this.isConfirmingPartial = false;
    this.resetPartialQuantities();
  }

  async confirmFullOrder() {
    if (!this.selectedOrder) return;

    try {
      const db = firebase.firestore();
      await db.collection('ordini_magazzino_test').doc(this.selectedOrder.idOrdine).update({
        stato: 'confermato',
        dataConferma: firebase.firestore.FieldValue.serverTimestamp()
      });

      // Aggiorna l'ordine locale
      this.selectedOrder.stato = 'confermato';
      this.fetchOrdini(); // Ricarica la lista ordini
    } catch (error) {
      console.error('Errore durante la conferma dell\'ordine:', error);
      this.snackBar.open('Errore durante la conferma dell\'ordine', 'Chiudi', {
        duration: 3000
      });
    }
  }

  async confirmPartialOrder() {
    if (!this.selectedOrder) return;

    // Prima apriamo il dialog per nome e foto
    const dialogRef = this.dialog.open(PartialConfirmDialogComponent, {
      width: '800px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed) {
        try {
          const partialOrder = this.selectedOrder.carrelloMagazzino.map((item: any) => ({
            ...item,
            QuantitaConsegnata: this.partialQuantities[item.Titolo],
            QuantitaOriginale: item.Quantita || item.quantita
          }));

          // Upload photo if exists
          let photoUrl = null;
          if (result.photo) {
            const storageRef = firebase.storage().ref();
            const photoRef = storageRef.child(`partial-confirmations/${Date.now()}_${result.photo.name}`);
            await photoRef.put(result.photo);
            photoUrl = await photoRef.getDownloadURL();
          }

          const db = firebase.firestore();
          await db.collection('ordini_magazzino_test').doc(this.selectedOrder.idOrdine).update({
            stato: 'confermato_parziale',
            dataConferma: firebase.firestore.FieldValue.serverTimestamp(),
            carrelloMagazzinoConsegnato: partialOrder,
            confermaDetails: {
              nome: result.name,
              photoUrl: photoUrl
            }
          });

          this.isConfirmingPartial = false;
          this.fetchOrdini();
        } catch (error) {
          console.error('Errore durante la conferma parziale dell\'ordine:', error);
          this.snackBar.open('Errore durante la conferma parziale', 'Chiudi', {
            duration: 3000
          });
        }
      }
    });
  }

  // Calcolo corretto del totale ordine
  calcoloCorrettoTotaleOrdine(ordine: any): number {
    if (!ordine || !ordine.carrelloMagazzino || ordine.carrelloMagazzino.length === 0) {
      return 0;
    }

    let totale = 0;

    for (const item of ordine.carrelloMagazzino) {
      const prezzo = parseFloat(item.Prezzo || '0') || 0;
      const quantita = parseInt(item.Quantita || item.quantita || '0') || 0;

      totale += prezzo * quantita;
    }

    return totale;
  }

  // Funzione pubblica che usa il calcolo precalcolato
  calcolaTotaleOrdine(ordine: any): string {
    if (!ordine) return "0.00";

    if (ordine.totalePrecalcolato !== undefined) {
      return ordine.totalePrecalcolato.toFixed(2);
    }

    const totale = this.calcoloCorrettoTotaleOrdine(ordine);
    return totale.toFixed(2);
  }

  getProperties(object: any) {
    if (!object) return [];
    return Object.keys(object);
  }

  // Formatta la data da diversi tipi di input
// Formatta la data da diversi tipi di input
  formatDate(dateValue: any): string {
    if (!dateValue) return 'N/D';

    try {
      // Se è un timestamp Firestore
      if (dateValue && typeof dateValue.toDate === 'function') {
        const date = dateValue.toDate();
        return this.formatDateObject(date);
      }

      // Se è un timestamp numerico o una stringa
      if (typeof dateValue === 'number') {
        // Se è già un numero, non serve parseInt
        const date = new Date(dateValue);
        if (isNaN(date.getTime())) {
          return 'Data non valida';
        }
        return this.formatDateObject(date);
      }

      if (typeof dateValue === 'string') {
        // Se è una stringa, usiamo parseInt
        const date = new Date(parseInt(dateValue, 10));
        if (isNaN(date.getTime())) {
          // Prova a interpretare direttamente la stringa come data
          const directDate = new Date(dateValue);
          if (isNaN(directDate.getTime())) {
            return 'Data non valida';
          }
          return this.formatDateObject(directDate);
        }
        return this.formatDateObject(date);
      }

      // Se è già un oggetto Date
      if (dateValue instanceof Date) {
        return this.formatDateObject(dateValue);
      }

      return 'Formato data sconosciuto';
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return 'Errore data';
    }
  }

  // Formatta un oggetto Date nel formato locale italiano
  private formatDateObject(date: Date): string {
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  // Funzione per debug - mostra nel console i dati dell'ordine
  logOrderDetails(order: any): void {
    console.group('Dettagli Ordine');
    console.log('ID Ordine:', order.idOrdine);
    console.log('Ristorante:', order.ristorante);
    console.log('Data oggetto:', order.data);
    console.log('Data formattata:', this.formatDate(order.data));
    console.log('Stato:', order.stato);
    console.log('Carrello prodotti:', order.carrelloMagazzino);

    if (order.carrelloMagazzino && order.carrelloMagazzino.length > 0) {
      console.group('Dettagli Prodotti');
      order.carrelloMagazzino.forEach((item, index) => {
        console.group(`Prodotto ${index + 1}`);
        console.log('Titolo:', item.Titolo);
        console.log('Prezzo:', item.Prezzo);
        console.log('Quantità:', item.Quantita || item.quantita);
        console.log('Prezzo Totale:', parseFloat(item.Prezzo || '0') * parseInt(item.Quantita || item.quantita || '0'));
        console.groupEnd();
      });
      console.groupEnd();
    }

    console.log('Totale calcolato:', this.calcolaTotaleOrdine(order));
    console.groupEnd();
  }

  constructor(
      private router: Router,
      private userService: UserDataService,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      public commonService: CommonService,
      public dataService: DataServiceGenerico,
      private snackBar: MatSnackBar
  ) {}

  openDialogRiepilogo(idOrdine): any {
    const dialogRef = this.dialog.open(CarrelloMagazzino, {
      data: { orderId: idOrdine }, // Passa i dati della riga al dialog
      width: '80vw',
      maxWidth: '80vw'
    });
  }

  // METODI MIGLIORATI

  // Ottieni l'ID del ristorante utilizzando diversi metodi
  private getRestaurantId(): Promise<string> {
    return new Promise((resolve, reject) => {
      // Metodo 1: Da getRistorante1()
      let restaurantId = this.dataService.getRistorante1();
      if (restaurantId && restaurantId !== '') {
        console.log('ID ristorante da getRistorante1:', restaurantId);
        return resolve(restaurantId);
      }

      // Metodo 2: Da getCurrentRestaurant()
      try {
        const currentRestaurant = this.dataService.getCurrentRestaurant();
        if (currentRestaurant && currentRestaurant.id) {
          console.log('ID ristorante da getCurrentRestaurant:', currentRestaurant.id);
          return resolve(currentRestaurant.id);
        }
      } catch (error) {
        console.log('Errore ottenendo getCurrentRestaurant');
      }

      // Metodo 3: Da getRistorante()
      try {
        restaurantId = this.dataService.getRistorante();
        if (restaurantId && restaurantId !== '') {
          console.log('ID ristorante da getRistorante:', restaurantId);
          return resolve(restaurantId);
        }
      } catch (error) {
        console.log('Errore ottenendo getRistorante');
      }

      // Metodo 4: Carica da Firebase
      this.dataService.loadSelectedMenuFromFirebase()
          .then(() => {
            const loadedRestaurantId = this.dataService.getRistorante1();
            if (loadedRestaurantId && loadedRestaurantId !== '') {
              console.log('ID ristorante caricato da Firebase:', loadedRestaurantId);
              return resolve(loadedRestaurantId);
            }

            reject(new Error('Impossibile determinare l\'ID del ristorante'));
          })
          .catch(error => {
            console.error('Errore caricando il ristorante da Firebase:', error);
            reject(error);
          });
    });
  }

  // Funzione migliorata per caricare gli ordini
  async fetchOrdini(): Promise<void> {
    if (!this.isComponentMounted) return;

    this.isLoading = true;
    this.errorMessage = '';

    try {
      console.log('Recupero ID ristorante...');

      if (!this.restaurantId) {
        this.restaurantId = await this.getRestaurantId();
      }

      if (!this.restaurantId) {
        this.errorMessage = 'Impossibile determinare il ristorante';
        this.isLoading = false;
        return;
      }

      console.log('Recupero ordini per ristorante:', this.restaurantId);

      const db = firebase.firestore();

      // Prova a cercare gli ordini con entrambi i campi possibili per il ristorante
      const querySnapshot = await db.collection('ordini_magazzino_test')
          .where('ristorante', '==', this.restaurantId)
          .orderBy('data', 'desc')
          .get();

      console.log('Ordini trovati:', querySnapshot.size);

      if (querySnapshot.empty) {
        console.log('Nessun ordine trovato');
        this.ordiniMagazzino = [];
        this.isLoading = false;
        return;
      }

      this.ordiniMagazzino = [];

      querySnapshot.forEach(doc => {
        const data = doc.data();
        console.log('Ordine trovato:', doc.id);

        // Garantisci che idOrdine sia sempre impostato
        if (!data.idOrdine && doc.id) {
          data.idOrdine = doc.id;
        }

        const ordine = this.formatOrderData(data);
        this.ordiniMagazzino.push(ordine);
      });

      // Seleziona il primo ordine se nessuno è selezionato
      if (this.ordiniMagazzino.length > 0 && !this.selectedOrder) {
        this.selectedOrder = this.ordiniMagazzino[0];
      }

      console.log('Ordini caricati:', this.ordiniMagazzino.length);

    } catch (error) {
      console.error('Errore nel recupero degli ordini:', error);
      this.errorMessage = 'Errore nel caricamento degli ordini';
    } finally {
      this.isLoading = false;
    }
  }

  // Funzione per formattare i dati dell'ordine
  formatOrderData(data: any): any {
    // Assicurati che tutte le proprietà necessarie esistano
    const formattedOrder = {
      ...data,
      idOrdine: data.idOrdine || data.id || 'N/D', // Supporta entrambi i campi ID
      ristorante: data.ristorante || 'N/D',
      stato: data.stato || 'in_attesa',
      dataFormatted: null,
      carrelloMagazzino: data.carrelloMagazzino || data.items || [] // Supporta entrambi i campi per gli elementi
    };

    // Formatta la data
    if (data.data) {
      // Se è un Timestamp Firestore, lo convertiamo
      if (typeof data.data.toDate === 'function') {
        formattedOrder.dataFormatted = data.data.toDate();
      } else {
        // Se è già una data o un timestamp numerico, lo convertiamo
        const timestamp = typeof data.data === 'string' || typeof data.data === 'number'
            ? parseInt(data.data)
            : data.data;
        formattedOrder.dataFormatted = new Date(timestamp);
      }
    }

    // Calcola il prezzo totale corretto
    const totale = this.calcoloCorrettoTotaleOrdine(formattedOrder);
    formattedOrder.totalePrecalcolato = totale;

    return formattedOrder;
  }

  ngOnInit(): void {
    console.log('Inizializzazione cronologia...');
    this.isComponentMounted = true;

    // Configura il listener di autenticazione
    this.authListener = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        console.log('Utente autenticato:', user.uid);
        try {
          // Tenta di ottenere l'ID del ristorante
          this.restaurantId = await this.getRestaurantId();

          // Carica gli ordini
          await this.fetchOrdini();
        } catch (error) {
          console.error('Errore durante il caricamento iniziale:', error);
          this.errorMessage = 'Errore durante il caricamento';
          this.isLoading = false;
        }
      } else {
        console.log('Nessun utente autenticato');
        this.isLoading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.isComponentMounted = false;

    // Rimuovi il listener di autenticazione
    if (this.authListener) {
      this.authListener();
    }
  }

  sezioneNovita = 0;
  dataSourceDipendenti2= new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;
  displayedColumnsDipendenti2: string[] = ['nome', 'email', 'Action', 'Elimina'];
  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };
  display = 'none';
  closeResult = '';
  nome = 'Totale';
  nome2 = 'Mancia per Mike';
  data = '23-01-2021 19.30';
  luogo = 'Rimini ,';
  prezzo = '26.00';
  prezzo2 = '2.60';
  percentuale = 0;
  percentuale2 = 10;
  user;
  ristorante;
  ifCarrello;
  carrelloTmp;
  carrello;
  numeroElementi = 0;
  tot = 0;
  isOver = false;

  ordini = [];
  receipts = [];
  idRistorante = '';
  camerieri = [];

  retriveCarrello(): void {
    if (!firebase.auth().currentUser) return;

    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
        data => {
          if (data.data() !== undefined) {
            this.user = data.data();
            this.carrelloTmp = data.data().carrello;
            this.receipts = data.data().scontrini;

            // Assicurati che gli ordini siano caricati
            if (this.ordiniMagazzino.length === 0) {
              this.fetchOrdini();
            }
          }
        }
    );
  }

  // Funzioni di filtro
  filtroSource = 3;
  filtroStato = 2;

  filtraPagato() {
    this.filtroStato = 0;
    this.fetchOrdini();
  }

  filtraNonPagato() {
    this.filtroStato = 1;
    this.fetchOrdini();
  }

  filtraStatoTutto() {
    this.filtroStato = 2;
    this.fetchOrdini();
  }

  calcolaTotale(): void {
    this.numeroElementi = 0;
    this.tot = 0;

    if (!this.carrelloTmp || !this.carrelloTmp.piatti) return;

    this.carrelloTmp.piatti.forEach(piatto => {
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {
          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);
    });
  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {
    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {
        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;
  }

  protected readonly parseFloat = parseFloat;
  protected readonly parseInt = parseInt;
}