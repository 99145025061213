


import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../stampanti/notificationService/notification.service";
import {HttpClient} from "@angular/common/http";

import firebase from 'firebase/app';
import 'firebase/firestore';

@Component({
    selector: 'notifiche',
    templateUrl: './notifiche.component.html',
    styleUrls: ['./notifiche.component.css']
})
export class NotificheComponent {
    notificheForm: FormGroup;
    isLoading = false;

    // URL della Cloud Function
    private backendUrl = 'https://us-central1-deweats.cloudfunctions.net/api/sendNotification';

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private http: HttpClient
    ) {
        this.initForm();
    }

    private initForm() {
        this.notificheForm = this.fb.group({
            userId: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    async inviaNotifica() {
        if (this.notificheForm.invalid) {
            return;
        }

        this.isLoading = true;
        try {
            const db = firebase.firestore();
            const userDoc = await db.collection('users').doc(this.notificheForm.value.userId).get();
            const userData = userDoc.data();

            if (!userData?.fcmToken) {
                throw new Error('Token FCM non trovato per questo utente');
            }

            const notificationData = {
                data: {
                    token: userData.fcmToken,
                    title: "Ordine pronto!",
                    body: this.notificheForm.value.message
                }
            };

            // Utilizzo di catchError per gestire errori HTTP specifici
            await this.http.post(this.backendUrl, notificationData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).toPromise();

            this.notificationService.show('Notifica inviata con successo', 'success');
            this.notificheForm.reset();
        } catch (error) {
            console.error('Errore invio notifica:', error);
            let errorMessage = 'Errore durante l\'invio della notifica';

            // Gestione più specifica degli errori
            if (error.status === 403) {
                errorMessage = 'Accesso non autorizzato';
            } else if (error.status === 404) {
                errorMessage = 'Servizio non trovato';
            }

            this.notificationService.show(errorMessage, 'error');
        } finally {
            this.isLoading = false;
        }
    }
}
