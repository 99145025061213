import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'categorieCard3',
  styles: [`


    .block {
      width: 100%;
      height: 65px;
      margin: 0px;
      margin-bottom: 3px;
      display: inline-flex;
      background: white;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 20px;
      border: solid 1px #e3e2e2;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      -webkit-box-shadow: 0px 4px 4px RGBA(220, 220, 220, 0.5);
      -o-box-shadow: 0px 1px 6px RGBA(181, 181, 181, 0.3);

       */


    }

    #store-locator {
      color: black;
    }

    .store {
      position: relative;
      right: -15px;
      background-size: 10% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
    }



  `],
  template: `

    <div id="store-locator" class="block" [ngStyle]="{'border': border !== undefined ? ((selezionato!== undefined)&& (selezionato == true))? 'solid 1px black' : 'solid 1px ' + border : ((selezionato!== undefined)&& (selezionato == true))? 'solid 1px black': null}">
    <img class="store" [ngStyle]="" style="background-size: 5% auto; transform: scale(0.7)" src="{{this.link}}" onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';"  >
      <div style="padding-left: 30px; width:38%"  [ngStyle]="{'color': border !== undefined ? border : null}">{{this.nome}}</div>

      <div style="text-align: center; margin-right: 25px; font-weight: 400">
        {{costo}}
      </div>

        <div longPress style="width: 5%; margin-right: 5%;" (click)="$event.stopPropagation(); rimuoviProdotto()"  (mouseLongPress)=" rimuoviProdotto()" *ngIf="quantita > 0">
          <img  style="max-width: 35px; display: block; margin: auto; cursor: pointer !important;"
                src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd">
        </div>
        <div style=" text-align: center; margin-right: 8px" *ngIf="quantita > 0">
          {{quantita}}
        </div>
        <div longPress style="width: 5%; margin-right: 2%; z-index: 9999" (click)="$event.stopPropagation(); aggiungiProdotto()" (mouseLongPress)=" aggiungiProdotto()" *ngIf="quantita > 0">
          <img  style="width: 35px; display: block; margin: auto; cursor: pointer !important"
                src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7">
        </div>

    </div>
  `
})
export class CategorieCard3 {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() stato ;
  @Input() border ;
  @Input() quantita ;

  @Input() selezionato ;


  @Output()
  quantitaOut = new EventEmitter<number>();


  aggiungiProdotto() {
    this.quantita = this.quantita + 1;
    this.quantitaOut.emit(this.quantita);
  }

  rimuoviProdotto() {
    if(this.quantita > 0)
    {
      this.quantita = this.quantita - 1
      this.quantitaOut.emit(this.quantita);
    }
  }


  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
