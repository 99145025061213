// dialog-varianti.component.ts
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import 'firebase/storage';


interface Variant {
  id: string;
  name: string;
  price: number;
  options: VariantOption[];
  isRequired: boolean;
  maxSelections?: number;
}

interface VariantOption {
  id: string;
  name: string;
  priceModifier: number;
  imageUrl?: string;
}

@Component({
  selector: 'app-dialog-varianti',
  templateUrl: './dialog-varianti.component.html',
  styleUrls: ['./dialog-varianti.component.css']
})
export class DialogVariantiComponent implements OnInit {
  variants: Variant[] = [];
  errorMessage: string = '';
  currentView: string = 'varianti'; // 'varianti' o 'opzioni'

  constructor(
      private dataService: DataServiceGenerico,
      private snackBar: MatSnackBar,
      private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.loadVariants();
  }

  // Imposta la vista corrente (varianti o opzioni)
  setCurrentView(view: string) {
    this.currentView = view;
    this.cdRef.detectChanges();
  }

  // Ottiene le varianti da visualizzare in base alla vista corrente
  getCurrentViewVariants(): Variant[] {
    if (this.currentView === 'varianti') {
      return this.variants.filter(variant => variant.isRequired);
    } else {
      return this.variants.filter(variant => !variant.isRequired);
    }
  }

  loadVariants() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    variantiRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.variants = data?.variants || [];
        this.cdRef.detectChanges();
      }
    });
  }

  async saveVariants() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    // Verifica duplicati
    const names = this.variants.map(v => v.name.trim().toLowerCase());
    const duplicates = names.filter((name, index) => names.indexOf(name) !== index);

    if (duplicates.length > 0) {
      this.snackBar.open('Attenzione: ci sono varianti con lo stesso nome!', 'Chiudi', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    // Sanitizza i dati
    const sanitizedVariants = this.variants.map(variant => ({
      ...variant,
      name: variant.name.trim(),
      price: Number(variant.price) || 0,
      options: variant.options.map(opt => ({
        ...opt,
        name: opt.name.trim(),
        priceModifier: Number(opt.priceModifier) || 0
      }))
    }));

    try {
      await variantiRef.set({ variants: sanitizedVariants });
      this.snackBar.open('Varianti salvate con successo!', 'Chiudi', {
        duration: 2000,
      });
    } catch (error) {
      this.snackBar.open('Errore durante il salvataggio delle varianti', 'Chiudi', {
        duration: 3000,
      });
    }
  }

  addVariant() {
    const newVariant: Variant = {
      id: firebase.firestore().collection('temporary').doc().id,
      name: '',
      price: 0,
      options: [],
      isRequired: this.currentView === 'varianti', // true se varianti, false se opzioni
      maxSelections: 1
    };
    this.variants = [...this.variants, newVariant];
    this.cdRef.detectChanges();
  }

  addOptionToVariant(variant: Variant) {
    const newOption: VariantOption = {
      id: firebase.firestore().collection('temporary').doc().id,
      name: '',
      priceModifier: 0
    };

    const variantIndex = this.variants.findIndex(v => v.id === variant.id);
    if (variantIndex !== -1) {
      this.variants = this.variants.map((v, index) => {
        if (index === variantIndex) {
          return {
            ...v,
            options: [...v.options, newOption]
          };
        }
        return v;
      });
      this.cdRef.detectChanges();
    }
  }

  removeVariant(variantId: string) {
    this.variants = this.variants.filter(v => v.id !== variantId);
    this.cdRef.detectChanges();
  }

  removeOption(variant: Variant, optionId: string) {
    const variantIndex = this.variants.findIndex(v => v.id === variant.id);
    if (variantIndex !== -1) {
      this.variants = this.variants.map((v, index) => {
        if (index === variantIndex) {
          return {
            ...v,
            options: v.options.filter(o => o.id !== optionId)
          };
        }
        return v;
      });
      this.cdRef.detectChanges();
    }
  }

  async onFileSelected(event: any, variant: Variant, option: VariantOption) {
    const file = event.target.files[0];
    if (file) {
      const storageRef = firebase.storage().ref();
      const filePath = `variantiImages/${Date.now()}_${file.name}`;

      try {
        const uploadTask = await storageRef.child(filePath).put(file);
        const url = await uploadTask.ref.getDownloadURL();

        // Aggiorna l'URL dell'immagine nell'opzione
        const variantIndex = this.variants.findIndex(v => v.id === variant.id);
        const optionIndex = this.variants[variantIndex].options.findIndex(o => o.id === option.id);

        if (variantIndex !== -1 && optionIndex !== -1) {
          this.variants[variantIndex].options[optionIndex].imageUrl = url;
          this.cdRef.detectChanges();

          this.snackBar.open('Immagine caricata con successo', 'Chiudi', {
            duration: 2000,
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        this.snackBar.open('Errore nel caricamento dell\'immagine', 'Chiudi', {
          duration: 3000,
        });
      }
    }
  }

  async removeImage(variant: Variant, option: VariantOption) {
    if (option.imageUrl) {
      try {
        // Rimuovi l'immagine da Firebase Storage
        const storageRef = firebase.storage().refFromURL(option.imageUrl);
        await storageRef.delete();

        // Rimuovi l'URL dell'immagine dall'opzione
        const variantIndex = this.variants.findIndex(v => v.id === variant.id);
        const optionIndex = this.variants[variantIndex].options.findIndex(o => o.id === option.id);

        if (variantIndex !== -1 && optionIndex !== -1) {
          this.variants[variantIndex].options[optionIndex].imageUrl = '';
          this.cdRef.detectChanges();

          this.snackBar.open('Immagine rimossa con successo', 'Chiudi', {
            duration: 2000,
          });
        }
      } catch (error) {
        console.error('Error removing image:', error);
        this.snackBar.open('Errore nella rimozione dell\'immagine', 'Chiudi', {
          duration: 3000,
        });
      }
    }
  }

  closeDialog() {
    //this.dialogRef.close();
  }
}