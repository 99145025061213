<div class="row" style="height: 95vh; width:calc(100vw - 570px); margin-top: 15px; border-radius: 20px; overflow-y: scroll">
    <!-- Sezione Abilita Fidelity -->
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Fidelity</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="fidelityForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="">
                            <label class="container">Permetti fidelity
                                <input type="checkbox" formControlName="fidelityEnabled">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="saveFidelitySettings()">
                    Salva Modifiche
                </div>
            </form>
        </div>
    </div>

    <!-- Sezione Punti -->
    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Punti</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <form [formGroup]="fidelityForm">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <div class="flex flex-col xl:flex-row gap-5">
                        <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                            <label class="font-bold text-gray-700 text-heading-sm">Punti per ogni euro speso</label>
                            <input type="number" formControlName="puntiPerEuro">
                        </div>
                    </div>
                </div>

                <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="saveFidelitySettings()">
                    Salva Modifiche
                </div>
            </form>
        </div>
    </div>

    <!-- Sezione Sconti -->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Sconti</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="display: flex; gap: 10px; margin: 0 1%;">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   (click)="addSconto()"
                                   [nome]="'Aggiungi Sconto'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'">
                    </categorieCard>
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   (click)="addAccountSconto()"
                                   [nome]="'Sconto Account'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="sconti" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome" style="width: 25%">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="punti" style="width: 15%">
                        <th mat-header-cell *matHeaderCellDef> Punti </th>
                        <td mat-cell *matCellDef="let sconto"> {{sconto.punti}} </td>
                    </ng-container>

                    <ng-container matColumnDef="sconto" style="width: 20%">
                        <th mat-header-cell *matHeaderCellDef> Premio </th>
                        <td mat-cell *matCellDef="let sconto">
                            {{sconto.hasOwnProperty('prodotto') ? sconto.prodotto.join(', ') : sconto.sconto}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="validita" style="width: 30%">
                        <th mat-header-cell *matHeaderCellDef> Validità </th>
                        <td mat-cell *matCellDef="let sconto"> {{getValidityText(sconto)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="edit" style="width: 10%">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let sconto">
                            <div style="width: 30px; margin-left: 10px">
                                <button class="action-button" (click)="openDialog(sconto)">
                                    <img [src]="'assets/icons/icons8-edit-90.png'" alt="Azione" style="max-width: 15px; max-height: 15px;">
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </table>
            </div>
        </div>
    </div>

    <!-- Sezione Invia Comunicazioni -->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Invia Comunicazioni</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 10px; margin-left: 0px">
                <div style="width: 100%; display: flex; margin-right: 1%; margin-left: 1%; margin-top: 20px">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; width: 32.6%; margin-right: 1%"
                                   [nome]="'Invia email'"
                                   [bg]="'rgb(52, 152, 219)'"
                                   altezza="50"
                                   [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-send-90.png?alt=media&token=a83d40a4-57c7-4415-96c8-1d6d64265da0')] | async">
                    </categorieCard>
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; width: 32.6%; margin-right: 1%"
                                   [nome]="'invia sms'"
                                   [bg]="'rgb(52, 172, 191)'"
                                   altezza="50"
                                   [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-sms-100.png?alt=media&token=dc31caeb-d00e-4a4d-afe6-3fd17a18874b')] | async">
                    </categorieCard>
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; width: 32.6%; margin-right: 1%"
                                   [nome]="'invia whatsapp'"
                                   [bg]="'rgb(52, 191, 163)'"
                                   altezza="50"
                                   [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-whatsapp-100.png?alt=media&token=0c851170-e244-46dd-a216-26b47078a316')] | async">
                    </categorieCard>
                </div>
            </div>

            <textarea style="width: 94%; height: 300px; margin-left: 1%; border: 1px solid #d2d9e0; background-color: #f7f9fb; border-radius: 10px; margin-top: 20px"></textarea>
        </div>
    </div>
</div>