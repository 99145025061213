import {Component, Input} from "@angular/core";
@Component({
  selector: 'ordini-card-magazzino',
  styles: [`
    .card-scontrino {
      background-color: white;
      border-radius: 20px;
      border: 1px solid #e3e2e2;
      transition: 0.3s;
      margin-top: 10px;
      padding-bottom: 10px; /* Aggiunto padding bottom alla card */
    }

    .card-scontrino.selected {
      border-left: 3px solid rgb(229, 89, 51);
    }

    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .container-scontrino {
      padding: 15px 5px 5px; /* Ridotto il padding bottom */
      height: auto; /* Cambiato da height fissa a auto */
      min-height: 90px; /* Aggiunto min-height invece di height fissa */
      width: 100%;
    }

    .order-row {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      float: left;
      display: flex;
      align-items: initial;
      justify-content: left;
      margin-top: 8px; /* Ridotto il margin top */
      clear: left;
      width: 100%;
    }

    .order-number {
      margin-left: 10px;
      max-height: 1000px;
      padding: 0 5px 5px; /* Ridotto il padding bottom */
    }

    .order-total {
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 5px; /* Ridotto il padding bottom */
    }

    .status-container {
      font-weight: 600;
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 0; /* Rimosso il padding bottom */
      display: grid;
      place-items: center;
    }

    .status-tag {
      padding: 4px 12px; /* Ridotto il padding verticale */
      border-radius: 16px; /* Ridotto il border radius */
      font-size: 12px; /* Ridotto il font size */
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 110px; /* Ridotto min-width */
      height: 24px; /* Ridotto height */
    }

    .status-paid {
      background-color: rgba(76, 175, 80, 0.1);
      color: #4CAF50;
    }

    .status-unpaid {
      background-color: rgba(244, 67, 54, 0.1);
      color: #f44336;
    }

    .status-processing {
      background-color: rgba(159, 95, 213, 0.1);
      color: rgb(150, 49, 190);
    }

    .status-confirmed {
      background-color: rgba(76, 175, 80, 0.1);
      color: #4CAF50;
    }

    .status-partial {
      background-color: rgba(255, 193, 7, 0.1);
      color: #ffc107;
    }

    .bold {
      font-weight: bold;
    }

    .order-info {
      display: flex;
      align-items: center;
      margin-left: 10px;
      gap: 5px;
    }

    .date-info {
      color: #666;
      font-size: 12px;
      font-weight: normal;
    }
  `],
  template: `
    <div class="header card-scontrino" [class.selected]="selected">
      <div class="container-scontrino">
        <div class="order-row">
          <div class="order-number">
            Ordine: <span class="bold">#{{ numero }}</span>
          </div>
          <div class="order-total">
            Totale: <span class="bold">{{ totale }}€</span>
          </div>
        </div>
        <div class="order-row">
          <div class="order-info">
            <tagComponent [fonte]="fonte"></tagComponent>
            <span class="date-info">{{data?.toDate() | date:'dd/MM/yyyy HH:mm'}}</span>
          </div>
          <div class="status-container">
            <div class="status-tag" [ngClass]="{
              'status-confirmed': stato === 'confermato',
              'status-partial': stato === 'confermato_parziale',
              'status-processing': bloccato && !stato,
              'status-unpaid': !stato && !bloccato
            }">
              {{getStatusText()}}
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrdiniCardMagazzinoComponent {
  @Input() stato: 'confermato' | 'confermato_parziale' | null;
  @Input() totale;
  @Input() fonte;
  @Input() nome;
  @Input() numero;
  @Input() bloccato;
  @Input() selected = false;
  @Input() data: any; // Per il timestamp di Firebase


  getStatusText(): string {
    if (this.stato === 'confermato') {
      return 'Confermato';
    } else if (this.stato === 'confermato_parziale') {
      return 'Confermato Parziale';
    } else if (this.bloccato) {
      return 'In Preparazione';
    } else {
      return 'In Attesa';
    }
  }

  constructor() {}
}