import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {Home} from './pages/home/home';
import {SignIn} from './pages/sign-in/sign-in';
import {AuthGuard} from './shared/guard/auth.guard';
import {Magazzino} from './pages/magazzino/magazzino';
import {Ordini} from "./pages/ordini/ordini";
import {Menu2} from "./pages/menu2/menu2";
import {MagazzinoNiko} from "./pages/magazzino-niko/magazzino-niko";
import {CassaCamerieri} from "./pages/cassaCamerieri/cassa-camerieri";
import {Report} from "./pages/reports/report/report";
import {SchermoCucina} from "./pages/schermo-cucina/schermo-cucina";
import {TestOfflineModeComponent} from "./pages/test-offline-mode/test-offline-mode.component";
import {ReportPanoramica} from "./pages/reports/report-panoramica/report-panoramica";
import {ReportInventario} from "./pages/reports/report-inventario/report-inventario";
import {ReportIncassi} from "./pages/reports/report-incassi/report-incassi";
import {ReportRevenewLoss} from "./pages/reports/report-revenewloss/report-revenewLoss";
import {Impostazioni} from "./pages/settings/impostazioni/impostazioni";
import {CassaBar} from "./pages/cassa-bar/cassa-bar";
import {Tavoli} from "./pages/settings/tavoli/tavoli";
import {ReportProdotti} from "./pages/reports/report-prodotti/report-prodotti";
import {CassaOrdini} from "./pages/cassa/cassa-ordini/cassa-ordini";
import {CassaOrdine} from "./pages/cassa/cassa-ordine/cassa-ordine";
import {Dashboard} from "./pages/Dashboard/dashboard";
import {OrdiniMagazzino} from "./pages/ordiniMagazzino/ordiniMagazzino";

const routes: Routes = [

  /*
  { path: 'home', pathMatch: 'full', component: Tavoli, canActivate: [AuthGuard] },
  { path: 'magazzino', pathMatch: 'full', component: MagazzinoNiko, canActivate: [AuthGuard] },
  { path: 'ordini', pathMatch: 'full', component: Cronologia, canActivate: [AuthGuard] },
  { path: 'reports', pathMatch: 'full', component: Tavoli, canActivate: [AuthGuard] },
  { path: 'cassa', pathMatch: 'full', component: CassaBar, canActivate: [AuthGuard] },
  { path: 'menu', pathMatch: 'full', component: Menu2, canActivate: [AuthGuard] },

  { path: 'cucina', pathMatch: 'full', component: SchermoCucina, canActivate: [AuthGuard] },


  { path: 'niko', pathMatch: 'full', component: MagazzinoNiko, canActivate: [AuthGuard] },

  { path: 'report', pathMatch: 'full', component: ReportInventario, canActivate: [AuthGuard] },

   */

  { path: 'home', pathMatch: 'full', component: Home},

  { path: 'dashboard', pathMatch: 'full', component: Dashboard, canActivate: [AuthGuard]},
  { path: 'magazzino', pathMatch: 'full', component: MagazzinoNiko, canActivate: [AuthGuard] },
  { path: 'ordini', pathMatch: 'full', component: Ordini, canActivate: [AuthGuard] },
  { path: 'ordini-magazzino', pathMatch: 'full', component: OrdiniMagazzino, canActivate: [AuthGuard] },
  { path: '', pathMatch: 'full', component: CassaOrdini, canActivate: [AuthGuard]},

  { path: 'cassa', pathMatch: 'full', component: CassaOrdini, canActivate: [AuthGuard]},
  {
    path: 'cassa/order/:id',
    component: CassaOrdine,
    canActivate: [AuthGuard]
  },

  { path: 'cassa-base', pathMatch: 'full', component: CassaCamerieri, canActivate: [AuthGuard]},
  { path: 'menu', pathMatch: 'full', component: Menu2, canActivate: [AuthGuard] },

  { path: 'cucina', pathMatch: 'full', component: SchermoCucina },


  { path: 'niko', pathMatch: 'full', component: MagazzinoNiko, canActivate: [AuthGuard] },

  { path: 'test-offline-mode', component: TestOfflineModeComponent },
  { path: 'login', component: SignIn },


  //reports
  { path: 'costi-operativi', pathMatch: 'full', component: Report, canActivate: [AuthGuard] },
  { path: 'foodcost', pathMatch: 'full', component: ReportInventario, canActivate: [AuthGuard] },
  { path: 'incassi', pathMatch: 'full', component: ReportIncassi, canActivate: [AuthGuard] },
  { path: 'report', pathMatch: 'full', component: ReportRevenewLoss, canActivate: [AuthGuard] },
  { path: 'report-prodotti', pathMatch: 'full', component: ReportProdotti, canActivate: [AuthGuard] },







  { path: 'inventario', pathMatch: 'full', component: ReportPanoramica, canActivate: [AuthGuard] },
  { path: 'operativita', pathMatch: 'full', component: ReportPanoramica, canActivate: [AuthGuard] },

  { path: 'impostazioni', pathMatch: 'full', component: Impostazioni, canActivate: [AuthGuard] },





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
