import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

@Component({
  selector: 'tab-card',
  styles: [`
    .tab-card {
      background-color: white;
      border: 1px solid rgba(181,181,181, 0.7);
      border-radius: 20px;
      width: 150px;
      aspect-ratio: 1 / 1;
      float: left;
      margin-left: 1%;
      margin-top: 1%;
    }
    .tab-card-unione {
      border: 1px solid #E55933 !important;
      box-shadow: 0 0 10px rgba(229, 89, 51, 0.3);
    }

    .header-container {
      float: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      clear: left;
      width: 100%;
      height: 20%;
      padding: 0 10px;
    }

    .covers-badge {
      background-color: #353555;
      color: white;
      font-weight: 600;
      font-size: 12px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
    }

    .status-wrapper {
      font-weight: 600;
      margin-left: auto !important;
      max-height: 1000px !important;
      padding-bottom: 10px !important;
      display: grid;
      place-items: center;
    }

    .status-badge {
      padding-right: 5px;
      padding-left: 5px;
      height: 28px;
      border-radius: 8px;
      display: grid;
      place-items: center;
      font-size: 11px;
    }

    .status-paid {
      color: rgba(14,184,14,0.7);
      background-color: rgba(0,255,0, 0.3);
    }

    .status-unpaid {
      color: rgba(255,0,0, 0.7);
      background-color: rgba(255,0,0, 0.5);
    }

    .status-completed {
      color: rgba(0,119,255,0.7);
      background-color: rgba(0,119,255,0.32);
    }

    .status-in-progress {
      padding-right: 5px;
      padding-left: 5px;
      height: 32px;
      border-radius: 8px;
      color: rgba(150,49,190,0.7);
      background-color: rgba(159,95,213,0.5);
      display: grid;
      place-items: center;
      font-size: 11px;
    }

    .number-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 60%;
    }

    .number {
      font-size: 20px;
      color: gray;
      font-weight: bold;
    }

    .total-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      height: 20%;
    }

    .total-text {
      margin-left: 10%;
      font-size: 12px;
    }
  `],
  template: `
    <div class="tab-card shadow menuItem" [ngClass]="{'tab-card-unione': this.unione}" >
      <div class="header-container">
        <div *ngIf="numCoperti > 0" class="covers-badge">
          {{numCoperti}}
        </div>
        <div class="status-wrapper">
          <div *ngIf="statoPagato == 0 && bloccato !== true"
               class="status-badge status-paid">
            Pagato
          </div>
          <div *ngIf="statoPagato == 1"
               class="status-badge status-unpaid">
            Non Pagato
          </div>
          <div *ngIf="statoPagato == 3"
               class="status-badge status-completed">
            Completato
          </div>
          <div *ngIf="bloccato == true && statoPagato !== 3"
               class="status-in-progress">
            In Preparazione
          </div>
        </div>
      </div>

      <div class="number-container" >
        <p class="number">{{this.numero}}</p>
      </div>

      <div class="total-container">
        <p class="total-text">Totale: <b>{{this.totale}}€</b></p>
      </div>
    </div>
  `
})
export class tabCardComponent {
  @Input() stato;
  @Input() statoPagato;
  @Input() totale;
  @Input() fonte;
  @Input() nome;
  @Input() numero;
  @Input() bloccato;
  @Input() unione = false;
  @Input() numCoperti = 0;

  constructor() {}
}