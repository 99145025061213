import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'tagComponent',
  styles: [`
    .tag {

      display: flex;
      width: max-content;
      height: 30px;
      border-radius: 7px;
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-left: 10px;
    }

    .icon {
      width: 12px;
    }

    .text {
      height: 100%;
      display: grid;
      place-items: center;
      font-size: 10px;
      font-weight: 600;
    }

    .tagCassa {
      background-color: rgba(84, 105, 212, 0.1);
    }

    .iconCassa {
      filter: invert(30%) sepia(56%) saturate(4248%) hue-rotate(207deg) brightness(94%) contrast(86%);
    }

    .textCassa {
      color: rgba(84, 105, 212);
    }

    .tagApp {
      background-color: rgba(73, 230, 136, 0.1);
    }

    .iconApp {
      filter: invert(72%) sepia(94%) saturate(482%) hue-rotate(88deg) brightness(97%) contrast(85%);
    }

    .textApp {
      color: rgb(73, 230, 136);
    }

    .tagKiosk {
      background-color: rgba(175, 147, 69, 0.1);
    }

    .iconKiosk {
      filter: invert(53%) sepia(45%) saturate(587%) hue-rotate(344deg) brightness(95%) contrast(95%);
    }

    .textKiosk {
      color: rgb(175, 147, 69);
    }


  `],
  template: `




    <div class="tag tagApp" *ngIf="fonte==0">
      <div style="display: grid; place-items: center;">
        <img class="icon iconApp" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-phone-100.png?alt=media&token=c21a07dc-1c97-42c0-b328-442a684ceb49">
      </div>
      <div class="text textApp" style="margin-left: 5px">
        App
      </div>
    </div>

    <div class="tag tagKiosk" *ngIf="fonte==1">
      <div style="display: grid; place-items: center;">
        <img class="icon iconKiosk" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-digital-kiosk-96.png?alt=media&token=97ae6a26-f77d-441d-a14b-8e632d903739">
      </div>
      <div class="text textKiosk" style="margin-left: 5px">
        Kiosk
      </div>
    </div>

    <div class="tag tagCassa" *ngIf="fonte==2">
      <div style="display: grid; place-items: center;">
        <img class="icon iconCassa" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-cash-register-100.png?alt=media&token=35d7a1c5-889c-4d1f-ae02-c041354fe0a6">
      </div>
      <div class="text textCassa" style="margin-left: 5px">
        Cassa
      </div>
    </div>

  `
})
export class tagComponent {

  @Input() fonte ;



}
