<!-- stampanti.html -->
<div class="row">
    <div>
        <div class="header-container">
            <div class="title">Stampanti</div>
            <button
                    type="button"
                    class="add-printer-button"
                    (click)="addPrinter()">
                Aggiungi Stampante
            </button>
        </div>
        <div class="card-container">
            <form [formGroup]="printerForm" (ngSubmit)="savePrinters()">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <!-- Printers Section -->
                    <div formArrayName="printers">
                        <div *ngFor="let printer of printers.controls; let i=index" [formGroupName]="i" class="printer-section">
                            <div class="printer-header">
                                <label class="font-bold text-gray-700 text-heading-sm">Stampante {{i + 1}}</label>
                                <button type="button" class="remove-printer-button" (click)="removePrinter(i)">×</button>
                            </div>
                            <div class="printer-inputs">
                                <div class="input-container">
                                    <input
                                            type="text"
                                            formControlName="name"
                                            placeholder="Nome stampante"
                                            [class.error]="printer.get('name')?.invalid && printer.get('name')?.touched">
                                    <div class="error-message" *ngIf="printer.get('name')?.invalid && printer.get('name')?.touched">
                                        {{ getErrorMessage('name', i) }}
                                    </div>
                                </div>
                                <div class="input-container">
                                    <input
                                            type="text"
                                            formControlName="ipAddress"
                                            placeholder="Indirizzo IP"
                                            [class.error]="printer.get('ipAddress')?.invalid && printer.get('ipAddress')?.touched">
                                    <div class="error-message" *ngIf="printer.get('ipAddress')?.invalid && printer.get('ipAddress')?.touched">
                                        {{ getErrorMessage('ipAddress', i) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="save-button-container">
                    <button
                            type="submit"
                            [disabled]="printerForm.invalid || isLoading">
                        {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>