// notification.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface INotification {
  message: string;
  type: 'success' | 'error';
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notifications$ = new Subject<INotification>();
  private currentId = 0;

  getNotifications() {
    return this.notifications$.asObservable();
  }

  show(message: string, type: 'success' | 'error') {
    this.notifications$.next({
      id: this.currentId++,
      message,
      type
    });
  }
}