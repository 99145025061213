import { Injectable } from '@angular/core';
import { ipcRenderer } from 'electron';

@Injectable({
  providedIn: 'root',
})
export class PosTransactionService {
  private ipcRenderer: typeof ipcRenderer;

  constructor() {
    if (this.isElectron()) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
    }
  }

  isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }

  sendTransaction(transactionAmount: number, datiScontrino: any) {
    if (this.isElectron()) {
      this.ipcRenderer.send('send-pos-transaction', transactionAmount, datiScontrino);
    }
  }

  onTransactionResult(callback: (event: any, response: any) => void) {
    if (this.isElectron()) {
      this.ipcRenderer.on('pos-transaction-result', callback);
    }
  }
}
