import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-product-title-dialog',
    template: `
    <h2 mat-dialog-title>Modifica Nome Prodotto</h2>
    <div mat-dialog-content>
      <p>Inserisci il nuovo nome per questo prodotto:</p>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Nome Prodotto</mat-label>
        <input matInput 
               [(ngModel)]="productTitle" 
               placeholder="Inserisci qui il nome..."
               #titleInput>
      </mat-form-field>
    </div>
    <div mat-dialog-actions style="justify-content: flex-end; margin-top: 20px;">
      <button mat-button (click)="onNoClick()">Annulla</button>
      <button mat-button 
              color="primary" 
              [disabled]="!productTitle?.trim()"
              (click)="onConfirm()">
        Salva
      </button>
    </div>
  `,
    styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class EditProductTitleDialogComponent {
    productTitle: string = '';

    constructor(
        public dialogRef: MatDialogRef<EditProductTitleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // Inserisce il titolo esistente del prodotto

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        console.log('onConfirm', this.productTitle);
        this.dialogRef.close(this.productTitle);
    }
}