

<div *ngIf="nuovoUtente == false && utenteSelezionato == undefined" style="height: 120px; margin: auto; overflow: hidden; padding-top: 50px">
  <div style="width: 400px">
    <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%; padding-bottom: 20px">Cerca l'utente: </span>

    <div style="background-color: transparent; float: left; width: 400px; height: 50px !important; margin-left: 10px">

      <input type="text" [(ngModel)]="testo" (ngModelChange)="ricerca()">

    </div>
  </div>
</div>

<div style="height: 200px !important; overflow: scroll; margin-bottom: 50px" *ngIf="utentiFilter.length>0 && utenteSelezionato == undefined && testo !== ''">
<div *ngFor="let utente of utentiFilter" >
  <div style="text-align: center; height: 50px; margin: 10px; border-radius: 20px; font-size: 20px; font-weight: 400; line-height: 50px; border: 1px solid #e3e2e2; cursor: pointer" (click)="utenteSelezionato = utente">
    {{utente.nome}} {{utente.cognome}} | {{utente.email}}
  </div>
</div>
</div>

<div style="text-align: center; height: 50px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 50px; border: 1px solid #e3e2e2; cursor: pointer" *ngIf="(utentiFilter.length < 1 && nuovoUtente==false) || (testo == '')" (click)="nuovoUtente = true">
  Aggiungi Cliente
</div>


<div style="" *ngIf="nuovoUtente == true">
  <div style="height: 50px; line-height: 50px; font-weight: 700;">Crea Cliente</div>
  <div style="width: 100%; height: 340px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


    <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

      <div class="flex flex-col xl:flex-row gap-5">


        <div class="flex flex-col gap-2 flex-1">
          <label class="font-bold text-gray-700 text-heading-sm"> Nome </label>
          <input type="text" [(ngModel)]="utente.nome">
        </div>

        <div class="flex flex-col gap-2 flex-1">
          <label class="font-bold text-gray-700 text-heading-sm"> Cognome </label>
          <input type="text" [(ngModel)]="utente.cognome">
        </div>

      </div>

      <div class="flex flex-col xl:flex-row gap-5">


        <div class="flex flex-col gap-2 flex-1">
          <label class="font-bold text-gray-700 text-heading-sm"> Numero </label>
          <input type="text" [(ngModel)]="utente.numero">
        </div>

        <div class="flex flex-col gap-2 flex-1">
          <label class="font-bold text-gray-700 text-heading-sm"> Email </label>
          <input type="text" [(ngModel)]="utente.email">
        </div>

      </div>

    </div>

    <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);
    cursor: pointer;">
      Salva
    </div>

  </div>



</div>


<div *ngIf="utenteSelezionato !== undefined" style="height: 120px; margin: auto; overflow: hidden; padding-top: 50px">
  <div style="width: 500px">
    <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%;">
      {{utenteSelezionato.nome}} {{utenteSelezionato.cognome}}
    </span>

    <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%; padding-bottom: 20px">
      {{utenteSelezionato.email}}  |  {{utenteSelezionato.numero}}
    </span>
  </div>
</div>

<div style="overflow-y: auto; float: left; width: 100%; border-radius: 30px; background-color: transparent; padding-top: 10px;" *ngIf="utenteSelezionato !== undefined">

  <div *ngFor="let piatto of utenteSelezionato['sconti'], let i = index" >

    <div style="position: relative; float: left; cursor: pointer; width: 23%; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '200px' : '180px'" [style.border-color]="scontoSelezionato == piatto? 'red': '#e3e2e2'" (click)="scontoSelezionato = piatto">

      <img [src]="piatto.image_link" style="height: 100px; margin-top: 15px; background-color: transparent;">

      <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 11px">{{piatto.nome}}</span>
      <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
        <div style="padding-left: 10px; ">
          <div style="background-color: #34bfa3; font-size: small; border-radius: 12px; color: white; font-weight: bold; padding: 6px" *ngIf="piatto.hasOwnProperty('personale')">
            Offerta
          </div>
        </div>
        <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;" [style.color]="piatto.punti? 'red': piatto.puntiAggiunti?'green': ''">{{ piatto.hasOwnProperty('personale')? '' : piatto.punti? '-' + piatto.punti + 'pt': piatto.puntiAggiunti? '+' + piatto.puntiAggiunti + 'pt': ''}}</span>
      </div>
    </div>
  </div>

</div>


<div style="width: 100%; margin-top: 20px" *ngIf="utenteSelezionato !== undefined">

  <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: left;
    padding: 15px;
    color: white;
    background-color: rgb(188 72 94);
    cursor: pointer;" (click)="utenteSelezionato = undefined">
    Cambia Utente
  </div>

  <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);
    cursor: pointer; " (click)="chiudi()">
    Conferma
  </div>
</div>



<!--div (click)="aggiungiPiatto2()" *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : true) && (data.dataKey? !data.dataKey.single : true)) || (primoClick == 1) || (data.dataKey.menu == false)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 130px; border: 1px solid #e3e2e2; cursor: pointer">
  Aggiungi
  <div style="font-size: large; margin-top: -25px; line-height: 0px; color: #157315">{{(getPrezzo2()*quantita).toFixed(2)}}€</div>
</div-->


