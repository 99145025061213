import {
  Component,
  Input,
  OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import firebase from 'firebase/app';

interface MenuItem {
  id: string;
  title: string;
  image_link: string;
  price: number;
  category: string;
  description?: string;
}

interface FavoriteSection {
  name: string;
  items: string[]; // Array of item IDs
}

interface MenuDocument {
  items: MenuItem[];
  favorites: FavoriteSection[];
}

@Component({
  selector: 'preferiti',
  templateUrl: './preferiti.html',
  styleUrls: ['./preferiti.css']
})
export class Preferiti implements OnInit {
  @Input() larghezza: string | '';

  menuBello: MenuItem[] = [];
  categorie: string[] = [];
  menu: any[] = [];
  sezione = 0;
  currentFavoriteSection = 0;
  favorites: FavoriteSection[] = [
    { name: 'Preferiti', items: [] }
  ];

  constructor(
      private router: Router,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private dataServiceGenerico: DataServiceGenerico
  ) {}

  // Check if a product is in the current favorite section
  preferito(piatto: MenuItem): boolean {
    const currentSection = this.favorites[this.currentFavoriteSection];
    return currentSection?.items.includes(piatto.id) ?? false;
  }

  // Toggle product in the current favorite section
  async setPreferito(piatto: MenuItem) {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    const currentSection = this.favorites[this.currentFavoriteSection];
    const index = currentSection.items.indexOf(piatto.id);

    if (index > -1) {
      // Remove from section
      currentSection.items.splice(index, 1);
    } else {
      // Add to section
      currentSection.items.push(piatto.id);
    }

    // Update favorites in Firestore
    try {
      await menuRef.update({ favorites: this.favorites });
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  }

  // Add a new favorite section
  async addFavoriteSection() {
    const newSectionName = prompt("Inserisci il nome della nuova sezione:");

    if (!newSectionName || newSectionName.trim() === '') return;

    // Check if section name already exists
    if (this.favorites.some(section =>
        section.name.trim().toLowerCase() === newSectionName.trim().toLowerCase()
    )) {
      alert(`Una sezione chiamata "${newSectionName}" esiste già.`);
      return;
    }

    // Add new section
    this.favorites.push({
      name: newSectionName.trim(),
      items: []
    });

    // Set current section to the new section
    this.currentFavoriteSection = this.favorites.length - 1;

    // Update in Firestore
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    try {
      await menuRef.update({ favorites: this.favorites });
    } catch (error) {
      console.error('Error adding favorite section:', error);
    }
  }

  // Remove a favorite section (except the first one)
  async removeFavoriteSection(index: number) {
    if (index === 0) return;

    this.favorites.splice(index, 1);
    this.currentFavoriteSection = 0;

    // Update in Firestore
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    try {
      await menuRef.update({ favorites: this.favorites });
    } catch (error) {
      console.error('Error removing favorite section:', error);
    }
  }

  // Get products for the current favorite section
  getCurrentSectionProducts(): MenuItem[] {
    if (!this.menuBello.length) return [];

    const currentSection = this.favorites[this.currentFavoriteSection];
    return this.menuBello.filter(piatto =>
        currentSection.items.includes(piatto.id)
    );
  }

  ngOnInit(): void {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveMenu();
      }
    });
  }

  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data() as MenuDocument;

        // Initialize menu items
        this.menuBello = data.items
            .filter(elemento => elemento.image_link?.length > 0);

        // Initialize favorites
        this.favorites = data.favorites || [{ name: 'Preferiti', items: [] }];

        // Process categories
        this.categorie = this.filterUniqueCategories(this.menuBello);

        this.menu = [];
        this.categorie.forEach(categoria => this.menu.push({
          testo: categoria,
          menu: []
        }));

        this.menu.forEach((categoria, i) => {
          this.menuBello.forEach((prodotto) => {
            if(categoria.testo == prodotto.category) {
              this.menu[i].menu.push(prodotto);
            }
          });
        });
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }

  filterUniqueCategories(menuItems: MenuItem[]): string[] {
    const seenCategories = new Set();
    return menuItems
        .filter(item => {
          if (seenCategories.has(item.category)) {
            return false;
          } else {
            seenCategories.add(item.category);
            return true;
          }
        })
        .map(x => x.category)
        .filter(element => element !== undefined);
  }

  width() {
    return (100 / (this.categorie.length));
  }
}