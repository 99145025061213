import {Component, Input} from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'navBarCard',
  styles: [`
    /* Demo styles */

    .block {
      width: 100%;
      height: 70px;
      margin: 0px;
      margin-bottom: 10px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: #969696;
    }

    .store {
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center left;
      position: relative;
      right: -15px;
      background-size: 50% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
    }

    svg {
      margin-left: 10%;
      margin-bottom: 5px;
    }

    path {
      fill: black;
    }


  `],
  template: `

    <div id="store-locator" class="block">
      <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
      <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
        <g id="Iconly_Bold_Home" data-name="Iconly/Bold/Home" transform="translate(-2.5 -2)">
          <g id="Home">
            <path id="Vector" d="M6.635,18.773V15.716A1.419,1.419,0,0,1,8.058,14.3h2.874a1.429,1.429,0,0,1,1.007.414,1.408,1.408,0,0,1,.417,1v3.058a1.213,1.213,0,0,0,.356.867,1.231,1.231,0,0,0,.871.36h1.961a3.46,3.46,0,0,0,2.443-1A3.41,3.41,0,0,0,19,16.578V7.867a2.473,2.473,0,0,0-.9-1.9L11.434.676A3.1,3.1,0,0,0,7.485.747L.967,5.965A2.474,2.474,0,0,0,0,7.867v8.7A3.444,3.444,0,0,0,3.456,20H5.372a1.231,1.231,0,0,0,1.236-1.218Z" transform="translate(2.5 2)" fill="rgba(230,89,52,0.8)"/>
          </g>
        </g>
      </svg>

      <div style="padding-left: 25px">{{this.nome}}</div>
    </div>
  `
})
export class NavBarCardComponent {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('porcodio');
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
