import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';

@Component({
  selector: 'menuDetailCard',
  styles: [`
    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      transition: 0.3s;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .background-allergia {
      background-color: rgba(252,232,232,0.6);
    }

    .background-stadard {
      background-color: white;
    }

    .last {
      max-height: 90px !important;
      border-bottom: solid 1px rgba(181,181,181, 0.3);
      border-bottom-right-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }

    .first {
      max-height: 90px !important;
      padding-top: 10px;
      border-top: solid 1px rgba(181,181,181, 0.3) !important;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }

    .solo {
      max-height: 90px !important;
      padding-top: 10px;
      border-top: solid 1px rgba(181,181,181, 0.3) !important;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
      border-bottom: solid 1px rgba(181,181,181, 0.3);
      border-bottom-right-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    b, p {
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;
    }

    .aggiungi:hover, .aggiungi:active {
      filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(90deg);
    }

    .rimuovi:hover, .rimuovi:active {
      filter: invert(48%) sepia(85%) saturate(7495%) hue-rotate(-15deg) brightness(92%);
    }

    .block {
      width: 100%;
      height: 90px;
      max-height: 90px !important;
      margin: 0px;
      font-size: 16px;
      display: flex;
      align-items: center;
      font-weight: bold;
      color: black;
      background-color: white;
      border-left: solid 1px rgba(181,181,181, 0.3);
      border-right: solid 1px rgba(181,181,181, 0.3);
      transition: 0.3s;
      flex-wrap: wrap;
    }

    .verde {
      color: green;
      transition: color 0.3s ease;
    }

    .rosso {
      color: red;
      transition: color 0.3s ease;
    }
  `],
  template: `
    <div class="block" [ngClass]="{ 'first': first, 'last': last && !first, 'solo': lenght == 1 }" (click)="clicco()">
      <div style="display: flex; align-items: center; width: 100%">
        <div [ngClass]="{'verde': isGreen, 'rosso': isRed}" style="width: 7%; text-align: center">
          {{this.quantita}}x
        </div>
        <div style="text-align: center; margin-right: 10px; margin-top: -5px">
          <img class="store" style="width: 40px !important; height: 40px !important; max-height: 40px !important; max-width: 40px !important; background-size: 100% auto; transform: scale(1)"
               src="{{this.link}}"
               onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';">
        </div>
        <div style="width: 74%; padding-left: 15px">
          <div style="width: 100%; font-size: 17px; font-weight: normal; color: #4A4A4A">{{this.nome}}</div>
          <div style="width: 100%; font-size: 15px; font-weight: normal; color: gray">{{this.ingredienti}}</div>
        </div>

        <!--div style="width: 5%;">
          <img longPress class="rimuovi"
               style="max-width: 35px; display: block; margin: auto; cursor: pointer !important;"
               src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd"
               (click)="rimuoviProdotto()"
               (mouseLongPress)=" rimuoviProdotto()"
               *ngIf="pagato !== undefined ? pagato == 0 ? false : true : true">
        </div>

        <div style="width: 5%; margin-right: 2%">
          <img longPress class="aggiungi" style="width: 35px; display: block; margin: auto; cursor: pointer !important"
               src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7"
               (click)="aggiungiProdotto()"
               (mouseLongPress)=" aggiungiProdotto()"
               *ngIf="pagato !== undefined ? pagato == 0 ? false : true : true">
        </div>

        <div style="width: 7%; text-align: right; padding-right: 15px">
          {{(this.prezzo * this.quantita).toFixed(2)}}€
        </div-->


        <div style=" text-align: right; padding-right: 15px">
          {{(this.prezzo * this.quantita).toFixed(2)}}€
        </div>

      </div>

      <div *ngIf="!this.last" style="flex-basis: 100%; margin-left:15px; margin-right:15px; height: 1px; background-color: rgba(181,181,181,0.3);"></div>
    </div>
  `
})
export class MenuDetailCardComponent implements OnChanges {

  @Input() id;
  @Input() nome;
  @Input() ingredienti;
  @Input() link;
  @Input() prezzo;
  @Input() quantita;
  @Input() allergene;
  @Input() piatto: any;
  @Input() user: any;
  @Input() last: any;
  @Input() first: any;
  @Input() pagato: any;
  @Input() carrello: any;
  @Input() lenght: any;

  @Output() quantitaOut = new EventEmitter<number>();

  allergia = false;
  allergeni = '';

  isGreen = false;
  isRed = false;

  constructor(
    private router: Router,
    public dataServiceGenerico: DataServiceGenerico
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.allergia = false;
    this.allergeni = '';

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.ingredienti) {
          this.piatto.ingredienti.forEach(ingrediente => {
            ingrediente.allergeni.forEach(allergeneIngrediente => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneIngrediente === allergeneUser) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneIngrediente;
                }
              });
            });
          });
        }
      }
    }

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.opzioni) {
          this.piatto.opzioni.forEach(opzione => {
            opzione.allergeni.forEach(allergeneOpzione => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneUser === allergeneOpzione) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneOpzione;
                }
              });
            });
          });
        }
      }
    }
  }

  aggiungiProdotto() {
    this.triggerColorChange('green');
    this.quantita = this.quantita + 1;
    this.quantitaOut.emit(this.quantita);

  }

  rimuoviProdotto() {
    this.triggerColorChange('red');
    if (this.quantita > 0) {
      this.quantita = this.quantita - 1;
      this.quantitaOut.emit(this.quantita);
    }
  }

  private triggerColorChange(color: 'green' | 'red') {
    if (color === 'green') {
      this.isGreen = true;
    } else {
      this.isRed = true;
    }

    setTimeout(() => {
      this.isGreen = false;
      this.isRed = false;
    }, 700);
  }

  clicco(): void {
    console.log('ciaociaociaociao', this.nome);
  }

  logout(): void {
    this.router.navigateByUrl('login');
  }
}
