import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

export interface ConfirmDialogData {
    title: string;
    confirmText: string;
    cancelText: string;
    isRestore?: boolean;
    warning?: string;
    pager?: number;
}

@Component({
    selector: 'app-confirm-dialog',
    template: `
        <div class="dialog-container" [class.with-warning]="data.warning">
            <div class="dialog-header" [class.restore]="data.isRestore">
                <mat-icon>{{ data.isRestore ? 'restore' : (data.warning ? 'warning' : 'help_outline') }}</mat-icon>
                <h2>{{data.title}}</h2>
            </div>

            <div class="dialog-content">
                <div *ngIf="data.warning" class="warning-message">
                    <mat-icon>warning_amber</mat-icon>
                    <p>{{data.warning}}</p>
                </div>

                <div *ngIf="data.pager" class="pager-info">
                    <div class="pager-icon">
                        <mat-icon>notifications_active</mat-icon>
                    </div>
                    <div class="pager-details">
                        <span class="pager-label">Pager da chiamare</span>
                        <span class="pager-number">#{{data.pager}}</span>
                    </div>
                </div>
            </div>

            <div class="dialog-actions">
                <button class="action-button cancel-button" (click)="onCancel()">
                    <mat-icon>close</mat-icon>
                    {{data.cancelText}}
                </button>
                <button class="action-button confirm-button"
                        [class.restore]="data.isRestore"
                        [class.warning]="data.warning"
                        (click)="onConfirm()">
                    <mat-icon>{{ data.isRestore ? 'restore' : 'check' }}</mat-icon>
                    {{data.confirmText}}
                </button>
            </div>
        </div>
    `,
    styles: [`
        :host {
            display: block;
            margin: -24px;  
        }
        .dialog-container {
            background: #1E1E1E;
            color: white;
            border-radius: 20px;
            overflow: hidden;
            min-width: 400px;
            box-shadow: 0 4px 24px rgba(0,0,0,0.3);
        }

        .dialog-container.with-warning {
            border: 2px solid #FF9800;
        }

        .dialog-header {
            background: #2D2D2D;
            padding: 24px;
            display: flex;
            align-items: center;
            gap: 16px;
            border-bottom: 1px solid rgba(255,255,255,0.1);
        }

        .dialog-header.restore {
            background: linear-gradient(135deg, #1976D2, #2196F3);
        }

        .dialog-header mat-icon {
            font-size: 28px;
            width: 28px;
            height: 28px;
        }

        h2 {
            margin: 0;
            font-size: 1.5rem;
            font-weight: 500;
            color: white;
        }

        .dialog-content {
            padding: 24px;
        }

        .warning-message,
        .pager-info {
            background: rgba(255,255,255,0.05);
            border-radius: 12px;
            padding: 16px;
            display: flex;
            align-items: center;
            gap: 16px;
            margin-bottom: 16px;
        }

        .warning-message {
            border-left: 4px solid #FF9800;
        }

        .warning-message mat-icon {
            color: #FF9800;
            font-size: 24px;
            width: 24px;
            height: 24px;
        }

        .pager-info {
            border-left: 4px solid #2196F3;
        }

        .pager-icon {
            background: rgba(33, 150, 243, 0.1);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .pager-icon mat-icon {
            color: #2196F3;
        }

        .pager-details {
            display: flex;
            flex-direction: column;
        }

        .pager-label {
            font-size: 0.9rem;
            color: rgba(255,255,255,0.7);
        }

        .pager-number {
            font-size: 1.2rem;
            font-weight: 500;
            color: #2196F3;
        }

        .dialog-actions {
            padding: 16px 24px;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            background: rgba(0,0,0,0.2);
        }

        .action-button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 10px 20px;
            border: none;
            border-radius: 10px;
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;
            transition: all 0.2s ease;
            color: white;
            min-width: 120px;
            justify-content: center;
        }

        .action-button mat-icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
        }

        .cancel-button {
            background: rgba(255,255,255,0.1);
        }

        .cancel-button:hover {
            background: rgba(255,255,255,0.15);
        }

        .confirm-button {
            background: #4CAF50;
        }

        .confirm-button:hover {
            background: #43A047;
            transform: translateY(-1px);
        }

        .confirm-button.restore {
            background: #2196F3;
        }

        .confirm-button.restore:hover {
            background: #1E88E5;
        }

        .confirm-button.warning {
            background: #FF9800;
        }

        .confirm-button.warning:hover {
            background: #F57C00;
        }

        p {
            margin: 0;
            font-size: 1rem;
            color: rgba(255,255,255,0.9);
            line-height: 1.5;
        }
    `]
})
export class ConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {
        // Impostiamo lo stile del dialog container
        dialogRef.addPanelClass('dark-theme-dialog');
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}