import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {newArray} from '@angular/compiler/src/util';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {map} from 'rxjs/operators';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';
import firebase from 'firebase/app';
import {CommonService} from "../../shared/services/firestore/common.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";


@Component({
  selector: 'aggiunta-piatto',
  templateUrl: './aggiunta-piatto.html',
  styleUrls: ['./aggiunta-piatto.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
  ],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"'
  },
})
export class AggiuntaPiatto implements OnDestroy {


  bucketLvl = 0;
  terzoLivello = false;
  quartoLivello = false;
  quintoLivello = false;

  @Inject(MAT_DIALOG_DATA) public data: any


  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private menuService: MenuService,
              private carrelloService: FirestoreService, private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<any>,
              public dataService: DataServiceGenerico
  ) {
    this.ristorante = 'menu';
    this.route.params.subscribe(params => {
        console.log(params);
        if (params.ristorante) {
          //this.ristorante = params.ristorante;
          this.ristorante = 'menu';
        }
        if (params.tavolo) {
          this.numeroTavolo = params.tavolo;
        }
      }
    );


    this.retriveMenu();



    // this.retriveCarrello();
  }


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passBack() {
    this.passEntry.emit(this.user);
  }

  ngOnDestroy(): any {

    //this.passBack();

  }

  piattoSelezionato = -1;

  ciao = false;

  ciao2 = false;

  ciao3 = false;
  ciao4 = false;


  clickPiattoNuovo(i){

    this.filtraMenu(this.categoriaSelezionata)[i]
    //this.passEntry.emit(this.filtraMenu(this.categoriaSelezionata)[i]);
    //this.dialogRef.close(false);
  }

  clickPiattoInterno(i)
  {

  }

  selezionatoContorno(i: any)
  {
    let index = this.selezionataRoba.findIndex(contorno => contorno.title == i.title)
    //console.log("index: ", i)
    return index > -1
  }

  selezionaContorno(i: any, contorno: any)
  {
    console.log("contorno: ", contorno)
    this.selezionataRoba[i] = contorno;
    if(this.contornoLvl < 2)
    {
      console.log("selezionata roba: ", this.selezionataRoba)

      this.contornoLvl = this.contornoLvl + 1;
    }
    else
    {
      console.log("selezionata roba: ", this.selezionataRoba)
      this.piattoSelezionato2['category'] = this.selezionataRoba[0].title + ', ' + this.selezionataRoba[1].title + ', ' + this.selezionataRoba[2].title
      this.piattoSelezionato2['contorno'] = this.selezionataRoba[0]
      this.piattoSelezionato2['bibita'] = this.selezionataRoba[1]
      this.piattoSelezionato2['salsa'] = this.selezionataRoba[2]
      let roba = ''
      roba = this.piattoSelezionato2['title'].replace(/ /g, '')+ this.piattoSelezionato2['contorno'].title.replace(/ /g, '') +this.piattoSelezionato2['bibita'].title.replace(/ /g, '') +this.piattoSelezionato2['salsa'].title.replace(/ /g, '') + this.piattoSelezionato2['dimensioneMenu']

      if(this.carrello !== undefined) {
        if (this.carrello.hasOwnProperty(roba)) {
          this.carrello[roba].quantita = this.carrello[roba].quantita + 1
        } else {
          this.piattoSelezionato2['quantita'] = 1;
          this.carrello[roba] = this.piattoSelezionato2

        }
      }
      else
      {
        this.carrello = {};
        this.piattoSelezionato2['quantita'] = 1;
        this.carrello[roba] = this.piattoSelezionato2
      }

      this.carrello[roba].price = +this.carrello[roba].price;
      this.carrello[roba].prezzo = +this.carrello[roba].price
      console.log("carrellooo: ", this.carrello)

      this.dialogRef.componentInstance.passEntry.emit(this.carrello)

      this.quartoLivello = false;
      this.terzoLivello = false;
      this.contornoLvl = 0;
      this.bucketLvl = 0;
      this.ciao = false;
      this.ciao2 = false;
      this.ciao3 = false;
      this.ciao4 = false;
      this.categoriaSelezionata = '';

    }
  }

  selezionataRoba = [
    {
      title: 'Patatine Fritte',
      descrizione: '',
      prezzo: '0,00€',
      img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fcontorni-interni%2F291_thumb_it.png?alt=media&token=11737803-7c52-4204-b405-372686ce0987'
    },
    {
      title: 'Free Refill',
      descrizione: '',
      prezzo: '0,00€',
      img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F287_thumb_it.png?alt=media&token=9dd9e4b4-4230-4db3-bade-402be298cb4f'
    },
    {
      title: "Maionese",
      descrizione: '',
      prezzo: '0,00€',
      img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fsalse%2Fmaionese_isolated%20(1).png?alt=media&token=681ba318-fd52-44ca-b729-fc5ee79acabb',
    }
  ]
  contorni = [
    {
      testo: 'Seleziona un contorno',
      menu: [
        {
          title: 'Patatine Fritte',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fcontorni-interni%2F291_thumb_it.png?alt=media&token=11737803-7c52-4204-b405-372686ce0987'
        },
        {
          title: "Purè",
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fcontorni-interni%2F313_thumb_it.png?alt=media&token=210b46b1-1d0d-4850-92a0-6cd958752228'
        },
        {
          title: 'Pannocchia',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fcontorni-interni%2F302_thumb_it.png?alt=media&token=531eab71-b593-4c8e-b988-0b528815cc3b'
        }
      ]
    },
    {
      testo: 'Seleziona una Bibita',
      menu: [
        {
          title: 'Free Refill',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F287_thumb_it.png?alt=media&token=9dd9e4b4-4230-4db3-bade-402be298cb4f'
        },
        {
          title: "Peroni Nastro Azzurro",
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F306_thumb_it.png?alt=media&token=5a177fc1-7f78-4b2b-b364-e9bd9037c073',
        },
        {
          title: 'Red Bull Energy Drink',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F396_thumb_it.png?alt=media&token=668e2c1b-20bd-4000-a966-85c4a5598d91'
        },
        {
          title: 'Red Bull zero',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F397_thumb_it.png?alt=media&token=e9a3b3e0-572f-4b4f-bfc5-bc5cf607962c'
        },
        {
          title: 'Acqua Frizzante',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F289_thumb_it.png?alt=media&token=beb9dc8d-1a5f-4a5b-89e8-6e15caf5e544'
        },
        {
          title: 'Acqua naturale',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbibite%2F289_thumb_it.png?alt=media&token=beb9dc8d-1a5f-4a5b-89e8-6e15caf5e544'
        }
      ]
    },
    {
      testo: 'Seleziona una salsa',
      menu: [
        {
          title: 'Ketchup',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fsalse%2Fketchup_isolated%20(1).png?alt=media&token=87be8d63-6133-4460-83b7-02b2eb66e8e3'
        },
        {
          title: "Maionese",
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fsalse%2Fmaionese_isolated%20(1).png?alt=media&token=681ba318-fd52-44ca-b729-fc5ee79acabb',
        },
        {
          title: 'Salsa Barbeque',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fsalse%2Fsalsa-barbecue_isolated%20(1).png?alt=media&token=81e7b511-317b-4265-8395-7a8a6ea809cf'
        },
        {
          title: 'Senape',
          descrizione: '',
          prezzo: '0,00€',
          img: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fsalse%2Fsenape_isolated%20(1).png?alt=media&token=52e9b788-cadf-4fda-aba2-027a781ce4ea'
        }
      ]
    },

  ]

  contornoLvl = 0;
  menuSelezionato = 0;

  selezionaMenu(dimensione: any)
  {
    this.piattoSelezionato2['dimensioneMenu'] = dimensione
    this.quartoLivello = true;
  }

  piattoSelezionato2;
  updateCameriere(i) {

    if(!i.hasOwnProperty('title')) {
      this.piattoSelezionato2 = this.filtraMenu(this.categoriaSelezionata)[i]
    }

    console.log("piatto selezionato: ", this.piattoSelezionato2)

    if((this.terzoLivello == false) && (this.piattoSelezionato2.menu == true))
    {
      console.log("piatto selezionato: ", this.piattoSelezionato2)
      this.terzoLivello = true;
    }
    else {
      if(i.hasOwnProperty('title'))
      {
        i = this.filtraMenu(this.categoriaSelezionata).findIndex((prodotto) => prodotto.title == i.title)
      }
      if ((this.carrello !== undefined) && (this.carrello.hasOwnProperty(this.filtraMenu(this.categoriaSelezionata)[i].title.replace(/ /g, '')))) {
        this.carrello[this.filtraMenu(this.categoriaSelezionata)[i].title.replace(/ /g, '')].quantita++;
      } else {
        if (this.carrello == undefined) {
          let nome = this.filtraMenu(this.categoriaSelezionata)[i].title.replace(/ /g, '');

          this.carrello = {}
          this.carrello[nome] = this.filtraMenu(this.categoriaSelezionata)[i];

          this.carrello[nome].quantita = 1;
          this.carrello[nome].price = +this.carrello[nome].price.slice(0, -1);
          this.carrello[nome].prezzo = +this.carrello[nome].price
        } else {
          let nome = this.filtraMenu(this.categoriaSelezionata)[i].title.replace(/ /g, '');

          this.carrello[nome] = this.filtraMenu(this.categoriaSelezionata)[i];
          console.log("received ciaociao3")

          this.carrello[nome].quantita = 1;
          this.carrello[nome].price = +this.carrello[nome].price.slice(0, -1);
          this.carrello[nome].prezzo = +this.carrello[nome].price
        }
      }

      this.dialogRef.componentInstance.passEntry.emit(this.carrello)
    }


    //this.passEntry.emit(this.carrello);

  }

  getPrice(price: any)
  {
    let prices = price + ''
    if(prices.includes('€'))
    {
      return prices+'';
    }
    else
    {
      return prices+'€'
    }
  }


  handleEvent(event: number, piatto: any) {
    console.log("handle event: ", event);
    piatto = piatto.replace(/ /g, '')
    this.carrello[piatto].quantita = event
    if(this.carrello[piatto].quantita == 0)
    {
      delete this.carrello[piatto]
    }

    this.dialogRef.componentInstance.passEntry.emit(this.carrello)
  }

  getQuantita(prodotto: any)
  {
    if ((this.carrello !== undefined) && (this.carrello.hasOwnProperty(prodotto.title.replace(/ /g, '')))) {
      return this.carrello[prodotto.title.replace(/ /g, '')].quantita;
    }
    else
    {
      return 0
    }
  }

  clickPiattoo(i): any{

    this.piattoSelezionato = i;
    this.ciao2 = true;
    this.ciao3 = true;


    if (this.piattoSelezionato >= 0)
    {
      try{

        console.log('opzioni: ', this.menu[this.categoriaSelezionata].menu[this.piattoSelezionato].opzioni);
        this.ciao3 = true;
        this.ciao4 = false;
        this.menu[this.categoriaSelezionata].menu[this.piattoSelezionato].opzioni.forEach(opzione => {
          console.log('ci sono opzioniii');
          this.ciao3 = false;
          this.ciao4 = true;
        });

      }catch (e){
        //console.log('errore: ', e);
        console.log('catch');
        this.ciao3 = true;
        this.piattoSelezionato = -1;
        this.ciao4 = false;
      }
    }
    else{
      if (this.ciao === true)
      {
        this.ciao2 = false;
      }
      this.ciao3 = true;
      console.log('ffff');
      this.ciao4 = false;
    }


    console.log('selezionato piatto: ', this.menu[this.categoriaSelezionata].menu[i].nome);

  }

  pogba = -1;

  categoriaSelezionata = '';

  getPogba(): any{
    return this.pogba;
  }

  getNome(i): any{

    let numeri = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

    let link = 'https://emojipedia.org/keycap-digit-zero/';

    let linkk = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/325/keycap-digit-' + numeri[i] + '_3' + i + '-fe0f-20e3.png';

    return linkk;

  }

  setPogba(): any{
    this.pogba = this.pogba + 1;
    console.log('pogba: ', this.pogba);
    console.log('menu: ', this.menu);
    try {
      if (this.menu[this.pogba].categoria === undefined){}
      console.log('pogba: ', this.pogba);
      return true;
    }catch (e)
    {
      return false;
    }
  }






  ciaociao(i): any {
    console.log("ciaociaociao: ", i);
    this.ciao = !this.ciao;
    this.categoriaSelezionata = i;
  }

  ciaociao2(): any {

    if(this.quartoLivello == true)
    {
      this.quartoLivello = false;
    }
    else {
      if (this.terzoLivello == true) {
        this.terzoLivello = false;
      } else {
        this.ciao = false;
        this.ciao2 = false;
        this.ciao3 = false;
        this.ciao4 = false;
        this.categoriaSelezionata = '';
      }
    }


  }

  ciaociao3(): any {

    this.ciao = true;
    this.ciao2 = false;
    this.ciao3 = false;
    this.ciao4 = false;
    this.piattoSelezionato = -1;

  }


  ciaociao4(): any{

    if (this.piattoSelezionato >= 0)
    {
      try{

        if (this.menu[this.categoriaSelezionata].menu[this.piattoSelezionato].opzioni){

        }

        this.ciao3 = false;
        return true;

      }catch (e){
        console.log('catch');
        this.ciao3 = true;
        this.piattoSelezionato = -1;
        return false;
      }
    }
    else{
      if (this.ciao === true)
      {
        this.ciao2 = false;
      }
      this.ciao3 = false;
      console.log('ffff');
      return false;
    }

  }


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  scontrini2 = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza 1',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger 1',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano 1',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi 1',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke 1',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];


  carrello = {}

  prezzoCarrello;

  ifCarrello = false;

  carrelloTmp;


  ricerca = '';

  ricercato = false;


  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];


  tutorials?: CategoriesModel[];
  currentTutorial?: CategoriesModel;
  currentIndex = -1;
  title = '';
  menu = new Array();
  menuFiltrato = new Array();

  user: User;


  isOver = false;


  ristorante = 'ciao';
  numeroTavolo = 0;


  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  nome = 'Marco Polo';

  ingredienti = 'Visualizza Profilo';

  prezzo = 93263269631;

  scrollato = false;

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];

  public scannerEnabled = false;

  public categoria = 0;
  public categoriaBefore = 0;

  public porcodioPrima = 0;
  public porcodioDopo = 0;
  public prece = 0;
  public selez = 0;

  tot = 0;
  numeroElementi = 0;

  @ViewChildren('cacaca') testDiv: QueryList<any>;
  isTestDivScrolledIntoView: boolean;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  ngOnInit(): void {

    console.log("data: ", this.dialogRef.componentInstance.carrello)
    this.carrello = this.dialogRef.componentInstance.carrello;

  }

  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  valuechange(newValue): any {
    console.log(this.ricerca);
    this.menuFiltrato = new Array();
    this.menuFiltrato = this.filterByValue(this.menu, newValue);
    console.log('menuCard filtrato');
    console.log(this.menuFiltrato);
    if (newValue.length > 0) {
      this.ricercato = true;
    } else {
      this.ricercato = false;
    }
    // this.scontrini.filter(t => t.nome === newValue);

  }

  filterByValue(array, stringa): any {


    const result = [];

    /*
    result.concat(array.forEach(element => {

      console.log('element');
      console.log(element);
      element.menuCard.filter(o => {
        console.log('o');
        console.log(o);
        try {
          Object.keys(o).some(k => o[k].toLowerCase().includes(stringa.toLowerCase));
        }catch {}
      });

    }));

     */

    array.forEach(element => {
      console.log('element');
      console.log(element.menu);
      console.log('element filtrato');
      const filtrato = this.filterByValueInside(element.menu, stringa);
      console.log(filtrato);
      if (filtrato.length > 0) {
        result.push(filtrato);
      }
    });
    console.log('result');
    console.log(result);
    const risultato = new Array();
    risultato.push(new Array());
    result.forEach(element => risultato[0] = risultato[0].concat(element));
    console.log('risultato');
    console.log(risultato);
    return risultato;
  }

  filterByValueInside(arrayy, stringaa): any {

    console.log('filtro: ', arrayy, stringaa);

    // console.log('array');
    // console.log(array);
    return arrayy.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringaa.toLowerCase())));
  }



  menuBello = []
  categorie = []

  filtraMenu(name: String)
  {
    if(name !== 'Bucket')
    {
      return this.menuBello.filter((value) => value.category == name)
    }
    else
    {
      if((this.bucketLvl == 0))
      {
        return this.menuBello.filter((value) => value.category == name)
      }
      if((this.bucketLvl > 0))
      {
        return this.menuBello.filter((value) => value.category == name).filter((value) => value.lvl == this.bucketLvl)
      }
    }


  }

  getImage(name: String)
  {
    if(this.menuBello.length > 0)
    {
      return this.menuBello[this.menuBello.findIndex((value) => value.category == name)].hasOwnProperty('image_link')?this.menuBello[this.menuBello.findIndex((value) => value.category == name)].image_link: ''
    }
    else
    {
      return '';
    }
  }


  calcolaTotaleBello() {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if(this.carrello !== undefined)
    {
      this.getProperties(this.carrello).forEach(piatto => {
        //console.log('piatto', piatto);
        this.numeroElementi = this.numeroElementi + this.carrello[piatto].quantita;

        let prezzoPiatto = this.carrello[piatto].prezzo;
        this.tot = this.tot + (prezzoPiatto * this.carrello[piatto].quantita);
        //console.log("tot nuovo: ", this.tot)
      });
    }

    return this.tot

  }



  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.menuBello = data.items; // Recupero e assegno i dati del menu
        this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

        console.log('Menu recuperato da Firebase:', this.menuBello);
      } else {
        console.log('Nessun documento trovato su Firebase.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }



  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function( element ) {
      return element !== undefined;
    });
  }

  clickMenu(event): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.router.navigate(['/carrello']);
    event.stopPropagation();
  }

  // tslint:disable-next-line:typedef
  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }

  clicco2(): void {
    this.router.navigate(['/tavoli']);
  }


  clicco3(): void {
    this.router.navigate([this.ristorante + '/menu/modifica/modifica']);
  }

  clickPiatto(piatto?: string): void {
    this.router.navigate([this.ristorante + '/menu/' + piatto]); // , { skipLocationChange: true});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    let offset = 300;
    if (this.ricercato === true) {
      offset = 280;
    } else {
      offset = 310;
    }
    // 350 with search bar
    // 280 without search bar
    if (window.pageYOffset > offset) {
      console.log('scrollato');

      const element3 = document.getElementById('menuListaPiatti');
      element3.classList.add('menuScrolled');
      element3.classList.remove('menu');
      this.scrollato = true;

    } else {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {

        console.log('scrollato2');
      } else {
        console.log('scrollato3');
        this.scrollato = false;
        const element3 = document.getElementById('menuListaPiatti');
        element3.classList.add('menu');
        element3.classList.remove('menuScrolled');
      }
    }

    console.log(this.testDiv);


    if (this.ricercato === false) {

      this.testDiv.forEach((child) => {

        if (child) {
          const test = child.nativeElement.id;
          const rect = child.nativeElement.getBoundingClientRect();
          const topShown = rect.top >= 0;
          const bottomShown = rect.bottom <= window.innerHeight;
          this.isTestDivScrolledIntoView = topShown && bottomShown;


          console.log('top/bottom: ' + (rect.top / rect.bottom));

          if (this.isTestDivScrolledIntoView) {
            this.porcodioPrima = window.pageYOffset;
            this.porcodioDopo = window.pageYOffset;
            this.categoria = test;
            this.categoriaBefore = test;


          }


          this.porcodioDopo = window.pageYOffset;


          if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 0.75)) {
            console.log('altezza su');


            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo((test * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + test);
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== test) {
                    const elemento = document.getElementById('buttonCategoria' + conta);
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );

              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');

            }

            const element2 = document.getElementById('buttonCategoria' + (test - 1));
            if (element2 !== null) {


              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          } else if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 1)) {
            console.log('altezza giu');

            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo(((test - 1) * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + (test - 1));
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== (test - 1)) {
                    const elemento = document.getElementById('buttonCategoria' + (conta));
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );


              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');
            }

            const element2 = document.getElementById('buttonCategoria' + test);
            if (element2 !== null) {
              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          }


        }


        console.log(this.isTestDivScrolledIntoView);
      });

    }

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);

    const yOffset = -150;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});


    // const el = document.getElementById(id).scrollIntoView();
    // el.scrollIntoView({behavior: 'smooth'});
  }


  rimuoviElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    if (this.carrelloTmp.piatti[i].quantita === 1) {
      this.carrelloTmp.piatti.splice(i, 1);
      console.log('carrelo con rimozione: ', this.carrelloTmp);

    } else {
      this.carrelloTmp.piatti[i].quantita--;
    }

    this.calcolaTotale();

    this.salva();


    event.stopPropagation();
  }

  aggiungiElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    this.carrelloTmp.piatti[i].quantita = this.carrelloTmp.piatti[i].quantita + 1;

    this.calcolaTotale();

    this.salva();

    event.stopPropagation();
  }

  getPrezzo(i): any {
    // console.log('ciaobiuyew giuye231<giue23<y789e23<');
    // console.log("carrello: ", this.carrelloTmp);
    // console.log("index: ", i);


    // console.log('piatto', this.carrelloTmp.piatti[i]);

    let prezzoPiatto = this.carrelloTmp.piatti[i].prezzo;

    if (this.carrelloTmp.piatti[i].opzioni) {
      this.carrelloTmp.piatti[i].opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }

    return prezzoPiatto;
  }


  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log('user: ', this.user);

    this.user.carrello = this.carrelloTmp;

    console.log('user da salvare: ', this.user);

    this.carrelloService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.carrelloService.create(userDasalvareSalva, this.user.uid);
    // this.router.navigate(['/menuCard']);
  }

}



/*
scroll(id: any): void {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  //el.scrollIntoView({behavior: 'smooth'});
  el.scrollIntoView({ behavior: 'smooth' });

}

 */
