import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { NotificationService } from "../stampanti/notificationService/notification.service";
import { EditSconti } from "../edit-sconti/edit-sconti";

@Component({
  selector: 'fidelity',
  templateUrl: './impostazioniFidelity.html',
  styleUrls: ['./impostazioniFidelity.css']
})
export class ImpostazioniFidelity implements OnInit, OnDestroy {
  displayedColumns: string[] = ['nome', 'punti', 'sconto', 'validita', 'edit'];
  fidelityForm: FormGroup;
  isLoading = false;
  uploadProgress = 0;
  currentImageFile: File | null = null;
  puntiEuro = 1;
  scontoAccount = '10%';
  dragPosition = {x: 253, y: 244};
  dragPosition2 = {x: 253, y: 244};
  immagini = {};
  private unsubscribe: () => void;

  sconti: any[] = [];

  constructor(
      private router: Router,
      private userService: UserDataService,
      public dialog: MatDialog,
      private dataServiceGenerico: DataServiceGenerico,
      private fb: FormBuilder,
      private notificationService: NotificationService
  ) {
    this.initForm();
  }

  private initForm(): void {
    this.fidelityForm = this.fb.group({
      fidelityEnabled: [false],
      puntiPerEuro: [1, [Validators.required, Validators.min(0)]]
    });
  }

  async ngOnInit() {
    if (this.dataServiceGenerico.getRistorante1() === 'demo_1') {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();
      await this.loadPrinters();
    } else {
      await this.loadPrinters();
    }

    await Promise.all([
      this.loadFidelitySettings(),
      this.loadSconti()
    ]);

    this.immagini = this.dataServiceGenerico.getImmagini();

    const restaurantId = this.dataServiceGenerico.getRistorante1();
    if (restaurantId) {
      const db = firebase.firestore();
      this.unsubscribe = db.collection('ristoranti').doc(restaurantId)
          .onSnapshot((doc) => {
            const data = doc.data();
            if (data?.fidelitySettings) {
              const settings = data.fidelitySettings;
              this.fidelityForm.patchValue({
                fidelityEnabled: settings.fidelityEnabled ?? false,
                puntiPerEuro: settings.puntiPerEuro ?? 1
              }, { emitEvent: false });
            }
          }, (error) => {
            console.error('Error in fidelity settings snapshot:', error);
          });

      // Aggiungi listener per gli sconti
      db.collection('sconti')
          .where('ristoranteId', '==', restaurantId)
          .onSnapshot((snapshot) => {
            this.sconti = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
          }, (error) => {
            console.error('Error in sconti snapshot:', error);
          });
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  async loadPrinters() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.printers) {
        console.log('Printers loaded:', data.printers);
      }
    } catch (error) {
      console.error('Error loading printers:', error);
    }
  }

  async loadFidelitySettings() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.fidelitySettings) {
        const settings = data.fidelitySettings;
        this.fidelityForm.patchValue({
          fidelityEnabled: settings.fidelityEnabled ?? false,
          puntiPerEuro: settings.puntiPerEuro ?? 1
        });
      }
    } catch (error) {
      console.error('Error loading fidelity settings:', error);
    }
  }

  async loadSconti() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) return;

      const db = firebase.firestore();
      const scontiSnapshot = await db.collection('sconti')
          .where('ristoranteId', '==', restaurantId)
          .get();

      this.sconti = scontiSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error loading sconti:', error);
      this.notificationService.show('Errore nel caricamento degli sconti', 'error');
    }
  }

  async saveFidelitySettings() {
    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const formValue = this.fidelityForm.value;

      const fidelitySettings = {
        fidelityEnabled: formValue.fidelityEnabled,
        puntiPerEuro: formValue.puntiPerEuro
      };

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        fidelitySettings: fidelitySettings
      });

      this.notificationService.show('Impostazioni fidelity salvate con successo', 'success');
    } catch (error) {
      console.error('Error saving fidelity settings:', error);
      this.notificationService.show('Errore durante il salvataggio delle impostazioni', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  addSconto() {
    const dialogRef = this.dialog.open(EditSconti, {
      width: '600px',
      data: {
        isAccountDiscount: false
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          result.ristoranteId = restaurantId;

          const db = firebase.firestore();
          await db.collection('sconti').add(result);

          this.notificationService.show('Sconto aggiunto con successo', 'success');
        } catch (error) {
          console.error('Error adding sconto:', error);
          this.notificationService.show('Errore durante l\'aggiunta dello sconto', 'error');
        }
      }
    });
  }

  addAccountSconto() {
    const dialogRef = this.dialog.open(EditSconti, {
      width: '600px',
      data: {
        isAccountDiscount: true,
        nome: 'Sconto Account',
        punti: 0,
        sconto: this.scontoAccount,
        tipoValidita: 'always'
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteSconto({ id: result.id });
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          result.ristoranteId = restaurantId;
          result.isAccountDiscount = true;
          result.nome = 'Sconto Account'; // Forza il nome
          result.punti = 0; // Forza i punti a 0 per lo sconto account

          const db = firebase.firestore();

          // Cerca se esiste già uno sconto account
          const existingAccountSconto = await db.collection('sconti')
              .where('ristoranteId', '==', restaurantId)
              .where('isAccountDiscount', '==', true)
              .get();

          if (!existingAccountSconto.empty) {
            await db.collection('sconti').doc(existingAccountSconto.docs[0].id).update(result);
          } else {
            await db.collection('sconti').add(result);
          }

          this.notificationService.show('Sconto account salvato con successo', 'success');
        } catch (error) {
          console.error('Error saving account sconto:', error);
          this.notificationService.show('Errore durante il salvataggio dello sconto account', 'error');
        }
      }
    });
  }
  openDialog(sconto: any): void {
    const dialogRef = this.dialog.open(EditSconti, {
      width: '600px',
      data: sconto
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteSconto({ id: result.id });
          return;
        }

        try {

          console.log("sconto data: ", result)

          const db = firebase.firestore();
          await db.collection('sconti').doc(sconto.id).update(result);

          this.notificationService.show('Sconto modificato con successo', 'success');
        } catch (error) {
          console.error('Error updating sconto:', error);
          this.notificationService.show('Errore durante la modifica dello sconto', 'error');
        }
      }
    });
  }
  getValidityText(sconto: any): string {
    if (sconto.tipoValidita === 'always') return 'Sempre valido';
    if (sconto.tipoValidita === 'timeRange') return `${sconto.timeRange.startTime} - ${sconto.timeRange.endTime}`;
    if (sconto.tipoValidita === 'dateRange') return `Dal ${sconto.dataInizio} al ${sconto.dataFine}`;
    if (sconto.tipoValidita === 'specificDays') {
      const days = Object.entries(sconto.giorniSettimana)
          .filter(([_, value]) => value)
          .map(([key, _]) => this.getDayName(key));
      return days.join(', ');
    }
    return '';
  }

  getDayName(key: string): string {
    const dayMap = {
      monday: 'Lun',
      tuesday: 'Mar',
      wednesday: 'Mer',
      thursday: 'Gio',
      friday: 'Ven',
      saturday: 'Sab',
      sunday: 'Dom'
    };
    return dayMap[key] || key;
  }

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  dropped(e: any) {
    console.log("droppato: ", e);
    console.log("droppato 2: ", this.dragPosition);
  }

  async deleteSconto(sconto: any) {
    try {
      const db = firebase.firestore();
      await db.collection('sconti').doc(sconto.id).delete();
      this.notificationService.show('Sconto eliminato con successo', 'success');
    } catch (error) {
      console.error('Error deleting sconto:', error);
      this.notificationService.show('Errore durante l\'eliminazione dello sconto', 'error');
    }
  }
}