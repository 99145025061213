import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogVariantiComponent } from "../dialog-varianti/dialog-varianti.component";
import firebase from 'firebase/app';

interface Variant {
    id: string;
    name: string;
    price: number;
    options: VariantOption[];
    isRequired: boolean;
    maxSelections?: number;
}

interface VariantOption {
    id: string;
    name: string;
    priceModifier: number;
}

interface PriceListSettings {
    counter: { vatId: string };
    table: { vatId: string };
    takeaway: { vatId: string };
}

interface PriceList {
    id: string;
    name: string;
    settings: PriceListSettings;
}

interface PriceListPrices {
    counter: { price: number; vatId: string };
    table: { price: number; vatId: string };
    takeaway: { price: number; vatId: string };
}


interface MenuSection {
    title: string;
    categories: string[];
    products: string[];
}

interface MenuConfig {
    sections: MenuSection[];
    prices: {
        [listId: string]: PriceListPrices;
    };
}


@Component({
    selector: 'menu-detail',
    templateUrl: './menu-detail.component.html',
    styleUrls: ['./menu-detail.component.css']
})
export class MenuDetailComponent implements OnChanges {
    @Input() item: any;
    @Output() itemUpdated = new EventEmitter<any>();
    @Output() itemDeleted = new EventEmitter<void>();

    // Basic fields
    title: string = '';
    price: string = '';
    selectedCategory: string[] = [];
    selectedImage: string = '';
    selectedIngredients: string[] = [];
    selectedAllergens: string[] = [];
    isMenuChecked = false;
    isSingleChecked = false;
    isDisponibileChecked = false;
    selectedPrintDestination: string = '';
    reparto: number = 1;

    // Collections
    categories: string[] = [];
    ingredients: string[] = [];
    allergens: string[] = [];

    // Variants
    variants: Variant[] = [];
    selectedVariantIds: string[] = [];
    showNewVariantForm = false;
    newVariant: Variant = {
        id: '',
        name: '',
        price: 0,
        options: [],
        isRequired: false,
        maxSelections: 1
    };

    // Menu Configuration
    menuConfig: MenuConfig = {
        sections: [],
        prices: {}
    };
    selectedMenuPriceList: string = '';
    menuPrices: {[listId: string]: PriceListPrices} = {};
    menuProducts: any[] = [];

    // Price lists
    priceLists: PriceList[] = [];
    selectedPriceListId: string = '';
    isCreatingNewList = false;
    newList: PriceList = {
        id: '',
        name: '',
        settings: {
            counter: { vatId: '1' },
            table: { vatId: '1' },
            takeaway: { vatId: '1' }
        }
    };
    currentPrices: PriceListPrices = {
        counter: { price: 0, vatId: '1' },
        table: { price: 0, vatId: '1' },
        takeaway: { price: 0, vatId: '1' }
    };
    existingPriceLists: { [key: string]: PriceListPrices } = {};
    modifiedPriceLists: Map<string, PriceListPrices> = new Map();

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.item && changes.item.currentValue) {
            this.initializeFormData(changes.item.currentValue);
            this.loadData();
        }
    }

    private convertToArray(value: any): string[] {
        if (Array.isArray(value)) {
            return value.map(item => typeof item === 'string' ? item : item.name || '').filter(Boolean);
        }
        if (typeof value === 'string') {
            return value.split(',').map(item => item.trim()).filter(Boolean);
        }
        return [];
    }

    private initializeFormData(itemData: any) {
        this.title = itemData.title || '';
        this.selectedCategory = itemData.category || '';
        this.selectedImage = itemData.image_link || '';
        this.selectedIngredients = this.convertToArray(itemData.ingredients);
        this.selectedAllergens = this.convertToArray(itemData.allergens);
        this.price = itemData.price || '';
        this.isMenuChecked = !!itemData.menu;
        this.isSingleChecked = !!itemData.single;
        this.isDisponibileChecked = !!itemData.disponibilita;
        this.selectedPrintDestination = itemData.selectedPrintDestination || '';
        this.reparto = itemData.reparto || 1;
        this.selectedVariantIds = itemData.variantIds || [];
        this.existingPriceLists = itemData.priceListPrices || {};

        // Initialize menu config if exists
        if (itemData.menuConfig) {
            this.menuConfig = itemData.menuConfig;
        }

        if (itemData.menuConfig.prices){
            this.menuPrices=itemData.menuConfig.prices;
        }

        // Set current prices if a price list is selected
        if (this.selectedPriceListId && this.existingPriceLists[this.selectedPriceListId]) {
            this.currentPrices = this.existingPriceLists[this.selectedPriceListId];
        }
    }

    private loadData() {
        this.fetchDataFromFirestore();
        this.loadVariants();
        this.loadPriceLists();
        this.loadMenuProducts();
    }

    loadVariants() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('varianti')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.variants = doc.data()?.variants || [];
                }
            })
            .catch(error => {
                console.error('Error loading variants:', error);
                this.snackBar.open('Errore nel caricamento delle varianti', 'Chiudi', { duration: 3000 });
            });
    }



    private loadMenuProducts() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test') // o il percorso corretto
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.menuProducts = doc.data()?.items || [];
                }
            });
    }


    loadPriceLists() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('price_lists')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.priceLists = doc.data()?.priceLists || [];
                }
            });
    }

    fetchDataFromFirestore() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    const items = data?.items || [];

                    const categoriesSet = new Set<string>();
                    const ingredientsSet = new Set<string>();

                    items.forEach(item => {
                        if (item.category) categoriesSet.add(item.category);
                        if (item.ingredients) {
                            item.ingredients.forEach(ingredient => ingredientsSet.add(ingredient.name));
                        }
                    });

                    this.categories = Array.from(categoriesSet);
                    this.ingredients = Array.from(ingredientsSet);
                    this.allergens = this.dataService.getAllergeni();
                }
            });
    }

    // Variant Management
    addCustomVariant() {
        this.showNewVariantForm = true;
        this.newVariant = {
            id: firebase.firestore().collection('temporary').doc().id,
            name: '',
            price: 0,
            options: [],
            isRequired: false,
            maxSelections: 1
        };
    }

    addOptionToNewVariant() {
        this.newVariant.options.push({
            id: firebase.firestore().collection('temporary').doc().id,
            name: '',
            priceModifier: 0
        });
    }

    removeOptionFromVariant(index: number) {
        this.newVariant.options.splice(index, 1);
    }

    async saveNewVariant() {
        if (!this.newVariant.name) {
            this.snackBar.open('Il nome della variante è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const variantiRef = firebase.firestore().collection('varianti').doc(restaurantName);

        try {
            const doc = await variantiRef.get();
            const existingVariants = doc.exists ? (doc.data()?.variants || []) : [];

            // Check if variant with same name exists
            const variantExists = existingVariants.some(v => v.name === this.newVariant.name);
            if (variantExists) {
                this.snackBar.open('Esiste già una variante con questo nome', 'Chiudi', { duration: 3000 });
                return;
            }

            // Add new variant to both lists
            const updatedVariants = [...existingVariants, this.newVariant];
            await variantiRef.set({ variants: updatedVariants });

            this.variants = updatedVariants;
            this.selectedVariantIds = [...this.selectedVariantIds, this.newVariant.id];

            this.showNewVariantForm = false;
            this.snackBar.open('Variante creata con successo', 'Chiudi', { duration: 2000 });
        } catch (error) {
            console.error('Error saving variant:', error);
            this.snackBar.open('Errore nel salvataggio della variante', 'Chiudi', { duration: 3000 });
        }
    }

    cancelNewVariant() {
        this.showNewVariantForm = false;
        this.newVariant = {
            id: '',
            name: '',
            price: 0,
            options: [],
            isRequired: false,
            maxSelections: 1
        };
    }

    // Price List Management
    onPriceListChange(event: any) {
        const listId = event.value;
        if (!this.modifiedPriceLists.has(listId)) {
            // Se esistono prezzi precedenti per questo listino, li usiamo
            if (this.existingPriceLists[listId]) {
                this.modifiedPriceLists.set(listId, {...this.existingPriceLists[listId]});
            } else {
                // Altrimenti inizializziamo nuovi prezzi
                this.modifiedPriceLists.set(listId, {
                    counter: { price: 0, vatId: '1' },
                    table: { price: 0, vatId: '1' },
                    takeaway: { price: 0, vatId: '1' }
                });
            }
        }
        this.currentPrices = this.modifiedPriceLists.get(listId);
    }

    async saveNewPriceList() {
        if (!this.newList.name) {
            this.snackBar.open('Il nome del listino è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = firebase.firestore()
            .collection('price_lists')
            .doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            const existingLists = doc.exists ? (doc.data()?.priceLists || []) : [];

            // Check if list with same name exists
            if (existingLists.some(l => l.name === this.newList.name)) {
                this.snackBar.open('Esiste già un listino con questo nome', 'Chiudi', { duration: 3000 });
                return;
            }

            const listToSave = {
                ...this.newList,
                id: firebase.firestore().collection('temporary').doc().id
            };

            const updatedLists = [...existingLists, listToSave];
            await priceListsRef.set({ priceLists: updatedLists });

            this.priceLists = updatedLists;
            this.selectedPriceListId = listToSave.id;
            this.isCreatingNewList = false;

            this.currentPrices = {
                counter: { price: 0, vatId: listToSave.settings.counter.vatId },
                table: { price: 0, vatId: listToSave.settings.table.vatId },
                takeaway: { price: 0, vatId: listToSave.settings.takeaway.vatId }
            };

            this.snackBar.open('Listino creato con successo!', 'Chiudi', { duration: 2000 });
        } catch (error) {
            console.error('Error saving price list:', error);
            this.snackBar.open('Errore durante il salvataggio del listino', 'Chiudi', { duration: 3000 });
        }
    }

    cancelNewList() {
        this.isCreatingNewList = false;
        this.newList = {
            id: '',
            name: '',
            settings: {
                counter: { vatId: '1' },
                table: { vatId: '1' },
                takeaway: { vatId: '1' }
            }
        };
    }

    // File Management
    onFileSelected(event: any) {
        const file = event.target.files[0];
        if (file) {
            const storageRef = firebase.storage().ref();
            const filePath = `immaginiMenu/${Date.now()}_${file.name}`;

            storageRef.child(filePath).put(file)
                .then(snapshot => snapshot.ref.getDownloadURL())
                .then(url => {
                    this.selectedImage = url;
                    this.snackBar.open('Immagine caricata con successo', 'Chiudi', { duration: 2000 });
                })
                .catch(error => {
                    console.error('Error uploading image:', error);
                    this.snackBar.open('Errore nel caricamento dell\'immagine', 'Chiudi', { duration: 3000 });
                });
        }
    }

    removeImage() {
        this.selectedImage = '';
    }
    saveChanges() {
        if (!this.title) {
            this.snackBar.open('Il nome del prodotto è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const updatedItem = {
            id: this.item?.id || firebase.firestore().collection('temporary').doc().id,
            title: this.title,
            category: this.selectedCategory || '',
            image_link: this.selectedImage || '',
            ingredients: this.selectedIngredients.map(name => ({ name, quantity: 1 })) || [],
            allergens: this.selectedAllergens.map(name => ({ name, quantity: 1 })) || [],
            price: this.price || '0',
            menu: this.isMenuChecked || false,
            single: this.isSingleChecked || false,
            disponibilita: this.isDisponibileChecked || false,
            selectedPrintDestination: this.selectedPrintDestination || 'cucina',
            reparto: this.reparto || 1,
            variantIds: this.selectedVariantIds || []
        };

        // Add menu configuration if menu is checked
        if (this.isMenuChecked) {
            updatedItem['menuConfig'] = {
                sections: this.menuConfig.sections,
                prices: this.menuPrices
            };
        }

        // Update existing price lists or add new ones
        /*if (this.selectedPriceListId) {
            const allPriceLists = { ...this.existingPriceLists };
            allPriceLists[this.selectedPriceListId] = this.currentPrices;
            updatedItem['priceListPrices'] = allPriceLists;
        }

         */

        const priceListPrices = {};
        this.modifiedPriceLists.forEach((prices, listId) => {
            priceListPrices[listId] = prices;
        });
        updatedItem['priceListPrices'] = {...this.existingPriceLists, ...priceListPrices};

        const restaurantName = this.dataService.getRistorante();
        const menuRef = firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName);

        menuRef.get().then(doc => {
            if (doc.exists) {
                const items = doc.data()?.items || [];
                let updatedItems: any[];

                if (this.item?.id) {
                    updatedItems = items.map(item =>
                        item.id === this.item.id ? updatedItem : item
                    );
                } else {
                    updatedItems = [...items, updatedItem];
                }

                if (!updatedItems || updatedItems.length === 0) {
                    throw new Error('No items to update');
                }

                const updateData = {
                    items: updatedItems,
                    ristorante: restaurantName
                };

                return menuRef.set(updateData)
                    .then(() => {
                        this.snackBar.open('Modifiche salvate con successo', 'Chiudi', { duration: 2000 });
                        this.itemUpdated.emit(updatedItem);
                    })
                    .catch(error => {
                        console.error('Error saving changes:', error);
                        this.snackBar.open('Errore nel salvataggio delle modifiche', 'Chiudi', { duration: 3000 });
                    });
            } else {
                const initialData = {
                    items: [updatedItem],
                    ristorante: restaurantName
                };
                return menuRef.set(initialData)
                    .then(() => {
                        this.snackBar.open('Elemento creato con successo', 'Chiudi', { duration: 2000 });
                        this.itemUpdated.emit(updatedItem);
                    })
                    .catch(error => {
                        console.error('Error creating document:', error);
                        this.snackBar.open('Errore nella creazione del documento', 'Chiudi', { duration: 3000 });
                    });
            }
        });
    }

    deleteItem() {
        if (!this.item?.id) return;

        const restaurantName = this.dataService.getRistorante();
        const menuRef = firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName);

        menuRef.get().then(doc => {
            if (doc.exists) {
                const items = doc.data()?.items || [];
                const updatedItems = items.filter(item => item.id !== this.item.id);

                menuRef.update({ items: updatedItems })
                    .then(() => {
                        this.snackBar.open('Elemento eliminato con successo', 'Chiudi', { duration: 2000 });
                        this.itemDeleted.emit();
                    })
                    .catch(error => {
                        console.error('Error deleting item:', error);
                        this.snackBar.open('Errore nell\'eliminazione dell\'elemento', 'Chiudi', { duration: 3000 });
                    });
            }
        });
    }

    // Menu Configuration Methods
    addMenuSection() {
        if (this.menuConfig.sections.length < 2) {
            this.menuConfig.sections.push({
                title: '',
                categories: [],
                products: []
            });
        }
    }

    removeMenuSection(index: number) {
        this.menuConfig.sections.splice(index, 1);
    }

    onMenuPriceListChange(event: any) {
        const listId = event.value;
        if (!this.menuPrices[listId]) {
            this.menuPrices[listId] = {
                counter: { price: 0, vatId: '1' },
                table: { price: 0, vatId: '1' },
                takeaway: { price: 0, vatId: '1' }
            };
        }
    }




}