// user-orders-dialog.component.ts
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';

interface OrderItem {
    title: string;
    quantita: number;
    prezzo: number;
    image_link?: string;
}

interface Order {
    id: string;
    data: Date;
    totale: number;
    items: OrderItem[];
}

@Component({
    selector: 'app-user-orders-dialog',
    template: `
        <div class="dialog-container">
            <h2 mat-dialog-title>Ordini di {{data.userName}}</h2>

            <mat-dialog-content>
                <div *ngIf="loading" class="loading-state">
                    <mat-spinner diameter="40"></mat-spinner>
                </div>

                <div *ngIf="!loading && orders.length === 0" class="empty-state">
                    <mat-icon>receipt_long</mat-icon>
                    <p>Nessun ordine trovato</p>
                </div>

                <div *ngIf="!loading && orders.length > 0" class="orders-list">
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let order of orders">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{order.data | date:'dd/MM/yyyy HH:mm'}}
                                </mat-panel-title>
                                <mat-panel-description>
                                    Totale: {{order.totale | currency:'EUR'}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="order-items">
                                <div *ngFor="let item of order.items" class="order-item">
                                    <div class="item-image" *ngIf="item.image_link">
                                        <img [src]="item.image_link"
                                             [alt]="item.title"
                                             onerror="this.src='assets/placeholder.png'">
                                    </div>
                                    <div class="item-details">
                                        <span class="item-title">{{item.title}}</span>
                                        <span class="item-quantity">Quantità: {{item.quantita}}</span>
                                        <span class="item-price">{{item.prezzo | currency:'EUR'}}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </mat-dialog-content>

            <mat-dialog-actions align="end">
                <button mat-button (click)="onClose()">Chiudi</button>
            </mat-dialog-actions>
        </div>
    `,
    styles: [`
        .dialog-container {
            min-width: 600px;
            max-width: 800px;
        }

        .loading-state {
            display: flex;
            justify-content: center;
            padding: 2rem;
        }

        .empty-state {
            text-align: center;
            padding: 2rem;
            color: rgba(0, 0, 0, 0.6);
        }

        .empty-state mat-icon {
            font-size: 48px;
            height: 48px;
            width: 48px;
            margin-bottom: 1rem;
        }

        .orders-list {
            margin: 1rem 0;
        }

        .order-items {
            margin-top: 1rem;
        }

        .order-item {
            display: flex;
            gap: 1rem;
            padding: 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .order-item:last-child {
            border-bottom: none;
        }

        .item-image {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            overflow: hidden;
        }

        .item-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .item-details {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
        }

        .item-title {
            font-weight: 500;
        }

        .item-quantity {
            color: rgba(0, 0, 0, 0.6);
            font-size: 0.9em;
        }

        .item-price {
            color: #10B981;
            font-weight: 500;
        }
    `]
})
export class UserOrdersDialogComponent implements OnInit {
    orders: Order[] = [];
    loading = true;

    constructor(
        public dialogRef: MatDialogRef<UserOrdersDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { userId: string; userName: string; restaurantId: string; }
    ) {}

    ngOnInit() {
        this.loadOrders();
    }

    async loadOrders() {
        try {
            const ordersSnapshot = await firebase.firestore()
                .collection('ordini')
                .where('userId', '==', this.data.userId)
                .where('ristorante', '==', this.data.restaurantId)
                .where('statoPagato', '==', 0)
                .orderBy('data', 'desc')
                .get();

            this.orders = ordersSnapshot.docs.map(doc => {
                const data = doc.data();
                const items = Object.values(data.carrello || {}).map((item: any) => ({
                    title: item.title,
                    quantita: item.quantita || 1,
                    prezzo: item.prezzo || 0,
                    image_link: item.image_link || null
                }));

                // Calcola il totale dell'ordine dalle quantità e prezzi degli items
                const totale = items.reduce((sum, item) =>
                    sum + (item.prezzo * item.quantita), 0);

                // Gestione sicura della conversione del timestamp
                let orderDate: Date;
                if (data.data && typeof data.data.toDate === 'function') {
                    orderDate = data.data.toDate();
                } else if (data.data instanceof Date) {
                    orderDate = data.data;
                } else {
                    orderDate = new Date(); // Data di fallback se non riusciamo a convertire
                }

                return {
                    id: doc.id,
                    data: orderDate,
                    totale: totale,
                    items
                };
            });
        } catch (error) {
            console.error('Error loading orders:', error);
        } finally {
            this.loading = false;
        }
    }

    onClose(): void {
        this.dialogRef.close();
    }
}