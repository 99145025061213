import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../shared/services/firestore/common.service';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import { ConfirmDialogComponent } from "../ConfirmDialogData/confirmDialogData.component";
import firebase from 'firebase/app';
import 'firebase/firestore';

interface CartProduct {
    title: string;
    quantita: number;
    variants?: Array<{
        name: string;
        variantTitle: string;
    }>;
    selectedPrintDestination?: string;
    category?: string;
    pronto: boolean;
    portata?: number;
}

interface Order {
    id: string;
    ordine: string;
    data: string;
    selectedPriceListType: string;
    tavolo?: string;
    nota?: string;
    statoPagato: number;
    timestampEvasione?: string;
    carrello: {
        [key: string]: CartProduct;
    };
    ordineInserimento: string[];
    pager?: number;
    statoEvasione?: 'completo' | 'parziale' | 'non_evaso';
}

interface TotalItem {
    title: string;
    quantity: number;
    category?: string;
    selectedPrintDestination?: string;
    variants?: Array<{
        name: string;
        variantTitle: string;
    }>;
}

@Component({
    selector: 'app-kitchen-monitor',
    templateUrl: './kitchen-monitor.component.html',
    styleUrls: ['./kitchen-monitor.component.css']
})
export class KitchenMonitorComponent implements OnInit, OnDestroy {
    // Stati della vista
    currentTime: Date = new Date();
    showHistory: boolean = false;
    showTotals: boolean = false;
    showFilters: boolean = false;
    showEvasiOnly: boolean = false;

    // Stato di navigazione
    activeSection: 'orders' | 'totals' | 'filters' = 'orders';
    selectedOrderIndex: number = 0;
    selectedItemIndex: number = 0;
    selectedFilterType: 'destinations' | 'categories' | null = null;
    selectedFilterIndex: number = -1;
    selectedTotalIndex: number = -1;

    // Paginazione
    currentPage: number = 0;
    ordersPerPage: number = 8;

    // Dati
    ordiniCucina: Order[] = [];
    ordiniEvasi: Order[] = [];
    ordiniOriginali: Order[] = [];
    tempoMedioEvasione: string = '0:00';
    totalItems: TotalItem[] = [];

    // Filtri
    categories: string[] = [];
    selectedCategories: string[] = [];
    printDestinations: string[] = [];
    selectedDestinations: string[] = [];

    private subscriptions: Subscription[] = [];
    private restaurantId: string = '';

    constructor(
        private commonService: CommonService,
        private dataServiceGenerico: DataServiceGenerico,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            interval(1000).subscribe(() => {
                this.currentTime = new Date();
            })
        );
        this.initializeMonitor();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', ' ', 'Tab'].includes(event.key)) {
            event.preventDefault();
        }

        // Gestione navigazione laterale tra sezioni
        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            const direction = event.key === 'ArrowLeft' ? -1 : 1;
            // Se siamo negli ordini, navighiamo tra gli ordini
            if (this.activeSection === 'orders') {
                this.navigateOrders(direction);
            }
            // Se siamo nei filtri, navighiamo tra le sezioni dei filtri
            else if (this.activeSection === 'filters') {
                if (direction === -1 && this.selectedFilterType === 'categories') {
                    this.selectedFilterType = 'destinations';
                    this.selectedFilterIndex = 0;
                } else if (direction === 1 && this.selectedFilterType === 'destinations') {
                    this.selectedFilterType = 'categories';
                    this.selectedFilterIndex = 0;
                }
            }
            return;
        }

        switch(event.key) {
            case 'Tab':
                event.preventDefault();
                if (event.shiftKey) {
                    this.navigatePreviousSection();
                } else {
                    this.navigateNextSection();
                }
                break;
            case 'ArrowUp':
                this.navigateVertical(-1);
                break;
            case 'ArrowDown':
                this.navigateVertical(1);
                break;
            case 'PageUp':
                this.previousPage();
                break;
            case 'PageDown':
                this.nextPage();
                break;
            case 'Enter':
                this.handleEnterKey();
                break;
            case 'Escape':
                this.handleEscapeKey();
                break;
            case ' ':
                this.toggleFilters();
                break;
            case 'e':
            case 'E':
                this.toggleEvasiView();
                break;
        }
    }

    // Naviga alla prossima sezione disponibile
    private navigateNextSection(): void {
        const sections: ('orders' | 'filters' | 'totals')[] = ['orders'];
        if (this.showFilters) sections.push('filters');
        if (this.showTotals) sections.push('totals');

        const currentIndex = sections.indexOf(this.activeSection);
        const nextIndex = (currentIndex + 1) % sections.length;
        this.activeSection = sections[nextIndex];

        // Reset degli indici appropriati per la nuova sezione
        if (this.activeSection === 'filters') {
            this.selectedFilterType = 'destinations';
            this.selectedFilterIndex = 0;
        } else if (this.activeSection === 'totals') {
            this.selectedTotalIndex = 0;
        }
    }

    // Naviga alla sezione precedente disponibile
    private navigatePreviousSection(): void {
        const sections: ('orders' | 'filters' | 'totals')[] = ['orders'];
        if (this.showFilters) sections.push('filters');
        if (this.showTotals) sections.push('totals');

        const currentIndex = sections.indexOf(this.activeSection);
        const prevIndex = (currentIndex - 1 + sections.length) % sections.length;
        this.activeSection = sections[prevIndex];

        // Reset degli indici appropriati per la nuova sezione
        if (this.activeSection === 'filters') {
            this.selectedFilterType = 'destinations';
            this.selectedFilterIndex = 0;
        } else if (this.activeSection === 'totals') {
            this.selectedTotalIndex = 0;
        }
    }

    // Navigazione principale
    private navigateForward(): void {
        switch(this.activeSection) {
            case 'orders':
                if (this.showFilters) {
                    this.activeSection = 'filters';
                    this.selectedFilterType = 'destinations';
                    this.selectedFilterIndex = 0;
                } else if (this.showTotals) {
                    this.activeSection = 'totals';
                    this.selectedTotalIndex = 0;
                }
                break;
            case 'filters':
                if (this.selectedFilterType === 'destinations') {
                    this.selectedFilterType = 'categories';
                    this.selectedFilterIndex = 0;
                } else if (this.showTotals) {
                    this.activeSection = 'totals';
                    this.selectedTotalIndex = 0;
                }
                break;
            case 'totals':
                this.activeSection = 'orders';
                break;
        }
    }

    private navigateBackward(): void {
        switch(this.activeSection) {
            case 'orders':
                if (this.showTotals) {
                    this.activeSection = 'totals';
                    this.selectedTotalIndex = 0;
                } else if (this.showFilters) {
                    this.activeSection = 'filters';
                    this.selectedFilterType = 'categories';
                    this.selectedFilterIndex = 0;
                }
                break;
            case 'filters':
                if (this.selectedFilterType === 'categories') {
                    this.selectedFilterType = 'destinations';
                    this.selectedFilterIndex = 0;
                } else {
                    this.activeSection = 'orders';
                }
                break;
            case 'totals':
                if (this.showFilters) {
                    this.activeSection = 'filters';
                    this.selectedFilterType = 'categories';
                    this.selectedFilterIndex = 0;
                } else {
                    this.activeSection = 'orders';
                }
                break;
        }
    }

    private navigateVertical(direction: number): void {
        switch(this.activeSection) {
            case 'orders':
                this.navigateItems(direction);
                break;
            case 'filters':
                this.navigateFilters(direction);
                break;
            case 'totals':
                this.navigateTotalItems(direction);
                break;
        }
    }

    private navigateItems(direction: number): void {
        if (this.activeSection !== 'orders') return;

        const currentOrder = this.paginatedOrders[this.selectedOrderIndex];
        if (!currentOrder) return;

        const items = this.getFilteredItems(currentOrder);
        const newIndex = this.selectedItemIndex + direction;

        if (newIndex >= 0 && newIndex < items.length) {
            this.selectedItemIndex = newIndex;
            this.ensureElementVisible(`.product-item:nth-child(${newIndex + 1})`);
        }
    }

    private navigateOrders(direction: number): void {
        if (this.activeSection !== 'orders') return;

        const newIndex = this.selectedOrderIndex + direction;
        if (newIndex >= 0 && newIndex < this.filteredOrders.length) {
            this.selectedOrderIndex = newIndex;
            this.selectedItemIndex = 0;
            this.ensureOrderVisible(newIndex);
        }
    }

    private navigateFilters(direction: number): void {
        if (this.activeSection !== 'filters' || !this.selectedFilterType) return;

        const items = this.selectedFilterType === 'destinations' ?
            this.printDestinations : this.categories;

        const newIndex = this.selectedFilterIndex + direction;
        if (newIndex >= 0 && newIndex < items.length) {
            this.selectedFilterIndex = newIndex;
            this.ensureElementVisible(`.filter-option.${this.selectedFilterType}:nth-child(${newIndex + 1})`);
        }
    }

    private navigateTotalItems(direction: number): void {
        if (this.activeSection !== 'totals') return;

        const items = this.getFilteredTotalItems();
        const newIndex = this.selectedTotalIndex + direction;

        if (newIndex >= 0 && newIndex < items.length) {
            this.selectedTotalIndex = newIndex;
            this.ensureElementVisible(`.total-item:nth-child(${newIndex + 1})`);
        }
    }

    // Gestione visibilità elementi
    private ensureOrderVisible(orderIndex: number): void {
        setTimeout(() => {
            const orderElement = document.querySelector(`.order-card:nth-child(${orderIndex + 1})`);
            if (orderElement) {
                orderElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }, 0);
    }

    private ensureElementVisible(selector: string): void {
        setTimeout(() => {
            const element = document.querySelector(selector);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest'
                });
            }
        }, 0);
    }

    // Gestione delle azioni
    private handleEnterKey(): void {
        switch(this.activeSection) {
            case 'orders':
                this.toggleSelectedItem();
                break;
            case 'filters':
                if (this.selectedFilterType === 'destinations' && this.selectedFilterIndex >= 0) {
                    this.toggleDestination(this.printDestinations[this.selectedFilterIndex]);
                } else if (this.selectedFilterType === 'categories' && this.selectedFilterIndex >= 0) {
                    this.toggleCategory(this.categories[this.selectedFilterIndex]);
                }
                break;
        }
    }

    private handleEscapeKey(): void {
        if (this.showTotals || this.showFilters) {
            this.showTotals = false;
            this.showFilters = false;
            this.activeSection = 'orders';
        } else {
            this.toggleTotals();
            if (this.showTotals) {
                this.activeSection = 'totals';
                this.selectedTotalIndex = 0;
            }
        }
    }

    // Paginazione
    get paginatedOrders(): Order[] {
        const startIndex = this.currentPage * this.ordersPerPage;
        return this.filteredOrders.slice(startIndex, startIndex + this.ordersPerPage);
    }

    get totalPages(): number {
        return Math.ceil(this.filteredOrders.length / this.ordersPerPage);
    }

    nextPage(): void {
        if (this.currentPage < this.totalPages - 1) {
            this.currentPage++;
            this.selectedOrderIndex = 0;
            this.selectedItemIndex = 0;
            this.ensureOrderVisible(0);
        }
    }

    previousPage(): void {
        if (this.currentPage > 0) {
            this.currentPage--;
            this.selectedOrderIndex = 0;
            this.selectedItemIndex = 0;
            this.ensureOrderVisible(0);
        }
    }

    // Metodi di inizializzazione e setup
    private async initializeMonitor(): Promise<void> {
        try {
            this.restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();
            if (!this.restaurantId) {
                throw new Error('Restaurant ID not available');
            }
            await this.loadCategories();
            this.setupOrdersListener();
        } catch (error) {
            console.error('Error initializing kitchen monitor:', error);
        }
    }

    private async loadCategories(): Promise<void> {
        try {
            const menuDoc = await firebase.firestore()
                .collection('menu_test')
                .doc(this.restaurantId)
                .get();

            if (menuDoc.exists) {
                const items = menuDoc.data()?.items || [];
                const categoriesSet = new Set<string>();
                const destinationsSet = new Set<string>();

                items?.forEach(item => {
                    if (item.category?.trim()) {
                        categoriesSet.add(item.category);
                    }
                    if (item.selectedPrintDestination) {
                        destinationsSet.add(item.selectedPrintDestination);
                    }
                });

                this.categories = Array.from(categoriesSet);
                this.printDestinations = Array.from(destinationsSet);
            }
        } catch (error) {
            console.error('Error loading categories:', error);
        }
    }

    private setupOrdersListener(): void {
        const today = new Date().setHours(0, 0, 0, 0);

        this.subscriptions.push(
            this.commonService.getOrdiniByDate(this.restaurantId, today).subscribe(ordini => {
                if (ordini) {
                    ordini?.forEach(ordine => {
                        if (ordine.carrello) {
                            Object.values(ordine.carrello).forEach((prodotto: any) => {
                                if (typeof prodotto.pronto === 'undefined') {
                                    prodotto.pronto = false;
                                }
                            });
                        }
                    });

                    const filteredOrders = ordini.sort((a, b) => parseInt(a.data) - parseInt(b.data));
                    this.ordiniOriginali = [...filteredOrders];
                    this.ordiniCucina = filteredOrders.filter(ordine => ordine.statoPagato !== 3);
                    this.ordiniEvasi = filteredOrders.filter(ordine => ordine.statoPagato === 3);

                    this.calcolaTempoMedioEvasione();
                    this.calculateTotalItems();
                    this.applyFilters();
                }
            })
        );
    }

    // Gestione degli ordini e filtri
    get filteredOrders(): Order[] {
        return this.showEvasiOnly ? this.ordiniEvasi : this.ordiniCucina;
    }

    getFilteredItems(order: Order): string[] {
        if (!order.ordineInserimento) return [];

        return order.ordineInserimento.filter(productId => {
            const product = order.carrello[productId];
            if (!product) return false;

            if (this.selectedDestinations.length > 0 &&
                !this.selectedDestinations.includes(product.selectedPrintDestination)) {
                return false;
            }

            if (this.selectedCategories.length > 0 &&
                !this.selectedCategories.includes(product.category)) {
                return false;
            }

            return true;
        });
    }

    private calculateTotalItems() {
        const itemsMap = new Map<string, TotalItem>();

        this.ordiniCucina?.forEach(order => {
            order.ordineInserimento?.forEach(productId => {
                const product = order.carrello[productId];
                if (!product) return;

                const key = product.title;

                if (itemsMap.has(key)) {
                    itemsMap.get(key)!.quantity += product.quantita;
                } else {
                    itemsMap.set(key, {
                        title: product.title,
                        quantity: product.quantita,
                        category: product.category || 'Non categorizzato',
                        selectedPrintDestination: product.selectedPrintDestination || 'Non specificato',
                        variants: product.variants
                    });
                }
            });
        });

        this.totalItems = Array.from(itemsMap.values())
            .sort((a, b) => b.quantity - a.quantity);
    }

    getFilteredTotalItems(): TotalItem[] {
        return this.totalItems.filter(item => {
            if (this.selectedDestinations.length > 0 &&
                !this.selectedDestinations.includes(item.selectedPrintDestination)) {
                return false;
            }

            if (this.selectedCategories.length > 0 &&
                !this.selectedCategories.includes(item.category)) {
                return false;
            }

            return true;
        });
    }

    // Toggle methods
    toggleTotals(): void {
        this.showTotals = !this.showTotals;
        if (this.showTotals) {
            this.activeSection = 'totals';
            this.selectedTotalIndex = 0;
            this.calculateTotalItems();
        } else {
            this.activeSection = 'orders';
        }
    }

    toggleFilters(): void {
        this.showFilters = !this.showFilters;
        if (this.showFilters) {
            this.activeSection = 'filters';
            this.selectedFilterType = 'destinations';
            this.selectedFilterIndex = 0;
        } else {
            this.activeSection = 'orders';
        }
    }

    toggleEvasiView(): void {
        this.showEvasiOnly = !this.showEvasiOnly;
        this.selectedOrderIndex = 0;
        this.selectedItemIndex = 0;
        this.currentPage = 0;
        this.applyFilters();
    }

    toggleDestination(destination: string): void {
        const index = this.selectedDestinations.indexOf(destination);
        if (index === -1) {
            this.selectedDestinations.push(destination);
        } else {
            this.selectedDestinations.splice(index, 1);
        }
        this.applyFilters();
    }

    toggleCategory(category: string): void {
        const index = this.selectedCategories.indexOf(category);
        if (index === -1) {
            this.selectedCategories.push(category);
        } else {
            this.selectedCategories.splice(index, 1);
        }
        this.applyFilters();
    }

    hasActiveFilters(): boolean {
        return this.selectedDestinations.length > 0 || this.selectedCategories.length > 0;
    }

    clearFilters(): void {
        this.selectedDestinations = [];
        this.selectedCategories = [];
        this.ordiniCucina = this.ordiniOriginali.filter(ordine => ordine.statoPagato !== 3);
        this.calculateTotalItems();
    }

    applyFilters(): void {
        const ordiniDaFiltrare = this.showEvasiOnly ?
            [...this.ordiniEvasi] :
            [...this.ordiniOriginali.filter(o => o.statoPagato !== 3)];

        let filteredOrders = [...ordiniDaFiltrare];

        if (this.selectedDestinations.length > 0) {
            filteredOrders = filteredOrders.filter(ordine =>
                ordine.ordineInserimento?.some(prodottoId =>
                    this.selectedDestinations.includes(ordine.carrello[prodottoId]?.selectedPrintDestination)
                )
            );
        }

        if (this.selectedCategories.length > 0) {
            filteredOrders = filteredOrders.filter(ordine =>
                ordine.ordineInserimento?.some(prodottoId =>
                    this.selectedCategories.includes(ordine.carrello[prodottoId]?.category)
                )
            );
        }

        if (this.showEvasiOnly) {
            this.ordiniCucina = [];
            this.ordiniEvasi = filteredOrders;
        } else {
            this.ordiniCucina = filteredOrders;
            this.ordiniEvasi = this.ordiniOriginali.filter(o => o.statoPagato === 3);
        }

        this.calculateTotalItems();
        this.currentPage = 0;
    }

    // Metodi di utilità e calcoli
    calcolaTempoMedioEvasione(): void {
        if (!this.ordiniEvasi?.length) {
            this.tempoMedioEvasione = '0:00';
            return;
        }

        let totalSeconds = 0;
        let ordiniValidi = 0;

        this.ordiniEvasi.forEach(ordine => {
            if (ordine.data && ordine.timestampEvasione) {
                const timestampCreazione = parseInt(ordine.data);
                const timestampEvasione = parseInt(ordine.timestampEvasione);
                const differenzaSecondi = Math.floor((timestampEvasione - timestampCreazione) / 1000);

                if (differenzaSecondi > 0) {
                    totalSeconds += differenzaSecondi;
                    ordiniValidi++;
                }
            }
        });

        if (ordiniValidi > 0) {
            const mediaSecondi = Math.floor(totalSeconds / ordiniValidi);
            const minutes = Math.floor(mediaSecondi / 60);
            const seconds = mediaSecondi % 60;
            this.tempoMedioEvasione = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        }
    }

    getElapsedTime(): string {
        return this.tempoMedioEvasione;
    }

    getOrderTime(timestamp: string): string {
        if (!timestamp) return '0:00';

        const orderTime = new Date(parseInt(timestamp));
        const now = new Date();
        const diffSeconds = Math.floor((now.getTime() - orderTime.getTime()) / 1000);

        const minutes = Math.floor(diffSeconds / 60);
        const seconds = diffSeconds % 60;

        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    getCompletionPercentage(): number {
        const total = (this.ordiniCucina?.length || 0) + (this.ordiniEvasi?.length || 0);
        if (total === 0) return 0;
        return Math.round(((this.ordiniEvasi?.length || 0) / total) * 100);
    }

    getOrderTypeLabel(type: string): string {
        switch (type) {
            case 'counter': return 'Banco';
            case 'table': return 'Tavolo';
            case 'takeaway': return 'Asporto';
            default: return 'N/D';
        }
    }

    // Gestione degli ordini
    private async toggleSelectedItem(): Promise<void> {
        if (this.activeSection !== 'orders') return;

        const order = this.paginatedOrders[this.selectedOrderIndex];
        if (!order) return;

        const items = this.getFilteredItems(order);
        const productId = items[this.selectedItemIndex];
        if (!productId) return;

        if (this.dialog.openDialogs.length > 0) return;

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
            data: {
                title: order.carrello[productId].pronto ?
                    'Ripristinare questo piatto?' :
                    'Segnare come pronto?',
                confirmText: order.carrello[productId].pronto ?
                    'Ripristina' :
                    'Conferma',
                cancelText: 'Annulla',
                isRestore: order.carrello[productId].pronto
            },
            autoFocus: true,
            restoreFocus: true
        });

        dialogRef.afterClosed().subscribe(async result => {
            if (result) {
                order.carrello[productId].pronto = !order.carrello[productId].pronto;

                if (!order.carrello[productId].pronto && order.statoPagato === 3) {
                    order.statoPagato = 1; // Ripristina lo stato non evaso
                    order.timestampEvasione = null;
                    order.statoEvasione = 'non_evaso';
                } else {
                    const allReady = Object.values(order.carrello)
                        .every(product => product.pronto);

                    if (allReady) {
                        order.statoPagato = 3;
                        order.timestampEvasione = new Date().getTime().toString();
                        order.statoEvasione = 'completo';
                    } else {
                        const someReady = Object.values(order.carrello)
                            .some(product => product.pronto);
                        order.statoEvasione = someReady ? 'parziale' : 'non_evaso';
                    }
                }

                await this.commonService.updateOrdine(order.id, order);
            }
        });
    }

    togglePiattoPreparazione(ordine: Order, prodottoId: string, event: Event): void {
        event.stopPropagation();
        if (ordine.carrello?.[prodottoId]) {
            ordine.carrello[prodottoId].pronto = !ordine.carrello[prodottoId].pronto;
            this.commonService.updateOrdine(ordine.id, ordine);
        }
    }

    isPiattoReady(ordine: Order, prodottoId: string): boolean {
        return ordine.carrello?.[prodottoId]?.pronto || false;
    }

    // Utility methods for totals view
    getDishCategory(dish: TotalItem): string {
        return dish.category || 'Non categorizzato';
    }

    getDishDestination(dish: TotalItem): string {
        return dish.selectedPrintDestination || 'Non specificato';
    }

    hasVariants(dish: TotalItem): boolean {
        return dish.variants?.length > 0;
    }

    getVariantsText(dish: TotalItem): string {
        if (!dish.variants?.length) return '';
        return dish.variants.map(v => v.name).join(', ');
    }
}