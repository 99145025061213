import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import {CommonService} from "../../../shared/services/firestore/common.service";

@Component({
  selector: 'edit-sconti',
  templateUrl: './edit-sconti.html',
  styleUrls: ['./edit-sconti.css'],
})
export class EditSconti implements OnInit, OnDestroy {
  isMenuChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  title: string = '';
  punti: 0;
  sconto: '0€';
  menu: []

  id: string = '';
  selectedCategory: string[] = [];
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';

  // Variabili per i prezzi dei menu
  menuPiccolo: string = '';
  menuMedio: string = '';
  menuGrande: string = '';

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditSconti>,
    private dataService: DataServiceGenerico,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any  // Riceve i dati dal dialog
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  ngOnInit(): void {
    this.fetchDataFromFirestore();

    if (this.data) {
      // Popola i campi con i dati passati
      this.title = this.data.nome || '';
      this.punti = this.data.punti || 0;
      this.sconto = this.data.sconto || '0€';
      // Converte la stringa di ingredienti in un array di stringhe
      this.selectedIngredients = this.data.hasOwnProperty('prodotto')? this.data.prodotto: [];

      this.isMenuChecked = this.data.hasOwnProperty('prodotto')? true: false;
      this.isSingleChecked = this.data.riutilizzabile? this.data.riutilizzabile: false;
    }

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): any {
    this.passBack();
  }

  fetchDataFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        this.menu = items

        console.log("menu: ", this.menu)

        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }

  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage() {
    this.selectedImage = '';
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file);
    }
  }

  uploadImageToFirebase(file: File) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        // Optional: Monitor upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        // Handle successful uploads
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.selectedImage = downloadURL;
          this.cdRef.detectChanges();
        });
      }
    );
  }

  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        // Filtra l'elemento da eliminare
        const updatedItems = items.filter(item => item.id !== this.id);

        // Aggiorna Firestore con l'array di elementi aggiornato
        menuRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Elemento eliminato con successo da Firestore!');
            this.dialogRef.close();  // Chiudi il dialog dopo l'eliminazione
          })
          .catch((error) => {
            console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
          });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    const dataToPassBack = {

      nome: this.title || '',
      punti: this.punti || 0,
      sconto: this.sconto || '0€',
      // Converte la stringa di ingredienti in un array di stringhe
      riutilizzabile: this.isSingleChecked,
      prodotto: this.selectedIngredients,

    };
    this.passEntry.emit(dataToPassBack);
  }
}

