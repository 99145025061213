<div class="row">
    <div class="header-container">
        <div class="title">Gestione Workstation</div>
        <div class="save-button-container top-save">
            <button (click)="savePrinters()" [disabled]="isLoading || !isValid()">
                {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
            </button>
        </div>
    </div>

    <div *ngIf="workstations.length === 0 && !isLoading" class="empty-state">
        <p>Nessuna workstation trovata per questo ristorante</p>
    </div>

    <div *ngIf="isLoading" class="loading-state">
        <p>Caricamento...</p>
    </div>

    <div *ngFor="let workstation of workstations" class="card-container">
        <div style="padding: 20px;">
            <h3>{{workstation.name}}</h3>

            <!-- Sezione Stampanti -->
            <div class="section-title">Stampanti</div>
            <div class="printer-section" *ngFor="let printer of getWorkstationPrinters(workstation); let i = index">
                <div class="printer-header">
                    <div class="printer-inputs">
                        <div class="input-container">
                            <input
                                    [class.error]="isPrinterNameInvalid(workstation.id, i)"
                                    [(ngModel)]="printer.name"
                                    placeholder="Nome Stampante"
                                    [readonly]="printer.name === 'fiscale'">
                            <div class="error-message" *ngIf="isPrinterNameInvalid(workstation.id, i)">
                                Nome stampante obbligatorio
                            </div>
                        </div>

                        <div class="input-container">
                            <input
                                    [class.error]="isPrinterIpInvalid(workstation.id, i)"
                                    [(ngModel)]="printer.ipAddress"
                                    placeholder="Indirizzo IP">
                            <div class="error-message" *ngIf="isPrinterIpInvalid(workstation.id, i)">
                                Indirizzo IP non valido
                            </div>
                        </div>
                    </div>

                    <button
                            *ngIf="printer.name !== 'fiscale'"
                            class="remove-printer-button"
                            (click)="removePrinter(workstation, i)">
                        ×
                    </button>
                </div>
            </div>

            <button class="add-printer-button" (click)="addPrinter(workstation)" type="button">
                Aggiungi Stampante
            </button>

            <!-- Sezione Cassetto Automatico -->
            <div class="cashmatic-section">
                <div class="section-title">Cassetto Automatico</div>
                <div class="cashmatic-header">
                    <div class="switch-container">
                        <span class="switch-label">Abilita Cassetto Automatico</span>
                        <label class="switch">
                            <input type="checkbox" [(ngModel)]="workstation.cashmatic.enabled">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>

                <div class="cashmatic-settings" *ngIf="workstation.cashmatic?.enabled">
                    <!-- Selezione del tipo di cassetto automatico -->
                    <div class="input-container tipo-cassetto">
                        <label>Tipo di Cassetto</label>
                        <select
                                [(ngModel)]="workstation.cashmatic.tipo"
                                class="select-input">
                            <option value="cashmatic">Cashmatic</option>
                            <option value="cashlogy">Cashlogy</option>
                            <option value="glory">Glory</option>
                            <option value="vne">VNE</option>
                            <option value="altro">Altro</option>
                        </select>
                    </div>

                    <div class="cashmatic-inputs">
                        <div class="input-container">
                            <label>Indirizzo IP</label>
                            <input
                                    [(ngModel)]="workstation.cashmatic.ipAddress"
                                    placeholder="Indirizzo IP"
                                    [class.error]="isCashmaticIpInvalid(workstation.id)">
                            <div class="error-message" *ngIf="isCashmaticIpInvalid(workstation.id)">
                                Indirizzo IP non valido
                            </div>
                        </div>

                        <div class="input-container">
                            <label>Username</label>
                            <input
                                    [(ngModel)]="workstation.cashmatic.username"
                                    placeholder="Username"
                                    [class.error]="isCashmaticUsernameInvalid(workstation.id)">
                            <div class="error-message" *ngIf="isCashmaticUsernameInvalid(workstation.id)">
                                Username obbligatorio
                            </div>
                        </div>

                        <div class="input-container">
                            <label>Password</label>
                            <input
                                    [(ngModel)]="workstation.cashmatic.password"
                                    placeholder="Password"
                                    type="password"
                                    [class.error]="isCashmaticPasswordInvalid(workstation.id)">
                            <div class="error-message" *ngIf="isCashmaticPasswordInvalid(workstation.id)">
                                Password obbligatoria
                            </div>
                        </div>

                        <!-- Campo porta opzionale (visibile solo per alcuni tipi) -->
                        <div class="input-container" *ngIf="['cashmatic', 'glory', 'vne'].includes(workstation.cashmatic.tipo)">
                            <label>Porta</label>
                            <input
                                    [(ngModel)]="workstation.cashmatic.port"
                                    placeholder="Porta (es. 8080)"
                                    type="number">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>