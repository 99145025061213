import {
  Component,
  OnInit, ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CarrelloMagazzino} from "../carrelloMagazzino/carrelloMagazzino";



@Component({
  selector: 'cronologia',
  templateUrl: './cronologia.html',
  styleUrls: ['./cronologia.css'],
})
  export class Cronologia implements OnInit{

  findCarrelloMagazzinoConsegnato(selectedOrder: any, item: any)
  {
    selectedOrder.carrelloMagazzinoConsegnato?.find((p) => p.titolo === item.id)?.QuantitaConsegnata
  }

  getOrderStatus(): string {
    if (this.selectedOrder.stato === 'confermato') {
      return 'Confermato';
    } else if (this.selectedOrder.stato === 'confermato_parziale') {
      return 'Confermato Parzialmente';
    } else if (this.selectedOrder.scheduledTime !== null) {
      return 'In preparazione';
    } else {
      return 'In attesa di conferma';
    }
  }


  selectedOrder: any = null;
  ordiniMagazzino: any[] = [];
  isConfirmingPartial: boolean = false;
  partialQuantities: { [key: string]: number } = {};

  openOrderDetail(order: any) {
    this.selectedOrder = order;
    this.isConfirmingPartial = false;
    this.resetPartialQuantities();
  }

  resetPartialQuantities() {
    this.partialQuantities = {};
    if (this.selectedOrder?.carrelloMagazzinoConsegnato) {
      this.selectedOrder.carrelloMagazzinoConsegnato.forEach((item: any) => {
        this.partialQuantities[item.Titolo] = parseInt(item.QuantitaConsegnata);
      });
    }
    else {
      if (this.selectedOrder?.carrelloMagazzino) {
        this.selectedOrder.carrelloMagazzino.forEach((item: any) => {
          this.partialQuantities[item.Titolo] = parseInt(item.Quantita);
        });
      }
    }
  }

  startPartialConfirmation() {
    this.isConfirmingPartial = true;
    this.resetPartialQuantities();
  }

  cancelPartialConfirmation() {
    this.isConfirmingPartial = false;
    this.resetPartialQuantities();
  }

  async confirmFullOrder() {
    if (!this.selectedOrder) return;

    try {
      const db = firebase.firestore();
      await db.collection('ordini_magazzino_test').doc(this.selectedOrder.idOrdine).update({
        stato: 'confermato',
        dataConferma: firebase.firestore.FieldValue.serverTimestamp()
      });

      // Aggiorna l'ordine locale
      this.selectedOrder.stato = 'confermato';
      this.fetchOrdini(); // Ricarica la lista ordini
    } catch (error) {
      console.error('Errore durante la conferma dell\'ordine:', error);
    }
  }

  async confirmPartialOrder() {
    if (!this.selectedOrder) return;

    try {
      const partialOrder = this.selectedOrder.carrelloMagazzino.map((item: any) => ({
        ...item,
        QuantitaConsegnata: this.partialQuantities[item.Titolo],
        QuantitaOriginale: item.Quantita
      }));

      console.log("partial order: ", partialOrder)
      const db = firebase.firestore();
      await db.collection('ordini_magazzino_test').doc(this.selectedOrder.idOrdine).update({
        stato: 'confermato_parziale',
        dataConferma: firebase.firestore.FieldValue.serverTimestamp(),
        carrelloMagazzinoConsegnato: partialOrder
      });

      this.isConfirmingPartial = false;
      this.fetchOrdini(); // Ricarica la lista ordini
    } catch (error) {
      console.error('Errore durante la conferma parziale dell\'ordine:', error);
    }
  }

  calcolaTotaleOrdine(ordine: any): string {
    if (!ordine || !ordine.carrelloMagazzino) return "0.00";

    const totale = ordine.carrelloMagazzino.reduce((acc: number, item: any) => {
      const prezzo = parseFloat(item.Prezzo) || 0;
      const quantita = parseInt(item.Quantita) || 0;
      return acc + (prezzo);
    }, 0);

    return totale.toFixed(2);
  }


  getProperties(object: any) {
    if (!object) return [];
    return Object.keys(object);
  }


  sezioneNovita = 0;

  dataSourceDipendenti2= new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  displayedColumnsDipendenti2: string[] = ['nome', 'email', 'Action', 'Elimina'];


  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };


  display = 'none';

  closeResult = '';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';

  scontrini = [


    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import cvs',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import json',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import xml',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    }
  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'menu', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    public commonService: CommonService, public dataService: DataServiceGenerico
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }



  openDialogRiepilogo(idOrdine): any {
    const dialogRef = this.dialog.open(CarrelloMagazzino, {
      data: { orderId: idOrdine }, // Passa i dati della riga al dialog
      width: '80vw',
      maxWidth: '80vw'
    });
    //modalRef.componentInstance.user = this.user;

  }




// Funzione per recuperare la lista degli ordini
  fetchOrdini(): void {
    const db = firebase.firestore();
    const ordiniRef = db.collection('ordini_magazzino_test');

    if (this.dataService.getRistorante1() == 'demo_1') {
      this.dataService.loadSelectedMenuFromFirebase().then(
          (menu) =>
              ordiniRef.where('ristorante', '==', this.dataService.getRistorante1())
                  .orderBy('data', 'desc')  // Ordina per data in ordine decrescente
                  .get()
                  .then((querySnapshot) => {
                    this.ordiniMagazzino = [];
                    querySnapshot.forEach((doc) => {
                      const ordine = doc.data();
                      this.ordiniMagazzino.push(ordine);
                    });
                    console.log('Cronologia recuperati:', this.ordiniMagazzino);
                  })
                  .catch((error) => {
                    console.error('Errore nel recupero degli ordini:', error);
                  }))
    }
    else
    {
      ordiniRef.where('ristorante', '==', this.dataService.getRistorante1())
          .orderBy('data', 'desc')  // Ordina per data in ordine decrescente
          .get()
          .then((querySnapshot) => {
            this.ordiniMagazzino = [];
            querySnapshot.forEach((doc) => {
              const ordine = doc.data();
              this.ordiniMagazzino.push(ordine);
            });
            console.log('Cronologia recuperati:', this.ordiniMagazzino);
          })
          .catch((error) => {
            console.error('Errore nel recupero degli ordini:', error);
          })
    }
  }

// Funzione per calcolare il totale di un ordine specifico
  calculateOrderTotal(idOrdine: string): number {
    const ordine = this.ordiniMagazzino.find(o => o.idOrdine === idOrdine);
    if (!ordine) {
      console.error('Ordine non trovato');
      return 0;
    }

    return ordine.carrelloMagazzino.reduce((total, item) => {
      const price = parseFloat(item.Prezzo) || 0;
      return total + price;
    }, 0);
  }



  openDialog(): any {
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  ngOnInit(): void {

    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });
  }

  ordini = [];

  receipts = [];

  idRistorante = '';

  camerieri = [];

  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;
          this.receipts = data.data().scontrini;

          console.log(this.carrelloTmp);

          this.calcolaTotale();
        }
      }
    );

    this.fetchOrdini();

  }


  filtroSource = 3;
  filtroStato = 2;


  filtraApp()
  {
    this.filtroSource = 0;
    this.retriveCarrello()
    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 0);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);
  }
  filtraChiosco()
  {
    this.filtroSource = 1;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 1);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }
  filtraCassa()
  {
    this.filtroSource = 2;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 2);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }

  filtraTutto()
  {
    this.filtroSource = 3;
    this.retriveCarrello()

    //this.ordini = this.filtroStato == 2? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.statoPagato === this.filtroStato);
  }

  filtraPagato() {

    this.filtroStato = 0;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 0);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraNonPagato()
  {
    this.filtroStato = 1;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraCompletato()
  {
    this.filtroStato = 3;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraStatoTutto()
  {
    this.filtroStato = 2;
    this.retriveCarrello()

    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
  }


  filter = '';


  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  calcolaMancia(): number {


    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }


  protected readonly parseFloat = parseFloat;
  protected readonly parseInt = parseInt;
}
