import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {DataServiceGenerico} from "../../../../shared/services/data-service/data.service";

@Component({
    selector: 'app-add-employee-dialog',
    template: `
        <div class="dialog-container">
            <div class="sticky-container">
                <div class="save-button">
                    <img src="assets/icons/save-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Salva Dipendente"
                         (click)="onSubmit()">
                </div>
                <div class="close-button">
                    <img src="assets/icons/close-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Chiudi"
                         (click)="onCancel()">
                </div>
            </div>

            <div style="height: 80vh; max-width: 500px;">
                <div class="example-container">
                    <form [formGroup]="employeeForm">
                        <fieldset class="custom-fieldset">
                            <legend>Nome</legend>
                            <div class="chart-container">
                                <input placeholder="Nome"
                                       class="custom-input"
                                       formControlName="nome">
                            </div>
                        </fieldset>

                        <fieldset class="custom-fieldset">
                            <legend>Cognome</legend>
                            <div class="chart-container">
                                <input placeholder="Cognome"
                                       class="custom-input"
                                       formControlName="cognome">
                            </div>
                        </fieldset>

                        <fieldset class="custom-fieldset">
                            <legend>Email</legend>
                            <div class="chart-container">
                                <input placeholder="Email"
                                       class="custom-input"
                                       formControlName="email"
                                       type="email">
                            </div>
                        </fieldset>

                        <fieldset class="custom-fieldset">
                            <legend>Password</legend>
                            <div class="chart-container">
                                <input placeholder="Password"
                                       class="custom-input"
                                       formControlName="password"
                                       type="password">
                            </div>
                        </fieldset>

                        <fieldset class="custom-fieldset">
                            <legend>Ruolo</legend>
                            <div class="chart-container">
                                <mat-select placeholder="Seleziona ruolo"
                                            class="custom-input"
                                            formControlName="ruolo">
                                    <mat-option *ngFor="let ruolo of ruoli"
                                                [value]="ruolo.nome">
                                        {{ruolo.nome}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .dialog-container {
            position: relative;
        }

        .sticky-container {
            position: sticky;
            top: 0px;
            right: 0px;
            z-index: 1000;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            cursor: pointer;
        }

        .close-button {
            width: 30px;
            aspect-ratio: 1 / 1;
            margin-top: -15px;
            margin-right: -15px;
        }

        .save-button {
            width: 30px;
            margin-top: -15px;
        }

        .custom-fieldset {
            margin-bottom: 10px;
            border-radius: 20px;
            height: auto;
            border: 2px solid rgba(222,214,214,0.57);
            display: flex;
            flex-direction: column;
            transition: border-color 0.3s ease;
        }

        .custom-fieldset legend {
            font-weight: 600;
            font-size: 16px;
        }

        .custom-input {
            border: none;
            outline: none;
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            transition: all 0.3s ease;
        }

        .custom-fieldset:focus-within {
            border-color: #000;
        }

        .custom-input:focus {
            border: none;
            outline: none;
        }
    `]
})
export class AddEmployeeDialogComponent {
    employeeForm: FormGroup;
    ruoli: any[] = [];

    constructor(
        private dialogRef: MatDialogRef<AddEmployeeDialogComponent>,
        private fb: FormBuilder,
        private dataService: DataServiceGenerico
    ) {
        this.employeeForm = this.fb.group({
            nome: ['', [Validators.required, Validators.minLength(2)]],
            cognome: ['', [Validators.required, Validators.minLength(2)]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            ruolo: ['', Validators.required]
        });
    }

    setRuoli(ruoli: any[]): void {
        this.ruoli = ruoli;
    }

    onSubmit(): void {
        if (this.employeeForm.valid) {
            const formData = {
                ...this.employeeForm.value,
                ristorante: this.dataService.getRistorante1()
            };
            this.dialogRef.close(formData);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}