import {Component, Input, OnInit} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 *
 * card per il costo finale e del cameriere
 */
@Component({
  selector: 'dettaglioScontrinoFinaleCard',
  styles: [`
        /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      margin-top: 5px;
    }

    h4{
      margin-bottom: 0px;
    }

    .container {
      text-align: center;
      width: 90%;
      max-width: 100%;
      margin-left: 5%;
    }

    .block {
      width: 100%;
      height: 70px;
      margin: 0px;
      margin-bottom: 1px;
      display: inline-flex;
      background: white;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: #7E7F80;

      border-radius: inherit;
      border: solid 1px #e3e2e2;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;

      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */


    }



  `],
  template: `

    <div class="block" (click)="clicco()">
      <div class="container" style="text-align: left">
        <h4 *ngIf="this.percent !== undefined" style="font-weight: lighter; margin-top: 0%;"><p style="color: #E95237; font-weight: bold; font-size: 16px">{{this.percent}}</p></h4>
        <h4 style="font-weight: lighter; margin-top: -5px; color: grey; font-size: 10px;"><p style="font-weight: bold; font-size: 16px">{{this.nome}}</p></h4>
      </div>
      <p style="text-align: right; width:20%;  display: inline-block; background-color: white; border: none; color: #7E7F80; font-size: 15px; padding-right: 0px; margin-right: 20px; font-weight: lighter">{{this.costo}} €</p>
    </div>
  `
})
export class DettaglioScontrinoFinaleCard implements OnInit{

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() quantita;
  @Input() percentuale;

  public percent = '';

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {

  }


  clicco(): void
  {
    console.log('porcodio');
  }
  /**
   * Simulate logout
   */

  ngOnInit(): void {
    if (this.percentuale !== undefined)
    {
      this.percent = this.percentuale + '%';
      console.log(this.percentuale + ' p0efirjoefrhnefqrw oi ' + this.percent);
    }
  }

}
