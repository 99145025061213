import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';

interface PriceList {
    id: string;
    name: string;
    settings: {
        counter: { vatId: string },
        table: { vatId: string },
        takeaway: { vatId: string }
    }
}

@Component({
    selector: 'app-price-lists',
    templateUrl: './price-lists.component.html',
    styleUrls: ['./price-lists.component.css']
})
export class PriceListsComponent implements OnInit {
    priceLists: PriceList[] = [];
    editingList: PriceList | null = null;
    isDirty = false;

    constructor(
        private dataService: DataServiceGenerico,
        private cdRef: ChangeDetectorRef,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.loadPriceLists();
    }

    loadPriceLists() {
        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        priceListsRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                this.priceLists = data?.priceLists || [];
                this.cdRef.detectChanges();
            }
        }).catch(error => {
            console.error('Errore nel caricamento dei listini:', error);
            this.snackBar.open('Errore nel caricamento dei listini', 'Chiudi', {
                duration: 3000
            });
        });
    }

    startEditing(list: PriceList | null = null) {
        if (list) {
            this.editingList = { ...list };
        } else {
            this.editingList = {
                id: firebase.firestore().collection('temporary').doc().id,
                name: '',
                settings: {
                    counter: { vatId: '1' },
                    table: { vatId: '1' },
                    takeaway: { vatId: '1' }
                }
            };
        }
        this.isDirty = false;
        this.cdRef.detectChanges();
    }

    async savePriceList() {
        if (!this.editingList?.name) {
            this.snackBar.open('Inserisci un nome per il listino', 'Chiudi', {
                duration: 3000
            });
            return;
        }

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            let updatedLists: PriceList[] = [];

            if (doc.exists) {
                const existingLists = doc.data()?.priceLists || [];
                if (existingLists.some(l => l.name.toLowerCase() === this.editingList.name.toLowerCase()
                    && l.id !== this.editingList.id)) {
                    this.snackBar.open('Esiste già un listino con questo nome', 'Chiudi', {
                        duration: 3000
                    });
                    return;
                }

                updatedLists = existingLists.map(list =>
                    list.id === this.editingList.id ? this.editingList : list
                );
                if (!existingLists.find(l => l.id === this.editingList.id)) {
                    updatedLists.push(this.editingList);
                }
            } else {
                updatedLists = [this.editingList];
            }

            await priceListsRef.set({ priceLists: updatedLists });
            this.priceLists = updatedLists;
            this.editingList = null;
            this.isDirty = false;

            this.snackBar.open('Listino salvato con successo!', 'Chiudi', {
                duration: 2000
            });

            this.cdRef.detectChanges();
        } catch (error) {
            console.error('Errore durante il salvataggio del listino:', error);
            this.snackBar.open('Errore durante il salvataggio', 'Chiudi', {
                duration: 3000
            });
        }
    }

    async deletePriceList(listId: string) {
        if (!confirm('Sei sicuro di voler eliminare questo listino?')) {
            return;
        }

        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = db.collection('price_lists').doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            if (!doc.exists) return;

            const existingLists = doc.data()?.priceLists || [];
            const updatedLists = existingLists.filter(list => list.id !== listId);

            await priceListsRef.set({ priceLists: updatedLists });

            this.priceLists = updatedLists;
            if (this.editingList?.id === listId) {
                this.editingList = null;
            }

            this.snackBar.open('Listino eliminato con successo!', 'Chiudi', {
                duration: 2000
            });

            this.cdRef.detectChanges();
        } catch (error) {
            console.error('Errore durante l\'eliminazione del listino:', error);
            this.snackBar.open('Errore durante l\'eliminazione', 'Chiudi', {
                duration: 3000
            });
        }
    }

    cancelEdit() {
        if (this.isDirty) {
            if (confirm('Ci sono modifiche non salvate. Vuoi davvero annullare?')) {
                this.editingList = null;
                this.isDirty = false;
            }
        } else {
            this.editingList = null;
        }
        this.cdRef.detectChanges();
    }

    onFieldChange() {
        this.isDirty = true;
    }
}