import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../../menu/menu";
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {EditMenu} from "../../edit-menu/edit-menu";
import {EditSconti} from "../edit-sconti/edit-sconti";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";


@Component({
  selector: 'fidelity',
  templateUrl: './impostazioniFidelity.html',
  styleUrls: ['./impostazioniFidelity.css']
})
  export class ImpostazioniFidelity implements OnInit{


  displayedColumns: string[] = ['nome', 'punti', 'requisito', 'livello'];
  displayedColumnsSconti: string[] = ['nome', 'punti', 'sconto', 'edit'];

  puntiEuro = 1
  scontoAccount = '10%'



  moltiplicatori = [
    {
      nome: 'ciaociao',
      punti: 999,
      requisito: '1€',
      livello: '1'
    },
    {
      nome: 'ciaociao',
      punti: 999,
      requisito: '1€',
      livello: '1'
    },
    {
      nome: 'ciaociao',
      punti: 999,
      requisito: '1€',
      livello: '1'
    },
  ]


  sconti = [
    {
      nome: 'Sconto',
      punti: 10,
      sconto: '1€',
      riutilizzabile: true,
    },
    {
      nome: 'Cannolo Siciliano Classico',
      punti: 150,
      prodotto: [
          'Cannolo Siciliano Classico'
      ],
      riutilizzabile: false,

    },
    {
      nome: 'Caffè Espresso',
      punti: 100,
      prodotto: [
          'Caffè Espresso'
      ],
      riutilizzabile: false,

    }
  ]


  openDialog(row: any): void {
    const dialogRef = this.dialog.open(EditSconti, {
      data: row // Passa i dati della riga al dialog
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Gestisci il risultato della chiusura del dialog qui
        console.log('Dialog chiuso, risultato:', result);
      }
    });
  }



  dragPosition = {x: 253, y: 244};

  dragPosition2 = {x: 253, y: 244};


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private ipcService: IpcService, private posTransactionService: PosTransactionService,
    private dataServiceGenerico: DataServiceGenerico

  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }

  dropped(e)
  {
    console.log("droppato: ", e)
    console.log("droppato 2: ", this.dragPosition)

  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()

  }




}
