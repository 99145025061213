import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'indietroCard',
  styles: [`


    .block {
      width: 40px;
      margin: 0px;
      margin-bottom: 3px;
      display: inline-flex;
      background: white;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 600;
      color: black;

      border-radius: 15px;
      border: solid 1px #e3e2e2;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      box-shadow: 0px 0px 6px RGBA(181, 181, 181, 0.1);
      -webkit-box-shadow: 0px 4px 4px RGBA(220, 220, 220, 0.5);
      -o-box-shadow: 0px 1px 6px RGBA(181, 181, 181, 0.3);

       */


    }

    #store-locator {
      color: black;
    }

    .store {
      position: relative;
      background-size: 10% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
      background-color: white;
    }



  `],
  template: `

    <div id="store-locator" class="block" [ngStyle]="{
  'border': border !== undefined ? 'solid 1px ' + border : null,
  'height': altezza + 'px'
}">
    <img class="store" [ngStyle]="" style="background-size: 5% auto; transform: scale(0.7)" src="{{this.link}}" onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';"  >
      <div style="padding-left: 30px; max-width: 80%"  [ngStyle]="{'color': border !== undefined ? border : null}">{{this.nome}}</div>
    </div>
  `
})
export class IndietroCardComponent {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() stato ;
  @Input() border ;
  @Input() altezza ;




  constructor(
    // public authService: AuthService,
    private router: Router
  ) {
    if(!this.altezza){
      this.altezza = '40'
    }
  }


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
