import { Injectable } from '@angular/core';
import { ipcRenderer } from 'electron';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentCashmaticService {
  private ipc: typeof ipcRenderer;
  private soldiInseritiSubject = new BehaviorSubject<number>(0);
  private paymentSuccessSubject = new Subject<any>();
  private paymentErrorSubject = new Subject<any>();

  soldiInseriti$ = this.soldiInseritiSubject.asObservable();
  paymentSuccess$ = this.paymentSuccessSubject.asObservable();
  paymentError$ = this.paymentErrorSubject.asObservable();

  constructor() {
    if ((<any>window).require) {
      try {
        this.ipc = (<any>window).require('electron').ipcRenderer;
        this.listenForUpdates();
      } catch (e) {
        throw e;
      }
    } else {
      console.warn('Electron\'s IPC was not loaded');
    }
  }


  testPay(totale: number, datiScontrino: any) {
    this.ipc.send('test-pay', totale, datiScontrino);
    this.ipc.on('payment-complete', (event, data) => {
      this.paymentSuccessSubject.next(data);
      console.log('Payment Response:', data);
    });
    this.ipc.on('payment-error', (event, error) => {
      this.paymentErrorSubject.next(error);
      console.error('Payment Error:', error);
    });
  }

  annullaPagamento() {
    if(this.ipc !== undefined)
    {
      this.ipc.send('annulla-pagamento', {});
      this.ipc.on('cancel-response', (event, data) => {
        console.log('Cancel Response:', data);
      });
      this.ipc.on('cancel-error', (event, error) => {
        console.error('Cancel Error:', error);
      });
    }
  }

  getTransaction() {
    this.ipc.send('get-transaction', {});
    this.ipc.on('transaction-response', (event, data) => {
      console.log('Transaction Response:', data);
    });
    this.ipc.on('transaction-error', (event, error) => {
      this.paymentErrorSubject.next(error);
      console.error('Transaction Error:', error);
    });
    this.ipc.on('payment-complete', (event, data) => {
      this.paymentSuccessSubject.next(data);
      console.log('Payment Complete:', data);
    });
  }

  private listenForUpdates() {
    this.ipc.on('update-soldi-inseriti', (event, soldiInseriti) => {
      this.soldiInseritiSubject.next(soldiInseriti);
      console.log('Soldi Inseriti Updated:', soldiInseriti);
    });
  }
}
