// slider-images.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import {NotificationService} from "../stampanti/notificationService/notification.service";

interface SliderImage {
  url: string;
  name: string;
}

@Component({
  selector: 'slider-totem',
  templateUrl: './slider-totem.html',
  styleUrls: ['./slider-totem.css'],
})
export class SliderTotem implements OnInit {
  sliderForm: FormGroup;
  isLoading = false;
  uploadProgress: { [key: number]: number } = {};

  constructor(
      private router: Router,
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService,
      private fb: FormBuilder
  ) {
    this.sliderForm = this.fb.group({
      images: this.fb.array([])
    });
  }

  get images() {
    return this.sliderForm.get('images') as FormArray;
  }

  addImage() {
    const imageGroup = this.fb.group({
      url: [''],
      name: ['', Validators.required],
      file: [null]
    });
    this.images.push(imageGroup);
  }

  removeImage(index: number) {
    this.images.removeAt(index);
  }

  async ngOnInit() {
    if (this.dataServiceGenerico.getRistorante1() === 'demo_1') {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();
      await this.loadImages();
    } else {
      await this.loadImages();
    }
  }

  async loadImages() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.sliderTotem) {
        const images = data.sliderTotem;
        images.forEach(image => {
          this.images.push(
              this.fb.group({
                url: [image.url],
                name: [image.name, Validators.required],
                file: [null]
              })
          );
        });
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }
  }

  async onFileSelected(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      const imageGroup = this.images.at(index);
      imageGroup.patchValue({
        file: file,
        name: file.name,
        url: ''  // Pulisci l'URL quando viene selezionato un nuovo file

      });

      // Aggiorna la preview immediatamente
      imageGroup.markAsDirty();
    }
  }


  async uploadImage(file: File, index: number): Promise<string> {
    const restaurantId = this.dataServiceGenerico.getRistorante1();
    if (!restaurantId || !file) throw new Error('Missing restaurant ID or file');

    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(`restaurants/${restaurantId}/slider/${file.name}`);

    const uploadTask = imageRef.put(file);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadProgress[index] = progress;
          },
          (error) => reject(error),
          async () => {
            const downloadUrl = await imageRef.getDownloadURL();
            resolve(downloadUrl);
          }
      );
    });
  }

  async saveImages() {
    if (!this.isFormValid()) {
      this.notificationService.show('Compila tutti i campi richiesti', 'error');
      return;
    }

    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const uploadedImages: SliderImage[] = [];

      for (let i = 0; i < this.images.length; i++) {
        const control = this.images.at(i);
        const file = control.get('file')?.value;
        const name = control.get('name')?.value;
        const existingUrl = control.get('url')?.value;

        let imageUrl = existingUrl;

        if (file instanceof File) {
          try {
            imageUrl = await this.uploadImage(file, i);
          } catch (error) {
            console.error(`Error uploading image ${name}:`, error);
            throw error;
          }
        }

        if (!imageUrl) {
          throw new Error(`Missing URL for image ${name}`);
        }

        uploadedImages.push({
          url: imageUrl,
          name: name
        });
      }

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        sliderTotem: uploadedImages
      });

      uploadedImages.forEach((image, index) => {
        this.images.at(index).patchValue({
          url: image.url,
          file: null
        });
      });

      this.notificationService.show('Immagini salvate con successo', 'success');
    } catch (error) {
      console.error('Error saving images:', error);
      this.notificationService.show('Errore durante il salvataggio delle immagini', 'error');
    } finally {
      this.isLoading = false;
      this.uploadProgress = {};
    }
  }


  getErrorMessage(controlName: string, index: number): string {
    const control = this.images.at(index).get(controlName);
    if (control?.hasError('required')) {
      return 'Questo campo è obbligatorio';
    }
    return '';
  }


  isFormValid(): boolean {
    if (this.images.length === 0) return false;

    return this.images.controls.every(control => {
      const name = control.get('name')?.value;
      const url = control.get('url')?.value;
      const file = control.get('file')?.value;

      return name && (url || file);
    });
  }
}