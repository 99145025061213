<div class="kitchen-monitor" [class.with-history]="showHistory" [class.with-totals]="showTotals">
    <!-- Header -->
    <div class="monitor-header">
        <div class="header-stats">
            <div class="stat-item">
                <mat-icon>schedule</mat-icon>
                <span>{{ currentTime | date:'HH:mm' }}</span>
            </div>
            <div class="stat-item">
                <mat-icon>trending_up</mat-icon>
                <span>Completamento {{ getCompletionPercentage() }}%</span>
            </div>
            <div class="stat-item">
                <mat-icon>timer</mat-icon>
                <span>Tempo Medio {{ getElapsedTime() }}</span>
            </div>
        </div>

        <div class="header-actions">
            <button mat-button (click)="toggleFilters()" class="action-button" [class.active]="showFilters">
                <mat-icon>filter_list</mat-icon>
                {{ showFilters ? 'Nascondi Filtri' : 'Mostra Filtri' }}
            </button>
            <button mat-button (click)="toggleEvasiView()" class="action-button" [class.active]="showEvasiOnly">
                <mat-icon>history</mat-icon>
                {{ showEvasiOnly ? 'Mostra Attivi' : 'Mostra Evasi' }}
            </button>
            <button mat-button (click)="toggleTotals()" class="action-button" [class.active]="showTotals">
                <mat-icon>format_list_bulleted</mat-icon>
                Totale Piatti
            </button>
        </div>
    </div>

    <!-- Filters Panel -->
    <div *ngIf="showFilters" class="filters-panel" [@slideInOut]>
        <div class="filters-grid">
            <!-- Print Destinations Filter -->
            <div class="filter-section">
                <h3>Destinazione Stampa</h3>
                <div class="filter-options">
                    <div *ngFor="let dest of printDestinations; let i = index"
                         [class.active]="selectedDestinations.includes(dest)"
                         [class.selected]="selectedDestinationIndex === i"
                         class="filter-option destination"
                         (click)="toggleDestination(dest)">
                        {{ dest }}
                    </div>
                </div>
            </div>

            <!-- Categories Filter -->
            <div class="filter-section">
                <h3>Categorie</h3>
                <div class="filter-options">
                    <div *ngFor="let cat of categories; let i = index"
                         [class.active]="selectedCategories.includes(cat)"
                         [class.selected]="selectedCategoryIndex === i"
                         class="filter-option category"
                         (click)="toggleCategory(cat)">
                        {{ cat }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Main Content -->
    <div class="monitor-content">
        <!-- Orders Grid -->
        <div class="orders-grid">
            <div *ngFor="let order of filteredOrders; let orderIndex = index"
                 class="order-card"
                 [class.selected-order]="orderIndex === selectedOrderIndex"
                 [class.completed]="order.statoPagato === 3"
                 [class.partial]="order.statoEvasione === 'parziale'">

                <!-- Order Header -->
                <div class="order-header" [ngClass]="order.selectedPriceListType">
                    <div class="order-info">
                        <span class="order-number">Ordine #{{order.ordine}}</span>
                        <span class="order-type">
                            {{getOrderTypeLabel(order.selectedPriceListType)}}
                            <span *ngIf="order.tavolo">- Tavolo {{order.tavolo}}</span>
                            <span *ngIf="order.pager && order.pager !== 0" class="pager-badge">
                                Pager: {{order.pager}}
                            </span>
                        </span>
                    </div>
                    <div class="order-time">
                        {{getOrderTime(order.data)}}
                    </div>
                </div>

                <!-- Order Items -->
                <div class="products-list">
                    <div *ngFor="let productId of getFilteredItems(order); let itemIndex = index"
                         class="product-item"
                         [class.selected-item]="orderIndex === selectedOrderIndex && itemIndex === selectedItemIndex"
                         [class.completed]="order.carrello[productId]?.pronto">
                        <div class="product-status" (click)="togglePiattoPreparazione(order, productId, $event)">
                            <mat-icon>{{isPiattoReady(order, productId) ? 'check_circle' : 'radio_button_unchecked'}}</mat-icon>
                        </div>
                        <span class="product-quantity">{{order.carrello[productId]?.quantita}}×</span>
                        <div class="product-details">
                            <div class="product-name">{{order.carrello[productId]?.title}}</div>
                            <div *ngIf="order.carrello[productId]?.variants?.length" class="variants-list">
                                <span *ngFor="let variant of order.carrello[productId]?.variants" class="variant-item">
                                    → {{variant.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Order Notes -->
                <div *ngIf="order.nota" class="order-notes">
                    <mat-icon>notes</mat-icon>
                    <span>{{order.nota}}</span>
                </div>
            </div>
        </div>

        <!-- Total Dishes Panel -->
        <div class="total-dishes-panel" [class.visible]="showTotals">
            <div class="panel-header">
                <h2>Totale Piatti</h2>
                <button class="close-btn" (click)="toggleTotals()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="panel-content">
                <div *ngFor="let item of getFilteredTotalItems(); let i = index"
                     class="total-item"
                     [class.selected]="selectedTotalIndex === i">
                    <div class="total-item-header">
                        <span class="total-quantity">{{item.quantity}}×</span>
                        <span class="total-title">{{item.title}}</span>
                    </div>
                    <div class="total-item-details">
                        <div class="detail-row">
                            <mat-icon>category</mat-icon>
                            <span>{{getDishCategory(item)}}</span>
                        </div>
                        <div class="detail-row">
                            <mat-icon>print</mat-icon>
                            <span>{{getDishDestination(item)}}</span>
                        </div>
                        <div class="detail-row" *ngIf="hasVariants(item)">
                            <mat-icon>list</mat-icon>
                            <span>{{getVariantsText(item)}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Keyboard Shortcuts -->
    <div class="keyboard-shortcuts">
        <div class="shortcut-item">
            <span class="key">↑↓</span>
            <span class="description">Naviga Piatti</span>
        </div>
        <div class="shortcut-item">
            <span class="key">←→</span>
            <span class="description">Cambia Ordine</span>
        </div>
        <div class="shortcut-item">
            <span class="key">Enter</span>
            <span class="description">Segna Pronto</span>
        </div>
        <div class="shortcut-item">
            <span class="key">E</span>
            <span class="description">Mostra/Nascondi Evasi</span>
        </div>
        <div class="shortcut-item">
            <span class="key">Esc</span>
            <span class="description">Totali</span>
        </div>
        <div class="shortcut-item">
            <span class="key">Spazio</span>
            <span class="description">Filtri</span>
        </div>
    </div>
</div>