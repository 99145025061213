import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {IpcService} from "../../shared/services/ipc.service";

@Component({
  selector: 'navbar',
  styles: [`
    .menuItem{
      cursor: pointer !important;
    }

    .menuItem:hover {
      background-color: rgba(181,181,181,0.2) !important;
    }

    .sidebar {
      margin: 0;
      padding: 0;
      margin-top: 1%;
      margin-left: 1%;
      width: 220px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      height: 96%;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
    }

    .closed{
      width: 70px !important;
    }

    /* Sidebar links */
    .sidebar a {
      display: block;
      color: black;
      padding: 16px;
      text-decoration: none;
    }

    .sidebar b {
      display: block;
      color: #E65934;
      text-decoration: none;
      width: 220px;
      text-align: center;
      padding-top: 15%;
      padding-bottom: 30%;
      font-size: 30px;
    }

    /* Active/current link */
    .sidebar a.active {
      background-color: #FBFBFB;
      color: white;
    }

    /* Links on mouse-over */
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white;
    }

    /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
    div.content {
      margin-left: 200px;
      padding: 1px 16px;
      height: 1000px;
    }

    .bottom-elements {
      position: absolute;
      bottom: 0;
      margin-bottom: 7.5%;
      display: flex;
      width: 100%;
    }

    .block {
      width: calc(100% - 20px);
      height: 65px;
      margin: 2px 10px;
      display: inline-flex;
      border-radius: 20px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 580;
      color: #969696;
    }

    .cardSVG{
      margin-left: 10%;
      margin-bottom: 5px;
    }

    .selected {
      font-weight: 600;
      background-color: rgba(181,181,181,0.2) !important;
      color: black;
    }

    .imgSelected{
      filter: brightness(0%);
    }

    .notSelected {
      color: #969696;
      fill: #969696;
    }

    .restaurant-select {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 8px;
      border-radius: 10px;
      border: 1px solid #e3e2e2;
      background-color: white;
      color: #969696;
      font-size: 14px;
      cursor: pointer;
      outline: none;
    }

    .restaurant-select:hover {
      border-color: #b5b5b5;
    }

    .restaurant-select:focus {
      border-color: #E65934;
      color: black;
    }

    .restaurant-container {
      margin-bottom: 20px;
    }

    /* On screens that are less than 700px wide, make the sidebar into a topbar */
    @media screen and (max-width: 700px) {
      .sidebar {
        width: 100%;
        height: auto;
        position: relative;
      }
      .sidebar a {float: left;}
      .sidebar b {
        float: left;
        width: 100%;
      }
      div.content {margin-left: 0;}
    }

    .reversed-a {
      display: inline-block;
      transform: rotate(180deg);
    }

    /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
    @media screen and (max-width: 400px) {
      .sidebar a {
        text-align: center;
        float: none;
      }
    }
  `],
  template: `
    <div class="sidebar shadow-sm" scroll="no" [ngClass]="{'closed': aperto === false}">
      <b>FILL<span class="reversed-a">A</span>PP</b>

      <div class="restaurant-container">
        <select
            class="restaurant-select"
            [(ngModel)]="selectedRestaurant"
            (change)="onRestaurantChange()"
            [title]="'Seleziona ristorante'">
          <option
              *ngFor="let restaurant of availableRestaurants"
              [value]="restaurant.id">
            {{restaurant.nome}}
          </option>
        </select>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 8, 'block': true}" (click)="cambiaRoute('dashboard')">
        <img [ngClass]="{'imgSelected': this.posizione === 8}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-dashboard-100.png?alt=media&token=487b051a-cbb5-4609-b0ba-b3c971ed6951" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Dashboard</div>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 1, 'block': true}" (click)="cambiaRoute('magazzino')">
        <img [ngClass]="{'imgSelected': this.posizione === 1}" src="assets/images/icons8-product-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Magazzino</div>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 9, 'block': true}" (click)="cambiaRoute('ordini-magazzino')">
        <img [ngClass]="{'imgSelected': this.posizione === 9}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-order-96.png?alt=media&token=55a69c2e-a05d-4115-bd85-fa4454094c11" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Ordini </div>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 3, 'block': true}" (click)="cambiaRoute('menu')">
        <img [ngClass]="{'imgSelected': this.posizione === 3}" src="assets/images/icons8-menu-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Menu</div>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 4, 'block': true}" (click)="navigateToCassa()" >
        <img [ngClass]="{'imgSelected': this.posizione === 4}" src="assets/images/icons8-cash-register-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Cassa</div>
      </div>

      <div class="menuItem" [ngClass]="{'selected': this.posizione === 5, 'block': true}" (click)="cambiaRoute('report')">
        <img [ngClass]="{'imgSelected': this.posizione === 5}" src="assets/images/icons8-slice-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Reportistica</div>
      </div>

      <div class="bottom-elements">
        <div class="menuItem" [ngClass]="{'selected': this.posizione === 7, 'block': true}" (click)="cambiaRoute('impostazioni')" >
          <img [ngClass]="{'imgSelected': this.posizione === 7}" src="assets/images/icons8-setting-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
          <div style="padding-left: 25px">Impostazioni</div>
        </div>
      </div>
    </div>
  `
})
export class NavBarComponent implements OnInit {
  aperto = true;
  posizione: any;
  availableRestaurants: any[] = [];
  selectedRestaurant: string = '';

  private readonly IMAGE_NAMES = [
    'icons8-setting-100.png',
    'icons8-cash-register-100.png',
    'icons8-order-96.png',
    'icons8-menu-100.png',
    'icons8-product-100.png',
    'icons8-slice-90.png',
    'icons8-edit-90.png',
  ];

  constructor(
      private router: Router,
      public authService: AuthService,
      private dataServiceGenerico: DataServiceGenerico,
      private ipcService: IpcService
  ) {
    router.events.subscribe((val) => {
      if ('url' in val) {
        if (val.url.includes('home')) {
          this.posizione = 0;
        }
        if (val.url.includes('magazzino')) {
          this.posizione = 1;
        }
        if (val.url.includes('ordini')) {
          this.posizione = 2;
        }
        if (val.url.includes('menu')) {
          this.posizione = 3;
        }
        if (val.url.includes('cassa')) {
          this.posizione = 4;
        }
        if (val.url.includes('report')) {
          this.posizione = 5;
        }
        if (val.url.includes('costi')) {
          this.posizione = 6;
        }
        if (val.url.includes('impostazioni')) {
          this.posizione = 7;
        }
        if (val.url.includes('dashboard')) {
          this.posizione = 8;
        }
        if (val.url.includes('ordini-magazzino')) {
          this.posizione = 9;
        }
      }
    });
  }

  ngOnInit(): void {
    // Sottoscriviti ai ristoranti disponibili
    this.dataServiceGenerico.availableRestaurants$.subscribe(restaurants => {
      this.availableRestaurants = restaurants;
    });

    // Sottoscriviti al ristorante selezionato
    this.dataServiceGenerico.selectedRistorante$.subscribe(ristoranteId => {
      this.selectedRestaurant = ristoranteId;
    });
  }

  async onRestaurantChange() {
    try {
      const selectedRestaurant = this.availableRestaurants.find(r => r.id === this.selectedRestaurant);
      if (selectedRestaurant) {
        this.dataServiceGenerico.setCurrentRestaurant(selectedRestaurant);
        if (selectedRestaurant.selectedMenu) {
          await this.dataServiceGenerico.setSelectedMenu(selectedRestaurant.selectedMenu);
        }
      }

      // Ricarica la pagina corrente
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([this.router.url]);
      });
    } catch (error) {
      console.error('Errore nel cambio ristorante:', error);
    }
  }

  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  cambiaRoute(route): void {
    this.router.navigateByUrl(route);
  }

  navigateToCassa(): void {
    this.router.navigate(['cassa'], { fragment: 'nonav' })
  }

  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }
}