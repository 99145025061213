<div style="display: flex">

    <div style="
      width: calc(80% - 4.5%);
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 25px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      margin-left: 3%;
      margin-right: 1.5%;
      align-items: center;
        ">

        <div style="width: 100%; height: 100%; display: flex">
            <div class="row tab-selector">
                <div class="slider"
                     [ngStyle]="sliderWidthStyle"
                     [style.background-color]="lista == 0? 'rgba(181,181,181,0.2)': 'transparent'"
                     (click)="changeView(0)">
                    <span>Scontrini</span>
                </div>
                <ng-container *ngIf="mostraTavoli">
                    <div class="slider"
                         [ngStyle]="sliderWidthStyle"
                         [style.background-color]="lista == 1? 'rgba(181,181,181,0.2)': 'transparent'"
                         (click)="changeView(1)">
                        <span>Tavoli</span>
                    </div>
                    <div class="slider"
                         [ngStyle]="sliderWidthStyle"
                         [style.background-color]="lista == 2? 'rgba(181,181,181,0.2)': 'transparent'"
                         (click)="changeView(2)">
                        <span>Sala</span>
                    </div>
                </ng-container>
                <!--div class="slider"
                     [ngStyle]="sliderWidthStyle"
                     [style.background-color]="lista == 3? 'rgba(181,181,181,0.2)': 'transparent'"
                     (click)="changeView(3)">
                    <span>Delivery</span>
                </div-->
            </div>
        </div>

    </div>
    <div style="
      width: calc(20% - 4.5%);
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 25px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      margin-left: 1.5%;
      margin-right: 3%;
      align-items: center;
        ">


        <div style="width: 100%;
                        height: 100%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
">
            <div class="menu-options">
                <!--div class="search">
                    <div>
                        <input type="text" placeholder="Cerca..." required>
                    </div>
                </div-->
                <!--div class="drawer-icon">
                    <img src="assets/images/icons8-drawer-100 (1).png" class="action-icon" (click)="apricassetto()">
                </div-->
                <div class="drawer-icon">
                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-test-account-90.png?alt=media&token=03d43a7f-d23e-4d31-a7bd-ff019c1b8ed0" class="action-icon" (click)="selectuser()">
                </div>
                <div class="settings-icon" *ngIf="hasAnyPermission([
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity',
      'Impostazioni',
      'Annulla Scontrino',
      'Totali',
       'Chiusura Giornaliera',
    ])">
                    <img src="assets/images/icons8-setting-100.png" class="action-icon" (click)="apriazioniStampanteDialog()">
                </div>
            </div>
        </div>
    </div>
</div>



<div class="row main-container">
    <div class="columnOrdini">
        <!--div class="sb-example-1 shadow">
            <div class="search">
                <button type="submit" class="search-button">
                    <img [src]="'assets/icons/search.png'">
                </button>
                <input type="text" class="searchTerm" placeholder="Cerca">
            </div>
        </div>

        <div class="row tab-selector">
            <div class="slider" [style.background-color]="lista == 0? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 0">
                <span>Cronologia</span>
            </div>
            <div class="slider" [style.background-color]="lista == 1? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 1">
                <span>Tavoli</span>
            </div>
            <div class="slider" [style.background-color]="lista == 2? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 2">
                <span>Sala</span>
            </div>
        </div-->

        <!--div *ngIf="modalitaUnione" class="alert alert-info">
            <div>Seleziona due tavoli da unire ({{tavoliDaUnire.length}}/2)</div>
            <div>Tavoli selezionati: {{tavoliDaUnire.join(', ')}}</div>
            <button class="btn btn-secondary" (click)="modalitaUnione = false; tavoliDaUnire = []">Annulla</button>
        </div-->

        <div class="orders-layout" *ngIf="lista == 0">
            <!-- Lista ordini (colonna sinistra) -->
            <div class="orders-list">
                <ordini-card *ngFor="let scontrino of camerieri; index as i"
                             stato="Apert"
                             [totale]="calcolaTotaleOrdine(scontrino.ordine)"
                             [fonte]="scontrino.fonte"
                             [nome]="scontrino.pager ? scontrino.displayName + ' - Pager: ' + scontrino.pager :  scontrino.displayName"
                             [numero]="scontrino.ordine "
                             [statoPagato]="scontrino.statoPagato"
                             [bloccato]="scontrino.bloccato"
                             [selected]="selectedOrder?.id === scontrino.id"
                             (click)="openOrderDetail(scontrino)"
                             (delete)="eliminaOrdine(scontrino.ordine)">
                </ordini-card>
            </div>

            <!-- Dettaglio ordine (colonna centrale) -->
            <!-- Modifica solo la parte del dettaglio ordine, rendendo l'intera area cliccabile -->
            <div class="order-detail" *ngIf="selectedOrder" (click)="cambiaSchermata(selectedOrder.id)">
                <div class="detail-header">
                    <h3>{{selectedOrder.displayName}}</h3>
                    <div class="status-tag" [ngClass]="{
      'status-paid': selectedOrder.statoPagato === 0,
      'status-unpaid': selectedOrder.statoPagato === 1,
      'status-processing': selectedOrder.bloccato
    }">
                        {{selectedOrder.statoPagato === 0 ? 'Pagato' : selectedOrder.statoPagato === 1 ? 'Non Pagato' : 'In Lavorazione'}}
                    </div>
                </div>

                <div class="detail-content">
                    <div class="info-section">
                        <div class="info-row">
                            <span class="info-label">Cliente:</span>
                            <span class="info-value">{{selectedOrder.displayName}}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Data:</span>
                            <span class="info-value">{{selectedOrder.data | date:'dd/MM/yyyy H:mm'}}</span>
                        </div>
                        <div class="info-row">
                            <span class="info-label">Tempo evasione ordine:</span>
                            <span class="info-value">{{getElapsedTime(selectedOrder.data, selectedOrder.closureOrderTime)}}</span>
                        </div>
                        <div class="info-row" *ngIf="selectedOrder.nota">
                            <span class="info-label">Nota:</span>
                            <span class="info-value">{{selectedOrder.nota}}</span>
                        </div>
                        <div class="info-row" *ngIf="selectedOrder.motivazione">
                            <span class="info-label">Motivo eliminazione:</span>
                            <span class="info-value">{{selectedOrder.motivazione}}</span>
                        </div>
                        <div class="info-row" *ngIf="selectedOrder.pager">
                            <span class="info-label">Pager:</span>
                            <span class="info-value">{{selectedOrder.pager}}</span>
                        </div>
                    </div>

                    <div class="products-section" *ngIf="selectedOrder?.carrello">
                        <h4>Prodotti</h4>
                        <div class="product-list">
                            <div class="product-item" *ngFor="let item of getProperties(selectedOrder.carrello)">
                                <span class="quantity">{{selectedOrder.carrello[item].quantita}}x </span>
                                <div class="product-name">{{selectedOrder.carrello[item].title}}</div>
                                <div class="product-details">
                                    <span class="price">{{(selectedOrder.carrello[item].prezzo * selectedOrder.carrello[item].quantita).toFixed(2)}} €</span>
                                </div>
                            </div>
                        </div>
                        <div class="total-row">
                            <span>Totale</span>
                            <span class="total-amount">{{calcolaTotaleOrdine(selectedOrder.ordine)}} €</span>
                        </div>
                        <div *ngIf="selectedOrder.pager" class="pager-button">
                            Chiama pager: {{selectedOrder.pager}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="lista == 1" class="sale-selector-container">
            <div class="sale-selector">
                <div *ngFor="let sala of sale"
                     [ngClass]="{'sala-tab-active': sala.id === salaSelezionata, 'sala-tab': sala.id !== salaSelezionata}"
                     (click)="cambiareSala(sala.id)">
                    {{sala.nome}}
                </div>
            </div>
        </div>

        <!-- Selettore sale per la vista Sala (lista == 2) -->
        <div *ngIf="lista == 2" class="sale-selector-container">
            <div class="sale-selector">
                <div *ngFor="let sala of sale"
                     [ngClass]="{'sala-tab-active': sala.id === salaSelezionata, 'sala-tab': sala.id !== salaSelezionata}"
                     (click)="cambiareSala(sala.id)">
                    {{sala.nome}}
                </div>
            </div>
        </div>

        <!-- Modifica alla vista tavoli per filtrare per sala -->
        <div *ngIf="lista == 1" style="margin-bottom: 20px">
            <div *ngFor="let tavolo of getTavoliSalaCorrente()">
                <tab-card
                        stato="Aperto"
                        [totale]="calcolaTotaleOrdine2(findTavolo(tavolo.numero)?.ordine)"
                        [fonte]="findTavolo(tavolo.numero)?.fonte"
                        [nome]="findTavolo(tavolo.numero)?.displayName"
                        [numero]="tavolo.numero"
                        [statoPagato]="findTavolo(tavolo.numero)?.statoPagato"
                        [bloccato]="findTavolo(tavolo.numero)?.bloccato"
                        [numCoperti]="getCopertiTavolo(findTavolo(tavolo.numero))"
                        (click)="(modalitaUnione || modalitaSpostamento) ?
        selezionaTavoloPerUnione(tavolo.numero.toString()) :
        (findTavolo(tavolo.numero) !== null ?
            cambiaSchermata(findTavolo(tavolo.numero)?.id) :
            findIndexTavolo(tavolo.numero))"
                        [unione]="tavoliDaUnire.includes(tavolo.numero.toString())">
                </tab-card>
            </div>
        </div>

        <!-- Modifica alla vista sala per filtrare per sala -->
        <div *ngIf="lista == 2">
            <div class="example-boundary2">
                <div class="example-boundary">
                    <div *ngFor="let tavolo of getTavoliSalaCorrente()"
                         class="example-box"
                         cdkDragBoundary=".example-boundary"
                         cdkDrag
                         [cdkDragFreeDragPosition]="tavolo.posizione"
                         [style.border]="getTavoloBorder(tavolo)"
                         cdkDragDisabled="true">
                        <app-table-number
                                [tableNumber]="tavolo.numero"
                                [coperti]="tavolo.coperti"
                                [occupato]="findTavolo(tavolo.numero)?.statoPagato"
                                [ngClass]="{'tavolo-selezionato': tavoliDaUnire.includes(tavolo.numero.toString())}"
                                (click)="(modalitaUnione || modalitaSpostamento) ? selezionaTavoloPerUnione((tavolo.numero).toString()) :
            (findTavolo(tavolo.numero) !== null ? cambiaSchermata(findTavolo(tavolo.numero)?.id) : findIndexTavolo(tavolo.numero))">
                        </app-table-number>
                    </div>
                </div>
            </div>
        </div>





        <div *ngIf="lista == 3">
            <div class="orders-layout">
                <!-- Lista ordini delivery (colonna sinistra) -->
                <div class="orders-list">
                    <ordini-card *ngFor="let ordine of ordiniDelivery"
                                 [stato]="ordine.stato"
                                 [totale]="ordine.totale"
                                 [fonte]="ordine.piattaforma"
                                 [nome]="ordine.nomeCliente"
                                 [numero]="ordine.numero"
                                 [statoPagato]="ordine.statoPagato"
                                 [bloccato]="ordine.bloccato"
                                 [selected]="selectedOrder?.id === ordine.id"
                                 (click)="openOrderDetail(ordine)">
                    </ordini-card>
                </div>

                <!-- Dettaglio ordine delivery (colonna centrale) -->
                <div class="order-detail" *ngIf="selectedOrder">
                    <div class="detail-header">
                        <h3>{{selectedOrder.nomeCliente}}</h3>
                        <div class="status-tag" [ngClass]="{
                    'status-paid': selectedOrder.statoPagato === 0,
                    'status-unpaid': selectedOrder.statoPagato === 1,
                    'status-processing': selectedOrder.bloccato
                }">
                            {{selectedOrder.stato}}
                        </div>
                    </div>

                    <div class="detail-content">
                        <div class="info-section">
                            <div class="info-row">
                                <span class="info-label">Piattaforma:</span>
                                <span class="info-value">{{selectedOrder.piattaforma}}</span>
                            </div>
                            <div class="info-row">
                                <span class="info-label">Ordine:</span>
                                <span class="info-value">{{selectedOrder.numero}}</span>
                            </div>
                            <div class="info-row">
                                <span class="info-label">Data:</span>
                                <span class="info-value">{{selectedOrder.data | date:'dd/MM/yyyy H:mm'}}</span>
                            </div>
                        </div>

                        <div class="products-section" *ngIf="selectedOrder?.carrello">
                            <h4>Prodotti</h4>
                            <div class="product-list">
                                <div class="product-item" *ngFor="let item of getProperties(selectedOrder.carrello)">
                                    <span class="quantity">{{selectedOrder.carrello[item].quantita}}x</span>
                                    <div class="product-name">{{selectedOrder.carrello[item].title}}</div>
                                    <div class="product-details">
                                        <span class="price">{{(selectedOrder.carrello[item].prezzo * selectedOrder.carrello[item].quantita).toFixed(2)}} €</span>
                                    </div>
                                </div>
                            </div>
                            <div class="total-row">
                                <span>Totale</span>
                                <span class="total-amount">{{selectedOrder.totale?.toFixed(2)}} €</span>
                            </div>
                        </div>

                        <!-- Bottoni azione -->
                        <div class="action-buttons" style="margin-top: 20px; display: flex; gap: 10px;">
                            <button *ngIf="!selectedOrder.accettato"
                                    (click)="accettaOrdine(selectedOrder)"
                                    style="padding: 10px 20px; background-color: #4CAF50; color: white; border: none; border-radius: 5px;">
                                Accetta Ordine
                            </button>
                            <button *ngIf="selectedOrder.accettato && selectedOrder.statoPagato !== 0"
                                    (click)="evadiOrdine(selectedOrder)"
                                    style="padding: 10px 20px; background-color: #353555; color: white; border: none; border-radius: 5px;">
                                Evadi Ordine
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="column1Ordini">


        <p class="section-title" longPress (mouseLongPress)="logout()">Azioni</p>

        <div class="container actions-container">

            <div class="filter-container" *ngIf="(lista == 0 || lista == 3)" style="background-color: #353555 !important;">
                <div class="filter-option" (click)="aggiungiOrdine();">
                    <div>
                        <img [src]="'assets/icons/icons8-add-90.png'" style="filter: invert(1) brightness(10)">
                    </div>
                    <div class="filter-text" style="color: white !important;">NUOVO BANCO</div>
                </div>

            </div>

            <div class="filter-container" *ngIf="(lista == 0 || lista == 3)" style="background-color: #4a3555 !important;">
                <div class="filter-option" (click)="addTakeawayOrder();">
                    <div>
                        <img [src]="'assets/icons/icons8-add-90.png'" style="filter: invert(1) brightness(10)">
                    </div>
                    <div class="filter-text" style="color: white !important;">NUOVO ASPORTO</div>
                </div>

            </div>

            <div class="filter-container" *ngIf="(lista == 0 || lista == 3)" style="background-color: #5f3555 !important;">

                <div class="filter-option" (click)="reopenLastOrder();">
                    <div>
                        <img [src]="'assets/icons/icons8-minus-90.png'" style="filter: invert(1) brightness(10)">
                    </div>
                    <div class="filter-text" style="color: white !important;">RIAPRI CASSA</div>
                </div>
            </div>

                <!--categorieCard *ngIf="lista == 0" class="col-12 col-md-12 menuItem"
                               (click)="aggiungiOrdine();"
                               [nome]="'Aggiungi Ordine'"
                               [altezza]="'65'"
                               [link]="'assets/icons/icons8-add-90.png'">
                </categorieCard>
                <categorieCard *ngIf="lista == 0" class="col-12 col-md-12 menuItem"
                               (click)="eliminazione = !eliminazione; modalitaUnione = false;"
                               [nome]="'Rimuovi Ordine'"
                               [altezza]="'65'"
                               [link]="'assets/icons/icons8-minus-90.png'">
                </categorieCard-->

            <div class="filter-container" *ngIf="lista > 0 && !modalitaUnione && !modalitaSpostamento && lista != 3">
                <div class="filter-option" (click)="attivaModalitaUnione();">
                    <div>
                        <img [src]="'assets/icons/icons8-add-90.png'">
                    </div>
                    <div class="filter-text" [ngStyle]="{'color': filtroSource === 0 ? '#353555' : 'black'}">Unisci Tavoli</div>
                </div>
                <div class="separator"></div>
                <div class="filter-option" (click)="attivaModalitaSpostamento();">
                    <div>
                        <img [src]="'assets/icons/icons8-add-90.png'">
                    </div>
                    <div class="filter-text" [ngStyle]="{'color': filtroSource === 0 ? '#353555' : 'black'}">Sposta Tavolo</div>
                </div>
            </div>

            <div class="filter-container" *ngIf="lista > 0 && modalitaUnione">
                <div class="filter-option" >
                    <div>
                        <img [src]="'assets/icons/icons8-radio-button-empty-96.png'">
                    </div>
                    <div class="filter-text" [ngStyle]="{'color': filtroSource === 0 ? '#353555' : 'black'}">Selezionati {{tavoliDaUnire.length}}/2</div>
                </div>
                <div class="separator"></div>
                <div class="filter-option" (click)="modalitaUnione = false; tavoliDaUnire = []" style="cursor: pointer">
                    <div>
                        <img [src]="'assets/icons/icons8-minus-90.png'">
                    </div>
                    <div class="filter-text" [ngStyle]="{'color': filtroSource === 0 ? '#353555' : 'black'}" >Annulla</div>
                </div>
            </div>


        </div>

        <div class="line"></div>

        <p class="section-title" *ngIf="lista == 0">Fonte</p>

        <div class="filter-container" *ngIf="lista == 0">
            <div class="filter-option" (click)="filtraApp()">
                <div>
                    <img [src]="filtroSource != 0 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroSource === 0 ? '#353555' : 'black'}">App</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraChiosco()">
                <div>
                    <img [src]="filtroSource != 1 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroSource === 1 ? '#353555' : 'black'}">Kiosk</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraCassa()">
                <div>
                    <img [src]="filtroSource != 2 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroSource === 2 ? '#353555' : 'black'}">Cassa</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraTutto()">
                <div>
                    <img [src]="filtroSource != 3 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroSource === 3 ? '#353555' : 'black'}">Tutti</div>
            </div>
        </div>


        <div class="line" *ngIf="lista == 0"></div>

        <p class="section-title">Stato</p>

        <div class="filter-container">
            <div class="filter-option" (click)="filtraPagato()">
                <div>
                    <img [src]="filtroStato != 0 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroStato === 0 ? '#353555' : 'black'}">Pagato</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraNonPagato()">
                <div>
                    <img [src]="filtroStato != 1 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroStato === 1 ? '#353555' : 'black'}">Non Pagato</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraCompletato()">
                <div>
                    <img [src]="filtroStato != 3 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroStato === 3 ? '#353555' : 'black'}">Completato</div>
            </div>
            <div class="separator"></div>
            <div class="filter-option" (click)="filtraStatoTutto()">
                <div>
                    <img [src]="filtroStato != 2 ? 'assets/icons/icons8-radio-button-empty-96.png' : 'assets/icons/icons8-radio-button-96.png'">
                </div>
                <div class="filter-text" [ngStyle]="{'color': filtroStato === 2 ? '#353555' : 'black'}">Tutti</div>
            </div>
        </div>
    </div>
</div>