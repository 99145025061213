<div class="container">
    <div class="sticky-container" style="margin-bottom: 30px">

        <categorieCard class="col-12 col-md-12 menuItem" style="width: 18%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Aggiungi Categoria'"
                       altezza="55"
                       [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90%20(1).png?alt=media&amp;token=1631fb96-82a9-415e-b43e-3ca3c088e667' "
                       [bg]="'#33bfa3'"
                       (click)="addCategory()">
        </categorieCard>
        <categorieCard class="col-12 col-md-12 menuItem" style="width: 10%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Salva'"
                       altezza="55"
                       [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e' "
                       [bg]="'rgb(52 152 219)'"
                       (click)="saveCategories()">
        </categorieCard>

    </div>

    <div class="content-container">
        <div class="categories-list">
            <fieldset class="custom-fieldset"
                      *ngFor="let category of categories; let i = index; trackBy: trackByFn"
                      [attr.id]="'category-' + i">
                <legend>Categoria</legend>
                <div class="chart-container">
                    <input placeholder="Nome Categoria"
                           class="custom-input"
                           [value]="category"
                           (input)="updateCategory(i, $event.target.value)">
                </div>
            </fieldset>
        </div>
    </div>
</div>