import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, Optional } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import axios from 'axios';

let ipcRenderer;
if (typeof window !== 'undefined' && window.require) {
  const electron = window.require('electron');
  ipcRenderer = electron.ipcRenderer;
}

@Injectable({
  providedIn: 'any'
})
export class MenuService {
  private dbPath = '/';
  private offlineMode: boolean = false;

  tutorialsRef: AngularFirestoreCollection<any[]>;

  constructor(
    private db: AngularFirestore,
    @Inject('ristorante') @Optional() private ristorante?: string,
    @Inject('raccolta') @Optional() private raccolta?: string
  ) {
    this.dbPath += raccolta;
    this.tutorialsRef = db.collection(this.dbPath);

    if (ipcRenderer) {
      ipcRenderer.on('offline-mode', (event, mode) => {
        this.offlineMode = mode;
        console.log(`Offline mode set to: ${this.offlineMode}`);
      });
    }
  }

  private async getMongoData(endpoint: string) {
    try {
      const response = await axios.get(`http://localhost:3005/backup/${endpoint}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from MongoDB:`, error);
      throw error;
    }
  }

  getAll(): Observable<any[]> {
    if (this.offlineMode) {
      return from(this.getMongoData(this.dbPath)).pipe(switchMap(data => of(data)));
    } else {
      return this.tutorialsRef.valueChanges();
    }
  }

  getDoc(id: string): AngularFirestoreDocument<any> {
    if (this.offlineMode) {
      const document: any = {
        valueChanges: () => from(this.getMongoData(this.dbPath)).pipe(
          switchMap(data => of(data.find(item => item._id === id)))
        )
      };
      return document;
    } else {
      return this.tutorialsRef.doc(id);
    }
  }

  getCollection(id: string): AngularFirestoreDocument<any> {
    return this.getDoc(id);
  }

  create(tutorial: any, id: string): Promise<void> {
    if (this.offlineMode) {
      console.warn('Cannot create document in offline mode');
      return Promise.reject('Cannot create document in offline mode');
    } else {
      return this.tutorialsRef.doc(id).set({ ...tutorial });
    }
  }

  update(id: string, data: any): Promise<void> {
    if (this.offlineMode) {
      console.warn('Cannot update document in offline mode');
      return Promise.reject('Cannot update document in offline mode');
    } else {
      return this.tutorialsRef.doc(id).update(data);
    }
  }

  delete(id: string): Promise<void> {
    if (this.offlineMode) {
      console.warn('Cannot delete document in offline mode');
      return Promise.reject('Cannot delete document in offline mode');
    } else {
      return this.tutorialsRef.doc(id).delete();
    }
  }
}
