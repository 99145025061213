<div class="row">



  <div  class="container primaColonna" style="">

    <div style="padding: 10px; border-radius: 20px;  border: 1px solid #e3e2e2; height: 400px !important; margin-right: 10px; overflow-y: scroll">

      <div style="display: inline">
        <!--span style="width: 100% !important; display: block; padding: 10px; padding-bottom: 0px; font-weight: 800; margin-bottom: 20px">Ordine: </span-->


        <!--div style="pointer-events: auto; margin-top: 15px">
          <div class="ciaociao" style="padding-top: 0%; padding-left: 0px; " *ngFor="let piatto of getProperties(this.carrello); let last = last; let first = first">
            <menuDetailCard  style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.carrello[piatto]['title']"  [ingredienti]="this.carrello[piatto]['category']"  [prezzo]="this.carrello[piatto]['prezzo']"  [quantita]="this.carrello[piatto].quantita"  [carrello]=true [last]="last" [first]="first" [lenght]="getProperties(this.carrello).length" [link]="this.carrello[piatto].image_link"></menuDetailCard>
          </div>
        </div-->


        <div *ngFor="let piatto of getProperties(this.carrello)">
          <categorieCard2 (click)="ciaociao(carrello[piatto]['category'])" class="menuItem" style="width: 100%; display: inline-flex; padding-left: 0px; margin-left: 0px;" [nome]="carrello[piatto]['title']" luogo="scontrino" [link]="carrello[piatto]['image_link']" costo="" [quantita]="carrello[piatto]['quantita']"  (quantitaOut)="handleEvent($event, piatto)" ></categorieCard2>

        </div>

      </div>

      <!--div style=" height: 50px; border-bottom-left-radius: 20px; border-bottom-right-radius: 20px; border-top: 1px solid grey">
        <span style="float: right">55.00€</span>
      </div-->
    </div>
    <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 15px">
      <div style="padding-top: 0%; padding-left: 0px">
        <div  style="flex-basis: 100%;  height: 1px; background-color: #e55933; "></div>

        <div style="text-align: right; font-weight: 600; margin-top: 10px; margin-right: 13px; color: #e55933">Totale: {{calcolaTotaleBello().toFixed(2)}}€ </div>
      </div>
    </div>

  </div>



  <div class="secondaColonna" *ngIf="ciao === false">

    <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngFor="let scontrino of categorie, let i = index, let even = even">
      <m *ngIf="even">
        <categorieCard (click)="ciaociao(scontrino)" class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " [nome]="scontrino" luogo="scontrino" [link]="getImage(scontrino)" costo="" ></categorieCard>
        <categorieCard (click)="ciaociao(categorie[i+1])" *ngIf="categorie[i+1]" class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 10px;" [nome]="categorie[i+1]" luogo ="" [link]="getImage(categorie[i+1])" costo="" ></categorieCard>
      </m>
    </div>

  </div>


  <div *ngIf="ciao === true && categoriaSelezionata !== '' && terzoLivello == false && quartoLivello == false" class="container secondaColonna" style="">
    <div style="width: calc(56vw - 44px) !important">
      <categorieCard class="" style="width: calc(56vw - 24px) !important; cursor: pointer"
                     [nome]="'Indietro'"
                     [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVR4nO2YvUrEQBSFxz+wWHutrLXWNxARtBYb30BtVNxuOiEJ95wJhMXoG8SfB7AS1PfwKRRBiQzMgoSNuyI7ozKnnFvk48yd3B+l/ruMMasAuiKyU1XVVDAQksck30m+kKxJ3iVJMucdBEDXAgA401pPA9gk+UzyJhRIr67rif65MWbPnS8EBbEieWBjaZrOq5AgIrLhcufSB8iRAznXWk82YusO5GHsCYwI0lB0pKnoyHf+IwDW7PMFcF8URUd5uppeE8TFTwHcjh0kz/NlAG8kyyZIlmWLWutZ5UsAdttqCsknL1fz6YNLbc70a45XIHyRM0GAROTQdWwXbcUQwKO3bk4i0BBJdGiIokN/ziGMMB0AuPICM0Kbse91iPtVE+WgWbssyxljzJadtQFcq8BbiNegWwjlBGCF5AmA7aD7mZ/oAwZyFTgtXSrKAAAAAElFTkSuQmCC'"
                     (click)="ciaociao2()">

      </categorieCard>
    </div>

    <!--div class="row" style="float: left; width: 97%; height: 50px; margin-top: 5px; border-radius: 30px; background-color: rgba(181,181,181,0.2); border: 1px solid rgba(181,181,181,0.5); padding: 5px; margin-bottom: 7px;"
         *ngIf="categoriaSelezionata == 'Bucket'"
    >
      <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: white; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="bucketLvl == 0? 'white': 'transparent'" (click)="bucketLvl = 0">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Tutto</span>
      </div>
      <div class="slider" style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="bucketLvl == 1? 'white': 'transparent'" (click)="bucketLvl = 1">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Per 1</span>

      </div>
      <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: white; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="bucketLvl == 2? 'white': 'transparent'" (click)="bucketLvl = 2">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Per 2</span>
      </div>
      <div class="slider" style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="bucketLvl == 3? 'white': 'transparent'" (click)="bucketLvl = 3">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Per 3</span>

      </div>
    </div-->

    <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngFor="let scontrino of filtraMenu(categoriaSelezionata), let i = index, let even = even">
      <m *ngIf="even">
        <categorieCard3  longPress (mouseLongPress)="updateCameriere(i)"  (click)="updateCameriere(i)" class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " [nome]="scontrino.title" luogo ="" [link]="scontrino.image_link" [costo]="getPrice(scontrino.price)" [quantita]="getQuantita(scontrino)" (quantitaOut)="handleEvent($event, scontrino.title)"></categorieCard3>
        <categorieCard3  longPress (mouseLongPress)="updateCameriere(i+1)"  (click)="updateCameriere(i+1)" *ngIf="filtraMenu(categoriaSelezionata)[i+1]" class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" [nome]="filtraMenu(categoriaSelezionata)[i+1].title" luogo ="" [link]="filtraMenu(categoriaSelezionata)[i+1].image_link" [costo]="getPrice(filtraMenu(categoriaSelezionata)[i+1].price)" [quantita]="getQuantita(filtraMenu(categoriaSelezionata)[i+1])" ></categorieCard3>
      </m>
    </div>
  </div>

  <div *ngIf="ciao === true && categoriaSelezionata !== '' && terzoLivello == true && quartoLivello == false" class="container secondaColonna" style="">
    <div style="width: calc(56vw - 44px) !important">
      <categorieCard class="" style="width: calc(56vw - 24px) !important; cursor: pointer"
                     [nome]="'Indietro'"
                     [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVR4nO2YvUrEQBSFxz+wWHutrLXWNxARtBYb30BtVNxuOiEJ95wJhMXoG8SfB7AS1PfwKRRBiQzMgoSNuyI7ozKnnFvk48yd3B+l/ruMMasAuiKyU1XVVDAQksck30m+kKxJ3iVJMucdBEDXAgA401pPA9gk+UzyJhRIr67rif65MWbPnS8EBbEieWBjaZrOq5AgIrLhcufSB8iRAznXWk82YusO5GHsCYwI0lB0pKnoyHf+IwDW7PMFcF8URUd5uppeE8TFTwHcjh0kz/NlAG8kyyZIlmWLWutZ5UsAdttqCsknL1fz6YNLbc70a45XIHyRM0GAROTQdWwXbcUQwKO3bk4i0BBJdGiIokN/ziGMMB0AuPICM0Kbse91iPtVE+WgWbssyxljzJadtQFcq8BbiNegWwjlBGCF5AmA7aD7mZ/oAwZyFTgtXSrKAAAAAElFTkSuQmCC'"
                     (click)="ciaociao2()">

      </categorieCard>
    </div>


    <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngIf="categoriaSelezionata == 'Bucket'">
      <m *ngIf="true">
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " (click)="updateCameriere(piattoSelezionato2)" nome="Solo Bucket" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.price)"  [quantita]="getQuantita(piattoSelezionato2)" (quantitaOut)="handleEvent($event, piattoSelezionato2.title)"></categorieCard3>
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" (click)="selezionaMenu(1)" nome="Menu" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.prezziMenu[0])"></categorieCard3>
      </m>
    </div>

    <!--div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngIf="categoriaSelezionata !== 'Bucket'">
      <m *ngIf="true">
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " (click)="updateCameriere(piattoSelezionato2)" nome="Singolo" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.price)"  [quantita]="getQuantita(piattoSelezionato2)" (quantitaOut)="handleEvent($event, piattoSelezionato2.title)"></categorieCard3>
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" (click)="selezionaMenu(1)" nome="Menu Piccolo" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.prezziMenu[0])"></categorieCard3>
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" (click)="selezionaMenu(2)" nome="Menu Medio" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.prezziMenu[1])"></categorieCard3>
        <categorieCard3  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" (click)="selezionaMenu(3)" nome="Menu Grande" luogo ="" [link]="piattoSelezionato2.image_link" [costo]="getPrice(piattoSelezionato2.prezziMenu[2])"></categorieCard3>
      </m>
    </div-->

    <div style="padding-top: 15px; display: flex; flex: 1" *ngIf="categoriaSelezionata !== 'Bucket'">
      <div (click)="updateCameriere(piattoSelezionato2)" style="width: 33%; text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer" >Singolo
        <div style="font-size: large; margin-top: -20px; line-height: 0px;">
          +{{ getPrice(piattoSelezionato2.price) }}
        </div>
      </div>
      <div  (click)="selezionaMenu(0)" style="width: 33%; text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer">Menu Piccolo
        <div style="font-size: large; margin-top: -20px; line-height: 0px;">
          +{{ getPrice(piattoSelezionato2.prezziMenu[0]) }}
        </div>
      </div>
      <div (click)="selezionaMenu(1)" style="width: 33%; text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer">Menu Medio
        <div style="font-size: large; margin-top: -20px; line-height: 0px;">
          +{{ getPrice(piattoSelezionato2.prezziMenu[1]) }}
        </div>
      </div>
    </div>

    <div (click)="selezionaMenu(2)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer" *ngIf="categoriaSelezionata !== 'Bucket'">
      Menu Large
      <div style="font-size: large; margin-top: -20px; line-height: 0px;">
        +{{ getPrice(piattoSelezionato2.prezziMenu[2]) }}
      </div>
    </div>

  </div>

  <div *ngIf="ciao === true && categoriaSelezionata !== '' && quartoLivello == true" class="container secondaColonna" style="">
    <div style="width: calc(56vw - 44px) !important">
      <categorieCard class="" style="width: calc(56vw - 24px) !important; cursor: pointer"
                     [nome]="'Indietro'"
                     [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAABR0lEQVR4nO2YvUrEQBSFxz+wWHutrLXWNxARtBYb30BtVNxuOiEJ95wJhMXoG8SfB7AS1PfwKRRBiQzMgoSNuyI7ozKnnFvk48yd3B+l/ruMMasAuiKyU1XVVDAQksck30m+kKxJ3iVJMucdBEDXAgA401pPA9gk+UzyJhRIr67rif65MWbPnS8EBbEieWBjaZrOq5AgIrLhcufSB8iRAznXWk82YusO5GHsCYwI0lB0pKnoyHf+IwDW7PMFcF8URUd5uppeE8TFTwHcjh0kz/NlAG8kyyZIlmWLWutZ5UsAdttqCsknL1fz6YNLbc70a45XIHyRM0GAROTQdWwXbcUQwKO3bk4i0BBJdGiIokN/ziGMMB0AuPICM0Kbse91iPtVE+WgWbssyxljzJadtQFcq8BbiNegWwjlBGCF5AmA7aD7mZ/oAwZyFTgtXSrKAAAAAElFTkSuQmCC'"
                     (click)="ciaociao2()">

      </categorieCard>
    </div>


    <div class="row" style="float: left; width: 97%; height: 50px; margin-top: 5px; border-radius: 30px; background-color: rgba(181,181,181,0.2); border: 1px solid rgba(181,181,181,0.5); padding: 5px; margin-bottom: 7px;">
      <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 33%; border-radius: 25px; background-color: white; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="contornoLvl == 0? 'white': 'transparent'" (click)="contornoLvl = 0">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Contorno</span>
      </div>
      <div class="slider" style="float: left; cursor: pointer; height: 100%; width: 33%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="contornoLvl == 1? 'white': 'transparent'" (click)="contornoLvl = 1">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Bibita</span>

      </div>
      <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 33%; border-radius: 25px; background-color: white; text-align: center; line-height: 50px; font-weight: 500" [style.background-color]="contornoLvl == 2? 'white': 'transparent'" (click)="contornoLvl = 2">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Salsa</span>
      </div>
    </div>

    <span *ngIf="contornoLvl == 0">
      <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngFor="let contorno of contorni[0].menu, let i = index, let even = even">
        <m *ngIf="even">
          <categorieCard3 (click)="selezionaContorno(0, contorno)"  longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " [nome]="contorno.title" luogo ="" [link]="contorno.img" [costo]="getPrice(contorno.prezzo)" [selezionato]="selezionatoContorno(contorno)"></categorieCard3>
          <categorieCard3 (click)="selezionaContorno(0, contorni[0].menu[i+1])"  *ngIf="contorni[0].menu[i+1]" longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" [nome]="contorni[0].menu[i+1].title" luogo ="" [link]="contorni[0].menu[i+1].img" [costo]="getPrice(contorni[0].menu[i+1].prezzo)" [selezionato]="selezionatoContorno(contorni[0].menu[i+1])"></categorieCard3>
        </m>
      </div>
    </span>

    <span *ngIf="contornoLvl == 1">
      <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngFor="let contorno of contorni[1].menu, let i = index, let even = even">
        <m *ngIf="even">
          <categorieCard3 (click)="selezionaContorno(1, contorno)" longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " [nome]="contorno.title" luogo ="" [link]="contorno.img" [costo]="getPrice(contorno.prezzo)" [selezionato]="selezionatoContorno(contorno)"></categorieCard3>
          <categorieCard3 (click)="selezionaContorno(1, contorni[1].menu[i+1])" *ngIf="contorni[1].menu[i+1]" longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" [nome]="contorni[1].menu[i+1].title" luogo ="" [link]="contorni[1].menu[i+1].img" [costo]="getPrice(contorni[1].menu[i+1].prezzo)" [selezionato]="selezionatoContorno(contorni[1].menu[i+1])"></categorieCard3>
        </m>
      </div>
    </span>

    <span *ngIf="contornoLvl == 2">
      <div class="" style="width: calc(56vw - 24px) !important; padding-top: 0%; padding-left: 0px; padding-right: 0px !important; margin-right: 0px; " *ngFor="let contorno of contorni[2].menu, let i = index, let even = even">
        <m *ngIf="even">
          <categorieCard3 (click)="selezionaContorno(2, contorno)" longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px; " [nome]="contorno.title" luogo ="" [link]="contorno.img" [costo]="getPrice(contorno.prezzo)" [selezionato]="selezionatoContorno(contorno)"></categorieCard3>
          <categorieCard3 (click)="selezionaContorno(2, contorni[2].menu[i+1])" *ngIf="contorni[2].menu[i+1]" longPress class="menuItem" style="width: calc(28vw - 22px) !important; display: inline-flex; padding-left: 0px; margin-left: 0px;" [nome]="contorni[2].menu[i+1].title" luogo ="" [link]="contorni[2].menu[i+1].img" [costo]="getPrice(contorni[2].menu[i+1].prezzo)" [selezionato]="selezionatoContorno(contorni[2].menu[i+1])"></categorieCard3>
        </m>
      </div>
    </span>



  </div>





</div>


<div style="width:100%; height: 60px; cursor: pointer" (click)="this.dialogRef.close(false);">
  <div style="float: right; background-color: rgba(0,128,0,0.1); margin: 10px; padding: 15px; border-radius: 20px; font-weight: bolder; border: 1px solid rgb(0,128,1); color: rgba(0,128,0,1)">Conferma</div>
</div>


<div style="background-color: rgba(232,114,114,0.53); width: 80px; height: 80px; position: absolute; top: 90vh; left: calc(50% - 40px); border-radius: 50px; border: 2px solid red; cursor: pointer" (click)="this.dialogRef.close(false);">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style="background-color: transparent !Important; height: 30px; margin-top: 25px; margin-left: 29px; filter: brightness(0) saturate(100%) invert(21%) sepia(58%) saturate(4525%) hue-rotate(352deg) brightness(82%) contrast(96%);" ><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
</div>
