import { Component, Input } from '@angular/core';

/**
 * Componente per visualizzare il numero del tavolo e gestire i coperti con layout dinamico.
 */
@Component({
  selector: 'app-table-number',
  template: `
    <div class="main-div" [ngStyle]="{'aspect-ratio': aspectRatio}">
      {{ tableNumber }}
      <!-- Div sui lati lunghi -->
      <div class="side-wrapper top" *ngIf="longSideDivs > 0">
        <div *ngFor="let div of [].constructor(longSideDivs)" class="side-horizontal"
             [ngStyle]="{'background-color': getSideDivColor()}"></div>
      </div>
      <div class="side-wrapper bottom" *ngIf="longSideDivs > 0">
        <div *ngFor="let div of [].constructor(longSideDivs)" class="side-horizontal"
             [ngStyle]="{'background-color': getSideDivColor()}"></div>
      </div>
      <!-- Div sui lati corti -->
      <div *ngIf="shortSides.length > 0" class="side" [ngClass]="shortSides[0].position"
           [ngStyle]="{'background-color': getSideDivColor()}"></div>
      <div *ngIf="shortSides.length > 1" class="side" [ngClass]="shortSides[1].position"
           [ngStyle]="{'background-color': getSideDivColor()}"></div>
    </div>
  `,
  styles: [`
    .main-div {
      position: relative;
      height: 45px;
      background: white;
      border: 1px solid rgba(181,181,181, 0.5);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
    }

    .side {
      background-color: rgba(181,181,181, 0.3);
      border-radius: 10px;
    }

    .side-wrapper {
      position: absolute;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      height: 4px;
    }

    .top { top: -7px; }
    .bottom { bottom: -7px; }

    .side-horizontal {
      height: 4px;
      flex: 1;
      margin: 0 2px;
      border-radius: 10px;
    }

    .right, .left {
      position: absolute;
      top: 7px;
      bottom: 7px;
      width: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right { right: -7px; }
    .left { left: -7px; }
  `]
})
export class TableNumberComponent {
  @Input() tableNumber: number = 0;
  @Input() coperti: number = 1; // Numero di coperti
  @Input() occupato: number = 0; // 0 = libero, 1 = occupato, 2 = prenotato

  aspectRatio: string = '1 / 1'; // Default aspect ratio
  longSideDivs: number = 0;
  shortSides: Array<{ position: string }> = [];

  ngOnChanges(): void {
    this.updateLayout();
  }

  updateLayout(): void {
    // Limita il numero di coperti a 15
    const copertiLimit = Math.min(this.coperti, 15);

    // Imposta l'aspect ratio in base al numero di coperti
    if (copertiLimit <= 4) {
      this.aspectRatio = '1 / 1'; // Tavolo quadrato
    } else if (copertiLimit <= 6) {
      this.aspectRatio = '2 / 1'; // Tavolo rettangolare
    } else if (copertiLimit <= 9) {
      this.aspectRatio = '3 / 1'; // Tavolo più largo
    } else if (copertiLimit <= 12) {
      this.aspectRatio = '4 / 1'; // Tavolo ancora più largo
    } else {
      this.aspectRatio = '5 / 1'; // Tavolo massimo larghezza
    }

    // Calcola i div sui lati lunghi
    const isEven = copertiLimit % 2 === 0;
    this.longSideDivs = isEven ? (copertiLimit - 2) / 2 : (copertiLimit - 1) / 2;

    // Gestisci i div sui lati corti
    this.shortSides = [];
    if (isEven && copertiLimit > 1) {
      this.shortSides.push({ position: 'left' });
      this.shortSides.push({ position: 'right' });
    } else if (!isEven) {
      this.shortSides.push({ position: 'left' });
    }
  }

  getSideDivColor(): string {
    if (this.occupato === 1) {
      return 'rgb(230 89 52 / 70%)'; // Rosso se occupato
    } else if (this.occupato === 2) {
      return 'rgb(52 191 163 / 70%)'; // Verde se prenotato
    }
    return 'rgba(181,181,181, 0.3)'; // Colore di default se libero
  }
}
