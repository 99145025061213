import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { InventoryItem } from "../../../shared/services/firestore/inventory.service";

@Component({
  selector: 'app-cronologia-menu',
  templateUrl: './menuCronologia.html',
  styleUrls: ['./menuCronologia.css']
})
export class CronologiaMenuComponent implements OnInit, OnChanges {
  @Input() item: InventoryItem;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && this.item) {
      // Sort movements by timestamp in descending order (newest first)
      if (this.item.movimenti) {
        this.item.movimenti.sort((a, b) => b.timestamp - a.timestamp);
      }
    }
  }

  getTagColor(modalita: string): string {
    switch(modalita) {
      case 'vendita':
        return '#E8F5E9';
      case 'aggiunta':
        return '#C4E2BC';
      case 'scarico':
        return '#FFE1AD';
      case 'scarti':
        return '#FFB6B6';
      case 'trasferimento':
        return '#B6DBFF';
      default:
        return '#E2E2E2';
    }
  }

  getTagTextColor(modalita: string): string {
    switch(modalita) {
      case 'vendita':
        return '#2E7D32';
      case 'aggiunta':
        return '#2D5A27';
      case 'scarico':
        return '#8B6F3D';
      case 'scarti':
        return '#8B3D3D';
      case 'trasferimento':
        return '#3D5F8B';
      default:
        return '#666666';
    }
  }

  getLevelUnit(level: string): string {
    const levelIndex = parseInt(level.replace('livello', '')) - 1;
    return this.item?.lvl[levelIndex]?.um || '';
  }

  formatPreviousQuantity(quantitaPrec: string): string {
    try {
      const quantities = JSON.parse(quantitaPrec);
      return Object.values(quantities)
          .filter(qty => qty !== '')
          .join(' - ');
    } catch {
      return quantitaPrec;
    }
  }
}