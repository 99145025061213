<div class="chiusura-scontrino-container">
    <div class="sticky-container">
       
        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>
    <!-- Scelta iniziale tramite due pulsanti grandi -->
    <div class="opzioni-iniziali" *ngIf="!chiusuraGiornaliera && !annullaScontrino">
        <button class="pulsante-grande" (click)="selezionaChiusuraGiornaliera()">Chiusura Giornaliera</button>
        <button class="pulsante-grande" (click)="selezionaAnnullaScontrino()">Annulla Scontrino</button>
        <button class="pulsante-grande" (click)="navigateToImpostazioni()">Backoffice</button>
        <button class="pulsante-esci" (click)="logout()">LogOut</button>
    </div>

    <!-- Conferma per la chiusura giornaliera -->
    <div class="conferma-chiusura" *ngIf="chiusuraGiornaliera">
        <p>Vuoi procedere con la chiusura giornaliera?</p>
        <button class="pulsante-medio" (click)="confermaChiusuraGiornaliera()">Conferma</button>
        <button class="pulsante-medio-annulla" (click)="annullaOperazione()">Annulla</button>
    </div>

    <!-- Form per annullare lo scontrino -->
    <div class="form-annulla-scontrino" *ngIf="annullaScontrino">
        <form (ngSubmit)="annullaScontrinoSubmit()">
            <div class="div-separazione">
                <label for="nomeScontrino">Numero Scontrino:</label>
                <input type="text" id="nomeScontrino" [(ngModel)]="nomeScontrino" name="nomeScontrino" required>
            </div>
            <div class="div-separazione">
                <label for="chiusuraGiornaliera">Chiusura Giornaliera:</label>
                <input type="text" id="chiusuraGiornaliera" [(ngModel)]="chiusuraGiornalieraInput" name="chiusuraGiornaliera" required>
            </div>
            <div class="div-separazione">
                <label for="data">Data (ggmmaaaa):</label>
                <input type="text" id="data" [(ngModel)]="dataInput" name="data" required pattern="d{2}d{2}d{4}">
            </div>
            <div class="div-separazione">
                <label for="data">Seriale:</label>
                <input type="text" id="seriale" [(ngModel)]="serialeInput" name="seriale" required p>
            </div>
            <button class="pulsante-medio" type="submit">Annulla Scontrino</button>
            <button class="pulsante-medio-annulla" type="button" (click)="annullaOperazione()">Annulla</button>
        </form>
    </div>
</div>