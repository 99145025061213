<div class="container">
    <div class="sticky-container" >
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90%20(1).png?alt=media&token=1631fb96-82a9-415e-b43e-3ca3c088e667"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Aggiungi Categoria"
                 (click)="addCategory()">
        </div>
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Modifiche"
                 (click)="saveCategories()">
        </div>

    </div>

    <div class="content-container">
        <div class="categories-list">
            <fieldset class="custom-fieldset"
                      *ngFor="let category of categories; let i = index; trackBy: trackByFn"
                      [attr.id]="'category-' + i">
                <legend>Categoria</legend>
                <div class="chart-container">
                    <input placeholder="Nome Categoria"
                           class="custom-input"
                           [value]="category"
                           (input)="updateCategory(i, $event.target.value)">
                </div>
            </fieldset>
        </div>
    </div>
</div>