import {Injectable, NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule} from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {NavBarComponent} from './component/navbar/navbar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NavBarCardComponent} from './component/navBarCard/navBarCard.component';
import {Home} from './pages/home/home';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './router.module';
import {environment, environmentFirebase} from '../environments/environment';
import {ReceiptOrderCardComponent} from './component/receiptOrderCard/receiptOrderCard.component';
import {cardDettaglioScontrino} from './component/receiptItemCard/cardDettagliScontrino.component';
import {DettaglioScontrinoFinaleCard} from './component/receiptItemCard/cardDettaglioScontrinoFinale.component';
import {MenuDetailCardComponent} from './component/menuDetailCard/menuDetailCard.component';
import {SignIn} from './pages/sign-in/sign-in';
import {CategorieCard} from './component/categoriesCard/cardCategorie.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MenuContainerComponent} from "./component/menuContainer/menuContainer.component";
import {MenuCardComponent} from "./component/menuCard/menuCard.component";
import {AggiuntaPiatto} from "./pages/aggiunta-piatto/aggiunta-piatto";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {Ordini} from './pages/ordini/ordini';
import {ReceiptComponent} from "./component/receipt/receipt.component";
import {OrdiniCardComponent} from "./component/ordiniCard/ordiniCard.component";
import {Menu} from "./pages/menu/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {Magazzino} from "./pages/magazzino/magazzino";
import {CassaCamerieri} from "./pages/cassaCamerieri/cassa-camerieri";
import {Report} from "./pages/reports/report/report";
import {SchermoCucina} from "./pages/schermo-cucina/schermo-cucina.component";
import {DataServiceGenerico} from "./shared/services/data-service/data.service";
import {EditMenu} from "./pages/edit-menu/edit-menu";
import {MatSelectModule} from "@angular/material/select";
import { NgxElectronModule } from 'ngx-electron';
import {TestOfflineModeComponent} from "./pages/test-offline-mode/test-offline-mode.component";
import {tagComponent} from "./component/Tag/tag.component"
import {CategorieCard2} from "./component/categoriesCard/cardCategorie2.component";
import {CategorieCard3} from "./component/categoriesCard/cardCategorie3.component";
import {LongPressDirective} from "./shared/long-press.directive";
import {MatDatepickerModule, MatDateRangePicker} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {tabCardComponent} from "./component/tabCard/tabCard.component";
import {NavbarReportComponent} from "./component/navbar-report/navbar-report.component";
import {IndietroCardComponent} from "./component/navbar-report/indietro.component";
import {ReportPanoramica} from "./pages/reports/report-panoramica/report-panoramica";
import {ReportInventario} from "./pages/reports/report-inventario/report-inventario";
import {MatSortModule} from "@angular/material/sort";
import {ReportIncassi} from "./pages/reports/report-incassi/report-incassi";
import {EditCategories} from "./pages/edit-categorie/edit-categories";
import * as Hammer from 'hammerjs';
import {ReportRevenewLoss} from "./pages/reports/report-revenewloss/report-revenewLoss";
import {EditMagazzino} from "./pages/edit-magazzino/edit-magazzino";
import {InventoryComponent} from "./pages/inventoryComponent/inventory.component";
import {EditInventario} from "./pages/edit-inventario/edit-inventario";
import {NavbarImpostazioniComponent} from "./component/navbar-impostazioni/navbar-impostazioni.component";
import {Impostazioni} from "./pages/settings/impostazioni/impostazioni";
import {CdkDrag, DragDropModule} from "@angular/cdk/drag-drop";
import {CassaBar} from "./pages/cassa-bar/cassa-bar";
import {MenuDetailCardBarComponent} from "./component/menuDetailCard-bar/menuDetailCard-bar.component";
import {Account} from "./pages/settings/account/account";
import {AggiuntaPiattoTotem} from "./pages/aggiunta-piatto-totem/aggiunta-piatto-totem";
import {TableNumberComponent} from "./component/tableNumber/tableNumber.component";
import {Fidelity} from "./pages/fidelity/fidelity";
import {Ristorante} from "./pages/settings/ristorante/ristorante";
import {Personale} from "./pages/settings/personale/personale";
import {Preferiti} from "./pages/settings/preferiti/preferiti";
import {DialogPagamento} from "./pages/dialogPagamento/dialogPagamento";
import {NumericKeypadComponent} from "./pages/settings/pulsantiera/pulsantiera";
import {TavoloDialogComponent} from "./pages/settings/tavoli/edit-tavolo/tavolo-dialog.component";
import {Tavoli} from "./pages/settings/tavoli/tavoli";
import {ChatComponent} from "./pages/settings/chat/chat";
import {ChatComponent2} from "./pages/settings/chat2/chat2";
import {ImpostazioniFidelity} from "./pages/settings/impostazioniFidelity/impostazioniFidelity";
import {ImpostazioniMenu} from "./pages/settings/impostazioniMenu/impostazioniMenu";
import {ImpostazioniRistoranti} from "./pages/settings/impostazioniRistoranti/impostazioniRistoranti";
import {EditSconti} from "./pages/settings/edit-sconti/edit-sconti";
import {Fatturazione} from "./pages/fatturazione/fatturazione";
import {ReportProdotti} from "./pages/reports/report-prodotti/report-prodotti";
import {CassaOrdine} from "./pages/cassa/cassa-ordine/cassa-ordine";
import {CassaOrdini} from "./pages/cassa/cassa-ordini/cassa-ordini";
import {APP_BASE_HREF, CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {AzioniStampanteComponent} from "./pages/azioniStampante/azioniStampante";
import {Stampanti} from "./pages/settings/stampanti/stampanti";
import {SnackbarComponent} from "./pages/settings/stampanti/notificationService/snackbar-component";
import {DialogVariantiComponent} from "./pages/dialog-varianti/dialog-varianti.component";
import {SliderImages} from "./pages/settings/slider-images/slider-images";
import {ObjectURLPipe} from "./pages/settings/slider-images/object-url-pipe";
import {SharedPipesModule} from "../environments/shared-pipes-module";
import {SelezionaVarianti} from "./pages/seleziona-varianti/seleziona-varianti";

// Importazioni Firebase
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import {EditMenus} from "./pages/edit-menus/edit-menus";
import {Calendario} from "./pages/settings/calendario/calendario";
import {Dashboard} from "./pages/Dashboard/dashboard";
import {CarrelloMagazzino} from "./pages/carrelloMagazzino/carrelloMagazzino";
import {AddOrdineMagazzino} from "./pages/add-ordine-magazzino/add-ordine-magazzino";
import {OrdiniMagazzino} from "./pages/ordiniMagazzino/ordiniMagazzino";
import {CronologiaMagazzinoCardComponent} from "./component/cronologiaMagazzinoCard/cronologiaMagazzinoCard.component";
import {NotificheDashboardCardComponent} from "./component/notificheCardDashboard/notificheCardDashboardCard.component";
import {Cronologia} from "./pages/cronologia/cronologia";
import {OrdiniCardMagazzinoComponent} from "./component/ordiniCard/ordiniCardMagazzino.component";
import {OrderDetailPopupComponent} from "./pages/settings/calendario/detail/order-detail-popup";
import {SliderTotem} from "./pages/settings/slider-totem/slider-totem";
import {AddEmployeeDialogComponent} from "./pages/settings/personale/dialog/add-employee-dialog.component";
import {AddRoleDialogComponent} from "./pages/settings/personale/dialog/add-role-dialog.component";

import {UpsellingComponent} from "./pages/upSelling/upSelling.component";
import {AddGroupDialogComponent} from "./pages/settings/impostazioniRistoranti/dialog/add-group-dialog";
import {AddRestaurantDialogComponent} from "./pages/settings/impostazioniRistoranti/dialog/add-restaurant-dialog";
import {PriceListsComponent} from "./pages/price-lists/price-lists.component";
import {AngularFireModule} from "@angular/fire";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {NotificheComponent} from "./pages/settings/notifiche/notifiche.component";
import {HttpClientModule} from "@angular/common/http";
import {MenuDetailComponent} from "./pages/menu-detail/menu-detail.component";
import {DeleteOrderDialogComponent} from "./component/delete-order-dialog/delete-order-dialog.component";
import {AddWorkstationDialogComponent} from "./pages/settings/impostazioniRistoranti/dialog/app-add-workstation-dialog";
import {RevenueComponent} from "./pages/settings/revenue/revenue.component";
import {EditSliderTotemComponent} from "./pages/settings/slider-totem/popup/edit-slider-totem";
import {EditSliderImageComponent} from "./pages/settings/slider-images/edit-slider-images/edit-slider-images";
import {Cassa} from "./pages/settings/cassa/cassa";
import {
  SelezionaVariantiSingoloComponent
} from "./pages/seleziona-varianti-singolo/seleziona-varianti-singolo.component";
import {PagerDialogComponent} from "./pages/PagerDialogComponent/pagerDialogComponent.component";
import {UserSelectionComponent} from "./pages/select-user/select-user";
import {SchermoSecondarioComponent} from "./pages/schermo-secondario/schermo-secondario";
import {AddNoteDialogComponent} from "./pages/addNoteDialog/addNoteDialog.component";
import {EditPredefinedSconti} from "./pages/settings/impostazioniFidelity/dialog/edit-predefined-sconti.component";
import {DialogBuoniPasto} from "./pages/dialogPagamento/dialog/dialog-buoni-pasto.component";
import {PartialConfirmDialogComponent} from "./pages/cronologia/dialog/partial-confirm-dialog.component";
import {CronologiaInventarioComponent} from "./pages/cronologiaInventario/cronologia.component";
import {RevenueInputComponent} from "./pages/revenueInput/revenue-input.component";
import {AnalyticsDashboardComponent} from "./pages/analytics-dashboard/analytics-dashboard.component";
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ProductStatisticsComponent} from "./pages/product-statistics/product-statistics.component";
import {ReceiptsDashboardComponent} from "./pages/receipts-dashboard/receipts-dashboard.component";
import {ConfirmDialogComponent} from "./pages/ConfirmDialogData/confirmDialogData.component";
import {FidelityUsersComponent} from "./pages/users-fidelity/users-fidelity";
import {LevelDialogComponent} from "./pages/users-fidelity/level-dialog";
import {AlertDialogComponent} from "./pages/dialogPagamento/dialog/alert-dialog.component";
import {UserOrdersDialogComponent} from "./pages/users-fidelity/user-orders-dialog.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {KitchenMonitorComponent} from "./pages/kitchen-monitor/kitchen-monitor.component";


if (!firebase.apps.length) {
  firebase.initializeApp(environmentFirebase.firebase);
}


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): any {
    return new Hammer(element, {
      swipe: { direction: Hammer.DIRECTION_ALL }
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,

    NavBarComponent,
    NavBarCardComponent,
    NavbarReportComponent,
    NavbarImpostazioniComponent,


    IndietroCardComponent,
    Home,
    Magazzino,
    Ordini,
    Menu,
    ReceiptComponent,
    OrdiniCardComponent,

    CassaCamerieri,


    ReceiptOrderCardComponent,
    cardDettaglioScontrino,
    DettaglioScontrinoFinaleCard,
    MenuDetailCardComponent,
    SignIn,
    CategorieCard,
    MenuContainerComponent,
    MenuCardComponent,
    AggiuntaPiatto,
      RevenueInputComponent,

    Report,
    ReportPanoramica,
    ReportInventario,
    ReportIncassi,
    ReportRevenewLoss,

    SchermoCucina,

    EditMenu,
    TestOfflineModeComponent,
    tagComponent,


    //card interno inserisci ordine
    CategorieCard2,

    //card interno dopo aver selezionato categoria
    CategorieCard3,


    //long press directive
    LongPressDirective,
    tabCardComponent,
    EditCategories,


    InventoryComponent,
    EditMagazzino,
    EditInventario,


    Impostazioni,
    Tavoli,
    CassaBar,

    MenuDetailCardBarComponent,
    Account,
    AggiuntaPiattoTotem,
    TableNumberComponent,
    Fidelity,
    Ristorante,
    Personale,

    Preferiti,
    TavoloDialogComponent,
    DialogPagamento,


    NumericKeypadComponent,


    ChatComponent,
    ChatComponent2,

    ImpostazioniFidelity,
    ImpostazioniMenu,
    ImpostazioniRistoranti,


    EditSconti,
    Fatturazione,

    ReportProdotti,

    CassaOrdine,
    CassaOrdini,
      AzioniStampanteComponent,
      Stampanti,
    SnackbarComponent,
      DialogVariantiComponent,
    SliderImages,
    SelezionaVarianti,
      EditMenus,
      Dashboard,
      CarrelloMagazzino,
      AddOrdineMagazzino,
      OrdiniMagazzino,
      CronologiaMagazzinoCardComponent,
      NotificheDashboardCardComponent,
      Cronologia,
      UpsellingComponent,


    Calendario,
    OrdiniCardMagazzinoComponent,
    OrderDetailPopupComponent,

    SliderTotem,

    //dialogs dipendenti
    AddEmployeeDialogComponent,
    AddRoleDialogComponent,

      //dialogs ristoranti
    AddGroupDialogComponent,
    AddRestaurantDialogComponent,
    PriceListsComponent,
      MenuDetailComponent,
      DeleteOrderDialogComponent,


    NotificheComponent,

    AddWorkstationDialogComponent,

    RevenueComponent,

    EditSliderTotemComponent,
    EditSliderImageComponent,
      SelezionaVariantiSingoloComponent,
      PagerDialogComponent,
      AddNoteDialogComponent,

    Cassa,
      CronologiaInventarioComponent,


    UserSelectionComponent,

    SchermoSecondarioComponent,


    EditPredefinedSconti,
    DialogBuoniPasto,

    PartialConfirmDialogComponent,
      AnalyticsDashboardComponent,
      ProductStatisticsComponent,
      ReceiptsDashboardComponent,
      ConfirmDialogComponent,
    FidelityUsersComponent,

    LevelDialogComponent,
      AlertDialogComponent,



    UserOrdersDialogComponent,
      KitchenMonitorComponent

  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    AppRoutingModule,

    MatSnackBarModule,
    AngularFireModule.initializeApp(environmentFirebase.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgbModule,
    MatTableModule,
    MatTabsModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    NgxElectronModule,
    MatCardModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    HammerModule,
    DragDropModule,
    CommonModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    SharedPipesModule,
    HttpClientModule,

    MatExpansionModule,
    MatProgressSpinnerModule

  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    DataServiceGenerico,
    MatDatepickerModule,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },

  ],
  bootstrap: [AppComponent],
  entryComponents: [Menu],
})
export class AppModule { }