<div class="settings-container">
    <div class="settings-header">
        <h2>Modifica Prodotto</h2>
        <div class="header-actions">
            <button class="icon-button delete" *ngIf="item?.id" (click)="deleteItem()" title="Elimina" style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                elimina
            </button>
            <button class="icon-button delete" *ngIf="!item?.id" (click)="deleteItem()" title="Elimina" style="border: 1px solid rgb(255 102 94); color: rgb(255 102 94)">
                Annulla
            </button>
            <button class="icon-button save" (click)="saveChanges()" title="Salva" style="background-color: rgb(52 152 219); border: 1px solid rgb(52 152 219); color: white">
                salva
            </button>
        </div>
    </div>

    <div class="settings-content">
        <div class="settings-section">
            <div class="section-header">
                <h3>Dettagli Prodotto</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Nome Prodotto</div>
                <div class="setting-field">
                    <input [(ngModel)]="title" placeholder="Nome prodotto" class="custom-input">
                </div>
            </div>

            <!--div class="settings-row">
                <div class="setting-label">Prezzo <p style="color: red">(Deprecato usare LISTINI)</p></div>
                <div class="setting-field">
                    <input [(ngModel)]="price" placeholder="0.00" class="custom-input">
                </div>
            </div>
            <div class="settings-row">
                <div class="setting-label">IVA/Reparto <p style="color: red">(Deprecato usare LISTINI)</p></div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="reparto" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>
            </div-->
            <div class="settings-row">
                <div class="setting-label">Categoria</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedCategory" class="custom-input">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{category}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Immagine</div>
                <div class="setting-field image-upload">
                    <img *ngIf="selectedImage" [src]="selectedImage" alt="Selected Image" class="preview-image">
                    <div class="upload-actions">
                        <button class="action-button" *ngIf="!selectedImage" (click)="fileInput.click()">
                            Scegli immagine
                        </button>
                        <button class="elimina-button" *ngIf="selectedImage" (click)="removeImage()">
                            Elimina immagine
                        </button>
                        <input #fileInput type="file" (change)="onFileSelected($event)" style="display: none">
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Ingredienti</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedIngredients" multiple class="custom-input">
                        <mat-option *ngFor="let ingredient of ingredients" [value]="ingredient">
                            {{ingredient}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>


            <!-- Inserisci questa sezione dopo la sezione degli ingredienti nel tuo HTML -->
            <!-- Sezione Distinta Base -->
            <div class="settings-section">
                <div class="section-header">
                    <h3>Distinta Base</h3>
                </div>

                <!-- Form per aggiungere nuovo elemento -->
                <div class="settings-row">
                    <div class="setting-label">Tipo</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newBomItem.type" class="custom-input">
                            <mat-option *ngFor="let type of bomTypes" [value]="type.value">
                                {{type.label}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Campo condizionale basato sul tipo -->
                <div class="settings-row" *ngIf="newBomItem.type === 'product'">
                    <div class="setting-label">Prodotto Menu</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newBomItem.productId"
                                    placeholder="Seleziona prodotto"
                                    class="custom-input">
                            <mat-option *ngFor="let product of availableProducts"
                                        [value]="product.id"
                                        [disabled]="product.id === item?.id">
                                {{product.title}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row" *ngIf="newBomItem.type === 'external' || newBomItem.type === 'process'">
                    <div class="setting-label">{{newBomItem.type === 'external' ? 'Nome Prodotto' : 'Nome Lavorazione'}}</div>
                    <div class="setting-field">
                        <input [(ngModel)]="newBomItem.name"
                               [placeholder]="newBomItem.type === 'external' ? 'Nome del prodotto esterno' : 'Nome della lavorazione'"
                               class="custom-input">
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">Quantità e Unità</div>
                    <div class="setting-field" style="display: flex; gap: 10px;">
                        <input type="number"
                               [(ngModel)]="newBomItem.quantity"
                               placeholder="Quantità"
                               class="custom-input"
                               min="0.01"
                               step="0.01"
                               style="flex: 1;">

                        <mat-select [(ngModel)]="newBomItem.unit"
                                    class="custom-input"
                                    style="flex: 1;">
                            <mat-option *ngFor="let unit of unitsOfMeasure" [value]="unit.value">
                                {{unit.label}}
                            </mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">Note</div>
                    <div class="setting-field">
                        <input [(ngModel)]="newBomItem.notes"
                               placeholder="Note aggiuntive (opzionale)"
                               class="custom-input">
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-field">
                        <button class="action-button" (click)="addBomItem()">
                            Aggiungi alla Distinta Base
                        </button>
                    </div>
                </div>

                <!-- Lista degli elementi nella distinta base -->
                <!-- Replace your existing bill of materials table with this version -->
                <div class="settings-row" *ngIf="billOfMaterials.length > 0">
                    <div class="setting-field">
                        <table style="width: 100%; border-collapse: collapse;">
                            <thead>
                            <tr>
                                <th style="text-align: left; padding: 8px;">Tipo</th>
                                <th style="text-align: left; padding: 8px;">Nome</th>
                                <th style="text-align: right; padding: 8px;">Quantità</th>
                                <th style="text-align: center; padding: 8px;">Unità</th>
                                <th style="text-align: left; padding: 8px;">Note</th>
                                <th style="text-align: center; padding: 8px;">Azioni</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let bom of billOfMaterials; let i = index"
                                style="border-bottom: 1px solid rgba(0,0,0,0.12);">
                                <td style="padding: 8px;">
                                    {{getBomTypeLabel(bom.type)}}
                                </td>
                                <td style="padding: 8px;">
                                    {{getItemName(bom)}}
                                </td>
                                <td style="text-align: right; padding: 8px;">
                                    {{bom.quantity}}
                                </td>
                                <td style="text-align: center; padding: 8px;">
                                    {{getUnitLabel(bom.unit)}}
                                </td>
                                <td style="padding: 8px;">
                                    {{bom.notes}}
                                </td>
                                <td style="text-align: center; padding: 8px;">
                                    <button class="elimina-button"
                                            (click)="removeBomItem(i)"
                                            style="padding: 4px 8px; height: 35px;">
                                        Rimuovi
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="settings-row" *ngIf="billOfMaterials.length === 0">
                    <div class="setting-field" style="text-align: center; padding: 20px; color: #666;">
                        Nessun componente nella distinta base
                    </div>
                </div>
            </div>


            <div class="settings-row">
                <div class="setting-label">Allergeni</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedAllergens" multiple class="custom-input">
                        <mat-option *ngFor="let allergen of allergens" [value]="allergen">
                            {{allergen}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <!-- Aggiungi questo dopo il campo "Nome Prodotto" nel menu-detail.component.html -->
        <div class="settings-row">
            <div class="setting-label">Codice Barcode</div>
            <div class="setting-field">
                <input [(ngModel)]="barcode"
                       placeholder="Scansiona o inserisci il codice"
                       class="custom-input"
                       #barcodeInput
                       (focus)="onBarcodeFocus()">
            </div>
        </div>


        <!-- Livelli di Imballaggio Section -->
        <div class="settings-section">
            <div class="section-header">
                <h3>Livelli di imballaggio</h3>
            </div>
            <div class="actions-container">
                <div class="action-item">
                    <span>1</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl'"
                                [checked]="isLvl1"
                                (click)="onMenuChangeLevel1()"
                        >
                        <label [for]="'checkbox-lvl'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>2</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl2'"
                                [checked]="isLvl2"
                                (click)="onMenuChangeLevel2()"
                        >
                        <label [for]="'checkbox-lvl2'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>3</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl3'"
                                [checked]="isLvl3"
                                (click)="onMenuChangeLevel3()"
                        >
                        <label [for]="'checkbox-lvl3'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>

                <div class="action-item">
                    <span>4</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-lvl4'"
                                [checked]="isLvl4"
                                (click)="onMenuChangeLevel4()"
                        >
                        <label [for]="'checkbox-lvl4'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- Primo Livello -->
        <div class="settings-row" *ngIf="(isLvl1 || isLvl2 || isLvl3 || isLvl4)">
            <div class="setting-label">Primo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex; justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[0].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[0].um">
                    </div>
                </div>
            </div>
        </div>

        <!-- Secondo Livello -->
        <div class="settings-row" *ngIf="(isLvl2 || isLvl3 || isLvl4)">
            <div class="setting-label">Secondo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex; justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[1].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[1].um">
                    </div>
                </div>
            </div>
        </div>

        <!-- Terzo Livello -->
        <div class="settings-row" *ngIf="(isLvl3 || isLvl4)">
            <div class="setting-label">Terzo Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex; justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[2].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[2].um">
                    </div>
                </div>
            </div>
        </div>

        <!-- Quarto Livello -->
        <div class="settings-row" *ngIf="isLvl4">
            <div class="setting-label">Quarto Livello</div>
            <div class="setting-field">
                <div class="chart-container" style="display: flex; justify-content: space-around;">
                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">Quantità</div>
                        <input placeholder="Quantità" class="custom-input" [(ngModel)]="levels[3].quantita">
                    </div>

                    <div style="width: 30%; margin-right: 3%;">
                        <div class="setting-label">UM</div>
                        <input placeholder="Unità di Misura" class="custom-input" [(ngModel)]="levels[3].um">
                    </div>
                </div>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Varianti</h3>
            </div>
            <div class="variants-container">
                <mat-select [(ngModel)]="selectedVariantIds" multiple class="custom-input">
                    <mat-option *ngFor="let variant of variants" [value]="variant.id">
                        {{variant.name}}
                    </mat-option>
                </mat-select>
            </div>
        </div>

        <!-- Aggiungi dopo la sezione delle varianti esistente -->
        <div class="settings-row" style="margin-top: 16px">
            <div class="setting-field">
                <button class="action-button" (click)="addCustomVariant()" *ngIf="!showNewVariantForm">
                    + Aggiungi Nuova Variante
                </button>
            </div>
        </div>

        <!-- Form per nuova variante -->
        <div class="variant-form" *ngIf="showNewVariantForm">
            <div class="settings-row">
                <div class="setting-label">Nome Variante</div>
                <div class="setting-field">
                    <input [(ngModel)]="newVariant.name" placeholder="Nome variante" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Prezzo Base</div>
                <div class="setting-field">
                    <input type="number" [(ngModel)]="newVariant.price" step="0.10" class="custom-input">
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Obbligatoria</div>
                <div class="setting-field">
                    <div class="checkbox-wrapper-26">
                        <input type="checkbox" [id]="'checkbox-required'" [(ngModel)]="newVariant.isRequired">
                        <label [for]="'checkbox-required'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="settings-row">
                <div class="setting-label">Massimo Selezionabili</div>
                <div class="setting-field">
                    <input type="number" [(ngModel)]="newVariant.maxSelections" min="1" class="custom-input">
                </div>
            </div>

            <!-- Opzioni della variante -->
            <div class="variant-options">
                <h4>Opzioni</h4>
                <div class="option-list">
                    <div class="option-item" *ngFor="let option of newVariant.options; let i = index">
                        <input [(ngModel)]="option.name" placeholder="Nome opzione" class="custom-input">
                        <input type="number" [(ngModel)]="option.priceModifier" step="0.10" placeholder="Modifica prezzo" class="custom-input">
                        <button class="elimina-button" (click)="removeOptionFromVariant(i)">
                            Rimuovi
                        </button>
                    </div>
                </div>
                <button class="action-button" (click)="addOptionToNewVariant()">
                    + Aggiungi Opzione
                </button>
            </div>

            <div class="button-group">
                <button class="action-button" (click)="saveNewVariant()">Salva Variante</button>
                <button class="elimina-button" (click)="cancelNewVariant()">Annulla</button>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Listino Prezzi</h3>
            </div>

            <div class="settings-row">
                <div class="setting-label">Listino</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedPriceListId"
                                (selectionChange)="onPriceListChange($event)"
                                class="custom-input">
                        <mat-option *ngFor="let list of priceLists" [value]="list.id">
                            {{list.name}}
                        </mat-option>
                        <mat-option (click)="isCreatingNewList = true">
                            + Crea nuovo listino
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="price-grid" *ngIf="selectedPriceListId && !isCreatingNewList">
                <div class="price-type">
                    <label>Prezzo Counter</label>
                    <input type="number" [(ngModel)]="currentPrices.counter.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.counter.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>

                <div class="price-type">
                    <label>Prezzo Table</label>
                    <input type="number" [(ngModel)]="currentPrices.table.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.table.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>

                <div class="price-type">
                    <label>Prezzo Takeaway</label>
                    <input type="number" [(ngModel)]="currentPrices.takeaway.price" class="custom-input" step="0.10">
                    <mat-select [(ngModel)]="currentPrices.takeaway.vatId" class="custom-input">
                        <mat-option value="1">22% IVA</mat-option>
                        <mat-option value="2">10% IVA</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>

        <div class="new-price-list-form" *ngIf="isCreatingNewList">
            <div class="settings-row">
                <div class="setting-label">Nome Listino</div>
                <div class="setting-field">
                    <input [(ngModel)]="newList.name" placeholder="Nome listino" class="custom-input">
                </div>
            </div>

            <div class="settings-section">
                <h4>Impostazioni IVA</h4>
                <div class="settings-row">
                    <div class="setting-label">IVA Counter</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.counter.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">IVA Table</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.table.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="setting-label">IVA Takeaway</div>
                    <div class="setting-field">
                        <mat-select [(ngModel)]="newList.settings.takeaway.vatId" class="custom-input">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>

            <div class="button-group">
                <button class="action-button" (click)="saveNewPriceList()">Salva Listino</button>
                <button class="elimina-button" (click)="cancelNewList()">Annulla</button>
            </div>
        </div>

        <div class="settings-section">
            <div class="section-header">
                <h3>Impostazioni</h3>
            </div>

            <!-- Nel tuo menu-detail.component.html -->
            <div class="settings-row">
                <div class="setting-label">Destinazione di stampa</div>
                <div class="setting-field">
                    <mat-select [(ngModel)]="selectedPrintDestination"
                                class="custom-input"
                                [panelClass]="'custom-select-panel'"
                                >
                        <mat-option *ngFor="let printer of availablePrinters" [value]="printer.name">
                            {{printer.label}}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="actions-container">
                <!--div class="action-item">
                    <span class="action-label">Menu</span>
                    <div class="checkbox-wrapper-26">
                        <input type="checkbox" [id]="'checkbox-menu'" [(ngModel)]="isMenuChecked">
                        <label [for]="'checkbox-menu'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div-->


                <div class="action-item">
                    <span class="action-label">Disponibile</span>
                    <div class="checkbox_item citem_1">
                        <label class="checkbox_wrap">
                            <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isDisponibileChecked">
                            <span class="checkbox_mark"></span>
                        </label>
                    </div>
                </div>
                <div class="action-item">
                    <span class="action-label">Disponibile Delivery</span>
                    <div class="checkbox_item citem_1">
                        <label class="checkbox_wrap">
                            <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isDisponibileDeliveryChecked">
                            <span class="checkbox_mark"></span>
                        </label>
                    </div>
                </div>
            </div>

            <!-- Sostituisci la sezione menu esistente con questa -->
            <div class="menu-config" *ngIf="isMenuChecked">
                <div class="settings-section">
                    <div class="section-header">
                        <h3>Configurazione Menu</h3>
                        <button class="action-button" (click)="addMenuSection()" *ngIf="menuConfig.sections.length < 2">
                            + Aggiungi Sezione
                        </button>
                    </div>

                    <div class="menu-sections">
                        <div class="menu-section" *ngFor="let section of menuConfig.sections; let i = index">
                            <div class="menu-section-content">
                                <div class="menu-section-row">
                                    <div class="menu-section-label">Nome Sezione</div>
                                    <div class="menu-section-field">
                                        <input [(ngModel)]="section.title"
                                               placeholder="Nome sezione"
                                               class="menu-input">
                                        <button class="menu-remove-button" (click)="removeMenuSection(i)">
                                            Rimuovi
                                        </button>
                                    </div>
                                </div>

                                <div class="menu-section-row">
                                    <div class="menu-section-label">Categorie Menu</div>
                                    <div class="menu-section-field">
                                        <mat-select [(ngModel)]="section.categories" multiple class="menu-select">
                                            <mat-option *ngFor="let category of categories" [value]="category">
                                                {{category}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="menu-section-row">
                                    <div class="menu-section-label">Prodotti Menu</div>
                                    <div class="menu-section-field">
                                        <mat-select [(ngModel)]="section.products" multiple class="menu-select">
                                            <mat-option *ngFor="let product of menuProducts" [value]="product.id">
                                                {{product.title}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Prezzi menu per listino -->
                    <div class="settings-section">
                        <div class="section-header">
                            <h3>Prezzi Menu</h3>
                        </div>

                        <div class="settings-row">
                            <div class="setting-label">Listino</div>
                            <div class="setting-field">
                                <mat-select [(ngModel)]="selectedMenuPriceList"
                                            (selectionChange)="onMenuPriceListChange($event)"
                                            class="custom-input">
                                    <mat-option *ngFor="let list of priceLists" [value]="list.id">
                                        {{list.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>

                        <div class="price-grid" *ngIf="selectedMenuPriceList">
                            <div class="price-type">
                                <label>Prezzo Menu Counter</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].counter.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].counter.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>

                            <div class="price-type">
                                <label>Prezzo Menu Table</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].table.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].table.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>

                            <div class="price-type">
                                <label>Prezzo Menu Takeaway</label>
                                <input type="number"
                                       [(ngModel)]="menuPrices[selectedMenuPriceList].takeaway.price"
                                       class="custom-input"
                                       step="0.10">
                                <mat-select [(ngModel)]="menuPrices[selectedMenuPriceList].takeaway.vatId" class="custom-input">
                                    <mat-option value="1">22% IVA</mat-option>
                                    <mat-option value="2">10% IVA</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>