// chat.component.ts
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import firebase from "firebase";
import {CommonService} from "../../../shared/services/firestore/common.service";
import {UserDataService} from "../../../shared/services/firestore/userData.service";


interface ChatMessage {
    id: string;
    text: string;
    sender: string;
    timestamp: firebase.firestore.Timestamp;
    seenBy: string[];
}

interface Chat {
    id: string;
    participants: string[];
    lastMessage?: string;
    lastMessageTimestamp?: firebase.firestore.Timestamp;
}

@Component({
    selector: 'app-chat',
    template: `


        <div class="chat-container">
            <div class="chat-list">
                <div class="search-bar">
                    <input [(ngModel)]="searchQuery" (keyup.enter)="createNewChat()" placeholder="Cerca o crea nuova chat">
                </div>
                <div *ngFor="let chat of chats$ | async" (click)="selectChat(chat.id)"
                     [class.active]="chat.id === selectedChatId">
                    <div class="chat-item">
                        <span>{{ getChatName(chat) }}</span>
                        <span class="last-message">{{ chat.lastMessage }}</span>
                        <span class="timestamp">{{ chat.lastMessageTimestamp?.toDate() | date:'HH:mm' }}</span>
                    </div>
                </div>
            </div>
            <div class="chat-messages" *ngIf="selectedChatId">
                <ng-container *ngIf="(messages$ | async) as messages; else loading">
                    <div *ngFor="let message of messages"
                         [ngClass]="{'message-right': message.sender === currentUser, 'message-left': message.sender !== currentUser}">
                        <div class="message-content" [style.background-color]="getUserColor(message.sender)">
                            {{ message.text }}
                            <span class="checkmark" *ngIf="message.seenBy.includes(currentUser)">✓</span>
                        </div>
                        <div class="message-info">
                            <span class="sender-id">{{ message.sender }}</span>
                            <span class="timestamp">{{ message.timestamp.toDate() | date:'HH:mm' }}</span>
                        </div>
                    </div>
                    <div *ngIf="messages.length === 0">Nessun messaggio. Inizia la conversazione!</div>
                </ng-container>
                <ng-template #loading>
                    <div>Caricamento messaggi...</div>
                </ng-template>
                <div class="input-container">
                    <input [(ngModel)]="newMessage" (keyup.enter)="sendMessage()" placeholder="Scrivi un messaggio...">
                    <button (click)="sendMessage()" [disabled]="!newMessage.trim()">Invia</button>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .chat-container {
            display: flex;
            height: 100vh;
            font-family: Arial, sans-serif;
            background-color: #0F0F0F;
            color: #FFFFFF;
        }
        .chat-list {
            width: 300px;
            border-right: 1px solid #2C2C2C;
            overflow-y: auto;
        }
        .search-bar {
            padding: 10px;
        }
        .search-bar input {
            width: 100%;
            padding: 8px;
            border: none;
            border-radius: 20px;
            background-color: #2C2C2C;
            color: #FFFFFF;
        }
        .chat-item {
            padding: 10px;
            border-bottom: 1px solid #2C2C2C;
            cursor: pointer;
        }
        .chat-item:hover, .chat-item.active {
            background-color: #2C2C2C;
        }
        .last-message, .timestamp {
            font-size: 0.8em;
            color: #888;
        }
        .timestamp {
            float: right;
        }
        .chat-messages {
            /*
            flex: 1;
            display: flex;
            */
            width: 400px;
            flex-direction: column;
            padding: 10px;
        }
        .message-left, .message-right {
            max-width: 70%;
            padding: 8px 12px;
            margin: 5px;
            border-radius: 15px;
            position: relative;
        }
        .message-left {
            align-self: flex-start;
            background-color: #2C2C2C;
        }
        .message-right {
            margin-left: auto;
            align-self: flex-end;
            background-color: #0D7963;
        }
        .message-content {
            position: relative;
        }
        .checkmark {
            position: absolute;
            right: -15px;
            bottom: -5px;
            font-size: 0.8em;
            color: #4FC3F7;
        }
        .message-info {
            font-size: 0.75em;
            color: #888;
            margin-top: 4px;
        }
        .input-container {
            display: flex;
            padding: 10px;
            background-color: #2C2C2C;
            position: fixed;
            bottom: 40px;
            width:400px; margin-left: -10px
        }
        .input-container input {
            flex: 1;
            padding: 10px;
            border: none;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #3C3C3C;
            color: #FFFFFF;
        }
        .input-container button {
            padding: 10px 20px;
            background-color: #0D7963;
            color: white;
            border: none;
            border-radius: 20px;
            cursor: pointer;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent2 implements OnInit {

    chats$: Observable<Chat[]>;
    messages$: Observable<ChatMessage[]>;
    private userSubscription: firebase.Unsubscribe;


    newMessage: string = '';
    currentUser: string = 'Matteo Giuglianelli';
    userColors: {[key: string]: string} = {};
    searchQuery: string = '';
    selectedChatId: string | null = null;

    constructor(private commonService: CommonService,
                private cdr: ChangeDetectorRef,
                private userService: UserDataService
    ) {}

    ngOnInit() {
        this.userSubscription = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.retrieveUserData();
            } else {
                // Gestione utente non autenticato
            }
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            //this.userSubscription.unsubscribe();
        }
    }

    user = {}
    retrieveUserData(): void {

        this.currentUser = firebase.auth().currentUser.email;
        if (this.currentUser !== undefined) {
            this.initializeChats();
        }
    }

    initializeChats() {
        this.chats$ = this.commonService.getChat21(this.currentUser);
        this.messages$ = this.commonService.getChat2();
        this.cdr.detectChanges();
    }

    selectChat(chatId: string) {
        console.log('Chat selezionata:', chatId);
        this.selectedChatId = chatId;
        this.commonService.subjecta(chatId);
        this.cdr.detectChanges();
    }

    createNewChat() {
        if (this.searchQuery.trim() === '') return;

        const newChat = {
            participants: [this.currentUser, this.searchQuery.trim()],
        };

        this.commonService.createChat(newChat).then(docRef => {
            console.log('Nuova chat creata:', docRef.id);
            this.selectChat(docRef.id);
            this.searchQuery = '';
            this.cdr.detectChanges();
        });
    }


    getChatName(chat: Chat): string {
        return chat.participants.filter(p => p !== this.currentUser).join(', ');
    }

    sendMessage() {
        if (this.newMessage.trim() === '' || !this.selectedChatId) return;

        const message = {
            text: this.newMessage,
            sender: this.currentUser,
            timestamp: firebase.firestore.Timestamp.now(),
            seenBy: [this.currentUser]
        };

        this.commonService.sendChat(this.selectedChatId, message).then(() => {

            this.commonService.cose(this.selectedChatId, this.newMessage, message)
        });

        this.newMessage = '';
    }

    getUserColor(userId: string): string {
        if (!this.userColors[userId]) {
            this.userColors[userId] = this.getRandomColor();
        }
        return this.userColors[userId];
    }

    getRandomColor(): string {
        return '#' + Math.floor(Math.random()*16777215).toString(16);
    }
}