import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import firebase from 'firebase/app';
import { UserDataService } from '../../shared/services/firestore/userData.service';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";

interface MenuItem {
    id: string;
    title: string;
    category: string;
    price: string;
    image_link: string;
    disponibilita: boolean;
}

interface CategoryUpselling {
    category: string;
    products: string[]; // array di ID dei prodotti
}

@Component({
    selector: 'app-upselling',
    templateUrl: './upselling.component.html',
    styleUrls: ['./upselling.component.css']
})
export class UpsellingComponent implements OnInit {
    menuBello: MenuItem[] = [];
    categorie: string[] = [];
    menu: any[] = [];
    sezione: number = 0;
    selectedCategory: string = '';
    upsellingData: CategoryUpselling[] = [];
    allProducts: MenuItem[] = [];
    productSezione: number = 0;

    constructor(
        private router: Router,
        private userService: UserDataService,
        private _location: Location,
        private dataServiceGenerico: DataServiceGenerico
    ) {}

    ngOnInit(): void {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.retrieveMenu();
                this.userService.emitter.subscribe(() => {
                    this.retrieveMenu();
                });
            }
        });
    }

    retrieveMenu(): void {
        const db = firebase.firestore();
        const restaurantName = this.dataServiceGenerico.getRistorante();
        const menuRef = db.collection('menu_test').doc(restaurantName);

        menuRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                this.menuBello = data.items || [];
                this.allProducts = [...this.menuBello];

                // Filtra i prodotti con immagine
                this.menuBello = this.menuBello.filter(elemento =>
                    elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0
                );

                // Estrai categorie uniche
                this.categorie = this.filterUniqueCategories(this.menuBello);

                // Organizza il menu per categorie
                this.menu = this.categorie.map(categoria => ({
                    testo: categoria,
                    menu: this.menuBello.filter(prodotto => prodotto.category === categoria)
                }));

                // Inizializza upsellingData se non esiste
                if (!data.upsellingData) {
                    this.upsellingData = this.categorie.map(category => ({
                        category,
                        products: []
                    }));
                    this.saveUpsellingData();
                } else {
                    this.upsellingData = data.upsellingData;
                }

                this.selectedCategory = this.categorie[0];
            }
        }).catch(error => {
            console.error('Errore nel recupero del menu:', error);
        });
    }

    saveUpsellingData(): void {
        const db = firebase.firestore();
        const restaurantName = this.dataServiceGenerico.getRistorante();
        const menuRef = db.collection('menu_test').doc(restaurantName);

        menuRef.update({
            upsellingData: this.upsellingData
        }).then(() => {
            console.log('Dati upselling salvati con successo');
        }).catch(error => {
            console.error('Errore nel salvataggio dei dati upselling:', error);
        });
    }

    toggleUpselling(piatto: MenuItem): void {
        const categoryData = this.upsellingData.find(data =>
            data.category === this.selectedCategory
        );

        if (categoryData) {
            const index = categoryData.products.indexOf(piatto.id);
            if (index > -1) {
                categoryData.products.splice(index, 1);
            } else {
                categoryData.products.push(piatto.id);
            }
            this.saveUpsellingData();
        }
    }

    isUpsellingProduct(piatto: MenuItem): boolean {
        const categoryData = this.upsellingData.find(data =>
            data.category === this.selectedCategory
        );
        return categoryData ? categoryData.products.includes(piatto.id) : false;
    }

    filterUniqueCategories(menuItems: MenuItem[]): string[] {
        const seenCategories = new Set();
        return menuItems
            .filter(item => {
                if (seenCategories.has(item.category)) {
                    return false;
                }
                seenCategories.add(item.category);
                return true;
            })
            .map(x => x.category)
            .filter(element => element !== undefined);
    }

    width(): number {
        return 100 / this.categorie.length;
    }

    onCategorySelect(category: string): void {
        this.selectedCategory = category;
        this.sezione = this.categorie.indexOf(category);
    }

    getUpsellingProductsForCategory(category: string): MenuItem[] {
        const categoryData = this.upsellingData.find(data => data.category === category);
        if (categoryData) {
            return categoryData.products.map(productId =>
                this.allProducts.find(product => product.id === productId)
            ).filter(product => product !== undefined);
        }
        return [];
    }
}