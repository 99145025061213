import {Component, OnInit, HostListener, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {IpcService} from "../../shared/services/ipc.service";
import firebase from 'firebase/app';
import {combineLatest, from, Observable, of, throwError} from "rxjs";
import {catchError, switchMap, tap} from "rxjs/operators";


@Component({
  selector: 'navbar',
  styles: [`
    .menuItem{
      cursor: pointer !important;
    }

    .menuItem:hover {
      background-color: rgba(181,181,181,0.2) !important;
    }

    .sidebar {
      margin: 0;
      padding: 0;
      margin-top: 1%;
      margin-left: 1%;
      width: 220px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      height: 96%;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
      transition: all 0.3s ease;
      z-index: 1000;
    }

    .closed {
      width: 70px !important;
    }

    .mobile-navbar {
      width: 100% !important;
      height: auto !important;
      position: relative !important;
      margin: 0 !important;
      border-radius: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mobile-menu {
      display: none;
      width: 100%;
      text-align: center;
      padding: 10px 0;
      background-color: white;
      border-bottom: 1px solid #e3e2e2;
    }

    .menu-icon {
      display: none;
      font-size: 24px;
      padding: 10px;
      cursor: pointer;
    }

    /* Sidebar links */
    .sidebar a {
      display: block;
      color: black;
      padding: 16px;
      text-decoration: none;
    }

    .sidebar b {
      display: block;
      color: #E65934;
      text-decoration: none;
      width: 220px;
      text-align: center;
      padding-top: 15%;
      padding-bottom: 30%;
      font-size: 30px;
    }

    /* Active/current link */
    .sidebar a.active {
      background-color: #FBFBFB;
      color: white;
    }

    /* Links on mouse-over */
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white;
    }

    /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
    div.content {
      margin-left: 200px;
      padding: 1px 16px;
      height: 1000px;
    }

    .bottom-elements {
      position: absolute;
      bottom: 0;
      margin-bottom: 7.5%;
      display: flex;
      width: 100%;
    }

    .block {
      width: calc(100% - 20px);
      height: 65px;
      margin: 2px 10px;
      display: inline-flex;
      border-radius: 20px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 580;
      color: #969696;
    }

    .cardSVG{
      margin-left: 10%;
      margin-bottom: 5px;
    }

    .selected {
      font-weight: 600;
      background-color: rgba(181,181,181,0.2) !important;
      color: black;
    }

    .imgSelected{
      filter: brightness(0%);
    }

    .notSelected {
      color: #969696;
      fill: #969696;
    }

    .restaurant-select {
      width: calc(100% - 20px);
      margin: 10px;
      padding: 8px;
      border-radius: 10px;
      border: 1px solid #e3e2e2;
      background-color: white;
      color: #969696;
      font-size: 14px;
      cursor: pointer;
      outline: none;
    }

    .restaurant-select:hover {
      border-color: #b5b5b5;
    }

    .restaurant-select:focus {
      border-color: #E65934;
      color: black;
    }

    .restaurant-container {
      margin-bottom: 20px;
    }

    .toggle-button {
      display: none;
      position: fixed;
      bottom: 20px;
      right: 20px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #E65934;
      color: white;
      border: none;
      box-shadow: 0 2px 10px rgba(0,0,0,0.2);
      z-index: 1001;
      cursor: pointer;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
    }

    /* Media queries for responsiveness */
    @media screen and (max-width: 992px) {
      .sidebar {
        width: 70px;
      }
      .sidebar b {
        font-size: 24px;
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .sidebar .menuItem div {
        display: none;
      }
      .toggle-button {
        display: block;
      }
    }

    /* On screens that are less than 768px wide */
    @media screen and (max-width: 768px) {
      .sidebar {
        width: 0;
        border: none;
        opacity: 0;
      }
      .sidebar.mobile-open {
        width: 220px;
        opacity: 1;
        border: 1px solid #e3e2e2;
      }
      .sidebar.mobile-open .menuItem div {
        display: block;
      }
      .toggle-button {
        display: block;
      }
    }

    /* Small screens - collapse into a hamburger menu */
    @media screen and (max-width: 480px) {
      .sidebar.mobile-open {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        z-index: 1100;
      }
    }

    .reversed-a {
      display: inline-block;
      transform: rotate(180deg);
    }
  `],
  template: `
    <button class="toggle-button" (click)="toggleMobileMenu()">
      <span>≡</span>
    </button>

    <div class="sidebar shadow-sm" [ngClass]="{'closed': !mobileOpen && aperto === false, 'mobile-open': mobileOpen}">
      <b>FILL<span class="reversed-a">A</span>PP</b>
      
      <!-- Admin -->
      <div *ngIf="hasPermessoForItem('admin') && hasPermission('Schermata Admin')" class="menuItem" [ngClass]="{'selected': this.posizione === 12, 'block': true}" (click)="cambiaRoute('admin')">
        <img [ngClass]="{'imgSelected': this.posizione === 12}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-dashboard-100.png?alt=media&token=487b051a-cbb5-4609-b0ba-b3c971ed6951" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Admin</div>
      </div>

      <!-- Dashboard -->
      <div *ngIf="hasPermessoForItem('dashboard') && hasPermission('Dashboard') " class="menuItem" [ngClass]="{'selected': this.posizione === 8, 'block': true}" (click)="cambiaRoute('dashboard')">
        <img [ngClass]="{'imgSelected': this.posizione === 8}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-dashboard-100.png?alt=media&token=487b051a-cbb5-4609-b0ba-b3c971ed6951" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Dashboard</div>
      </div>

      <!-- Reportistica -->
      <div *ngIf="hasPermessoForItem('stats') && hasPermission('Reportistica')" class="menuItem" [ngClass]="{'selected': this.posizione === 5, 'block': true}" (click)="cambiaRoute('stats')">
        <img [ngClass]="{'imgSelected': this.posizione === 5}" src="assets/images/icons8-slice-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Reportistica</div>
      </div>

      <!-- Anagrafica -->
      <div *ngIf="hasPermessoForItem('menu') && hasPermission('Anagrafica')" class="menuItem" [ngClass]="{'selected': this.posizione === 3, 'block': true}" (click)="cambiaRoute('menu')">
        <img [ngClass]="{'imgSelected': this.posizione === 3}" src="assets/images/icons8-menu-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Anagrafica</div>
      </div>

      <!-- Ordini -->
      <div *ngIf="hasPermessoForItem('ordini-magazzino') && hasPermission('Cronologia Ordini')" class="menuItem" [ngClass]="{'selected': this.posizione === 9, 'block': true}" (click)="cambiaRoute('ordini-magazzino')">
        <img [ngClass]="{'imgSelected': this.posizione === 9}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-order-96.png?alt=media&token=55a69c2e-a05d-4115-bd85-fa4454094c11" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Ordini </div>
      </div>

      <!-- Fidelity -->
      <div *ngIf="hasPermessoForItem('utentiFidelity') && hasPermission('Fidelity')" class="menuItem" [ngClass]="{'selected': this.posizione === 11, 'block': true}" (click)="cambiaRoute('utentiFidelity')">
        <img [ngClass]="{'imgSelected': this.posizione === 11}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-users-96.png?alt=media&token=3cc7d0ab-d649-45cd-bcc3-242158077a4b" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Fidelity</div>
      </div>

      <!-- Cassa -->
      <div *ngIf="hasPermessoForItem('cassa')" class="menuItem" [ngClass]="{'selected': this.posizione === 4, 'block': true}" (click)="navigateToCassa()" >
        <img [ngClass]="{'imgSelected': this.posizione === 4}" src="assets/images/icons8-cash-register-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
        <div style="padding-left: 25px">Cassa</div>
      </div>

      <!-- Impostazioni (sempre disponibile) -->
      <div class="bottom-elements" *ngIf="hasPermission('impostazioni')">
        <div class="menuItem" [ngClass]="{'selected': this.posizione === 7, 'block': true}" (click)="cambiaRoute('impostazioni')" >
          <img [ngClass]="{'imgSelected': this.posizione === 7}" src="assets/images/icons8-setting-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">
          <div style="padding-left: 25px">Impostazioni</div>
        </div>
      </div>
    </div>
  `
})
export class NavBarComponent implements OnInit, OnDestroy {
  aperto = true;
  posizione: any;
  availableRestaurants: any[] = [];
  selectedRestaurant: string = '';
  mobileOpen = false;
  screenWidth: number;



  // Nuove proprietà per i permessi
  permessiRistorante: string[] = [];
  permessiUnsubscribe: () => void;

  // Mapping tra item del menu e permessi richiesti
  private menuPermessiMap = {
    'admin': 'Schermata Admin',
    'dashboard': 'Dashboard',
    'stats': 'Reportistica',
    'menu': 'Anagrafica',
    'ordini-magazzino': 'Cronologia Ordini',
    'utentiFidelity': 'Gestione fidelity',
    'cassa': 'Cassa',
    // Impostazioni è sempre disponibile, non necessita di permessi
  };

  // Metodo per controllare se un item dovrebbe essere visualizzato
  hasPermessoForItem(itemKey: string): boolean {

    if(this.permessiRistorante.length == 0)
    {
      return true;
    }


    // Impostazioni è sempre disponibile
    if (itemKey === 'impostazioni') return true;

    // Per altri item, controlla se il permesso corrispondente esiste
    const permessoRichiesto = this.menuPermessiMap[itemKey];

    // Se non è definito un permesso per l'item, per sicurezza lo nascondiamo
    if (!permessoRichiesto) return false;


    // Controlla se il permesso esiste nell'array dei permessi
    return this.permessiRistorante.includes(permessoRichiesto);
  }


  hasPermission(permissionName: string): boolean {
    // Never filter 'Cassa' permission
    if (permissionName.toLowerCase() === 'cassa') {
      return true;
    }

    const userLocale = this.dataServiceGenerico.getUserLocale();
    if (!userLocale || !userLocale['permessi']) {
      return false;
    }

    // List of navbar-related permissions
    const navbarPermissions = [
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity'
    ];

    // Case-insensitive permission check
    const hasPermission = userLocale['permessi'].some(
        permission => permission.toLowerCase() === permissionName.toLowerCase()
    );

    // If it's a navbar permission, check if it exists in user permissions
    if (navbarPermissions.includes(permissionName)) {
      return hasPermission;
    }

    // For all other permissions, return the normal check result
    return hasPermission;
  }



  // Gestione della cache dei permessi
  private getPermessiFromCache(ristoranteId: string): string[] | null {
    const cachedData = localStorage.getItem('navbar_permessi_cache');

    if (!cachedData) return null;

    const cacheItem = JSON.parse(cachedData);

    // Verifica che i dati in cache siano per il ristorante corrente
    if (cacheItem.ristoranteId !== ristoranteId) return null;

    // Verifica della validità temporale (30 minuti)
    const cacheDuration = 30 * 60 * 1000; // 30 minuti in millisecondi
    const now = new Date().getTime();
    if (now - cacheItem.timestamp > cacheDuration) {
      localStorage.removeItem('navbar_permessi_cache');
      return null;
    }

    return cacheItem.permessi;
  }

  private savePermessiToCache(ristoranteId: string, permessi: string[]): void {
    const cacheItem = {
      ristoranteId,
      permessi,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('navbar_permessi_cache', JSON.stringify(cacheItem));
  }

// Carica i permessi dal database
  private loadPermessi(ristoranteId: string): void {
    // Prima controlla la cache


    // Se non ci sono dati in cache, carica da Firestore
    const db = firebase.firestore();

    // Cancella il vecchio listener se esiste
    if (this.permessiUnsubscribe) {
      this.permessiUnsubscribe();
    }

    // Crea un nuovo listener
    this.permessiUnsubscribe = db.collection('ristoranti').doc(ristoranteId)
        .onSnapshot((doc) => {
          if (doc.exists && doc.data().permessi) {
            const permessi = doc.data().permessi;
            this.permessiRistorante = permessi;
            this.savePermessiToCache(ristoranteId, permessi);
            console.log('Permessi navbar aggiornati:', permessi);
          } else {
            this.permessiRistorante = [];
            console.log('Nessun permesso trovato per questo ristorante');
          }
        }, (error) => {
          console.error('Errore nel recupero dei permessi:', error);
          this.permessiRistorante = [];
        });
  }

  private readonly IMAGE_NAMES = [
    'icons8-setting-100.png',
    'icons8-cash-register-100.png',
    'icons8-order-96.png',
    'icons8-menu-100.png',
    'icons8-product-100.png',
    'icons8-slice-90.png',
    'icons8-edit-90.png',
  ];

  constructor(
      private router: Router,
      public authService: AuthService,
      private dataServiceGenerico: DataServiceGenerico,
      private ipcService: IpcService,
  ) {
    router.events.subscribe((val) => {
      if ('url' in val) {
        if (val.url.includes('home')) {
          this.posizione = 0;
        }
        if (val.url.includes('magazzino')) {
          this.posizione = 1;
        }
        if (val.url.includes('ordini')) {
          this.posizione = 2;
        }
        if (val.url.includes('menu')) {
          this.posizione = 3;
        }
        if (val.url.includes('cassa')) {
          this.posizione = 4;
        }
        if (val.url.includes('stats')) {
          this.posizione = 5;
        }
        if (val.url.includes('costi')) {
          this.posizione = 6;
        }
        if (val.url.includes('impostazioni')) {
          this.posizione = 7;
        }
        if (val.url.includes('dashboard')) {
          this.posizione = 8;
        }
        if (val.url.includes('ordini-magazzino')) {
          this.posizione = 9;
        }
        if (val.url.includes('revenue')) {
          this.posizione = 10;
        }
        if (val.url.includes('utentiFidelity')) {
          this.posizione = 11;
        }
        if (val.url.includes('admin')) {
          this.posizione = 12;
        }
      }

      // Chiudi la navbar mobile quando navighi su schermi piccoli
      if (this.screenWidth <= 768) {
        this.mobileOpen = false;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    // Chiudi automaticamente la navbar su schermi piccoli
    if (this.screenWidth <= 768) {
      this.aperto = false;
    } else {
      this.aperto = true;
    }
  }

  ngOnInit(): void {

    //this.initializeUserData()
    // Codice esistente
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 768) {
      this.aperto = false;
    }

    // Sottoscriviti ai ristoranti disponibili
    this.dataServiceGenerico.availableRestaurants$.subscribe(restaurants => {
      this.availableRestaurants = restaurants;
    });

    // Sottoscriviti al ristorante selezionato e carica i permessi
    this.dataServiceGenerico.selectedRistorante$.subscribe(ristoranteId => {
      this.selectedRestaurant = ristoranteId;

      // Carica i permessi quando cambia il ristorante
      if (ristoranteId) {

        const cachedPermessi = this.getPermessiFromCache(ristoranteId);

        if (cachedPermessi) {
          console.log('Usando permessi della cache per la navbar');
          this.permessiRistorante = cachedPermessi;
          //return;
        }

        this.loadPermessi(ristoranteId);
      }
    });
  }


  private initializeUserData(): Observable<any> {


    const ristoranteId2 = this.dataServiceGenerico.getRistorante1();

    const cachedPermessi = this.dataServiceGenerico.getPermessiFromCache(ristoranteId2);

    if (cachedPermessi) {
      this.permessiRistorante = cachedPermessi;
    }

    return from(this.dataServiceGenerico.loadAvailableRestaurants()).pipe(
        switchMap(() => {
          const ristoranteId = this.dataServiceGenerico.getRistorante1();
          if (!ristoranteId) {
            return throwError('ID ristorante non disponibile');
          }

          // Carica i permessi e configura i tavoli
          this.loadPermessi(ristoranteId);
        }),
        catchError(error => {
          console.error('Errore durante l\'inizializzazione:', error);
          return of(null);
        })
    );
  }

  ngOnDestroy(): void {
    // Cancella il listener dei permessi
    if (this.permessiUnsubscribe) {
      this.permessiUnsubscribe();
    }
  }


  async onRestaurantChange() {
    try {
      const selectedRestaurant = this.availableRestaurants.find(r => r.id === this.selectedRestaurant);
      if (selectedRestaurant) {
        this.dataServiceGenerico.setCurrentRestaurant(selectedRestaurant);
        if (selectedRestaurant.selectedMenu) {
          await this.dataServiceGenerico.setSelectedMenu(selectedRestaurant.selectedMenu);
        }
      }

      // Ricarica la pagina corrente
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //this.router.navigate([this.router.url]);
      });
    } catch (error) {
      console.error('Errore nel cambio ristorante:', error);
    }
  }

  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  cambiaRoute(route): void {
    this.router.navigateByUrl(route);
    // Chiudi la navbar su dispositivi mobili quando si naviga
    if (this.screenWidth <= 768) {
      this.mobileOpen = false;
    }
  }

  navigateToCassa(): void {
    this.router.navigate(['cassa'], { fragment: 'nonav' })
    // Chiudi la navbar su dispositivi mobili quando si naviga
    if (this.screenWidth <= 768) {
      this.mobileOpen = false;
    }
  }

  toggleMobileMenu() {
    this.mobileOpen = !this.mobileOpen;
  }


  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }
}