import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { CommonService } from "../../shared/services/firestore/common.service";
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import firebase from 'firebase/app';

export class Group {
    level = 0;
    parent: Group;
    expanded = true;
    totalCounts = 0;
    get visible(): boolean {
        return !this.parent || (this.parent.visible && this.parent.expanded);
    }
}

@Component({
    selector: 'app-fornitori',
    templateUrl: './menu-fornitori.html',
    styleUrls: ['./menu-fornitori.css']
})
export class Fornitori implements OnInit {
    public dataSource = new MatTableDataSource<any | Group>([]);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    _alldata: any[];
    columns: any[];
    displayedColumns: string[];
    searchText: string = '';
    filteredData: any[];
    selectedFornitore: any = null;

    // Flag per gestire la visualizzazione della lista o del dettaglio
    selezione = 'lista';

    constructor(
        public dialog: MatDialog,
        public commonService: CommonService,
        public dataService: DataServiceGenerico,
        private cdr: ChangeDetectorRef
    ) {
        this.columns = [
            { field: 'nome' },
            { field: 'email' },
            { field: 'telefono' }
        ];

        this.displayedColumns = this.columns.map(column => column.field);
    }

    ngOnInit(): void {
        this.fetchFornitori();
    }

    fetchFornitori(): void {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('fornitori')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    this._alldata = data?.fornitori || [];
                    this.dataSource.data = this._alldata;
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.cdr.detectChanges();
                } else {
                    // Se il documento non esiste, inizializzare con un array vuoto
                    this._alldata = [];
                    this.dataSource.data = [];
                    this.cdr.detectChanges();
                }
            })
            .catch(error => {
                console.error('Errore nel recupero dei fornitori:', error);
            });
    }

    applyFilter(): void {
        if (!this.searchText || this.searchText.trim() === '') {
            this.dataSource.data = this._alldata;
            return;
        }

        const searchTermLower = this.searchText.toLowerCase().trim();
        this.filteredData = this._alldata.filter(item => {
            return (
                item.nome && item.nome.toLowerCase().includes(searchTermLower) ||
                item.email && item.email.toLowerCase().includes(searchTermLower) ||
                item.telefono && item.telefono.toLowerCase().includes(searchTermLower)
            );
        });

        this.dataSource.data = this.filteredData;
    }

    selectFornitore(fornitore: any): void {
        this.selectedFornitore = fornitore;
        this.cdr.detectChanges();
    }

    openDialog(): void {
        this.selectedFornitore = {};
        this.cdr.detectChanges();
    }

    onFornitoreUpdate(updatedFornitore: any): void {
        if (this.selectedFornitore) {
            const index = this._alldata.findIndex(item => item.id === updatedFornitore.id);
            if (index !== -1) {
                this._alldata[index] = updatedFornitore;
                this.dataSource.data = this._alldata;
            }
        }
    }

    onFornitoreDeleted(): void {
        this.selectedFornitore = null;
        this.fetchFornitori(); // Ricarica i dati dopo l'eliminazione
        this.cdr.detectChanges();
    }

    getTabStyle(tab: string) {
        const isSelected = this.selezione === tab;
        return {
            'font-weight': isSelected ? 'bold' : '580',
            'color': isSelected ? 'black' : '#969696',
            'display': 'flex',
            'align-items': 'center',
            'height': 'calc(100% - 2px)',
            'border-bottom': isSelected ? '2px solid black' : '2px solid white',
            'padding': '0px 10px',
            'cursor': 'pointer'
        };
    }
}