<div class="row" style=" height: 95vh; width:calc(100vw - 270px); margin-left: 30px; margin-top: 15px; border-radius: 20px; overflow-y: hidden ">
    <div style="height: 50px; border-bottom: 1px solid #dadada; line-height: 50px; padding-left: 20px; font-weight: 500;">Impostazioni account</div>

    <div class="sidebar shadow-sm" scroll="no" style="float: left">
        <!-- Visualizza le opzioni del menu solo se l'utente ha i permessi -->
        <div *ngIf="hasPermessoForPagina(1)" class="menuItem block" (click)="pagina = 1" [ngClass]="{'selected': this.pagina === 1}">
            <div style="padding-left: 25px">Dati aziendali</div>
        </div>

        <div *ngIf="hasPermessoForPagina(2)" class="menuItem block" (click)="pagina = 2" [ngClass]="{'selected': this.pagina === 2}">
            <div style="padding-left: 25px">Tavoli</div>
        </div>

        <div *ngIf="hasPermessoForPagina(11)" class="menuItem block" (click)="pagina = 11" [ngClass]="{'selected': this.pagina === 11}">
            <div style="padding-left: 25px">Stampanti</div>
        </div>

        <div *ngIf="hasPermessoForPagina(12)" class="menuItem block" (click)="pagina = 12" [ngClass]="{'selected': this.pagina === 12}">
            <div style="padding-left: 25px">Immagini Pubblicitarie</div>
        </div>

        <div *ngIf="hasPermessoForPagina(13)" class="menuItem block" (click)="pagina = 13" [ngClass]="{'selected': this.pagina === 13}">
            <div style="padding-left: 25px">Totem</div>
        </div>

        <div *ngIf="hasPermessoForPagina(4)" class="menuItem block" (click)="pagina = 4" [ngClass]="{'selected': this.pagina === 4}">
            <div style="padding-left: 25px;" class="maxWidth">Personale</div>
        </div>

        <div *ngIf="hasPermessoForPagina(6)" class="menuItem block" (click)="pagina = 6" [ngClass]="{'selected': this.pagina === 6}">
            <div style="padding-left: 25px">Fidelity</div>
        </div>

        <div *ngIf="hasPermessoForPagina(10)" class="menuItem block" (click)="pagina = 10" [ngClass]="{'selected': this.pagina === 10}">
            <div style="padding-left: 25px">Ristoranti</div>
        </div>

        <!--div *ngIf="hasPermessoForPagina(14)" class="menuItem block" (click)="pagina = 14" [ngClass]="{'selected': this.pagina === 14}">
            <div style="padding-left: 25px">Notifiche</div>
        </div>

        <div *ngIf="hasPermessoForPagina(15)" class="menuItem block" (click)="pagina = 15" [ngClass]="{'selected': this.pagina === 15}">
            <div style="padding-left: 25px">Fatturato</div>
        </div-->

        <div class="block" (click)="logout()" style="cursor: pointer; font-weight: 600">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-log-out-100.png?alt=media&token=63460a1f-cbc8-4e65-ad04-d5e25e94c614" style="padding-left: 22px; width: 20px; aspect-ratio: 1 / 1;">
            <div style="padding-left: 5px; color: #ff655d">Esci</div>
        </div>
    </div>

    <!-- Componenti condizionali per ogni pagina -->
    <account style="float: left; margin-left: 40px" *ngIf="pagina == 0"></account>
    <ristorante style="float: left; margin-left: 40px" *ngIf="pagina == 1 && hasPermessoForPagina(1)"></ristorante>
    <tavoli style="float: left; margin-left: 40px" *ngIf="pagina == 2 && hasPermessoForPagina(2)"></tavoli>
    <personale style="float: left; margin-left: 40px" *ngIf="pagina == 4 && hasPermessoForPagina(4)"></personale>
    <impostazioni-menu style="float: left; margin-left: 40px" *ngIf="pagina == 5"></impostazioni-menu>
    <fidelity style="float: left; margin-left: 40px" *ngIf="pagina == 6 && hasPermessoForPagina(6)"></fidelity>
    <preferiti larghezza="width:calc(100vw - 600px);" style="float: left; margin-left: 40px" *ngIf="pagina == 8"></preferiti>
    <impostazioni-ristoranti style="float: left; margin-left: 40px" *ngIf="pagina == 10 && hasPermessoForPagina(10)"></impostazioni-ristoranti>
    <stampanti style="float: left; margin-left: 40px" *ngIf="pagina == 11 && hasPermessoForPagina(11)"></stampanti>
    <slider-images style="float: left; margin-left: 40px" *ngIf="pagina == 12 && hasPermessoForPagina(12)"></slider-images>
    <slider-totem style="float: left; margin-left: 40px" *ngIf="pagina == 13 && hasPermessoForPagina(13)"></slider-totem>
    <notifiche style="float: left; margin-left: 40px" *ngIf="pagina == 14 && hasPermessoForPagina(14)"></notifiche>
    <app-revenue style="float: left; margin-left: 40px" *ngIf="pagina == 15 && hasPermessoForPagina(15)"></app-revenue>
    <cassa style="float: left; margin-left: 40px" *ngIf="pagina == 16"></cassa>
</div>