<div class="dialog-container">
    <div class="sticky-container">
        <div class="save-button" *ngIf="!scontoForm.get('isAccountDiscount').value && data?.id">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Elimina Sconto"
                 (click)="deleteSconto()">
        </div>
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Sconto"
                 (click)="saveSconto()"
                 [class.disabled]="isLoading">
        </div>
        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>

    <div style="height: 80vh; overflow-y: auto; padding: 20px;">
        <form [formGroup]="scontoForm">
            <!-- Sezione per sconti normali -->
            <ng-container *ngIf="!scontoForm.get('isAccountDiscount').value">
                <div style="margin-bottom: 20px">
                    <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
                        <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                            <!-- Titolo -->
                            <div class="flex flex-col gap-2">
                                <label class="font-bold text-gray-700 text-heading-sm">Titolo Sconto</label>
                                <input placeholder="Titolo" class="custom-input" formControlName="nome">
                            </div>

                            <!-- Punti -->
                            <div class="flex flex-col gap-2">
                                <label class="font-bold text-gray-700 text-heading-sm">Punti necessari</label>
                                <input type="number" placeholder="Punti" class="custom-input" formControlName="punti">
                            </div>

                            <!-- Tipologia -->
                            <div class="flex flex-col gap-2">
                                <label class="font-bold text-gray-700 text-heading-sm">Tipologia Sconto</label>
                                <div class="flex flex-col gap-3">
                                    <div class="action-item">
                                        <label class="container" style="font-size: 16px">Prodotto in regalo
                                            <input type="checkbox" formControlName="isMenuChecked">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="action-item">
                                        <label class="container" style="font-size: 16px">Riutilizzabile
                                            <input type="checkbox" formControlName="isSingleChecked">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!-- Sconto in euro -->
                            <div class="flex flex-col gap-2" *ngIf="!scontoForm.get('isMenuChecked').value">
                                <label class="font-bold text-gray-700 text-heading-sm">Valore Sconto</label>
                                <input placeholder="Sconto (es: 5€)" class="custom-input" formControlName="sconto">
                            </div>

                            <!-- Prodotti in regalo -->
                            <div class="flex flex-col gap-2" *ngIf="scontoForm.get('isMenuChecked').value">
                                <label class="font-bold text-gray-700 text-heading-sm">Prodotti da regalare</label>
                                <mat-select placeholder="Seleziona prodotti" multiple class="custom-input" formControlName="selectedProducts">
                                    <mat-option *ngFor="let item of menu" [value]="item.title">
                                        {{item.title}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Sezione per sconto account -->
            <ng-container *ngIf="scontoForm.get('isAccountDiscount').value">
                <div style="margin-bottom: 20px">
                    <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
                        <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                            <div class="flex flex-col gap-2">
                                <label class="font-bold text-gray-700 text-heading-sm">Sconto per il cliente con un account (%)</label>
                                <input type="number" formControlName="sconto" class="custom-input">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Sezione comune per entrambi i tipi di sconto -->
            <div style="margin-bottom: 20px">
                <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
                    <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                        <!-- Validità -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">Validità dello sconto</label>
                            <div class="flex flex-col gap-3">
                                <div *ngFor="let type of discountTypes">
                                    <label class="container" style="font-size: 16px">{{type.label}}
                                        <input type="radio" [value]="type.id" formControlName="discountType">
                                        <span class="checkmark" style="border-radius: 50%"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- Orari specifici -->
                        <div *ngIf="scontoForm.get('discountType').value === 'timeRange'" class="flex flex-col gap-5">
                            <div formGroupName="timeRange" class="flex flex-col xl:flex-row gap-5">
                                <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                                    <label class="font-bold text-gray-700 text-heading-sm">Ora inizio</label>
                                    <input type="time" formControlName="startTime" class="custom-input">
                                </div>
                                <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                                    <label class="font-bold text-gray-700 text-heading-sm">Ora fine</label>
                                    <input type="time" formControlName="endTime" class="custom-input">
                                </div>
                            </div>
                        </div>

                        <!-- Giorni specifici -->
                        <div *ngIf="scontoForm.get('discountType').value === 'specificDays'" class="flex flex-col gap-2">
                            <div formGroupName="specificDays" class="grid grid-cols-2 gap-3">
                                <div *ngFor="let giorno of giorni">
                                    <label class="container" style="font-size: 16px">{{giorno.label}}
                                        <input type="checkbox" [formControlName]="giorno.id">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- Intervallo date -->
                        <div *ngIf="scontoForm.get('discountType').value === 'dateRange'" class="flex flex-col gap-5">
                            <div class="flex flex-col xl:flex-row gap-5">
                                <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                                    <label class="font-bold text-gray-700 text-heading-sm">Data inizio</label>
                                    <input type="date" formControlName="dataInizio" class="custom-input">
                                </div>
                                <div class="flex flex-col gap-2 flex-1" style="max-width: 50%">
                                    <label class="font-bold text-gray-700 text-heading-sm">Data fine</label>
                                    <input type="date" formControlName="dataFine" class="custom-input">
                                </div>
                            </div>
                        </div>

                        <!-- Descrizione -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">Messaggio da visualizzare</label>
                            <textarea formControlName="descrizione" class="custom-input"
                                      style="min-height: 100px; resize: vertical;"></textarea>
                        </div>

                        <!-- Immagine -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">Immagine sconto</label>
                            <input type="file" accept="image/*" (change)="onFileSelected($event)" class="file-input">

                            <div class="preview-container" *ngIf="scontoForm.get('imageUrl').value || scontoForm.get('imageFile').value">
                                <img [src]="(scontoForm.get('imageFile').value ? (scontoForm.get('imageFile').value | objectURL) :
                                         (scontoForm.get('imageUrl').value | objectURL)) | async"
                                     class="image-preview"
                                     alt="Preview">
                            </div>

                            <div *ngIf="uploadProgress > 0" class="progress-bar">
                                <div class="progress" [style.width.%]="uploadProgress"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>