
import {CommonService} from "../firestore/common.service";
import { Injectable } from '@angular/core';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {catchError, map, switchMap} from "rxjs/operators";

@Injectable()
export class DataServiceGenerico {

  private selectedMenuSubject = new BehaviorSubject<string>('demo_1');
  selectedMenu$: Observable<string> = this.selectedMenuSubject.asObservable();

  private selectedRistoranteSubject = new BehaviorSubject<string>('demo_1');


  constructor() {
    this.loadSelectedMenuFromFirebase();
  }

  private getCurrentUser(): Promise<firebase.User | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }

  private async findUserRestaurant(): Promise<string | null> {
    const user = await this.getCurrentUser();
    if (!user) return null;

    const db = firebase.firestore();

    // Prima cerca come admin diretto
    const adminQuerySnapshot = await db
        .collection('ristoranti')
        .where('admin', '==', user.uid)
        .get();

    if (!adminQuerySnapshot.empty) {
      return adminQuerySnapshot.docs[0].id;
    }

    // Se non trovato come admin diretto, cerca negli array di utenti
    const userQuerySnapshot = await db
        .collection('ristoranti')
        .where('utenti', 'array-contains', user.uid)
        .get();

    if (!userQuerySnapshot.empty) {
      return userQuerySnapshot.docs[0].id;
    }

    return null;
  }

  getAvailableMenus(): Observable<string[]> {
    return from(this.getCurrentUser()).pipe(
        switchMap(user => {
          if (!user) {
            throw new Error('Utente non autenticato');
          }

          return from(this.findUserRestaurant()).pipe(
              switchMap(restaurantId => {
                if (!restaurantId) {
                  throw new Error('Nessun ristorante trovato per questo utente');
                }

                const db = firebase.firestore();
                return from(db.collection('menu_test')
                    .where('ristorante', '==', restaurantId)
                    .get());
              }),
              map((snapshot: firebase.firestore.QuerySnapshot) => {
                const menuNames: string[] = [];
                snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
                  menuNames.push(doc.id);
                });
                console.log("menu names: ", menuNames)
                return menuNames;
              })
          );
        }),
        catchError(error => {
          console.error('Errore nel recupero dei menu:', error);
          return of([]);  // Ritorna un array vuoto in caso di errore
        })
    );
  }

  async setSelectedMenu(menuName: string) {
    try {
      const restaurantId = await this.findUserRestaurant();
      if (!restaurantId) {
        throw new Error('Nessun ristorante trovato per questo utente');
      }

      const db = firebase.firestore();
      await db.collection('ristoranti')
          .doc(restaurantId)
          .update({
            selectedMenu: menuName
          });

      this.selectedMenuSubject.next(menuName);
      this.selectedRistoranteSubject.next(restaurantId);

      console.log('Menu selezionato salvato con successo!');
    } catch (error) {
      console.error('Errore durante il salvataggio del menu selezionato:', error);
      throw error;
    }
  }

  public async loadSelectedMenuFromFirebase(): Promise<void> {
    try {
      const restaurantId = await this.findUserRestaurant();
      if (!restaurantId) {
        console.error('Nessun ristorante trovato per questo utente');
        return;
      }

      const db = firebase.firestore();
      const restaurantDoc = await db.collection('ristoranti')
          .doc(restaurantId)
          .get();

      if (restaurantDoc.exists) {
        const data = restaurantDoc.data();
        if (data && data.selectedMenu) {
          this.selectedMenuSubject.next(data.selectedMenu);
        }
        if (data && data.id) {
          this.selectedRistoranteSubject.next(data.id);
        }
      }
    } catch (error) {
      console.error('Errore nel recupero del menu selezionato:', error);
    }
  }



  // Create a new menu in Firebase
  async createMenu(newMenuName: string): Promise<void> {
    try {
      // Trova prima l'id del ristorante dell'utente
      const restaurantId = await this.findUserRestaurant();
      if (!restaurantId) {
        throw new Error('Nessun ristorante trovato per questo utente');
      }

      const db = firebase.firestore();
      return db.collection('menu_test').doc(newMenuName).set({
        items: [], // Inizializza con un array vuoto di items
        nome_ristorante: newMenuName,
        ristorante: restaurantId // Aggiungi l'id del ristorante
      });
    } catch (error) {
      console.error('Errore durante la creazione del menu:', error);
      throw error;
    }
  }



  setUser(u)
  {
    user = u
  }

  getUser()
  {
    return user;
  }

  salva()
  {
    this.camerieriSubject.next(camerieri);
  }

  subtractCameriere(cameriere: any, piatto: any)
  {

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)

    let cameriere2 = camerieri[filteredCamerieri]

    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false)
    {
      if(cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita == 1)
      {
        delete cameriere2.carrello[piatto.title.replace(/ /g,'')]
      }
      else
      {
        cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita--;
      }
    }

    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine))
    camerieri.push(cameriere2)
    console.log("received camerieri 2: ", camerieri)
    console.log("received camerieri 2: ", cameriere2)


    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)
    ordine = cameriere2

    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);


  }


  updateCameriere2(cameriere: any, piatto: any, ){
    console.log("received cameriere: ", cameriere)
    console.log("received camerieri: ", camerieri)

    console.log("received piatto: ", piatto)

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)
    console.log("received filtered camerieri: ", filteredCamerieri)
    camerieri[filteredCamerieri].carrello = piatto;

    console.log("received ciaociao2")

    ordine = camerieri[filteredCamerieri]

    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)

    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);
  }


  updateCameriere(cameriere: any, piatto: any, portata?: any){
    console.log("received cameriere: ", cameriere)
    console.log("received camerieri: ", camerieri)

    console.log("received piatto: ", piatto)

    let filteredCamerieri = camerieri.findIndex(cameriere3 => cameriere3.ordine == cameriere)
    console.log("received filtered camerieri: ", filteredCamerieri)
    let cameriere2 = camerieri[filteredCamerieri]

    console.log("received cameriere2: ", cameriere2)
    if(cameriere2.hasOwnProperty('carrello')? cameriere2.carrello?.hasOwnProperty(piatto.title.replace(/ /g,'')): false)
    {

      if (!cameriere2.hasOwnProperty('ordineInserimento')) {
        cameriere2['ordineInserimento'] = [];
        // Se ordineInserimento non esiste, aggiungiamo tutti gli elementi del carrello
        for (let key in cameriere2.carrello) {
          if (cameriere2.carrello.hasOwnProperty(key)) {
            cameriere2['ordineInserimento'].push(key);
          }
        }
      }

      cameriere2.carrello[piatto.title.replace(/ /g,'')].quantita++;
    }
    else
    {
      if(!cameriere2.hasOwnProperty('carrello'))
      {
        console.log("received ciaociao: ", piatto.title.replace(/ /g,''))
        let nome = piatto.title.replace(/ /g,'');
        console.log("received cameriere22: ", cameriere2)

        cameriere2['carrello'] = {}
        cameriere2['ordineInserimento'] = [];
        cameriere2['carrello'][nome] = piatto;
        console.log("received ciaociao3")

        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price;
        if(portata == 4)
        {
          portata = 0
        }
        cameriere2.carrello[nome].portata = portata;

        cameriere2['ordineInserimento'].push(nome);


        console.log("received ciaociao4: ", cameriere2)
      }else
      {
        let nome = piatto.title.replace(/ /g,'');
        console.log("received cameriere22: ", cameriere2)

        cameriere2['carrello'][nome] = piatto;
        console.log("received ciaociao3")

        cameriere2.carrello[nome].quantita = 1;
        cameriere2.carrello[nome].price = +cameriere2.carrello[nome].price;
        cameriere2.carrello[nome].prezzo = +cameriere2.carrello[nome].price;
        if(portata == 4)
        {
          portata = 0
        }
        cameriere2.carrello[nome].portata = portata;

        if (!cameriere2.hasOwnProperty('ordineInserimento')) {
          cameriere2['ordineInserimento'] = [];
          // Se ordineInserimento non esiste, aggiungiamo tutti gli elementi del carrello
          for (let key in cameriere2.carrello) {
            if (cameriere2.carrello.hasOwnProperty(key)) {
              cameriere2['ordineInserimento'].push(key);
            }
          }
        }
        else
        {
          cameriere2['ordineInserimento'].push(nome);
        }
      }
    }

    console.log("received ciaociao2")



    camerieri = camerieri.filter(item => !(item.ordine == cameriere2.ordine))
    camerieri.push(cameriere2)
    console.log("received camerieri 2: ", camerieri)
    console.log("received camerieri 2: ", cameriere2)


    ordine = cameriere2

    camerieri = camerieri.sort((a, b) => b.ordine - a.ordine)

    this.OrdineSubject.next(ordine);

    this.camerieriSubject.next(camerieri);
  }

  setCamerieri(camerieri2: any)
  {
    camerieri2.forEach(ordine => {
      if(ordine.hasOwnProperty('carrello'))
      {
        if (!ordine.hasOwnProperty('ordineInserimento')) {
          ordine['ordineInserimento'] = [];
          // Se ordineInserimento non esiste, aggiungiamo tutti gli elementi del carrello
          for (let key in ordine.carrello) {
            if (ordine.carrello.hasOwnProperty(key)) {
              ordine['ordineInserimento'].push(key);
            }
          }
        }
      }
    })
    camerieri = camerieri2;
    this.camerieriSubject.next(camerieri);
  }


  private camerieriSubject = new BehaviorSubject<any>(0);
  camerieriSubjectItem$ = this.camerieriSubject.asObservable();

  //ordine da salvare
  private OrdineSubject = new BehaviorSubject<any>(0);
  OrdineSubjectItem$ = this.OrdineSubject.asObservable();


  getCamerieri()
  {
    return camerieri.filter(ordine => ordine.hasOwnProperty('fonte')? ordine.fonte == 1: false).filter(ordine => ordine.hasOwnProperty('statoPagato')? ordine.fonte == 0: false);
  }

  setFilterFonte(filterFonteInterno)
  {
    filterFonte = filterFonteInterno
  }


  setFilterPagato(filterPagatoInterno)
  {
    filterPagato = filterPagatoInterno
  }

  getRistorante(): string {
    return this.selectedMenuSubject.getValue();  // This will return the value stored in the BehaviorSubject
  }


  setRistorante(ristorante) {
    this.selectedMenuSubject.next(ristorante);  // Update the BehaviorSubject with the value from Firebase
  }


  getRistorante1(): string {
    return this.selectedRistoranteSubject.getValue();  // This will return the value stored in the BehaviorSubject
  }


  setRistorante1(ristorante) {
    this.selectedRistoranteSubject.next(ristorante);  // Update the BehaviorSubject with the value from Firebase
  }


  getAllergeni()
  {
    return allergeni
  }

  getRistoranti()
  {
    return ristoranti
  }

  setRistoranti(ristoranti2)
  {
    ristoranti = ristoranti2
  }

  getGruppi()
  {
    return gruppi
  }
  setGruppi(gruppi2)
  {
    gruppi = gruppi2
  }

  getCollegamenti()
  {
    return collegamenti
  }
  seCollegamenti(gruppi2)
  {
    collegamenti = gruppi2
  }

  setImmagini(immagini2)
  {
    immagini = immagini2
  }

  getImmagini()
  {
    return immagini
  }

}

let immagini = {}

let user = {}

let camerieri = []

let ordine = {}


let filterPagato = 'Non Pagato'
let filterFonte = 'Cassa'


let ristorante = 'demo_1'


let allergeni = [
  "glutine",
  "latticini",
  "crostacei",
  "uova",
  "arachidi",
  "soia",
  "frutta con guscio",
  "sedano",
  "senape",
  "sesamo",
  "lupini",
  "molluschi",
  "Anidride solforosa",
]


let ristoranti = [
  {
    nome: 'Befane',
    piva: '981237983732971',
    email: 'befane@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 44',
    piva: '981237983732971',
    email: 'bagno44@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Bagno 23',
    piva: '981237983732971',
    email: 'bagno23@fillapp.it',
    permessi: ['']
  },
  {
    nome: 'Stazione bologna',
    piva: '981237983732971',
    email: 'Bologna@fillapp.it',
    permessi: ['']
  },
]

let gruppi = [
  {
    nome: 'Centri Commerciali',
    ristoranti: [
      {
        nome: 'Befane',
        piva: '981237983732971',
        email: 'befane@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Mare',
    ristoranti: [
      {
        nome: 'Bagno 44',
        piva: '981237983732971',
        email: 'bagno44@fillapp.it',
        permessi: ['']
      },
      {
        nome: 'Bagno 23',
        piva: '981237983732971',
        email: 'bagno23@fillapp.it',
        permessi: ['']
      },
    ]
  },
  {
    nome: 'Stazione',
    ristoranti: [
      {
        nome: 'Stazione bologna',
        piva: '981237983732971',
        email: 'Bologna@fillapp.it',
        permessi: ['']
      },
    ]
  }
]


let collegamenti = [
  {
    nome: 'cannoleria',
    gruppo: [
      {
        nome: 'Centri Commerciali',
        ristoranti: []
      },
    ],
    ristoranti: [

    ]
  },
  {
    nome: 'fastfood',
    gruppo: [
      {
        nome: 'Mare',
        ristoranti: []
      },

    ],
    ristoranti: [

    ]
  },
  {
    nome: 'pasticceria_1',
    gruppo: [
      {
        nome: 'Stazione',
        ristoranti: []
      }
    ],
    ristoranti: [

    ]
  },
  {
    nome: 'pasticceria_2',
    gruppo: [
      ''
    ],
    ristoranti: [

    ]
  }
]

