<div class="row" style="overflow-y: hidden !important; height: 100vh !important;">



  <div class="column">


    <div style="width: 100%; display: flex; margin-top: 20px">
      <div style="width: 9%; margin-right: 1%">
        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;"

                       [link]="'assets/icons/icons8-back-arrow-96.png'"
                       [border]="'#e55933'"
                       (click)="indietro();"
                        >
        </categorieCard>
      </div>
      <div style="width: 90%">
          <receiptOrderCard1 style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             [titolo]="getTitoloOrdine()"
                             [stato]="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? 'Pagato': 'Non Pagato': 'Non Pagato'"
                             [fonte]="currentOrder?.fonte">
          </receiptOrderCard1>
      </div>
       </div>


    <div class="container menu"  >
      <p style="text-align: left; width:80%; display: inline-block; background-color: #FBFBFB; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 20px; margin-bottom: 0px; margin-left: 0px; font-weight: normal">Dettagli Ordinazione:</p>

        <div class="row1" style="float: left; width: calc(100% - 10px); height: 60px; margin-top: 10px; margin-bottom: 10px; border-radius: 20px; background-color: white; border: solid 1px #e3e2e2;  padding:  0px 5px">


            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20% " [style.background-color]="portata == 0? 'rgba(181,181,181,0.2)': 'transparent'"  (click)="portata = 0 ">
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 15px; font-weight: 600">1</div>
                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20%" [style.background-color]="portata == 1? 'rgba(181,181,181,0.2)': 'transparent'"   (click)="portata = 1" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 15px; font-weight: 600">2</div>
                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center;width: 20% " [style.background-color]="portata == 2? 'rgba(181,181,181,0.2)': 'transparent'"   (click)="portata = 2" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 15px; font-weight: 600">3</div>

                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center;width: 20% " [style.background-color]="portata == 3? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 3" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 15px; font-weight: 600">4</div>

                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20%" [style.background-color]="portata == 4? 'rgba(181,181,181,0.2)': 'transparent'"  (click)="portata = 4" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 15px; font-weight: 600">Tutto</div>

                </div>
            </div>

        </div>

      <div style="pointer-events: auto; margin-top: 15px; overflow-y: scroll; height: 40vh; border-radius: 20px; border: solid 1px rgba(181,181,181, 0.3)">
        <div class="ciaociao" style="padding-top: 0%; padding-left: 0px; " *ngFor="let piatto of getPropertiesPortata(currentOrder?.carrello); let last = last; let first = first">
            <menuDetailCard-bar
                    style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                    [nome]="currentOrder?.carrello[piatto]['title']"
                    [ingredienti]="currentOrder?.carrello[piatto]['category']"
                    [prezzo]="getPrezzoPiatto(currentOrder?.carrello[piatto])"
                    [quantita]="currentOrder?.carrello[piatto].quantita"
                    [id]="currentOrder.ordine"
                    [pagato]="currentOrder.statoPagato"
                    [carrello]="true"
                    [last]="last"
                    [first]="first"
                    [lenght]="getProperties(currentOrder?.carrello).length"
                    [link]="imagePaths[currentOrder?.carrello[piatto].title] | async"
                    [aggiunte]="currentOrder?.carrello[piatto].aggiunte"
                    [rimozioni]="currentOrder?.carrello[piatto].rimozioni"
                    [nota]="currentOrder?.carrello[piatto].note"
                    [variants]="currentOrder?.carrello[piatto].variants"
                    [isPaidQuantity]="currentOrder?.carrello[piatto].isPaidQuantity"
                    (quantitaOut)="handleEvent($event, currentOrder?.carrello[piatto], piatto)"
                    (click)="apriVarianti(currentOrder?.carrello[piatto])" >
            </menuDetailCard-bar>
        </div>
      </div>


    </div>


    <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 15px">
      <div style="padding-top: 0%; padding-left: 0px">
        <div  style="flex-basis: 100%;  height: 1px; background-color: #e55933; "></div>

        <div style="text-align: right; font-weight: 600; margin-top: 10px; margin-right: 13px; color: #e55933; font-size: x-large">Totale:  {{calcolaTotale().toFixed(2)}}€</div>
      </div>

      <div style=" margin-top: 20px">




        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; margin-right: 2%; margin-bottom: 1%" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                       [nome]="'Preconto'"
                       [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                       altezza="55"
                       (click)="printReceipt('nonAprire')">

        </categorieCard>

        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%"
                       [nome]="'Invia Comanda'"
                       [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                       altezza="55"
                       (click)="testComande()">
        </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; margin-right: 2%; margin-bottom: 1%" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                         [nome]="'Fidelity'"
                         [link]="'assets/icons/icons8-loyalty-90.png'"
                         altezza="55"
                         (click)="testComande()">
          </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                         [nome]="'Avanti Comanda'"
                         [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                         altezza="55"
                         (click)="avantiComanda()">
          </categorieCard>

          <!--categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 100%; margin-bottom: 1%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                         [nome]="'Fidelity'"
                         [link]="'assets/icons/icons8-loyalty-90.png'"
                         [bg]="'rgb(52, 152, 219)'"
                         altezza="55"
                         (click)="openDialogFidelity()">
          </categorieCard-->


        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%; margin-right: 2%" *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true" (click)="eliminaOrdine(currentOrder?.ordine)"
                       [nome]="'Elimina '"
                       [link]="'assets/icons/icons8-delete-96.png'"
                       [bg]="'rgba(255,0,0,0.8)'"
                       altezza="55"
                       (click)="schermata = 0">

        </categorieCard>

        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; " *ngIf="currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true"
                       [nome]="'Pagamento'"
                       [link]="'assets/icons/icons8-cash-100.png'"
                       [bg]="'#34bfa3'"
                       altezza="55"
                       (click) = "openDialogPagamento()">

        </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; " *ngIf="!(currentOrder?.statoPagato !== undefined? currentOrder?.statoPagato == 0? false: true: true)"
                         [nome]="'Fatturazione'"
                         [link]="'assets/icons/icons8-cash-100.png'"
                         [bg]="'#34bfa3'"
                         altezza="55"
                         (click) = "openDialogFatturazione()">

          </categorieCard>


      </div>
    </div>

    <!--div class="container2NotScrolled" style="pointer-events: auto; margin-top: 1px">
      <div style="padding-top: 0%; padding-left: 0px">
        <dettaglioScontrinoFinaleCard [nome]="this.nome2" [luogo]="this.luogo" [costo]="calcolaMancia()" [percentuale]="this.percentuale2"
        style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px"></dettaglioScontrinoFinaleCard>
      </div>
    </div-->


  </div>

  <div class="column2">



        <div class="categories-container" style="width: calc(100% - 10px); margin-top: 20px; border-radius: 20px; background-color: white; border: solid 1px #e3e2e2; padding: 5px; display: grid; grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); gap: 5px; min-height: 90px; max-height: 170px;">

            <!-- Favorites Button -->
            <div class="category-item"
                 [class.active]="sezione == -1"
                 (click)="sezione = -1">
                <div class="category-content">
                    <img class="category-icon"
                         [src]="'assets/icons/icons8-heart-100.png'"
                         alt="Favorites">
                    <div class="category-title">Preferiti</div>
                </div>
            </div>

            <!-- Category Items -->
            <div *ngFor="let element of menu; let i = index"
                 class="category-item"
                 [class.active]="sezione == i"
                 (click)="sezione = i">
                <div class="category-content">
                    <img class="category-icon"
                         [src]="imagePaths[element.menu[0]['title']] | async"
                         [alt]="element.menu[0]['category']">
                    <div class="category-title">{{element.menu[0]['category']}}</div>
                </div>
            </div>
        </div>



    <div style="overflow-y: auto; float: left; width: 100%; height: 80vh; border-radius: 30px; background-color: transparent; padding-top: 10px; " *ngIf="sezione > -1">

      <div *ngFor="let piatto of menu[sezione]['menu'], let i = index, trackBy: trackByFn" >

        <div style="position: relative; float: left; cursor: pointer; width: 18%; aspect-ratio: 1/1 !important; min-height: 0px !important;  border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '180px' : '180px'" (click)="updateOrdine(piatto);">

          <img [src]="imagePaths[piatto.title] | async" style="height: 100px; margin-top: 15px; background-color: transparent;">

          <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 14px">{{piatto.title}}</span>
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
            <div style="padding-left: 10px; ">
              <div style="background-color: #34bfa3; font-size: small; border-radius: 12px; color: white; font-weight: bold; aspect-ratio: 1/1; padding: 3px 6px" *ngIf="getQuantita(piatto) > 0">
                {{getQuantita(piatto)}}x
              </div>
            </div>
            <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;">{{(+piatto.price).toFixed(2)}}€</span>
          </div>
        </div>
      </div>

    </div>


    <div style="overflow-y: auto; float: left; width: 100%; height: 80vh; border-radius: 30px; background-color: transparent; padding-top: 10px; " *ngIf="sezione == -1">

      <div *ngFor="let piatto of user?.preferiti, let i = index" >

        <div style="position: relative; float: left; cursor: pointer; width: 18%; aspect-ratio: 1/1 !important; min-height: 0px !important; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '200px' : '180px'" (click)="updateOrdine(piatto); ">

          <img [src]="imagePaths[piatto.title] | async" style="height: 100px; margin-top: 15px; background-color: transparent;">

          <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 14px">{{piatto.title}}</span>
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
            <div style="padding-left: 10px; ">
              <div style="background-color: #34bfa3; font-size: small; border-radius: 12px; color: white; font-weight: bold; aspect-ratio: 1/1; padding: 3px 6px" *ngIf="getQuantita(piatto) > 0">
                {{getQuantita(piatto)}}x
              </div>
            </div>
            <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;">{{(+piatto.price).toFixed(2)}}€</span>
          </div>
        </div>
      </div>

    </div>




      <app-numeric-keypad></app-numeric-keypad>


  </div>

</div>
