import {Component, EventEmitter, Inject, OnDestroy, Output} from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-utenti-fatturazione',
  templateUrl: './fatturazione.html',
  styleUrls: ['./fatturazione.css'],
})
export class Fatturazione implements OnDestroy {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  chiudi() {
    this.dialogRef.close(false);
  }

  nuovoUtente = false;

  utenti = [
    {
      ragioneSociale: 'Rossi SRL',
      partitaIVA: '12345678901',
      codiceFiscale: 'RSSMRA80A01H501U',
      indirizzo: 'Via Roma 1',
      cap: '00100',
      citta: 'Roma',
      provincia: 'RM',
      email: 'info@rossisrl.it',
      pec: 'rossisrl@pec.it',
      codiceSdi: 'XXXXXXX',
      telefono: '3803710182'
    },
    {
      ragioneSociale: 'Bianchi SpA',
      partitaIVA: '09876543210',
      codiceFiscale: 'BNCGVN75M15F205X',
      indirizzo: 'Corso Italia 10',
      cap: '20100',
      citta: 'Milano',
      provincia: 'MI',
      email: 'info@bianchispa.it',
      pec: 'bianchispa@pec.it',
      codiceSdi: 'YYYYYYY',
      telefono: '3924954600'

    }
  ];

  testo = '';

  utenteSelezionato = undefined;
  utente = {
    ragioneSociale: '',
    partitaIVA: '',
    codiceFiscale: '',
    indirizzo: '',
    cap: '',
    citta: '',
    provincia: '',
    email: '',
    pec: '',
    codiceSdi: '',
    telefono: ''
  };

  utentiFilter = [];

  ricerca() {
    this.utentiFilter = this.utenti.filter(utente =>
        Object.keys(utente).some(k =>
            utente[k].toString().toLowerCase().includes(this.testo.toLowerCase())
        )
    );
  }

  salvaUtente() {
    this.utenti.push({...this.utente});
    this.nuovoUtente = false;
    this.utente = {
      ragioneSociale: '',
      partitaIVA: '',
      codiceFiscale: '',
      indirizzo: '',
      cap: '',
      citta: '',
      provincia: '',
      email: '',
      pec: '',
      codiceSdi: '',
      telefono: ''
    };
    this.ricerca();
  }

  constructor(
      private router: Router,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<any>
  ) {
    this.utentiFilter = this.utenti;
  }

  ngOnDestroy(): void {
    // Codice di pulizia se necessario
  }

  cambiaRoute(route: string): void {
    this.router.navigateByUrl(route);
    this.dialogRef.close(false);
  }
}