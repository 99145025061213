import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {CategoriesModel} from '../../models/menu/categories.model';
import {UserModel} from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private dbPath = '/users';

  tutorialsRef: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) {
    this.tutorialsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<any> {
    return this.tutorialsRef;
  }

  getDoc( id: string ): AngularFirestoreDocument<any> {
    return this.tutorialsRef.doc(id);
  }

  create(tutorial: UserModel, id: string): any {
    return this.tutorialsRef.doc(id).set( { ...tutorial });
    // return this.tutorialsRef.add({ ...tutorial });
  }

  update(id: string, data: any): Promise<void> {
    return this.tutorialsRef.doc(id).update(data);
    // return this.tutorialsRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {
    return this.tutorialsRef.doc(id).delete();
  }
}
