import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../router.module';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../menu/menu";
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {PaymentCashmaticService} from "../../shared/services/payment-cashmatic.service";
import {IpcService} from "../../shared/services/ipc.service";
import {PosTransactionService} from "../../shared/services/pos-transaction.service";
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-categorie',
  templateUrl: './cassa-camerieri.html',
  styleUrls: ['./cassa-camerieri.css']
})
  export class CassaCamerieri implements OnInit{

  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';
  fonteSelected = 'Cassa';
  pagatoSelected = 'Non Pagato';


  lista = 0;
  pagamentoCartaAperto = 0;
  cashmaticAperto = 0;


  handleEvent(event: number, piatto: any) {
    console.log("handle event: ", event);
    let nomePiatto = ''
    if(piatto.hasOwnProperty('dimensioneMenu'))
    {
      nomePiatto  = piatto['title'].replace(/ /g, '')+ piatto['contorno'].title.replace(/ /g, '') +piatto['bibita'].title.replace(/ /g, '') +piatto['salsa'].title.replace(/ /g, '') + piatto['dimensioneMenu']
    }
    else
    {
      nomePiatto = piatto.title.replace(/ /g, '')

    }
    console.log("")
    this.camerieri[this.getIndex()].carrello[nomePiatto].quantita = event
    if(this.camerieri[this.getIndex()].carrello[nomePiatto].quantita == 0)
    {
      delete this.camerieri[this.getIndex()].carrello[nomePiatto]
    }
    //this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, this.camerieri[this.getIndex()].carrello)
  }

  save()
  {
    if(this.camerieri[this.schermataIndex].carrello !== undefined)
    {
      this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, this.camerieri[this.schermataIndex].carrello)

    }
  }

  //fonte
  //0 = app
  //1 = kiosk
  //2 = cassa
  //3 = tutto

  //pagato
  //0 = pagato
  //1 = non pagato
  //2 = tutto
  //3 = completato

  filtroSource = 2;
  filtroStato = 2;



  filtraApp()
  {
    this.filtroSource = 0;
    this.retriveCarrello()
    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 0);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);
  }
  filtraChiosco()
  {
    this.filtroSource = 1;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 1);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }
  filtraCassa()
  {
    this.filtroSource = 2;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 2);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }

  filtraTutto()
  {
    this.filtroSource = 3;
    this.retriveCarrello()

    //this.ordini = this.filtroStato == 2? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.statoPagato === this.filtroStato);
  }

  filtraPagato() {

    this.filtroStato = 0;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 0);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraNonPagato()
  {
    this.filtroStato = 1;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraCompletato()
  {
    this.filtroStato = 3;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraStatoTutto()
  {
    this.filtroStato = 2;
    this.retriveCarrello()

    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
  }

  filter = '';
  filtraCerca()
  {
    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

    //this.ordini = this.ordini.filter(data =>  Object.keys(data).some(k => this.filter.trim().split(" ").some(el => typeof data[k] !== 'string' && typeof data[k] !== 'number'? Object.keys(data[k]).some(property => data[k][property].toString().toLowerCase().includes(el.toLowerCase())) : data[k]?.toString().toLowerCase().includes(el.toLowerCase()))))
  }



  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };


  display = 'none';
  schermata = 0;
  schermataIndex ;
  tavoli = [
    {numero: 1, coperti: 2, occupato: 1 , pagato: 'In App',scontrino: [
        {piatto: "Pizza margherita", descrizione: "pomodoro, mozzarella, basilico", quantita: 2, prezzo: 15},
        {piatto: "Coca Cola", descrizione: "", quantita: 2, prezzo: 7}
      ]},
    {numero: 2, coperti: 2, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Spaghetti aglio, olio e peperoncino", descrizione: "aglio, olio, peperoncino", quantita: 2, prezzo: 12},
        {piatto: "Acqua naturale", descrizione: "", quantita: 2, prezzo: 3}
      ]},
    {numero: 3, coperti: 4, occupato: 1 , pagato: 'Non Pagato', scontrino: [
        {piatto: "Lasagne al forno", descrizione: "carne macinata, besciamella, parmigiano", quantita: 4, prezzo: 18},
        {piatto: "Aranciata", descrizione: "", quantita: 4, prezzo: 4}
      ]},
    {numero: 4, coperti: 4, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Penne alla vodka", descrizione: "pomodoro, panna, vodka", quantita: 4, prezzo: 16},
        {piatto: "Birra Moretti", descrizione: "", quantita: 4, prezzo: 5}
      ]},
    {numero: 5, coperti: 4, occupato: 1 , pagato: 'Kiosk', scontrino: [
        {piatto: "Risotto ai frutti di mare", descrizione: "frutti di mare misti, prezzemolo", quantita: 4, prezzo: 20},
        {piatto: "Limonata", descrizione: "", quantita: 4, prezzo: 4}
      ]},
    {numero: 6, coperti: 2, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Insalata caprese", descrizione: "pomodoro, mozzarella, basilico", quantita: 2, prezzo: 10},
        {piatto: "Birra Peroni", descrizione: "", quantita: 2, prezzo: 5}
      ]},
    {numero: 7, coperti: 2, occupato: 0 , pagato: 'Libero', scontrino: []},
    {numero: 8, coperti: 8, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Pizza quattro stagioni", descrizione: "pomodoro, mozzarella, funghi, prosciutto cotto, carciofi, olive", quantita: 1, prezzo: 20},
        {piatto: "Gnocchi alla sorrentina", descrizione: "pomodoro, mozzarella, basilico", quantita: 1, prezzo: 18},
        {piatto: "Tiramisù", descrizione: "savoiardi, mascarpone, caffè, cacao", quantita: 1, prezzo: 14},
        {piatto: "Frittura mista di pesce", descrizione: "calamari, gamberi, triglie", quantita: 1, prezzo: 22},
        {piatto: "Filetto di manzo alla griglia", descrizione: "carne di manzo, contorno di verdure", quantita: 1, prezzo: 26},
        {piatto: "Insalata di mare", descrizione: "frutti di mare misti, verdure", quantita: 1, prezzo: 18},
        {piatto: "Cannoli siciliani", descrizione: "ricotta, cioccolato, scorza d'arancia", quantita: 1, prezzo: 10},
        {piatto: "Spaghetti alle vongole", descrizione: "vongole, aglio, prezzemolo", quantita: 1, prezzo: 22}
      ]},
    {numero: 9, coperti: 8, occupato: 1 , pagato: 'In App', scontrino: [
        {piatto: "Piatto misto di carne alla griglia", descrizione: "costine di maiale, salsicce, pollo", quantita: 1, prezzo: 18},
        {piatto: "Patatine fritte", descrizione: "", quantita: 1, prezzo: 5},
        {piatto: "Insalata verde", descrizione: "insalata, pomodoro, cetriolo", quantita: 1, prezzo: 6},
        {piatto: "Birra artigianale", descrizione: "", quantita: 1, prezzo: 6},
        {piatto: "Risotto ai funghi porcini", descrizione: "funghi porcini, prezzemolo", quantita: 1, prezzo: 16},
        {piatto: "Torta della nonna", descrizione: "crema pasticcera, pinoli", quantita: 1, prezzo: 8},
        {piatto: "Acqua naturale", descrizione: "", quantita: 1, prezzo: 3},
        {piatto: "Caffè espresso", descrizione: "", quantita: 1, prezzo: 2}
      ]},
    {numero: 10, coperti: 4, occupato: 1 , pagato: 'Non Pagato', scontrino: [
        {piatto: "Spaghetti alle vongole", descrizione: "vongole, aglio, prezzemolo", quantita: 1, prezzo: 11},
        {piatto: "Acqua frizzante", descrizione: "", quantita: 1, prezzo: 2},
        {piatto: "Crostini misti", descrizione: "pane, pomodoro, olive, salumi", quantita: 1, prezzo: 8},
        {piatto: "Insalata caprese", descrizione: "pomodoro, mozzarella, basilico", quantita: 1, prezzo: 7}
      ]},
    {numero: 11, coperti: 4, occupato: 0 ,   pagato: 'Libero', scontrino: []},
    {numero: 12, coperti: 2, occupato: 0 , pagato: 'Libero', scontrino: []}
  ];

  tastierino = [7,8,9,4,5,6,1,2,3]
  closeResult = '';
  prezzoPiatto = '0';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'menu', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    private commonService: CommonService, private dataServiceGenerico: DataServiceGenerico, private paymentCashmaticService: PaymentCashmaticService, private ipcService: IpcService, private posTransactionService: PosTransactionService
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }
  printReceipt(cassetto: string) {

    let stringa = []
    this.schermata = 1;

    this.getProperties(this.camerieri[this.getIndex()]?.carrello)?.forEach(
      key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )

    this.stampaScontrino(stringa);
    this.ipcService.printReceipt(this.stampaScontrino(stringa), cassetto);

    if(cassetto != 'apriSolo'){
    this.camerieri[this.getIndex()].statoPagato = 0;
    }

    this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

  }
  sendTransaction(transactionAmount) {
    let stringa = []
    this.pagamentoCartaAperto = 1;
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
      key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )
    this.posTransactionService.sendTransaction(transactionAmount, this.stampaScontrino(stringa));
  }
  stampaScontrino(menu: any[]): string {
    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;


    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * item.price;
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
        ? item.title.slice(0, maxLength)
        : item.title.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------

  `;

    return header + scontrino + footer;
  }

  testPay() {
    this.cashmaticAperto = 1;
    let stringa = []
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
      key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )

    this.paymentCashmaticService.testPay(this.calcolaTotale(),this.stampaScontrino(stringa));
  }

  annullaPagamento() {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  getTransaction() {
    this.paymentCashmaticService.getTransaction();
  }

  openDialog(): any {

    this.modalOptions.data = this.camerieri[this.schermataIndex].ordine
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.carrello = this.camerieri[this.getIndex()]?.carrello
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
      this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, receivedEntry)
    });

  }


  aggiungiBirra()
  {
    let birra = {
      category: 'Bibite',
      menu: '0',
      prezzo: 5,
      price: 5,
      quantita: 1,
      title: 'Birra'
    }
    if(this.camerieri[this.schermataIndex].hasOwnProperty('carrello'))
    {
      if(this.camerieri[this.schermataIndex].carrello.hasOwnProperty('Birra'))
      {
        this.camerieri[this.schermataIndex].carrello['Birra'].quantita += 1;
      }
      else
      {
        this.camerieri[this.schermataIndex].carrello['Birra'] = birra;
      }
    }
    else
    {
      this.camerieri[this.schermataIndex]['carrello'] = {}
      this.camerieri[this.schermataIndex]['carrello']['Birra'] = birra;
    }

    this.commonService.updateOrdine(this.camerieri[this.schermataIndex].id, this.camerieri[this.schermataIndex])

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  ngOnInit(): void {
    this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        console.log(response.message);
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        this.camerieri[this.getIndex()].statoPagato = 0;

        this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

        // Transazione avvenuta con successo
      } else if (response.status === 'failure') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Transazione fallita
      } else if (response.status === 'error') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Errore di connessione
      }
    });

    this.subscriptions.push(
      this.paymentCashmaticService.soldiInseriti$.subscribe(
        (soldiInseriti) => {
          this.soldiInseriti = soldiInseriti;
        }
      ),
      this.paymentCashmaticService.paymentSuccess$.subscribe(
        (data) => {
          this.paymentMessage = 'Pagamento completato con successo!';
          this.schermata = 1;
          this.cashmaticAperto = 0;
          this.camerieri[this.getIndex()].statoPagato = 0;

          this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

        }
      ),
      this.paymentCashmaticService.paymentError$.subscribe(
        (error) => {
          this.paymentMessage = 'Errore durante il pagamento: ' + error;
        }
      )
    );


    this.dataServiceGenerico.camerieriSubjectItem$
      .subscribe(item => {

        console.log("received camerieri subject")
        this.camerieri = item
        }
      )

    this.dataServiceGenerico.OrdineSubjectItem$
      .subscribe(item => {
        console.log("received camerieri 2 salvare: ", item)
        if(item !== 0)
        {
          this.commonService.updateOrdine(item.id, item)
        }
        }
      )


    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });
  }

  idRistorante = '';

  camerieri = []
  menu = [];
  carrello2 = {};


  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  eliminazione = false;

  eliminaOrdine(ordine: any)
  {
    console.log("elimina ordine: ", ordine)

    this.commonService.deleteOrdine(this.camerieri[this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine)].id)

    this.camerieri = this.camerieri.filter(item => !(item.ordine == ordine))

    this.dataServiceGenerico.setCamerieri(this.camerieri)
  }
  aggiungiOrdine()
  {
    let current = new Date();

    let timestamp = current.getTime();
    let psw = timestamp.toString();

    console.log("psw: ", new Date(+psw))

    console.log("camerieri: ", this.camerieri)
    let filterderArray = this.camerieri.sort((a, b) => b.ordine - a.ordine)
    if(filterderArray.length > 0)
    {
      let ordine ={
        ristorante: this.dataServiceGenerico.getRistorante(),
        id: 'c1'+psw,
        displayName: 'Ordine 000' + (+filterderArray[0].ordine+1),
        ordine: +filterderArray[0].ordine+1,
        fonte: 2,
        statoPagato: 1,
        data: psw
      }

      this.camerieri.unshift(
        ordine
      )

      this.cambiaSchermata(1, this.camerieri.findIndex(cameriere => cameriere.ordine == ordine.ordine), ordine.ordine)

      this.commonService.updateOrdine('c1'+psw, ordine)
    }
    else
    {
      let ordine = {
        ristorante: this.dataServiceGenerico.getRistorante(),
        id: 'c1'+psw,
        displayName: 'Ordine 0001',
        ordine: 1,
        fonte: 2,
        statoPagato: 1,
        data: psw
      }

      this.camerieri.unshift(ordine)

      this.cambiaSchermata(1, this.camerieri.findIndex(cameriere => cameriere.ordine == ordine.ordine), ordine.ordine)

      console.log("camerieri: ", this.camerieri)
      this.camerieri = this.camerieri.sort((a, b) => b.ordine - a.ordine)


      this.commonService.updateOrdine('c1'+psw, ordine)

    }

    this.dataServiceGenerico.setCamerieri(this.camerieri)
  }



  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.commonService.getOrdini(this.dataServiceGenerico.getRistorante(), this.filtroSource, this.filtroStato).subscribe(
      (dataCamerieri) =>
      {
        console.log("dataCameriere: x", dataCamerieri)
        if (dataCamerieri !== undefined) {
          console.log("dataCameriere: ", dataCamerieri)


          //fonte
          //0 = app
          //1 = kiosk
          //2 = cassa
          //3 = tutto

          if(this.filtroSource == 3)
          {
            this.camerieri = dataCamerieri
          }
          else
          {
            this.camerieri = dataCamerieri.filter(ordine => ordine.hasOwnProperty('fonte')? ordine.fonte == this.filtroSource: false)
          }

          if(this.filtroStato == 2)
          {

          }
          else
          {
            this.camerieri = this.camerieri.filter(ordine => ordine.hasOwnProperty('statoPagato')? ordine.statoPagato == this.filtroStato: false)
          }
          //this.camerieri = dataCamerieri.filter(ordine => ordine.hasOwnProperty('fonte')? ordine.fonte == 0: false).filter(ordine => ordine.hasOwnProperty('statoPagato')? ordine.statoPagato == 0: false)
          this.dataServiceGenerico.setCamerieri(this.camerieri)


          this.menu.forEach(categoria => {
            console.log("categoria: ", categoria)
            if(categoria.hasOwnProperty('menu'))
            {
              console.log("carrello: ", categoria.menu)
              categoria.menu.forEach(prodotto => {
                if(prodotto.hasOwnProperty('id'))
                {
                  if(this.carrello !== undefined)
                  {
                    this.carrello['carrello'][prodotto.id] = {
                      quantita: 0
                    };
                    console.log("carrello 2: ", this.carrello['carrello'])
                  }
                }
              })
            }
          })
        }
        else
        {
          this.camerieri = []
          this.dataServiceGenerico.setCamerieri(this.camerieri)


          this.menu.forEach(categoria => {
            console.log("categoria: ", categoria)
            if(categoria.hasOwnProperty('menu'))
            {
              console.log("carrello: ", categoria.menu)
              categoria.menu.forEach(prodotto => {
                if(prodotto.hasOwnProperty('id'))
                {
                  this.carrello['carrello'][prodotto.id] = {
                    quantita: 0
                  };
                  console.log("carrello 2: ", this.carrello['carrello'])
                }
              })
            }
          })
        }
      }
    )

    this.commonService.getRistorante().subscribe(
      (data) =>
      {
        console.log("ciaociao; ", data)
        if(data !== undefined)
        {
          //this.idRistorante = data[0].hasOwnProperty('id')? data[0]['id']: '';

          //this.menu = data[0].hasOwnProperty('magazzino')? data[0]['magazzino']: [];

          console.log("idristorante: ", this.idRistorante)
        }
      }
    );


    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;

          console.log(this.carrelloTmp);

          this.calcolaTotale();


        }
      }
    );
  }

  getIndex(): number
  {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == this.ordineOrdine)
  }

  getIndexOrdine(ordine: any): number
  {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine)
  }

  calcolaTotale() {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if(this.camerieri[this.getIndex()] !== undefined)
      {
        if (this.camerieri[this.getIndex()].hasOwnProperty('carrello')) {
          this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(piatto => {
            //console.log('piatto', piatto);
            this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndex()]?.carrello[piatto].quantita;

            let prezzoPiatto = this.camerieri[this.getIndex()]?.carrello[piatto].prezzo;
            this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndex()]?.carrello[piatto].quantita);
            //console.log("tot nuovo: ", this.tot)
          });
        }
      }
    }
    return this.tot
  }


  calcolaTotaleOrdine(ordine: any) {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if (this.camerieri[this.getIndexOrdine(ordine)].hasOwnProperty('carrello')) {
        this.getProperties(this.camerieri[this.getIndexOrdine(ordine)]?.carrello).forEach(piatto => {
          //console.log('piatto', piatto);
          this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita;

          let prezzoPiatto = this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].prezzo;
          this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita);
          //console.log("tot nuovo: ", this.tot)
        });
      }
    }
    return this.tot.toFixed(2)
  }

  modificaPrezzp(numerino: any): void {

    if(numerino == 'elimina'){
      this.prezzoPiatto = this.prezzoPiatto.slice(0, -1);
    }else{
      this.prezzoPiatto += numerino.toString();
    }

  }
  ordineOrdine = '';

  ordine = [];
  cambiaSchermata(schermo: number, index: any, index2: any): void {

    this.ordine = [];
    this.schermata = schermo;
    this.schermataIndex = index;
    this.ordineOrdine = index2;
    console.log("ordineOrdine: ", this.ordineOrdine, this.schermata, this.schermataIndex);
    this.calcolaTotale()
  }

  aggiungiPiatto(prezzo: any, index: number): void {
    this.tavoli[index].scontrino.push({piatto: "Piatto personalizzato", descrizione: "", quantita: 1, prezzo: prezzo},)

  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }

  cucina()
  {
    this.router.navigateByUrl('cucina#nonav');
  }


}
