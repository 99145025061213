import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../../menu/menu";
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {MatTableDataSource} from "@angular/material/table";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";


@Component({
  selector: 'personale',
  templateUrl: './personale.html',
  styleUrls: ['./personale.css']
})
  export class Personale implements OnInit{


  displayedColumns = [ 'nome', 'permessi' ]

  dragPosition = {x: 253, y: 244};

  dragPosition2 = {x: 253, y: 244};


  ruoli = [
    {
      nome: 'Admin',
      permessi: [
        'Cassa',
        'Report',
        'Menu',
        'Magazzino',
        'Impostazioni Ristorante',
        'Impostazioni Personali',
      ]
    },
    {
      nome: 'Cassiere',
      permessi: [
          'Cassa',
          'Impostazioni Personali'
      ]
    }
  ]

  permessi = [
      'Cassa',
      'Report',
      'Menu',
      'Magazzino',
      'Impostazioni Ristorante',
      'Impostazioni Personali',
  ]

  organico = [
    {
      nome: 'Alessandro',
      cognome: 'Barbieri',
      mail: 'a.b@fillapp.it',
      ruolo: 'Admin',
      permessi: []
    },
    {
      nome: 'Klevi',
      cognome: 'Hoxha',
      mail: 'k.h@fillapp.it',
      ruolo: 'Cassiere',
      permessi: []
    }
  ]
  constructor(
    private router: Router, private userService: UserDataService,
    private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    private ipcService: IpcService, private posTransactionService: PosTransactionService,
    private dataServiceGenerico: DataServiceGenerico
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');


  }

  dropped(e)
  {
    console.log("droppato: ", e)
    console.log("droppato 2: ", this.dragPosition)

  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()

    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);


  }


  dataSource = new MatTableDataSource<any>([]);

  columns: any[] = [{
    field: 'nome'
  }, {
    field: 'cognome'
  }, {
    field: 'mail'
  }, {
    field: 'ruolo'
  },
    {
      field: 'permessi'
    }
  ];
  displayedColumns2: string[] = this.columns.map(column => column.field);
  groupByColumns: string[] = ['ruolo'];


  addCose()
  {
    this.organico.push(
        {
          nome: 'Klevi',
          cognome: 'Hoxha',
          mail: 'k.h@fillapp.it',
          ruolo: 'Admin',
          permessi: []
        }
    )

    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);

  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  checkGroupByColumn(field, add ) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if ( add ) {
        this.groupByColumns.push(field);
      }
    }
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this.organico, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
  }

  // below is for grid row grouping
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(
        row => {
          if (!(row instanceof Group)) {
            return false;
          }
          let match = true;
          this.groupByColumns.forEach(column => {
            if (!row[column] || !data[column] || row[column] !== data[column]) {
              match = false;
            }
          });
          return match;
        }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();  // bug here need to fix
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
        data.map(
            row => {
              const result = new Group();
              result.level = level + 1;
              result.parent = parent;
              for (let i = 0; i <= level; i++) {
                result[groupByColumns[i]] = row[groupByColumns[i]];
              }
              return result;
            }
        ),
        JSON.stringify);

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }


}


class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true;
    this.visible = false; // Per default i gruppi sono nascosti
    this.totalCounts = 0;
  }
}