import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CommonService } from '../../../shared/services/firestore/common.service';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import { TavoliService } from '../../../shared/services/tavoli.service';
import { DailyReceiptService } from '../../../shared/services/daily-receipt.service';
import { IpcService } from '../../../shared/services/ipc.service';
import { AuthService } from '../../../shared/services/auth.service';
import { AzioniStampanteComponent } from '../../azioniStampante/azioniStampante';

@Component({
  selector: 'app-modern-pos',
  templateUrl: './cassa-ordini-updated.component.html',
  styleUrls: ['./cassa-ordini-updated.component.scss']
})
export class ModernPosComponent implements OnInit {
  // Stato UI
  activeTab = 'receipts';
  selectedOrder: any = null;
  showFilters = false;

  // Helper methods
  setShowFilters(show: boolean): void {
    this.showFilters = show;
  }

  getFonteText(fonte: number): string {
    switch(fonte) {
      case 0: return 'App';
      case 1: return 'Kiosk';
      default: return 'Banco';
    }
  }

  // Variabili dal componente originale
  lista = 0;
  filtroSource = 2;
  filtroStato = 1;
  camerieri: any[] = [];
  schermataIndex: number = 0;
  tavoli: any[] = [];
  ordineOrdine = '';

  // Modificatori
  tavoliDaUnire: string[] = [];
  modalitaUnione = false;
  modalitaSpostamento = false;
  tavoloDaSpostare = '';
  eliminazione = false;

  constructor(
      private router: Router,
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private userDataService: UserDataService,
      private tavoliService: TavoliService,
      private dailyReceiptService: DailyReceiptService,
      private ipcService: IpcService,
      private authService: AuthService,
      public dialog: MatDialog
  ) {}

    async ngOnInit() {
        await this.loadViewPreference();

        const ristoranteId = await this.dataServiceGenerico.getCurrentRestaurantId();
        if (ristoranteId) {
            // Assicuriamoci che il ristorante sia impostato prima di recuperare gli ordini
            this.retriveCarrello();

            this.tavoliService.getTavoli(ristoranteId).subscribe((tavoli) => {
                this.tavoli = tavoli.sort((a, b) => {
                    // Logica di ordinamento tavoli dal codice originale
                    const valA = String(a.numero);
                    const valB = String(b.numero);

                    if (valA === 'Banco') return -1;
                    if (valB === 'Banco') return 1;

                    return parseInt(valA) - parseInt(valB);
                });
            });
        }
    }

    retriveCarrello(): void {
        const ristorante = this.dataServiceGenerico.getRistorante();

        if (!ristorante) {
            console.error('ID ristorante non disponibile');
            return;
        }

        this.commonService.getOrdini(
            ristorante,
            this.filtroSource,
            this.filtroStato
        ).subscribe((dataCamerieri) => {
            if (dataCamerieri !== undefined) {
                this.camerieri = dataCamerieri;
                this.dataServiceGenerico.setCamerieri(this.camerieri);

                if (this.camerieri && this.camerieri.length > 0 && !this.selectedOrder) {
                    this.selectedOrder = this.camerieri[0];
                }
            } else {
                this.camerieri = [];
                this.dataServiceGenerico.setCamerieri(this.camerieri);
            }
        });
    }

  // Metodi UI
  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }


  openOrderDetail(order: any): void {
    this.selectedOrder = order;
  }

  // Metodi esistenti dal componente originale
  async loadViewPreference(): Promise<void> {
    try {
      this.lista = await this.dataServiceGenerico.getViewPreference();
    } catch (error) {
      console.error('Error loading view preference:', error);
    }
  }

  async changeView(viewIndex: number): Promise<void> {
    this.lista = viewIndex;
    await this.dataServiceGenerico.saveViewPreference(viewIndex);
  }


  getProperties(object: any) {
    if (object !== undefined) {
      return Object.keys(object);
    }
    return [];
  }

  getIndex(): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == this.ordineOrdine);
  }

  getIndexOrdine(ordine: any): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine);
  }

  calcolaTotaleOrdine(ordine: any) {
    let numeroElementi = 0;
    let tot = 0;

    if (this.camerieri.length > 0) {
      const index = this.getIndexOrdine(ordine);
      if (index >= 0 && this.camerieri[index].hasOwnProperty('carrello')) {
        this.getProperties(this.camerieri[index]?.carrello).forEach(piatto => {
          numeroElementi = numeroElementi + this.camerieri[index]?.carrello[piatto].quantita;
          let prezzoPiatto = this.camerieri[index]?.carrello[piatto].prezzo;
          tot = tot + (prezzoPiatto * this.camerieri[index]?.carrello[piatto].quantita);
        });
      }
    }
    return tot.toFixed(2);
  }

  // Filtri
  filtraApp() {
    this.filtroSource = 0;
    this.retriveCarrello();
  }

  filtraChiosco() {
    this.filtroSource = 1;
    this.retriveCarrello();
  }

  filtraCassa() {
    this.filtroSource = 2;
    this.retriveCarrello();
  }

  filtraTutto() {
    this.filtroSource = 3;
    this.retriveCarrello();
  }

  filtraPagato() {
    this.filtroStato = 0;
    this.retriveCarrello();
  }

  filtraNonPagato() {
    this.filtroStato = 1;
    this.retriveCarrello();
  }

  filtraCompletato() {
    this.filtroStato = 3;
    this.retriveCarrello();
  }

  filtraStatoTutto() {
    this.filtroStato = 2;
    this.retriveCarrello();
  }

  // Gestione ordini
  eliminaOrdine(ordine: any) {
    console.log("elimina ordine: ", ordine);
    const index = this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine);
    if (index !== -1) {
      this.commonService.deleteOrdine(this.camerieri[index].id);
      this.camerieri.splice(index, 1);
      this.dataServiceGenerico.setCamerieri(this.camerieri);
    }
  }

  async aggiungiOrdine(tavolo?: any) {
    let priceListType: 'counter' | 'takeaway' | 'table' = 'counter';
    let displayNamePrefix = 'Banco';

    if (tavolo) {
      priceListType = 'table';
      displayNamePrefix = 'Tavolo';
    }

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        this.dataServiceGenerico.getRistorante1()
    );

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: tavolo ? `${displayNamePrefix} ${tavolo.numero}` : `${displayNamePrefix} #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      tavolo: tavolo ? tavolo.numero.toString() : null,
      selectedPriceListType: priceListType
    };

    this.camerieri.unshift(ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);
    this.cambiaSchermata(ordine.id);
  }

  async addTakeawayOrder() {
    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        this.dataServiceGenerico.getRistorante1()
    );

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: `Asporto #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      selectedPriceListType: 'takeaway'
    };

    this.camerieri.unshift(ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);
    this.cambiaSchermata(ordine.id);
  }

  // Gestione tavoli
  findTavolo(tavolo: any) {
    if (this.camerieri && this.camerieri.length > 0) {
      let index = this.camerieri.findIndex(c => c.tavolo == tavolo);
      if (index > -1) {
        return this.camerieri[index];
      } else {
        return null;
      }
    }
    return null;
  }

  findIndexTavolo(tavolo: any) {
    let index = this.camerieri.findIndex(c => c.tavolo == tavolo);
    if (index > -1) {
      return index;
    } else {
      this.aggiungiOrdine({numero: tavolo});
    }
  }

  selezionaTavoloPerUnione(numeroTavolo: string) {
    if (this.modalitaUnione) {
      const index = this.tavoliDaUnire.indexOf(numeroTavolo);
      if (index > -1) {
        this.tavoliDaUnire.splice(index, 1);
      } else if (this.tavoliDaUnire.length < 2) {
        this.tavoliDaUnire.push(numeroTavolo);
      }

      if (this.tavoliDaUnire.length === 2) {
        this.unisciTavoli();
      }
    } else if (this.modalitaSpostamento) {
      if (this.tavoloDaSpostare == '') {
        const ordineEsistente = this.findTavolo(numeroTavolo);
        if (ordineEsistente) {
          this.tavoloDaSpostare = numeroTavolo;
        }
      } else {
        const ordineOrigine = this.findTavolo(this.tavoloDaSpostare);
        if (ordineOrigine) {
          ordineOrigine.tavolo = numeroTavolo;
          ordineOrigine.displayName = `Tavolo ${numeroTavolo}`;
          this.commonService.updateOrdine(ordineOrigine.id, ordineOrigine);
        }
        this.tavoloDaSpostare = '';
        this.modalitaSpostamento = false;
      }
    } else {
      const tavolo = this.findTavolo(numeroTavolo);
      if (tavolo) {
        this.cambiaSchermata(tavolo.id);
      } else {
        this.findIndexTavolo(numeroTavolo);
      }
    }
  }

  unisciTavoli() {
    if (this.tavoliDaUnire.length !== 2) {
      console.error('Seleziona esattamente due tavoli da unire');
      return;
    }

    const [primoTavolo, secondoTavolo] = this.tavoliDaUnire;
    const ordinePrimoTavolo = this.camerieri.find(c => c.tavolo === primoTavolo);
    const ordineSecondoTavolo = this.camerieri.find(c => c.tavolo === secondoTavolo);

    if (ordinePrimoTavolo && ordineSecondoTavolo) {
      if (!ordineSecondoTavolo.carrello) {
        ordineSecondoTavolo.carrello = {};
      }

      if (ordinePrimoTavolo.carrello) {
        Object.keys(ordinePrimoTavolo.carrello).forEach(prodottoKey => {
          if (ordineSecondoTavolo.carrello[prodottoKey]) {
            ordineSecondoTavolo.carrello[prodottoKey].quantita +=
                ordinePrimoTavolo.carrello[prodottoKey].quantita;
          } else {
            ordineSecondoTavolo.carrello[prodottoKey] = {
              ...ordinePrimoTavolo.carrello[prodottoKey]
            };
          }
        });

        if (ordinePrimoTavolo.ordineInserimento) {
          if (!ordineSecondoTavolo.ordineInserimento) {
            ordineSecondoTavolo.ordineInserimento = [];
          }
          ordinePrimoTavolo.ordineInserimento.forEach(prodotto => {
            if (!ordineSecondoTavolo.ordineInserimento.includes(prodotto)) {
              ordineSecondoTavolo.ordineInserimento.push(prodotto);
            }
          });
        }
      }

      this.commonService.updateOrdine(ordineSecondoTavolo.id, ordineSecondoTavolo);
      this.eliminaOrdine(ordinePrimoTavolo.ordine);

      this.tavoliDaUnire = [];
      this.modalitaUnione = false;
    }
  }

  attivaModalitaUnione() {
    this.modalitaUnione = true;
    this.modalitaSpostamento = false;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
    this.tavoloDaSpostare = '';
  }

  attivaModalitaSpostamento() {
    this.modalitaSpostamento = true;
    this.modalitaUnione = false;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
    this.tavoloDaSpostare = '';
  }

  // Navigazione
  // Metodi aggiuntivi richiesti dal template
  apricassetto(): void {
    this.ipcService.printReceipt('', 'apriSolo', 'rch');
  }

  apriazioniStampanteDialog(): void {
    const dialogRef = this.dialog.open(AzioniStampanteComponent, {
      width: '645px',
      height: '490px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog chiuso', result);
    });
  }

  logout(): void {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  // Navigazione
  cambiaSchermata(idOrdine: any): void {
    this.router.navigate(['/cassa/order', idOrdine], { fragment: 'nonav' });
  }
}