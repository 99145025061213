import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add-role-dialog',
    template: `
        <div class="dialog-container">
            <div class="sticky-container">
                <div class="save-button">
                    <img src="assets/icons/save-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Salva Ruolo"
                         (click)="onSubmit()">
                </div>
                <div class="close-button">
                    <img src="assets/icons/close-icon.png"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Chiudi"
                         (click)="onCancel()">
                </div>
            </div>

            <div style="height: 80vh; max-width: 500px;">
                <div class="example-container">
                    <form [formGroup]="roleForm">
                        <fieldset class="custom-fieldset">
                            <legend>Nome ruolo</legend>
                            <div class="chart-container">
                                <input placeholder="Nome Ruolo"
                                       class="custom-input"
                                       formControlName="nome">
                            </div>
                        </fieldset>

                        <fieldset class="custom-fieldset">
                            <legend>Permessi</legend>
                            <div class="chart-container">
                                <mat-select placeholder="Seleziona permessi"
                                            multiple
                                            class="custom-input"
                                            formControlName="permessi">
                                    <mat-option *ngFor="let permesso of permessiDisponibili"
                                                [value]="permesso">
                                        {{permesso}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .dialog-container {
            position: relative;
        }

        .sticky-container {
            position: sticky;
            top: 0px;
            right: 0px;
            z-index: 1000;
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: flex-end;
            cursor: pointer;
        }

        .close-button {
            width: 30px;
            aspect-ratio: 1 / 1;
            margin-top: -15px;
            margin-right: -15px;
        }

        .save-button {
            width: 30px;
            margin-top: -15px;
        }

        .custom-fieldset {
            margin-bottom: 10px;
            border-radius: 20px;
            height: auto;
            border: 2px solid rgba(222,214,214,0.57);
            display: flex;
            flex-direction: column;
            transition: border-color 0.3s ease;
        }

        .custom-fieldset legend {
            font-weight: 600;
            font-size: 16px;
        }

        .custom-input {
            border: none;
            outline: none;
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
            transition: all 0.3s ease;
        }

        .custom-fieldset:focus-within {
            border-color: #000;
        }

        .custom-input:focus {
            border: none;
            outline: none;
        }
    `]
})
export class AddRoleDialogComponent {
    roleForm: FormGroup;
    permessiDisponibili = [
        'Cassa',
        'Report',
        'Menu',
        'Magazzino',
        'Impostazioni Ristorante',
        'Impostazioni Personali',
    ];

    constructor(
        private dialogRef: MatDialogRef<AddRoleDialogComponent>,
        private fb: FormBuilder
    ) {
        this.roleForm = this.fb.group({
            nome: ['', [Validators.required, Validators.minLength(2)]],
            permessi: [[], [Validators.required, Validators.minLength(1)]]
        });
    }

    onSubmit(): void {
        if (this.roleForm.valid) {
            this.dialogRef.close(this.roleForm.value);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}