import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InventoryAvailabilityService } from '../../../shared/services/firestore/inventory-availability.service';

@Component({
    selector: 'app-product-availability',
    template: `
        <div class="availability-indicator" *ngIf="disponibilita$ | async as disponibilita">
            <div class="indicator"
                 [ngClass]="{'available': disponibilita > criticalThreshold, 'low': disponibilita <= criticalThreshold && disponibilita > 0, 'unavailable': disponibilita <= 0}">
                <div class="quantity" *ngIf="showQuantity">
                    {{ disponibilita }}
                </div>
            </div>
            
        </div>
    `,
    styles: [`
        .availability-indicator {
            position: absolute;
            top: 5px;
            left: 5px;
            gap: 5px;



            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 600;
        }

        .indicator {
            width: 25px;
            height: 25px;
            border-radius: 50%;
        }

        .available {
            background-color: #34bfa3;
        }

        .low {
            background-color: #ffb822;
        }

        .unavailable {
            background-color: #f44336;
        }

        .quantity {
            position: absolute;
            top: 5px;
            left: 5px;
        }
    `]
})
export class ProductAvailabilityComponent implements OnInit {
    @Input() productId: string;
    @Input() showQuantity: boolean = false;
    @Input() criticalThreshold: number = 5;

    disponibilita$: Observable<number>;

    constructor(private availabilityService: InventoryAvailabilityService) {}

    ngOnInit() {
        if (this.productId) {
            // Utilizziamo catchError per assicurarci che un errore non blocchi l'UI
            this.disponibilita$ = this.availabilityService.checkAvailability(this.productId)
                .pipe(
                    catchError(err => {
                        console.error('Errore nel recupero disponibilità:', err);
                        return of(999); // Valore di fallback alto
                    })
                );
        } else {
            // Se non c'è ID prodotto, mostriamo disponibilità alta
            this.disponibilita$ = of(999);
        }
    }
}