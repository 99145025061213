<!-- edit-predefined-sconti.component.html -->
<div class="dialog-container">
    <div class="sticky-container">
        <div class="save-button" *ngIf="data?.nome">
            <img src="assets/icons/icons8-close-90.png"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Elimina Sconto"
                 (click)="deleteSconto()">
        </div>



        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Sconto"
                 (click)="saveSconto()">
        </div>
        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>

    <div style="height: 80vh; overflow-y: auto; padding: 20px;">
        <form [formGroup]="scontoForm">
            <div style="margin-bottom: 20px">
                <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
                    <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                        <!-- Nome -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">Nome Sconto</label>
                            <input placeholder="Nome" class="custom-input" formControlName="nome">
                        </div>

                        <!-- Tipo Sconto -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">Tipo Sconto</label>
                            <div class="flex flex-col gap-3">
                                <div>
                                    <label class="container" style="font-size: 16px">Percentuale
                                        <input type="radio" value="percentage" formControlName="tipo">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div>
                                    <label class="container" style="font-size: 16px">Valore
                                        <input type="radio" value="value" formControlName="tipo">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- Valore -->
                        <div class="flex flex-col gap-2">
                            <label class="font-bold text-gray-700 text-heading-sm">
                                {{scontoForm.get('tipo').value === 'percentage' ? 'Percentuale sconto' : 'Valore sconto (€)'}}
                            </label>
                            <input type="number"
                                   [placeholder]="scontoForm.get('tipo').value === 'percentage' ? 'Es: 10' : 'Es: 5'"
                                   class="custom-input"
                                   formControlName="valore">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>