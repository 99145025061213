import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';

@Component({
  selector: 'app-add-ordine-magazzino',
  templateUrl: './add-ordine-magazzino.html',
  styleUrls: ['./add-ordine-magazzino.css']
})
export class AddOrdineMagazzino implements OnInit, OnDestroy {
  // Dati prodotto
  product: any = {};
  originalPrice: number = 0;
  totalPrice: number = 0;
  totalQuantity: number = 0;
  isInCart: boolean = false;

  // Livelli di imballaggio
  maxLvl: number = 2; // Default a livello 2
  // Valori per la selezione del livello attivo
  isLvl1: boolean = false;
  isLvl2: boolean = true; // Default a livello 2
  isLvl3: boolean = false;
  isLvl4: boolean = false;

  // Livelli attuali (quelli che l'utente modifica)
  levels: any[] = [
    { quantita: '0', um: 'pz' },
    { quantita: '0', um: 'confezione' },
    { quantita: '0', um: '' },
    { quantita: '0', um: '' }
  ];

  // Livelli originali (quelli salvati nel prodotto)
  originalLevels: any[] = [
    { quantita: '1', um: 'pz' },
    { quantita: '6', um: 'confezione' },
    { quantita: '0', um: '' },
    { quantita: '0', um: '' }
  ];

  constructor(
      private dialogRef: MatDialogRef<AddOrdineMagazzino>,
      private dataService: DataServiceGenerico,
      private snackBar: MatSnackBar,
      private cdRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      // Imposta i dati base del prodotto
      this.product = {...this.data};
      this.originalPrice = parseFloat(this.data.Prezzo) || 0;

      // Inizializza i livelli di imballaggio
      this.initializePackagingLevels();

      // Controlla se il prodotto è già nel carrello
      this.checkCartForProduct();
    }
  }

  ngOnDestroy(): void {
    // Cleanup se necessario
  }

  private initializePackagingLevels(): void {
    // Se il prodotto ha livelli definiti, usali
    if (this.product.lvl && this.product.lvl.length > 0) {
      // Imposta maxLvl dal prodotto
      this.maxLvl = this.product.maxLvl || 2;

      // Copia i livelli dal prodotto
      for (let i = 0; i < this.product.lvl.length; i++) {
        this.originalLevels[i] = { ...this.product.lvl[i] };
        this.levels[i].um = this.product.lvl[i].um || '';
      }
    } else {
      // Imposta valori di default se non ci sono livelli
      this.originalLevels[0] = {
        quantita: '1',
        um: this.product.UM || 'pz'
      };
      this.levels[0].um = this.product.UM || 'pz';

      // Imposta il livello di default
      this.maxLvl = 2;
    }

    // Imposta i flag per la visualizzazione in base a maxLvl
    this.updateLevelFlags();
  }

  // Aggiorna i flag di visualizzazione in base a maxLvl
  private updateLevelFlags(): void {
    this.isLvl1 = false;
    this.isLvl2 = false;
    this.isLvl3 = false;
    this.isLvl4 = false;

    // Attiva il flag corrispondente a maxLvl
    switch(this.maxLvl) {
      case 1:
        this.isLvl1 = true;
        break;
      case 2:
        this.isLvl2 = true;
        break;
      case 3:
        this.isLvl3 = true;
        break;
      case 4:
        this.isLvl4 = true;
        break;
      default:
        this.isLvl2 = true; // Default a livello 2
    }
  }

  // Gestisce il click sui pulsanti di selezione livello
  onLevelSelect(level: number): void {
    this.maxLvl = level;
    this.updateLevelFlags();
  }

  async checkCartForProduct(): Promise<void> {
    try {
      const db = firebase.firestore();
      const restaurantId = await this.dataService.getRistorante1();
      const cartRef = db.collection('ristoranti').doc(restaurantId);

      const doc = await cartRef.get();
      if (doc.exists) {
        const data = doc.data();
        const cartItems = data?.carrelloMagazzino || [];
        const existingProduct = cartItems.find(item => item.id === this.product.id);

        if (existingProduct) {
          this.isInCart = true;
          this.totalQuantity = parseInt(existingProduct.quantita) || 0;

          // Imposta le quantità dal carrello
          if (existingProduct.QuantitaPerUM && existingProduct.QuantitaPerUM.length > 0) {
            existingProduct.QuantitaPerUM.forEach((qty, index) => {
              if (index < this.levels.length) {
                this.levels[index].quantita = qty;
              }
            });
          } else if (existingProduct.quantita) {
            this.levels[0].quantita = existingProduct.quantita;
          }

          this.calculateTotalQuantityAndPrice();
        }
      }
    } catch (error) {
      console.error('Error checking cart for product:', error);
    }
  }

  calculateTotalQuantityAndPrice(): void {
    try {
      let totalQty = 0;

      // Calcola la quantità totale basandosi sui livelli
      // Per livello 1, quantità diretta
      const level1Qty = parseInt(this.levels[0].quantita) || 0;
      totalQty += level1Qty;

      // Per livello 2 (se attivo), quantità * moltiplicatore livello 1
      if (this.maxLvl >= 2 && parseInt(this.levels[1].quantita) > 0) {
        const level2Qty = parseInt(this.levels[1].quantita) || 0;
        const level1Multiplier = parseInt(this.originalLevels[0].quantita) || 1;
        totalQty += level2Qty * level1Multiplier;
      }

      // Per livello 3 (se attivo), quantità * moltiplicatore livello 1 * moltiplicatore livello 2
      if (this.maxLvl >= 3 && parseInt(this.levels[2].quantita) > 0) {
        const level3Qty = parseInt(this.levels[2].quantita) || 0;
        const level1Multiplier = parseInt(this.originalLevels[0].quantita) || 1;
        const level2Multiplier = parseInt(this.originalLevels[1].quantita) || 1;
        totalQty += level3Qty * level1Multiplier * level2Multiplier;
      }

      // Per livello 4 (se attivo), quantità * moltiplicatore livello 1 * moltiplicatore livello 2 * moltiplicatore livello 3
      if (this.maxLvl >= 4 && parseInt(this.levels[3].quantita) > 0) {
        const level4Qty = parseInt(this.levels[3].quantita) || 0;
        const level1Multiplier = parseInt(this.originalLevels[0].quantita) || 1;
        const level2Multiplier = parseInt(this.originalLevels[1].quantita) || 1;
        const level3Multiplier = parseInt(this.originalLevels[2].quantita) || 1;
        totalQty += level4Qty * level1Multiplier * level2Multiplier * level3Multiplier;
      }

      this.totalQuantity = totalQty;
      this.totalPrice = this.originalPrice * this.totalQuantity;

      // Assicurati che il prezzo non sia mai negativo
      if (this.totalPrice < 0) this.totalPrice = 0;

      this.cdRef.detectChanges();
    } catch (error) {
      console.error('Error calculating total price:', error);
      this.totalPrice = 0;
    }
  }

  async saveChangesToFirestore(): Promise<void> {
    try {
      // Controlla che almeno una quantità sia inserita
      const hasQuantity = this.levels.some((level, index) =>
          index < this.maxLvl && parseInt(level.quantita || '0') > 0
      );

      if (!hasQuantity) {
        this.snackBar.open('Inserisci almeno una quantità', 'Chiudi', { duration: 3000 });
        return;
      }

      // Calcola i totali finali prima di salvare
      this.calculateTotalQuantityAndPrice();

      // Ottieni riferimento a Firebase
      const db = firebase.firestore();
      const restaurantId = await this.dataService.getRistorante1();
      const cartRef = db.collection('ristoranti').doc(restaurantId);

      // Prepara i dati del prodotto per il carrello
      const productData = {
        id: this.product.id,
        Titolo: this.product.Titolo,
        Categoria: this.product.Categoria,
        CodiceFornitore: this.product.CodiceFornitore || '',
        Fornitore: this.product.Fornitore || '',
        image_link: this.product.image_link || '',
        // Mantieni il prezzo unitario originale
        Prezzo: this.originalPrice.toFixed(2),
        quantita: this.totalQuantity.toString(),
        QuantitaPerUM: this.levels.slice(0, this.maxLvl).map(l => l.quantita || '0'),
        Scadenza: this.product.Scadenza || '',
        SyncMenu: this.product.SyncMenu || '0',
        UM: this.product.UM || '',
        lvl: this.originalLevels.slice(0, this.maxLvl),
        maxLvl: this.maxLvl
      };

      // Aggiorna il carrello
      const doc = await cartRef.get();
      if (doc.exists) {
        const data = doc.data();
        let cartItems = data?.carrelloMagazzino || [];
        const existingProductIndex = cartItems.findIndex(item => item.id === productData.id);

        if (existingProductIndex !== -1) {
          // Aggiorna prodotto esistente
          cartItems[existingProductIndex] = productData;
        } else {
          // Aggiungi nuovo prodotto
          cartItems.push(productData);
        }

        await cartRef.update({ carrelloMagazzino: cartItems });
      } else {
        // Crea nuovo carrello
        await cartRef.set({ carrelloMagazzino: [productData] });
      }

      this.snackBar.open('Prodotto aggiunto al carrello', 'Chiudi', { duration: 2000 });
      this.dialogRef.close({ added: true, product: productData });
    } catch (error) {
      console.error('Error saving product to cart:', error);
      this.snackBar.open('Errore durante il salvataggio', 'Chiudi', { duration: 3000 });
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}