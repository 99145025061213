<div class="row">
    <div class="column">
        <div style="
      width: 100%;
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 15px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      align-items: center;
        ">
            <div style="width: 70%; height: 100%; margin-left: 10px; display: flex">
                <div [ngStyle]="getTabStyle('menu')"

                     (click)="selezione = 'menu'">
                    Anagrafica
                </div>
                <div [ngStyle]="getTabStyle('fornitori')"
                     *ngIf="hasPermessoForPagina('fornitori')"
                     (click)="selezione = 'fornitori'">

                    Fornitori
                </div>
                <div [ngStyle]="getTabStyle('inventario')"
                     *ngIf="hasPermessoForPagina('inventario')"
                     (click)="selezione = 'inventario'">
                    Inventario
                </div>
                <div [ngStyle]="getTabStyle('listini')"
                     *ngIf="hasPermessoForPagina('listini')"
                     (click)="selezione = 'listini'">
                    Listini
                </div>
                <div [ngStyle]="getTabStyle('categorie')"
                     *ngIf="hasPermessoForPagina('categorie')"
                     (click)="selezione = 'categorie'">
                    Categorie
                </div>
                <div [ngStyle]="getTabStyle('varianti')"
                     *ngIf="hasPermessoForPagina('varianti')"
                     (click)="selezione = 'varianti'">
                    Varianti
                </div>
                <div [ngStyle]="getTabStyle('preferiti')"
                     *ngIf="hasPermessoForPagina('preferiti')"
                     (click)="selezione = 'preferiti'">
                    Preferiti
                </div>
                <div [ngStyle]="getTabStyle('upselling')"
                     *ngIf="hasPermessoForPagina(13)"
                     (click)="selezione = 'upselling'">
                    UpSelling
                </div>
                <div [ngStyle]="getTabStyle('esporta')"
                     (click)="triggerFileInput()"
                >

                    <img src="assets/icons/icons8-import-90.png" style=" aspect-ratio: 1; width: 22px; margin-right: 5px; cursor: pointer">
                    <p  style="color: rgb(54 191 163) !important; cursor: pointer" >Importa</p>
                </div>
                <div [ngStyle]="getTabStyle('esporta')"
                     (click)="exportMenuToCSV()"
                >
                    <img src="assets/icons/icons8-export-90.png" style=" aspect-ratio: 1; width: 22px; margin-right: 5px; cursor: pointer">
                    <p  style="color: rgb(52 152 219) !important; cursor: pointer" >Esporta</p>
                </div>
                <input type="file" id="fileInput" style="display: none" (change)="onFileSelected($event)">

            </div>
            <div style="width: calc(30% - 10px);
                        height: 100%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
">
                <div class="menu-options" >
                    <!-- Select for available menus -->


                    <!--select [(ngModel)]="this.selectedMenu" (change)="onMenuSelect($event)" style=" height: 40px; border: none; padding: 5px 5px; font-weight: 600; font-size: 15px; font-family: 'Inter', sans-serif">
                        <option *ngFor="let menu of availableMenus" [value]="menu">{{menu}}</option>
                    </select-->

                    <!-- Button to create a new menu -->
                    <!--img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7"
                          style="width: 30px; aspect-ratio: 1 / 1; cursor: pointer" (click)="createNewMenu();"-->

                </div>
            </div>
        </div>

        <!-- Simple Search Bar Component -->
        <div class="simple-search-container" *ngIf="selezione == 'menu'">
            <div class="flex-container">
                <div class="simple-search-input-container">
                    <input type="text" class="simple-search-input" placeholder="Cerca prodotto..." [(ngModel)]="searchText" (keyup)="applyFilter()">
                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-search-100%20(1).png?alt=media&token=b682cc61-602e-476d-aef0-a4f0f2573632" class="search-icon" alt="Cerca" *ngIf="false">
                </div>
                <button class="toggle-categories-button" (click)="toggleAllCategories()">
                    {{ allCategoriesExpanded ? 'Chiudi categorie' : 'Espandi categorie' }}
                </button>
            </div>
        </div>

        <div class="content-container" *ngIf="selezione == 'menu'">
            <div class="table-section" [ngClass]="{'with-detail': selectedMenuItem}">
                <div class="container menu" id="menuListaPiatti">
                    <div style="pointer-events: auto;">
                        <div style="padding-top: 0%; padding-left: 0px">
                            <div class='table-responsive' style="height: 89.5vh; overflow-y: auto;">
                                <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                                    <!-- Header with Add Button -->


                                    <!-- Existing Columns -->
                                    <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
                                        <mat-header-cell *matHeaderCellDef [ngStyle]="{'justify-content': column.field == 'Disponibilità' ? 'flex-end' : 'inherit'}">
                                            <div *ngIf="column.field != 'Disponibilità'">
                                                {{ column.field }}
                                            </div>
                                            <div *ngIf="column.field === 'Disponibilità'" style="display: flex; align-items: center; margin: 0px">
                                                <categorieCard class="col-12 col-md-12 menuItem" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                                               [nome]="'Aggiungi Prodotto'"
                                                               altezza="45"
                                                               [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7' "
                                                               [bg]="'#FF0000B3'"
                                                               (click)="openDialog()">
                                                </categorieCard>
                                            </div>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let row" (click)="selectMenuItem(row)">
                                            <!-- Title Column with Image -->
                                            <ng-container *ngIf="column.field === 'title'">
                                                <img [src]="row.image_link" alt="Immagine del piatto"
                                                     style="width: 35px; height: 35px; object-fit: cover; margin-right: 10px;"
                                                     *ngIf="row.image_link"/>
                                                <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px;  "
                                                       type="text" class="searchTerm" [value]="row[column.field]" readonly>
                                            </ng-container>

                                            <!-- Standard Column -->
                                            <ng-container *ngIf="column.field !== 'title' && column.field !== 'Disponibilità' && column.field !== 'price'">
                                                <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px;  "
                                                       type="text" class="searchTerm" [value]="row[column.field]" readonly>
                                            </ng-container>

                                            <!-- price Column -->
                                            <ng-container *ngIf="column.field === 'price'">
                                                <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px;  "
                                                       type="text" class="searchTerm" [value]="(+row[column.field]).toFixed(2) + '€'" readonly>

                                            </ng-container>
                                            <ng-container *ngIf="column.field === 'Disponibilità'">
                                                <div class="checkbox_item citem_1" style="width: 30px; margin-left: 20px;">
                                                    <label class="checkbox_wrap">
                                                        <input type="checkbox" name="checkbox" class="checkbox_inp"
                                                               [checked]="row.disponibilita == true"
                                                               (change)="onDisponibilitaChange(row)">
                                                        <span class="checkbox_mark"></span>
                                                    </label>
                                                </div>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Row Definitions -->
                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                                             [class.selected]="selectedMenuItem?.id === row.id">
                                    </mat-row>

                                    <!-- Group Header -->
                                    <ng-container matColumnDef="groupHeader">
                                        <mat-cell colspan="999" *matCellDef="let group">
                                            <mat-icon *ngIf="group.expanded"></mat-icon>
                                            <mat-icon *ngIf="!group.expanded"></mat-icon>
                                            <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                                             (click)="groupHeaderClick(row)">
                                    </mat-row>
                                </mat-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Detail Section -->
            <div class="detail-section" *ngIf="selectedMenuItem">
                <menu-detail
                        [item]="selectedMenuItem"
                        (itemUpdated)="onItemUpdate($event)"
                        (itemDeleted)="onItemDeleted()">
                </menu-detail>
            </div>
        </div>

        <div class=""  *ngIf="selezione == 'fornitori'">
            <app-fornitori></app-fornitori>
        </div>


        <!-- The rest of your HTML content remains unchanged -->
        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'categorie'">
            <edit-categories [collectionType]="'menu_test'"></edit-categories>
        </div>

        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'varianti'">
            <app-dialog-varianti></app-dialog-varianti>
        </div>

        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'preferiti'">
            <preferiti></preferiti>
        </div>

        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'listini'">
            <app-price-lists></app-price-lists>
        </div>

        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'upselling'">
            <app-upselling></app-upselling>
        </div>

        <div class="container menu scheda-interna" style="  border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'inventario'">
            <app-menu-inventory></app-menu-inventory>
        </div>
    </div>
</div>