<div class="dialog-container">
  <div class="sticky-container">
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Elimina Piatto"
           (click)="deleteItemFromFirestore()">
    </div>
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Salva Piatto"
           (click)="saveChangesToFirestore()">
    </div>
    <div class="close-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Chiudi"
           (click)="closeDialog()">
    </div>
  </div>
  <div style="height: 80vh; aspect-ratio: 1 / 1;">
    <div class="example-container" style="max-width: 100%;">

      <fieldset class="custom-fieldset">
        <legend>Nome piatto</legend>
        <div class="chart-container">
          <input placeholder="Nome Piatto" class="custom-input" [(ngModel)]="title">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Categoria</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona Categoria" multiple class="custom-input" [(ngModel)]="selectedCategory">
            <mat-option *ngFor="let category of categories" [value]="category">
              {{category}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Immagine</legend>
        <div class="chart-container" style="display: flex">
          <img *ngIf="selectedImage " [src]="selectedImage" alt="Selected Image" style="max-width: 50px; margin-bottom: 10px;">
          <div style="width: 250px; margin-left: 20px">
            <categorieCard *ngIf="selectedImage && selectedImage.length > 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                           [nome]="'Elimina immagine'"
                           altezza="45"
                           [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd'"

                           (click)="removeImage()">
            </categorieCard>

            <categorieCard *ngIf="!selectedImage || selectedImage.length === 0" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                           [nome]="'Scegli immagine'"
                           altezza="45"
                           [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"

                           (click)="fileInput.click()">
            </categorieCard>

          </div>

          <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Ingredienti</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona ingredienti" multiple class="custom-input" [(ngModel)]="selectedIngredients">
            <mat-option *ngFor="let ingredient of ingredients" [value]="ingredient">
              {{ingredient}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Alergeni</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona allergeni" multiple class="custom-input" [(ngModel)]="selectedAllergens">
            <mat-option *ngFor="let allergen of allergens" [value]="allergen">
              {{allergen}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>

        <!-- Aggiungi questa sezione dopo il fieldset degli allergeni -->
        <fieldset class="custom-fieldset">
            <legend>Seleziona/Crea varianti Prodotto</legend>
            <div class="chart-container">
                <div class="variants-container">
                    <!-- Selezione varianti esistenti -->
                    <div class="variant-selection">
                        <div class="row">

                            <div class="secondaColonna">
                                <mat-select
                                        placeholder="Seleziona varianti"
                                        multiple
                                        class="custom-input"
                                        [(ngModel)]="selectedVariantIds">
                                    <mat-option *ngFor="let variant of variants" [value]="variant.id">
                                        {{variant.name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>

                    <!-- Separatore -->
                    <div class="separator">
                        <span>oppure</span>
                    </div>

                    <!-- Creazione nuova variante -->
                    <div class="new-variant">
                        <div class="row">
                            <div class="primaColonna">
                                <button *ngIf="!newVariant" type="button" class="add-button" (click)="addCustomVariant()">
                                    + Aggiungi Variante
                                </button>
                            </div>

                            <div class="secondaColonna">
                                <div *ngIf="newVariant" class="variant-item">
                                    <div class="variant-header">
                                        <input
                                                placeholder="Nome variante"
                                                class="custom-input"
                                                [(ngModel)]="newVariant.name"
                                                style="width: 150px; margin-right: 10px; font-size: 15px; font-weight: 600;">

                                        €<input
                                                type="number"
                                                placeholder="Prezzo base"
                                                class="custom-input"
                                                [(ngModel)]="newVariant.price"
                                                step="0.10"
                                                style="width: 100px; margin-right: 10px;">

                                        <div style="margin-right: 10px">Obbligatorio: </div>
                                        <div class="checkbox-wrapper-26" style="margin-right: 10px;">
                                            <input
                                                    type="checkbox"
                                                    [id]="'checkbox-required-new'"
                                                    [(ngModel)]="newVariant.isRequired">
                                            <label [for]="'checkbox-required-new'">
                                                <div class="tick_mark"></div>
                                            </label>
                                        </div>

                                        <div style="margin-right: 10px">Max selezionabili: </div>
                                        <input
                                                type="number"
                                                class="custom-input"
                                                [(ngModel)]="newVariant.maxSelections"
                                                step="1"
                                                min="1"
                                                style="width: 80px; margin-right: 10px;">
                                    </div>

                                    <div class="variant-options">
                                        <div *ngFor="let option of newVariant.options" class="option-item">
                                            <input
                                                    placeholder="Nome opzione"
                                                    class="custom-input"
                                                    [(ngModel)]="option.name"
                                                    style="width: 200px; margin-right: 10px;">

                                            €<input
                                                    type="number"
                                                    placeholder="Modifica prezzo"
                                                    class="custom-input"
                                                    [(ngModel)]="option.priceModifier"
                                                    step="0.10"
                                                    style="width: 100px; margin-right: 10px;">

                                            <div style="width: 30px">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                                                     style="width: 30px; aspect-ratio: 1 / 1;"
                                                     (click)="removeOptionFromNewVariant(option.id)">
                                            </div>
                                        </div>

                                        <div class="buttons-container">
                                            <button
                                                    type="button"
                                                    (click)="addOptionToNewVariant()"
                                                    class="add-button">
                                                + Aggiungi Opzione
                                            </button>

                                            <div style="display: flex; justify-content: flex-end; margin-top: 10px">
                                                <button
                                                        type="button"
                                                        (click)="saveNewVariant()"
                                                        class="add-button"
                                                        [disabled]="!newVariant.name">
                                                    Salva Variante
                                                </button>
                                                <button
                                                        type="button"
                                                        (click)="cancelNewVariant()"
                                                        class="add-button"
                                                        style="background-color: white; color: #33bfa3; border: 1px solid #33bfa3; margin-left: 5px">
                                                    Annulla
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Prezzo</legend>
        <div class="chart-container">
          <input placeholder="Prezzo" class="custom-input" [(ngModel)]="price">
        </div>
      </fieldset>

      <fieldset class="custom-fieldset">
        <legend>Azioni</legend>
        <div class="actions-container">
          <div class="action-item">
            <span class="action-label">Diversifica prezzi</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input
                type="checkbox"
                [id]="'checkbox-menu'"
                [(ngModel)]="isMenuChecked"
                (change)="onMenuChange($event)"
              >
              <label [for]="'checkbox-menu'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>
          <div class="action-item">
            <span class="action-label">Consigliato</span>
            <div class="checkbox-wrapper-26" style="width: 30px;">
              <input type="checkbox" [id]="'checkbox-single'" [(ngModel)]="isSingleChecked">
              <label [for]="'checkbox-single'">
                <div class="tick_mark"></div>
              </label>
            </div>
          </div>
          <div class="action-item">
            <span class="action-label">Disponibilità</span>
            <div class="checkbox_item citem_1" style="width: 30px;">
              <label class="checkbox_wrap">
                <input type="checkbox" name="checkbox" class="checkbox_inp" [(ngModel)]="isDisponibileChecked">
                <span class="checkbox_mark"></span>
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="custom-fieldset" *ngIf="isMenuChecked">
        <legend>Prezzi menu</legend>
        <div class="chart-container price-menu-container">
            <label style="padding: 10px; font-size: 14px; font-weight: 500">Banco</label>
          <input placeholder="Menu piccolo" [(ngModel)]="menuPiccolo" class="custom-input price-input">
            <label style="padding: 10px; font-size: 14px; font-weight: 500">Tavolo</label>
          <input placeholder="Menu medio" [(ngModel)]="menuMedio" class="custom-input price-input">
            <label style="padding: 10px; font-size: 14px; font-weight: 500">Asporto</label>
          <input placeholder="Menu grande" [(ngModel)]="menuGrande" class="custom-input price-input">
        </div>
      </fieldset>

        <fieldset class="custom-fieldset">
            <legend>Destinazione di stampa</legend>
            <div class="chart-container">
                <mat-select placeholder="Seleziona destinazione di stampa" class="custom-input" [(ngModel)]="selectedPrintDestination">
                    <mat-option value="cucina">Cucina</mat-option>
                    <mat-option value="bar">Bar</mat-option>
                    <mat-option value="pizzeria">Pizzeria</mat-option>
                </mat-select>
            </div>
        </fieldset>

        <fieldset class="custom-fieldset">
            <legend>Seleziona IVA</legend>
            <div class="chart-container">
                <mat-select placeholder="Seleziona IVA" class="custom-input" [(ngModel)]="reparto">
                    <mat-option value="1">22% IVA</mat-option>
                    <mat-option value="2">10% IVA</mat-option>
                </mat-select>
            </div>
        </fieldset>

    </div>
  </div>
</div>
