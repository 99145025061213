import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserDataService } from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { AggiuntaPiatto } from "../aggiunta-piatto/aggiunta-piatto";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "../../shared/services/firestore/common.service";
import * as Papa from 'papaparse';
import {EditMagazzino} from "../edit-magazzino/edit-magazzino";
import {EditCategories} from "../edit-categorie/edit-categories";
import { ChangeDetectorRef } from '@angular/core';
import {InventoryComponent} from "../inventoryComponent/inventory.component";
import {map} from "rxjs/operators";
import {EditInventario} from "../edit-inventario/edit-inventario";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {AddOrdineMagazzino} from "../add-ordine-magazzino/add-ordine-magazzino";
import { Observable, of, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ordini-magazzino-test',
  templateUrl: './ordiniMagazzino.html',
  styleUrls: ['./ordiniMagazzino.css']
})
export class OrdiniMagazzino implements OnInit, OnDestroy {
  dataSourceDipendenti2 = new MatTableDataSource<any | Group>();
  public dataSource = new MatTableDataSource<any | Group>([]);

  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  _alldata: any[];
  menu = [];
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  displayedColumnsDipendenti2: string[] = [];

  modalOptions = {
    backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {}
  };

  // Variabili per la ricerca
  searchTerm: string = '';
  filterValue: string = '';

  display = 'none';
  closeResult = '';
  nome = 'Totale';
  nome2 = 'Mancia per Mike';
  data = '23-01-2021 19.30';
  luogo = 'Rimini ,';
  prezzo = '26.00';
  prezzo2 = '2.60';
  percentuale = 0;
  percentuale2 = 10;
  user;
  ristorante;
  ifCarrello;
  carrelloTmp;
  carrello;
  numeroElementi = 0;
  tot = 0;
  lista = 0;
  cartItems: Set<string> = new Set();
  totalOrderAmount = 0;
  idRistorante = '';
  selectedWarehouse: string = 'punto_vendita'; // Default al punto vendita
  warehouses: any[] = [];
  cartItemsArray: any[] = [];

  constructor(
      private router: Router,
      private userService: UserDataService,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private cdRef: ChangeDetectorRef,
      public commonService: CommonService,
      public dataService: DataServiceGenerico,
      public dataServiceGenerico: DataServiceGenerico,
      private snackBar: MatSnackBar
  ) {
    this.columns = [{
      field: 'Titolo'
    }, {
      field: 'UM'
    }, {
      field: 'Prezzo'
    }, {
      field: 'Aggiungi'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['Categoria'];

    // Inizializza l'elenco dei magazzini disponibili
    this.warehouses = [
      { id: 'punto_vendita', name: 'Punto Vendita' },
      { id: 'centrale', name: 'Magazzino Centrale' }
    ];
  }

  openDialog(row:any): any {
    const dialogRef = this.dialog.open(EditMagazzino, {
      data: row // Passa i dati della riga al dialog
    });
  }

  unsubscribe;

  selezione = 'Catalogo'; // Cambio default a "Catalogo" invece di "Carrello"

  getTabStyle(tab: string) {
    const isSelected = this.selezione === tab;
    return {
      'font-weight': isSelected ? 'bold' : 'normal',
      'color': isSelected ? '#333333' : '#777777',
      'display': 'flex',
      'align-items': 'center',
      'height': 'calc(100% - 2px)',
      'border-bottom': isSelected ? '2px solid #333333' : '2px solid transparent',
      'padding': '0px 15px',
      'cursor': 'pointer',
      'transition': 'all 0.2s ease'
    };
  }

  async fetchCartItems() {
    let restaurantId = await this.dataService.getCurrentRestaurantId();
    const cartRef = firebase.firestore()
        .collection('ristoranti')
        .doc(restaurantId);

    // Creare uno snapshot listener invece di una singola query
    this.unsubscribe = cartRef.onSnapshot((doc) => {
      if (doc.exists) {
        console.log('carrelloMagazzino: ', doc.data());
        const items = doc.data()?.carrelloMagazzino || [];
        this.cartItems = new Set(items.map((item: any) => item.id));
        this.cartItemsArray = items;

        // Calcolare il totale dell'ordine
        this.totalOrderAmount = this.calculateTotalOrderAmount();
        this.cdRef.detectChanges();
      } else {
        console.log('Il documento non esiste');
        this.cartItems = new Set();
        this.cartItemsArray = [];
        this.totalOrderAmount = 0;
        this.cdRef.detectChanges();
      }
    }, (error) => {
      console.error('Errore nel monitoraggio del carrello:', error);
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  openDialogAdd(row: any): any {
    const dialogRef = this.dialog.open(AddOrdineMagazzino, {
      data: {
        ...row,
        warehouseType: this.selectedWarehouse
      },
      width: '500px'
    });

    // Ascolta l'evento di chiusura per aggiornare gli elementi del carrello
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.added) {
        // Il dialog ha restituito dati di un prodotto aggiunto con successo
        this.snackBar.open('Prodotto aggiunto al carrello', 'Chiudi', {
          duration: 2000
        });
      }
    });
  }

  // Metodo per ottenere un array degli elementi nel carrello
  getCartItemsArray(): any[] {
    return this.cartItemsArray || [];
  }

  // Metodo per modificare un elemento nel carrello
  editCartItem(item: any): void {
    this.openDialogAdd(item);
  }

  // Metodo per rimuovere un elemento dal carrello
  async removeCartItem(item: any): Promise<void> {
    try {
      const restaurantId = await this.dataService.getCurrentRestaurantId();
      const cartRef = firebase.firestore().collection('ristoranti').doc(restaurantId);

      // Ottieni l'array corrente dal carrello
      const doc = await cartRef.get();
      if (doc.exists) {
        const cartData = doc.data();
        const cartItems = cartData?.carrelloMagazzino || [];

        // Filtra l'elemento da rimuovere
        const updatedCartItems = cartItems.filter((cartItem: any) => cartItem.id !== item.id);

        // Aggiorna il documento con il nuovo array
        await cartRef.update({ carrelloMagazzino: updatedCartItems });

        this.snackBar.open('Prodotto rimosso dal carrello', 'Chiudi', {
          duration: 3000
        });
      }
    } catch (error) {
      console.error('Errore durante la rimozione dell\'elemento dal carrello:', error);
      this.snackBar.open('Errore durante la rimozione del prodotto', 'Chiudi', {
        duration: 3000
      });
    }
  }

  // Calcola il totale dell'ordine
  calculateTotalOrderAmount(): number {
    if (!this.cartItemsArray || this.cartItemsArray.length === 0) return 0;

    return this.cartItemsArray.reduce((total, item) => {
      const price = parseFloat(item.Prezzo) || 0;
      const quantity = parseInt(item.quantita) || 0;
      return total + (price * quantity);
    }, 0);
  }

  // Formatta il prezzo nel carrello
  formatCartPrice(item: any): string {
    if (!item || !item.Prezzo || !item.quantita) return 'N/A';

    const price = parseFloat(item.Prezzo);
    const quantity = parseInt(item.quantita);

    if (isNaN(price) || isNaN(quantity)) return 'N/A';

    return (price * quantity).toFixed(2) + '€';
  }

  // Metodo per inviare l'ordine
  async sendOrder(): Promise<void> {
    try {
      if (this.cartItemsArray.length === 0) {
        this.snackBar.open('Il carrello è vuoto', 'Chiudi', {
          duration: 3000
        });
        return;
      }

      const restaurantId = await this.dataService.getCurrentRestaurantId();
      const db = firebase.firestore();
      const ordiniRef = db.collection('ordini_magazzino_test');
      const cartRef = db.collection('ristoranti').doc(restaurantId);

      // Genera un nuovo ID unico per l'ordine
      const nuovoOrdineRef = ordiniRef.doc();
      const orderId = nuovoOrdineRef.id;

      // Crea l'oggetto ordine
      const order = {
        id: orderId,
        data: firebase.firestore.Timestamp.now(),
        items: [...this.cartItemsArray], // Copia degli elementi del carrello
        sourceWarehouse: this.selectedWarehouse,
        destinationWarehouse: this.selectedWarehouse === 'centrale' ? 'punto_vendita' : 'centrale',
        status: 'pending',
        totalAmount: this.calculateTotalOrderAmount(),
        userId: firebase.auth().currentUser?.uid || 'unknown',
        ristorante: restaurantId
      };

      // Salva l'ordine
      await nuovoOrdineRef.set(order);

      // Svuota il carrello
      await cartRef.update({ carrelloMagazzino: [] });

      this.snackBar.open('Ordine inviato con successo', 'Chiudi', {
        duration: 3000
      });
    } catch (error) {
      console.error('Errore durante l\'invio dell\'ordine:', error);
      this.snackBar.open('Errore durante l\'invio dell\'ordine', 'Chiudi', {
        duration: 3000
      });
    }
  }

  openDialogCategorie(row: any): void {
    this.dialog.open(EditCategories, {
      data: {
        collectionType: 'menu_test'  // Utilizziamo menu_test per le categorie
      }
    });
  }

  openDialogInventario(row: any): void {
    this.dialog.open(EditInventario, {
      data: {
        collectionType: 'menu_test',  // Utilizziamo menu_test per l'inventario
        warehouseType: this.selectedWarehouse // Passa il tipo di magazzino selezionato
      }
    });
  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  onSyncMenuChange(row: any) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    // Toggle the SyncMenu value
    const newSyncMenuValue = row.SyncMenu === '1' ? '0' : '1';

    // Trova l'elemento da aggiornare e imposta il nuovo valore di SyncMenu
    const updatedItems = this._alldata.map(item => {
      if (item.id === row.id) {
        return { ...item, SyncMenu: newSyncMenuValue };
      }
      return item;
    });

    // Aggiorna l'array items nel documento su Firestore
    menuRef.update({ items: updatedItems })
        .then(() => {
          console.log('SyncMenu aggiornato con successo su Firestore!');
          // Aggiorna la visualizzazione locale
          row.SyncMenu = newSyncMenuValue;
          this.cdRef.detectChanges();
        })
        .catch(error => {
          console.error('Errore durante l\'aggiornamento di SyncMenu su Firestore:', error);
        });
  }

  immagini = {}

  // Metodo per cambiare il magazzino selezionato
  onWarehouseChange(warehouseId: string): void {
    this.selectedWarehouse = warehouseId;
    this.retriveCarrello(); // Ricarica i dati con il nuovo magazzino selezionato
  }

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {
    this.immagini = this.dataServiceGenerico.getImmagini()
    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.retriveCarrello();
        this.userService.emitter.subscribe(
            () => {
              this.retriveCarrello();
            }
        );
      } else {
        // No user is signed in.
      }
    });

    this.fetchCartItems();
  }

  triggerFileInput() {
    document.getElementById('fileInput').click();
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        Papa.parse(csvData as string, {
          header: true,
          delimiter: ';',
          complete: (result) => {
            const processedData = this.processCSVData(result.data);
            this.saveWarehouseToFirestore(processedData);
          }
        });
      };
      reader.readAsText(file);
    }
  }

  processCSVData(data: any[]) {
    return data;
  }

  saveWarehouseToFirestore(warehouseItems: any[]) {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();

    // Determiniamo la collection in base al tipo di magazzino selezionato
    const collectionName = this.selectedWarehouse === 'centrale' ? 'magazzino_centrale' : 'magazzino_test';
    const warehouseRef = db.collection(collectionName).doc(restaurantName);

    const updatedWarehouseItems = [];

    warehouseItems.forEach(item => {
      const newDocRef = db.collection(collectionName).doc().collection(restaurantName).doc();
      item.id = newDocRef.id;
      updatedWarehouseItems.push(item);
    });

    warehouseRef.set({ items: updatedWarehouseItems })
        .then(() => {
          console.log('Magazzino caricato con successo su Firestore con ID generati!');
          this.retriveCarrello();
        })
        .catch((error) => {
          console.error('Errore durante il caricamento del magazzino su Firestore:', error);
        });
  }

  retriveCarrello(): void {
    // Ottieni i dati dell'anagrafica (menu) e li adatta per l'uso in magazzino
    this.loadMenuDataForWarehouse(this.selectedWarehouse).subscribe({
      next: (menuItems) => {
        console.log("Menu per magazzino: ", menuItems);
        if (menuItems && menuItems.length > 0) {
          // Converte gli elementi del menu nel formato necessario per il magazzino
          this._alldata = menuItems.map(item => this.convertMenuItemToWarehouseItem(item));

          // Aggiungi dati specifici del magazzino se esistono
          this.loadWarehouseSpecificData().subscribe(warehouseData => {
            if (warehouseData) {
              // Aggiorna i dati del magazzino con le informazioni specifiche (quantità, ecc.)
              this.mergeWarehouseDataWithMenuItems(warehouseData);
            }

            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
            this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
            this.dataSource.filter = performance.now().toString();

            // Imposta tutte le categorie come espanse (aperte) per default
            this.expandAllGroups();
          });
        } else {
          console.error('No items found in the menu');
          this._alldata = [];
          this.dataSource.data = [];
        }
      },
      error: (error) => {
        console.error('Error retrieving menu data:', error);
        this._alldata = [];
        this.dataSource.data = [];
      }
    });
  }

  // Metodo per espandere tutte le categorie
  expandAllGroups(): void {
    this.dataSource.data.forEach(item => {
      if (item instanceof Group) {
        item.expanded = true;
        this.toggleChildVisibility(item);
      }
    });
    this.dataSource.filter = performance.now().toString();
  }

  loadMenuDataForWarehouse(warehouseType: string): Observable<any[]> {
    // Ottieni il menu completo
    return from(this.dataServiceGenerico.getCurrentRestaurantId()).pipe(
        switchMap(restaurantId => {
          return this.commonService.getMenuSnapshot(restaurantId).pipe(
              map(snapshot => {
                if (snapshot.length > 0) {
                  const data = snapshot.map(doc => {
                    const docData = doc.payload.doc.data() as { items: any[], ristorante: string };
                    return docData;
                  });

                  if (data[0] && data[0].items) {
                    // Filtra gli elementi in base a eventuali criteri specifici del magazzino
                    // Ad esempio, potresti filtrare per categoria o tipo di prodotto
                    return data[0].items.filter(item =>
                        // Esempio di filtro - potresti personalizzarlo in base alle tue esigenze
                        // Per ora ritorno tutti gli elementi
                        true
                    );
                  }
                }
                return [];
              })
          );
        }),
        catchError(error => {
          console.error('Error loading menu data:', error);
          return of([]);
        })
    );
  }

  // Carica dati specifici del magazzino se esistono
  loadWarehouseSpecificData(): Observable<any> {
    return from(this.dataServiceGenerico.getCurrentRestaurantId()).pipe(
        switchMap(restaurantId => {
          // Determina quale collezione usare in base al tipo di magazzino
          const collectionName = this.selectedWarehouse === 'centrale' ? 'magazzino_centrale' : 'magazzino_test';

          return new Observable(observer => {
            firebase.firestore().collection(collectionName).doc(restaurantId).get()
                .then(doc => {
                  if (doc.exists) {
                    observer.next(doc.data());
                  } else {
                    observer.next(null);
                  }
                  observer.complete();
                })
                .catch(error => {
                  console.error('Error loading warehouse data:', error);
                  observer.error(error);
                });
          });
        }),
        catchError(error => {
          console.error('Error loading warehouse data:', error);
          return of(null);
        })
    );
  }

  // Combina i dati del menu con i dati specifici del magazzino
  mergeWarehouseDataWithMenuItems(warehouseData: any): void {
    if (!warehouseData || !warehouseData.items) return;

    this._alldata.forEach(menuItem => {
      // Cerca l'elemento corrispondente nei dati del magazzino
      const warehouseItem = warehouseData.items.find(item => item.id === menuItem.id);
      if (warehouseItem) {
        // Aggiorna con i dati specifici del magazzino (quantità, UM, etc.)
        menuItem.Quantita = warehouseItem.Quantita || 0;
        menuItem.UM = warehouseItem.UM || menuItem.UM || 'pz';
        // Altri campi specifici del magazzino...
      }
    });
  }

  // Converte un elemento del menu in un elemento di magazzino
  convertMenuItemToWarehouseItem(menuItem: any): any {
    return {
      id: menuItem.id,
      Titolo: menuItem.title,
      Categoria: menuItem.category,
      Prezzo: menuItem.price || 0,
      UM: menuItem.unitOfMeasure || 'pz', // Unità di misura predefinita
      Quantita: 0, // Valore predefinito
      image_link: menuItem.image_link,
      barcode: menuItem.barcode || '',
      descrizione: menuItem.description || '',
      visible: true, // Imposta tutti gli elementi come visibili di default
      // Altri campi necessari...

      // Mantieni i dati originali del menu per riferimento
      original: menuItem
    };
  }

  salvaCategorie() {
    console.log("ristorante: ", this.menu);
    let newMenu = {
      magazzino: this.menu,
      menu: this.menu,
    }
    this.commonService.updateDati(this.idRistorante, newMenu);
  }

  salvaMenu() {
    console.log("ristorante: ", this.dataSource.data);
    let newDataSource = this.dataSource.data.filter(element => !element.hasOwnProperty('expanded'));
    console.log("ristorant2: ", newDataSource);

    let dataSourceDaSavlvare = [];
    newDataSource.forEach(element => {
      if (dataSourceDaSavlvare.some(elemento => elemento.categoria == element.categoria)) {
        dataSourceDaSavlvare[dataSourceDaSavlvare.findIndex(elemento => elemento.categoria == element.categoria)].menu.push(element);
      } else {
        dataSourceDaSavlvare.push({
          categoria: element.categoria,
          menu: [element]
        });
      }
    });

    console.log("datasourcedasalvare: ", dataSourceDaSavlvare);

    let menuDaSalvare = {
      magazzino: dataSourceDaSavlvare,
      menu: this.menu,
    };

    this.commonService.updateDati(this.idRistorante, menuDaSalvare);
  }

  calcolaTotale(): void {
    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {
          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);
    });
  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {
    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {
        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;
  }

  // Metodo per applicare il filtro di ricerca
  applyFilter(filterValue: string): void {
    this.filterValue = filterValue.trim().toLowerCase();

    if (this.filterValue === '') {
      // Se il filtro è vuoto, ripristina tutti gli elementi e i gruppi
      this.resetAllGroupsAndItems();
    }else {
      // Altrimenti, applica il filtro
      this.applyFilterToGroups();
    }

    // Aggiorna la vista
    this.dataSource.filter = performance.now().toString();
  }

  // Ripristina la visibilità di tutti i gruppi e gli elementi
  resetAllGroupsAndItems(): void {
    this.dataSource.data.forEach(item => {
      if (item instanceof Group) {
        item.expanded = true;
        item.visible = true;
      } else {
        item.visible = true;
      }
    });
  }

  // Applica il filtro ai gruppi e agli elementi
  applyFilterToGroups(): void {
    // Prima identifica quali elementi corrispondono al filtro
    const matchingItemIds = new Set<string>();
    const matchingCategories = new Set<string>();
    const categoryCounts = new Map<string, number>();
    const categoryHasProducts = new Map<string, boolean>();

    this._alldata.forEach(item => {
      if (this.matchesFilter(item, this.filterValue)) {
        matchingItemIds.add(item.id);
        if (item.Categoria) {
          matchingCategories.add(item.Categoria);
          // Incrementa il contatore per questa categoria
          categoryCounts.set(item.Categoria, (categoryCounts.get(item.Categoria) || 0) + 1);
          categoryHasProducts.set(item.Categoria, true);
        }
      }
    });

    // Poi aggiorna la visibilità dei gruppi e degli elementi
    this.dataSource.data.forEach(item => {
      if (item instanceof Group) {
        // Se la categoria del gruppo contiene elementi corrispondenti
        const groupMatches = matchingCategories.has(item.Categoria);
        const hasVisibleProducts = categoryHasProducts.get(item.Categoria) || false;

        // Mostra solo i gruppi che hanno prodotti visibili
        item.expanded = groupMatches; // Espandi solo i gruppi con elementi corrispondenti
        item.visible = groupMatches && hasVisibleProducts;  // Mostra solo i gruppi con elementi corrispondenti

        // Aggiorna il totale dei conteggi per questo gruppo
        if (groupMatches) {
          item.totalCounts = categoryCounts.get(item.Categoria) || 0;
        }
      } else {
        // Per gli elementi normali, mostra solo quelli che corrispondono al filtro
        item.visible = matchingItemIds.has(item.id);
      }
    });

    // Rimuovi categorie vuote (quelle senza prodotti corrispondenti alla ricerca)
    this.removeEmptyCategories();
  }

  // Rimuove categorie senza prodotti visibili
  removeEmptyCategories(): void {
    const categoriesWithVisibleProducts = new Map<string, boolean>();

    // Prima identifica le categorie che hanno almeno un prodotto visibile
    this.dataSource.data.forEach(item => {
      if (!(item instanceof Group) && item.visible && item.Categoria) {
        categoriesWithVisibleProducts.set(item.Categoria, true);
      }
    });

    // Quindi nascondi le categorie che non hanno prodotti visibili
    this.dataSource.data.forEach(item => {
      if (item instanceof Group) {
        item.visible = categoriesWithVisibleProducts.get(item.Categoria) || false;
      }
    });
  }

  // Verifica se un elemento corrisponde al filtro
  matchesFilter(item: any, filter: string): boolean {
    // Cerca nel titolo, categoria, descrizione e UM
    const searchableFields = ['Titolo', 'Categoria', 'descrizione', 'UM'];

    for (const field of searchableFields) {
      if (item[field] && item[field].toString().toLowerCase().includes(filter)) {
        return true;
      }
    }

    return false;
  }

  // Nuovo customFilterPredicate migliorato
  customFilterPredicate(data: any | Group, filter: string): boolean {
    // Usa semplicemente la proprietà visible che è stata già impostata
    return data.visible;
  }

  // Metodo per gestire i clic sui gruppi
  groupHeaderClick(row: Group) {
    row.expanded = !row.expanded;
    this.toggleChildVisibility(row);
    this.dataSource.filter = performance.now().toString();
  }

  // Aggiorna la visibilità di tutti gli elementi figli
  toggleChildVisibility(group: Group): void {
    if (this.filterValue === '') {
      // Se non c'è filtro, semplice toggle della visibilità
      this.dataSource.data.forEach(item => {
        if (!(item instanceof Group) && item.Categoria === group.Categoria) {
          item.visible = group.expanded;
        }
      });
    } else {
      // Se c'è un filtro attivo, mostra solo gli elementi corrispondenti
      this.dataSource.data.forEach(item => {
        if (!(item instanceof Group) && item.Categoria === group.Categoria) {
          // Un elemento è visibile solo se: corrisponde al filtro E il gruppo è espanso
          item.visible = group.expanded && this.matchesFilter(item, this.filterValue);
        }
      });
    }
  }

  groupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, true);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
    this.expandAllGroups(); // Espandi tutte le categorie dopo il raggruppamento
  }

  unGroupBy(event, column) {
    event.stopPropagation();
    this.checkGroupByColumn(column.field, false);
    this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
    this.expandAllGroups(); // Espandi tutte le categorie dopo aver rimosso il raggruppamento
  }

  checkGroupByColumn(field, add) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if (add) {
        this.groupByColumns.push(field);
      }
    }
  }

  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    const result = this.getSublevel(data, 0, groupByColumns, rootGroup);

    // Imposta tutti i gruppi e i loro figli come visibili di default
    result.forEach(item => {
      if (item instanceof Group) {
        item.expanded = true;
        item.visible = true;
      } else {
        item.visible = true;
      }
    });

    return result;
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
        data?.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          result.expanded = true; // Impostato a true per default
          result.visible = true; // Impostato a true per default
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups?.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    if (!a) return [];

    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index: number, item: any): boolean {
    return item instanceof Group;
  }
}

class Group {
  level: number = 0;
  parent: Group | null = null;
  expanded: boolean = true;
  visible: boolean = true;
  totalCounts: number = 0;
  [key: string]: any;
  constructor() {
    this.level = 0;
    this.parent = null;
    this.expanded = true; // Tutte le categorie espanse per default
    this.visible = true; // Tutte le categorie visibili per default
    this.totalCounts = 0;
  }
}