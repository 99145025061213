import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-pager-dialog',
    styles: [`
        .dialog-container {
            padding: 1.5rem;
            max-width: 320px;
            margin: 0 auto;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;
        }

        .close-button {
            background: none;
            border: none;
            font-size: 1.5rem;
            cursor: pointer;
            padding: 0.5rem;
        }

        .input-display {
            background-color: white;
            padding: 0.75rem;
            margin-bottom: 1rem;
            border-radius: 4px;
        }

        .input-label {
            color: #666;
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
        }

        .input-value {
            font-size: 1.5rem;
            font-weight: bold;
            text-align: left;
            min-height: 2rem;
        }

        .keypad {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 0.5rem;
            margin-bottom: 1rem;
        }

        .key {
            background-color: #f5f5f5;
            border: none;
            padding: 1rem;
            font-size: 1.25rem;
            cursor: pointer;
            border-radius: 4px;
            transition: background-color 0.2s;
        }

        .key:hover {
            background-color: #e0e0e0;
        }

        .key.active {
            background-color: #007bff;
            color: white;
        }

        .action-key {
            background-color: #007bff;
            color: white;
        }

        .total {
            text-align: left;
            padding: 0.75rem;
            background-color: #f8f9fa;
            border-radius: 4px;
            margin-bottom: 1rem;
        }

        .actions {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 1rem;
        }

        .conferma-button {
            width: 100%;
            border: none;
            border-radius: 20px;
            padding: 17px 10px;
            background-color: #36bfa3;
            font-size: 17px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            color: white;
        }

        .conferma-button:hover {
            background-color: #24937c;
        }

        .conferma-button:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
        }

        .annulla-button {
            width: 100%;
            border-radius: 20px;
            padding: 17px 10px;
            border: 1px solid #ff0000b3;
            font-size: 17px;
            font-weight: 600;
            text-align: center;
            cursor: pointer;
            background: white;
        }

        .annulla-button:hover {
            background-color: #ff0000b3;
            color: white;
        }
    `],
    template: `
        <div class="dialog-container">
            <div class="header">
                <h2 class="text-xl font-bold">Assegna Pager</h2>
                <button class="close-button" (click)="onCancel()">×</button>
            </div>

            <div class="input-display">
                <div class="input-label">Quantità</div>
                <div class="input-value">{{displayValue || '0'}}</div>
            </div>

            <div class="keypad">
                <button
                        *ngFor="let num of [1,2,3,4,5,6,7,8,9]"
                        class="key"
                        (click)="onKeyPress(num.toString())"
                        [class.active]="lastPressed === num.toString()">
                    {{num}}
                </button>
                <button class="key" (click)="onKeyPress('0')">0</button>
                <button class="key" (click)="onBackspace()">←</button>
                <button class="key action-key" (click)="onConfirm()">OK</button>
            </div>

            <div style="display: flex; justify-content: space-between; gap: 1rem;">
                <button
                        class="annulla-button"
                        (click)="onCancel()">
                    Annulla
                </button>
                <button
                        class="conferma-button"
                        (click)="onConfirm()"
                        [disabled]="!isValidInput()">
                    Conferma
                </button>
            </div>
        </div>
    `
})
export class PagerDialogComponent {
    displayValue: string = '';
    lastPressed: string = '';
    maxLength: number = 4; // Maximum length for pager number

    constructor(
        public dialogRef: MatDialogRef<PagerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onKeyPress(key: string): void {
        if (this.displayValue.length < this.maxLength) {
            this.displayValue += key;
            this.lastPressed = key;
        }
    }

    onBackspace(): void {
        this.displayValue = this.displayValue.slice(0, -1);
        this.lastPressed = '';
    }

    isValidInput(): boolean {
        const num = parseInt(this.displayValue);
        return this.displayValue.length > 0 &&
            this.displayValue.length <= this.maxLength &&
            !isNaN(num) &&
            num > 0;
    }

    onConfirm(): void {
        if (this.isValidInput()) {
            this.dialogRef.close(parseInt(this.displayValue));
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}