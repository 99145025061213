import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';

@Pipe({
    name: 'objectURL'
})
export class ObjectURLPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(input: File | string | null): Observable<SafeUrl> {

        console.log("input: ", input)
        if (!input) {
            return of('');
        }

        // Se è una stringa (URL), restituiscila direttamente
        if (typeof input === 'string') {
            return of(this.sanitizer.bypassSecurityTrustUrl(input));
        }

        // Se è un File, usa FileReader
        return new Observable<SafeUrl>(observer => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string;
                observer.next(this.sanitizer.bypassSecurityTrustUrl(result));
                observer.complete();
            };
            reader.onerror = error => {
                observer.error(error);
                observer.complete();
            };
            reader.readAsDataURL(input);
        });
    }
}