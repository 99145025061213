<div class="row">
    <div class="column" style="width: 100%">
        <div class="container menu" id="menuListaPiatti">
            <div style="pointer-events: auto; margin-top: 0px">
                <div class="noScrollBarr" style="padding-top: 0%; padding-left: 0px; padding: 10px; max-height: 90vh; overflow-x: visible; overflow-y: scroll">
                    <div class="container" style="padding-top: 0px;">
                        <!-- Loading indicator -->
                        <div *ngIf="isLoading" class="loading-container">
                            <div class="spinner"></div>
                            <p>Caricamento ordini in corso...</p>
                        </div>

                        <!-- Error message -->
                        <div *ngIf="errorMessage && !isLoading" class="error-message">
                            {{ errorMessage }}
                            <button class="retry-button" (click)="fetchOrdini()">Riprova</button>
                        </div>

                        <div class="orders-layout" *ngIf="!isLoading && !errorMessage">
                            <!-- Lista ordini (colonna sinistra) -->
                            <div class="orders-list">
                                <div *ngIf="ordiniMagazzino.length === 0" class="empty-orders">
                                    <p>Nessun ordine trovato</p>
                                </div>
                                <div class="order-card"
                                     *ngFor="let ordine of ordiniMagazzino"

                                     (click)="openOrderDetail(ordine)">
                                    <ordini-card-magazzino
                                            [stato]="ordine.stato"
                                            [totale]="calcolaTotaleOrdine(ordine)"
                                            [fonte]="ordine.fonte"
                                            [nome]="ordine.ristorante"
                                            [numero]="ordine.idOrdine"
                                            [bloccato]="ordine.bloccato"
                                            [data]="ordine.data"
                                            [selected]="selectedOrder?.idOrdine === ordine.idOrdine">
                                    </ordini-card-magazzino>
                                </div>
                            </div>

                            <!-- Dettaglio ordine (colonna destra) -->
                            <div class="order-detail" *ngIf="selectedOrder">
                                <div class="detail-header">
                                    <h3>Ordine #{{selectedOrder.idOrdine || 'N/D'}}</h3>
                                    <div class="status-tag" [ngClass]="{
                              'status-paid': selectedOrder.stato === 'confermato',
                              'status-partial': selectedOrder.stato === 'confermato_parziale',
                              'status-processing': selectedOrder.scheduledTime !== null,
                              'status-unpaid': !selectedOrder.stato || selectedOrder.stato === 'in_attesa'
                          }">
                                        {{getOrderStatus()}}
                                    </div>
                                </div>

                                <div class="detail-content">
                                    <div class="info-section">
                                        <div class="info-row">
                                            <span class="info-label">Ristorante:</span>
                                            <span class="info-value">{{selectedOrder.ristorante || 'N/D'}}</span>
                                        </div>
                                        <div class="info-row">
                                            <span class="info-label">Data:</span>
                                            <span class="info-value">
                                                <ng-container *ngIf="selectedOrder.dataFormatted">
                                                    {{selectedOrder.dataFormatted | date:'dd/MM/yyyy HH:mm'}}
                                                </ng-container>
                                                <ng-container *ngIf="!selectedOrder.dataFormatted && selectedOrder.data">
                                                    {{formatDate(selectedOrder.data)}}
                                                </ng-container>
                                                <ng-container *ngIf="!selectedOrder.dataFormatted && !selectedOrder.data">
                                                    N/D
                                                </ng-container>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="products-section">
                                        <h4>Prodotti Ordinati</h4>
                                        <div *ngIf="!selectedOrder.carrelloMagazzino || selectedOrder.carrelloMagazzino.length === 0"
                                             class="empty-products">
                                            Nessun prodotto trovato in questo ordine.
                                        </div>
                                        <div class="product-list" *ngIf="selectedOrder.carrelloMagazzino && selectedOrder.carrelloMagazzino.length > 0">
                                            <div class="product-item" *ngFor="let item of selectedOrder.carrelloMagazzino">
                                                <span class="quantity">
                                                    <ng-container *ngIf="!isConfirmingPartial">
                                                        <div *ngIf="selectedOrder.stato === 'confermato_parziale'" class="quantity-confirmed">
                                                            <span class="original-quantity"><s>{{item.Quantita || '0'}}x</s></span>
                                                            <span class="received-quantity">{{partialQuantities[item.Titolo] || '0'}}x</span>
                                                            <span class="um">{{item.UM || 'pz'}}</span>
                                                        </div>
                                                        <div *ngIf="selectedOrder.stato !== 'confermato_parziale'">
                                                            {{item.Quantita || item.quantita || '0'}}x {{item.UM || 'pz'}}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="isConfirmingPartial">
                                                        <div class="quantity-input">
                                                            <input type="number"
                                                                   [(ngModel)]="partialQuantities[item.Titolo]"
                                                                   [max]="item.Quantita || item.quantita"
                                                                   min="0"
                                                                   class="form-control">
                                                            <span class="um">{{item.UM || 'pz'}}</span>
                                                        </div>
                                                        <div class="quantity-original">
                                                            Ordinati: {{item.Quantita || item.quantita || '0'}}
                                                        </div>
                                                    </ng-container>
                                                </span>
                                                <div class="product-info">
                                                    <div class="product-name">{{item.Titolo || 'Prodotto senza nome'}}</div>
                                                    <div class="product-supplier">
                                                        {{item.Fornitore || 'N/D'}} - {{item.Categoria || 'N/D'}}
                                                    </div>
                                                    <div class="product-code" *ngIf="item.CodiceFornitore">
                                                        Cod: {{item.CodiceFornitore}}
                                                    </div>
                                                </div>
                                                <div class="product-details">
                                                    <div class="expiry-date" *ngIf="item.Scadenza">
                                                        Scad: {{item.Scadenza}}
                                                    </div>
                                                    <span class="price">
                                                        {{(parseFloat(item.Prezzo || '0') * (parseInt(item.Quantita || item.quantita || '0'))).toFixed(2)}} €
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="total-row">
                                            <span>Totale Ordine</span>
                                            <span class="total-amount">{{calcolaTotaleOrdine(selectedOrder)}} €</span>
                                        </div>

                                        <!-- Pulsanti di conferma - mostrati solo se l'ordine non è stato ancora confermato -->
                                        <div class="confirmation-buttons" *ngIf="(!selectedOrder.stato || selectedOrder.stato === 'in_attesa') && !selectedOrder.scheduledTime">
                                            <ng-container *ngIf="!isConfirmingPartial">
                                                <button class="btn-conferma" (click)="confirmFullOrder()">
                                                    Conferma Ordine Completo
                                                </button>
                                                <button class="btn-parziale" (click)="startPartialConfirmation()">
                                                    Conferma Parziale
                                                </button>
                                            </ng-container>

                                            <ng-container *ngIf="isConfirmingPartial">
                                                <div class="partial-confirmation">
                                                    <button class="btn-conferma" (click)="confirmPartialOrder()">
                                                        Conferma Quantità Ricevute
                                                    </button>
                                                    <button class="btn-annulla" (click)="cancelPartialConfirmation()">
                                                        Annulla
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>