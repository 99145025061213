// slider-totem.ts
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { NotificationService } from "../stampanti/notificationService/notification.service";
import { EditSliderTotemComponent } from "./popup/edit-slider-totem";

interface SliderImage {
  url: string;
  name: string;
  duration: number;
  isClickable?: boolean;
  isDiscount?: boolean;
  linkedItemId?: string;
  discountId?: string;
}

interface ConsumptionOption {
  id: string;
  text: string;
  imageUrl: string;
  isActive: boolean;
}

interface MenuSettings {
  menuFontSize: number;
  menuWidth: number;
  buttonHeight: number;
  productsPerRow: number;
  cardHeight: number;
  maxTextLength: number;
}

interface KioskSettings {
  showFidelity: boolean;
  showPromoInMenu: boolean;
  showConsumptionChoice: boolean;
  useCustomConsumptionOptions: boolean;
  consumptionOptions: ConsumptionOption[];
  standardConsumptionOptions: ConsumptionOption[];
  showPagerPage: boolean;
  customPagerImage: string;
  showLanguageButton: boolean;
  showAccessibilityButton: boolean;
  hideVariantsPopup: boolean;
  showQuantityOnCard: boolean;
  directPaymentWhenSingle: boolean;
  orderNumberFontSize: number;
  printLargeOrderNumber: boolean;
  menuSettings: MenuSettings;
}

@Component({
  selector: 'slider-totem',
  templateUrl: './slider-totem.html',
  styleUrls: ['./slider-totem.css']
})
export class SliderTotem implements OnInit {
  displayedColumns: string[] = ['nome', 'durata', 'tipo', 'preview', 'edit'];
  images: SliderImage[] = [];
  settingsForm: FormGroup;
  uploadProgress: { [key: number]: number } = {};
  isLoading = false;

  defaultConsumptionOptions: ConsumptionOption[] = [
    { id: 'dine_in', text: 'Mangia qui', imageUrl: '', isActive: true },
    { id: 'takeaway', text: 'Porta via', imageUrl: '', isActive: true }
  ];

  defaultCustomOptions: ConsumptionOption[] = [
    { id: 'dine_in', text: 'Mangia qui', imageUrl: '', isActive: true },
    { id: 'takeaway', text: 'Porta via', imageUrl: '', isActive: true },
    { id: 'walking', text: 'Da passeggio', imageUrl: '', isActive: true }
  ];

  constructor(
      private dialog: MatDialog,
      private fb: FormBuilder,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService
  ) {
    this.initializeForm();
  }

  async ngOnInit() {
    await this.loadImages();
    await this.loadSettings();
    this.setupRealtimeUpdates();
    this.setupConsumptionOptionsListener();
  }

  private initializeForm() {
    this.settingsForm = this.fb.group({
      showFidelity: [false],
      showPromoInMenu: [false],
      showConsumptionChoice: [false],
      useCustomConsumptionOptions: [false],
      consumptionOptions: [this.defaultCustomOptions],
      standardConsumptionOptions: [this.defaultConsumptionOptions],
      showPagerPage: [false],
      customPagerImage: [''],
      showLanguageButton: [true],
      showAccessibilityButton: [true],
      hideVariantsPopup: [false],
      showQuantityOnCard: [false],
      directPaymentWhenSingle: [false],
      orderNumberFontSize: [24, [Validators.min(12), Validators.max(72)]],
      printLargeOrderNumber: [true],
      menuSettings: this.fb.group({
        menuFontSize: [16, [Validators.min(12), Validators.max(32)]],
        menuWidth: [800, [Validators.min(400), Validators.max(2000)]],
        buttonHeight: [50, [Validators.min(30), Validators.max(100)]],
        productsPerRow: [3, [Validators.min(1), Validators.max(6)]],
        cardHeight: [200, [Validators.min(100), Validators.max(500)]],
        maxTextLength: [100, [Validators.min(10), Validators.max(500)]]
      })
    });
  }

  private setupConsumptionOptionsListener() {
    this.settingsForm.get('useCustomConsumptionOptions').valueChanges.subscribe(useCustom => {
      const options = useCustom ? this.defaultCustomOptions : this.defaultConsumptionOptions;
      this.settingsForm.patchValue({ consumptionOptions: options });
    });
  }



  async loadImages() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.sliderTotem) {
        this.images = data.sliderTotem;
      }
    } catch (error) {
      console.error('Error loading images:', error);
    }
  }



  getTipoText(image: SliderImage): string {
    if (!image.isClickable) return 'Non cliccabile';
    return image.isDiscount ? 'Sconto' : 'Prodotto';
  }

  addImage() {
    const dialogRef = this.dialog.open(EditSliderTotemComponent, {
      width: '600px',
      data: null
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteImage(result.url);
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          const currentImages = data?.sliderTotem || [];

          currentImages.push(result);

          await db.collection('ristoranti').doc(restaurantId).update({
            sliderTotem: currentImages
          });

          this.notificationService.show('Immagine aggiunta con successo', 'success');
        } catch (error) {
          console.error('Error adding image:', error);
          this.notificationService.show('Errore durante l\'aggiunta dell\'immagine', 'error');
        }
      }
    });
  }

  editImage(image: SliderImage) {
    const dialogRef = this.dialog.open(EditSliderTotemComponent, {
      width: '600px',
      data: image
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (result.delete) {
          await this.deleteImage(image.url);
          return;
        }

        try {
          const restaurantId = this.dataServiceGenerico.getRistorante1();
          if (!restaurantId) throw new Error('No restaurant ID found');

          const db = firebase.firestore();
          const doc = await db.collection('ristoranti').doc(restaurantId).get();
          const data = doc.data();
          let currentImages = data?.sliderTotem || [];

          const index = currentImages.findIndex(img => img.url === image.url);
          if (index !== -1) {
            currentImages[index] = result;

            await db.collection('ristoranti').doc(restaurantId).update({
              sliderTotem: currentImages
            });

            this.notificationService.show('Immagine modificata con successo', 'success');
          }
        } catch (error) {
          console.error('Error updating image:', error);
          this.notificationService.show('Errore durante la modifica dell\'immagine', 'error');
        }
      }
    });
  }

  async deleteImage(imageUrl: string) {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) throw new Error('No restaurant ID found');

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();
      let currentImages = data?.sliderTotem || [];

      currentImages = currentImages.filter(img => img.url !== imageUrl);

      await db.collection('ristoranti').doc(restaurantId).update({
        sliderTotem: currentImages
      });

      try {
        const storage = firebase.storage();
        const imageRef = storage.refFromURL(imageUrl);
        await imageRef.delete();
      } catch (error) {
        console.error('Error deleting image from storage:', error);
      }

      this.notificationService.show('Immagine eliminata con successo', 'success');
    } catch (error) {
      console.error('Error deleting image:', error);
      this.notificationService.show('Errore durante l\'eliminazione dell\'immagine', 'error');
    }
  }

  addConsumptionOption() {
    if (!this.settingsForm.get('useCustomConsumptionOptions').value) return;

    const newOption: ConsumptionOption = {
      id: 'option_' + Date.now(),
      text: '',
      imageUrl: '',
      isActive: true
    };

    const currentOptions = [...this.settingsForm.get('consumptionOptions').value];
    currentOptions.push(newOption);
    this.settingsForm.patchValue({ consumptionOptions: currentOptions });
  }

  removeConsumptionOption(index: number) {
    if (!this.settingsForm.get('useCustomConsumptionOptions').value) return;

    const currentOptions = [...this.settingsForm.get('consumptionOptions').value];
    currentOptions.splice(index, 1);
    this.settingsForm.patchValue({ consumptionOptions: currentOptions });
  }

  async uploadConsumptionImage(optionIndex: number, event: any) {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const fileRef = storageRef.child(`restaurants/${restaurantId}/consumption_options/${Date.now()}_${file.name}`);
      await fileRef.put(file);
      const imageUrl = await fileRef.getDownloadURL();

      const currentOptions = [...this.settingsForm.get('consumptionOptions').value];
      currentOptions[optionIndex].imageUrl = imageUrl;
      this.settingsForm.patchValue({ consumptionOptions: currentOptions });
    } catch (error) {
      console.error('Error uploading image:', error);
      this.notificationService.show('Errore durante il caricamento dell\'immagine', 'error');
    }
  }

  async uploadStandardConsumptionImage(optionIndex: number, event: any) {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const fileRef = storageRef.child(`restaurants/${restaurantId}/standard_consumption_options/${Date.now()}_${file.name}`);
      await fileRef.put(file);
      const imageUrl = await fileRef.getDownloadURL();

      const currentOptions = [...this.settingsForm.get('standardConsumptionOptions').value];
      currentOptions[optionIndex].imageUrl = imageUrl;
      this.settingsForm.patchValue({ standardConsumptionOptions: currentOptions });
    } catch (error) {
      console.error('Error uploading image:', error);
      this.notificationService.show('Errore durante il caricamento dell\'immagine', 'error');
    }
  }

  async uploadPagerImage(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      const storage = firebase.storage();
      const storageRef = storage.ref();

      const fileRef = storageRef.child(`restaurants/${restaurantId}/pager_images/${Date.now()}_${file.name}`);
      await fileRef.put(file);
      const imageUrl = await fileRef.getDownloadURL();

      this.settingsForm.patchValue({ customPagerImage: imageUrl });
    } catch (error) {
      console.error('Error uploading pager image:', error);
      this.notificationService.show('Errore durante il caricamento dell\'immagine', 'error');
    }
  }

  async saveSettings() {
    try {
      const restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();
      if (!restaurantId) throw new Error('No restaurant ID found');

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        parametriKiosk: {
          // Menu settings
          menuSettings: this.settingsForm.get('menuSettings').value,

          // Visualizzazione
          showFidelity: this.settingsForm.get('showFidelity').value,
          showPromoInMenu: this.settingsForm.get('showPromoInMenu').value,
          showConsumptionChoice: this.settingsForm.get('showConsumptionChoice').value,
          useCustomConsumptionOptions: this.settingsForm.get('useCustomConsumptionOptions').value,
          consumptionOptions: this.settingsForm.get('consumptionOptions').value,
          standardConsumptionOptions: this.settingsForm.get('standardConsumptionOptions').value,

          // Pager
          showPagerPage: this.settingsForm.get('showPagerPage').value,
          customPagerImage: this.settingsForm.get('customPagerImage').value,

          // Funzionalità
          showLanguageButton: this.settingsForm.get('showLanguageButton').value,
          showAccessibilityButton: this.settingsForm.get('showAccessibilityButton').value,
          hideVariantsPopup: this.settingsForm.get('hideVariantsPopup').value,
          showQuantityOnCard: this.settingsForm.get('showQuantityOnCard').value,
          directPaymentWhenSingle: this.settingsForm.get('directPaymentWhenSingle').value,

          // Numero Ordine
          orderNumberFontSize: this.settingsForm.get('orderNumberFontSize').value,
          printLargeOrderNumber: this.settingsForm.get('printLargeOrderNumber').value
        }
      });

      this.notificationService.show('Impostazioni salvate con successo', 'success');
    } catch (error) {
      console.error('Error saving settings:', error);
      this.notificationService.show('Errore durante il salvataggio delle impostazioni', 'error');
    }
  }

  async loadSettings() {
    try {
      const restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();
      if (!restaurantId) return;

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.parametriKiosk) {
        const parametriKiosk = data.parametriKiosk;

        if (!parametriKiosk.consumptionOptions) {
          parametriKiosk.consumptionOptions = this.defaultConsumptionOptions;
        }
        if (!parametriKiosk.standardConsumptionOptions) {
          parametriKiosk.standardConsumptionOptions = this.defaultConsumptionOptions;
        }

        this.settingsForm.patchValue(parametriKiosk);
      }
    } catch (error) {
      console.error('Error loading settings:', error);
      this.notificationService.show('Errore durante il caricamento delle impostazioni', 'error');
    }
  }

  private async setupRealtimeUpdates() {
    const restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();
    if (restaurantId) {
      const db = firebase.firestore();
      db.collection('ristoranti').doc(restaurantId)
          .onSnapshot((doc) => {
            const data = doc.data();
            if (data?.sliderTotem) {
              this.images = data.sliderTotem;
            }
            if (data?.parametriKiosk) {
              this.settingsForm.patchValue(data.parametriKiosk, { emitEvent: false });
            }
          });
    }
  }

  getCurrentConsumptionOptions() {
    return this.settingsForm.get('useCustomConsumptionOptions').value ?
        this.settingsForm.get('consumptionOptions').value :
        this.settingsForm.get('standardConsumptionOptions').value;
  }

  updateConsumptionOptionText(index: number, text: string) {
    const options = this.getCurrentConsumptionOptions();
    options[index].text = text;
    this.settingsForm.patchValue({
      consumptionOptions: this.settingsForm.get('useCustomConsumptionOptions').value ? options : undefined,
      standardConsumptionOptions: !this.settingsForm.get('useCustomConsumptionOptions').value ? options : undefined
    });
  }

  toggleConsumptionOptionActive(index: number) {
    const options = this.getCurrentConsumptionOptions();
    options[index].isActive = !options[index].isActive;
    this.settingsForm.patchValue({
      consumptionOptions: this.settingsForm.get('useCustomConsumptionOptions').value ? options : undefined,
      standardConsumptionOptions: !this.settingsForm.get('useCustomConsumptionOptions').value ? options : undefined
    });
  }
}