import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {UserDataService} from "../../../shared/services/firestore/userData.service";
import {NotificationService} from "./notificationService/notification.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import firebase from 'firebase/app';
import 'firebase/firestore';

interface Printer {
  name: string;
  ipAddress: string;
}

interface Cashmatic {
  enabled: boolean;
  tipo: 'cashmatic' | 'cashlogy' | 'glory' | 'vne' | 'altro';
  ipAddress: string;
  username: string;
  password: string;
  port?: number;
}

interface Workstation {
  id: string;
  name: string;
  printers: Printer[];
  type: string;
  ristoranteId?: string;
  cashmatic: Cashmatic;
}

@Component({
  selector: 'stampanti',
  templateUrl: './stampanti.html',
  styleUrls: ['./stampanti.css']
})
export class Stampanti implements OnInit {
  workstations: Workstation[] = [];
  isLoading = false;
  ristoranteAttuale: string = '';

  constructor(
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService
  ) {}

  async ngOnInit() {
    // Ottieni l'ID del ristorante attuale dal servizio
    this.ristoranteAttuale = await this.dataServiceGenerico.getCurrentRestaurantId();
    console.log('Ristorante attuale:', this.ristoranteAttuale);

    await this.loadWorkstations();
  }

  async loadWorkstations() {
    try {
      this.isLoading = true;
      const db = firebase.firestore();

      // Filtra le workstation per il ristorante attuale
      const snapshot = await db.collection('workstations')
          .where('ristoranteId', '==', this.ristoranteAttuale)
          .get();

      if (snapshot.empty) {
        console.log('Nessuna workstation trovata per questo ristorante');
        // Se non ci sono workstation, crea una workstation predefinita
        await this.createDefaultWorkstation();
      } else {
        this.workstations = snapshot.docs.map(doc => {
          const data = doc.data();
          // Assicurati di includere tutte le proprietà richieste dall'interfaccia Workstation
          return {
            id: doc.id,
            name: data.name || 'Workstation',  // Nome predefinito se mancante
            type: data.type || 'default',      // Tipo predefinito se mancante
            ristoranteId: data.ristoranteId,
            printers: data.printers || [{ name: 'fiscale', ipAddress: '' }],
            // Inizializza la configurazione del cassetto automatico se non esiste
            cashmatic: data.cashmatic || {
              enabled: false,
              tipo: 'cashmatic' as const,  // Tipo predefinito
              ipAddress: '',
              username: '',
              password: '',
              port: 80  // Porta predefinita
            }
          };
        });
      }

      console.log('Workstations caricate:', this.workstations);
    } catch (error) {
      console.error('Error loading workstations:', error);
      this.notificationService.show('Errore nel caricamento delle workstation', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  // Crea una workstation predefinita se non ce ne sono
  async createDefaultWorkstation() {
    try {
      const db = firebase.firestore();
      const defaultWorkstation: Omit<Workstation, 'id'> = {
        name: 'Workstation Principale',
        ristoranteId: this.ristoranteAttuale,
        type: 'default',
        printers: [{ name: 'fiscale', ipAddress: '' }],
        cashmatic: {
          enabled: false,
          tipo: 'cashmatic',
          ipAddress: '',
          username: '',
          password: '',
          port: 80
        }
      };

      const docRef = await db.collection('workstations').add(defaultWorkstation);
      this.workstations = [{
        id: docRef.id,
        ...defaultWorkstation
      }];

      console.log('Creata workstation predefinita');
    } catch (error) {
      console.error('Errore nella creazione della workstation predefinita:', error);
    }
  }

  getWorkstationPrinters(workstation: Workstation) {
    return workstation.printers || [];
  }

  addPrinter(workstation: Workstation) {
    if (!workstation.printers) {
      workstation.printers = [];
    }
    workstation.printers.push({ name: '', ipAddress: '' });
  }

  removePrinter(workstation: Workstation, index: number) {
    workstation.printers.splice(index, 1);
  }

  isPrinterNameInvalid(workstationId: string, index: number): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    if (!workstation || !workstation.printers || !workstation.printers[index]) {
      return true;
    }
    return !workstation.printers[index].name;
  }

  isPrinterIpInvalid(workstationId: string, index: number): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    if (!workstation || !workstation.printers || !workstation.printers[index]) {
      return true;
    }
    const ipAddress = workstation.printers[index].ipAddress;
    const ipPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return !ipPattern.test(ipAddress);
  }

  // Validazione per il Cassetto Automatico
  isCashmaticIpInvalid(workstationId: string): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    if (!workstation || !workstation.cashmatic || !workstation.cashmatic.enabled) {
      return false;
    }
    const ipAddress = workstation.cashmatic.ipAddress;
    const ipPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    return !ipPattern.test(ipAddress);
  }

  isCashmaticUsernameInvalid(workstationId: string): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    if (!workstation || !workstation.cashmatic || !workstation.cashmatic.enabled) {
      return false;
    }
    return !workstation.cashmatic.username;
  }

  isCashmaticPasswordInvalid(workstationId: string): boolean {
    const workstation = this.workstations.find(w => w.id === workstationId);
    if (!workstation || !workstation.cashmatic || !workstation.cashmatic.enabled) {
      return false;
    }
    return !workstation.cashmatic.password;
  }

  isValid(): boolean {
    // Prima verifica che tutte le stampanti siano valide
    const printersValid = this.workstations.every(workstation =>
        workstation.printers?.every(printer =>
            printer.name &&
            /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(printer.ipAddress)
        )
    );

    // Poi verifica la validità del cassetto automatico dove è abilitato
    const cashmaticValid = this.workstations.every(workstation => {
      if (workstation.cashmatic?.enabled) {
        return (
            /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(workstation.cashmatic.ipAddress) &&
            !!workstation.cashmatic.username &&
            !!workstation.cashmatic.password
        );
      }
      return true; // Se non è abilitato, considera valido
    });

    return printersValid && cashmaticValid;
  }

  async savePrinters() {
    if (!this.isValid()) {
      this.notificationService.show('Verifica che tutti i campi siano compilati correttamente', 'error');
      return;
    }

    this.isLoading = true;
    try {
      const db = firebase.firestore();

      // Salvataggio delle workstation
      for (const workstation of this.workstations) {
        const docRef = db.collection('workstations').doc(workstation.id);

        // Assicurati che il ristoranteId sia impostato
        if (!workstation.ristoranteId) {
          workstation.ristoranteId = this.ristoranteAttuale;
        }

        // Aggiorna l'intero documento includendo le informazioni sul cassetto automatico
        await docRef.set({
          name: workstation.name,
          type: workstation.type,
          ristoranteId: workstation.ristoranteId,
          printers: workstation.printers,
          cashmatic: workstation.cashmatic
        }, { merge: true });
      }

      this.notificationService.show('Configurazione salvata con successo', 'success');
      console.log('Configurazione salvata con successo:', this.workstations);
    } catch (error) {
      console.error('Error saving configuration:', error);
      this.notificationService.show('Errore durante il salvataggio della configurazione', 'error');
    } finally {
      this.isLoading = false;
    }
  }
}