import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-add-restaurant-dialog',
    template: `
    <div class="dialog-container">
      <div class="sticky-container">
        <div class="save-button">
          <img src="assets/icons/save-icon.png"
               style="width: 30px; aspect-ratio: 1 / 1;"
               title="Salva Ristorante"
               (click)="onSubmit()">
        </div>
        <div class="close-button">
          <img src="assets/icons/close-icon.png"
               style="width: 30px; aspect-ratio: 1 / 1;"
               title="Chiudi"
               (click)="onCancel()">
        </div>
      </div>
        
        <div style="height: 80vh; max-width: 500px;">
            <form [formGroup]="restaurantForm">
                <fieldset class="custom-fieldset">
                    <legend>Nome Ristorante</legend>
                    <input placeholder="Nome" class="custom-input" formControlName="nome">
                </fieldset>

                <fieldset class="custom-fieldset">
                    <legend>Partita IVA</legend>
                    <input placeholder="P.IVA" class="custom-input" formControlName="piva">
                </fieldset>

                <fieldset class="custom-fieldset">
                    <legend>Email Admin</legend>
                    <input placeholder="Email" type="email" class="custom-input" formControlName="email">
                </fieldset>

                <fieldset class="custom-fieldset">
                    <legend>Password Admin</legend>
                    <input placeholder="Password" type="password" class="custom-input" formControlName="password">
                </fieldset>
            </form>
        </div>
    </div>
  `,
    styles: [`
    .dialog-container { 
      position: relative; 
    }
    .sticky-container {
      position: sticky;
      top: 0;
      right: 0;
      z-index: 1000;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      cursor: pointer;
    }
    .close-button {
      width: 30px;
      aspect-ratio: 1 / 1;
      margin-top: -15px;
      margin-right: -15px;
    }
    .save-button {
      width: 30px;
      margin-top: -15px;
    }
    .custom-fieldset {
      margin-bottom: 10px;
      border-radius: 20px;
      height: auto;
      border: 2px solid rgba(222,214,214,0.57);
      display: flex;
      flex-direction: column;
      transition: border-color 0.3s ease;
    }
    .custom-fieldset legend {
      font-weight: 600;
      font-size: 16px;
    }
    .custom-input {
      border: none;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      transition: all 0.3s ease;
    }
    .custom-fieldset:focus-within {
      border-color: #000;
    }
    .custom-input:focus {
      border: none;
      outline: none;
    }
  `]
})
export class AddRestaurantDialogComponent {
    restaurantForm: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<AddRestaurantDialogComponent>,
        private fb: FormBuilder
    ) {
        this.restaurantForm = this.fb.group({
            nome: ['', Validators.required],
            piva: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    onSubmit(): void {
        if (this.restaurantForm.valid) {
            this.dialogRef.close(this.restaurantForm.value);
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}


