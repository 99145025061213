import { Injectable } from '@angular/core';
import {catchError, map} from "rxjs/operators";
import {from, Observable, of} from "rxjs";
import {ipcRenderer} from "electron";
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class IpcService {

  constructor() { }

  printReceipt(stringa, cassetto) {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('print-receipt', stringa, cassetto);
    }
  }

  PrintFiscalReceipt(xmlString: string): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('print-fiscal-receipt', xmlString);  // Send XML string to main process
    }
  }

  private readonly IMAGE_NAMES = [
    'icons8-back-arrow-96.png',
    'icons8-drawer-100.png',
    'icons8-loyalty-90.png',
    'icons8-delete-96.png',
    'icons8-cash-100.png',
    'search.png',
    'icons8-add-90.png',
    'icons8-minus-90.png',
    'icons8-radio-button-empty-96.png',
    'icons8-radio-button-96.png',
    'icons8-heart-100 (1).png'
  ];


  /*
  getImagePath(url: string): Observable<string> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      try {
        const fileName = this.getFileNameFromUrl(url);
        return ipcRenderer.invoke('get-image-path', fileName).pipe(
            map(relativePath => `file:///menuImages/${relativePath}`),
            catchError(() => of('ciaociao'))
        );
      }catch {
      }
    }
    return of(url);
  }

   */


  async getImagePath2(url: string): Promise<string> {
    if (window && window.require) {

      const { ipcRenderer } = window.require('electron');
      try {
        const fileName = this.getFileNameFromUrl(url);
        if (this.IMAGE_NAMES.includes(fileName)) {
          console.log("filename: ", fileName)
          return ipcRenderer.invoke('get-local-image-path', fileName).pipe(
              map(localPath => `menuImages/${localPath}`),
              catchError(() => of(url))) // Fallback to original URL if local file not found
        }
        else
        {
          const localPath = await ipcRenderer.invoke('get-image-path', url);
          if (localPath.startsWith('file://')) {
            // Rimuovi eventuali spazi extra e assicurati che il percorso sia formattato correttamente
            return encodeURI(localPath.trim());
          } else {
            // Se non inizia con 'file://', aggiungilo e formatta correttamente
            return encodeURI(`menuImages/${localPath.trim()}`);
          }
        }
      } catch (error) {
        console.error('Errore nel recupero del percorso dell\'immagine:', error);
        return url; // Fallback all'URL originale in caso di errore
      }
    } else {
      return url; // Se non è un'app Electron, usa l'URL originale
    }
  }

  getImagePath(url: string): Promise<string> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      const fileName = this.getFileNameFromUrl(url);
      return ipcRenderer.invoke('get-image-path', url).pipe(
          map(localPath => localPath),
          catchError(() => from(['']))
      );
    }
    //return url;
  }

  async getLocalImage(imagePath: string): Promise<string> {
    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        //const fileName = this.getFileNameFromUrl(imagePath);
        const base64Image = await ipcRenderer.invoke('GET_LOCAL_IMAGE', imagePath);
        return `data:image/jpeg;base64,${base64Image}`;
      } catch (error) {
        console.error('Errore nel recuperare l\'immagine locale:', error);
        return imagePath;

        //throw error;
      }
    } else {
      console.warn('Questa funzione è disponibile solo nell\'app Electron');
      return imagePath;
    }
  }

  /*
  async getLocalImage2(imagePath: string): Promise<string> {
    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        //const fileName = this.getFileNameFromUrl(imagePath);
        const base64Image = await ipcRenderer.invoke('GET_LOCAL_IMAGE2', imagePath);
        return `data:image/jpeg;base64,${base64Image}`;
      } catch (error) {
        console.error('Errore nel recuperare l\'immagine locale:', error);
        throw error;
      }
    } else {
      console.log("imagePath: ", imagePath)
      //console.warn('Questa funzione è disponibile solo nell\'app Electron');
      return imagePath;
    }
  }

   */

  private getFileNameFromUrl(url: string): string {
    console.log('url: ', url)
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0];
  }


}
