<div class="variant-dialog">
    <!-- Header Section -->
    <div class="product-header">
        <!--div class="product-image">
            <img [src]="data.dataKey?.image_link || defaultImage" [alt]="data.dataKey?.title">
        </div-->
        <div class="product-info">
            <h2 class="product-title">{{data.dataKey?.title}} </h2>
            <p class="product-description">{{data.dataKey?.descrizione}}</p>
            <div class="product-price">{{getBasePrice() | currency:'EUR'}}</div>
        </div>
    </div>

    <!-- Variants Navigation -->
    <div class="variants-nav" *ngIf="variants.length > 0">
        <div class="variants-grid">
            <div *ngFor="let variant of variants; let i = index"
                 class="variant-pill"
                 [class.active]="currentVariantIndex === i"
                 (click)="selectVariant(i)">
                <span class="variant-name">{{variant.name}}</span>
                <span class="variant-badge" *ngIf="variant.maxSelections > 1">
          {{getSelectedCount(variant.id)}}/{{variant.maxSelections}}
        </span>
                <span class="required-badge" *ngIf="variant.isRequired">*</span>
            </div>
        </div>
    </div>

    <!-- Options Grid -->
    <div class="options-container" *ngIf="variants[currentVariantIndex]">
        <div class="options-header">
            <h3>{{variants[currentVariantIndex].domanda}}</h3>
            <span class="selection-info" *ngIf="variants[currentVariantIndex].maxSelections > 1">
        Seleziona fino a {{variants[currentVariantIndex].maxSelections}} opzioni
      </span>
        </div>

        <div class="options-grid">
            <div *ngFor="let option of variants[currentVariantIndex].options"
                 class="option-card"
                 [class.selected]="isOptionSelected(option, variants[currentVariantIndex])"
                 (click)="toggleOption(option, variants[currentVariantIndex])">

                <div class="option-image" *ngIf="option.imageUrl">
                    <img [src]="option.imageUrl" [alt]="option.name">
                </div>

                <div class="option-content">
                    <span class="option-name">{{option.name}}</span>
                    <span class="option-price" *ngIf="option.priceModifier > 0">
            +{{option.priceModifier | currency:'EUR'}}
          </span>

                    <div class="selection-counter" *ngIf="variants[currentVariantIndex].maxSelections > 1 && isOptionSelected(option, variants[currentVariantIndex])">
                        {{getOptionCount(option, variants[currentVariantIndex])}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="dialog-footer">
        <div class="total-price">
            Totale: {{getTotalPrice() | currency:'EUR'}}
        </div>
        <button class="add-button"
                [disabled]="!isValid()"
                (click)="addToOrder()">
            Aggiungi al carrello
        </button>
    </div>
</div>