<div class="dialog-container">
    <div class="sticky-container" style="margin-bottom: 30px">

        <categorieCard class="col-12 col-md-12 menuItem" style="width: 18%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Aggiungi Variante'"
                       altezza="55"
                       [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90%20(1).png?alt=media&amp;token=1631fb96-82a9-415e-b43e-3ca3c088e667' "
                       [bg]="'#33bfa3'"
                       (click)="addVariant()">
        </categorieCard>
        <categorieCard class="col-12 col-md-12 menuItem" style="width: 10%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Salva'"
                       altezza="55"
                       [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e' "
                       [bg]="'rgb(52 152 219)'"
                       (click)="saveVariants()">
        </categorieCard>


    </div>

    <div style="margin-top: 15px; height: 80vh; overflow-y: auto;">
        <div class="row">

            <div class="secondaColonna">
                <div class="variants-list">
                    <div *ngFor="let variant of variants; let i = index" class="variant-item custom-fieldset">
                        <div style="width: 100%; display: flex; justify-content: space-between">
                            <input
                                    placeholder="Nome variante"
                                    class="custom-input"
                                    [(ngModel)]="variant.name"
                                    style="width: 200px; margin-right: 10px; font-size: 15px; font-weight: 600;">
                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                                 style="width: 30px; height: 30px "
                                 (click)="removeVariant(variant.id)">
                        </div>


                        <div class="variant-header">


                            <!--input
                                    type="number"
                                    placeholder="Prezzo base"
                                    class="custom-input"
                                    [(ngModel)]="variant.price"
                                    step="0.01"
                                    style="width: 100px; margin-right: 10px;"-->

                            <div style="margin-right: 10px">Obbligatorio: </div>
                            <div class="checkbox-wrapper-26" style="margin-right: 10px;">
                                <input
                                        type="checkbox"
                                        [id]="'checkbox-required-' + i"
                                        [(ngModel)]="variant.isRequired">
                                <label [for]="'checkbox-required-' + i">
                                    <div class="tick_mark"></div>
                                </label>
                            </div>

                            <div style="margin-right: 10px">Max selezionabili: </div>
                            <input
                                    type="number"
                                    class="custom-input"
                                    [(ngModel)]="variant.maxSelections"
                                    min="1"
                                    style="width: 80px; margin-right: 10px;">


                        </div>

                        <div class="variant-options">
                            <div *ngFor="let option of variant.options; let j = index" class="option-item">

                                <!-- Image Preview/Upload Section -->
                                <div class="option-image-container">
                                    <img *ngIf="option.imageUrl"
                                         [src]="option.imageUrl"
                                         alt="Option preview"
                                         class="option-image-preview">
                                    <div *ngIf="!option.imageUrl" class="image-placeholder">
                                        <i class="material-icons">image</i>
                                    </div>
                                    <div class="image-actions">
                                        <button mat-icon-button (click)="fileInput.click()" class="upload-button">
                                            <i class="material-icons">{{ option.imageUrl ? 'edit' : 'add_photo_alternate' }}</i>
                                        </button>
                                        <button mat-icon-button *ngIf="option.imageUrl"
                                                (click)="removeImage(variant, option)"
                                                class="remove-button">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </div>
                                    <input #fileInput
                                           type="file"
                                           (change)="onFileSelected($event, variant, option)"
                                           style="display: none"
                                           accept="image/*">
                                </div>

                                <input placeholder="Nome opzione"
                                       class="custom-input"
                                       [(ngModel)]="variant.options[j].name"
                                       style="width: 200px; margin-right: 10px;">

                                <input type="number"
                                       placeholder="Modifica prezzo"
                                       class="custom-input"
                                       [(ngModel)]="variant.options[j].priceModifier"
                                       step="0.01"
                                       style="width: 100px; margin-right: 10px;">

                                <div style="width: 30px">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                                         style="width: 30px; aspect-ratio: 1 / 1;"
                                         (click)="removeOption(variant, option.id)">
                                </div>
                            </div>

                            <button
                                    type="button"
                                    (click)="addOptionToVariant(variant)"
                                    class="add-button">
                                + Aggiungi Opzione
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>