<div class="row">
    <div class="content-wrapper">
        <!-- Slider Images Section -->
        <div class="header-container">
            <div class="title">Immagini Totem</div>
            <button type="button" class="add-button" (click)="addImage()">
                Aggiungi Immagine
            </button>
        </div>

        <div class="table-responsive">
            <table mat-table [dataSource]="images" class="mat-elevation-z8">
                <!-- Nome Column -->
                <ng-container matColumnDef="nome">
                    <th mat-header-cell *matHeaderCellDef>Nome</th>
                    <td mat-cell *matCellDef="let image">{{image.name}}</td>
                </ng-container>

                <!-- Durata Column -->
                <ng-container matColumnDef="durata">
                    <th mat-header-cell *matHeaderCellDef>Durata (secondi)</th>
                    <td mat-cell *matCellDef="let image">{{image.duration}}</td>
                </ng-container>

                <!-- Tipo Column -->
                <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef>Tipo</th>
                    <td mat-cell *matCellDef="let image">
                        {{getTipoText(image)}}
                    </td>
                </ng-container>

                <!-- Preview Column -->
                <ng-container matColumnDef="preview">
                    <th mat-header-cell *matHeaderCellDef>Anteprima</th>
                    <td mat-cell *matCellDef="let image">
                        <div class="preview-cell">
                            <img [src]="image.url" alt="Preview" class="preview-image">
                        </div>
                    </td>
                </ng-container>

                <!-- Edit Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let image">
                        <button class="action-button" (click)="editImage(image)">
                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                                 alt="Edit"
                                 class="action-icon">
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
            </table>
        </div>

        <!-- Kiosk Settings Section -->
        <div class="settings-section">
            <div class="header-container">
                <div class="title">Impostazioni Kiosk</div>
                <button type="button" class="save-button" (click)="saveSettings()">
                    Salva Impostazioni
                </button>
            </div>

            <form [formGroup]="settingsForm" class="settings-form">
                <!-- Menu e Layout Settings -->
                <mat-card class="settings-card">
                    <mat-card-header>
                        <mat-card-title>Impostazioni Menu e Layout</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="settings-grid" formGroupName="menuSettings">
                            <mat-form-field appearance="outline">
                                <mat-label>Dimensione Font Menu</mat-label>
                                <input matInput type="number" formControlName="menuFontSize">
                                <mat-hint>px (12-32)</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Larghezza Menu</mat-label>
                                <input matInput type="number" formControlName="menuWidth">
                                <mat-hint>px (400-2000)</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Altezza Bottoni</mat-label>
                                <input matInput type="number" formControlName="buttonHeight">
                                <mat-hint>px (30-100)</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Prodotti per Riga</mat-label>
                                <input matInput type="number" formControlName="productsPerRow">
                                <mat-hint>(1-6)</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Altezza Card Prodotto</mat-label>
                                <input matInput type="number" formControlName="cardHeight">
                                <mat-hint>px (100-500)</mat-hint>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Lunghezza Massima Testo</mat-label>
                                <input matInput type="number" formControlName="maxTextLength">
                                <mat-hint>caratteri (10-500)</mat-hint>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <!-- Visualizzazione Settings -->
                <mat-card class="settings-card">
                    <mat-card-header>
                        <mat-card-title>Visualizzazione</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="settings-grid">
                            <mat-checkbox formControlName="showFidelity" color="primary">
                                Mostra Fidelity
                            </mat-checkbox>

                            <mat-checkbox formControlName="showPromoInMenu" color="primary">
                                Mostra Promo nel Menu
                            </mat-checkbox>

                            <mat-checkbox formControlName="showConsumptionChoice" color="primary">
                                Mostra Scelta Consumazione
                            </mat-checkbox>

                            <mat-checkbox formControlName="showPagerPage" color="primary">
                                Mostra Pagina Pager
                            </mat-checkbox>
                        </div>

                        <!-- Pager Image Upload -->
                        <div class="image-upload-section" *ngIf="settingsForm.get('showPagerPage').value">
                            <div class="section-header">
                                <h3>Immagine Pager Personalizzata</h3>
                            </div>
                            <div class="image-upload-content">
                                <div class="preview-container" *ngIf="settingsForm.get('customPagerImage').value">
                                    <img [src]="settingsForm.get('customPagerImage').value" alt="Preview" class="preview-image">
                                </div>
                                <button mat-stroked-button (click)="pagerFileInput.click()">
                                    <mat-icon>upload</mat-icon>
                                    Carica Immagine Pager
                                </button>
                                <input #pagerFileInput type="file" hidden (change)="uploadPagerImage($event)" accept="image/*">
                            </div>
                        </div>

                        <!-- Consumption Options -->
                        <div class="consumption-options" *ngIf="settingsForm.get('showConsumptionChoice').value">
                            <div class="consumption-options-header">
                                <h3>Opzioni di Consumazione</h3>
                                <mat-checkbox formControlName="useCustomConsumptionOptions" color="primary">
                                    Usa Opzioni Personalizzate
                                </mat-checkbox>
                                <button mat-mini-fab color="primary"
                                        *ngIf="settingsForm.get('useCustomConsumptionOptions').value"
                                        (click)="addConsumptionOption()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>

                            <!-- Standard Options -->
                            <div class="consumption-options-list" *ngIf="!settingsForm.get('useCustomConsumptionOptions').value">
                                <mat-card class="option-card" *ngFor="let option of settingsForm.get('standardConsumptionOptions').value; let i = index">
                                    <div class="option-content">
                                        <mat-checkbox [(ngModel)]="option.isActive"
                                                      [ngModelOptions]="{standalone: true}"
                                                      (change)="toggleConsumptionOptionActive(i)">
                                            Attiva
                                        </mat-checkbox>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Testo Opzione</mat-label>
                                            <input matInput [(ngModel)]="option.text"
                                                   [ngModelOptions]="{standalone: true}"
                                                   (change)="updateConsumptionOptionText(i, option.text)">
                                        </mat-form-field>

                                        <div class="image-upload">
                                            <div class="preview-container" *ngIf="option.imageUrl">
                                                <img [src]="option.imageUrl" alt="Preview" class="preview-image">
                                            </div>
                                            <button mat-stroked-button (click)="standardFileInput.click()">
                                                <mat-icon>upload</mat-icon>
                                                Carica Immagine
                                            </button>
                                            <input #standardFileInput type="file" hidden
                                                   (change)="uploadStandardConsumptionImage(i, $event)"
                                                   accept="image/*">
                                        </div>
                                    </div>
                                </mat-card>
                            </div>

                            <!-- Custom Options -->
                            <div class="consumption-options-list" *ngIf="settingsForm.get('useCustomConsumptionOptions').value">
                                <mat-card class="option-card" *ngFor="let option of settingsForm.get('consumptionOptions').value; let i = index">
                                    <div class="option-content">
                                        <mat-checkbox [(ngModel)]="option.isActive"
                                                      [ngModelOptions]="{standalone: true}"
                                                      (change)="toggleConsumptionOptionActive(i)">
                                            Attiva
                                        </mat-checkbox>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Testo Opzione</mat-label>
                                            <input matInput [(ngModel)]="option.text"
                                                   [ngModelOptions]="{standalone: true}"
                                                   (change)="updateConsumptionOptionText(i, option.text)">
                                        </mat-form-field>

                                        <div class="image-upload">
                                            <div class="preview-container" *ngIf="option.imageUrl">
                                                <img [src]="option.imageUrl" alt="Preview" class="preview-image">
                                            </div>
                                            <button mat-stroked-button (click)="customFileInput.click()">
                                                <mat-icon>upload</mat-icon>
                                                Carica Immagine
                                            </button>
                                            <input #customFileInput type="file" hidden
                                                   (change)="uploadConsumptionImage(i, $event)"
                                                   accept="image/*">
                                        </div>

                                        <button mat-icon-button color="warn"
                                                *ngIf="i >= 3"
                                                (click)="removeConsumptionOption(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>

                <!-- Funzionalità Settings -->
                <mat-card class="settings-card">
                    <mat-card-header>
                        <mat-card-title>Funzionalità</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="settings-grid">
                            <mat-checkbox formControlName="showLanguageButton" color="primary">
                                Mostra Pulsante Lingua
                            </mat-checkbox>

                            <mat-checkbox formControlName="showAccessibilityButton" color="primary">
                                Mostra Pulsante Accessibilità
                            </mat-checkbox>

                            <mat-checkbox formControlName="hideVariantsPopup" color="primary">
                                Nascondi Popup Varianti
                            </mat-checkbox>

                            <mat-checkbox formControlName="showQuantityOnCard" color="primary">
                                Mostra Quantità sulla Card
                            </mat-checkbox>

                            <mat-checkbox formControlName="directPaymentWhenSingle" color="primary">
                                Pagamento Diretto se Unica Opzione
                            </mat-checkbox>
                        </div>
                    </mat-card-content>
                </mat-card>

                <!-- Monitor e Stampa Settings -->
                <mat-card class="settings-card">
                    <mat-card-header>
                        <mat-card-title>Impostazioni Numero Ordine</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="settings-grid">
                            <mat-form-field appearance="outline">
                                <mat-label>Dimensione Font Numero Ordine</mat-label>
                                <input matInput type="number" formControlName="orderNumberFontSize">
                                <mat-hint>px (12-72)</mat-hint>
                            </mat-form-field>

                            <mat-checkbox formControlName="printLargeOrderNumber" color="primary">
                                Stampa Numero Ordine Grande
                            </mat-checkbox>
                        </div>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>
    </div>
</div>