import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

@Component({
  selector: 'edit-magazzino',
  templateUrl: './edit-magazzino.html',
  styleUrls: ['./edit-magazzino.css'],
})
export class EditMagazzino implements OnInit, OnDestroy {
  isMenuChecked = false;
  isGenericoChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  Titolo: string = '';
  id: string = '';
  selectedCategoria: string = '';
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';
  quantity: string = '';
  supplierCode: string = '';
  supplier: string = '';
  expiryDate: string = '';
  um: string = '';
  maxLvl: number = 1; // Default a 1 livello


  // Variabili per i livelli di imballaggio
  levels: { quantita: string, um: string, barCode: string }[] = [
    { quantita: '', um: '', barCode: '' }, // Primo livello
    { quantita: '', um: '', barCode: '' }, // Secondo livello
    { quantita: '', um: '', barCode: '' }, // Terzo livello
    { quantita: '', um: '', barCode: '' }  // Quarto livello
  ];

  // Variabili per i prezzi dei menu
  menuPiccolo: string = '';
  menuMedio: string = '';
  menuGrande: string = '';

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isLvl1 = true;
  isLvl2 = false;
  isLvl3 = false;
  isLvl4 = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<EditMagazzino>,
    private dataService: DataServiceGenerico,
    @Inject(MAT_DIALOG_DATA) public data: any  // Riceve i dati dal dialog
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  ngOnInit(): void {
    this.fetchCategoriesFromFirestore();

    if (this.data) {
      // Popola i campi con i dati passati
      this.id = this.data.id || '';
      this.Titolo = this.data.Titolo || '';
      this.selectedCategoria = this.data.Categoria || '';
      this.selectedImage = this.data.image_link || '';
      this.supplierCode = this.data.CodiceFornitore || '';
      this.supplier = this.data.Fornitore || '';
      this.quantity = this.data.Quantita || '';
      this.price = this.data.Prezzo || '';
      this.expiryDate = this.data.Scadenza || '';
      this.um = this.data.UM || '';
      this.levels = this.data.lvl || this.levels;
      this.maxLvl = this.data.maxLvl || 1;



      this.isMenuChecked = this.data.menu;
      this.isGenericoChecked = this.data.generico
      this.isSingleChecked = this.data.single;
      this.isDisponibileChecked = this.data.disponibilita;

      console.log('livello',this.maxLvl)
      // Imposta i livelli in base a maxLvl
      this.isLvl1 = this.maxLvl == 1;
      this.isLvl2 = this.maxLvl == 2;
      this.isLvl3 = this.maxLvl == 3;
      this.isLvl4 = this.maxLvl == 4;


      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): any {
    this.passBack();
  }

  fetchCategoriesFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const collectionRef = db.collection('magazzino_test').doc(restaurantName);

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();
        const ingredientsSet = new Set<string>();

        items.forEach(item => {
          if (item.Categoria) {
            categoriesSet.add(item.Categoria);
          }
          if (item.Titolo) {
            ingredientsSet.add(item.Titolo); // Usa i titoli dei prodotti come ingredienti
          }
        });

        this.categories = Array.from(categoriesSet);
        this.ingredients = Array.from(ingredientsSet);
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }

  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  onGenericoChange(event: any) {
    this.isGenericoChecked = event.target.checked;
  }

  onMenuChangeLevel1(event: any) {
    this.isLvl1 = event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl1 ? 1 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel2(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl2 ? 2 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel3(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl3 ? 3 : 2;  // Aggiorna maxLvl
  }

  onMenuChangeLevel4(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = event.target.checked;
    this.maxLvl = this.isLvl4 ? 4 : 3;  // Aggiorna maxLvl
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage(levelIndex: number) {
    this.levels[levelIndex].barCode = '';
    this.cdRef.detectChanges();
  }

  onFileSelected(event: any, levelIndex: number) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file, levelIndex);
    }
  }

  uploadImageToFirebase(file: File, levelIndex: number) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.levels[levelIndex].barCode = downloadURL;
          this.cdRef.detectChanges();
        });
      }
    );
  }

  saveChangesToFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        if (this.id) {
          // Aggiorna un prodotto esistente
          const updatedItems = items.map(item => {
            if (item.id === this.id) {
              return {
                ...item,
                Titolo: this.Titolo,
                Categoria: this.selectedCategoria,
                CodiceFornitore: this.supplierCode,
                Fornitore: this.supplier,
                Quantita: this.quantity,
                Prezzo: this.price,
                Scadenza: this.expiryDate,
                UM: this.um,
                lvl: this.levels, // Salva i livelli
                maxLvl: this.maxLvl // Salva il numero massimo di livelli
              };
            }
            return item;
          });

          magazzinoRef.update({
            items: updatedItems
          })
            .then(() => {
              console.log('Prodotto aggiornato con successo su Firestore!');
            })
            .catch((error) => {
              console.error('Errore durante l\'aggiornamento del prodotto su Firestore: ', error);
            });

        } else {
          // Aggiungi un nuovo prodotto
          const newId = db.collection('magazzino_test').doc().id;
          const newItem = {
            id: newId,
            Titolo: this.Titolo,
            Categoria: this.selectedCategoria,
            CodiceFornitore: this.supplierCode,
            Fornitore: this.supplier,
            Quantita: this.quantity,
            Prezzo: this.price,
            Scadenza: this.expiryDate,
            UM: this.um,
            image_link: this.selectedImage || '',
            ingredients: this.selectedIngredients.map(name => ({ name, quantity: 1 })),
            allergens: this.selectedAllergens.map(name => ({ name, quantity: 1 })),
            menu: this.isMenuChecked,
            generico: this.isGenericoChecked,
            single: this.isSingleChecked,
            disponibilita: this.isDisponibileChecked,
            lvl: this.levels, // Salva i livelli
            maxLvl: this.maxLvl // Salva il numero massimo di livelli
          };

          items.push(newItem);

          magazzinoRef.update({
            items: items
          })
            .then(() => {
              console.log('Nuovo prodotto aggiunto con successo su Firestore!');
            })
            .catch((error) => {
              console.error('Errore durante l\'aggiunta del nuovo prodotto su Firestore: ', error);
            });
        }
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }


  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const updatedItems = items.filter(item => item.id !== this.id);

        magazzinoRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Elemento eliminato con successo da Firestore!');
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
          });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    // Potresti voler restituire dati al chiamante, ad esempio:
    // this.passEntry.emit({ ... });
  }
}
