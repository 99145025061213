import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

interface TavoliData {
    tavoli: any[];
}

interface SalaData {
    id: string;
    nome: string;
    tavoli: any[];
}

@Injectable({
    providedIn: 'root',
})
export class TavoliService {
    private ristoranteRef = this.firestore.collection('ristoranti');

    constructor(private firestore: AngularFirestore) {}

    // Metodo originale per mantenere retrocompatibilità
    getTavoli(ristorante?: string, salaId?: string): Observable<any[]> {
        console.log("ristorante: ", ristorante);

        // Se non è specificata una salaId, usa il comportamento originale
        if (!salaId) {
            return this.ristoranteRef.doc(ristorante).get().pipe(
                map((doc) => {
                    const data = doc.data() as TavoliData | undefined;
                    return data ? data.tavoli ? data.tavoli : [] : [];
                })
            );
        } else {
            // Altrimenti ottieni i tavoli dalla specifica sala
            return this.ristoranteRef.doc(ristorante).collection('sale').doc(salaId).get().pipe(
                map((doc) => {
                    const data = doc.data() as SalaData | undefined;
                    return data ? data.tavoli ? data.tavoli : [] : [];
                })
            );
        }
    }

    // Metodo originale per mantenere retrocompatibilità
    aggiornaTavoli(tavoli: any[], ristorante?: string, salaId?: string): Promise<void> {
        console.log("ristorante: ", ristorante);

        // Se non è specificata una salaId, usa il comportamento originale
        if (!salaId) {
            return this.ristoranteRef.doc(ristorante).set({ tavoli }, { merge: true });
        } else {
            // Altrimenti, aggiorna i tavoli nella specifica sala
            return this.ristoranteRef.doc(ristorante).collection('sale').doc(salaId).set({ tavoli }, { merge: true });
        }
    }

    // Metodo per ottenere le sale di un ristorante
    getSale(ristorante?: string): Observable<any[]> {
        if (!ristorante) {
            return of([]);
        }

        return this.ristoranteRef.doc(ristorante).collection('sale').snapshotChanges().pipe(
            map(actions => {
                return actions.map(action => {
                    const data = action.payload.doc.data() as any;
                    const id = action.payload.doc.id;
                    return { id, ...data };
                });
            })
        );
    }

    // Metodo per aggiornare o creare una sala
    aggiornaSala(sala: any, ristorante?: string): Promise<void> {
        if (!ristorante || !sala.id) {
            return Promise.reject('Ristorante o ID sala mancante');
        }

        return this.ristoranteRef.doc(ristorante).collection('sale').doc(sala.id).set(sala, { merge: true });
    }

    // Metodo per aggiornare tutte le sale
    aggiornaSale(sale: any[], ristorante?: string): Promise<void> {
        if (!ristorante) {
            return Promise.reject('Ristorante mancante');
        }

        // Crea un batch per aggiornare le sale
        const batch = this.firestore.firestore.batch();

        // Prima elimina tutte le sale esistenti
        return this.ristoranteRef.doc(ristorante).collection('sale').get().toPromise()
            .then(snapshot => {
                snapshot.docs.forEach(doc => {
                    batch.delete(doc.ref);
                });

                // Poi aggiungi le nuove sale
                sale.forEach(sala => {
                    const salaRef = this.ristoranteRef.doc(ristorante).collection('sale').doc(sala.id).ref;
                    batch.set(salaRef, sala);
                });

                // Esegui il batch
                return batch.commit();
            });
    }

    // Nuovo metodo per aggiornare lo stato di un singolo tavolo (mantenuto per retrocompatibilità)
    aggiornaTavoloStato(numeroTavolo: string, occupato: boolean, ristorante?: string, salaId?: string): Promise<void> {
        return this.getTavoli(ristorante, salaId).pipe(
            take(1),
            map(tavoli => {
                const tavoloIndex = tavoli.findIndex(t => t.numero === numeroTavolo);
                if (tavoloIndex !== -1) {
                    tavoli[tavoloIndex].occupato = occupato ? 1 : 0;
                    return tavoli;
                }
                throw new Error('Tavolo non trovato');
            }),
            switchMap(tavoliAggiornati => this.aggiornaTavoli(tavoliAggiornati, ristorante, salaId))
        ).toPromise();
    }
}