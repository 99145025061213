// tavolo-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tavolo-dialog',
  template: `
    <h1 mat-dialog-title style="font-family: 'Inter', sans-serif; font-weight: 600; font-size: 18px; margin: 0">
      {{ data.isEdit ? 'Modifica Tavolo' : 'Aggiungi Tavolo' }}
    </h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill" style="font-family: 'Inter', sans-serif">
        <div style="font-weight: 500; padding: 5px; font-family: 'Inter', sans-serif">Numero Tavolo</div>
        <input style="padding: 10px 20px; border-radius: 10px; border: 1px solid rgba(181,181,181,0.3);" matInput [(ngModel)]="data.numero">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <div style="font-weight: 500; padding: 5px; font-family: 'Inter', sans-serif">Numero di Coperti</div>
        <input style="padding: 10px 20px; border-radius: 10px; border: 1px solid rgba(181,181,181,0.3);" matInput [(ngModel)]="data.coperti" type="number">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button *ngIf="data.isEdit" mat-button (click)="onDelete()" style="padding: 10px 20px; border-radius: 15px; background-color: red; color: white; border: none; font-weight: bold; font-family: 'Inter', sans-serif; margin-right: 5px">
        Elimina
      </button>
      <button mat-button [mat-dialog-close]="data" style="padding: 10px 20px; border-radius: 15px; background-color: #34bfa3; color: white; border: none; font-weight: bold; font-family: 'Inter', sans-serif">
        {{ data.isEdit ? 'Salva' : 'Aggiungi' }}
      </button>
    </div>
  `,
})
export class TavoloDialogComponent {
  constructor(
      public dialogRef: MatDialogRef<TavoloDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onDelete(): void {
    this.dialogRef.close({ delete: true });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}