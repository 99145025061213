<div class="row" style="height: 95vh; width:calc(100vw - 610px); margin-top: 15px; border-radius: 20px;">

  <div style="">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Nome e cognome</div>
    <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


      <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

        <div class="flex flex-col xl:flex-row gap-5">


          <div class="flex flex-col gap-2 flex-1">
            <label class="font-bold text-gray-700 text-heading-sm"> Nome </label>
              <input type="text">
          </div>

          <div class="flex flex-col gap-2 flex-1">
            <label class="font-bold text-gray-700 text-heading-sm"> Cognome </label>
              <input type="text">
          </div>

        </div>

      </div>

      <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
        Salva Modifiche
      </div>

    </div>

  </div>


</div>


