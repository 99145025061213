import {
    Component,
    ElementRef,
    HostListener,
    ViewChild,
    AfterViewInit,
    EventEmitter,
    Output,
    Input,
    SimpleChanges,
    OnChanges
} from '@angular/core';

@Component({
    selector: 'app-numeric-keypad',
    template: `
        <div class="keypad-container"
             #keypad
             tabindex="0"
             (keydown)="onKeyDown($event)">
            <!-- Display principale -->
            <div class="keypad-header">
                <div class="keypad-display">{{ formattedDisplay }}</div>
                <button class="clear-button" (click)="clearDisplay()">
                    <span>C</span>
                </button>
            </div>

            <div class="keypad-content">
                <!-- Sezione sinistra con la tastiera numerica -->
                <div class="keypad-grid">
                    <button *ngFor="let num of numbers"
                            (click)="onButtonClick(num)"
                            class="keypad-button"
                            [class.operator-button]="isOperator(num)">
                        {{ num }}
                    </button>
                </div>

                <!-- Sezione destra con i pulsanti funzione -->
                <div class="function-buttons">
                    <div class="button-group">
                        <button class="function-button discount-button" (click)="applyDiscount('percentage', 'product')">
                            <span class="button-icon">%</span>
                            <span class="button-label">Sconto prodotto</span>
                        </button>
                        <button class="function-button discount-button" (click)="applyDiscount('percentage', 'total')">
                            <span class="button-icon">%</span>
                            <span class="button-label">Sconto totale</span>
                        </button>
                    </div>

                    <div class="button-group">
                        <button class="function-button discount-button" (click)="applyDiscount('amount', 'discount')">
                            <span class="button-icon">€-</span>
                            <span class="button-label">Sconta</span>
                        </button>
                        <button class="function-button discount-button" (click)="applyDiscount('amount', 'increase')">
                            <span class="button-icon">€+</span>
                            <span class="button-label">Maggiora</span>
                        </button>
                    </div>

                    <button class="function-button reset-button" (click)="resetAllPrintedQuantities()">
                        <span class="button-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
                                <path d="M3 3v5h5"></path>
                                <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"></path>
                                <path d="M16 16h5v5"></path>
                            </svg>
                        </span>
                        <span class="button-label">Azzera Comande</span>
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .keypad-container {
            position: fixed;
            right: 20px;
            bottom: 20px;
            width: 560px;
            background-color: white;
            border-radius: 20px;
            border: none;
            font-family: 'Inter', sans-serif;
            outline: none;
            z-index: 1000;
            box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }

        .keypad-header {
            display: flex;
            padding: 16px 16px 8px;
            gap: 16px;
            background-color: #f8f9fa;
            border-bottom: 1px solid #f0f0f0;
        }

        .keypad-display {
            flex: 1;
            text-align: right;
            font-size: 28px;
            padding: 16px;
            background-color: white;
            border: 1px solid #e8e8e8;
            border-radius: 12px;
            font-weight: 600;
            color: #333;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
        }

        .clear-button {
            width: 64px;
            height: 62px;
            background-color: #f44336;
            color: white;
            border: none;
            border-radius: 12px;
            font-weight: 600;
            font-size: 24px;
            cursor: pointer;
            touch-action: manipulation;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px 4px rgba(244, 67, 54, 0.3);
        }

        .clear-button:hover {
            background-color: #e53935;
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(244, 67, 54, 0.4);
        }

        .clear-button:active {
            transform: translateY(0);
            box-shadow: 0 1px 2px rgba(244, 67, 54, 0.4);
        }

        .keypad-content {
            display: flex;
            padding: 16px;
            gap: 16px;
        }

        .keypad-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
            gap: 10px;
            flex: 3;
        }

        .keypad-button {
            height: 70px;
            font-size: 22px;
            background-color: white;
            border: 1px solid #e8e8e8;
            border-radius: 12px;
            cursor: pointer;
            font-weight: 500;
            touch-action: manipulation;
            color: #333;
            transition: all 0.15s ease;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
        }

        .keypad-button:hover {
            background-color: #f8f9fa;
            transform: translateY(-1px);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
        }

        .keypad-button:active {
            transform: translateY(0);
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        .operator-button {
            background-color: #f2f7ff;
            color: #353555;
            font-weight: 600;
            border-color: #e1e9ff;
        }

        .operator-button:hover {
            background-color: #e6efff;
        }

        .function-buttons {
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .button-group {
            display: flex;
            gap: 10px;
            margin-bottom: 10px;
        }

        .function-button {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 12px 8px;
            border: none;
            border-radius: 12px;
            cursor: pointer;
            transition: all 0.2s ease;
            height: 90px;
            color: white;
        }

        .discount-button {
            background-color: #353555;
            box-shadow: 0 2px 6px rgba(57, 73, 171, 0.3);
        }

        .discount-button:hover {
            background-color: #353555;
            transform: translateY(-2px);
            box-shadow: 0 4px 10px rgba(57, 73, 171, 0.4);
        }

        .discount-button:active {
            transform: translateY(0);
            box-shadow: 0 1px 4px rgba(57, 73, 171, 0.4);
        }

        .reset-button {
            background-color: #4a3555;
            box-shadow: 0 2px 6px rgba(74, 53, 85, 0.3);
            height: 70px;
        }

        .reset-button:hover {
            background-color: #4a3555;
            transform: translateY(-2px);
            box-shadow: 0 4px 10px rgba(229, 57, 53, 0.4);
        }

        .reset-button:active {
            transform: translateY(0);
            box-shadow: 0 1px 4px rgba(229, 57, 53, 0.4);
        }

        .button-icon {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .button-label {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
        }
    `]
})
export class NumericKeypadComponent implements AfterViewInit, OnChanges {
    @ViewChild('keypad') keypad: ElementRef;
    @Output() valueEntered = new EventEmitter<{type: 'price' | 'quantity', value: number, createNewProduct?: boolean}>();
    @Output() addGenericProduct = new EventEmitter<{vatId: number, price: number}>();
    @Output() applyDiscountToOrder = new EventEmitter<{
        type: 'percentage' | 'amount',
        value: number,
        orderTotal: number,
        target: 'product' | 'total',
        operation: 'discount' | 'increase'
    }>();
    @Output() resetPrintedQuantities = new EventEmitter<void>();
    @Input() reset: number = 0;
    @Input() orderTotal: number = 0;

    ngOnChanges(changes: SimpleChanges) {
        if (changes['reset'] && !changes['reset'].firstChange) {
            this.clearDisplay();
        }
    }

    numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '.', '0', 'x'];
    display = '0';
    isMultiplier = false;
    previousValue = '';
    hasDecimal = false;

    constructor() {}

    ngAfterViewInit() {
        // La pulsantiera sarà sempre visibile
    }

    get formattedDisplay(): string {
        if (this.isMultiplier) {
            return `x${this.display}`;
        }

        // Mostra il valore semplice senza formattazione quando stiamo inserendo
        return `€ ${this.display}`;
    }

    onButtonClick(value: string) {
        if (value === 'x') {
            if (!this.isMultiplier && this.display !== '0') {
                this.isMultiplier = true;
                this.previousValue = this.display;
                this.display = this.previousValue;
                this.hasDecimal = false;
                // Qui emettiamo il valore come quantità (intero)
                this.valueEntered.emit({
                    type: 'quantity',
                    value: parseInt(this.display)
                });
            }
            return;
        }

        if (value === '.') {
            if (!this.hasDecimal) {
                this.hasDecimal = true;
                if (this.display === '0') {
                    this.display = '0.';
                } else {
                    this.display += '.';
                }
            }
            return;
        }

        // Gestione numeri
        if (this.display === '0' && !this.hasDecimal) {
            this.display = value;
        } else {
            // Controlla decimali
            if (this.hasDecimal) {
                const decimalPart = this.display.split('.')[1];
                if (decimalPart && decimalPart.length >= 2) {
                    return; // Non permettere più di 2 decimali
                }
            }
            this.display += value;
        }

        // Emettiamo il valore come prezzo con due decimali SOLO quando non siamo in modalità moltiplicatore
        if (!this.isMultiplier) {
            let numericValue = parseFloat(this.display);
            if (!this.hasDecimal) {
                numericValue = parseInt(this.display);
            }
            this.valueEntered.emit({
                type: 'price',
                value: numericValue,
                createNewProduct: true // Indica che deve creare un nuovo prodotto se già esistente
            });
        }
    }

    clearDisplay() {
        this.display = '0';
        this.isMultiplier = false;
        this.hasDecimal = false;
        this.previousValue = '';
    }

    isOperator(value: string): boolean {
        return value === 'x' || value === '.';
    }

    addGenericProductWithVat(vatId: number) {
        // Ottieni il prezzo corrente dal display
        const price = parseFloat(this.display) || 0;

        // Emetti un evento con il vatId e il prezzo corrente
        this.addGenericProduct.emit({
            vatId: vatId,
            price: price
        });

        // Reset del display dopo l'aggiunta
        this.clearDisplay();
    }

    applyDiscount(type: 'percentage' | 'amount', target: 'product' | 'total' | 'discount' | 'increase') {
        // Ottieni il valore corrente dal display
        const value = parseFloat(this.display) || 0;

        if (value <= 0) {
            // Non permette sconti negativi o zero
            return;
        }

        // Determina se è uno sconto o una maggiorazione
        let operation: 'discount' | 'increase' = 'discount';
        let actualTarget: 'product' | 'total' = 'product';

        if (target === 'increase') {
            operation = 'increase';
            actualTarget = 'total'; // Sempre sul totale per €+
        } else if (target === 'discount') {
            operation = 'discount';
            actualTarget = 'total'; // Sempre sul totale per €-
        } else {
            actualTarget = target;
        }

        // Se è una percentuale su un prodotto specifico, mostra un messaggio speciale
        if (type === 'percentage' && actualTarget === 'product') {
            this.display = `${value}% - Clicca sul prodotto`;
            // Emetti l'evento con tipo percentuale, specificando che deve attendere la selezione di un prodotto
            this.applyDiscountToOrder.emit({
                type: type,
                value: value,
                orderTotal: this.orderTotal,
                target: actualTarget,
                operation: operation
            });
            return; // Non resettare il display finché non si seleziona un prodotto
        }

        if (type === 'amount' && actualTarget === 'total' && operation === 'discount' && value > this.orderTotal) {
            // Non permette sconti in valore assoluto maggiori del totale dell'ordine
            return;
        }

        // Emetti l'evento con il tipo di sconto e il valore
        this.applyDiscountToOrder.emit({
            type: type,
            value: value,
            orderTotal: this.orderTotal,
            target: actualTarget,
            operation: operation
        });

        // Reset del display dopo l'applicazione dello sconto
        this.clearDisplay();
    }

    // Metodo per resettare le quantità stampate
    resetAllPrintedQuantities() {
        this.resetPrintedQuantities.emit();
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (/^[0-9]$/.test(event.key)) {
            this.onButtonClick(event.key);
        } else if (event.key === '.') {
            this.onButtonClick('.');
        } else if (event.key === 'x') {
            this.onButtonClick('x');
        } else if (event.key === 'Escape') {
            this.clearDisplay();
        }
        event.preventDefault();
    }
}