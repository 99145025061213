<div style="background-color: black; height: 100vh; padding: 20px">


  <div style="width: 100%; height: 60px">
    <div class="Row">
      <div class="Column" style="background-color: black; color: white; font-size: xx-large"> <strong>Ora 12:00 PM</strong></div>

      <div class="Column" style="background-color: black; color: white; font-size: xx-large;   text-align: center;"> <strong>Completamento 60%</strong></div>

      <div class="Column" style="background-color: black; color: white; font-size: xx-large;   text-align: right;"> <strong>Tempo 2:24</strong></div>
    </div>
  </div>

  <div style="height: 80px; width: 200px; position: absolute; bottom: 20px; left: 20px; background-color: #319033; border-radius: 20px; text-align: center; line-height: 80px; font-weight: 600" (click)="cronologia()"  >{{!cronologiaVar? 'Cronologia': 'Cronologia aperti'}}</div>



  //fonte
  //0 = app    rgba(73, 230, 136, 0.1)
  //1 = kiosk  rgba(175, 147, 69, 0.1);
  //2 = cassa  rgba(84, 105, 212, 0.1)
  //3 = tutto

    <div class="Row" *ngIf="camerieri2.length>0">
        <div class="Column" *ngFor="let cameriere of camerieri2 | slice:0:5; index as i" style=" width: 19.5% !important " [style.border]="cameriere['bloccato']? cameriere.bloccato == true? '2px solid red': '2px solid transparent': '2px solid transparent'" (swiperight)="onSwipeRight($event, cameriere)"  (swipeleft)="onSwipeLeft($event, cameriere)">
          <div [style.background-color]="cameriere.fonte == 2? 'rgba(84,105,212,0.55)':  cameriere.fonte == 1? 'rgba(175,147,69,0.46)': 'rgba(73,230,136,0.47)'"  style="width: 100%; height: 40px; border-top-right-radius: 20px; border-top-left-radius: 20px; line-height: 40px;">
            <span style="margin-left: 10px">Ordine: <strong>#{{getOrdine(cameriere)}}</strong></span>
            <span style="position: relative; float: right; margin-right: 10px">Tempo: <strong>0.36</strong></span>
          </div>
          <ul style="background-color: white">
            <div *ngFor="let prodotto of getProperties(cameriere.carrello)">
              <li >{{camerieri2[i].carrello[prodotto].quantita }}x
                <strong>{{camerieri2[i].carrello[prodotto].title}}</strong>
              </li>
            </div>
          </ul>
        </div>

      <span *ngIf="camerieri2.length < 5">
        <div class="Column" *ngFor="let cameriere of camerieri2 | slice: camerieri2.length-1: 10; index as i" style="width: 10% !important">
        </div>
      </span>

    </div>

  <div class="Row" *ngIf="camerieri2.length>4">
    <div class="Column" *ngFor="let cameriere of camerieri2 | slice: 5: 10; index as i" style="width: 19.5% !important" [style.border]="cameriere['bloccato']? cameriere.bloccato == true? '2px solid red': '2px solid transparent': '2px solid transparent'" (swiperight)="onSwipeRight($event, cameriere)"  (swipeleft)="onSwipeLeft($event, cameriere)">
      <div [style.background-color]="cameriere.fonte == 2? 'rgba(84,105,212,0.55)':  cameriere.fonte == 1? 'rgba(175,147,69,0.46)': 'rgba(73,230,136,0.47)'"  style="width: 100%; background-color: #b9adad; height: 40px; border-top-right-radius: 20px; border-top-left-radius: 20px; line-height: 40px;">
        <span style="margin-left: 10px">Ordine: <strong>#{{getOrdine(cameriere)}}</strong></span>
        <span style="position: relative; float: right; margin-right: 10px">Tempo: <strong>0.36</strong></span>
      </div>
      <ul style="background-color: white">
        <div *ngFor="let prodotto of getProperties(cameriere.carrello)">
          <li >{{camerieri2[i+5].carrello[prodotto].quantita }}x
            <strong>{{camerieri2[i+5].carrello[prodotto].title}}</strong>
          </li>
        </div>
      </ul>
    </div>

    <span *ngIf="camerieri2.length < 10">
      <div class="Column" *ngFor="let cameriere of camerieri2 | slice: camerieri2.length-1: 15; index as i" style="width: 10% !important">
      </div>
    </span>

  </div>

  <div class="Row" *ngIf="camerieri2.length>9">
    <div class="Column" *ngFor="let cameriere of camerieri2 | slice: 10: 15; index as i" style="width: 19.5% !important" [style.border]="cameriere['bloccato']? cameriere.bloccato == true? '2px solid red': '2px solid transparent': '2px solid transparent'" (swiperight)="onSwipeRight($event, cameriere)"  (swipeleft)="onSwipeLeft($event, cameriere)">
      <div [style.background-color]="cameriere.fonte == 2? 'rgba(84,105,212,0.55)':  cameriere.fonte == 1? 'rgba(175,147,69,0.46)': 'rgba(73,230,136,0.47)'" style="width: 100%; background-color: #b9adad; height: 40px; border-top-right-radius: 20px; border-top-left-radius: 20px; line-height: 40px;">
        <span style="margin-left: 10px">Ordine: <strong>#{{getOrdine(cameriere)}}</strong></span>
        <span style="position: relative; float: right; margin-right: 10px">Tempo: <strong>0.36</strong></span>
      </div>
      <ul style="background-color: white">
        <div *ngFor="let prodotto of getProperties(cameriere.carrello)">
          <li >{{camerieri2[i+10].carrello[prodotto].quantita }}x
            <strong>{{camerieri2[i+10].carrello[prodotto].title}}</strong>
          </li>
        </div>
      </ul>
    </div>

    <span *ngIf="camerieri2.length < 15">
      <div class="Column" *ngFor="let cameriere of camerieri2 | slice: camerieri2.length-1: 20; index as i" style="width: 10% !important">
      </div>
    </span>

  </div>


</div>
