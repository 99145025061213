import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import { IpcService } from "../../shared/services/ipc.service";
import {AuthService} from "../../shared/services/auth.service";

@Component({
  selector: 'app-azioni-stampante',
  templateUrl: './azioniStampante.html',
  styleUrls: ['./azioniStampante.css']
})
export class AzioniStampanteComponent {
  chiusuraGiornaliera: boolean = false;
  annullaScontrino: boolean = false;
  nomeScontrino: string = '';
  chiusuraGiornalieraInput: string = '';
  dataInput: string = '';
  serialeInput: string = '';

  constructor(public dialogRef: MatDialogRef<AzioniStampanteComponent>,
              private ipcService: IpcService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService) {}

  selezionaChiusuraGiornaliera(): void {
    this.chiusuraGiornaliera = true;
  }

  selezionaAnnullaScontrino(): void {
    this.annullaScontrino = true;
  }

  confermaChiusuraGiornaliera(): void {
    this.zReport();
    this.dialogRef.close();
  }

  annullaOperazione(): void {
    this.chiusuraGiornaliera = false;
    this.annullaScontrino = false;
  }

  navigateToImpostazioni(): void{
    this.router.navigate(['/impostazioni']);
    this.dialogRef.close();
  }

  logout() {
    this.authService.SignOut();
    this.router.navigate(['/login']);
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  annullaScontrinoSubmit(): void {
    this.epsonFiscalVoid();
    this.dialogRef.close();
  }

  zReport(): void {
    let zReportXml=`<printerFiscalReport>
<displayText operator="1" data="Chiusura giornaliera" />
<printZReport operator="1" timeout="10000" />
</printerFiscalReport>`
    this.ipcService.PrintFiscalReceipt(zReportXml);
    console.log('Chiusura giornaliera avviata');
  }


  epsonFiscalVoid(): void {
    // Logica per annullare lo scontrino
    console.log('Annullamento scontrino avviato con i dati:', {
      nomeScontrino: this.nomeScontrino,
      chiusuraGiornaliera: this.chiusuraGiornalieraInput,
      data: this.dataInput,
      seriale: this.serialeInput
    });
    let voidXml=`
<printerFiscalReceipt>
<displayText operator="1" data="Annullo Scontrino" />
<printRecMessage operator="1" message="VOID ${this.chiusuraGiornalieraInput} ${this.nomeScontrino} ${this.dataInput} ${this.serialeInput}" messageType="4" />
</printerFiscalReceipt>
`
    this.ipcService.PrintFiscalReceipt(voidXml);
    console.log('Chiusura giornaliera avviata');

  }
}