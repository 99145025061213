import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'ordini-card',
  styles: [`
    .card-scontrino {
      background-color: white;
      border-radius: 20px;
      border: 1px solid #e3e2e2;
      transition: 0.3s;
      margin-top: 10px;
    }

    .card-scontrino.selected {
      border-left: 3px solid rgb(229, 89, 51);
    }

    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .container-scontrino {
      padding: 15px 5px 10px;
      height: 100px;
      width: 100%;
    }

    .order-row {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      float: left;
      display: flex;
      align-items: initial;
      justify-content: left;
      margin-top: 10px;
      clear: left;
      width: 100%;
    }

    .order-number {
      margin-left: 10px;
      max-height: 1000px;
      padding: 0 5px 10px;
    }

    .order-total {
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 10px;
    }

    .status-container {
      font-weight: 600;
      margin-right: 20px;
      margin-left: auto;
      max-height: 1000px;
      padding: 0 5px 10px;
      display: grid;
      place-items: center;
    }

    .status-badge {
      padding: 0 5px;
      height: 32px;
      border-radius: 8px;
      display: grid;
      place-items: center;
      font-size: 11px;
    }

    .status-paid {
      color: rgba(14,184,14,0.7);
      background-color: rgba(0,255,0, 0.3);
    }

    .status-unpaid {
      color: rgba(255,0,0, 0.7);
      background-color: rgba(255,0,0, 0.5);
    }

    .status-completed {
      color: rgba(0,119,255,0.7);
      background-color: rgba(0,119,255,0.32);
    }

    .status-processing {
      color: rgba(150,49,190,0.7);
      background-color: rgba(159,95,213,0.5);
    }

    .bold {
      font-weight: bold;
    }
  `],
  template: `
    <div class="header card-scontrino" [class.selected]="selected">
      <div class="container-scontrino">
        <div class="order-row">
          <div class="order-number">
            Scontrino: <span class="bold">#{{ numero }}</span>
          </div>
          <div class="order-total">
            Totale: <span class="bold">{{ totale }}€</span>
          </div>
        </div>
        <div class="order-row">
          <tagComponent [fonte]="fonte"></tagComponent>
          <div class="status-container">
            <div *ngIf="statoPagato == 0 && bloccato !== true"
                 class="status-badge status-paid">
              Pagato
            </div>
            <div *ngIf="statoPagato == 1"
                 class="status-badge status-unpaid">
              Non Pagato
            </div>
            <div *ngIf="statoPagato == 3"
                 class="status-badge status-completed">
              Completato
            </div>
            <div *ngIf="bloccato == true && statoPagato !== 3"
                 class="status-badge status-processing">
              In Preparazione
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class OrdiniCardComponent {
  @Input() stato;
  @Input() statoPagato;
  @Input() totale;
  @Input() fonte;
  @Input() nome;
  @Input() numero;
  @Input() bloccato;
  @Input() selected = false;

  constructor() {}
}