import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';
import firebase from 'firebase/app';
import { HammerModule } from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CommonService} from "../../shared/services/firestore/common.service";

interface SelectedOption {
  name: string;
  priceModifier: number;
  variantId: string;
  variantTitle: string;
  quantity: number;
}

@Component({
  selector: 'seleziona-varianti',
  templateUrl: './seleziona-varianti.html',
  styleUrls: ['./seleziona-varianti.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
  ],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"'
  },
})
export class SelezionaVarianti implements OnDestroy {

  variants: any[] = [];



  primoClick = 1;
  quantita = 1;
  currentOrder;
  piattoRicevuto;
  portata=0;


  linkImg='https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/kfc%2Fmenu%2Fbucket%2F322_thumb_it.png?alt=media&token=14f68096-132e-48c5-b933-4ec01d2b701f'

  // tslint:disable-next-line:max-line-length
  constructor(
      private router: Router,
      private menuService: MenuService,
      private carrelloService: FirestoreService,
      private route: ActivatedRoute,
      private cdRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dataServiceGenerico: DataServiceGenerico,
      public dialogRef: MatDialogRef<any>,
      private commonService: CommonService
  ) {

    this.ristorante = 'menu';
    this.route.params.subscribe(params => {
        console.log(params);
        if (params.ristorante) {
          //this.ristorante = params.ristorante;
          this.ristorante = 'menu';
        }
        if (params.tavolo) {
          this.numeroTavolo = params.tavolo;
        }
      }
    );

    this.retriveMenu();


    // this.retriveCarrello();
  }


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passBack() {
    this.passEntry.emit(this.user);
  }

  ngOnDestroy(): any {

    this.passBack();

  }

  piattoSelezionato = -1;

  ciao = false;

  ciao2 = false;

  ciao3 = false;
  ciao4 = false;

  pogba = -1;

  categoriaSelezionata = 0;

  animationTimeouts: { [key: string]: any } = {};

  selectedOptions: { [key: string]: Array<SelectedOption> } = {};

  handleSwipe(direction: string, variant: any, option: any) {
    const variantId = variant.id;
    if (!this.selectedOptions[variantId]) {
      this.selectedOptions[variantId] = [];
    }
    const options = this.selectedOptions[variantId];
    const existingOptionIndex = options.findIndex(opt => opt.name === option.name);

    if (existingOptionIndex > -1) {
      if (direction === 'right') {
        options[existingOptionIndex].quantity++;
        this.applyAnimation(variantId, option.name, 'success');
      } else {
        options[existingOptionIndex].quantity--;
        this.applyAnimation(variantId, option.name, 'error');
        if (options[existingOptionIndex].quantity <= 0) {
          options.splice(existingOptionIndex, 1);
          if (options.length === 0) {
            delete this.selectedOptions[variantId];
          }
        }
      }
      this.updateOrder();
      this.cdRef.detectChanges();
    }
  }

  selectOption(option: any, variant: any) {
    const variantId = variant.id;
    if (!this.selectedOptions[variantId]) {
      this.selectedOptions[variantId] = [];
    }

    const existingOptionIndex = this.selectedOptions[variantId].findIndex(opt => opt.name === option.name);

    if (existingOptionIndex > -1) {
      this.selectedOptions[variantId][existingOptionIndex].quantity++;
    } else {
      this.selectedOptions[variantId].push({
        name: option.name,
        priceModifier: option.priceModifier || 0,
        variantId: variant.id,
        variantTitle: variant.name,
        quantity: 1
      });
    }
    this.applyAnimation(variantId, option.name, 'success');
    this.updateOrder();
    this.cdRef.detectChanges();
  }

  private applyAnimation(variantId: string, optionName: string, type: 'success' | 'error') {
    const key = `${variantId}_${optionName}`;
    if (this.animationTimeouts[key]) {
      clearTimeout(this.animationTimeouts[key]);
    }

    const element = document.querySelector(`[data-variant="${variantId}"][data-option="${optionName}"]`);
    if (element) {
      element.classList.add(type === 'success' ? 'swipe-success' : 'swipe-error');
      this.animationTimeouts[key] = setTimeout(() => {
        element.classList.remove('swipe-success', 'swipe-error');
      }, 1000);
    }
  }

  updateOrder() {
    if (!this.currentOrder) {
      this.currentOrder = { carrello: {}, ordineInserimento: [] };
    }

    const nome = this.data.dataKey.title.replace(/ /g,'');

    // Rimuovi solo gli elementi relativi a questo prodotto
    Object.keys(this.currentOrder.carrello).forEach(key => {
      if (key.startsWith(nome)) {
        delete this.currentOrder.carrello[key];
        this.currentOrder.ordineInserimento =
            this.currentOrder.ordineInserimento.filter(item => item !== key);
      }
    });

    // Aggiungi le nuove selezioni
    Object.entries(this.selectedOptions).forEach(([variantId, options]) => {
      options.forEach(option => {
        if (option.quantity > 0) {
          const uniqueIdentifier = `${nome}_${variantId}_${option.name}`;
          const totalPriceModifier = option.priceModifier * option.quantity;

          this.currentOrder.carrello[uniqueIdentifier] = {
            ...this.data.dataKey,
            quantita: option.quantity,
            price: +this.data.dataKey.price,
            prezzo: +this.data.dataKey.price + totalPriceModifier,
            portata: this.portata == 4 ? 0 : this.portata,
            variants: [option]
          };

          this.currentOrder.ordineInserimento.push(uniqueIdentifier);
        }
      });
    });

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }

  getOptionQuantity(variantId: string, optionName: string): number {
    if (!this.selectedOptions[variantId]) return 0;
    const option = this.selectedOptions[variantId].find(opt => opt.name === optionName);
    return option ? option.quantity : 0;
  }

  ciaociao(i): any {
    console.log("ciaociaociao");
    this.ciao = !this.ciao;
    this.categoriaSelezionata = i;
  }

  ciaociao2(): any {

    this.ciao = false;
    this.ciao2 = false;
    this.ciao3 = false;
    this.ciao4 = false;
    this.categoriaSelezionata = 0;

  }

  ciaociao3(): any {

    this.ciao = true;
    this.ciao2 = false;
    this.ciao3 = false;
    this.ciao4 = false;
    this.piattoSelezionato = -1;

  }


  ciaociao4(): any{

    if (this.piattoSelezionato >= 0)
    {
      try{

        if (this.menu[this.categoriaSelezionata].menu[this.piattoSelezionato].opzioni){

        }

        this.ciao3 = false;
        return true;

      }catch (e){
        console.log('catch');
        this.ciao3 = true;
        this.piattoSelezionato = -1;
        return false;
      }
    }
    else{
      if (this.ciao === true)
      {
        this.ciao2 = false;
      }
      this.ciao3 = false;
      console.log('ffff');
      return false;
    }

  }


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      title: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      title: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      title: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      title: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      title: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  scontrini2 = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      title: 'Pizza 1',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      title: 'Burger 1',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      title: 'Italiano 1',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      title: 'Sushi 1',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      title: 'Poke 1',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  calcolaPrezzo(aggiunta: number)
  {
    let prezzo = this.data.dataKey.prezzo.slice(0, -1);
    prezzo = prezzo.replace(',', '.')
    console.log("prezzo: ", prezzo)
    return (+prezzo + aggiunta).toFixed(2)
  }


  carrello = new Array();

  prezzoCarrello;

  ifCarrello = false;

  carrelloTmp;


  ricerca = '';

  ricercato = false;


  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];


  tutorials?: CategoriesModel[];
  currentTutorial?: CategoriesModel;
  currentIndex = -1;
  title = '';
  menu = new Array();
  menuFiltrato = new Array();

  user: User;


  isOver = false;


  ristorante = 'ciao';
  numeroTavolo = 0;


  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  ingredienti = 'Visualizza Profilo';

  prezzo = 93263269631;

  scrollato = false;

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];

  public scannerEnabled = false;

  public categoria = 0;
  public categoriaBefore = 0;

  public porcodioPrima = 0;
  public porcodioDopo = 0;
  public prece = 0;
  public selez = 0;

  tot = 0;
  numeroElementi = 0;
  currentVariantIndex: number = 0;

  @ViewChildren('cacaca') testDiv: QueryList<any>;
  isTestDivScrolledIntoView: boolean;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  ngOnInit() {
    this.loadVariants();
    this.loadExistingCartItems();
  }

  loadExistingCartItems() {
    if (this.currentOrder?.carrello) {
      const nome = this.data.dataKey.title.replace(/ /g,'');

      Object.entries(this.currentOrder.carrello).forEach(([key, item]: [string, any]) => {
        if (key.startsWith(nome)) {
          const [_, variantId, optionName] = key.split('_');

          if (!this.selectedOptions[variantId]) {
            this.selectedOptions[variantId] = [];
          }

          if (item.variants?.[0]) {
            this.selectedOptions[variantId].push({
              ...item.variants[0],
              quantity: item.quantita
            });
          }
        }
      });
    }
  }

  loadVariants() {
    if (this.data.dataKey?.variantIds?.length > 0) {
      const db = firebase.firestore();
      const restaurantName = this.dataServiceGenerico.getRistorante();
      const variantiRef = db.collection('varianti').doc(restaurantName);

      variantiRef.get().then(doc => {
        if (doc.exists) {
          const allVariants = doc.data()?.variants || [];
          // Filtra solo le varianti che sono presenti nel piatto
          this.variants = allVariants.filter(v =>
              this.data.dataKey.variantIds.includes(v.id)
          );
          this.cdRef.detectChanges();
        }
      });
    }
  }

  nextVariant() {
    if (this.currentVariantIndex < this.variants.length - 1) {
      this.currentVariantIndex++;
      this.cdRef.detectChanges();
    }
  }

  previousVariant() {
    if (this.currentVariantIndex > 0) {
      this.currentVariantIndex--;
      this.cdRef.detectChanges();
    }
  }

  selectVariant(index: number) {
    if (index >= 0 && index < this.variants.length) {
      this.currentVariantIndex = index;
      this.cdRef.detectChanges();
    }
  }

  // Metodo per verificare se è l'ultima variante
  isLastVariant(): boolean {
    return this.currentVariantIndex === this.variants.length - 1;
  }

  // Metodo per verificare se è la prima variante
  isFirstVariant(): boolean {
    return this.currentVariantIndex === 0;
  }

  valuechange(newValue): any {
    console.log(this.ricerca);
    this.menuFiltrato = new Array();
    this.menuFiltrato = this.filterByValue(this.menu, newValue);
    console.log('menuCard filtrato');
    console.log(this.menuFiltrato);
    if (newValue.length > 0) {
      this.ricercato = true;
    } else {
      this.ricercato = false;
    }
    // this.scontrini.filter(t => t.title === newValue);

  }

  filterByValue(array, stringa): any {


    const result = [];

    /*
    result.concat(array.forEach(element => {

      console.log('element');
      console.log(element);
      element.menuCard.filter(o => {
        console.log('o');
        console.log(o);
        try {
          Object.keys(o).some(k => o[k].toLowerCase().includes(stringa.toLowerCase));
        }catch {}
      });

    }));

     */

    array.forEach(element => {
      console.log('element');
      console.log(element.menu);
      console.log('element filtrato');
      const filtrato = this.filterByValueInside(element.menu, stringa);
      console.log(filtrato);
      if (filtrato.length > 0) {
        result.push(filtrato);
      }
    });
    console.log('result');
    console.log(result);
    const risultato = new Array();
    risultato.push(new Array());
    result.forEach(element => risultato[0] = risultato[0].concat(element));
    console.log('risultato');
    console.log(risultato);
    return risultato;
  }

  filterByValueInside(arrayy, stringaa): any {

    console.log('filtro: ', arrayy, stringaa);

    // console.log('array');
    // console.log(array);
    return arrayy.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringaa.toLowerCase())));
  }


  retriveMenu(): void {

    this.menuService.getDoc(this.ristorante).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          console.log('menuCard: ', this.menu);
          console.log('ho trovato: ', data.data().menu);

          let i = 0;
          // tslint:disable-next-line:forin
          for (const x in data.data().menu) {
            this.menu.push(data.data().menu[i]);
            i = i + 1;
          }
          // @ts-ignore
          // this.menuCard = CategoriesModel[data.data];
          // @ts-ignore
          this.menu.concat(data.data().menu);
          // this.menuCard = data.data();
        }
      }
    );

  }

  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.carrelloService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;

          console.log(this.carrelloTmp);
          if (this.carrelloTmp.ristorante !== this.ristorante) {
            this.ifCarrello = false;
          } else {
            this.ifCarrello = true;


            this.calcolaTotale();


          }

          /*
          let i = 0;
          for (const x in data.data()) {
            this.carrello.push(data.data()[i]);
            i = i + 1;
          }
          // @ts-ignore
          // this.menuCard = CategoriesModel[data.data];
          // @ts-ignore
          this.menuCard.concat(data.data);
          // this.menuCard = data.data();

           */


        }
      }
    );
  }

  clickMenu(event): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.router.navigate(['/carrello']);
    event.stopPropagation();
  }

  // tslint:disable-next-line:typedef
  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );
  }

  clicco2(): void {
    this.router.navigate(['/tavoli']);
  }


  clicco3(): void {
    this.router.navigate([this.ristorante + '/menu/modifica/modifica']);
  }

  clickPiatto(piatto?: string): void {
    this.router.navigate([this.ristorante + '/menu/' + piatto]); // , { skipLocationChange: true});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    let offset = 300;
    if (this.ricercato === true) {
      offset = 280;
    } else {
      offset = 310;
    }
    // 350 with search bar
    // 280 without search bar
    if (window.pageYOffset > offset) {
      console.log('scrollato');

      const element3 = document.getElementById('menuListaPiatti');
      element3.classList.add('menuScrolled');
      element3.classList.remove('menu');
      this.scrollato = true;

    } else {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {

        console.log('scrollato2');
      } else {
        console.log('scrollato3');
        this.scrollato = false;
        const element3 = document.getElementById('menuListaPiatti');
        element3.classList.add('menu');
        element3.classList.remove('menuScrolled');
      }
    }

    console.log(this.testDiv);


    if (this.ricercato === false) {

      this.testDiv.forEach((child) => {

        if (child) {
          const test = child.nativeElement.id;
          const rect = child.nativeElement.getBoundingClientRect();
          const topShown = rect.top >= 0;
          const bottomShown = rect.bottom <= window.innerHeight;
          this.isTestDivScrolledIntoView = topShown && bottomShown;


          console.log('top/bottom: ' + (rect.top / rect.bottom));

          if (this.isTestDivScrolledIntoView) {
            this.porcodioPrima = window.pageYOffset;
            this.porcodioDopo = window.pageYOffset;
            this.categoria = test;
            this.categoriaBefore = test;


          }


          this.porcodioDopo = window.pageYOffset;


          if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 0.75)) {
            console.log('altezza su');


            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo((test * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + test);
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== test) {
                    const elemento = document.getElementById('buttonCategoria' + conta);
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );

              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');

            }

            const element2 = document.getElementById('buttonCategoria' + (test - 1));
            if (element2 !== null) {


              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          } else if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 1)) {
            console.log('altezza giu');

            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo(((test - 1) * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + (test - 1));
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                cathegories => {
                  if (conta !== (test - 1)) {
                    const elemento = document.getElementById('buttonCategoria' + (conta));
                    elemento.classList.remove('categorieVisibile');
                    elemento.classList.add('categorie');
                  }
                  conta++;
                }
              );


              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');
            }

            const element2 = document.getElementById('buttonCategoria' + test);
            if (element2 !== null) {
              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          }


        }


        console.log(this.isTestDivScrolledIntoView);
      });

    }

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);

    const yOffset = -150;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});


    // const el = document.getElementById(id).scrollIntoView();
    // el.scrollIntoView({behavior: 'smooth'});
  }


  rimuoviElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    if (this.carrelloTmp.piatti[i].quantita === 1) {
      this.carrelloTmp.piatti.splice(i, 1);
      console.log('carrelo con rimozione: ', this.carrelloTmp);

    } else {
      this.carrelloTmp.piatti[i].quantita--;
    }

    this.calcolaTotale();

    this.salva();


    event.stopPropagation();
  }

  aggiungiElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    this.carrelloTmp.piatti[i].quantita = this.carrelloTmp.piatti[i].quantita + 1;

    this.calcolaTotale();

    this.salva();

    event.stopPropagation();
  }

  getPrezzo(i): any {
    // console.log('ciaobiuyew giuye231<giue23<y789e23<');
    // console.log("carrello: ", this.carrelloTmp);
    // console.log("index: ", i);


    // console.log('piatto', this.carrelloTmp.piatti[i]);

    let prezzoPiatto = this.carrelloTmp.piatti[i].prezzo;

    if (this.carrelloTmp.piatti[i].opzioni) {
      this.carrelloTmp.piatti[i].opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }

    return prezzoPiatto;
  }


  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log('user: ', this.user);

    this.user.carrello = this.carrelloTmp;

    console.log('user da salvare: ', this.user);

    this.carrelloService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.carrelloService.create(userDasalvareSalva, this.user.uid);
    // this.router.navigate(['/menuCard']);
  }

}



/*
scroll(id: any): void {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  //el.scrollIntoView({behavior: 'smooth'});
  el.scrollIntoView({ behavior: 'smooth' });

}

 */
