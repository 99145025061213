import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

@Component({
  selector: 'add-magazzino',
  templateUrl: './add-magazzino.html',
  styleUrls: ['./add-magazzino.css'],
})
export class AddMagazzino implements OnInit, OnDestroy {
  isMenuChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  Titolo: string = '';
  id: string = '';
  selectedCategoria: string = '';
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';
  quantity: string = '';
  supplierCode: string = '';
  supplier: string = '';
  expiryDate: string = '';
  um: string = '';
  maxLvl: number = 1; // Default a 1 livello

  // Variabili per i livelli di imballaggio
  levels: { quantita: string, um: string, barCode: string }[] = [
    { quantita: '', um: '', barCode: '' }, // Primo livello
    { quantita: '', um: '', barCode: '' }, // Secondo livello
    { quantita: '', um: '', barCode: '' }, // Terzo livello
    { quantita: '', um: '', barCode: '' }  // Quarto livello
  ];

  // Variabili per la cronologia
  cronologia: any[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isLvl1 = true;
  isLvl2 = false;
  isLvl3 = false;
  isLvl4 = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddMagazzino>,
    private dataService: DataServiceGenerico,
    @Inject(MAT_DIALOG_DATA) public data: any  // Riceve i dati dal dialog
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  ngOnInit(): void {
    this.fetchCategoriesFromFirestore();

    if (this.data) {
      // Popola i campi con i dati passati
      this.id = this.data.id || '';
      this.Titolo = this.data.Titolo || '';
      this.selectedCategoria = this.data.Categoria || '';
      this.selectedImage = this.data.image_link || '';
      this.supplierCode = this.data.CodiceFornitore || '';
      this.supplier = this.data.Fornitore || '';
      this.quantity = this.data.Quantita || '';
      this.price = this.data.Prezzo || '';
      this.expiryDate = this.data.Scadenza || '';
      this.um = this.data.UM || '';
      this.maxLvl = this.data.maxLvl || 1;

      // Inizializza i livelli con quantità zero
      this.levels = this.data.lvl?.map((level) => ({ ...level, quantita: '0' })) || [
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' }
      ];

      this.isMenuChecked = this.data.menu;
      this.isSingleChecked = this.data.single;
      this.isDisponibileChecked = this.data.disponibilita;

      // Imposta i livelli in base a maxLvl
      this.isLvl1 = this.maxLvl >= 1;
      this.isLvl2 = this.maxLvl >= 2;
      this.isLvl3 = this.maxLvl >= 3;
      this.isLvl4 = this.maxLvl >= 4;

      this.cdRef.detectChanges();
    }
  }


  ngOnDestroy(): any {
    this.passBack();
  }

  fetchCategoriesFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const collectionRef = db.collection('magazzino_test').doc(restaurantName);

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();
        const ingredientsSet = new Set<string>();

        items.forEach(item => {
          if (item.Categoria) {
            categoriesSet.add(item.Categoria);
          }
          if (item.Titolo) {
            ingredientsSet.add(item.Titolo); // Usa i titoli dei prodotti come ingredienti
          }
        });

        this.categories = Array.from(categoriesSet);
        this.ingredients = Array.from(ingredientsSet);
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }

  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  onMenuChangeLevel1(event: any) {
    this.isLvl1 = event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl1 ? 1 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel2(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl2 ? 2 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel3(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl3 ? 3 : 2;  // Aggiorna maxLvl
  }

  onMenuChangeLevel4(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = event.target.checked;
    this.maxLvl = this.isLvl4 ? 4 : 3;  // Aggiorna maxLvl
  }


  aggiunta = true;
  inventario = false;
  spreco = false;
  trasferimento = false;

  onModalitaChangeAggiunta(event: any)
  {
    this.aggiunta = true;
    this.spreco = false;
    this.trasferimento = false;
    this.inventario = false;
  }

  onModalitaChangeSpreco(event: any)
  {
    this.aggiunta = false;
    this.spreco = true;
    this.trasferimento = false;
    this.inventario = false;
  }

  onModalitaChangeTrasferimento(event: any)
  {
    this.aggiunta = false;
    this.spreco = false;
    this.trasferimento = true;
    this.inventario = false;
  }

  onModalitaChangeInventario(event: any)
  {
    this.aggiunta = false;
    this.spreco = false;
    this.trasferimento = false;
    this.inventario = true;
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage(levelIndex: number) {
    this.levels[levelIndex].barCode = '';
    this.cdRef.detectChanges();
  }

  onFileSelected(event: any, levelIndex: number) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file, levelIndex);
    }
  }

  uploadImageToFirebase(file: File, levelIndex: number) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.levels[levelIndex].barCode = downloadURL;
          this.cdRef.detectChanges();
        });
      }
    );
  }

  saveChangesToFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        if (this.id) {
          // Trova l'elemento esistente
          const currentItem = items.find(item => item.id === this.id);

          if (currentItem) {
            // Calcola la quantità totale da aggiungere basata sui livelli
            let quantitaAggiunta = 0;

            if (this.maxLvl >= 1) {
              quantitaAggiunta += parseInt(this.levels[0].quantita || '0', 10) * parseInt(this.data.lvl[0].quantita || '0', 10);
            }
            if (this.maxLvl >= 2) {
              quantitaAggiunta += parseInt(this.levels[1].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }
            if (this.maxLvl >= 3) {
              quantitaAggiunta += parseInt(this.levels[2].quantita || '0', 10) * parseInt(this.data.lvl[2].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }
            if (this.maxLvl === 4) {
              quantitaAggiunta += parseInt(this.levels[3].quantita || '0', 10) * parseInt(this.data.lvl[3].quantita || '0', 10) * parseInt(this.data.lvl[2].quantita || '0', 10) * parseInt(this.data.lvl[1].quantita || '0', 10);
            }

            let newQuantity = 0;
            let modalita = ''
            if(this.aggiunta == true)
            {
              modalita = 'aggiunta'
              newQuantity = parseInt(currentItem.Quantita || '0', 10) + quantitaAggiunta;
            }
            if(this.spreco == true)
            {
              modalita = 'spreco'

              newQuantity = parseInt(currentItem.Quantita || '0', 10) - quantitaAggiunta;
            }
            if(this.inventario == true)
            {
              modalita = 'inventario'

              newQuantity = quantitaAggiunta;
            }
            if(this.trasferimento == true)
            {
              modalita = 'trasferimento'

              newQuantity = parseInt(currentItem.Quantita || '0', 10) - quantitaAggiunta;
            }

            const updatedItem = {
              ...currentItem,
              Quantita: newQuantity.toString(),
              Scadenza: this.expiryDate,
              cronologia: [
                ...(currentItem.cronologia || []),
                {
                  data: new Date().toLocaleString(),
                  quantitaPrec: currentItem.Quantita,
                  quantitaAgg: quantitaAggiunta.toString(),
                  lvl: this.levels.slice(0, this.maxLvl),
                  modalita: modalita
                }
              ]
            };

            const updatedItems = items.map(item =>
              item.id === this.id ? updatedItem : item
            );

            magazzinoRef.update({ items: updatedItems })
              .then(() => {
                console.log('Quantità aggiornata con successo su Firestore!');
              })
              .catch((error) => {
                console.error('Errore durante l\'aggiornamento della quantità su Firestore: ', error);
              });
          } else {
            console.error('Elemento non trovato');
          }
        } else {
          console.error('ID del prodotto mancante.');
        }
      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }



  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const updatedItems = items.filter(item => item.id !== this.id);

        magazzinoRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Elemento eliminato con successo da Firestore!');
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
          });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    // Potresti voler restituire dati al chiamante, ad esempio:
    // this.passEntry.emit({ ... });
  }
}
