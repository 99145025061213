import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LevelDialogComponent } from './level-dialog';
import { UserOrdersDialogComponent } from './user-orders-dialog.component';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';

interface UserStats {
    id: string;
    displayName: string;
    email: string;
    telefono: string;
    punti: number;
    totalePunti: number;
    level: number;
    favoriteProduct: {
        title: string;
        image_link: string;
    };
}

@Component({
    selector: 'app-fidelity-users',
    template: `
        <div class="dashboard-container">
            <!-- Stats Cards Row -->
            <div class="stats-cards">
                <div class="gradient-card">
                    <div class="card-content">
                        <div class="value">{{totalUsers}}</div>
                        <div class="label">Utenti Totali</div>
                        <div class="icon-container">
                            <mat-icon>people</mat-icon>
                        </div>
                    </div>
                </div>

                <div class="gradient-card">
                    <div class="card-content">
                        <div class="value">{{avgPoints | number:'1.0-0'}}</div>
                        <div class="label">Punti Medi</div>
                        <div class="icon-container">
                            <mat-icon>stars</mat-icon>
                        </div>
                    </div>
                </div>

                <div class="gradient-card purple">
                    <div class="card-content">
                        <div class="value">{{topUserName}}</div>
                        <div class="label">Utente Più Fedele</div>
                        <div class="icon-container">
                            <mat-icon>emoji_events</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Search Section -->
            <div class="search-section">
                <div class="search-container">
                    <mat-form-field appearance="outline" class="search-field">
                        <mat-label>Cerca utente</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Cerca...">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <!-- Table Section -->
            <div style="flex: 1; overflow: auto; width: 100%; height: 61vh">
                <table mat-table [dataSource]="dataSource" matSort class="products-table">
                    <!-- Name Column -->
                    <ng-container matColumnDef="displayName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
                        <td mat-cell *matCellDef="let user">{{user.displayName}}</td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let user">{{user.email}}</td>
                    </ng-container>

                    <!-- Phone Column -->
                    <ng-container matColumnDef="telefono">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
                        <td mat-cell *matCellDef="let user">{{user.telefono || '-'}}</td>
                    </ng-container>

                    <!-- Points Column -->
                    <ng-container matColumnDef="punti">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Punti Attuali</th>
                        <td mat-cell *matCellDef="let user">
                            <span class="quantity-value">{{user.punti}}</span>
                        </td>
                    </ng-container>

                    <!-- Total Points Column -->
                    <ng-container matColumnDef="totalePunti">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Punti Totali</th>
                        <td mat-cell *matCellDef="let user">
                            <span class="quantity-value">{{user.totalePunti}}</span>
                        </td>
                    </ng-container>

                    <!-- Favorite Product Column -->
                    <ng-container matColumnDef="favoriteProduct">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Prodotto Preferito</th>
                        <td mat-cell *matCellDef="let user">
                            <div class="favorite-product" style="display: flex; align-items: center; gap: 12px;">
                                <div *ngIf="user.favoriteProduct.image_link"
                                     style="width: 40px; height: 40px; border-radius: 8px; overflow: hidden;">
                                    <img [src]="user.favoriteProduct.image_link"
                                         style="width: 100%; height: 100%; object-fit: cover;"
                                         onerror="this.src='assets/placeholder.png'"
                                         [alt]="user.favoriteProduct.title">
                                </div>
                                <span>{{user.favoriteProduct.title}}</span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Level Column -->
                    <ng-container matColumnDef="level">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Livello</th>
                        <td mat-cell *matCellDef="let user">
                        <span class="category-badge"
                              [ngStyle]="getLevelStyle(user.level)"
                              (click)="onLevelClick(user); $event.stopPropagation()"
                              style="cursor: pointer">
                            Livello {{user.level}}
                        </span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        (click)="onRowClick(row)"
                        style="cursor: pointer"
                        class="hover:bg-gray-50"></tr>
                </table>
            </div>

            <!-- Loading Overlay -->
            <div class="loading-overlay" *ngIf="loading">
                <mat-spinner diameter="50" color="accent"></mat-spinner>
            </div>

            <!-- Error State -->
            <div class="error-state" *ngIf="error">
                <mat-icon color="warn">error_outline</mat-icon>
                <h3>Errore di caricamento</h3>
                <p>Non è stato possibile caricare i dati.</p>
                <button mat-raised-button color="primary" (click)="loadUserStats()">
                    Riprova
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./users-fidelity.css']
})
export class FidelityUsersComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['displayName', 'email', 'telefono', 'punti', 'totalePunti', 'favoriteProduct', 'level'];
    dataSource: MatTableDataSource<UserStats>;

    totalUsers: number = 0;
    avgPoints: number = 0;
    topUserName: string = '';

    loading = true;
    error = false;

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService,
        private dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource<UserStats>();
    }

    ngOnInit(): void {
        this.loadUserStats();
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private async getFavoriteProduct(userId: string, restaurantId: string): Promise<{ title: string; image_link: string }> {
        try {
            const ordersSnapshot = await firebase.firestore()
                .collection('ordini')
                .where('userId', '==', userId)
                .where('ristorante', '==', restaurantId)
                .where('statoPagato', '==', 0)
                .get();

            const productCount = new Map<string, { count: number; product: any }>();

            ordersSnapshot.docs.forEach(doc => {
                const order = doc.data();
                if (order.carrello) {
                    Object.values(order.carrello).forEach((item: any) => {
                        const key = item.title;
                        const current = productCount.get(key) || { count: 0, product: item };
                        productCount.set(key, {
                            count: current.count + (item.quantita || 1),
                            product: item
                        });
                    });
                }
            });

            let maxCount = 0;
            let favoriteProduct = { title: '-', image_link: '' };

            productCount.forEach(({count, product}, key) => {
                if (count > maxCount) {
                    maxCount = count;
                    favoriteProduct = {
                        title: product.title,
                        image_link: product.image_link || ''
                    };
                }
            });

            return favoriteProduct;
        } catch (error) {
            console.error('Error getting favorite product:', error);
            return { title: '-', image_link: '' };
        }
    }

    protected async loadUserStats(): Promise<void> {
        try {
            this.loading = true;

            const restaurantId = await this.dataService.getCurrentRestaurantId();

            const usersSnapshot = await firebase.firestore()
                .collection('users')
                .where('ristorantiFidelity', 'array-contains', restaurantId)
                .get();

            const users = await Promise.all(usersSnapshot.docs.map(async doc => {
                const data = doc.data();
                const points = data.punti || 0;
                return {
                    id: doc.id,
                    displayName: data.displayName || 'Utente Anonimo',
                    email: data.email || '',
                    telefono: data.telefono || '',
                    punti: points,
                    totalePunti: data.totalePunti || 0,
                    level: this.calculateLevel(points),
                    favoriteProduct: await this.getFavoriteProduct(doc.id, restaurantId)
                };
            }));

            this.dataSource.data = users;
            this.calculateStatistics(users);
            this.loading = false;
        } catch (error) {
            console.error('Error loading user statistics:', error);
            this.error = true;
            this.loading = false;
        }
    }

    private calculateLevel(points: number): number {
        if (points >= 2000) return 5;
        if (points >= 1200) return 4;
        if (points >= 800) return 3;
        if (points >= 400) return 2;
        if (points >= 200) return 1;
        return 0;
    }

    private calculateStatistics(users: UserStats[]): void {
        this.totalUsers = users.length;
        this.avgPoints = users.reduce((sum, user) => sum + user.punti, 0) / Math.max(users.length, 1);

        const topUser = users.reduce((prev, current) =>
                (current.totalePunti > prev.totalePunti) ? current : prev
            , users[0]);

        this.topUserName = topUser?.displayName || '';
    }

    async onRowClick(user: UserStats): Promise<void> {
        const restaurantId = await this.dataService.getCurrentRestaurantId();
        this.dialog.open(UserOrdersDialogComponent, {
            data: {
                userId: user.id,
                userName: user.displayName,
                restaurantId
            }
        });
    }

    async onLevelClick(user: UserStats): Promise<void> {
        const dialogRef = this.dialog.open(LevelDialogComponent, {
            width: '400px',
            data: { currentLevel: user.level }
        });

        const result = await dialogRef.afterClosed().toPromise();

        if (result) {
            try {
                await firebase.firestore()
                    .collection('users')
                    .doc(user.id)
                    .update({
                        totalePunti: result.points
                    });

                // Aggiorna la riga nella tabella
                const index = this.dataSource.data.findIndex(u => u.id === user.id);
                if (index !== -1) {
                    const updatedUser = {
                        ...user,
                        totalePunti: result.points,
                        level: result.level
                    };

                    const newData = [...this.dataSource.data];
                    newData[index] = updatedUser;
                    this.dataSource.data = newData;

                    // Ricalcola le statistiche
                    this.calculateStatistics(newData);
                }
            } catch (error) {
                console.error('Error updating user level:', error);
            }
        }
    }

    getLevelStyle(level: number): object {
        const colors = {
            0: { background: 'rgba(156, 163, 175, 0.1)', color: '#6B7280' },
            1: { background: 'rgba(59, 130, 246, 0.1)', color: '#3B82F6' },
            2: { background: 'rgba(16, 185, 129, 0.1)', color: '#10B981' },
            3: { background: 'rgba(245, 158, 11, 0.1)', color: '#F59E0B' },
            4: { background: 'rgba(237, 88, 14, 0.1)', color: '#ED580E' },
            5: { background: 'rgba(239, 68, 68, 0.1)', color: '#EF4444' }
        };

        return colors[level] || colors[0];
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
}