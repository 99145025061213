import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import { Router } from "@angular/router";
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';

interface RestaurantStats {
    id: string;
    name: string;
    monthlyStats: {
        lastMonth: number;
        currentMonth: number;
        projected: number;
        percentageChange: number;
    };
    revenueByYearMonth: Map<string, number>; // Formato 'YYYY-MM'
    topProducts: TopProduct[];
    totalRevenue: number;
    ordersBySource?: {
        app: number;
        kiosk: number;
        pos: number;
    };
    ordersByType?: {
        table: number;
        takeaway: number;
        counter: number;
    };
}

interface TopProduct {
    title: string;
    image_link: string;
    quantity: number;
    revenue: number;
    percentage: number;
}

interface RestaurantFilters {
    dateRange: {
        start: Date;
        end: Date;
    };
    orderTypes: string[];
    orderSources: string[];
    timeRange: {
        start: string;
        end: string;
    };
    searchTerm: string;
}

@Component({
    selector: 'app-analytics-multi-dashboard',
    templateUrl: './analytics-multi-dashboard.component.html',
    styleUrls: ['./analytics-multi-dashboard.component.css']
})
export class AnalyticsMultiDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('revenueComparisonChart') revenueChartCanvas: ElementRef;

    restaurants: any[] = [];
    restaurantStats: RestaurantStats[] = [];
    chartLabels: string[] = [];
    loading = true;
    error = false;
    compareMode: 'revenue' | 'growth' | 'products' | 'sources' = 'revenue';
    sortBy: 'name' | 'revenue' | 'growth' = 'revenue';
    sortDirection: 'asc' | 'desc' = 'desc';
    activePreset: string = 'lastMonth';
    showCustomDateRange = false;

    // Form Controls
    dateRange: FormGroup;
    timeRangeControl: FormControl;

    // Dati adattati alla scala temporale
    timeScale: 'hour' | 'day' | 'month' = 'month';

    // Proprietà per i dati temporali
    hourlyData: Map<string, Map<number, number>> = new Map(); // ristorante -> ora -> ricavo
    dailyData: Map<string, Map<string, number>> = new Map();  // ristorante -> giorno -> ricavo
    monthlyData: Map<string, Map<string, number>> = new Map(); // ristorante -> mese -> ricavo

    private destroy$ = new Subject<void>();

    private chart: Chart;
    private chartColors = [
        '#4F46E5', // Indigo
        '#3B82F6', // Blue
        '#06B6D4', // Cyan
        '#10B981', // Emerald
        '#F59E0B', // Amber
        '#EF4444', // Red
        '#8B5CF6', // Violet
        '#EC4899'  // Pink
    ];

    // Filtri
    filters: RestaurantFilters = {
        dateRange: {
            start: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            end: new Date()
        },
        orderTypes: ['table', 'takeaway', 'counter'],
        orderSources: ['app', 'kiosk', 'pos'],
        timeRange: {
            start: '00:00',
            end: '23:59'
        },
        searchTerm: ''
    };

    // Tipi di ordine
    orderTypeFilters = [
        { label: 'Tavolo', value: 'table', icon: 'table_restaurant' },
        { label: 'Asporto', value: 'takeaway', icon: 'delivery_dining' },
        { label: 'Banco', value: 'counter', icon: 'point_of_sale' }
    ];

    // Fonti degli ordini
    orderSourceFilters = [
        { label: 'App', value: 'app', icon: 'smartphone' },
        { label: 'Kiosk', value: 'kiosk', icon: 'tablet_mac' },
        { label: 'Cassa', value: 'pos', icon: 'point_of_sale' }
    ];

    // Filtri predefiniti per date
    datePresets = [
        { label: 'Oggi', value: 'today' },
        { label: 'Ieri', value: 'yesterday' },
        { label: 'Ultima Settimana', value: 'lastWeek' },
        { label: 'Ultimo Mese', value: 'lastMonth' },
        { label: 'Ultimo Anno', value: 'lastYear' }
    ];

    constructor(
        public router: Router,
        private commonService: CommonService,
        private dataService: DataServiceGenerico
    ) {
        this.dateRange = new FormGroup({
            start: new FormControl(),
            end: new FormControl()
        });
        this.timeRangeControl = new FormControl('all');
    }

    ngOnInit(): void {
        this.initializeFormControls();
        this.setDatePreset('lastMonth'); // Imposta un valore predefinito
    }

    ngAfterViewInit(): void {
        // Chart will be initialized after data is loaded
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeFormControls(): void {
        // Set default values for date range
        this.dateRange.setValue({
            start: this.filters.dateRange.start,
            end: this.filters.dateRange.end
        });

        // Subscribe to form control changes
        this.timeRangeControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.onTimeRangeChange(value));

        this.dateRange.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(range => {
                if (range.start && range.end) {
                    this.updateDateRange(range.start, range.end);
                }
            });
    }

    private loadRestaurants(): void {
        this.loading = true;
        this.dataService.availableRestaurants$.subscribe(restaurants => {
            if (restaurants.length > 0) {
                this.restaurants = restaurants;
                this.loadAllRestaurantsData();
            } else {
                this.loading = false;
                this.error = true;
            }
        });
    }

    private loadAllRestaurantsData(): void {
        // Prima genera le etichette del grafico in base al periodo selezionato
        this.generateChartLabels();

        // Pulisci le mappe di dati temporali
        this.hourlyData.clear();
        this.dailyData.clear();
        this.monthlyData.clear();

        const restaurantDataObservables = this.restaurants.map(restaurant =>
            this.loadRestaurantData(restaurant)
        );

        forkJoin(restaurantDataObservables).subscribe(
            results => {
                this.restaurantStats = results.filter(data => data !== null);
                this.loading = false;

                // Sort restaurants
                this.sortRestaurants();

                // Initialize chart after data is loaded
                setTimeout(() => {
                    if (this.revenueChartCanvas) {
                        this.initComparisonChart();
                    }
                }, 0);
            },
            error => {
                console.error('Error loading restaurant data:', error);
                this.loading = false;
                this.error = true;
            }
        );
    }

    private loadRestaurantData(restaurant: any): Observable<RestaurantStats> {
        const startTimestamp = this.filters.dateRange.start.getTime();
        const endTimestamp = this.filters.dateRange.end.getTime();

        return from(firebase.firestore()
            .collection('ordini')
            .where('ristorante', '==', restaurant.id)
            .where('data', '>=', startTimestamp.toString())
            .where('data', '<=', endTimestamp.toString())
            .get()
        ).pipe(
            map(ordersSnapshot => {
                const allOrders = ordersSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    selectedPriceListType: doc.data().selectedPriceListType || 'counter',
                    orderSource: doc.data().orderSource || 'pos',
                    data: doc.data().data || Date.now().toString()
                }));

                // Applica i filtri
                const orders = this.applyFiltersToOrders(allOrders);

                if (orders.length === 0) {
                    // Return empty stats for restaurants with no orders
                    return {
                        id: restaurant.id,
                        name: restaurant.nome,
                        monthlyStats: {
                            lastMonth: 0,
                            currentMonth: 0,
                            projected: 0,
                            percentageChange: 0
                        },
                        revenueByYearMonth: new Map<string, number>(),
                        topProducts: [],
                        totalRevenue: 0,
                        ordersBySource: {
                            app: 0,
                            kiosk: 0,
                            pos: 0
                        },
                        ordersByType: {
                            table: 0,
                            takeaway: 0,
                            counter: 0
                        }
                    };
                }

                // Calculate monthly stats
                const monthlyStats = this.calculateMonthlyStats(orders);

                // Calculate revenue by year and month
                const revenueByYearMonth = this.calculateRevenueByYearMonth(orders);

                // Calculate top products
                const { topProducts, totalRevenue } = this.calculateTopProducts(orders);

                // Calculate orders by source and type
                const ordersBySource = this.calculateOrdersBySource(orders);
                const ordersByType = this.calculateOrdersByType(orders);

                // Calcola i dati per le diverse scale temporali
                const { hourly, daily, monthly } = this.calculateTimeScaleData(orders, restaurant.id);

                // Salva i dati nelle mappe
                this.hourlyData.set(restaurant.id, hourly);
                this.dailyData.set(restaurant.id, daily);
                this.monthlyData.set(restaurant.id, monthly);

                return {
                    id: restaurant.id,
                    name: restaurant.nome,
                    monthlyStats,
                    revenueByYearMonth,
                    topProducts,
                    totalRevenue,
                    ordersBySource,
                    ordersByType
                };
            })
        );
    }

    private applyFiltersToOrders(orders: any[]): any[] {
        return orders.filter(order => {
            // Filtra per tipo di ordine
            if (this.filters.orderTypes.length &&
                !this.filters.orderTypes.includes(order.selectedPriceListType)) {
                return false;
            }

            // Filtra per fonte dell'ordine
            if (this.filters.orderSources.length &&
                !this.filters.orderSources.includes(order.orderSource)) {
                return false;
            }

            // Filtra per orario
            const orderTime = moment(parseInt(order.data));
            const startTime = moment(this.filters.timeRange.start, 'HH:mm');
            const endTime = moment(this.filters.timeRange.end, 'HH:mm');

            startTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());
            endTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());

            if (!orderTime.isBetween(startTime, endTime, undefined, '[]')) {
                return false;
            }

            // Filtra per termine di ricerca
            if (this.filters.searchTerm) {
                const searchTerm = this.filters.searchTerm.toLowerCase();
                const searchableFields = [
                    order.displayName,
                    ...(order.carrello ? Object.values(order.carrello).map((item: any) => item.title) : [])
                ].filter(Boolean);

                return searchableFields.some(field =>
                    field && typeof field === 'string' && field.toLowerCase().includes(searchTerm)
                );
            }

            return true;
        });
    }

    private calculateMonthlyStats(orders: any[]): { lastMonth: number; currentMonth: number; projected: number; percentageChange: number } {
        const now = new Date();

        // Calcola periodo attuale e periodo precedente
        const currentPeriodStart = new Date(this.filters.dateRange.start);
        const currentPeriodEnd = new Date(this.filters.dateRange.end);

        // Calcola la durata del periodo in millisecondi
        const periodDuration = currentPeriodEnd.getTime() - currentPeriodStart.getTime();

        // Calcola il periodo precedente (con la stessa durata)
        const previousPeriodEnd = new Date(currentPeriodStart.getTime() - 1); // 1 millisecondo prima del periodo attuale
        const previousPeriodStart = new Date(previousPeriodEnd.getTime() - periodDuration);

        // Calcola fatturato per periodo attuale
        let currentPeriodRevenue = 0;
        const currentPeriodOrders = orders.filter(order => {
            if (!order.data) return false;
            const orderDate = new Date(parseInt(order.data));
            return orderDate >= currentPeriodStart && orderDate <= currentPeriodEnd;
        });

        currentPeriodOrders.forEach(order => {
            currentPeriodRevenue += this.calculateOrderRevenue(order);
        });

        // Calcola fatturato per periodo precedente (usando tutti gli ordini disponibili, non solo quelli filtrati)
        let previousPeriodRevenue = 0;

        // Qui utilizziamo una query separata per ottenere gli ordini del periodo precedente
        // In un'implementazione reale, dovresti fare una query al DB per questo periodo
        // In questo esempio simuliamo guardando solo negli ordini disponibili
        const previousOrders = orders.filter(order => {
            if (!order.data) return false;
            const orderDate = new Date(parseInt(order.data));
            return orderDate >= previousPeriodStart && orderDate <= previousPeriodEnd;
        });

        previousOrders.forEach(order => {
            previousPeriodRevenue += this.calculateOrderRevenue(order);
        });

        // Calcola proiezione in base al periodo selezionato
        let projectedRevenue = currentPeriodRevenue;

        // Se è selezionato un periodo specifico (oggi, ieri, ultima settimana, ultimo mese, ultimo anno)
        if (this.activePreset) {
            // Per "oggi" e "ieri" proiettiamo al giorno completo
            if (this.activePreset === 'today' || this.activePreset === 'yesterday') {
                // Determina quanto del giorno è già trascorso (in percentuale)
                const startOfDay = new Date(now);
                startOfDay.setHours(0, 0, 0, 0);

                const endOfDay = new Date(now);
                endOfDay.setHours(23, 59, 59, 999);

                const totalDayMs = endOfDay.getTime() - startOfDay.getTime();
                const elapsedMs = now.getTime() - startOfDay.getTime();
                const dayPercentage = elapsedMs / totalDayMs;

                // Se siamo nel periodo "oggi", proietta per l'intero giorno
                if (this.activePreset === 'today' && dayPercentage > 0) {
                    projectedRevenue = currentPeriodRevenue / dayPercentage;
                }
            }
            // Per "ultima settimana" proiettiamo alla settimana completa
            else if (this.activePreset === 'lastWeek') {
                // Calcola quanti giorni della settimana sono già trascorsi
                const daysPassed = Math.ceil((now.getTime() - currentPeriodStart.getTime()) / (1000 * 60 * 60 * 24));
                const totalDays = 7;

                if (daysPassed < totalDays) {
                    projectedRevenue = (currentPeriodRevenue / daysPassed) * totalDays;
                }
            }
            // Per "ultimo mese" proiettiamo al mese completo
            else if (this.activePreset === 'lastMonth') {
                // Calcola quanti giorni del mese sono già trascorsi
                const daysPassed = Math.ceil((now.getTime() - currentPeriodStart.getTime()) / (1000 * 60 * 60 * 24));
                const daysInMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();

                if (daysPassed < daysInMonth) {
                    projectedRevenue = (currentPeriodRevenue / daysPassed) * daysInMonth;
                }
            }
            // Per "ultimo anno" proiettiamo all'anno completo
            else if (this.activePreset === 'lastYear') {
                // Calcola quanti giorni dell'anno sono già trascorsi
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                const daysPassed = Math.ceil((now.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24));
                const daysInYear = this.isLeapYear(now.getFullYear()) ? 366 : 365;

                if (daysPassed < daysInYear) {
                    projectedRevenue = (currentPeriodRevenue / daysPassed) * daysInYear;
                }
            }
        }
        // Per periodi personalizzati, non facciamo proiezioni a meno che il periodo non includa oggi
        else {
            const includesCurrentDate = now >= currentPeriodStart && now <= currentPeriodEnd;

            if (includesCurrentDate) {
                // Calcola quanto del periodo è già trascorso
                const totalPeriodMs = currentPeriodEnd.getTime() - currentPeriodStart.getTime();
                const elapsedMs = now.getTime() - currentPeriodStart.getTime();
                const periodPercentage = elapsedMs / totalPeriodMs;

                if (periodPercentage > 0 && periodPercentage < 1) {
                    projectedRevenue = currentPeriodRevenue / periodPercentage;
                }
            }
        }

        // Calcola la variazione percentuale
        const percentageChange = previousPeriodRevenue > 0
            ? ((currentPeriodRevenue - previousPeriodRevenue) / previousPeriodRevenue) * 100
            : 0;

        return {
            lastMonth: previousPeriodRevenue, // Rinominare questa proprietà in futuro a "previousPeriod"
            currentMonth: currentPeriodRevenue, // Rinominare questa proprietà in futuro a "currentPeriod"
            projected: projectedRevenue,
            percentageChange: percentageChange
        };
    }

    // Utility helper per verificare se l'anno è bisestile
    private isLeapYear(year: number): boolean {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    private calculateRevenueByYearMonth(orders: any[]): Map<string, number> {
        const revenueByYearMonth = new Map<string, number>();

        // Inizializza la mappa con gli ultimi 12 mesi a zero
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();

        for (let i = 0; i < 12; i++) {
            let targetMonth = currentMonth - i;
            let targetYear = currentYear;

            if (targetMonth < 0) {
                targetMonth += 12;
                targetYear -= 1;
            }

            const monthKey = `${targetYear}-${(targetMonth + 1).toString().padStart(2, '0')}`;
            revenueByYearMonth.set(monthKey, 0);
        }

        // Riempi la mappa con i dati effettivi
        orders.forEach(order => {
            if (!order.data || !order.carrello) return;

            const orderDate = new Date(parseInt(order.data));
            const orderYear = orderDate.getFullYear();
            const orderMonth = orderDate.getMonth() + 1; // +1 perché getMonth() restituisce 0-11
            const monthKey = `${orderYear}-${orderMonth.toString().padStart(2, '0')}`;

            // Calcola il fatturato per questo ordine
            const orderRevenue = this.calculateOrderRevenue(order);

            // Aggiungi al mese corrispondente se è nell'intervallo di visualizzazione
            if (revenueByYearMonth.has(monthKey)) {
                revenueByYearMonth.set(monthKey, (revenueByYearMonth.get(monthKey) || 0) + orderRevenue);
            }
        });

        return revenueByYearMonth;
    }

    private calculateTimeScaleData(orders: any[], restaurantId: string): {
        hourly: Map<number, number>,
        daily: Map<string, number>,
        monthly: Map<string, number>
    } {
        const hourlyMap = new Map<number, number>();
        const dailyMap = new Map<string, number>();
        const monthlyMap = new Map<string, number>();

        // Inizializza mappe con zeri in base al periodo
        this.initializeTimeMaps(hourlyMap, dailyMap, monthlyMap);

        // Popola le mappe con i dati effettivi
        orders.forEach(order => {
            if (!order.data || !order.carrello) return;

            const orderDate = new Date(parseInt(order.data));
            const orderRevenue = this.calculateOrderRevenue(order);

            // Aggiorna mappa oraria (0-23)
            const hour = orderDate.getHours();
            hourlyMap.set(hour, (hourlyMap.get(hour) || 0) + orderRevenue);

            // Aggiorna mappa giornaliera (YYYY-MM-DD)
            const dayKey = this.formatDateForKey(orderDate, 'day');
            dailyMap.set(dayKey, (dailyMap.get(dayKey) || 0) + orderRevenue);

            // Aggiorna mappa mensile (YYYY-MM)
            const monthKey = this.formatDateForKey(orderDate, 'month');
            monthlyMap.set(monthKey, (monthlyMap.get(monthKey) || 0) + orderRevenue);
        });

        return { hourly: hourlyMap, daily: dailyMap, monthly: monthlyMap };
    }

    private initializeTimeMaps(
        hourlyMap: Map<number, number>,
        dailyMap: Map<string, number>,
        monthlyMap: Map<string, number>
    ): void {
        const startDate = new Date(this.filters.dateRange.start);
        const endDate = new Date(this.filters.dateRange.end);

        // Inizializza mappa oraria (per 'oggi' o 'ieri')
        if (this.activePreset === 'today' || this.activePreset === 'yesterday') {
            for (let hour = 0; hour < 24; hour++) {
                hourlyMap.set(hour, 0);
            }
        }

        // Inizializza mappa giornaliera
        let currentDay = new Date(startDate);
        while (currentDay <= endDate) {
            const dayKey = this.formatDateForKey(currentDay, 'day');
            dailyMap.set(dayKey, 0);
            currentDay.setDate(currentDay.getDate() + 1);
        }

        // Inizializza mappa mensile
        let currentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

        while (currentMonth <= endMonth) {
            const monthKey = this.formatDateForKey(currentMonth, 'month');
            monthlyMap.set(monthKey, 0);
            currentMonth.setMonth(currentMonth.getMonth() + 1);
        }
    }

    private formatDateForKey(date: Date, scale: 'day' | 'month'): string {
        if (scale === 'day') {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        } else {
            return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        }
    }

    private calculateTopProducts(orders: any[]): { topProducts: TopProduct[], totalRevenue: number } {
        const productMetrics: { [key: string]: TopProduct } = {};
        let totalRevenue = 0;

        // Calcola le metriche per ogni prodotto
        orders.forEach(order => {
            if (!order.carrello) return;

            Object.values(order.carrello).forEach((item: any) => {
                if (!productMetrics[item.title]) {
                    productMetrics[item.title] = {
                        title: item.title,
                        image_link: item.image_link || '',
                        quantity: 0,
                        revenue: 0,
                        percentage: 0
                    };
                }

                const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');

                productMetrics[item.title].quantity += quantity;
                productMetrics[item.title].revenue += price * quantity;
                totalRevenue += price * quantity;
            });
        });

        // Converti in array e ordina per fatturato
        const topProducts = Object.values(productMetrics)
            .sort((a, b) => b.revenue - a.revenue)
            .slice(0, 3);

        // Calcola le percentuali sul fatturato totale
        topProducts.forEach(product => {
            product.percentage = totalRevenue > 0 ? (product.revenue / totalRevenue) * 100 : 0;
        });

        return { topProducts, totalRevenue };
    }

    private calculateOrdersBySource(orders: any[]): { app: number; kiosk: number; pos: number } {
        const ordersBySource = {
            app: 0,
            kiosk: 0,
            pos: 0
        };

        orders.forEach(order => {
            const source = order.orderSource || 'pos';
            if (ordersBySource.hasOwnProperty(source)) {
                ordersBySource[source]++;
            }
        });

        return ordersBySource;
    }

    private calculateOrdersByType(orders: any[]): { table: number; takeaway: number; counter: number } {
        const ordersByType = {
            table: 0,
            takeaway: 0,
            counter: 0
        };

        orders.forEach(order => {
            const type = order.selectedPriceListType || 'counter';
            if (ordersByType.hasOwnProperty(type)) {
                ordersByType[type]++;
            }
        });

        return ordersByType;
    }

    private calculateOrderRevenue(order: any): number {
        let orderRevenue = 0;

        if (order.carrello) {
            Object.values(order.carrello).forEach((item: any) => {
                const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
                orderRevenue += price * quantity;
            });
        }

        return orderRevenue;
    }

    private generateChartLabels(): void {
        this.chartLabels = [];

        const startDate = new Date(this.filters.dateRange.start);
        const endDate = new Date(this.filters.dateRange.end);

        // Determina la scala temporale in base al periodo selezionato
        if (this.activePreset === 'today' || this.activePreset === 'yesterday') {
            this.timeScale = 'hour';

            // Etichette orarie (0-23)
            for (let hour = 0; hour < 24; hour++) {
                this.chartLabels.push(`${hour}:00`);
            }
        }
        else if (this.activePreset === 'lastWeek' || this.activePreset === 'lastMonth' ||
            (this.activePreset === null && this.getDaysDifference(startDate, endDate) <= 31)) {
            this.timeScale = 'day';

            // Etichette giornaliere
            let currentDay = new Date(startDate);
            while (currentDay <= endDate) {
                this.chartLabels.push(`${currentDay.getDate()}/${currentDay.getMonth() + 1}`);
                currentDay.setDate(currentDay.getDate() + 1);
            }
        }
        else {
            this.timeScale = 'month';

            // Etichette mensili
            const months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
            let currentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
            const endMonth = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

            while (currentMonth <= endMonth) {
                const monthName = months[currentMonth.getMonth()];
                const label = `${monthName} ${currentMonth.getFullYear()}`;
                this.chartLabels.push(label);
                currentMonth.setMonth(currentMonth.getMonth() + 1);
            }
        }
    }

    private getDaysDifference(startDate: Date, endDate: Date): number {
        return Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    }

    private initComparisonChart(): void {
        if (!this.revenueChartCanvas || this.restaurantStats.length === 0) return;

        const ctx = this.revenueChartCanvas.nativeElement.getContext('2d');

        // Prepara i dati per il grafico in base alla modalità di confronto
        let datasets = [];

        if (this.compareMode === 'revenue' || this.compareMode === 'growth') {
            // Grafico fatturato per ristorante in base alla scala temporale
            datasets = this.restaurantStats.map((restaurant, index) => {
                const colorIndex = index % this.chartColors.length;

                // Estrai i dati nell'ordine corretto
                const chartData = this.extractChartData(restaurant.id);

                return {
                    label: restaurant.name,
                    data: chartData,
                    borderColor: this.chartColors[colorIndex],
                    backgroundColor: this.getGradient(ctx, this.chartColors[colorIndex]),
                    tension: 0.4,
                    borderWidth: 2,
                    pointRadius: this.timeScale === 'month' ? 0 : 3, // Mostra punti per ore e giorni
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#FFFFFF',
                    pointHoverBorderColor: this.chartColors[colorIndex],
                    pointHoverBorderWidth: 2,
                    fill: true
                };
            });
        } else if (this.compareMode === 'sources') {
            // Grafico ordini per fonte
            const sourceData = {
                app: new Array(this.chartLabels.length).fill(0),
                kiosk: new Array(this.chartLabels.length).fill(0),
                pos: new Array(this.chartLabels.length).fill(0)
            };

            // Somma i valori di tutti i ristoranti
            this.restaurantStats.forEach(restaurant => {
                if (restaurant.ordersBySource) {
                    sourceData.app[this.chartLabels.length - 1] += restaurant.ordersBySource.app;
                    sourceData.kiosk[this.chartLabels.length - 1] += restaurant.ordersBySource.kiosk;
                    sourceData.pos[this.chartLabels.length - 1] += restaurant.ordersBySource.pos;
                }
            });

            datasets = [
                {
                    label: 'App',
                    data: sourceData.app,
                    borderColor: '#34bfa3',
                    backgroundColor: this.getGradient(ctx, '#34bfa3'),
                    tension: 0.4,
                    fill: true,
                    borderWidth: 2
                },
                {
                    label: 'Kiosk',
                    data: sourceData.kiosk,
                    borderColor: '#4F46E5',
                    backgroundColor: this.getGradient(ctx, '#4F46E5'),
                    tension: 0.4,
                    fill: true,
                    borderWidth: 2
                },
                {
                    label: 'Cassa',
                    data: sourceData.pos,
                    borderColor: '#F59E0B',
                    backgroundColor: this.getGradient(ctx, '#F59E0B'),
                    tension: 0.4,
                    fill: true,
                    borderWidth: 2
                }
            ];
        } else if (this.compareMode === 'products') {
            // Trova i prodotti più venduti tra tutti i ristoranti
            const allProducts = {};

            this.restaurantStats.forEach(restaurant => {
                restaurant.topProducts.forEach(product => {
                    if (!allProducts[product.title]) {
                        allProducts[product.title] = {
                            title: product.title,
                            revenue: 0
                        };
                    }
                    allProducts[product.title].revenue += product.revenue;
                });
            });

            // Seleziona i top 5 prodotti
            const topProducts = Object.values(allProducts)
                .sort((a: any, b: any) => b.revenue - a.revenue)
                .slice(0, 5);

            datasets = topProducts.map((product: any, index) => {
                const colorIndex = index % this.chartColors.length;

                return {
                    label: product.title,
                    data: [product.revenue],
                    backgroundColor: this.chartColors[colorIndex],
                    borderColor: this.chartColors[colorIndex],
                    borderWidth: 1
                };
            });
        }

        const config: ChartConfiguration = {
            type: this.compareMode === 'products' ? 'bar' : 'line',
            data: {
                labels: this.chartLabels,
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            // Rotazione se ci sono molte etichette
                            maxRotation: this.timeScale === 'day' ? 45 : 0,
                            minRotation: this.timeScale === 'day' ? 45 : 0
                        }
                    },
                    y: {
                        grid: {
                            borderDash: [8, 4],
                            color: 'rgba(148, 163, 184, 0.1)'
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            callback: (value) => '€' + value.toLocaleString()
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'top',
                        labels: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            padding: 16
                        }
                    },
                    tooltip: {
                        backgroundColor: '#FFFFFF',
                        titleColor: '#1E293B',
                        bodyColor: '#1E293B',
                        borderColor: '#E2E8F0',
                        borderWidth: 1,
                        padding: 12,
                        displayColors: true,
                        callbacks: {
                            label: (context) => context.dataset.label + ': €' + context.raw.toLocaleString()
                        }
                    }
                }
            }
        };

        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart(ctx, config);
    }

    // Estrai i dati nell'ordine corretto per il grafico in base alla scala temporale
    private extractChartData(restaurantId: string): number[] {
        const chartData: number[] = [];

        switch (this.timeScale) {
            case 'hour':
                const hourlyMap = this.hourlyData.get(restaurantId) || new Map<number, number>();
                for (let hour = 0; hour < 24; hour++) {
                    chartData.push(hourlyMap.get(hour) || 0);
                }
                break;

            case 'day':
                const dailyMap = this.dailyData.get(restaurantId) || new Map<string, number>();
                const startDate = new Date(this.filters.dateRange.start);
                const endDate = new Date(this.filters.dateRange.end);

                let currentDay = new Date(startDate);
                while (currentDay <= endDate) {
                    const dayKey = this.formatDateForKey(currentDay, 'day');
                    chartData.push(dailyMap.get(dayKey) || 0);
                    currentDay.setDate(currentDay.getDate() + 1);
                }
                break;

            case 'month':
                const monthlyMap = this.monthlyData.get(restaurantId) || new Map<string, number>();
                const startMonth = new Date(this.filters.dateRange.start.getFullYear(), this.filters.dateRange.start.getMonth(), 1);
                const endMonth = new Date(this.filters.dateRange.end.getFullYear(), this.filters.dateRange.end.getMonth(), 1);

                let currentMonth = new Date(startMonth);
                while (currentMonth <= endMonth) {
                    const monthKey = this.formatDateForKey(currentMonth, 'month');
                    chartData.push(monthlyMap.get(monthKey) || 0);
                    currentMonth.setMonth(currentMonth.getMonth() + 1);
                }
                break;
        }

        return chartData;
    }

    private getGradient(ctx: CanvasRenderingContext2D, color: string): CanvasGradient {
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        // Create RGBA versions with transparency
        const rgbaColor = this.hexToRGBA(color, 0.4);
        const rgbaColorTransparent = this.hexToRGBA(color, 0.1);

        gradient.addColorStop(0, rgbaColor);
        gradient.addColorStop(1, rgbaColorTransparent);

        return gradient;
    }

    private hexToRGBA(hex: string, alpha: number): string {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    toggleCompareMode(mode: 'revenue' | 'growth' | 'products' | 'sources'): void {
        this.compareMode = mode;

        if (this.chart) {
            this.chart.destroy();
        }

        setTimeout(() => {
            this.initComparisonChart();
        }, 0);
    }

    changeSortBy(sortBy: 'name' | 'revenue' | 'growth'): void {
        if (this.sortBy === sortBy) {
            // Toggle direction if same sort field
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortBy = sortBy;
            // Default to descending for revenue and growth, ascending for name
            this.sortDirection = sortBy === 'name' ? 'asc' : 'desc';
        }

        this.sortRestaurants();
    }

    private sortRestaurants(): void {
        this.restaurantStats.sort((a, b) => {
            let comparison = 0;

            switch (this.sortBy) {
                case 'name':
                    comparison = a.name.localeCompare(b.name);
                    break;
                case 'revenue':
                    comparison = b.totalRevenue - a.totalRevenue;
                    break;
                case 'growth':
                    comparison = b.monthlyStats.percentageChange - a.monthlyStats.percentageChange;
                    break;
            }

            return this.sortDirection === 'asc' ? comparison : -comparison;
        });
    }

    navigateToRestaurantDetails(restaurantId: string): void {
        // Set the current restaurant
        const restaurant = this.restaurants.find(r => r.id === restaurantId);
        if (restaurant) {
            this.dataService.setCurrentRestaurant(restaurant).then(() => {
                this.router.navigate(['/cassa']);
            });
        }
    }

    // Metodi per i filtri
    setDatePreset(preset: string): void {
        const today = new Date();
        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);

        switch (preset) {
            case 'today':
                this.filters.dateRange.start = new Date(today.setHours(0, 0, 0, 0));
                this.filters.dateRange.end = endOfDay;
                break;

            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                const yesterdayEnd = new Date(yesterday);
                yesterdayEnd.setHours(23, 59, 59, 999);

                this.filters.dateRange.start = yesterday;
                this.filters.dateRange.end = yesterdayEnd;
                break;

            case 'lastWeek':
                const lastWeekStart = new Date(today);
                lastWeekStart.setDate(today.getDate() - 7);
                lastWeekStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastWeekStart;
                this.filters.dateRange.end = endOfDay;
                break;

            case 'lastMonth':
                const lastMonthStart = new Date(today);
                lastMonthStart.setMonth(today.getMonth() - 1);
                lastMonthStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastMonthStart;
                this.filters.dateRange.end = endOfDay;
                break;

            case 'lastYear':
                const lastYearStart = new Date(today);
                lastYearStart.setFullYear(today.getFullYear() - 1);
                lastYearStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastYearStart;
                this.filters.dateRange.end = endOfDay;
                break;
        }

        this.activePreset = preset;
        this.showCustomDateRange = false;
        this.loadRestaurants();
    }

    openDateRangePicker(): void {
        this.showCustomDateRange = true;
        this.activePreset = null;

        // Aggiorna il form con i valori correnti
        this.dateRange.setValue({
            start: this.filters.dateRange.start,
            end: this.filters.dateRange.end
        });
    }

    updateDateRange(start: Date, end: Date): void {
        this.filters.dateRange = { start, end };
        this.loadRestaurants();
    }

    onTimeRangeChange(value: string): void {
        switch(value) {
            case 'morning':
                this.filters.timeRange = { start: '06:00', end: '12:00' };
                break;
            case 'lunch':
                this.filters.timeRange = { start: '12:00', end: '15:00' };
                break;
            case 'afternoon':
                this.filters.timeRange = { start: '15:00', end: '19:00' };
                break;
            case 'dinner':
                this.filters.timeRange = { start: '19:00', end: '23:00' };
                break;
            case 'all':
                this.filters.timeRange = { start: '00:00', end: '23:59' };
                break;
        }
        this.loadRestaurants();
    }

    toggleOrderType(type: string): void {
        const index = this.filters.orderTypes.indexOf(type);
        if (index === -1) {
            this.filters.orderTypes.push(type);
        } else {
            this.filters.orderTypes.splice(index, 1);
        }
        this.loadRestaurants();
    }

    toggleOrderSource(source: string): void {
        const index = this.filters.orderSources.indexOf(source);
        if (index === -1) {
            this.filters.orderSources.push(source);
        } else {
            this.filters.orderSources.splice(index, 1);
        }
        this.loadRestaurants();
    }

    isActivePreset(preset: string): boolean {
        return this.activePreset === preset;
    }

    search(term: string): void {
        this.filters.searchTerm = term;
        this.loadRestaurants();
    }

    exportToCSV(): void {
        const headers = [
            'Ristorante',
            'Fatturato Totale',
            'Fatturato Mese Corrente',
            'Proiezione Mensile',
            'Variazione',
            'Ordini App',
            'Ordini Kiosk',
            'Ordini Cassa',
            'Prodotto Principale',
            'Fatturato Prodotto Principale'
        ];

        const csvData = this.restaurantStats.map(restaurant => {
            const topProduct = restaurant.topProducts.length > 0 ? restaurant.topProducts[0] : null;

            return [
                `"${restaurant.name}"`,
                restaurant.totalRevenue,
                restaurant.monthlyStats.currentMonth,
                restaurant.monthlyStats.projected,
                `${restaurant.monthlyStats.percentageChange.toFixed(2)}%`,
                restaurant.ordersBySource?.app || 0,
                restaurant.ordersBySource?.kiosk || 0,
                restaurant.ordersBySource?.pos || 0,
                topProduct ? `"${topProduct.title}"` : 'N/D',
                topProduct ? topProduct.revenue : 0
            ];
        });

        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'statistiche_ristoranti.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);
    }
}
