import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 *
 * card per lo stato dell'ordine nella home
 */
@Component({
  selector: 'receiptOrderCard1',
  styles: [`

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
      overflow: visible;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }




    .block {
      width: 100%;
      height: 65px;
      margin: 0px;
      display: flex;
      background: white;
      font-size: 16px;
      font-weight: bold;
      color: black;

      border: solid 1px #e3e2e2;
      border-radius: 20px;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */


    }







  `],
  template: `

    <div class="block" >
      <div  style="display: grid; place-items: center; text-align: left; width: 50%; ">
        <div style="width: 100%; text-align: left; padding-left: 20px">{{this.titolo}}</div>

      </div>
      <div style="display: flex; width: 50%; align-items: center; justify-content: flex-end;">
        <div style=" display: grid; place-items: center; ">
          <tagComponent [fonte]="this.fonte"></tagComponent>
        </div>
        <div style=" display: grid; place-items: center; ">
          <div style="width: 100%; display: grid; place-items: end; padding-right: 20px">
            <div *ngIf="this.stato == 'Pagato'"
                 style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px;  color:rgba(14,184,14,0.7); background-color: rgba(0,255,0, 0.3); display: grid; place-items: center; font-size: 11px">
              Pagato
            </div>
            <div *ngIf="this.stato == 'Non Pagato'"
                 style="padding-right: 5px; padding-left: 5px; height: 32px; border-radius: 8px;  color:rgba(255,0,0, 0.7); background-color: rgba(255,0,0, 0.5); display: grid; place-items: center; font-size: 11px">
              Non Pagato
            </div>
          </div>
        </div>
      </div>

    </div>

  `
})
export class cardDettaglioScontrino {

  @Input() titolo ;
  @Input() stato ;
  @Input() fonte ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  /**
   * Simulate logout
   */


}
