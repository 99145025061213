import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotificationService} from "../stampanti/notificationService/notification.service";
import {HttpClient} from "@angular/common/http";

import firebase from 'firebase/app';
import 'firebase/firestore';

@Component({
    selector: 'notifiche',
    templateUrl: './notifiche.component.html',
    styleUrls: ['./notifiche.component.css']
})
export class NotificheComponent {
    notificheForm: FormGroup;
    isLoading = false;

    private backendUrl = 'http://localhost:9000/sendNotification'; // Cambia questo in produzione

    constructor(
        private fb: FormBuilder,
        private notificationService: NotificationService,
        private http: HttpClient
    ) {
        this.initForm();
    }

    private initForm() {
        this.notificheForm = this.fb.group({
            userId: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    async inviaNotifica() {
        if (this.notificheForm.invalid) {
            return;
        }

        this.isLoading = true;
        try {
            const db = firebase.firestore();
            const userDoc = await db.collection('users').doc(this.notificheForm.value.userId).get();
            const userData = userDoc.data();

            if (!userData?.fcmToken) {
                throw new Error('Token FCM non trovato per questo utente');
            }

            const notificationData = {
                data: {
                    token: userData.fcmToken,
                    title: "Nuova notifica",
                    body: this.notificheForm.value.message
                }
            };

            await this.http.post(this.backendUrl, notificationData).toPromise();

            this.notificationService.show('Notifica inviata con successo', 'success');
            this.notificheForm.reset();
        } catch (error) {
            console.error('Errore invio notifica:', error);
            this.notificationService.show('Errore durante l\'invio della notifica', 'error');
        } finally {
            this.isLoading = false;
        }
    }
}