import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges, OnDestroy,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../router.module';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../menu/menu";
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CarrelloMagazzino} from "../carrelloMagazzino/carrelloMagazzino";



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.css'],
})
  export class Dashboard implements OnInit, OnDestroy{


  notifiche = [
    { data: "2024-10-30", testoNotifica: "Sono appena arrivati nuovi ingredienti freschi nel nostro magazzino! Scopri subito le novità.", tipologiaNotifica: "Nuovi Prodotti" },
    { data: "2024-10-29", testoNotifica: "Promozione speciale: ordina carne biologica con il 10% di sconto per i prossimi 3 giorni.", tipologiaNotifica: "Prodotti in Offerta" },
    { data: "2024-10-28", testoNotifica: "Disponibili ora: nuove varietà di formaggi artigianali per arricchire il tuo menu.", tipologiaNotifica: "Nuovi Prodotti" },
    { data: "2024-10-27", testoNotifica: "Previsione di alta domanda per il weekend: suggeriamo di aumentare l'ordine di farine per pizza.", tipologiaNotifica: "Consigli sugli Cronologia" },
    { data: "2024-10-26", testoNotifica: "Aggiornamento di magazzino: frutti di mare freschi sono ora disponibili. Ordina prima che finiscano!", tipologiaNotifica: "Nuovi Prodotti" },
    { data: "2024-10-25", testoNotifica: "Sconto esclusivo su prodotti da forno artigianali per il tuo ristorante: affrettati, quantità limitate!", tipologiaNotifica: "Prodotti in Offerta" },
    { data: "2024-10-24", testoNotifica: "Basandoci sui tuoi ultimi ordini, potrebbe essere il momento di rifornirti di olio extravergine di oliva.", tipologiaNotifica: "Consigli sugli Cronologia" },
    { data: "2024-10-23", testoNotifica: "Ingredienti di stagione in offerta: verdure fresche con uno sconto del 15% fino a fine settimana.", tipologiaNotifica: "Prodotti in Offerta" },
    { data: "2024-10-22", testoNotifica: "Disponibili solo per oggi: tartufi freschi! Approfittane per sorprendere i tuoi clienti.", tipologiaNotifica: "Prodotti Esclusivi" },
    { data: "2024-10-21", testoNotifica: "Considera l'acquisto di frutta fresca extra per soddisfare l'aumento delle richieste di dessert estivi.", tipologiaNotifica: "Consigli sugli Cronologia" },
    { data: "2024-10-20", testoNotifica: "Nota: stai per esaurire le scorte di riso. Suggeriamo di effettuare un nuovo ordine per evitare mancanze.", tipologiaNotifica: "Rifornimenti Necessari" },
    { data: "2024-10-19", testoNotifica: "Offerta limitata: spezie rare per dare un tocco unico ai tuoi piatti. Ordinale subito!", tipologiaNotifica: "Prodotti Esclusivi" },
    { data: "2024-10-18", testoNotifica: "Scorte di vino rosso basse. È consigliabile effettuare un rifornimento per mantenere il servizio impeccabile.", tipologiaNotifica: "Rifornimenti Necessari" },
    { data: "2024-10-17", testoNotifica: "I nuovi arrivi di pesce potrebbero essere perfetti per un nuovo piatto speciale del weekend!", tipologiaNotifica: "Suggerimenti per il Menu" },
    { data: "2024-10-16", testoNotifica: "Abbiamo ingredienti freschi per creare un'insalata di stagione. Porta freschezza nel tuo menu oggi stesso.", tipologiaNotifica: "Suggerimenti per il Menu" }
  ];



  sezioneNovita = 0;
  lista = 0;

  dataSourceDipendenti2= new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  displayedColumnsDipendenti2: string[] = ['nome', 'email', 'Action', 'Elimina'];


  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };


  display = 'none';

  closeResult = '';



  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;

  filtroSource = 3;
  filtroStato = 2;

  filter = '';


  openDialogRiepilogo(idOrdine): any {
    const dialogRef = this.dialog.open(CarrelloMagazzino, {
      data: { orderId: idOrdine }, // Passa i dati della riga al dialog
      width: '80vw',
      maxWidth: '80vw'
    });
    //modalRef.componentInstance.user = this.user;

  }

  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    public commonService: CommonService, public dataService: DataServiceGenerico,     private cdRef: ChangeDetectorRef // Inietta ChangeDetectorRef qui
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }

  ordiniMagazzino: any[] = [];

// Funzione per recuperare la lista degli ordini
  fetchOrdini(): void {
    const db = firebase.firestore();
    const ordiniRef = db.collection('ordini_magazzino_test');

    console.log("ordini recuperati ristorante: ", this.dataService.getRistorante1())

    if(this.dataService.getRistorante1() == 'demo_1')
    {
      this.dataService.loadSelectedMenuFromFirebase().then(
          (menu) => {
            this.unsubscribeOrdini = ordiniRef
                .where('ristorante', '==', this.dataService.getRistorante1())
                .onSnapshot(
                    (querySnapshot) => {
                      this.ordiniMagazzino = [];
                      querySnapshot.forEach((doc) => {
                        // Include anche l'ID del documento nei dati
                        const ordine = {
                          id: doc.id,
                          ...doc.data()
                        };
                        this.ordiniMagazzino.push(ordine);
                      });
                      console.log('Cronologia recuperati:', this.ordiniMagazzino);

                      // Trigger change detection se necessario
                      if (this.cdRef) {
                        this.cdRef.detectChanges();
                      }
                    },
                    (error) => {
                      console.error('Errore nel monitoraggio degli ordini:', error);
                    }
                );
          }
      )
    }
    else
    {
      this.unsubscribeOrdini = ordiniRef
          .where('ristorante', '==', this.dataService.getRistorante1())
          .onSnapshot(
              (querySnapshot) => {
                this.ordiniMagazzino = [];
                querySnapshot.forEach((doc) => {
                  // Include anche l'ID del documento nei dati
                  const ordine = {
                    id: doc.id,
                    ...doc.data()
                  };
                  this.ordiniMagazzino.push(ordine);
                });
                console.log('Cronologia recuperati:', this.ordiniMagazzino);

                // Trigger change detection se necessario
                if (this.cdRef) {
                  this.cdRef.detectChanges();
                }
              },
              (error) => {
                console.error('Errore nel monitoraggio degli ordini:', error);
              }
          );
    }
    // Creare uno snapshot listener per gli ordini

  }

// Aggiungere questo metodo per fare cleanup quando il componente viene distrutto
  ngOnDestroy(): void {
    // Cleanup per gli ordini
    if (this.unsubscribeOrdini) {
      this.unsubscribeOrdini();
    }
  }

// Aggiungi questa proprietà nella tua classe
  private unsubscribeOrdini: (() => void) | undefined;
// Funzione per calcolare il totale di un ordine specifico
  calculateOrderTotal(idOrdine: string): number {
    const ordine = this.ordiniMagazzino.find(o => o.idOrdine === idOrdine);
    if (!ordine) {
      console.error('Ordine non trovato');
      return 0;
    }

    return ordine.carrelloMagazzino.reduce((total, item) => {
      const price = parseFloat(item.Prezzo) || 0;
      return total + price;
    }, 0);
  }



  openDialog(): any {
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  ngOnInit(): void {

    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
        this.retriveCarrello();
      }
    });


  }

  ordini = [];

  receipts = [];

  idRistorante = '';

  camerieri = [];



  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          this.user = data.data();

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato2: ', data.data());

          this.carrelloTmp = data.data().carrello;
          this.receipts = data.data().scontrini;

          console.log(this.carrelloTmp);

          this.calcolaTotale();
        }
      }
    );

    this.fetchOrdini();

  }



  filtraStatoTutto()
  {
    this.filtroStato = 2;
    this.retriveCarrello()

    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
  }

  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }






}
