import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/app';

// Interfaccia per i contatti del fornitore
interface ContattoFornitore {
    nome: string;
    ruolo: string;
    telefono: string;
    email: string;
}

// Interfaccia per i prodotti associati
interface ProdottoAssociato {
    prodottoId: string;
    prezzoAcquisto: number;
    tempoDiConsegna: number; // in giorni
    minOrdine: number;
    note: string;
}

@Component({
    selector: 'fornitore-detail',
    templateUrl: './menu-fornitori-detail.html',
    styleUrls: ['./menu-fornitori-detail.css']
})
export class FornitoreDetailComponent implements OnChanges {
    @Input() fornitore: any;
    @Output() fornitoreUpdated = new EventEmitter<any>();
    @Output() fornitoreDeleted = new EventEmitter<void>();

    // Campi principali
    nome: string = '';
    partitaIva: string = '';
    indirizzo: string = '';
    cap: string = '';
    citta: string = '';
    provincia: string = '';
    nazione: string = 'Italia';
    telefono: string = '';
    email: string = '';
    sitoWeb: string = '';
    note: string = '';

    // Gestione contatti
    contatti: ContattoFornitore[] = [];
    nuovoContatto: ContattoFornitore = {
        nome: '',
        ruolo: '',
        telefono: '',
        email: ''
    };

    // Prodotti associati
    prodottiAssociati: ProdottoAssociato[] = [];
    prodottiDisponibili: any[] = [];
    nuovoProdotto: ProdottoAssociato = {
        prodottoId: '',
        prezzoAcquisto: 0,
        tempoDiConsegna: 7,
        minOrdine: 1,
        note: ''
    };

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService,
        private snackBar: MatSnackBar
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fornitore && changes.fornitore.currentValue) {
            this.initializeFormData(changes.fornitore.currentValue);
            this.loadProdotti();
        }
    }

    private initializeFormData(fornitoreData: any) {
        // Inizializza i campi del form con i dati del fornitore
        this.nome = fornitoreData.nome || '';
        this.partitaIva = fornitoreData.partitaIva || '';
        this.indirizzo = fornitoreData.indirizzo || '';
        this.cap = fornitoreData.cap || '';
        this.citta = fornitoreData.citta || '';
        this.provincia = fornitoreData.provincia || '';
        this.nazione = fornitoreData.nazione || 'Italia';
        this.telefono = fornitoreData.telefono || '';
        this.email = fornitoreData.email || '';
        this.sitoWeb = fornitoreData.sitoWeb || '';
        this.note = fornitoreData.note || '';

        // Inizializza contatti
        this.contatti = fornitoreData.contatti || [];

        // Inizializza prodotti associati
        this.prodottiAssociati = fornitoreData.prodottiAssociati || [];
    }

    private loadProdotti() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    this.prodottiDisponibili = data?.items || [];
                }
            })
            .catch(error => {
                console.error('Errore nel caricamento dei prodotti:', error);
                this.snackBar.open('Errore nel caricamento dei prodotti', 'Chiudi', { duration: 3000 });
            });
    }

    // Metodi per gestire i contatti
    addContatto() {
        if (this.validateContatto()) {
            this.contatti.push({...this.nuovoContatto});
            this.resetNuovoContatto();
        }
    }

    validateContatto(): boolean {
        if (!this.nuovoContatto.nome) {
            this.snackBar.open('Inserisci il nome del contatto', 'Chiudi', { duration: 3000 });
            return false;
        }
        return true;
    }

    resetNuovoContatto() {
        this.nuovoContatto = {
            nome: '',
            ruolo: '',
            telefono: '',
            email: ''
        };
    }

    removeContatto(index: number) {
        this.contatti.splice(index, 1);
    }

    // Metodi per gestire i prodotti associati
    addProdottoAssociato() {
        if (this.validateProdottoAssociato()) {
            this.prodottiAssociati.push({...this.nuovoProdotto});
            this.resetNuovoProdotto();
        }
    }

    validateProdottoAssociato(): boolean {
        if (!this.nuovoProdotto.prodottoId) {
            this.snackBar.open('Seleziona un prodotto', 'Chiudi', { duration: 3000 });
            return false;
        }
        if (this.nuovoProdotto.prezzoAcquisto <= 0) {
            this.snackBar.open('Inserisci un prezzo di acquisto valido', 'Chiudi', { duration: 3000 });
            return false;
        }
        // Verifica se il prodotto è già associato
        if (this.prodottiAssociati.some(p => p.prodottoId === this.nuovoProdotto.prodottoId)) {
            this.snackBar.open('Questo prodotto è già associato al fornitore', 'Chiudi', { duration: 3000 });
            return false;
        }
        return true;
    }

    resetNuovoProdotto() {
        this.nuovoProdotto = {
            prodottoId: '',
            prezzoAcquisto: 0,
            tempoDiConsegna: 7,
            minOrdine: 1,
            note: ''
        };
    }

    removeProdottoAssociato(index: number) {
        this.prodottiAssociati.splice(index, 1);
    }

    // Ottiene il nome del prodotto dal suo ID
    getProdottoNome(prodottoId: string): string {
        const prodotto = this.prodottiDisponibili.find(p => p.id === prodottoId);
        return prodotto ? prodotto.title : 'Prodotto non trovato';
    }

    saveChanges() {
        if (!this.nome) {
            this.snackBar.open('Il nome del fornitore è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const updatedFornitore = {
            id: this.fornitore?.id || firebase.firestore().collection('temporary').doc().id,
            nome: this.nome,
            partitaIva: this.partitaIva,
            indirizzo: this.indirizzo,
            cap: this.cap,
            citta: this.citta,
            provincia: this.provincia,
            nazione: this.nazione,
            telefono: this.telefono,
            email: this.email,
            sitoWeb: this.sitoWeb,
            note: this.note,
            contatti: this.contatti,
            prodottiAssociati: this.prodottiAssociati
        };

        const restaurantName = this.dataService.getRistorante();
        const fornitoriRef = firebase.firestore()
            .collection('fornitori')
            .doc(restaurantName);

        fornitoriRef.get().then(doc => {
            let updatedFornitori: any[];

            if (doc.exists) {
                const fornitori = doc.data()?.fornitori || [];

                if (this.fornitore?.id) {
                    // Aggiorna un fornitore esistente
                    updatedFornitori = fornitori.map(item =>
                        item.id === this.fornitore.id ? updatedFornitore : item
                    );
                } else {
                    // Aggiunge un nuovo fornitore
                    updatedFornitori = [...fornitori, updatedFornitore];
                }
            } else {
                // Crea un nuovo documento con il primo fornitore
                updatedFornitori = [updatedFornitore];
            }

            return fornitoriRef.set({
                fornitori: updatedFornitori,
                ristorante: restaurantName
            })
                .then(() => {
                    this.snackBar.open('Modifiche salvate con successo', 'Chiudi', { duration: 2000 });
                    this.fornitoreUpdated.emit(updatedFornitore);
                })
                .catch(error => {
                    console.error('Errore nel salvataggio delle modifiche:', error);
                    this.snackBar.open('Errore nel salvataggio delle modifiche', 'Chiudi', { duration: 3000 });
                });
        }).catch(error => {
            console.error('Errore nel recupero del documento:', error);
            this.snackBar.open('Errore nel recupero del documento', 'Chiudi', { duration: 3000 });
        });
    }

    deleteFornitore() {
        if (!this.fornitore?.id) {
            // Annulla la creazione di un nuovo fornitore
            this.fornitoreDeleted.emit();
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const fornitoriRef = firebase.firestore()
            .collection('fornitori')
            .doc(restaurantName);

        fornitoriRef.get().then(doc => {
            if (doc.exists) {
                const fornitori = doc.data()?.fornitori || [];
                const updatedFornitori = fornitori.filter(item => item.id !== this.fornitore.id);

                fornitoriRef.update({ fornitori: updatedFornitori })
                    .then(() => {
                        this.snackBar.open('Fornitore eliminato con successo', 'Chiudi', { duration: 2000 });
                        this.fornitoreDeleted.emit();
                    })
                    .catch(error => {
                        console.error('Errore nell\'eliminazione del fornitore:', error);
                        this.snackBar.open('Errore nell\'eliminazione del fornitore', 'Chiudi', { duration: 3000 });
                    });
            }
        });
    }


    // Nel componente
    get prodottiDisponibiliNonAssociati() {
        return this.prodottiDisponibili.filter(prodotto =>
            !this.prodottiAssociati.some(item => item.prodottoId === prodotto.id)
        );
    }
}