import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Input, Output, ElementRef } from '@angular/core';
import firebase from 'firebase/app';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";

@Component({
  selector: 'edit-categories',
  templateUrl: './edit-categories.html',
  styleUrls: ['./edit-categories.css'],
})
export class EditCategories implements OnInit, OnDestroy {
  @Input() collectionType: 'menu_test' | 'magazzino_test';
  categories: string[] = [];
  originalCategories: string[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(
      private cdRef: ChangeDetectorRef,
      private elRef: ElementRef,
      private dataService: DataServiceGenerico,
  ) {}

  ngOnInit(): void {
    this.fetchCategoriesFromFirestore();
  }

  ngOnDestroy(): any {
    this.passBack();
  }

  fetchCategoriesFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    console.log('vediamo pd', this.collectionType);
    const collectionRef = db.collection(this.collectionType).doc(restaurantName); // Usa collectionType

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();

        if (this.collectionType === 'menu_test') {
          // Logica per menu_test
          items.forEach(item => {
            if (item.category) {
              categoriesSet.add(item.category);
            }
          });
        } else if (this.collectionType === 'magazzino_test') {
          // Logica per magazzino_test
          items.forEach(item => {
            if (item.Categoria) {
              categoriesSet.add(item.Categoria);
            }
          });
        }

        this.categories = Array.from(categoriesSet);
        this.originalCategories = [...this.categories]; // Salva le categorie originali per confrontarle
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }


  trackByFn(index: number, item: string): number {
    return index; // o return item; se l'elemento stesso è univoco
  }

  updateCategory(index: number, value: string): void {
    this.categories[index] = value;
  }

  addCategory(): void {
    this.categories.push('');  // Aggiunge un nuovo campo vuoto per la categoria
    this.cdRef.detectChanges();  // Rende effettiva la modifica nel DOM
    this.scrollToBottom();  // Scorre fino in fondo alla lista
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const lastCategoryElement = this.elRef.nativeElement.querySelector(`#category-${this.categories.length - 1}`);
      if (lastCategoryElement) {
        lastCategoryElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  saveCategories(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const collectionRef = db.collection(this.collectionType).doc(restaurantName); // Usa collectionType

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        // Mappatura delle categorie modificate
        const categoryMapping: { [key: string]: string } = {};

        // Trova tutte le categorie attuali
        let existingCategories;

        if (this.collectionType === 'menu_test') {
          existingCategories = items.map(item => item.category);
        } else if (this.collectionType === 'magazzino_test') {
          existingCategories = items.map(item => item.Categoria);
        }

        // Identifica le categorie da aggiornare
        this.originalCategories.forEach((originalCategory, index) => {
          const newCategory = this.categories[index];
          if (originalCategory !== newCategory) {
            categoryMapping[originalCategory] = newCategory;
          }
        });

        // Aggiorna gli elementi esistenti con le categorie modificate
        const updatedItems = items.map(item => {
          if (this.collectionType === 'menu_test' && categoryMapping[item.category]) {
            const updatedCategory = categoryMapping[item.category];
            existingCategories = existingCategories.filter(cat => cat !== item.category);
            existingCategories.push(updatedCategory);
            return {
              ...item,
              category: updatedCategory,
            };
          } else if (this.collectionType === 'magazzino_test' && categoryMapping[item.Categoria]) {
            const updatedCategory = categoryMapping[item.Categoria];
            existingCategories = existingCategories.filter(cat => cat !== item.Categoria);
            existingCategories.push(updatedCategory);
            return {
              ...item,
              Categoria: updatedCategory,
            };
          }
          return item;
        });

        // Identifica le nuove categorie che non esistono già e non sono solo rinominate
        this.categories.forEach((category) => {
          const isNewCategory = !existingCategories.includes(category);
          if (isNewCategory) {
            const newId = db.collection(this.collectionType).doc().id; // Usa collectionType per generare un ID univoco
            if (this.collectionType === 'menu_test') {
              const newItem = {
                id: newId,
                title: '',
                price: '',
                category: category,
                image_link: '',
                ingredients: [],
                allergens: [],
                menu: false,
                consigliato: false,
                disponibilita: false,
                prezziMenu: ['', '', ''],
              };
              updatedItems.push(newItem);
            } else if (this.collectionType === 'magazzino_test') {
              const newItem = {
                id: newId,
                Categoria: category,
                CodiceFornitore: '',
                Fornitore: '',
                Prezzo: '',
                Quantita: '',
                Scadenza: '',
                Titolo: '',
                UM: ''
              };
              updatedItems.push(newItem);
            }
            existingCategories.push(category);  // Aggiorna existingCategories con la nuova categoria
          }
        });

        // Aggiorna Firestore solo se ci sono cambiamenti
        if (JSON.stringify(items) !== JSON.stringify(updatedItems)) {
          collectionRef.update({ items: updatedItems })
            .then(() => {
              console.log('Categorie aggiornate con successo su Firestore!');
            })
            .catch((error) => {
              console.error('Errore durante l\'aggiornamento delle categorie su Firestore:', error);
            });
        }

      } else {
        const db = firebase.firestore();
        const restaurantName = this.dataService.getRistorante();
        db.collection(this.collectionType).doc(this.dataService.getRistorante()).set({
          items: []
        }) // Usa collectionType
        this.saveCategories();
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }


  deleteCategory(): void {
    // Implementa la logica per eliminare una categoria, se necessario
  }

  closeDialog() {
    this.passBack();
    //this.dialogRef.close();
  }

  passBack() {
    const dataToPassBack = {
      categories: this.categories
    };
    this.passEntry.emit(dataToPassBack);
  }
}
