import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import firebase from 'firebase/app';
import moment from 'moment';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";

interface OrderStats {
    totalOrders: number;
    totalRevenue: number;
    averageReceipt: number;
    percentageChanges: {
        orders: number;
        revenue: number;
        average: number;
    };
}

interface OrderFilters {
    dateRange: {
        start: Date;
        end: Date;
    };
    orderTypes: string[];
    products: string[];
    categories: string[];
    vatRates: string[];
    timeRange: {
        start: string;
        end: string;
    };
    searchTerm: string;
    showDeleted: boolean;
}

@Component({
    selector: 'app-receipts-dashboard',
    templateUrl: './receipts-dashboard.component.html',
    styleUrls: ['./receipts-dashboard.component.css']
})
export class ReceiptsDashboardComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    // Form Controls
    dateRange: FormGroup;
    timeRangeControl: FormControl;
    showCustomDateRange = false;
    activePreset: string = 'today';

    // Dati principali
    orders: any[] = [];
    filteredOrders: any[] = [];

    // Statistiche
    stats: OrderStats = {
        totalOrders: 0,
        totalRevenue: 0,
        averageReceipt: 0,
        percentageChanges: {
            orders: 0,
            revenue: 0,
            average: 0
        }
    };

    // Filtri
    filters: OrderFilters = {
        dateRange: {
            start: new Date(),
            end: new Date()
        },
        orderTypes: [],
        products: [],
        categories: [],
        vatRates: [],
        timeRange: {
            start: '00:00',
            end: '23:59'
        },
        searchTerm: '',
        showDeleted: false
    };

    // Filtri predefiniti per date
    datePresets = [
        { label: 'Oggi', value: 'today' },
        { label: 'Ieri', value: 'yesterday' },
        { label: 'Ultima Settimana', value: 'lastWeek' },
        { label: 'Ultimo Mese', value: 'lastMonth' }
    ];

    // Tipi di ordine
    orderTypeFilters = [
        { label: 'Tavolo', value: 'table', icon: 'table_restaurant' },
        { label: 'Asporto', value: 'takeaway', icon: 'delivery_dining' },
        { label: 'Banco', value: 'counter', icon: 'point_of_sale' }
    ];

    // Loading states
    loading = true;
    error = false;

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService
    ) {
        this.dateRange = new FormGroup({
            start: new FormControl(),
            end: new FormControl()
        });
        this.timeRangeControl = new FormControl('all');
    }

    ngOnInit(): void {
        this.initializeFilters();
        this.loadOrders();

        this.timeRangeControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.onTimeRangeChange(value));

        this.dateRange.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(range => {
                if (range.start && range.end) {
                    this.updateDateRange(range.start, range.end);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeFilters(): void {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        this.filters.dateRange = {
            start: today,
            end: new Date()
        };

        this.filters.orderTypes = this.orderTypeFilters.map(type => type.value);
    }

    async loadOrders(): Promise<void> {
        try {
            this.loading = true;
            const resid= await this.dataService.getCurrentRestaurantId();
            const restaurantName = await this.dataService.getRistorante();

            // Converte le date in timestamp per la query
            const startTimestamp = this.filters.dateRange.start.getTime();
            const endTimestamp = this.filters.dateRange.end.getTime();

            console.log('Query timestamps:', {
                start: startTimestamp,
                end: endTimestamp,
                startDate: new Date(startTimestamp),
                endDate: new Date(endTimestamp)
            });

            const ordersRef = firebase.firestore()
                .collection('ordini')
                .where('ristorante', '==', restaurantName)
                .where('data', '>=', startTimestamp.toString())
                .where('data', '<=', endTimestamp.toString());

            const snapshot = await ordersRef.get();
            this.orders = snapshot.docs.map(doc => {
                const data = doc.data();
                // Assicuriamoci che l'ordine abbia tutte le proprietà necessarie
                return {
                    id: doc.id,
                    ...data,
                    selectedPriceListType: data.selectedPriceListType || 'counter', // Default a counter se non specificato
                    data: data.data || Date.now().toString() // Assicuriamoci che ci sia sempre una data
                };
            });

            console.log('Ordini caricati:', this.orders);
            this.applyFilters();
            this.calculateStats();
            this.loading = false;
        } catch (error) {
            console.error('Error loading orders:', error);
            this.error = true;
            this.loading = false;
        }
    }


    applyFilters(): void {
        this.filteredOrders = this.orders.filter(order => {
            // Prima verifichiamo se l'ordine deve essere mostrato in base al suo stato di eliminazione
            if (this.filters.showDeleted) {
                if (!order.dataEliminazione) return false;
            } else {
                if (order.dataEliminazione) return false;
            }

            // Poi applichiamo il filtro per tipo ordine (banco, tavolo, asporto)
            if (this.filters.orderTypes.length &&
                !this.filters.orderTypes.includes(order.selectedPriceListType)) {
                return false;
            }

            // Filtro per orario
            const orderTime = moment(parseInt(order.data));
            const startTime = moment(this.filters.timeRange.start, 'HH:mm');
            const endTime = moment(this.filters.timeRange.end, 'HH:mm');

            startTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());
            endTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());

            if (!orderTime.isBetween(startTime, endTime, undefined, '[]')) {
                return false;
            }

            // Filtro di ricerca
            if (this.filters.searchTerm) {
                const searchTerm = this.filters.searchTerm.toLowerCase();
                const searchableFields = [
                    order.displayName,
                    order.motivazione,
                    ...Object.values(order.carrello || {}).map((item: any) => item.title)
                ].filter(Boolean);

                return searchableFields.some(field =>
                    field.toLowerCase().includes(searchTerm)
                );
            }

            return true;
        });

        this.calculateStats();
    }

    private calculateStats(): void {
        const currentStats = this.calculatePeriodStats(this.filteredOrders);
        const previousPeriodStart = new Date(this.filters.dateRange.start);
        const previousPeriodEnd = new Date(this.filters.dateRange.end);
        const periodDiff = previousPeriodEnd.getTime() - previousPeriodStart.getTime();

        previousPeriodStart.setTime(previousPeriodStart.getTime() - periodDiff);
        previousPeriodEnd.setTime(previousPeriodEnd.getTime() - periodDiff);

        const previousOrders = this.orders.filter(order => {
            const orderDate = new Date(parseInt(order.data));
            // Applica la stessa logica di filtro per ordini eliminati
            if (this.filters.showDeleted) {
                return order.dataEliminazione != null &&
                    orderDate >= previousPeriodStart &&
                    orderDate <= previousPeriodEnd;
            }
            return order.dataEliminazione == null &&
                orderDate >= previousPeriodStart &&
                orderDate <= previousPeriodEnd;
        });

        const previousStats = this.calculatePeriodStats(previousOrders);

        this.stats = {
            totalOrders: currentStats.totalOrders,
            totalRevenue: currentStats.totalRevenue,
            averageReceipt: currentStats.averageReceipt,
            percentageChanges: {
                orders: this.calculatePercentageChange(previousStats.totalOrders, currentStats.totalOrders),
                revenue: this.calculatePercentageChange(previousStats.totalRevenue, currentStats.totalRevenue),
                average: this.calculatePercentageChange(previousStats.averageReceipt, currentStats.averageReceipt)
            }
        };
    }

    private calculatePeriodStats(orders: any[]): Omit<OrderStats, 'percentageChanges'> {
        const totalOrders = orders.length;
        const totalRevenue = orders.reduce((sum, order) => {
            if (!order.carrello) return sum;
            return sum + (Object.values(order.carrello) as any[]).reduce((orderSum: number, item: any) => {
                const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
                const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
                return orderSum + (price * quantity);
            }, 0);
        }, 0);

        return {
            totalOrders,
            totalRevenue,
            averageReceipt: totalOrders ? totalRevenue / totalOrders : 0
        };
    }

    private calculatePercentageChange(previous: number, current: number): number {
        if (!previous) return 100;
        return ((current - previous) / previous) * 100;
    }

    setDatePreset(preset: string): void {
        const today = new Date();
        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);

        switch (preset) {
            case 'today':
                this.filters.dateRange.start = new Date(today.setHours(0, 0, 0, 0));
                this.filters.dateRange.end = endOfDay;
                break;

            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                const yesterdayEnd = new Date(yesterday);
                yesterdayEnd.setHours(23, 59, 59, 999);

                this.filters.dateRange.start = yesterday;
                this.filters.dateRange.end = yesterdayEnd;
                break;

            case 'lastWeek':
                const lastWeekStart = new Date(today);
                lastWeekStart.setDate(today.getDate() - 7);
                lastWeekStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastWeekStart;
                this.filters.dateRange.end = endOfDay;
                break;

            case 'lastMonth':
                const lastMonthStart = new Date(today);
                lastMonthStart.setMonth(today.getMonth() - 1);
                lastMonthStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastMonthStart;
                this.filters.dateRange.end = endOfDay;
                break;
        }

        this.activePreset = preset;
        this.showCustomDateRange = false;
        this.loadOrders();
    }

    openDateRangePicker(): void {
        this.showCustomDateRange = true;
        this.activePreset = null;
    }

    updateDateRange(start: Date, end: Date): void {
        this.filters.dateRange = { start, end };
        this.loadOrders();
    }

    onTimeRangeChange(value: string): void {
        switch(value) {
            case 'morning':
                this.filters.timeRange = { start: '06:00', end: '12:00' };
                break;
            case 'lunch':
                this.filters.timeRange = { start: '12:00', end: '15:00' };
                break;
            case 'afternoon':
                this.filters.timeRange = { start: '15:00', end: '19:00' };
                break;
            case 'dinner':
                this.filters.timeRange = { start: '19:00', end: '23:00' };
                break;
            case 'all':
                this.filters.timeRange = { start: '00:00', end: '23:59' };
                break;
        }
        this.applyFilters();
        this.calculateStats();
    }

    toggleOrderType(type: string): void {
        const index = this.filters.orderTypes.indexOf(type);
        if (index === -1) {
            this.filters.orderTypes.push(type);
        } else {
            this.filters.orderTypes.splice(index, 1);
        }
        this.applyFilters();
        this.calculateStats();
    }

    toggleDeletedOrders(): void {
        this.filters.showDeleted = !this.filters.showDeleted;
        this.applyFilters();
        this.calculateStats();  // Ricalcola le statistiche
    }

    isActivePreset(preset: string): boolean {
        return this.activePreset === preset;
    }

    getOrderProducts(order: any): any[] {
        if (!order.carrello) return [];
        return Object.values(order.carrello);
    }

    getPaymentMethodIcon(method: string): string {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'contanti':
                return 'payments';
            case 'card':
            case 'carta':
                return 'credit_card';
            case 'bancomat':
                return 'credit_card';
            default:
                return 'payment';
        }
    }

    getPaymentMethodLabel(method: string): string {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'contanti':
                return 'Contanti';
            case 'card':
            case 'carta':
                return 'Carta';
            case 'bancomat':
                return 'Bancomat';
            default:
                return method || 'N/D';
        }
    }

    getTotalProducts(order: any): number {
        if (!order.carrello) return 0;
        return (Object.values(order.carrello) as any[]).reduce((sum: number, item: any) => {
            return sum + (parseInt(item.quantita) || 0);
        }, 0);
    }

    getOrderTotal(order: any): number {
        if (!order.carrello) return 0;
        return (Object.values(order.carrello) as any[]).reduce((sum: number, item: any) => {
            const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
            const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
            return sum + (price * quantity);
        }, 0);
    }

    viewOrderDetails(order: any): void {
        console.log('View order details:', order);
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);
    }

    formatDate(timestamp: string): string {
        return moment(parseInt(timestamp)).format('DD/MM/YYYY HH:mm');
    }

    search(term: string): void {
        this.filters.searchTerm = term;
        this.applyFilters();
        this.calculateStats();
    }

    exportToCSV(): void {
        // Get the current filtered orders
        const ordersToExport = this.filteredOrders;

        // Prepare CSV content
        const headers = [
            'ID Scontrino',
            'Nome Cliente',
            'Data',
            'Numero Prodotti',
            'Totale',
            'Metodo di Pagamento',
            'Dettagli Prodotti'
        ];

        const csvData = ordersToExport.map(order => {
            // Prepare product details
            const productDetails = this.getOrderProducts(order)
                .map(product =>
                    `${product.quantita}x ${product.title} (${this.formatCurrency(product.prezzo * product.quantita)})`
                )
                .join(' | ');

            return [
                `"${order.id}"`,
                `"${order.displayName || 'N/D'}"`,
                `"${this.formatDate(order.data)}"`,
                this.getTotalProducts(order),
                this.getOrderTotal(order),
                this.getPaymentMethodLabel(order.paymentMethod),
                `"${productDetails}"`
            ];
        });

        // Combine headers and data
        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.join(','))
        ].join('\n');

        // Create and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'scontrini_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}