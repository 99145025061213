// stampanti.ts
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import {NotificationService} from "./notificationService/notification.service";

interface Printer {
  name: string;
  ipAddress: string;
}

@Component({
  selector: 'stampanti',
  templateUrl: './stampanti.html',
  styleUrls: ['./stampanti.css']
})
export class Stampanti implements OnInit {
  printerForm: FormGroup;
  isLoading = false;

  constructor(
      private router: Router,
      private userService: UserDataService,
      private dataServiceGenerico: DataServiceGenerico,
      private notificationService: NotificationService,

      private fb: FormBuilder
  ) {
    this.printerForm = this.fb.group({
      printers: this.fb.array([])
    });
  }

  get printers() {
    return this.printerForm.get('printers') as FormArray;
  }

  addPrinter() {
    const printerGroup = this.fb.group({
      name: ['', Validators.required],
      ipAddress: ['', [Validators.required, Validators.pattern('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$')]]
    });
    this.printers.push(printerGroup);
  }

  removePrinter(index: number) {
    this.printers.removeAt(index);
  }

  async ngOnInit() {
    if (this.dataServiceGenerico.getRistorante1() === 'demo_1') {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();
      await this.loadPrinters();
    } else {
      await this.loadPrinters();
    }
  }

  async loadPrinters() {
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        console.error('No restaurant ID found');
        return;
      }

      const db = firebase.firestore();
      const doc = await db.collection('ristoranti').doc(restaurantId).get();
      const data = doc.data();

      if (data?.printers) {
        const printers = data.printers;
        printers.forEach(printer => {
          this.printers.push(
              this.fb.group({
                name: [printer.name, Validators.required],
                ipAddress: [printer.ipAddress, [Validators.required, Validators.pattern('^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$')]]
              })
          );
        });
      }
    } catch (error) {
      console.error('Error loading printers:', error);
    }
  }

  async savePrinters() {
    if (this.printerForm.invalid) {
      Object.keys(this.printerForm.controls).forEach(key => {
        const control = this.printerForm.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.isLoading = true;
    try {
      const restaurantId = this.dataServiceGenerico.getRistorante1();
      if (!restaurantId) {
        throw new Error('No restaurant ID found');
      }

      const formValue = this.printerForm.value;
      const printers: Printer[] = formValue.printers;

      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurantId).update({
        printers: printers
      });

      this.notificationService.show('Stampanti salvate con successo', 'success');
    } catch (error) {
      console.error('Error saving printers:', error);
      this.notificationService.show('Errore durante il salvataggio delle stampanti', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  getErrorMessage(controlName: string, index: number): string {
    const control = this.printers.at(index).get(controlName);
    if (control?.hasError('required')) {
      return 'Questo campo è obbligatorio';
    }
    if (control?.hasError('pattern')) {
      return 'Inserire un indirizzo IP valido';
    }
    return '';
  }
}