<div class="row" style="width: 100% !important; padding-top: 20px">
  <div class="column">

    <!--fieldset style=" min-width: 99% !important; margin-left: 1%; border-radius: 20px; height: 320px; border: 2px solid rgba(222,214,214,0.57); display: flex; background-color: #fdfae9">
      <legend style="font-weight: 600; font-size: xx-large"> Inventario </legend>

      <div class="chart-container" *ngIf="pieChart !== {}" style="display: flex">
        <canvas [id]="idPieChart" style="max-width: 45% !important; max-height: 100% !important; margin-top: 30px; float: left"></canvas>
        <canvas [id]="idChart" style="max-width: 55% !important; max-height: 100% !important; float: left"></canvas>

      </div>

    </fieldset-->

    <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 20px">
      <div style="width: 24.25%; margin-right: 1%;">
        <div class="sb-example-1 shadow" style="margin-left: 10px; width: 98%">
          <div class="search" >
            <button type="submit" class="search-button" style="margin-top: 5px !important">
              <i class="fa fa-search"></i>
            </button>
            <input style="margin-left: 10px;" type="text" class="searchTerm" placeholder="Cerca" (input)="applyFilter($event.target.value)">
          </div>
        </div>
      </div>
      <div style="width: 24.25%; margin-right: 1%;">
        <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Seleziona Periodo'"
                       altezza="45"
                       [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7')] | async">
        </categorieCard>
      </div>
      <div style="width: 24.25%;">
        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Esportazione'"
                       altezza="45"
                       [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-export-100.png?alt=media&token=ffe66f30-afb0-4cca-90d2-10135785c4d6')] | async"
                       [border]="'#2196F3'">
        </categorieCard>
      </div>
    </div>

    <div class='table-responsive' style="padding-bottom: 20px; margin-top: 20px; margin-left: 1%; width: 101%; overflow-y: scroll; height: calc(100vh - 180px); border-radius: 20px; border: 1px solid #e3e2e2;">

      <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

        <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
          <mat-header-cell *matHeaderCellDef>{{ column.field.toUpperCase()  }}
            <mat-menu #menu>
              <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
              <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
            </mat-menu>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{ column.field !== ' '?  row[column.field]: ' ' }} {{column.field == 'prezzo'? ' €': ''}} {{column.field == 'variazioneCost'? ' €': ''}} {{column.field == 'spesaProdotto'? ' €': ''}} {{column.field == 'variazionePercentuale'? ' %': ''}}</span>
            <span *ngIf="column.field == ' '" [ngClass]="{'inner-circleRed': row['variazioneUnits'] < 0, 'inner-circleWhite': row['variazioneUnits'] == 0, 'inner-circleGreen': row['variazioneUnits'] > 0}"
            ></span>
          </mat-cell>
        </ng-container>

        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <mat-cell colspan="999" *matCellDef="let group">
            <strong>{{group[groupByColumns[group.level-1]]}}</strong>
            <strong *ngIf="group.categoria == 'Risultati'">TOTALI</strong>
            <strong style=" margin-left: auto; padding: 10px; width: 7%"> {{sumVariazioneProdotto(group.categoria)}} € </strong>


            <strong style="padding: 10px; width: 9%"> {{sumCostoProdotto(group.categoria)}} €</strong>

            <strong style="margin-right: 7%; padding: 10px; width: 10%"> {{sumValoreMagazzino(group.categoria)}} € </strong>


            <span>{{stampRow(group.categoria)}}</span>

          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

      </mat-table>

    </div>
  </div>
</div>
