<div style="margin-left: 220px">
  <h1>Test Offline Mode</h1>
  <button [ngClass]="{'btn-success': areDataEqual, 'btn-danger': !areDataEqual}">
    {{ areDataEqual ? 'Dati Uguali' : 'Dati Diversi' }}
  </button>

  <h2>Dati Online</h2>
  <pre>{{ onlineData$ | async | json }}</pre>

  <h2>Dati Offline</h2>
  <pre>{{ offlineData$ | async | json }}</pre>
</div>
