// src/app/shared/pipes/shared-pipes.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectURLPipe} from "../app/pages/settings/slider-images/object-url-pipe";

@NgModule({
    declarations: [ObjectURLPipe],
    imports: [CommonModule],
    exports: [ObjectURLPipe]
})
export class SharedPipesModule { }