// delete-order-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-order-dialog',
    template: `
    <div class="fixed inset-0 bg-black p-4">
        <div class="bg-white rounded-3xl w-full h-full flex flex-col overflow-hidden relative" style="background: #fbfbfb">
            <div class="dialog-container">
                <div class="sticky-container">
                    <button mat-icon-button class="close-button" aria-label="Chiudi" (click)="onNoClick()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                
                <h2 class="dialog-title">Elimina Ordine</h2>
                
                <div class="dialog-content">
                    <p class="instruction-text">Inserisci la motivazione dell'eliminazione:</p>
                    
                    <div class="text-display">
                        <div class="text-area">{{motivazione}}</div>
                        <div *ngIf="showCursor" class="cursor-blink">|</div>
                    </div>
                    
                    <div class="keyboard-container">
                        <!-- Prima riga - Numeri -->
                        <div class="keyboard-row">
                            <button *ngFor="let key of numberKeys" 
                                    class="keyboard-key" 
                                    (click)="addChar(key)">{{key}}</button>
                        </div>
                        
                        <!-- Seconda riga - Prima riga lettere -->
                        <div class="keyboard-row">
                            <button *ngFor="let key of qwertyKeys[0]" 
                                    class="keyboard-key" 
                                    (click)="addChar(key)">{{key}}</button>
                        </div>
                        
                        <!-- Terza riga - Seconda riga lettere -->
                        <div class="keyboard-row">
                            <button *ngFor="let key of qwertyKeys[1]" 
                                    class="keyboard-key" 
                                    (click)="addChar(key)">{{key}}</button>
                        </div>
                        
                        <!-- Quarta riga - Terza riga lettere + speciali -->
                        <div class="keyboard-row">
                            <button class="keyboard-key function-key shift-key" 
                                    (click)="toggleShift()">
                                <mat-icon>keyboard_capslock</mat-icon>
                            </button>
                            <button *ngFor="let key of qwertyKeys[2]" 
                                    class="keyboard-key" 
                                    (click)="addChar(key)">{{key}}</button>
                            <button class="keyboard-key function-key backspace-key" 
                                    (click)="removeChar()">
                                <mat-icon>backspace</mat-icon>
                            </button>
                        </div>
                        
                        <!-- Quinta riga - Spazio e altri tasti speciali -->
                        <div class="keyboard-row">
                            <button class="keyboard-key symbol-key" 
                                    (click)="toggleSymbols()">?123</button>
                            <button class="keyboard-key space-key" 
                                    (click)="addChar(' ')">Spazio</button>
                            <button class="keyboard-key return-key" 
                                    (click)="addNewLine()">
                                <mat-icon>keyboard_return</mat-icon>
                            </button>
                        </div>
                    </div>
                    
                    <!-- Pulsanti azione -->
                    <div class="actions-container">
                        <button mat-raised-button class="cancel-button" (click)="onNoClick()">
                            Annulla
                        </button>
                        <button mat-raised-button 
                                class="confirm-button"
                                [disabled]="!motivazione?.trim()"
                                (click)="onConfirm()">
                            Conferma
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    styles: [`
        /* Stili per il bordo nero e container esterno */
        .fixed {
            position: fixed;
        }
        
        .inset-0 {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
        
        .bg-black {
            background-color: #000;
        }
        
        .p-4 {
            padding: 1rem;
        }
        
        .rounded-3xl {
            border-radius: 1.5rem;
        }
        
        .w-full {
            width: 100%;
        }
        
        .h-full {
            height: 100%;
        }
        
        .flex {
            display: flex;
        }
        
        .flex-col {
            flex-direction: column;
        }
        
        .overflow-hidden {
            overflow: hidden;
        }
        
        .relative {
            position: relative;
        }
        
        /* Stili dialog container */
        .dialog-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;
        }
        
        .dialog-title {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 20px;
            text-align: center;
        }
        
        .dialog-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        
        .instruction-text {
            font-size: 16px;
            color: #666;
        }
        
        /* Stili area testo */
        .text-display {
            min-height: 100px;
            max-height: 150px;
            border: 1px solid #ddd;
            border-radius: 8px;
            padding: 12px;
            background-color: #fff;
            font-size: 16px;
            line-height: 1.5;
            overflow-y: auto;
            display: flex;
            flex-wrap: wrap;
            position: relative;
        }
        
        .text-area {
            white-space: pre-wrap;
            word-break: break-word;
        }
        
        .cursor-blink {
            display: inline-block;
            animation: blink 1s infinite;
            color: #333;
            margin-left: 2px;
        }
        
        @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
        }
        
        /* Stili tastierino */
        .keyboard-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1;
        }
        
        .keyboard-row {
            display: flex;
            gap: 6px;
            justify-content: center;
        }
        
        .keyboard-key {
            height: 50px;
            flex: 1;
            min-width: 40px;
            border-radius: 8px;
            background-color: #f5f5f7;
            border: none;
            font-size: 16px;
            font-weight: 500;
            color: #333;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .keyboard-key:active {
            transform: scale(0.95);
            background-color: #e0e0e0;
        }
        
        .function-key {
            flex: 1.5;
        }
        
        .backspace-key, .shift-key {
            background-color: #e0e0e0;
        }
        
        .space-key {
            flex: 3;
        }
        
        .symbol-key, .return-key {
            flex: 1.5;
            background-color: #e0e0e0;
        }
        
        /* Stili pulsanti azione */
        .actions-container {
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            margin-top: 20px;
        }
        
        .cancel-button {
            background-color: #f44336;
            color: white;
            padding: 10px 20px;
            border-radius: 8px;
            border: none;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
        }
        
        .confirm-button {
            background-color: #4caf50;
            color: white;
            padding: 10px 20px;
            border-radius: 8px;
            border: none;
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
        }
        
        .confirm-button:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
        }
        
        /* Header e bottone chiusura */
        .sticky-container {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 1000;
        }
        
        .close-button {
            width: 40px;
            height: 40px;
            background-color: red;
            border: 1px solid red;
            color: white;
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    `]
})
export class DeleteOrderDialogComponent {
    motivazione: string = '';
    showCursor: boolean = true;
    isUpperCase: boolean = false;
    showSymbols: boolean = false;

    // Layout tastiera
    numberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    qwertyKeys = [
        ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
        ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
        ['z', 'x', 'c', 'v', 'b', 'n', 'm']
    ];
    symbolKeys = [
        ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')'],
        ['-', '_', '=', '+', '[', ']', '{', '}', ';', "'"],
        ['/', '\\', '|', ',', '.', '<', '>']
    ];

    constructor(
        public dialogRef: MatDialogRef<DeleteOrderDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        // Attiva l'animazione del cursore
        setInterval(() => {
            this.showCursor = !this.showCursor;
        }, 500);
    }

    addChar(char: string): void {
        if (this.isUpperCase && !this.showSymbols) {
            char = char.toUpperCase();
        }
        this.motivazione += char;
    }

    removeChar(): void {
        if (this.motivazione.length > 0) {
            this.motivazione = this.motivazione.substring(0, this.motivazione.length - 1);
        }
    }

    addNewLine(): void {
        this.motivazione += '\n';
    }

    toggleShift(): void {
        this.isUpperCase = !this.isUpperCase;
    }

    toggleSymbols(): void {
        this.showSymbols = !this.showSymbols;
        if (this.showSymbols) {
            // Salvare il layout QWERTY e mostrare i simboli
            this.qwertyKeys = this.symbolKeys;
        } else {
            // Ripristinare il layout QWERTY
            this.qwertyKeys = [
                ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
                ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
                ['z', 'x', 'c', 'v', 'b', 'n', 'm']
            ];
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onConfirm(): void {
        this.dialogRef.close(this.motivazione);
    }
}