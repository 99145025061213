<div style="max-height: 150px; width: 100% !important; display: flex; align-items: center; justify-content: center;">
    <img [src]="data.dataKey? data.dataKey.image_link: linkImg" style="aspect-ratio: 1 / 1; width: 20%; background-color: transparent; overflow-clip-margin: content-box">
</div>

<div style="text-align: center;border-radius: 20px; font-size: xx-large; font-weight: 900; width: 600px">
    {{ data.dataKey? data.dataKey.title: 'Titolo mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: x-large; max-width: 70%; margin-left: 15%; font-weight: 500">
    {{ data.dataKey? data.dataKey.descrizione: 'Descrizione mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: large; font-weight: 400">
    {{ data.dataKey? data.dataKey.price + '€': '0.00€' }}
</div>

<div class="row slider-container" *ngIf="variants.length > 1">
    <div *ngFor="let variant of variants; let i = index"
         class="slider"
         [style.width]="100/variants.length + '%'"
         [style.background-color]="currentVariantIndex === i ? 'rgba(181,181,181,0.2)': 'transparent'"
         (click)="selectVariant(i)">
        <span>{{variant.name}}</span>
    </div>
</div>

<div class="variant-content" *ngIf="variants[currentVariantIndex]" style="height: 37vh; overflow-y: auto">
    <div class="variant-header mb-4">
        <h3 class="text-lg font-semibold">{{variants[currentVariantIndex].domanda}}</h3>
        <span *ngIf="variants[currentVariantIndex].isRequired" class="required-badge">
      Obbligatorio
    </span>
    </div>

    <div class="options-grid">
        <div *ngFor="let option of variants[currentVariantIndex].options"
             class="option-card"
             [class.selected]="getOptionQuantity(variants[currentVariantIndex].id, option.name) > 0"
             (click)="selectOption(option, variants[currentVariantIndex])"
             (swipeleft)="handleSwipe('left', variants[currentVariantIndex], option)"
             (swiperight)="handleSwipe('right', variants[currentVariantIndex], option)"

             style="display: flex; align-items: center; justify-content: center; position: relative">
            <div class="option-content">
                <span class="option-name">{{option.name}}</span>
                <span *ngIf="option.priceModifier > 0" class="price">
          +{{option.priceModifier.toFixed(2)}}€
        </span>
            </div>
            <div *ngIf="getOptionQuantity(variants[currentVariantIndex].id, option.name) > 0"
                 [attr.data-variant]="variants[currentVariantIndex].id"
                 [attr.data-option]="option.name"
                 class="quantity-badge">
                {{getOptionQuantity(variants[currentVariantIndex].id, option.name)}}
            </div>
        </div>
    </div>
</div>

