import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Input, Output, ElementRef } from '@angular/core';
import firebase from 'firebase/app';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";


@Component({
  selector: 'edit-menus',
  templateUrl: './edit-menus.html',
  styleUrls: ['./edit-menus.css'],
})
export class EditMenus implements OnInit, OnDestroy {
  menus: string[] = [];
  newMenuName: string = '';

  trackByFn(index: number): number {
    return index;
  }

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(
      private cdRef: ChangeDetectorRef,
      private elRef: ElementRef,
      private dataService: DataServiceGenerico,
  ) {}

  ngOnInit(): void {
    this.fetchMenusFromFirestore();
  }

  ngOnDestroy(): any {
    this.passBack();
  }

  fetchMenusFromFirestore(): void {
    this.dataService.getAvailableMenus().subscribe(menus => {
      this.menus = menus;
      this.cdRef.detectChanges();
    });
  }

  saveNewMenu(): void {
    if (this.newMenuName.trim()) {
      this.dataService.createMenu(this.newMenuName).then(() => {
        console.log('Nuovo menu creato:', this.newMenuName);
        this.newMenuName = ''; // Reset input field
        this.fetchMenusFromFirestore(); // Aggiorna la lista
      }).catch(error => {
        console.error('Errore durante la creazione del menu:', error);
      });
    }
  }

  async deleteMenu(menuName: string): Promise<void> {
    if (!confirm(`Sei sicuro di voler eliminare il menu "${menuName}"?`)) {
      return;
    }

    try {
      const db = firebase.firestore();
      const restaurantName = this.dataService.getRistorante();

      // Verifica se il menu esiste
      const menuDoc = await db.collection('menu_test').doc(menuName).get();

      if (!menuDoc.exists) {
        console.error('Menu non trovato:', menuName);
        return;
      }

      // Esegui l'eliminazione
      await db.collection('menu_test').doc(menuName).delete();

      console.log('Menu eliminato con successo:', menuName);
      this.fetchMenusFromFirestore(); // Aggiorna la lista

    } catch (error) {
      console.error('Errore durante l\'eliminazione del menu:', error);
    }
  }

  addMenu(): void {
    this.menus.push('');  // Aggiunge un nuovo campo vuoto per il menu
    this.cdRef.detectChanges();
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    setTimeout(() => {
      const lastMenuElement = this.elRef.nativeElement.querySelector(`#menu-${this.menus.length - 1}`);
      if (lastMenuElement) {
        lastMenuElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  updateMenu(index: number, value: string): void {
    this.menus[index] = value;
  }

  saveMenus(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();

    // Filtra i menu vuoti
    const validMenus = this.menus.filter(menu => menu.trim() !== '');

    // Salva ogni nuovo menu
    const savePromises = validMenus.map(menuName => {
      return db.collection('menu_test')
          .doc(menuName)
          .set({ items: [] }, { merge: true });
    });

    Promise.all(savePromises)
        .then(() => {
          console.log('Menu salvati con successo!');
          this.fetchMenusFromFirestore();
        })
        .catch(error => {
          console.error('Errore durante il salvataggio dei menu:', error);
        });
  }

  passBack() {
    const dataToPassBack = {
      menus: this.menus
    };
    this.passEntry.emit(dataToPassBack);
  }
}