import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {newArray} from '@angular/compiler/src/util';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {map} from 'rxjs/operators';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';
import {User} from '../../shared/services/user';
import firebase from 'firebase/app';
import {CommonService} from "../../shared/services/firestore/common.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import { PaymentCashmaticService } from "../../shared/services/payment-cashmatic.service";
import { IpcService } from "../../shared/services/ipc.service";
import { Subscription } from 'rxjs';
import {PosTransactionService} from "../../shared/services/pos-transaction.service";



@Component({
  selector: 'dialog-pagamento',
  templateUrl: './dialogPagamento.html',
  styleUrls: ['./dialogPagamento.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
  ],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"'
  },
})
export class DialogPagamento implements OnDestroy {

  tipoSconto = true;

  sconto = 0;

  coperti = 1;

  selectedCategory: string = 'Totale';  // Default to Totale
  quotePagate: number = 0;
  splitItems: any[] = [];
  originalCarrello: any = {};  // Store original cart
  splitCarrello: any = {};

  change(qualcose? : any)
  {
    console.log("model change: ", this.sconto)
    console.log("model carrello: ", this.carrello)

    if(this.carrello !== undefined) {
      if (this.sconto == 0 || this.sconto == null) {
        if (this.carrello.hasOwnProperty('Sconto')) {
          delete this.carrello['Sconto']
        } else {

        }
      } else {
        if (this.carrello.hasOwnProperty('Sconto')) {
          this.carrello['Sconto']['prezzo'] = -this.sconto
          this.carrello['Sconto']['price'] = -this.sconto
          this.carrello['Sconto']['tipo'] = this.tipoSconto
        } else {
          this.carrello['Sconto'] = {
            image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
            prezzo: -this.sconto,
            price: -this.sconto,
            tipo: this.tipoSconto,
            title: 'Sconto'
          }
        }
      }
    }
  }

  cambiaSconto() {
    this.tipoSconto = !this.tipoSconto

    if (this.carrello !== undefined) {
      if (this.sconto == 0 || this.sconto == null) {
        if (this.carrello.hasOwnProperty('Sconto')) {
          delete this.carrello['Sconto']
        } else {

        }
      } else {
        if (this.carrello.hasOwnProperty('Sconto')) {
          this.carrello['Sconto']['prezzo'] = -this.sconto
          this.carrello['Sconto']['price'] = -this.sconto
          this.carrello['Sconto']['tipo'] = this.tipoSconto
        } else {
          this.carrello['Sconto'] = {
            image_link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
            prezzo: -this.sconto,
            price: -this.sconto,
            tipo: this.tipoSconto,
            title: 'Sconto'
          }
        }
      }
    }

    console.log("carrello: ", this.carrello)
  }

  getSconto()
  {
    if(this.tipoSconto == true)
    {
      return '€'
    }
    else
    {
      return '%'
    }
  }

  bucketLvl = 0;
  terzoLivello = false;
  quartoLivello = false;
  quintoLivello = false;

  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  cashmaticAperto = 0;

  @Inject(MAT_DIALOG_DATA) public data: any


  // tslint:disable-next-line:max-line-length
  constructor(private router: Router, private menuService: MenuService,
              private carrelloService: FirestoreService, private route: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<any>,
              public dataService: DataServiceGenerico,
              private paymentCashmaticService: PaymentCashmaticService,
              private ipcService: IpcService,
              private dataServiceGenerico: DataServiceGenerico,
              private posTransactionService: PosTransactionService
  ) {
    this.ristorante = 'menu';
    this.route.params.subscribe(params => {
          console.log(params);
          if (params.ristorante) {
            //this.ristorante = params.ristorante;
            this.ristorante = 'menu';
          }
          if (params.tavolo) {
            this.numeroTavolo = params.tavolo;
          }
        }
    );


    this.retriveMenu();

    this.posTransactionService.onTransactionResult((event, result) => {
      console.log("POS Transaction Result:", result);

      // Controlla prima se la risposta contiene indicazione di annullamento
      if (result.data && result.data.toLowerCase().includes('annullata') ||
          (result.message && result.message.toLowerCase().includes('annullata'))) {
        this.posStatus = 'cancelled';
        this.paymentMessage = 'Operazione annullata';
        setTimeout(() => {
          this.pagamentoCartaAperto = 0;
          this.posStatus = 'idle';
        }, 2000);
        return;
      }

      // Gestione degli altri stati
      switch (result.status) {
        case 'progress':
          this.posStatus = 'progress';
          this.paymentMessage = result.message;
          break;

        case 'success':
          this.handleSuccessfulPayment(result);
          break;

        case 'error':
        case 'failure':
          this.posStatus = 'error';
          this.paymentMessage = result.message || 'Errore durante il pagamento';
          setTimeout(() => {
            this.pagamentoCartaAperto = 0;
            this.posStatus = 'idle';
          }, 2000);
          break;
      }
    });
    // this.retriveCarrello();
  }


  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  passBack() {
    this.passEntry.emit(this.user);
  }

  ngOnDestroy(): any {

    //this.passBack();

  }

  piattoSelezionato = -1;

  ciao = false;

  ciao2 = false;

  ciao3 = false;
  ciao4 = false;


  clickPiattoNuovo(i){

    this.filtraMenu(this.categoriaSelezionata)[i]
    //this.passEntry.emit(this.filtraMenu(this.categoriaSelezionata)[i]);
    //this.dialogRef.close(false);
  }

  clickPiattoInterno(i)
  {

  }
  showInputForDiners: boolean = false;
  isSingoloSelected: boolean = false;
  selezionati: { [key: string]: number } = {};

  contornoLvl = 0;
  menuSelezionato = 0;

  selezionaMenu(dimensione: any)
  {
    this.piattoSelezionato2['dimensioneMenu'] = dimensione
    this.quartoLivello = true;
  }

  piattoSelezionato2;


  private handleSuccessfulPayment(result: any) {
    this.posStatus = 'success';
    let stringa = [];

    if (this.selectedCategory === 'Singolo') {
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          item.title = item.title.split('_')[0];
          stringa.push(item);
        }
      });

      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key]) {
          delete this.carrello[key];
          delete this.selezionati[key];
        }
      });
    } else {
      this.getProperties(this.carrello)?.forEach(key => stringa.push(this.carrello[key]));
    }

    // Stampa scontrino
    this.printReceipt('', 'carta');

    if (this.selectedCategory === 'Romana' && this.quotePagate < this.coperti) {
      this.camerieri.statoPagato = 1;
      this.quotePagate++;
    } else if (this.selectedCategory === 'Singolo' && Object.keys(this.carrello).length > 1) {
      this.camerieri.statoPagato = 1;
    } else {
      this.camerieri.statoPagato = 0;
    }

    this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    this.checkOrderComplete();

    setTimeout(() => {
      this.pagamentoCartaAperto = 0;
      this.posStatus = 'idle';
    }, 2000);
  }

  handleEvent(event: number, piatto: any) {
    if (this.selectedCategory === 'Singolo') {
      // In modalità singolo, rimuovi direttamente l'item se selezionato
      if (this.selezionati[piatto] && event === 0) {
        delete this.carrello[piatto];
        delete this.selezionati[piatto];

        // Aggiorna il carrello originale
        const originalKey = this.carrello[piatto]?.originalKey;
        if (originalKey && this.originalCarrello[originalKey]) {
          this.originalCarrello[originalKey].quantita--;
          if (this.originalCarrello[originalKey].quantita === 0) {
            delete this.originalCarrello[originalKey];
          }
        }
      }
    } else {
      // Comportamento normale per altre modalità
      piatto = piatto.replace(/ /g, '');
      this.carrello[piatto].quantita = event;
      if (this.carrello[piatto].quantita === 0) {
        delete this.carrello[piatto];
      }
    }

    this.dialogRef.componentInstance.passEntry.emit(
        this.selectedCategory === 'Singolo' ? this.originalCarrello : this.carrello
    );
    this.checkOrderComplete();
  }

  annullaPagamentoCard() {
    this.pagamentoCartaAperto = 0;
    this.posStatus = 'idle';
    this.paymentMessage = '';
  }


  checkOrderComplete() {
    let isComplete = false;

    switch (this.selectedCategory) {
      case 'Totale':
        // Per il totale, l'ordine è completo dopo un singolo pagamento
        isComplete = true;
        break;
      case 'Romana':
        // Per la romana, l'ordine è completo quando tutte le quote sono pagate
        isComplete = this.quotePagate === this.coperti;
        break;
      case 'Singolo':
        // Per il singolo, l'ordine è completo quando tutti gli items sono stati pagati
        isComplete = Object.keys(this.carrello).length === 0 ||
            (Object.keys(this.carrello).length === 1 && this.carrello.hasOwnProperty('Sconto'));
        break;
    }

    if (isComplete) {
      this.camerieri.statoPagato = 0;
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);

      this.dialogRef.close(false);
    }
  }

  // Modifica del template per mostrare le quote pagate
  getButtonText(category: string): string {
    if (category === 'Romana') {
      return `Romana: ${(this.calcolaTotaleBello() / this.coperti).toFixed(2)}€ (${this.quotePagate}/${this.coperti})`;
    }
    return category;
  }


  selectCategory(category: string) {
    if (this.selectedCategory === category) {
      this.selectedCategory = '';
      this.showInputForDiners = false;
      this.restoreOriginalCarrello();
    } else {
      this.selectedCategory = category;
      this.showInputForDiners = category === 'Romana';

      if (category === 'Singolo') {
        this.splitCarrelloItems();
      } else {
        this.restoreOriginalCarrello();
      }
    }

    if (category === 'Singolo') {
      this.isSingoloSelected = true;
      this.resetSelezionati();
    } else {
      this.isSingoloSelected = false;
      this.resetSelezionati();
    }
  }

  // Nuovo metodo per splittare gli items
  splitCarrelloItems() {
    // Salva il carrello originale
    this.originalCarrello = { ...this.carrello };

    // Crea nuovo carrello con items splittati
    const newCarrello = {};

    Object.keys(this.carrello).forEach(key => {
      const item = this.carrello[key];

      // Salta lo sconto
      if (key === 'Sconto') {
        newCarrello[key] = item;
        return;
      }

      // Crea un item singolo per ogni quantità
      for (let i = 0; i < item.quantita; i++) {
        const newKey = `${key}_${i}`;
        newCarrello[newKey] = {
          ...item
        };
      }
    });

    this.carrello = newCarrello;
    this.resetSelezionati();
  }

  // Metodo per ripristinare il carrello originale
  restoreOriginalCarrello() {
    if (Object.keys(this.originalCarrello).length > 0) {
      this.carrello = { ...this.originalCarrello };
    }
  }


  // Metodo per resettare tutti i `categorieCard2`
  resetSelezionati() {
    Object.keys(this.selezionati).forEach(key => {
      this.selezionati[key] = 0;  // Imposta selezionato su 0 per tutti
    });
  }
  handleCardClick(piatto: any) {
    // Se "Singolo" è selezionato, toggla lo stato del piatto
    if (this.isSingoloSelected) {
      if (this.selezionati[piatto] === 1) {
        this.selezionati[piatto] = 0;  // Deseleziona se era già selezionato
      } else {
        this.selezionati[piatto] = 1;  // Seleziona se non era selezionato
      }
    }
  }

  isSelectedForPiatto(piatto: any): number {
    // Restituisce lo stato "selezionato" del piatto, default a 0 se non è selezionato
    return this.selezionati[piatto] || 0;
  }

  isSelected(category: string): boolean {
    return this.selectedCategory === category;
  }



  categoriaSelezionata = '';


  ciaociao(i): any {
    console.log("ciaociaociao: ", i);
    this.ciao = !this.ciao;
    this.categoriaSelezionata = i;
  }


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];
  carrello = {}
  camerieri: any = {
    id: "",
    statoPagato: 0,
    carrello: {}
  };
  pagamentoCartaAperto = 0;
  ifCarrello = false;
  carrelloTmp;
  ricerca = '';
  ricercato = false;
  items = [
    'Item 0',
    'Item 1',
    'Item 2',
    'Item 3',
    'Item 4',
    'Item 5',
    'Item 6',
    'Item 7',
  ];

  currentIndex = -1;
  title = '';
  menu = new Array();
  menuFiltrato = new Array();

  user: User;


  isOver = false;


  ristorante = 'ciao';
  numeroTavolo = 0;


  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FGroup_3_cc.png?alt=media&token=0d4a5c5a-3784-4206-aa5e-28e4a669032a';

  nome = 'Marco Polo';

  ingredienti = 'Visualizza Profilo';

  prezzo = 93263269631;

  scrollato = false;

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];

  public scannerEnabled = false;

  public categoria = 0;
  public categoriaBefore = 0;

  public porcodioPrima = 0;
  public porcodioDopo = 0;
  public prece = 0;
  public selez = 0;

  tot = 0;
  numeroElementi = 0;
  tipoStampante='rch';
  posStatus: 'idle' | 'progress' | 'success' | 'error' | 'cancelled' = 'idle';

  @ViewChildren('cacaca') testDiv: QueryList<any>;
  isTestDivScrolledIntoView: boolean;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  ngOnInit(): void {

    console.log("data: ", this.dialogRef.componentInstance.carrello)
    this.carrello = this.dialogRef.componentInstance.carrello;
    if(this.carrello.hasOwnProperty('Sconto'))
    {
      this.tipoSconto = this.carrello['Sconto']['tipo']
      this.sconto = -this.carrello['Sconto']['prezzo']
    }

    this.subscriptions.push(
        this.paymentCashmaticService.soldiInseriti$.subscribe(
            (soldiInseriti) => {
              this.soldiInseriti = soldiInseriti;
            }
        ),
        this.paymentCashmaticService.paymentSuccess$.subscribe(
            (data) => {
              this.paymentMessage = 'Pagamento completato con successo!';
              this.cashmaticAperto = 0;
              // Update payment status
              ////this.updatePaymentStatus();
            }
        ),
        this.paymentCashmaticService.paymentError$.subscribe(
            (error) => {
              this.paymentMessage = 'Errore durante il pagamento: ' + error;
            }
        )
    );


  }


  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  valuechange(newValue): any {
    console.log(this.ricerca);
    this.menuFiltrato = new Array();
    this.menuFiltrato = this.filterByValue(this.menu, newValue);
    console.log('menuCard filtrato');
    console.log(this.menuFiltrato);
    if (newValue.length > 0) {
      this.ricercato = true;
    } else {
      this.ricercato = false;
    }
    // this.scontrini.filter(t => t.nome === newValue);

  }

  filterByValue(array, stringa): any {


    const result = [];

    /*
    result.concat(array.forEach(element => {

      console.log('element');
      console.log(element);
      element.menuCard.filter(o => {
        console.log('o');
        console.log(o);
        try {
          Object.keys(o).some(k => o[k].toLowerCase().includes(stringa.toLowerCase));
        }catch {}
      });

    }));

     */

    array.forEach(element => {
      console.log('element');
      console.log(element.menu);
      console.log('element filtrato');
      const filtrato = this.filterByValueInside(element.menu, stringa);
      console.log(filtrato);
      if (filtrato.length > 0) {
        result.push(filtrato);
      }
    });
    console.log('result');
    console.log(result);
    const risultato = new Array();
    risultato.push(new Array());
    result.forEach(element => risultato[0] = risultato[0].concat(element));
    console.log('risultato');
    console.log(risultato);
    return risultato;
  }

  filterByValueInside(arrayy, stringaa): any {

    console.log('filtro: ', arrayy, stringaa);

    // console.log('array');
    // console.log(array);
    return arrayy.filter(o => Object.keys(o).some(k => o[k].toString().toLowerCase().includes(stringaa.toLowerCase())));
  }



  menuBello = []
  categorie = []

  filtraMenu(name: String)
  {
    if(name !== 'Bucket')
    {
      return this.menuBello.filter((value) => value.category == name)
    }
    else
    {
      if((this.bucketLvl == 0))
      {
        return this.menuBello.filter((value) => value.category == name)
      }
      if((this.bucketLvl > 0))
      {
        return this.menuBello.filter((value) => value.category == name).filter((value) => value.lvl == this.bucketLvl)
      }
    }


  }

  getImage(name: String)
  {
    if(this.menuBello.length > 0)
    {
      return this.menuBello[this.menuBello.findIndex((value) => value.category == name)].hasOwnProperty('image_link')?this.menuBello[this.menuBello.findIndex((value) => value.category == name)].image_link: ''
    }
    else
    {
      return '';
    }
  }


  calcolaTotaleBello() {

    //console.log("receive calcola index: ", this.getIndex())

    let sconto = 0;
    let scontoTrue = false;
    if(this.carrello !== undefined)
    {
      if(this.carrello.hasOwnProperty('Sconto'))
      {
        if(this.carrello['Sconto']['tipo'] == true)
        {

        }
        else
        {
          sconto = -this.carrello['Sconto']['prezzo']
          scontoTrue = true;
          //delete this.carrello['Sconto']
        }
      }
    }

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if(this.carrello !== undefined)
    {
      this.getProperties(this.carrello).forEach(piatto => {
        //console.log('piatto', piatto);
        if(scontoTrue == true)
        {
          if(piatto == 'Sconto')
          {

          }
          else
          {
            this.numeroElementi = this.numeroElementi + this.carrello[piatto].quantita;

            let prezzoPiatto = this.getPrezzoPiatto(this.carrello[piatto]);
            this.tot = this.tot + (prezzoPiatto * this.carrello[piatto].quantita);
          }
        }
        else
        {
          this.numeroElementi = this.numeroElementi + this.carrello[piatto].quantita;

          let prezzoPiatto = this.getPrezzoPiatto(this.carrello[piatto]);
          this.tot = this.tot + (prezzoPiatto * this.carrello[piatto].quantita);
        }

        //console.log("tot nuovo: ", this.tot)
      });
    }


    if(scontoTrue == true)
    {

      return (this.tot * (100-sconto))/100
    }
    else
    {
      return this.tot
    }

  }

  testPay() {
    this.cashmaticAperto = 1;
    let stringa = [];
    let importoDaPagare = 0;

    this.getProperties(this.carrello).forEach(key => stringa.push(this.carrello[key]));

    switch (this.selectedCategory) {
      case 'Romana':
        importoDaPagare = this.calcolaTotaleBello() / this.coperti;
        this.paymentCashmaticService.testPay(importoDaPagare, this.stampaScontrino(stringa, ''));
        this.quotePagate++;
        break;

      case 'Singolo':
        importoDaPagare = this.calcolaSelezionati();
        this.paymentCashmaticService.testPay(importoDaPagare, this.stampaScontrino(stringa, ''));
        break;

      default: // Totale
        importoDaPagare = this.calcolaTotaleBello();
        this.paymentCashmaticService.testPay(importoDaPagare, this.stampaScontrino(stringa, ''));
        break;
    }
  }




  annullaPagamento() {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  getTransaction() {
    this.paymentCashmaticService.getTransaction();
  }

  printReceipt(cassetto: string, pagamento: string): void {
    let stringa = [];

    if (this.selectedCategory === 'Singolo') {
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = { ...this.carrello[key] };
          const originalKey = this.removeLastNumberSuffix(key);


          // Verifica se l'elemento esiste già nel carrello del cameriere
          if (this.camerieri.carrello[originalKey]?.isPaid) {
            // Se l'elemento esiste, aggiorna la quantità pagata
            this.camerieri.carrello[originalKey].isPaidQuantity += this.selezionati[key];
          } else {
            // Altrimenti, aggiungi un nuovo elemento
            this.camerieri.carrello[originalKey] = {
              ...item,
              isPaid: true,
              isPaidQuantity: this.selezionati[key]
            };
          }

          // Aggiungi gli elementi selezionati all'array da stampare
          for (let i = 0; i < this.selezionati[key]; i++) {
            stringa.push({ ...item});
          }

          // Rimuovi l'elemento dal carrello corrente
          delete this.carrello[key];
          delete this.selezionati[key];
        }
      });

      // Aggiorna il database con il nuovo stato di pagamento
      this.commonService.updateOrdine(this.camerieri.id, this.camerieri);
    } else {
      this.getProperties(this.carrello)?.forEach(
          key => stringa.push(this.carrello[key])
      );
    }

    this.ipcService.PrintFiscalReceipt(this.stampaScontrino(stringa, pagamento), this.tipoStampante);
    this.checkOrderComplete();
  }

  removeLastNumberSuffix(key: string): string {

    // Cerca un pattern che corrisponde a _N alla fine della stringa
    const lastUnderscoreIndex = key.lastIndexOf('_');
    if (lastUnderscoreIndex === -1) return key;

    // Verifica se dopo l'ultimo underscore c'è solo un numero
    const lastPart = key.slice(lastUnderscoreIndex + 1);
    if (/^\d+$/.test(lastPart)) {
      // Se è così, ritorna la stringa senza l'ultimo _N
      return key.slice(0, lastUnderscoreIndex);
    }
    return key;
  }

  sendTransaction() {
    this.pagamentoCartaAperto = 1;
    let stringa = [];

    if (this.selectedCategory === 'Singolo') {
      Object.keys(this.selezionati).forEach(key => {
        if (this.selezionati[key] && this.carrello[key]) {
          const item = {...this.carrello[key]};
          item.title = item.title.split('_')[0];
          stringa.push(item);
        }
      });
      this.posTransactionService.sendTransaction(
          this.calcolaSelezionati(),
          this.stampaScontrino(stringa, 'carta')
      );
      this.printReceipt('', 'carta');
    } else if (this.selectedCategory === 'Romana') {
      this.getProperties(this.carrello)?.forEach(key => stringa.push(this.carrello[key]));
      this.posTransactionService.sendTransaction(
          this.calcolaTotaleBello() / this.coperti,
          this.stampaScontrino(stringa, 'carta')
      );
      this.printReceipt('', 'carta');
    } else {
      this.getProperties(this.carrello)?.forEach(key => stringa.push(this.carrello[key]));
      this.posTransactionService.sendTransaction(
          this.calcolaTotaleBello(),
          this.stampaScontrino(stringa, 'carta')
      );
      this.printReceipt('', 'carta');
    }
  }

  /*stampaScontrino(menu: any[]): string {
    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;


    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * item.price;
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------

  `;
    console.log(header + scontrino + footer);

    return header + scontrino + footer;
  }

   */

  getPrezzoPiatto(piatto)
  {
    if(piatto?.hasOwnProperty('aggiunte'))
    {
      return piatto?.prezzo + piatto?.aggiunte.length
    }
    else
    {
      return piatto?.prezzo
    }
  }


  stampaScontrino(menu: any[], pagamento: string): string {
    // Header of the XML structure
    let xml = `<printerFiscalReceipt>\n<beginFiscalReceipt operator="1" />\n`;

    // Loop through the menu array and format each item
    menu.forEach(item => {
      xml += `<printRecItem operator="1" description="${item.title}" quantity="${item.quantita}" unitPrice="${this.getPrezzoPiatto(item)}" department="${item?.hasOwnProperty('reparto')? item?.reparto: '2'}"  justification="1" />\n`;
    });

    // Close the fiscal receipt
    xml += `<printRecSubtotal operator="1" option="1" />\n`;
    if (pagamento=='carta'){
      xml += `<printRecTotal operator="1" description="Pagamento con carta" payment="${this.calcolaTotaleBello().toFixed(2)}" paymentType="2" index="1" justification="2" />\n`;
    }
    else{
      xml += `<printRecTotal operator="1" description="Pagamento in contanti" payment="${this.calcolaTotaleBello().toFixed(2)}" paymentType="0" index="1" justification="2" />\n`;
    }
    xml += `<endFiscalReceipt operator="1" />\n</printerFiscalReceipt>`;

    console.log("dati stampa xml",xml);
    return xml;
  }


  calcolaSelezionati() {
    let totale = 0;

    if (this.selezionati !== undefined) {
      Object.keys(this.selezionati).forEach(piatto => {
        if (this.selezionati[piatto] && this.carrello[piatto]) {
          let prezzoPiatto = this.getPrezzoPiatto(this.carrello[piatto]);
          totale += prezzoPiatto;  // Ogni item ha quantità 1 in modalità singola
        }
      });
    }

    return totale;
  }



  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.menuBello = data.items; // Recupero e assegno i dati del menu
        this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

        console.log('Menu recuperato da Firebase:', this.menuBello);
      } else {
        console.log('Nessun documento trovato su Firebase.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }



  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function( element ) {
      return element !== undefined;
    });
  }

  clickMenu(event): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.router.navigate(['/carrello']);
    event.stopPropagation();
  }

  // tslint:disable-next-line:typedef
  dropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
        this.items,
        event.previousIndex,
        event.currentIndex
    );
  }

  clicco2(): void {
    this.router.navigate(['/tavoli']);
  }


  clicco3(): void {
    this.router.navigate([this.ristorante + '/menu/modifica/modifica']);
  }

  clickPiatto(piatto?: string): void {
    this.router.navigate([this.ristorante + '/menu/' + piatto]); // , { skipLocationChange: true});
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    let offset = 300;
    if (this.ricercato === true) {
      offset = 280;
    } else {
      offset = 310;
    }
    // 350 with search bar
    // 280 without search bar
    if (window.pageYOffset > offset) {
      console.log('scrollato');

      const element3 = document.getElementById('menuListaPiatti');
      element3.classList.add('menuScrolled');
      element3.classList.remove('menu');
      this.scrollato = true;

    } else {
      if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {

        console.log('scrollato2');
      } else {
        console.log('scrollato3');
        this.scrollato = false;
        const element3 = document.getElementById('menuListaPiatti');
        element3.classList.add('menu');
        element3.classList.remove('menuScrolled');
      }
    }

    console.log(this.testDiv);


    if (this.ricercato === false) {

      this.testDiv.forEach((child) => {

        if (child) {
          const test = child.nativeElement.id;
          const rect = child.nativeElement.getBoundingClientRect();
          const topShown = rect.top >= 0;
          const bottomShown = rect.bottom <= window.innerHeight;
          this.isTestDivScrolledIntoView = topShown && bottomShown;


          console.log('top/bottom: ' + (rect.top / rect.bottom));

          if (this.isTestDivScrolledIntoView) {
            this.porcodioPrima = window.pageYOffset;
            this.porcodioDopo = window.pageYOffset;
            this.categoria = test;
            this.categoriaBefore = test;


          }


          this.porcodioDopo = window.pageYOffset;


          if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 0.75)) {
            console.log('altezza su');


            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo((test * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + test);
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                  cathegories => {
                    if (conta !== test) {
                      const elemento = document.getElementById('buttonCategoria' + conta);
                      elemento.classList.remove('categorieVisibile');
                      elemento.classList.add('categorie');
                    }
                    conta++;
                  }
              );

              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');

            }

            const element2 = document.getElementById('buttonCategoria' + (test - 1));
            if (element2 !== null) {


              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          } else if ((this.categoria === test) && (((rect.top - 100) / rect.bottom) <= 1)) {
            console.log('altezza giu');

            if (this.widgetsContent !== undefined) {
              this.widgetsContent.nativeElement.scrollTo(((test - 1) * 100), 0);
            }

            const element = document.getElementById('buttonCategoria' + (test - 1));
            if (element !== null) {

              let conta = 0;
              this.menu.forEach(
                  cathegories => {
                    if (conta !== (test - 1)) {
                      const elemento = document.getElementById('buttonCategoria' + (conta));
                      elemento.classList.remove('categorieVisibile');
                      elemento.classList.add('categorie');
                    }
                    conta++;
                  }
              );


              element.classList.remove('categorie');
              element.classList.add('categorieVisibile');
            }

            const element2 = document.getElementById('buttonCategoria' + test);
            if (element2 !== null) {
              element2.classList.remove('categorieVisibile');
              element2.classList.add('categorie');
            }

          }


        }


        console.log(this.isTestDivScrolledIntoView);
      });

    }

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);

    const yOffset = -150;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});


    // const el = document.getElementById(id).scrollIntoView();
    // el.scrollIntoView({behavior: 'smooth'});
  }


  rimuoviElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    if (this.carrelloTmp.piatti[i].quantita === 1) {
      this.carrelloTmp.piatti.splice(i, 1);
      console.log('carrelo con rimozione: ', this.carrelloTmp);

    } else {
      this.carrelloTmp.piatti[i].quantita--;
    }

    this.calcolaTotale();

    this.salva();


    event.stopPropagation();
  }

  aggiungiElementoCarrello(i): void {
    console.log('ciaobiuyew giuye231<giue23<y789e23<');
    console.log('carrello: ', this.carrelloTmp);
    console.log('index: ', i);

    this.carrelloTmp.piatti[i].quantita = this.carrelloTmp.piatti[i].quantita + 1;

    this.calcolaTotale();

    this.salva();

    event.stopPropagation();
  }

  getPrezzo(i): any {
    // console.log('ciaobiuyew giuye231<giue23<y789e23<');
    // console.log("carrello: ", this.carrelloTmp);
    // console.log("index: ", i);


    // console.log('piatto', this.carrelloTmp.piatti[i]);

    let prezzoPiatto = this.carrelloTmp.piatti[i].prezzo;

    if (this.carrelloTmp.piatti[i].opzioni) {
      this.carrelloTmp.piatti[i].opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }

    return prezzoPiatto;
  }


  calcolaTotale(): any {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });

    return this.tot;
  }


  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log('user: ', this.user);

    this.user.carrello = this.carrelloTmp;

    console.log('user da salvare: ', this.user);

    this.carrelloService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.carrelloService.create(userDasalvareSalva, this.user.uid);
    // this.router.navigate(['/menuCard']);
  }

}



/*
scroll(id: any): void {
  console.log(`scrolling to ${id}`);
  let el = document.getElementById(id);
  //el.scrollIntoView({behavior: 'smooth'});
  el.scrollIntoView({ behavior: 'smooth' });

}

 */