import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface ConfirmInventoryDialogData {
    operationType: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento';
    changedItems: Array<{
        productTitle: string;
        quantities: string[];
    }>;
}

@Component({
    selector: 'app-confirm-inventory-dialog',
    template: `
    <div class="dialog-container">
      <div class="sticky-container">
        <div class="save-button" [ngClass]="{'disabled': !confirmForm.valid}" (click)="onSubmit()">
          <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
               style="width: 30px; aspect-ratio: 1 / 1;"
               title="Conferma">
        </div>
        <div class="close-button" (click)="onCancel()">
          <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
               style="width: 30px; aspect-ratio: 1 / 1;"
               title="Chiudi">
        </div>
      </div>

      <div style="height: 80vh; overflow-y: auto; padding: 20px;">
        <form [formGroup]="confirmForm">
          <div style="margin-bottom: 20px">
            <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
              <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                <!-- Titolo -->
                <div class="flex flex-col gap-2">
                  <h2 class="font-bold text-xl" [ngStyle]="{'color': getOperationColor()}">
                    Conferma {{ getOperationName() }}
                  </h2>
                  <p class="text-gray-600">
                    Stai per aggiornare l'inventario con le seguenti modifiche:
                  </p>
                </div>

                <!-- Lista prodotti modificati -->
                <div class="changes-list">
                  <div class="change-item" *ngFor="let item of data.changedItems">
                    <div class="product-title">{{ item.productTitle }}</div>
                    <div class="quantities">
                      <div class="quantity" *ngFor="let qty of item.quantities; let i = index">
                        <span *ngIf="qty && qty !== '0'">Livello {{i+1}}: {{qty}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Note -->
                <div class="flex flex-col gap-2">
                  <label class="font-bold text-gray-700">Note (Opzionale)</label>
                  <textarea
                    placeholder="Inserisci eventuali note o commenti..."
                    class="custom-textarea"
                    formControlName="notes"
                    rows="4"></textarea>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  `,
    styles: [`
    .dialog-container {
      position: relative;
      padding: 20px;
      max-width: 800px;
    }

    .sticky-container {
      position: sticky;
      top: 0;
      z-index: 1000;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin: -20px -20px 0;
      padding: 10px 20px;
      background-color: white;
    }

    .save-button,
    .close-button {
      width: 30px;
      height: 30px;
      cursor: pointer;
      transition: opacity 0.2s ease;
    }

    .save-button.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .custom-textarea {
      background-color: #f7f9fb;
      border-color: #d2d9e0 !important;
      border-width: 1px;
      border-radius: 10px;
      padding: 0.75rem;
      font-size: 1rem;
      line-height: 1.5rem;
      width: 100%;
      border-style: solid;
      resize: vertical;
    }

    .custom-textarea:focus {
      outline-color: rgb(0 125 104);
      border-color: rgb(0 125 104) !important;
    }

    .changes-list {
      margin: 20px 0;
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid #d2d9e0;
      border-radius: 10px;
      padding: 10px;
    }

    .change-item {
      padding: 10px;
      border-bottom: 1px solid #e5e7eb;
    }

    .change-item:last-child {
      border-bottom: none;
    }

    .product-title {
      font-weight: 600;
      margin-bottom: 5px;
    }

    .quantities {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .quantity {
      background-color: #f3f4f6;
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 0.9rem;
    }

    /* Utility classes */
    .flex { display: flex; }
    .flex-col { flex-direction: column; }
    .justify-between { justify-content: space-between; }
    .gap-2 { gap: 0.5rem; }
    .gap-8 { gap: 2rem; }
    .font-bold { font-weight: 700; }
    .text-xl { font-size: 1.25rem; line-height: 1.75rem; }
    .text-gray-600 { color: rgb(75 85 99); }
    .text-gray-700 { color: rgb(55 65 81); }
  `]
})
export class ConfirmInventoryDialogComponent {
    confirmForm: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<ConfirmInventoryDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmInventoryDialogData
    ) {
        this.confirmForm = this.fb.group({
            notes: ['']
        });
    }

    getOperationName(): string {
        switch (this.data.operationType) {
            case 'aggiunta': return 'Carico';
            case 'scarico': return 'Scarico';
            case 'scarti': return 'Scarti';
            case 'trasferimento': return 'Trasferimento';
            default: return 'Operazione';
        }
    }

    getOperationColor(): string {
        switch (this.data.operationType) {
            case 'aggiunta': return '#2D5A27';
            case 'scarico': return '#8B6F3D';
            case 'scarti': return '#8B3D3D';
            case 'trasferimento': return '#3D5F8B';
            default: return '#333333';
        }
    }

    onSubmit(): void {
        if (this.confirmForm.valid) {
            this.dialogRef.close({
                confirmed: true,
                notes: this.confirmForm.get('notes').value
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close({ confirmed: false });
    }
}