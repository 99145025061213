import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import firebase from 'firebase/app';
import moment from 'moment';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { Chart } from 'chart.js';

interface OrderStats {
    totalOrders: number;
    totalRevenue: number;
    averageReceipt: number;
    percentageChanges: {
        orders: number;
        revenue: number;
        average: number;
    };
}

interface OrderFilters {
    dateRange: {
        start: Date;
        end: Date;
    };
    orderTypes: string[];
    orderSources: string[];
    products: string[];
    categories: string[];
    vatRates: string[];
    timeRange: {
        start: string;
        end: string;
    };
    searchTerm: string;
    showDeleted: boolean;
}

@Component({
    selector: 'app-receipts-dashboard',
    templateUrl: './receipts-dashboard.component.html',
    styleUrls: ['./receipts-dashboard.component.css']
})
export class ReceiptsDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('revenueChart') revenueChartCanvas: ElementRef;
    private chart: Chart;
    private destroy$ = new Subject<void>();
    showRevenueChart = false;
    monthlyRevenueData: number[] = new Array(12).fill(0);
    months = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];

    // Form Controls
    dateRange: FormGroup;
    timeRangeControl: FormControl;
    showCustomDateRange = false;
    activePreset: string = 'today';

    // Dati principali
    orders: any[] = [];
    filteredOrders: any[] = [];

    // Statistiche
    stats: OrderStats = {
        totalOrders: 0,
        totalRevenue: 0,
        averageReceipt: 0,
        percentageChanges: {
            orders: 0,
            revenue: 0,
            average: 0
        }
    };

    // Filtri
    filters: OrderFilters = {
        dateRange: {
            start: new Date(),
            end: new Date()
        },
        orderTypes: [],
        orderSources: [],
        products: [],
        categories: [],
        vatRates: [],
        timeRange: {
            start: '00:00',
            end: '23:59'
        },
        searchTerm: '',
        showDeleted: false
    };

    // Filtri predefiniti per date
    datePresets = [
        { label: 'Oggi', value: 'today' },
        { label: 'Ieri', value: 'yesterday' },
        { label: 'Ultima Settimana', value: 'lastWeek' },
        { label: 'Ultimo Mese', value: 'lastMonth' }
    ];

    // Tipi di ordine
    orderTypeFilters = [
        { label: 'Tavolo', value: 'table', icon: 'table_restaurant' },
        { label: 'Asporto', value: 'takeaway', icon: 'delivery_dining' },
        { label: 'Banco', value: 'counter', icon: 'point_of_sale' }
    ];

    // Fonti degli ordini
    orderSourceFilters = [
        { label: 'App', value: 'app', icon: 'smartphone' },
        { label: 'Kiosk', value: 'kiosk', icon: 'tablet_mac' },
        { label: 'Cassa', value: 'pos', icon: 'point_of_sale' }
    ];

    // Loading states
    loading = true;
    error = false;

    activeFilterTab: string = null;
    showDateMenu: boolean = false;

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService
    ) {
        this.dateRange = new FormGroup({
            start: new FormControl(),
            end: new FormControl()
        });
        this.timeRangeControl = new FormControl('all');
    }

    ngOnInit(): void {
        this.initializeFilters();
        this.loadOrders();

        this.timeRangeControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => this.onTimeRangeChange(value));

        this.dateRange.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(range => {
                if (range.start && range.end) {
                    this.updateDateRange(range.start, range.end);
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.showRevenueChart) {
            this.initChart();
        }
    }

    setActiveFilterTab(tab: string): void {
        if (this.activeFilterTab === tab) {
            this.activeFilterTab = null;
        } else {
            this.activeFilterTab = tab;
        }
    }

    toggleDateMenu(): void {
        this.showDateMenu = !this.showDateMenu;
    }

    getActiveDateLabel(): string {
        switch (this.activePreset) {
            case 'today':
                return 'Oggi';
            case 'yesterday':
                return 'Ieri';
            case 'lastWeek':
                return 'Ultima Settimana';
            case 'lastMonth':
                return 'Ultimo Mese';
            default:
                return `${moment(this.filters.dateRange.start).format('DD/MM')} - ${moment(this.filters.dateRange.end).format('DD/MM')}`;
        }
    }

    toggleProductList(order: any): void {
        order.showProducts = !order.showProducts;
    }

    clearSearch(): void {
        this.filters.searchTerm = '';
        this.search('');
    }

    isMobileDevice(): boolean {
        return (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            (window.innerWidth <= 768)
        );
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    private initializeFilters(): void {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        this.filters.dateRange = {
            start: today,
            end: new Date()
        };

        this.filters.orderTypes = this.orderTypeFilters.map(type => type.value);
        this.filters.orderSources = this.orderSourceFilters.map(source => source.value);
    }

    toggleRevenueChart(): void {
        this.showRevenueChart = !this.showRevenueChart;
        if (this.showRevenueChart) {
            this.calculateMonthlyRevenue();
            setTimeout(() => {
                this.initChart();
            }, 0);
        }
    }


    private calculateMonthlyRevenue(): void {
        if (!this.revenueChartCanvas) return;

        const start = moment(this.filters.dateRange.start);
        const end = moment(this.filters.dateRange.end);
        const isSingleDay = start.isSame(end, 'day');

        let timeLabels: string[] = [];
        const chartData = {
            app: [] as number[],
            kiosk: [] as number[],
            pos: [] as number[],
            total: [] as number[]
        };

        // Se è un singolo giorno, dividi in fasce orarie
        if (isSingleDay) {
            // Crea fasce orarie di 1 ora
            for (let hour = 0; hour < 24; hour++) {
                timeLabels.push(moment(start).hour(hour).format('HH:00'));
                chartData.app[hour] = 0;
                chartData.kiosk[hour] = 0;
                chartData.pos[hour] = 0;
                chartData.total[hour] = 0;
            }

            // Raggruppa gli ordini per ora
            this.filteredOrders.forEach(order => {
                if (!order.data) return;
                const orderMoment = moment(parseInt(order.data));
                const orderHour = orderMoment.hour();

                if (orderMoment.isSame(start, 'day')) {
                    const total = this.getOrderTotal(order);
                    const source = order.orderSource || 'pos';

                    chartData[source][orderHour] += total;
                    chartData.total[orderHour] += total;
                }
            });
        } else {
            // Per più giorni, usa le date effettive
            let current = start.clone().startOf('day');
            const endDate = end.clone().endOf('day');

            while (current.isSameOrBefore(endDate)) {
                timeLabels.push(current.format('DD/MM'));
                const index = timeLabels.length - 1;

                chartData.app[index] = 0;
                chartData.kiosk[index] = 0;
                chartData.pos[index] = 0;
                chartData.total[index] = 0;

                current.add(1, 'day');
            }

            // Raggruppa gli ordini per giorno
            this.filteredOrders.forEach(order => {
                if (!order.data) return;
                const orderDate = moment(parseInt(order.data));
                const dayIndex = orderDate.diff(start, 'days');

                if (dayIndex >= 0 && dayIndex < timeLabels.length &&
                    orderDate.isBetween(start, end, null, '[]')) {
                    const total = this.getOrderTotal(order);
                    const source = order.orderSource || 'pos';

                    chartData[source][dayIndex] += total;
                    chartData.total[dayIndex] += total;
                }
            });
        }

        console.log('Labels:', timeLabels);
        console.log('Chart data:', chartData);

        if (this.chart) {
            this.chart.destroy();
        }

        const ctx = this.revenueChartCanvas.nativeElement.getContext('2d');

        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: timeLabels,
                datasets: [
                    {
                        label: 'App',
                        data: chartData.app,
                        borderColor: '#34bfa3',
                        backgroundColor: 'rgba(52, 191, 163, 0.1)',
                        tension: 0.4,
                        fill: true,
                        borderWidth: 2
                    },
                    {
                        label: 'Kiosk',
                        data: chartData.kiosk,
                        borderColor: '#4F46E5',
                        backgroundColor: 'rgba(79, 70, 229, 0.1)',
                        tension: 0.4,
                        fill: true,
                        borderWidth: 2
                    },
                    {
                        label: 'Cassa',
                        data: chartData.pos,
                        borderColor: '#F59E0B',
                        backgroundColor: 'rgba(245, 158, 11, 0.1)',
                        tension: 0.4,
                        fill: true,
                        borderWidth: 2
                    },
                    {
                        label: 'Totale',
                        data: chartData.total,
                        borderColor: '#EF4444',
                        backgroundColor: 'rgba(239, 68, 68, 0.1)',
                        tension: 0.4,
                        fill: true,
                        borderWidth: 2,
                        borderDash: [5, 5]
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: 'index'
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            maxRotation: 45,
                            minRotation: 45,
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b'
                        }
                    },
                    y: {
                        grid: {
                            borderDash: [8, 4],
                            color: 'rgba(148, 163, 184, 0.1)'
                        },
                        beginAtZero: true,
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            callback: (value) => '€' + value.toLocaleString()
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            usePointStyle: true,
                            padding: 20,
                            font: {
                                family: 'Inter',
                                size: 12
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: '#FFFFFF',
                        titleColor: '#1E293B',
                        bodyColor: '#1E293B',
                        borderColor: '#E2E8F0',
                        borderWidth: 1,
                        padding: 12,
                        displayColors: true,
                        callbacks: {
                            label: (context) => `${context.dataset.label}: €${context.raw.toLocaleString()}`
                        }
                    }
                }
            }
        });
    }

    private initChart(): void {
        if (!this.revenueChartCanvas || !this.showRevenueChart) return;

        const ctx = this.revenueChartCanvas.nativeElement.getContext('2d');

        // Crea il gradiente per lo sfondo
        const gradient = ctx.createLinearGradient(0, 0, 0, 300);
        gradient.addColorStop(0, 'rgba(52, 191, 163, 0.4)');
        gradient.addColorStop(1, 'rgba(52, 191, 163, 0.1)');

        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.months,
                datasets: [{
                    label: 'Fatturato Mensile',
                    data: this.monthlyRevenueData,
                    borderColor: '#34bfa3',
                    tension: 0.4,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 6,
                    pointHoverBackgroundColor: '#FFFFFF',
                    pointHoverBorderColor: '#34bfa3',
                    pointHoverBorderWidth: 2,
                    fill: true,
                    backgroundColor: gradient
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b'
                        }
                    },
                    y: {
                        grid: {
                            borderDash: [8, 4],
                            color: 'rgba(148, 163, 184, 0.1)'
                        },
                        ticks: {
                            font: {
                                family: 'Inter',
                                size: 12
                            },
                            color: '#64748b',
                            callback: (value) => '€' + value.toLocaleString()
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        backgroundColor: '#FFFFFF',
                        titleColor: '#1E293B',
                        bodyColor: '#1E293B',
                        borderColor: '#E2E8F0',
                        borderWidth: 1,
                        padding: 12,
                        displayColors: false,
                        callbacks: {
                            label: (context) => '€' + context.raw.toLocaleString()
                        }
                    }
                }
            }
        });
    }

    async loadOrders(): Promise<void> {
        try {
            this.loading = true;
            const resid = await this.dataService.getCurrentRestaurantId();
            const restaurantName = await this.dataService.getRistorante();

            const startTimestamp = this.filters.dateRange.start.getTime();
            const endTimestamp = this.filters.dateRange.end.getTime();

            const ordersRef = firebase.firestore()
                .collection('ordini')
                .where('ristorante', '==', restaurantName)
                .where('data', '>=', startTimestamp.toString())
                .where('data', '<=', endTimestamp.toString());

            const snapshot = await ordersRef.get();
            this.orders = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                selectedPriceListType: doc.data().selectedPriceListType || 'counter',
                orderSource: doc.data().orderSource || 'pos',
                data: doc.data().data || Date.now().toString()
            }));

            this.applyFilters();
            this.calculateStats();

            // Always initialize chart if it's shown
            if (this.showRevenueChart) {
                this.calculateMonthlyRevenue();
                if (this.chart) {
                    this.chart.destroy();
                }
                this.initChart();
            }

            this.loading = false;
        } catch (error) {
            console.error('Error loading orders:', error);
            this.error = true;
            this.loading = false;
        }
    }

    applyFilters(): void {
        this.filteredOrders = this.orders.filter(order => {
            // Verifica eliminati
            if (this.filters.showDeleted) {
                if (!order.dataEliminazione) return false;
            } else {
                if (order.dataEliminazione) return false;
            }

            // Verifica solo ordini pagati (statoPagato = 0 o statoPagato = 3)
            // statoPagato = 0: completamente pagato
            // statoPagato = 3: pagato e chiuso
            // statoPagato = 1: in attesa di pagamento
            if (order.statoPagato !== 0 && order.statoPagato !== 3) {
                return false;
            }

            // Verifica tipi ordine
            if (this.filters.orderTypes.length &&
                !this.filters.orderTypes.includes(order.selectedPriceListType)) {
                return false;
            }

            // Verifica fonti ordine
            if (this.filters.orderSources.length &&
                !this.filters.orderSources.includes(order.orderSource)) {
                return false;
            }

            // Verifica fascia oraria
            const orderTime = moment(parseInt(order.data));
            const startTime = moment(this.filters.timeRange.start, 'HH:mm');
            const endTime = moment(this.filters.timeRange.end, 'HH:mm');

            startTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());
            endTime.year(orderTime.year()).month(orderTime.month()).date(orderTime.date());

            if (!orderTime.isBetween(startTime, endTime, undefined, '[]')) {
                return false;
            }

            // Funzione helper per verificare se un ordine è un preconto
            const isPreconto = (order) => {
                // Controlla il campo nota
                if (order.nota && String(order.nota).toLowerCase().includes('pre')) return true;

                // Controlla i flag dell'ordine
                if (order.isPreconto || order.preconto) return true;

                // Controlla elementi nel carrello
                if (order.carrello && Object.values(order.carrello).some((item: any) =>
                    item.preconto || (item.note && String(item.note).toLowerCase().includes('pre')))) {
                    return true;
                }

                return false;
            };

            // Se c'è un termine di ricerca
            if (this.filters.searchTerm) {
                const searchTerm = this.filters.searchTerm.toLowerCase();

                // Se la ricerca è "preconto+"
                if (searchTerm === 'preconto+') {
                    // Mostra tutti gli ordini pagati, inclusi i preconti
                    return order.statoPagato === 0 || order.statoPagato === 3;
                }

                // Se la ricerca è "preconto"
                if (searchTerm === 'preconto') {
                    // Mostra solo i preconti
                    return isPreconto(order);
                }

                // Per tutte le altre ricerche, escludiamo i preconti e facciamo la ricerca normale
                if (isPreconto(order)) return false;

                // Ricerca standard nei campi
                const searchableFields = [
                    order.displayName,
                    order.motivazione,
                    order.nota,
                    ...(order.tags || []),
                    ...Object.values(order.carrello || {}).map((item: any) => item.title)
                ].filter(Boolean);

                return searchableFields.some(field =>
                    String(field).toLowerCase().includes(searchTerm)
                );
            } else {
                // Se non c'è ricerca, escludiamo comunque i preconti
                return !isPreconto(order);
            }
        });

        // Calcola le statistiche considerando solo gli ordini pagati
        this.calculateStats();

        if (this.showRevenueChart) {
            this.calculateMonthlyRevenue();
            if (this.chart) {
                this.chart.data.datasets[0].data = this.monthlyRevenueData;
                this.chart.update();
            }
        }

        // Aggiungi un log per debug
        console.log('Filtrati:', this.filteredOrders.length, 'ordini di cui pagati:',
            this.filteredOrders.filter(o => o.statoPagato === 0 || o.statoPagato === 3).length);
    }

    private calculateStats(): void {
        const currentStats = this.calculatePeriodStats(this.filteredOrders);
        const previousPeriodStart = new Date(this.filters.dateRange.start);
        const previousPeriodEnd = new Date(this.filters.dateRange.end);
        const periodDiff = previousPeriodEnd.getTime() - previousPeriodStart.getTime();

        previousPeriodStart.setTime(previousPeriodStart.getTime() - periodDiff);
        previousPeriodEnd.setTime(previousPeriodEnd.getTime() - periodDiff);

        const previousOrders = this.orders.filter(order => {
            const orderDate = new Date(parseInt(order.data));
            if (this.filters.showDeleted) {
                return order.dataEliminazione != null &&
                    orderDate >= previousPeriodStart &&
                    orderDate <= previousPeriodEnd;
            }
            return order.dataEliminazione == null &&
                orderDate >= previousPeriodStart &&
                orderDate <= previousPeriodEnd;
        });

        const previousStats = this.calculatePeriodStats(previousOrders);

        this.stats = {
            totalOrders: currentStats.totalOrders,
            totalRevenue: currentStats.totalRevenue,
            averageReceipt: currentStats.averageReceipt,
            percentageChanges: {
                orders: this.calculatePercentageChange(previousStats.totalOrders, currentStats.totalOrders),
                revenue: this.calculatePercentageChange(previousStats.totalRevenue, currentStats.totalRevenue),
                average: this.calculatePercentageChange(previousStats.averageReceipt, currentStats.averageReceipt)
            }
        };
    }

    private calculatePeriodStats(orders: any[]): Omit<OrderStats, 'percentageChanges'> {
        const totalOrders = orders.length;
        const totalRevenue = orders.reduce((sum, order) => {
            if (!order.carrello) return sum;
            return sum + this.getOrderTotal(order);
        }, 0);

        return {
            totalOrders,
            totalRevenue,
            averageReceipt: totalOrders ? totalRevenue / totalOrders : 0
        };
    }

    private calculatePercentageChange(previous: number, current: number): number {
        if (!previous) return 100;
        return ((current - previous) / previous) * 100;
    }

    setDatePreset(preset: string): void {
        const today = new Date();
        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);

        switch (preset) {
            case 'today':
                this.filters.dateRange.start = new Date(today.setHours(0, 0, 0, 0));
                this.filters.dateRange.end = endOfDay;
                break;

            case 'yesterday':
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);
                yesterday.setHours(0, 0, 0, 0);

                const yesterdayEnd = new Date(yesterday);
                yesterdayEnd.setHours(23, 59, 59, 999);

                this.filters.dateRange.start = yesterday;
                this.filters.dateRange.end = yesterdayEnd;
                break;

            case 'lastWeek':
                const lastWeekStart = new Date(today);
                lastWeekStart.setDate(today.getDate() - 7);
                lastWeekStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastWeekStart;
                this.filters.dateRange.end = endOfDay;
                break;

            case 'lastMonth':
                const lastMonthStart = new Date(today);
                lastMonthStart.setMonth(today.getMonth() - 1);
                lastMonthStart.setHours(0, 0, 0, 0);

                this.filters.dateRange.start = lastMonthStart;
                this.filters.dateRange.end = endOfDay;
                break;
        }

        this.activePreset = preset;
        this.showCustomDateRange = false;
        this.loadOrders();
    }

    openDateRangePicker(): void {
        this.showCustomDateRange = true;
        this.activePreset = null;
    }

    updateDateRange(start: Date, end: Date): void {
        // Crea copie delle date per evitare modifiche indesiderate
        const startDate = new Date(start);
        const endDate = new Date(end);

        // Imposta l'ora di inizio a 00:00:00
        startDate.setHours(0, 0, 0, 0);

        // Se le date sono uguali, imposta l'ora di fine a 23:59:59
        if (startDate.getDate() === endDate.getDate() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getFullYear() === endDate.getFullYear()) {
            endDate.setHours(23, 59, 59, 999);
        }

        this.filters.dateRange = { start: startDate, end: endDate };
        this.loadOrders();
    }

    onTimeRangeChange(value: string): void {
        switch(value) {
            case 'morning':
                this.filters.timeRange = { start: '06:00', end: '12:00' };
                break;
            case 'lunch':
                this.filters.timeRange = { start: '12:00', end: '15:00' };
                break;
            case 'afternoon':
                this.filters.timeRange = { start: '15:00', end: '19:00' };
                break;
            case 'dinner':
                this.filters.timeRange = { start: '19:00', end: '23:00' };
                break;
            case 'all':
                this.filters.timeRange = { start: '00:00', end: '23:59' };
                break;
        }
        this.applyFilters();
    }

    toggleOrderType(type: string): void {
        const index = this.filters.orderTypes.indexOf(type);
        if (index === -1) {
            this.filters.orderTypes.push(type);
        } else {
            this.filters.orderTypes.splice(index, 1);
        }
        this.applyFilters();
    }

    toggleOrderSource(source: string): void {
        const index = this.filters.orderSources.indexOf(source);
        if (index === -1) {
            this.filters.orderSources.push(source);
        } else {
            this.filters.orderSources.splice(index, 1);
        }
        this.applyFilters();
    }

    toggleDeletedOrders(): void {
        this.filters.showDeleted = !this.filters.showDeleted;
        this.applyFilters();
    }

    isActivePreset(preset: string): boolean {
        return this.activePreset === preset;
    }

    getOrderProducts(order: any): any[] {
        if (!order.carrello) return [];
        return Object.values(order.carrello);
    }

    getPaymentMethodIcon(method: string): string {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'contanti':
                return 'payments';
            case 'card':
            case 'carta':
                return 'credit_card';
            case 'bancomat':
                return 'credit_card';
            default:
                return 'payment';
        }
    }

    getPaymentMethodLabel(method: string): string {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'contanti':
                return 'Contanti';
            case 'card':
            case 'carta':
                return 'Carta';
            case 'bancomat':
                return 'Bancomat';
            default:
                return method || 'N/D';
        }
    }

    getSourceIcon(source: string): string {
        const sourceFilter = this.orderSourceFilters.find(s => s.value === source);
        return sourceFilter ? sourceFilter.icon : 'point_of_sale';
    }

    getSourceLabel(source: string): string {
        const sourceFilter = this.orderSourceFilters.find(s => s.value === source);
        return sourceFilter ? sourceFilter.label : 'Cassa';
    }

    getTotalProducts(order: any): number {
        if (!order.carrello) return 0;
        return (Object.values(order.carrello) as any[]).reduce((sum: number, item: any) => {
            return sum + (parseInt(item.quantita) || 0);
        }, 0);
    }

    getOrderTotal(order: any): number {
        if (!order.carrello) return 0;
        return (Object.values(order.carrello) as any[]).reduce((sum: number, item: any) => {
            const price = typeof item.prezzo === 'number' ? item.prezzo : parseFloat(item.prezzo || '0');
            const quantity = typeof item.quantita === 'number' ? item.quantita : parseInt(item.quantita || '0');
            return sum + (price * quantity);
        }, 0);
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);
    }

    formatDate(timestamp: string): string {
        return moment(parseInt(timestamp)).format('DD/MM/YYYY HH:mm');
    }

    search(term: string): void {
        this.filters.searchTerm = term;
        this.applyFilters();
    }

    exportToCSV(): void {
        const ordersToExport = this.filteredOrders;
        const headers = [
            'ID Scontrino',
            'Nome Cliente',
            'Data',
            'Numero Prodotti',
            'Totale',
            'Metodo di Pagamento',
            'Fonte Ordine',
            'Dettagli Prodotti'
        ];

        const csvData = ordersToExport.map(order => {
            const productDetails = this.getOrderProducts(order)
                .map(product =>
                    `${product.quantita}x ${product.title} (${this.formatCurrency(product.prezzo * product.quantita)})`
                )
                .join(' | ');

            return [
                `"${order.id}"`,
                `"${order.displayName || 'N/D'}"`,
                `"${this.formatDate(order.data)}"`,
                this.getTotalProducts(order),
                this.getOrderTotal(order),
                this.getPaymentMethodLabel(order.paymentMethod),
                this.getSourceLabel(order.orderSource),
                `"${productDetails}"`
            ];
        });

        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'scontrini_report.csv');
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}