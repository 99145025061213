import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import firebase from 'firebase/app';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject, Observable, from, of, combineLatest, throwError } from "rxjs";
import { CommonService } from "../../../shared/services/firestore/common.service";
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { PaymentCashmaticService } from "../../../shared/services/payment-cashmatic.service";
import { IpcService } from "../../../shared/services/ipc.service";
import { PosTransactionService } from "../../../shared/services/pos-transaction.service";
import { TavoliService } from "../../../shared/services/tavoli.service";
import { UserDataService } from "../../../shared/services/firestore/userData.service";
import { AuthService } from "../../../shared/services/auth.service";
import { AzioniStampanteComponent } from "../../azioniStampante/azioniStampante";
import { takeUntil, map, tap, catchError, switchMap, finalize, filter } from 'rxjs/operators';
import { DailyReceiptService } from "../../../shared/services/daily-receipt.service";

interface UserLocale {
  nome?: string;
  username?: string;
  ruolo?: string;
  cognome?: string;
  passwordCassa?: string;
  permessi?: string[];
  // altre proprietà che potrebbero essere presenti
}

@Component({
  selector: 'app-categorie',
  templateUrl: './cassa-ordini.html',
  styleUrls: ['./cassa-ordini.css']
})
export class CassaOrdini implements OnInit, OnDestroy {
  // Proprietà di classe
  permessiRistorante: string[] = [];
  mostraTavoli: boolean = false;
  ordiniDelivery = [
    {
      id: 'je001',
      numero: 'JE-123',
      nomeCliente: 'Marco Rossi',
      piattaforma: 'Just Eat',
      statoPagato: 1, // 1 = in attesa, 0 = evaso
      stato: 'In attesa',
      bloccato: false,
      accettato: false,
      totale: 35.90,
      carrello: {
        'Margherita': { title: 'Pizza Margherita', quantita: 2, prezzo: 8.00 },
        'CocaCola': { title: 'Coca Cola 33cl', quantita: 2, prezzo: 3.00 },
        'Tiramisu': { title: 'Tiramisù', quantita: 1, prezzo: 5.00 }
      },
      data: new Date().getTime()
    },
    {
      id: 'gl002',
      numero: 'GL-456',
      nomeCliente: 'Giulia Bianchi',
      piattaforma: 'Glovo',
      statoPagato: 1,
      stato: 'In attesa',
      bloccato: false,
      accettato: false,
      totale: 28.50,
      carrello: {
        'Diavola': { title: 'Pizza Diavola', quantita: 1, prezzo: 10.00 },
        'Patatine': { title: 'Patatine Fritte', quantita: 2, prezzo: 4.50 }
      },
      data: new Date().getTime()
    },
    {
      id: 'ue003',
      numero: 'UE-789',
      nomeCliente: 'Luca Verdi',
      piattaforma: 'Uber Eats',
      statoPagato: 1,
      stato: 'In attesa',
      bloccato: false,
      accettato: false,
      totale: 42.00,
      carrello: {
        'Carbonara': { title: 'Pasta Carbonara', quantita: 2, prezzo: 12.00 },
        'Insalata': { title: 'Insalata Mista', quantita: 1, prezzo: 6.00 }
      },
      data: new Date().getTime()
    },
    {
      id: 'de004',
      numero: 'DE-101',
      nomeCliente: 'Sofia Russo',
      piattaforma: 'Deliveroo',
      statoPagato: 1,
      stato: 'In attesa',
      bloccato: false,
      accettato: false,
      totale: 33.00,
      carrello: {
        'Sushi': { title: 'Sushi Mix', quantita: 1, prezzo: 25.00 },
        'Edamame': { title: 'Edamame', quantita: 2, prezzo: 4.00 }
      },
      data: new Date().getTime()
    }
  ];

  portata = 0;
  imagePaths: { [key: string]: Promise<string> } = {};
  categorie = [];
  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  private destroy$ = new Subject<void>();
  private authUnsubscribe: (() => void) | null = null;
  private posTransactionCleanup: (() => void) | null = null;
  private permessiUnsubscribe: (() => void) | null = null;
  fonteSelected = 'Cassa';
  pagatoSelected = 'Non Pagato';
  lista = 1;
  pagamentoCartaAperto = 0;
  cashmaticAperto = 0;
  filtroSource = 2;
  filtroStato = 1;
  menuBello = [];
  sezione = -1;
  display = 'none';
  schermata = 0;
  schermataIndex: number;
  tastierino = [7, 8, 9, 4, 5, 6, 1, 2, 3];
  closeResult = '';
  prezzoPiatto = '0';
  user: any;
  ristorante: any;
  carrello: any;
  numeroElementi = 0;
  tot = 0;
  isOver = false;
  isSwipingOrder = false;
  sliderWidthStyle = {};
  tavoliDaUnire: string[] = [];
  modalitaUnione = false;
  modalitaSpostamento = false;
  tavoloDaSpostare = '';
  idRistorante = '';
  camerieri = [];
  menu = [];
  carrello2 = {};
  eliminazione = false;
  filter = '';
  selectedOrder: any = null;
  ordineOrdine = '';
  ordine = [];
  tavoli: Array<any> = [];

  sale: Array<any> = [];
  salaSelezionata: string = '';

  constructor(
      private router: Router,
      private userService: UserDataService,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private paymentCashmaticService: PaymentCashmaticService,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private tavoliService: TavoliService,
      private dailyReceiptService: DailyReceiptService,
      private authService: AuthService,
      private cdr: ChangeDetectorRef
  ) {}

  // Lifecycle hooks
  ngOnInit(): void {
    this.initializeApp();
  }

  ngOnDestroy(): void {
    this.cleanupResources();
  }

  private loadSales(): void {
    const ristoranteId = this.dataService.getRistorante1();

    this.tavoliService.getSale(ristoranteId).subscribe((sale) => {
      this.sale = sale || [];

      // Se non ci sono sale, imposta una sala predefinita
      if (this.sale.length === 0) {
        console.log('Nessuna sala trovata, usando sala predefinita');
        // Usa una sala predefinita solo localmente, senza salvarla
        this.sale = [{
          id: 'default',
          nome: 'Sala principale'
        }];
      }

      // Imposta la prima sala come selezionata se non ne è già selezionata una
      if (!this.salaSelezionata || !this.sale.some(s => s.id === this.salaSelezionata)) {
        this.salaSelezionata = this.sale[0].id;
      }

      // Carica i tavoli della sala corrente
      this.loadTavoli();
    });
  }

  private loadTavoli(): void {
    const ristoranteId = this.dataService.getRistorante1();

    this.tavoliService.getTavoli(ristoranteId, this.salaSelezionata).subscribe((tavoli) => {
      this.tavoli = this.sortTavoli(tavoli || []);
    });
  }

  cambiareSala(idSala: string): void {
    if (this.salaSelezionata !== idSala) {
      this.salaSelezionata = idSala;
      this.loadTavoli();

      // Resetta stato unione e spostamento quando si cambia sala
      this.modalitaUnione = false;
      this.modalitaSpostamento = false;
      this.tavoliDaUnire = [];
      this.tavoloDaSpostare = '';
    }
  }

// Metodo per ottenere solo i tavoli della sala corrente
  getTavoliSalaCorrente(): Array<any> {
    if (!this.salaSelezionata || !this.tavoli) {
      return [];
    }

    // Filtra i tavoli per la sala corrente
    return this.tavoli.filter(tavolo =>
        !tavolo.salaId || tavolo.salaId === this.salaSelezionata
    );
  }

  async reopenLastOrder(): Promise<void> {
    try {
      // Filtra gli ordini non pagati (statoPagato = 1 o 2)
      const ordiniNonPagati = this.camerieri.filter(ordine =>
          ordine.statoPagato === 1 || ordine.statoPagato === 2 // Non pagato o parzialmente pagato
      );

      // Se ci sono ordini non pagati
      if (ordiniNonPagati && ordiniNonPagati.length > 0) {
        // Trova l'ordine con il numero più alto (ultimo aggiunto)
        const ultimoOrdine = ordiniNonPagati.reduce((max, ordine) => {
          const numeroOrdine = parseInt(ordine.ordine);
          return (isNaN(numeroOrdine) || numeroOrdine <= parseInt(max.ordine)) ? max : ordine;
        }, ordiniNonPagati[0]);

        // Naviga all'ordine trovato
        this.cambiaSchermata(ultimoOrdine.id);
      } else {
        // Non ci sono ordini non pagati, quindi crea un nuovo ordine
        console.log('Nessun ordine non pagato disponibile, creazione nuovo ordine');
        await this.aggiungiOrdine();
      }
    } catch (error) {
      console.error('Errore durante la riapertura dell\'ultimo ordine:', error);
      // In caso di errore, prova comunque a creare un nuovo ordine
      try {
        await this.aggiungiOrdine();
      } catch (innerError) {
        console.error('Errore anche nella creazione di un nuovo ordine:', innerError);
      }
    }
  }

  // Metodi di inizializzazione
  private initializeApp(): void {
    // Verifica se esiste un utente selezionato con dati validi
    const userLocale = this.dataServiceGenerico.getUserLocale() as UserLocale;
    console.log('user locale', userLocale);

    // Verifica se l'oggetto utente ha le proprietà necessarie
    if (!userLocale || Object.keys(userLocale).length === 0 || (!userLocale.nome && !userLocale.username)) {
      console.log('Nessun utente selezionato. Verifico se esistono utenti nel ristorante...');

      // Prima di reindirizzare, verifica che il ristorante abbia degli utenti
      this.checkIfUsersExistAndRedirect();
      return; // Interrompe l'inizializzazione del componente
    }

    // Continua con l'inizializzazione normale se l'utente esiste
    this.loadViewPreference();
    this.setupIpcListeners();
    this.setupAuthListener();
    this.setupSubcriptions();

    // Carica le sale
    this.loadSales();
  }

  /**
   * Verifica se esistono utenti nel ristorante e reindirizza in base al risultato
   */
  private async checkIfUsersExistAndRedirect() {
    const ristoranteId = await this.dataServiceGenerico.getCurrentRestaurantId();

    if (!ristoranteId) {
      console.error('ID ristorante non disponibile');
      return;
    }

    // Accedi a Firestore per verificare se ci sono utenti nel ristorante
    firebase.firestore()
        .collection('ristoranti')
        .doc(ristoranteId)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data();
            const hasUsers = data && data.organico && data.organico.length > 0;

            if (hasUsers) {
              console.log('Utenti trovati nel ristorante. Reindirizzamento a select-user.');
              this.router.navigate(['select-user'], { fragment: 'nonav' });
            } else {
              console.log('Nessun utente disponibile nel ristorante. Proseguo senza redirect.');
              // Continua con l'inizializzazione base senza utente
              this.loadViewPreference();
              this.setupIpcListeners();
              this.setupAuthListener();
              this.setupSubcriptions();
            }
          } else {
            console.log('Documento ristorante non trovato. Proseguo senza redirect.');
            // Continua con l'inizializzazione base senza utente
            this.loadViewPreference();
            this.setupIpcListeners();
            this.setupAuthListener();
            this.setupSubcriptions();
          }
        })
        .catch(error => {
          console.error('Errore nel controllo degli utenti:', error);
          // In caso di errore, continua comunque con l'inizializzazione base
          this.loadViewPreference();
          this.setupIpcListeners();
          this.setupAuthListener();
          this.setupSubcriptions();
        });
  }

  private setupIpcListeners(): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.on('navigate-to', (_, route: string) => {
        console.log('Navigazione richiesta verso:', route);
        this.router.navigate([route], { fragment: 'nonav' });
      });
    }
  }

  private setupAuthListener(): void {
    // Utilizziamo l'auth service invece di chiamate dirette a Firebase
    this.authService.user$
        .pipe(
            takeUntil(this.destroy$),
            filter(user => !!user),
            switchMap(user => {
              // Verifica se esiste un utente locale selezionato
              const userLocale = this.dataServiceGenerico.getUserLocale();
              if (!userLocale) {
                // Reindirizza alla pagina di selezione utente
                this.router.navigate(['select-user'], { fragment: 'nonav' });
                return of(null); // Interrompi il flusso
              }
              return this.initializeUserData(user);
            })
        )
        .subscribe({
          next: (result) => {
            // Se result è null, significa che è stato reindirizzato
            if (result) {
              console.log('Dati utente inizializzati con successo');
            }
          },
          error: (error) => {
            console.error('Errore durante l\'inizializzazione dei dati utente:', error);
          }
        });

    // Manteniamo anche il listener diretto per la retrocompatibilità
    this.authUnsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // Verifica se esiste un utente locale selezionato
        const userLocale = this.dataServiceGenerico.getUserLocale();
        if (!userLocale) {
          this.router.navigate(['select-user'], { fragment: 'nonav' });
          return;
        }

        this.user = this.dataServiceGenerico.getUser();
        this.setupPosTransaction();
      }
    });
  }

  private initializeUserData(user: any): Observable<any> {


    const ristoranteId2 = this.dataService.getRistorante1();

    const cachedPermessi = this.dataServiceGenerico.getPermessiFromCache(ristoranteId2);

    if (cachedPermessi) {
      this.updatePermissions(cachedPermessi);
    }

    return from(this.dataServiceGenerico.loadAvailableRestaurants()).pipe(
        switchMap(() => {
          const ristoranteId = this.dataService.getRistorante1();
          if (!ristoranteId) {
            return throwError('ID ristorante non disponibile');
          }

          // Carica i permessi e configura i tavoli
          this.getPermessiRistorante(ristoranteId);

          // Combine multiple data loads into a single stream
          return combineLatest([
            this.tavoliService.getTavoli(ristoranteId).pipe(
                tap(tavoli => {
                  this.tavoli = this.sortTavoli(tavoli);
                })
            ),
            this.loadCarrelloData(),
            this.loadMenuData()
          ]);
        }),
        catchError(error => {
          console.error('Errore durante l\'inizializzazione:', error);
          return of(null);
        })
    );
  }

  private setupPosTransaction(): void {
    this.posTransactionCleanup = this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        this.handlePaymentSuccess();
      } else if (response.status === 'failure' || response.status === 'error') {
        this.handlePaymentFailure();
      }
    });
  }

  private setupSubcriptions(): void {
    // Subscription per il servizio di pagamento
    this.subscriptions.push(
        this.paymentCashmaticService.soldiInseriti$
            .pipe(takeUntil(this.destroy$))
            .subscribe(soldiInseriti => this.soldiInseriti = soldiInseriti),

        this.paymentCashmaticService.paymentSuccess$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.handlePaymentSuccess()),

        this.paymentCashmaticService.paymentError$
            .pipe(takeUntil(this.destroy$))
            .subscribe(error => this.paymentMessage = 'Errore durante il pagamento: ' + error),

        // Subscription per aggiornamenti dei camerieri
        this.dataServiceGenerico.camerieriSubjectItem$
            .pipe(takeUntil(this.destroy$))
            .subscribe(item => {
              if (item !== 0) {
                this.camerieri = item;
              }
            }),

        // Subscription per aggiornamenti degli ordini
        this.dataServiceGenerico.OrdineSubjectItem$
            .pipe(takeUntil(this.destroy$))
            .subscribe(item => {
              if (item !== 0) {
                this.commonService.updateOrdine(item.id, item);
              }
            }),

        // Subscription per aggiornamenti dei dati utente
        this.userService.emitter
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.retriveCarrello();
              this.retriveMenu();
            })
    );
  }

  private cleanupResources(): void {
    // Complete the subject
    this.destroy$.next();
    this.destroy$.complete();

    // Clean up subscriptions
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    // Clean up event handlers
    [
      this.posTransactionCleanup,
      this.authUnsubscribe,
      this.permessiUnsubscribe
    ].forEach(cleanup => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    });
  }

  // Metodi per il caricamento dei dati
  private loadCarrelloData(): Observable<any> {
    const ristoranteId = this.dataServiceGenerico.getRistorante();
    return this.commonService.getOrdini(
        ristoranteId,
        this.filtroSource,
        this.filtroStato
    ).pipe(
        tap(dataCamerieri => {
          if (dataCamerieri && dataCamerieri.length > 0) {
            this.camerieri = dataCamerieri;
            this.dataServiceGenerico.setCamerieri(this.camerieri);

            // Se non c'è un ordine selezionato, seleziona il primo
            if (!this.selectedOrder) {
              this.selectedOrder = this.camerieri[0];
            }
          } else {
            this.camerieri = [];
            this.dataServiceGenerico.setCamerieri(this.camerieri);
          }
        }),
        catchError(error => {
          console.error('Errore nel caricamento dei dati carrello:', error);
          return of([]);
        })
    );
  }

  private loadMenuData(): Observable<any> {
    const restaurantName = this.dataServiceGenerico.getRistorante();
    return from(
        firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName)
            .get()
    ).pipe(
        map(doc => {
          if (doc.exists) {
            const data = doc.data();
            return data.items || [];
          }
          return [];
        }),
        tap(menuItems => {
          this.menuBello = menuItems.filter(elemento =>
              elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0
          );
          this.categorie = this.filterUniqueCategories(this.menuBello);
          this.processMenu();
        }),
        catchError(error => {
          console.error('Errore nel recupero del menu da Firebase:', error);
          return of([]);
        })
    );
  }

  private getPermessiRistorante(ristoranteId: string): void {
    // Prima controlla la cache
    const cachedPermessi = this.dataServiceGenerico.getPermessiFromCache(ristoranteId);

    if (cachedPermessi) {
      this.updatePermissions(cachedPermessi);
    }

    // Configura listener per i cambiamenti
    this.permessiUnsubscribe = this.dataServiceGenerico.setupPermessiListener(
        ristoranteId,
        (permessi) => this.updatePermissions(permessi)
    );
  }

  // Check if the user has a specific permission
  hasPermission(permissionName: string): boolean {
    // Never filter 'Cassa' permission
    if (permissionName.toLowerCase() === 'cassa') {
      return true;
    }

    const userLocale = this.dataServiceGenerico.getUserLocale();
    if (!userLocale || !userLocale['permessi']) {
      return false;
    }

    // List of navbar-related permissions
    const navbarPermissions = [
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity',
      'Impostazioni'

    ];

    // Case-insensitive permission check
    const hasPermission = userLocale['permessi'].some(
      permission => permission.toLowerCase() === permissionName.toLowerCase()
    );

    // If it's a navbar permission, check if it exists in user permissions
    if (navbarPermissions.includes(permissionName)) {
      return hasPermission;
    }

    // For all other permissions, return the normal check result
    return hasPermission;
  }

  hasAnyPermission(permissionNames: string[]): boolean {
    // Never filter 'Cassa' permission - returns true if 'cassa' is in requested permissions
    if (permissionNames.some(permission => permission.toLowerCase() === 'cassa')) {
      return true;
    }

    const userLocale = this.dataServiceGenerico.getUserLocale();
    if (!userLocale || !userLocale['permessi']) {
      return false;
    }

    // List of navbar-related permissions
    const navbarPermissions = [
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity',
        'Impostazioni',

      'Annulla Scontrino',
      "Totali",
      "Chiusura Giornaliera",
    ];

    // Check if at least one of the requested permissions exists in user permissions (case-insensitive)
    const userPermissions = userLocale['permessi'].map(p => p.toLowerCase());

    // Special handling for navbar permissions
    const requestedNavbarPermissions = permissionNames.filter(p => navbarPermissions.includes(p));

    // If all requested permissions are navbar permissions, check if user has at least one
    if (requestedNavbarPermissions.length === permissionNames.length) {
      return permissionNames.some(permission =>
          userPermissions.includes(permission.toLowerCase())
      );
    }

    // For non-navbar permissions or mixed permissions, check if user has at least one
    return permissionNames.some(permission =>
        userPermissions.includes(permission.toLowerCase())
    );
  }

  private updatePermissions(permessi: string[]): void {
    this.permessiRistorante = permessi;
    this.mostraTavoli = this.permessiRistorante.includes('Tavoli');
    this.updateSliderWidth();
  }

  // Metodi di gestione dei dati
  private processMenu(): void {
    this.menu = [];

    // Crea struttura menu basata su categorie
    this.categorie.forEach(categoria =>
        this.menu.push({
          testo: categoria,
          menu: []
        })
    );

    // Popola ogni categoria con i prodotti relativi
    this.menu.forEach((categoria, i) => {
      this.menuBello.forEach((prodotto) => {
        if (categoria.testo == prodotto.category) {
          this.menu[i].menu.push(prodotto);
        }
      });
    });
  }

  private initializeImagePaths(): void {
    this.menu.forEach(categoria => {
      categoria.menu.forEach(item => {
        this.imagePaths[item.title] = this.ipcService.getLocalImage(item.image_link);
      });
    });
  }

  // Metodi di gestione pagamenti
  private handlePaymentSuccess(): void {
    this.paymentMessage = 'Pagamento completato con successo!';
    this.schermata = 1;
    this.pagamentoCartaAperto = 0;
    this.cashmaticAperto = 0;

    if (this.camerieri[this.getIndex()]) {
      this.camerieri[this.getIndex()].statoPagato = 0;
      this.commonService.updateOrdine(
          this.camerieri[this.getIndex()].id,
          this.camerieri[this.getIndex()]
      );
    }
  }

  private handlePaymentFailure(): void {
    this.schermata = 1;
    this.pagamentoCartaAperto = 0;
  }

  // Metodi di utilità
  private sortTavoli(tavoli: any[]): any[] {
    return tavoli.sort((a, b) => {
      const valA = String(a.numero);
      const valB = String(b.numero);

      // Priorità a "Banco"
      if (valA === 'Banco') return -1;
      if (valB === 'Banco') return 1;

      const firstCharA = valA.charAt(0);
      const firstCharB = valB.charAt(0);

      // Priorità ai numeri rispetto alle lettere
      const aIsNumber = !isNaN(parseInt(firstCharA));
      const bIsNumber = !isNaN(parseInt(firstCharB));

      if (aIsNumber && !bIsNumber) return -1;
      if (!aIsNumber && bIsNumber) return 1;

      // Per i tavoli con lettere (es. A1, B2, etc.)
      if (!aIsNumber && !bIsNumber) {
        if (firstCharA !== firstCharB) {
          return firstCharA.localeCompare(firstCharB);
        }
        const numA = parseInt(valA.substring(1));
        const numB = parseInt(valB.substring(1));
        return numA - numB;
      }

      // Ordinamento numerico
      return parseInt(valA) - parseInt(valB);
    });
  }

  updateSliderWidth(): void {
    // Calcola il numero totale di tab visibili
    let numTabs = 1; // Per default, sempre presente "Scontrini" e "Delivery"
    if (this.mostraTavoli) {
      numTabs += 2; // Aggiungi "Tavoli" e "Sala" se visibili
    }

    // Imposta lo stile con la larghezza calcolata
    this.sliderWidthStyle = {
      'width': `${100 / numTabs}%`
    };
  }

  // Metodi pubblici per l'interfaccia utente
  getProperties(object: any) {
    if (object !== undefined) {
      return Object.keys(object);
    }
    return [];
  }

  getPrezzoPiatto(piatto: any): number {
    if (piatto?.hasOwnProperty('aggiunte')) {
      return piatto.prezzo + piatto.aggiunte.length;
    }
    return piatto?.prezzo || 0;
  }

  async changeView(viewIndex: number): Promise<void> {
    this.lista = viewIndex;
    await this.saveViewPreference(viewIndex);
    this.updateSliderWidth();
  }

  private async loadViewPreference(): Promise<void> {
    try {
      this.lista = await this.dataServiceGenerico.getViewPreference();
    } catch (error) {
      console.error('Error loading view preference:', error);
    }
  }

  private async saveViewPreference(view: number): Promise<void> {
    try {
      await this.dataServiceGenerico.saveViewPreference(view);
    } catch (error) {
      console.error('Error saving view preference:', error);
    }
  }

  filterUniqueCategories(menuItems: any[]): string[] {
    const seenCategories = new Set();
    return menuItems
        .filter(item => {
          if (seenCategories.has(item.category)) {
            return false;
          } else {
            seenCategories.add(item.category);
            return true;
          }
        })
        .map(x => x.category)
        .filter(element => element !== undefined);
  }

  // Metodi per la gestione degli ordini
  async aggiungiOrdine(tavolo?: any): Promise<void> {
    // Check if user has permission for either Preconto or Cassa
    // Both these permissions would likely allow creating new orders
    if (!this.hasPermission('Preconto') && !this.hasPermission('Cassa')) {
      console.error('User does not have permission to create new orders');
      return;
    }

    let priceListType: 'counter' | 'takeaway' | 'table' = 'counter'; // Default tipo
    let displayNamePrefix = 'Banco';

    if (tavolo) {
      priceListType = 'table';
      displayNamePrefix = 'Tavolo';
    }

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        this.dataServiceGenerico.getRistorante1()
    );

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: tavolo ? `${displayNamePrefix} ${tavolo.numero}` : `${displayNamePrefix} #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      tavolo: tavolo ? tavolo.numero.toString() : null,
      selectedPriceListType: priceListType,
      salaId: tavolo && tavolo.salaId ? tavolo.salaId : this.salaSelezionata // Aggiungi l'ID della sala
    };

    this.camerieri.unshift(ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);
    this.cambiaSchermata(ordine.id);
  }

  getCopertiTavolo(ordine: any): number {
    if (!ordine || !ordine.carrello) {
      return 0;
    }

    // Look for a product named 'Coperti' in the cart
    const keys = Object.keys(ordine.carrello);
    for (const key of keys) {
      const prodotto = ordine.carrello[key];

      // Check if the product is named 'Coperti' (case insensitive)
      if (prodotto &&
          (prodotto.title === 'Coperti' ||
              prodotto.title?.toLowerCase() === 'coperti' ||
              key.toLowerCase() === 'coperti')) {

        return prodotto.quantita || 0;
      }
    }

    return 0;
  }

  async addTakeawayOrder(): Promise<void> {
    // Check if user has permission for either Preconto or Cassa
    if (!this.hasPermission('Preconto') && !this.hasPermission('Cassa')) {
      console.error('User does not have permission to create takeaway orders');
      return;
    }
    
    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    const nextReceiptNumber = await this.dailyReceiptService.getNextReceiptNumber(
        this.dataServiceGenerico.getRistorante1()
    );

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: `Asporto #${nextReceiptNumber}`,
      ordine: nextReceiptNumber,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      selectedPriceListType: 'takeaway'
    };

    this.camerieri.unshift(ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);
    this.cambiaSchermata(ordine.id);
  }

  eliminaOrdine(ordine: any): void {
    // Check if user has permission to delete orders
    if (!this.hasPermission('Elimina Ordine')) {
      console.error('User does not have permission to delete orders');
      return;
    }
    
    const index = this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine);
    if (index !== -1) {
      this.commonService.deleteOrdine(this.camerieri[index].id);
      this.camerieri.splice(index, 1);
      this.dataServiceGenerico.setCamerieri(this.camerieri);
    }
  }

  // Metodi di navigazione
  cambiaSchermata(idOrdine: any): void {
    this.router.navigate(['/cassa/order', idOrdine], { fragment: 'nonav' });
  }

  // Metodi per la gestione dei tavoli
  findTavolo(tavolo: any): any {
    // Verifica se camerieri è definito e non è vuoto
    if (this.camerieri && this.camerieri.length > 0) {
      let index = this.camerieri.findIndex(c => c.tavolo == tavolo);
      if (index > -1) {
        return this.camerieri[index];
      }
    }
    return null;
  }

  findIndexTavolo(tavolo: any): any {
    let index = this.camerieri.findIndex(c => c.tavolo == tavolo);
    if (index > -1) {
      return index;
    } else {
      this.aggiungiOrdine({numero: tavolo});
    }
  }

  selezionaTavoloPerUnione(numeroTavolo: string): void {
    if (this.modalitaUnione) {
      const index = this.tavoliDaUnire.indexOf(numeroTavolo);
      if (index > -1) {
        this.tavoliDaUnire.splice(index, 1);
      } else if (this.tavoliDaUnire.length < 2) {
        this.tavoliDaUnire.push(numeroTavolo);
      }

      if (this.tavoliDaUnire.length === 2) {
        this.unisciTavoli();
      }
    } else if (this.modalitaSpostamento) {
      if (this.tavoloDaSpostare == '') {
        const ordineEsistente = this.findTavolo(numeroTavolo);
        if (ordineEsistente) {
          this.tavoloDaSpostare = numeroTavolo;
        }
      } else {
        const ordineOrigine = this.findTavolo(this.tavoloDaSpostare);
        if (ordineOrigine) {
          // Aggiorna il tavolo e il nome display
          ordineOrigine.tavolo = numeroTavolo;
          ordineOrigine.displayName = `Tavolo ${numeroTavolo}`;

          // Aggiorna nel database
          this.commonService.updateOrdine(ordineOrigine.id, ordineOrigine);
        }

        // Reset dello stato
        this.tavoloDaSpostare = '';
        this.modalitaSpostamento = false;
      }
    } else {
      // Gestione normale del click sul tavolo
      const tavolo = this.findTavolo(numeroTavolo);
      if (tavolo) {
        this.cambiaSchermata(tavolo.id);
      } else {
        this.findIndexTavolo(numeroTavolo);
      }
    }
  }

  unisciTavoli(): void {
    if (this.tavoliDaUnire.length !== 2) {
      console.error('Seleziona esattamente due tavoli da unire');
      return;
    }

    const [primoTavolo, secondoTavolo] = this.tavoliDaUnire;
    const ordinePrimoTavolo = this.camerieri.find(c => c.tavolo === primoTavolo);
    const ordineSecondoTavolo = this.camerieri.find(c => c.tavolo === secondoTavolo);

    if (ordinePrimoTavolo && ordineSecondoTavolo) {
      // Unisci nel secondo tavolo selezionato
      if (!ordineSecondoTavolo.carrello) {
        ordineSecondoTavolo.carrello = {};
      }

      if (ordinePrimoTavolo.carrello) {
        // Unione dei prodotti
        this.mergeCarrelli(ordinePrimoTavolo, ordineSecondoTavolo);
      }

      // Aggiorna il database
      this.commonService.updateOrdine(ordineSecondoTavolo.id, ordineSecondoTavolo);
      this.eliminaOrdine(ordinePrimoTavolo.ordine);

      this.tavoliDaUnire = [];
      this.modalitaUnione = false;
    }
  }

  private mergeCarrelli(ordineSorgente: any, ordineDestinazione: any): void {
    // Unione dei prodotti
    Object.keys(ordineSorgente.carrello).forEach(prodottoKey => {
      if (ordineDestinazione.carrello[prodottoKey]) {
        // Se il prodotto esiste già, somma le quantità
        ordineDestinazione.carrello[prodottoKey].quantita +=
            ordineSorgente.carrello[prodottoKey].quantita;
      } else {
        // Altrimenti, aggiungi il prodotto
        ordineDestinazione.carrello[prodottoKey] = {
          ...ordineSorgente.carrello[prodottoKey]
        };
      }
    });

    // Gestione ordineInserimento
    if (ordineSorgente.ordineInserimento) {
      if (!ordineDestinazione.ordineInserimento) {
        ordineDestinazione.ordineInserimento = [];
      }
      ordineSorgente.ordineInserimento.forEach(prodotto => {
        if (!ordineDestinazione.ordineInserimento.includes(prodotto)) {
          ordineDestinazione.ordineInserimento.push(prodotto);
        }
      });
    }
  }

  attivaModalitaUnione(): void {
    this.modalitaUnione = true;
    this.modalitaSpostamento = false;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
    this.tavoloDaSpostare = '';
  }

  attivaModalitaSpostamento(): void {
    this.modalitaSpostamento = true;
    this.modalitaUnione = false;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
    this.tavoloDaSpostare = '';
  }

  liberaTavolo(numeroTavolo: string): void {
    const ordine = this.camerieri.find(c => c.tavolo === numeroTavolo);
    if (ordine) {
      this.eliminaOrdine(ordine.ordine);
      console.log(`Tavolo ${numeroTavolo} liberato con successo`);
    } else {
      console.error(`Nessun ordine trovato per il tavolo ${numeroTavolo}`);
    }
  }

  getTavoloBorder(tavolo: any): string {
    if (this.modalitaUnione && this.tavoliDaUnire.includes(tavolo.numero.toString())) {
      return '2px solid #E55933';
    }
    if (this.modalitaSpostamento) {
      if (this.tavoloDaSpostare === tavolo.numero.toString()) {
        return '2px solid #4CAF50'; // Verde per il tavolo origine
      }
      if (!this.findTavolo(tavolo.numero)) {
        return '2px dashed #2196F3'; // Blu tratteggiato per tavoli disponibili
      }
    }
    return tavolo.unito ? '1px solid red' : '1px solid #ccc';
  }

  // Metodi per la gestione degli ordini delivery
  accettaOrdine(ordine: any): void {
    const index = this.ordiniDelivery.findIndex(o => o.id === ordine.id);
    if (index !== -1) {
      this.ordiniDelivery[index].accettato = true;
      this.ordiniDelivery[index].stato = 'Accettato';
    }
  }

  evadiOrdine(ordine: any): void {
    const index = this.ordiniDelivery.findIndex(o => o.id === ordine.id);
    if (index !== -1) {
      this.ordiniDelivery[index].statoPagato = 0;
      this.ordiniDelivery[index].stato = 'Evaso';
    }
  }

  // Metodi per il calcolo di prezzi e totali
  calcolaTotale(): number {
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0 && this.camerieri[this.getIndex()] !== undefined) {
      const index = this.getIndex();
      const carrello = this.camerieri[index]?.carrello;

      if (carrello) {
        Object.keys(carrello).forEach(piatto => {
          this.numeroElementi += carrello[piatto].quantita;
          const prezzoPiatto = this.getPrezzoPiatto(carrello[piatto]);
          this.tot += (prezzoPiatto * carrello[piatto].quantita);
        });
      }
    }

    return this.tot;
  }

  calcolaTotaleOrdine(ordine: any): string {
    // Reset contatori
    this.numeroElementi = 0;
    this.tot = 0;

    if (!ordine) {
      return "0.00";
    }

    // Trova l'ordine corrispondente
    const ordineAttuale = this.camerieri.find(cam => {
      // Verifica sia ordine sia id per supportare diverse fonti
      return (cam.ordine == ordine || cam.id == ordine);
    });

    if (!ordineAttuale || !ordineAttuale.carrello) {
      return "0.00";
    }

    // Calcola il totale
    Object.keys(ordineAttuale.carrello).forEach(nomeProdotto => {
      const prodotto = ordineAttuale.carrello[nomeProdotto];

      if (prodotto && prodotto.quantita) {
        this.numeroElementi += prodotto.quantita;

        // Usa prezzo o price
        const prezzoProdotto = prodotto.prezzo || prodotto.price || 0;
        this.tot += (prezzoProdotto * prodotto.quantita);
      }
    });

    return this.tot.toFixed(2);
  }

  calcolaTotaleOrdine2(ordine: any): string {
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0 && ordine !== null) {
      const index = this.getIndexOrdine(ordine);
      if (index !== -1 && this.camerieri[index].hasOwnProperty('carrello')) {
        const carrello = this.camerieri[index].carrello;

        Object.keys(carrello).forEach(piatto => {
          this.numeroElementi += carrello[piatto].quantita;
          const prezzoPiatto = carrello[piatto].prezzo;
          this.tot += (prezzoPiatto * carrello[piatto].quantita);
        });
      }
    }

    return this.tot.toFixed(2);
  }

  // Metodi di utilità per la gestione del tempo
  getElapsedTime(orderDate: string, closureOrderTime?: string): string {
    if (!orderDate) return '00:00:00';

    const startTime = new Date(parseInt(orderDate));
    const endTime = closureOrderTime ? new Date(parseInt(closureOrderTime)) : new Date();
    const diff = endTime.getTime() - startTime.getTime();

    // Calcola ore, minuti e secondi
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    // Formatta il risultato come H:mm:ss
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  // Metodi per gestione indici
  getIndex(): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == this.ordineOrdine);
  }

  getIndexOrdine(ordine: any): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine);
  }

  // Metodi per il recupero dati
  retriveCarrello(): void {
    // Carica i dati utente
    if (firebase.auth().currentUser) {
      this.userService.getDoc(firebase.auth().currentUser.uid).get()
          .pipe(takeUntil(this.destroy$))
          .subscribe(data => {
            if (data.data() !== undefined) {
              this.user = data.data();
              if (this.user.hasOwnProperty('preferiti')) {
                this.user['preferiti'] = this.user['preferiti'].filter(preferito =>
                    this.menuBello.some(piatto => piatto.title == preferito.title)
                );
              }
            }
          });
    }

    // Controllo se il ristorante è impostato
    const ristoranteId = this.dataServiceGenerico.getRistorante();
    if (!ristoranteId) {
      // Se non c'è ristorante, caricalo prima
      this.dataServiceGenerico.loadAvailableRestaurants()
          .then(() => this.loadOrdiniByFilter());
    } else {
      // Altrimenti carica direttamente
      this.loadOrdiniByFilter();
    }
  }

  private loadOrdiniByFilter(): void {
    const ristoranteId = this.dataServiceGenerico.getRistorante();
    this.commonService.getOrdini(ristoranteId, this.filtroSource, this.filtroStato)
        .pipe(
            takeUntil(this.destroy$),
            catchError(error => {
              console.error('Errore nel caricamento degli ordini:', error);
              return of([]);
            })
        )
        .subscribe(dataCamerieri => {
          if (dataCamerieri && dataCamerieri.length > 0) {
            this.camerieri = dataCamerieri;
            this.dataServiceGenerico.setCamerieri(this.camerieri);

            // Se non c'è un ordine selezionato, seleziona il primo
            if (!this.selectedOrder) {
              this.selectedOrder = this.camerieri[0];
            }

            // Aggiorna le quantità del menù
            this.updateMenuQuantities();
          } else {
            this.camerieri = [];
            this.dataServiceGenerico.setCamerieri(this.camerieri);
          }
        });
  }

  private updateMenuQuantities(): void {
    // Per ogni categoria nel menu, verifica se c'è un carrello e aggiorna le quantità
    this.menu.forEach(categoria => {
      if (categoria.hasOwnProperty('menu')) {
        categoria.menu.forEach(prodotto => {
          if (prodotto.hasOwnProperty('id') && this.carrello?.carrello) {
            this.carrello.carrello[prodotto.id] = { quantita: 0 };
          }
        });
      }
    });
  }

  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();

    // Se non c'è ristorante selezionato, non procedere
    if (!restaurantName) return;

    db.collection('menu_test').doc(restaurantName).get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            this.menuBello = (data?.items || [])
                .filter(elemento => elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0);

            this.categorie = this.filterUniqueCategories(this.menuBello);
            this.processMenu();
          } else {
            console.log('Nessun documento trovato su Firebase.');
          }
        })
        .catch(error => {
          console.error('Errore nel recupero del menu da Firebase:', error);
        });
  }

  // Metodi per la stampa e il pagamento
  printReceipt(cassetto: string): void {
    if (!this.camerieri[this.getIndex()]) return;

    let stringa = [];
    this.schermata = 1;

    // Raccolta prodotti dal carrello
    this.getProperties(this.camerieri[this.getIndex()]?.carrello)?.forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    );

    // Generazione e stampa scontrino
    const contenutoScontrino = this.stampaScontrino(stringa);
    this.ipcService.printReceipt(contenutoScontrino, cassetto);

    // Aggiornamento stato pagamento
    if (cassetto != 'apriSolo' && cassetto != 'nonAprire') {
      this.camerieri[this.getIndex()].statoPagato = 0;
    }
    if (cassetto == 'nonAprire') {
      this.camerieri[this.getIndex()].statoPagato = 1;
    }

    // Salvataggio modifiche
    this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()]);
  }

  stampaScontrino(menu: any[]): string {
    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28;
    const spaceBetweenFields = ' '.repeat(2);
    let totale = 0;

    // Generazione righe prodotti
    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
      totale += totalePrezzo;

      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength);

      const quantita = item.quantita.toString().padStart(3);
      const prezzo = totalePrezzo.toFixed(2).padStart(6);
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%';

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    // Calcolo totali
    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    // Piè di pagina
    const footer = `
      -------------------------------
  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}
      -------------------------------
         GRAZIE ED ARRIVEDERCI
    `;

    return header + scontrino + footer;
  }

  sendTransaction(transactionAmount: number): void {
    let stringa = [];
    this.pagamentoCartaAperto = 1;

    // Raccolta prodotti dal carrello
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    );

    // Invio transazione
    const contenutoScontrino = this.stampaScontrino(stringa);
    this.posTransactionService.sendTransaction(transactionAmount, contenutoScontrino);
  }

  testPay(): void {
    this.cashmaticAperto = 1;
    let stringa = [];

    // Raccolta prodotti dal carrello
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    );

    // Test pagamento
    const totale = this.calcolaTotale();
    const contenutoScontrino = this.stampaScontrino(stringa);
    this.paymentCashmaticService.testPay(totale, contenutoScontrino);
  }

  annullaPagamento(): void {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  // Metodi per UI e azioni utente
  openOrderDetail(order: any): void {
    this.selectedOrder = order;
  }

  getStatusClass(status: number): string {
    switch(status) {
      case 0: return 'status-paid';
      case 1: return 'status-unpaid';
      default: return 'status-processing';
    }
  }

  getStatusText(status: number): string {
    switch(status) {
      case 0: return 'Pagato';
      case 1: return 'Non Pagato';
      default: return 'In Lavorazione';
    }
  }

  getFonteText(fonte: number): string {
    switch(fonte) {
      case 0: return 'App';
      case 1: return 'Kiosk';
      case 2: return 'Cassa';
      default: return 'Altro';
    }
  }

  getTitoloOrdine(): string {
    const ordineCorrente = this.camerieri[this.getIndex()];
    return ordineCorrente?.tavolo
        ? `Tavolo ${ordineCorrente.tavolo}`
        : `Ordine #${ordineCorrente?.ordine}`;
  }

  // Gestione filtri
  filtraApp(): void {
    this.filtroSource = 0;
    this.retriveCarrello();
  }

  filtraChiosco(): void {
    this.filtroSource = 1;
    this.retriveCarrello();
  }

  filtraCassa(): void {
    this.filtroSource = 2;
    this.retriveCarrello();
  }

  filtraTutto(): void {
    this.filtroSource = 3;
    this.retriveCarrello();
  }

  filtraPagato(): void {
    this.filtroStato = 0;
    this.retriveCarrello();
  }

  filtraNonPagato(): void {
    this.filtroStato = 1;
    this.retriveCarrello();
  }

  filtraCompletato(): void {
    this.filtroStato = 3;
    this.retriveCarrello();
  }

  filtraStatoTutto(): void {
    this.filtroStato = 2;
    this.retriveCarrello();
  }

  // Metodi di utilità e azioni UI
  handleEvent(event: number, piatto: any, piatto2?: string): void {
    const cameriere = this.camerieri[this.getIndex()];
    if (!cameriere || (cameriere.statoPagato !== undefined && cameriere.statoPagato === 0)) {
      return;
    }

    const nomePiatto = piatto2 || '';
    if (nomePiatto && cameriere.carrello[nomePiatto]) {
      cameriere.carrello[nomePiatto].quantita = event;

      // Se la quantità è zero, rimuovi il prodotto
      if (cameriere.carrello[nomePiatto].quantita === 0) {
        delete cameriere.carrello[nomePiatto];

        // Aggiorna anche l'ordine di inserimento se presente
        if (cameriere.ordineInserimento) {
          cameriere.ordineInserimento = cameriere.ordineInserimento.filter(item => item !== nomePiatto);
        }
      }
    }

    // Aggiorna il carrello
    if (this.camerieri[this.schermataIndex]) {
      this.dataServiceGenerico.updateCameriere2(
          this.camerieri[this.schermataIndex].ordine,
          cameriere.carrello
      );
    }
  }

  save(): void {
    if (this.camerieri[this.schermataIndex]?.carrello !== undefined) {
      this.dataServiceGenerico.updateCameriere2(
          this.camerieri[this.schermataIndex].ordine,
          this.camerieri[this.schermataIndex].carrello
      );
    }
  }

  testComande(): void {
    if (!this.camerieri[this.schermataIndex]?.carrello) return;

    // Raccolta prodotti dall'ordine
    let cose = [];
    const carrello = this.camerieri[this.schermataIndex].carrello;

    Object.keys(carrello).forEach((piatto) => {
      const piattoObj = carrello[piatto];
      if (piattoObj.hasOwnProperty('portata')
          ? piattoObj.portata == this.portata || this.portata == 4
          : this.portata == 4) {
        cose.push(piattoObj);
      }
    });

    // Filtraggio per destinazione
    const cucinaItems = cose.filter(item => item.selectedPrintDestination === 'cucina');
    const pizzeriaItems = cose.filter(item => item.selectedPrintDestination === 'pizzeria');
    const barItems = cose.filter(item => item.selectedPrintDestination === 'bar');
    const tavolo = this.camerieri[this.schermataIndex]?.tavolo;

    // Formattazione e invio
    this.stampaComandaPerDestinazione('pizzeria', pizzeriaItems, cucinaItems, tavolo, '192.168.123.103');
    this.stampaComandaPerDestinazione('cucina', cucinaItems, pizzeriaItems, tavolo, '192.168.123.102');
    this.stampaComandaPerDestinazione('bar', barItems, [], tavolo, '192.168.123.101');
  }

  private stampaComandaPerDestinazione(
      nome: string,
      itemsPrincipali: any[],
      itemsSecondari: any[],
      tavolo: string,
      stampanteIp: string
  ): void {
    if (itemsPrincipali.length === 0 && itemsSecondari.length === 0) return;

    let message = `${nome.toUpperCase()} \nTavolo: ${tavolo}\n\n`;

    // Raggruppa per portata
    const portate = [
      { items: itemsPrincipali.filter(i => i.portata === 0),
        others: itemsSecondari.filter(i => i.portata === 0),
        label: 'Prima portata' },
      { items: itemsPrincipali.filter(i => i.portata === 1),
        others: itemsSecondari.filter(i => i.portata === 1),
        label: 'Seconda portata' },
      { items: itemsPrincipali.filter(i => i.portata === 2),
        others: itemsSecondari.filter(i => i.portata === 2),
        label: 'Terza portata' },
      { items: itemsPrincipali.filter(i => i.portata === 3),
        others: itemsSecondari.filter(i => i.portata === 3),
        label: 'Quarta portata' }
    ];

    // Costruzione messaggio
    portate.forEach(portata => {
      if (portata.items.length > 0 || portata.others.length > 0) {
        message += `\n---${portata.label}---\n\n`;

        portata.items.forEach(item => {
          message += `<b>${item.quantita} x ${item.title}</b>\n`;
        });

        portata.others.forEach(item => {
          message += `${item.quantita} x ${item.title}\n`;
        });
      }
    });

    // Invio alla stampante
    this.ipcService.printReceipt(message, stampanteIp);
  }

  // Azioni di sistema
  apricassetto(): void {
    this.ipcService.printReceipt('', 'apriSolo', 'rch');
  }

  apriazioniStampanteDialog(): void {
    const dialogRef = this.dialog.open(AzioniStampanteComponent, {
      width: '645px',
      height: '490px',
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog chiuso', result);
    });
  }

  selectuser(): void {
    this.router.navigateByUrl('select-user#nonav');
  }

  logout(): void {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }
}