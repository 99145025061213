// receipt-page.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../shared/services/firestore/common.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-receipt-page',
    template: `
    <div class="main-content">
      <div class="print-content">
        <div class="container">
          <div class="grid receipt-box">
            
            <div class="full-width centered-text">
              <span>Zolon Technology</span>
            </div>
            <div class="full-width centered-text">
              <span>Strasse 42</span>
            </div>
            <div class="full-width centered-text">
              <span>0000 Düsseldorf</span>
            </div>
            <div class="full-width centered-text">
              <span>DE123456</span>
            </div>

            <!-- Stars/Rating -->
            <div class="feedback-box full-width border-small-top fat-text flex justify-center align-center" style="padding-top: 10px">
              <div class="rating flex justify-center">
                <ng-container *ngFor="let i of [5,4,3,2,1]">
                  <input type="radio" name="rating" [value]="i" [id]="i">
                  <label [for]="i" [title]="i + ' Stars'"></label>
                </ng-container>
              </div>
            </div>

            <!-- Receipt Number -->
            <div class="colspan-2 border-small-top fat-text" style="width: 110%">&nbsp;&nbsp;</div>
            <div class="colspan-2 border-small-top right-aligned-text">ft{{currentOrder?.ordine}}#</div>
            <div class="colspan-2 border-small-bottom">{{ getFormattedDate(currentOrder?.data) }}</div>
            <div class="colspan-2 border-small-bottom right-aligned-text"></div>

            <!-- Items -->
            <ng-container *ngFor="let item of getOrderItems()">
              <div class="full-width">{{ item.title }}</div>
              <div class="colspan-2">{{ item.quantita }} &nbsp; x &nbsp; € {{ item.prezzo.toFixed(2) }}</div>
              <div></div>
              <div class="right-aligned-text">{{ (item.prezzo * item.quantita).toFixed(2) }}</div>
            </ng-container>

            <!-- Total -->
            <div class="colspan-2 rowspan-2 border-small-top border-double-bottom font-xl flex" style="font-weight: bold; padding-top:5px; padding-bottom: 5px; width: 110%">
              <span>Summe in €</span>
            </div>
            <div class="colspan-2 rowspan-2 border-small-top border-double-bottom font-xl right-aligned-text justify-center" style="font-weight: bold; padding-top:5px; padding-bottom: 5px">
              <span>{{ getTotal().toFixed(2) }}</span>
            </div>

            <!-- VAT Info -->
            <div class="cell">MwSt</div>
            <div class="centered-text">Netto</div>
            <div class="centered-text">Steuer</div>
            <div class="right-aligned-text">Brutto</div>

            <div class="cell">? 22%</div>
            <div class="centered-text">{{ getSubtotal().toFixed(2) }}</div>
            <div class="centered-text">{{ getVat().toFixed(2) }}</div>
            <div class="right-aligned-text">{{ getTotal().toFixed(2) }}</div>

            <!-- Payment Method -->
            <div class="full-width border-small-top fat-text" style="line-height: 1.5em;">Zahlungsart</div>
            <div class="colspan-2">Contanti</div>
            <div class="colspan-2 right-aligned-text">{{ getTotal().toFixed(2) }}</div>

            <!-- Action Buttons -->
            <div class="full-width border-small-top" style="grid-row: span 3;" id="share-to-applications">
              <a class="action-link" title="Beleg speichern">
                <div class="app-icon">
                  <img src="https://receipts-sandbox.fiskaltrust.cloud/share-pdf.png" width="32px">
                </div>
                <span class="partner-link-label">Beleg speichern</span>
              </a>
              <a class="action-link" title="Consumer App">
                <div class="app-icon">
                  <img src="https://receipts-sandbox.fiskaltrust.cloud/share-receipthero.svg" width="32px">
                </div>
                <span class="partner-link-label">Consumer App</span>
              </a>
              <a class="action-link" title="ReceiptHero öffnen">
                <div class="app-icon">
                  <img src="https://receipts-sandbox.fiskaltrust.cloud/share-receipthero.png" width="32px">
                </div>
                <span class="partner-link-label">ReceiptHero öffnen</span>
              </a>
              <a class="action-link" title="Digitale Belege">
                <div class="app-icon">
                  <img src="https://receipts-sandbox.fiskaltrust.cloud/share-tree.png" width="32px">
                </div>
                <span class="partner-link-label">Digitale Belege</span>
              </a>
            </div>

            <!-- Footer -->
            <div class="full-width border-small-top" id="signatures-box">
              <div class="full-width signatures signature-key">SANDBOX</div>
              <div class="full-width signatures signature-value">be6934d5-60d0-45a0-8abb-424116b24136</div>
            </div>

            <div class="ft-logo-cell">
              <img src="https://receipts-sandbox.fiskaltrust.cloud/fiskaltrust-logo.svg" width="152" height="47" alt="logo">
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
    styles: [`
    .main-content {
      display: flex;
      justify-content: center;
      min-height: 95vh;
      padding: 2rem;
      background-color: #f3f4f6;
    }

    .print-content {
      background: white;
      padding: 2rem;
      width: 100%;
      max-width: 400px;
      box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
    }

    .receipt-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0.5rem;
      font-family: 'Courier New', Courier, monospace;
    }

    .full-width {
      grid-column: span 4;
    }

    .colspan-2 {
      grid-column: span 2;
    }

    .centered-text {
      text-align: center;
    }

    .right-aligned-text {
      text-align: right;
    }

    .border-small-top {
      border-top: 1px solid black;
    }

    .border-small-bottom {
      border-bottom: 1px solid black;
    }

    .border-double-bottom {
      border-bottom: 2px solid black;
    }

    .fat-text {
      font-weight: bold;
    }

    .font-xl {
      font-size: 1.25rem;
    }

    .flex {
      display: flex;
    }

    .justify-center {
      justify-content: center;
    }

    .align-center {
      align-items: center;
    }

    /* Rating Stars */
    .rating {
      display: flex;
      flex-direction: row-reverse;
      gap: 4px;
    }

    .rating input {
      display: none;
    }

    .rating label {
      cursor: pointer;
      width: 44px;
      height: 44px;
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path stroke="%23fbbf24" fill="none" stroke-width="2" d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"/></svg>');
    }

    /* Action Links */
    #share-to-applications {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      padding: 1rem 0;
    }

    .action-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }

    .app-icon {
      width: 48px;
      height: 48px;
      background: #f3f4f6;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
    }

    .partner-link-label {
      font-size: 0.625rem;
      text-align: center;
    }

    /* Signatures */
    .signatures {
      text-align: center;
      margin: 0.5rem 0;
    }

    .signature-key {
      letter-spacing: 0.2em;
    }

    .signature-value {
      font-size: 0.75rem;
    }

    .ft-logo-cell {
      grid-column: span 4;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  `]
})
export class ReceiptPageComponent implements OnInit, OnDestroy {
    currentOrder: any;
    private orderSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private commonService: CommonService
    ) {}

    ngOnInit() {
        const orderId = this.route.snapshot.paramMap.get('id');
        if (orderId) {
            this.orderSubscription = this.commonService.getOrderById(orderId)
                .subscribe(order => {
                    this.currentOrder = order.payload.data();
                });
        }
    }

    ngOnDestroy() {
        if (this.orderSubscription) {
            this.orderSubscription.unsubscribe();
        }
    }

    getFormattedDate(timestamp: string): string {
        if (!timestamp) return '';
        const date = new Date(parseInt(timestamp));
        return new Intl.DateTimeFormat('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    }

    getOrderItems(): any[] {
        if (!this.currentOrder?.carrello) return [];
        return Object.values(this.currentOrder.carrello)
            .filter((item: any) => item.title !== 'Sconto');
    }

    getSubtotal(): number {
        return this.getTotal() / 1.22;
    }

    getVat(): number {
        return this.getTotal() - this.getSubtotal();
    }

    getTotal(): number {
        if (!this.currentOrder?.carrello) return 0;

        let total = Object.values(this.currentOrder.carrello)
            .filter((item: any) => item.title !== 'Sconto')
            .reduce((sum: number, item: any) =>
                sum + (item.prezzo * item.quantita), 0);

        // Apply discount if present
        const sconto = Object.values(this.currentOrder.carrello)
            .find((item: any) => item.title === 'Sconto');

        if (sconto) {
            if (sconto['tipo']) { // Fixed amount
                // @ts-ignore
                total -= Math.abs(sconto['prezzo']);
            } else { // Percentage
                // @ts-ignore
                total *= (1 - (Math.abs(sconto['prezzo']) / 100));
            }
        }

        return <number>total;
    }
}