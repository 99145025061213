<div class="row">
  <div class="column">
      <div style="
      width: 100%;
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 15px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      align-items: center;
        ">
          <div style="width: 70%; height: 100%; margin-left: 10px; display: flex">
              <div [ngStyle]="getTabStyle('menu')"
                   (click)="selezione = 'menu'">
                  Menu
              </div>
              <div [ngStyle]="getTabStyle('listini')"
                   (click)="selezione = 'listini'">
                  Listini
              </div>
              <div [ngStyle]="getTabStyle('categorie')"
                   (click)="selezione = 'categorie'">
                  Categorie
              </div>
              <div [ngStyle]="getTabStyle('varianti')"
                   (click)="selezione = 'varianti'">
                  Varianti
              </div>
          </div>
          <div style="width: calc(30% - 10px);
                        height: 100%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
">
              <div class="menu-options" >
                  <!-- Select for available menus -->
                  <div class="search">
                      <div>
                          <input type="text" placeholder="Cerca . . ." required>
                      </div>
                  </div>

                  <select [(ngModel)]="selectedMenu" (change)="onMenuSelect($event)" style=" height: 40px; border: none; padding: 5px 5px; font-weight: 600; font-size: 15px; font-family: 'Inter', sans-serif">
                      <option *ngFor="let menu of availableMenus" [value]="menu">{{menu}}</option>
                  </select>

                  <!-- Button to create a new menu -->
                  <!--img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7"
                        style="width: 30px; aspect-ratio: 1 / 1; cursor: pointer" (click)="createNewMenu();"-->

              </div>
          </div>
      </div>




    <div class="container menu" id="menuListaPiatti" *ngIf="selezione == 'menu'">
      <div style="pointer-events: auto; margin-top: 1vh">
        <div style="padding-top: 0%; padding-left: 0px">
          <!-- Contenitore per la tabella con altezza fissa e scorrimento verticale -->
          <div class='table-responsive' style="height: 87.5vh; overflow-y: auto;">
            <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

              <!-- Itera attraverso le colonne -->
              <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
                  <mat-header-cell *matHeaderCellDef [ngStyle]="{'justify-content': column.field === 'Aggiungi' ? 'flex-end' : 'inherit'}">
                      <div *ngIf="column.field != 'Aggiungi'">
                          {{ column.field }}
                      </div>
                      <div *ngIf="column.field === 'Aggiungi'" style="display: flex; align-items: center; margin: 0px">
                          <categorieCard class="col-12 col-md-12 menuItem" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                         [nome]="'Aggiungi Piatto'"
                                         altezza="45"
                                         [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7' "
                                         [bg]="'#FF0000B3'"
                                         (click)="openDialog('')">
                          </categorieCard>
                      </div>
                  </mat-header-cell>

                  <mat-cell *matCellDef="let row; let i = index"
                            [ngStyle]="{'justify-content': column.field === 'Aggiungi' ? 'center ' : 'inherit'}">
                  <!-- Aggiungi qui l'immagine del piatto, se applicabile -->
                  <ng-container *ngIf="column.field === 'title'">

                    <img [src]="row.image_link" alt="Immagine del piatto" style="width: 35px; height: 35px; object-fit: cover; margin-right: 10px;" *ngIf="row.image_link"/>

                    <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px; background-color: white;" type="text" class="searchTerm" [value]="row[column.field]" readonly>

                  </ng-container>

                  <!-- Contenuto della cella -->
                  <ng-container *ngIf="column.field !== 'actions' && column.field !== 'category' && column.field !== 'menu' && column.field !== 'title' && column.field !== 'Disponibilità' && column.field !== 'Aggiungi'">
                    <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px; background-color: white;" type="text" class="searchTerm" [value]="row[column.field]" readonly>
                  </ng-container>

                  <ng-container *ngIf="column.field === 'category'">
                    <span>{{ row[column.field] }}</span>
                  </ng-container>

                  <!-- Colonna del pulsante di Menu -->
                  <ng-container *ngIf="column.field === 'menu'" style="max-width: 50px">
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                      <!-- Genera un ID univoco usando il titolo del piatto o l'indice -->
                      <input
                        type="checkbox"
                        [id]="'checkbox-' + column.field + '-' + i"
                        [checked]="row.menu == 1"
                        (change)="onMenuChange(row)"
                      >
                      <label [for]="'checkbox-' + column.field + '-' + i">
                        <div class="tick_mark"></div>
                      </label>
                    </div>
                  </ng-container>




                  <ng-container *ngIf="column.field === 'Disponibilità'">

                    <div class="checkbox_item citem_1" style="width: 30px; margin-left: 20px; margin-right: calc(100% - 90px)">
                      <label class="checkbox_wrap">
                        <input
                          type="checkbox"
                          name="checkbox"
                          class="checkbox_inp"
                          [checked]="row.disponibilita == 1"
                          (change)="onDisponibilitaChange(row)"
                        >
                        <span class="checkbox_mark"></span>
                      </label>
                    </div>

                  </ng-container>

                    <ng-container *ngIf="column.field === 'Aggiungi'" >


                        <div style="width: 30px; margin-left: 30px">
                            <button class="action-button" (click)="openDialog(row)">
                                <img [src]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-90.png?alt=media&token=3975c982-b1e5-467a-92cd-0f81e5a30c5a' " alt="Azione" style="max-width: 15px; max-height: 15px;">
                            </button>
                        </div>
                    </ng-container>


                </mat-cell>
              </ng-container>

              <!-- Header row con sticky -->
              <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="sticky"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              <!-- Group header per categoria -->
              <ng-container matColumnDef="groupHeader">
                <mat-cell colspan="999" *matCellDef="let group">
                  <mat-icon *ngIf="group.expanded"></mat-icon>
                  <mat-icon *ngIf="!group.expanded"></mat-icon>
                  <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                </mat-cell>
              </ng-container>

              <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

            </mat-table>
          </div>
        </div>
      </div>
    </div >

      <div class="container menu scheda-interna" style="background-color: white; border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'categorie'">
          <edit-categories [collectionType]="'menu_test'"></edit-categories>
      </div>

      <div class="container menu scheda-interna" style="background-color: white; border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'varianti'">

         <app-dialog-varianti></app-dialog-varianti>
      </div>

      <div class="container menu scheda-interna" style="background-color: white; border: 1px solid rgb(227 226 226); border-radius: 25px; padding: 10px; height: 86vh; margin-top: 1vh; overflow-y: auto;" *ngIf="selezione == 'listini'">

          <edit-menus></edit-menus>
      </div>

  </div>
</div>
