<div class="row">

    <div style="width: 94%; margin-left: 3%; border: 1px solid #281d02; border-radius: 25px; height: 150px; margin-top: 2vh; background-color: rgb(253, 251, 254); display: flex; text-align: center">
        <div style="width: 70%; text-align: left; padding: 45px 30px">
            <div style="font-weight: bold; font-size: 25px">Benvenuto!</div>
            <div>Controlla le tue notifiche e i tuoi ordini.</div>

        </div>
        <div style="width: 30%; height: 150px; padding-top: 15px; display: inline-block">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/login%2Flogo.jpg?alt=media&token=0dec5685-0b2c-40a9-9a46-fe3db9a057d1" style="height: 80%; aspect-ratio: 1/1; max-height: 120px; vertical-align: middle">

        </div>

    </div>

    <div class="row" style="float: left; margin-left : 3%; margin-right: 3%; width: 94%; height: 60px; margin-top: 20px; border-radius: 30px; background-color: white; border: 1px solid rgba(181,181,181,0.2); padding: 5px; ">
        <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="lista == 0? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 0">
            <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Notifiche</span>
        </div>
        <div class="slider" style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="lista == 1? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 1">
            <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Cronologia</span>
        </div>
        <div class="slider slider2" style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="lista == 2? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 2">
            <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Ordina</span>
        </div>
    </div>



  <div class="column">

    <!--div class="row" style="float: left; width: 97%; height: 60px; margin-top: 20px; border-radius: 30px; background-color: rgba(181,181,181,0.2); border: 1px solid #e3e2e2; padding: 5px; margin-left: 10px ">
      <div style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: white; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 0? 'white': 'transparent'" (click)="sezioneNovita = 0">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Lista</span>
      </div>
      <div style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 1? 'white': 'transparent'" (click)="sezioneNovita = 1">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Tab</span>

      </div>
    </div-->

    <div class="sb-example-1 shadow" style="margin-top: 30px !important;  width: 100%" *ngIf="lista == 0">
      <div class="search" >
        <button type="submit" class="search-button" style="margin-top: 5px !important">
          <i class="fa fa-search"></i>
        </button>
        <input style="margin-left: 10px;" type="text" class="searchTerm" placeholder="Cerca" [(ngModel)]="filter" >

      </div>
    </div>


    <div class="container menu" id="menuListaPiatti">
      <div style="pointer-events: auto; margin-top: 0px">



              <cronologia *ngIf="lista == 1"></cronologia>

          <div class="flex-container" style="display: flex; width: 95%; gap: 20px; padding: 20px;"  *ngIf="lista == 0">
              <!-- Left side - Notifications Container -->
              <div class="notifications-container" style="flex: 1; max-width: 70%;">
                  <div class="noScrollBarr" style="padding: 10px; max-height: 90vh; overflow-x: visible; overflow-y: scroll" *ngIf="lista == 0">
                      <div class="container" style="padding-top: 0px;">
                          <div class="row" style="padding-top: 0%" *ngFor="let notifica of notifiche">
                              <notifiche-dashboard-card
                                      class="col col-md menuItem"
                                      [data]="notifica.data"
                                      [testoNotifica]="notifica.testoNotifica"
                                      [tipologiaNotifica]="notifica.tipologiaNotifica">
                              </notifiche-dashboard-card>
                          </div>
                      </div>
                  </div>
              </div>

              <!-- Right side - Filters -->
              <div class="filters-container" style="width: 30%; min-width: 300px;">
                  <p style="width: 75%; text-align: left; font-weight: bold; font-size: 22px; margin: 0; margin-top: 15px;">Periodo</p>

                  <div style="background-color: white; border: 1px solid rgba(181,181,181,0.3); border-radius: 20px; padding: 15px 20px; margin-top: 15px">
                      <!-- Last Week Filter -->
                      <div style="display: flex; cursor: pointer" (click)="filtroStato = 0">
                          <div>
                              <img *ngIf="filtroStato != 0" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="width: 30px">
                              <img *ngIf="filtroStato == 0" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="width: 30px">
                          </div>
                          <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; margin-left: 20px" [ngStyle]="{'color': filtroStato === 0 ? '#E55933' : 'black'}">Ultima Settimana</div>
                      </div>

                      <div style="margin-left: 40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin: 10px 0;"></div>

                      <!-- Last Month Filter -->
                      <div style="display: flex; cursor: pointer" (click)="filtroStato = 1">
                          <div>
                              <img *ngIf="filtroStato != 1" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="width: 30px">
                              <img *ngIf="filtroStato == 1" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="width: 30px">
                          </div>
                          <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; margin-left: 20px" [ngStyle]="{'color': filtroStato === 1 ? '#E55933' : 'black'}">Ultimo mese</div>
                      </div>

                      <div style="margin-left: 40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin: 10px 0;"></div>

                      <!-- Last Year Filter -->
                      <div style="display: flex; cursor: pointer" (click)="filtroStato = 2">
                          <div>
                              <img *ngIf="filtroStato != 2" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5" style="width: 30px">
                              <img *ngIf="filtroStato == 2" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae" style="width: 30px">
                          </div>
                          <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; margin-left: 20px" [ngStyle]="{'color': filtroStato === 2 ? '#E55933' : 'black'}">Ultimo Anno</div>
                      </div>

                      <div style="margin-left: 40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin: 10px 0;"></div>

                      <!-- Custom Date Range -->
                      <div style="display: flex; cursor: pointer" (click)="filtraStatoTutto()">
                          <mat-form-field appearance="fill" style="margin-top: -10px; margin-left: -15px; display: flex; align-items: center;">
                              <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                              <mat-label style="font-size: 16px; font-weight: 600;" [ngStyle]="{'color': filtroStato === 3 ? '#E55933' : 'black'}">
                                  Periodo Personalizzato
                              </mat-label>
                              <mat-date-range-input [rangePicker]="picker" style="margin-left: 20px">
                                  <input matStartDate placeholder="Start date">
                                  <input matEndDate placeholder="End date">
                              </mat-date-range-input>
                              <mat-date-range-picker #picker></mat-date-range-picker>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
          </div>

          <div class="flex-container" style="display: flex; width: 100%; gap: 20px; padding: 20px;"  *ngIf="lista == 2">
              <!-- Left side - Notifications Container -->
              <div class="notifications-container" style="flex: 1; max-width: 70%;">
                  <div class="noScrollBarr" style="padding: 10px; max-height: 90vh; overflow-x: visible; overflow-y: scroll" *ngIf="lista == 2">
                      <div class="container" style="padding-top: 0px;">
                          <app-ordini-magazzino-test></app-ordini-magazzino-test>
                      </div>
                  </div>
              </div>
          </div>


      </div>
    </div>





  </div>



</div>
