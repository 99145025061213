import { Component, ElementRef, HostListener, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-numeric-keypad',
    template: `
        <div class="keypad-container" #keypad [style.bottom.px]="bottomPosition" tabindex="0" (keydown)="onKeyDown($event)">
            <div class="keypad-handle" (mousedown)="onDragStart($event)" (touchstart)="onDragStart($event)"></div>
            <div class="keypad-content">
                <div class="left-section">
                    <div class="numeric-pad">
                        <button *ngFor="let num of numbers" (click)="onNumberClick(num)">{{ num }}</button>
                    </div>
                </div>
                <div class="right-section">
                    <div class="display">{{ formattedDisplay }}</div>
                    <div class="function-buttons">
                        <button class="func-btn">- %</button>
                        <button class="func-btn">- €</button>
                        <button class="func-btn">Vini</button>
                        <button class="func-btn">Secondi Piat</button>
                        <button class="func-btn">Storno</button>
                        <button class="func-btn"></button>
                        <button class="func-btn">Primi Piatti</button>
                        <button class="func-btn">Ricarica</button>
                        <button class="func-btn">X</button>
                        <button class="func-btn">=x</button>
                        <button class="func-btn">Altro</button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .keypad-container {
            position: fixed;
            right: 20px;
            width: 600px;
            background-color: #f0f0f0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            box-shadow: 0 -2px 10px rgba(0,0,0,0.2);
            touch-action: none;
            outline: none;
        }
        .keypad-handle {
            height: 20px;
            background-color: #ddd;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            cursor: ns-resize;
        }
        .keypad-content {
            display: flex;
            padding: 10px;
        }
        .left-section {
            flex: 1;
            margin-right: 10px;
        }
        .right-section {
            flex: 2;
        }
        .numeric-pad {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
        }
        .display {
            text-align: right;
            font-size: 24px;
            padding: 10px;
            background-color: white;
            border: 1px solid #ccc;
            margin-bottom: 10px;
        }
        .function-buttons {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 5px;
        }
        button {
            font-size: 18px;
            padding: 10px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 5px;
        }
        .func-btn {
            font-size: 14px;
        }
    `]
})
export class NumericKeypadComponent implements AfterViewInit {
    @ViewChild('keypad') keypad: ElementRef;
    numbers = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '00', 'C'];
    display = '0';
    bottomPosition = -280;
    private isDragging = false;
    private startY = 0;
    private startBottom = 0;
    private keypadHeight: number;

    constructor(private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.keypadHeight = this.keypad.nativeElement.offsetHeight;
        this.bottomPosition = -(this.keypadHeight - 20);
    }

    get formattedDisplay(): string {
        const value = parseInt(this.display);
        return (value / 100).toFixed(2);
    }

    onNumberClick(num: string) {
        if (num === 'C') {
            this.display = '0';
        } else if (this.display === '0' && num !== '00') {
            this.display = num;
        } else {
            this.display += num;
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (/^[0-9]$/.test(event.key)) {
            this.onNumberClick(event.key);
        } else if (event.key === 'Backspace') {
            this.display = this.display.slice(0, -1) || '0';
        } else if (event.key === 'Delete' || event.key === 'Escape') {
            this.display = '0';
        }
        event.preventDefault();
    }

    onDragStart(event: MouseEvent | TouchEvent) {
        this.isDragging = true;
        this.startY = this.getEventY(event);
        this.startBottom = this.bottomPosition;
        event.preventDefault();

        if (event instanceof MouseEvent) {
            this.renderer.listen('document', 'mousemove', this.onDragMove.bind(this));
            this.renderer.listen('document', 'mouseup', this.onDragEnd.bind(this));
        } else {
            this.renderer.listen('document', 'touchmove', this.onDragMove.bind(this));
            this.renderer.listen('document', 'touchend', this.onDragEnd.bind(this));
        }
    }

    onDragMove(event: MouseEvent | TouchEvent) {
        if (this.isDragging) {
            const currentY = this.getEventY(event);
            const deltaY = this.startY - currentY;
            let newBottom = this.startBottom + deltaY;

            newBottom = Math.min(0, Math.max(-(this.keypadHeight - 20), newBottom));

            this.bottomPosition = newBottom;
        }
    }

    onDragEnd() {
        this.isDragging = false;
        this.renderer.destroy();
    }

    private getEventY(event: MouseEvent | TouchEvent): number {
        return event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    }
}