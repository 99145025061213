<div class="dashboard-container" style="margin-left: 1rem">
    <div class="dashboard-header">
        <h1></h1>
        <div class="view-controls">
            <button [class.active]="compareMode === 'revenue'" (click)="toggleCompareMode('revenue')">
                <i class="material-icons">euro</i> Fatturato
            </button>
            <button [class.active]="compareMode === 'growth'" (click)="toggleCompareMode('growth')">
                <i class="material-icons">trending_up</i> Crescita
            </button>
            <button [class.active]="compareMode === 'products'" (click)="toggleCompareMode('products')">
                <i class="material-icons">shopping_basket</i> Prodotti
            </button>
            <button [class.active]="compareMode === 'sources'" (click)="toggleCompareMode('sources')">
                <i class="material-icons">source</i> Fonti
            </button>
        </div>
    </div>

    <!-- Filters Section -->
    <div class="filters-section">
        <div class="filters-grid">
            <!-- Date Range Filters -->
            <div class="date-filters">
                <div class="date-range-picker">
                    <button *ngFor="let preset of datePresets"
                            class="date-chip"
                            [ngClass]="{'active': isActivePreset(preset.value)}"
                            (click)="setDatePreset(preset.value)">
                        {{preset.label}}
                    </button>
                    <button class="date-chip custom" (click)="openDateRangePicker()">
                        <i class="material-icons">date_range</i>
                        Periodo Personalizzato
                    </button>
                    <button class="date-chip export" (click)="exportToCSV()">
                        <i class="material-icons">download</i>
                        Esporta CSV
                    </button>
                </div>

                <mat-form-field *ngIf="showCustomDateRange" class="custom-date-range">
                    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Data inizio">
                        <input matEndDate formControlName="end" placeholder="Data fine">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <!-- Order Type and Source Filters -->
            <!--div class="type-filters">
                <button *ngFor="let type of orderTypeFilters"
                        class="type-chip"
                        [ngClass]="{'active': filters.orderTypes.includes(type.value)}"
                        (click)="toggleOrderType(type.value)">
                    <i class="material-icons">{{type.icon}}</i>
                    {{type.label}}
                </button>

                <button *ngFor="let source of orderSourceFilters"
                        class="type-chip"
                        [ngClass]="{'active': filters.orderSources.includes(source.value)}"
                        (click)="toggleOrderSource(source.value)">
                    <i class="material-icons">{{source.icon}}</i>
                    {{source.label}}
                </button>
            </div-->

            <!-- Search and Additional Filters -->
            <div class="search-filters">
                <!--div class="search-input">
                    <i class="material-icons">search</i>
                    <input type="text"
                           [(ngModel)]="filters.searchTerm"
                           (ngModelChange)="search($event)"
                           placeholder="Cerca prodotti, ristoranti...">
                </div-->

                <div class="time-range">
                    <mat-form-field>
                        <mat-label>Orario</mat-label>
                        <mat-select [formControl]="timeRangeControl">
                            <mat-option value="all">Tutto il giorno</mat-option>
                            <mat-option value="morning">Mattino (6:00-12:00)</mat-option>
                            <mat-option value="lunch">Pranzo (12:00-15:00)</mat-option>
                            <mat-option value="afternoon">Pomeriggio (15:00-19:00)</mat-option>
                            <mat-option value="dinner">Cena (19:00-23:00)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!-- Loading State -->
    <div class="loading-state" *ngIf="loading">
        <div class="spinner"></div>
        <p>Caricamento dati...</p>
    </div>

    <!-- Error State -->
    <div class="error-state" *ngIf="error">
        <i class="material-icons">error_outline</i>
        <p>Non è stato possibile caricare i dati dei ristoranti</p>
    </div>

    <!-- Chart Section -->
    <div class="chart-section" *ngIf="!loading && !error">
        <h2>
            {{ compareMode === 'revenue' ? 'Confronto Fatturato' :
            compareMode === 'growth' ? 'Confronto Crescita' :
                compareMode === 'products' ? 'Confronto Prodotti Principali' :
                    'Confronto Fonti Ordini' }}
            {{ timeScale === 'hour' ? 'Orario' :
            timeScale === 'day' ? 'Giornaliero' :
                'Mensile' }}
        </h2>
        <div class="chart-container">
            <canvas #revenueComparisonChart></canvas>
        </div>
    </div>

    <!-- Restaurant Comparison Table -->
    <div class="comparison-table-section" *ngIf="!loading && !error">
        <h2>Confronto Ristoranti</h2>

        <div class="table-controls">
            <div class="sort-controls">
                <span>Ordina per:</span>
                <button [class.active]="sortBy === 'name'" (click)="changeSortBy('name')">
                    Nome
                    <i class="material-icons" *ngIf="sortBy === 'name'">
                        {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                    </i>
                </button>
                <button [class.active]="sortBy === 'revenue'" (click)="changeSortBy('revenue')">
                    Fatturato
                    <i class="material-icons" *ngIf="sortBy === 'revenue'">
                        {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                    </i>
                </button>
                <button [class.active]="sortBy === 'growth'" (click)="changeSortBy('growth')">
                    Crescita
                    <i class="material-icons" *ngIf="sortBy === 'growth'">
                        {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
                    </i>
                </button>
            </div>
        </div>

        <div class="comparison-table">
            <div class="table-header">
                <div class="col-name">Ristorante</div>
                <div class="col-stats">{{ activePreset === 'today' ? 'Incasso Giornaliero' :
                    activePreset === 'yesterday' ? 'Incasso Ieri' :
                        activePreset === 'lastWeek' ? 'Incasso Settimana' :
                            activePreset === 'lastMonth' ? 'Incasso Mensile' :
                                activePreset === 'lastYear' ? 'Incasso Annuale' :
                                    'Fatturato Totale' }}</div>
                <div class="col-stats">Variazione</div>
                <div class="col-stats">{{ activePreset === 'today' ? 'Proiezione Giornaliera' :
                    activePreset === 'yesterday' ? 'Proiezione (se oggi)' :
                        activePreset === 'lastWeek' ? 'Proiezione Settimanale' :
                            activePreset === 'lastMonth' ? 'Proiezione Mensile' :
                                activePreset === 'lastYear' ? 'Proiezione Annuale' :
                                    'Proiezione Periodo' }}</div>
                <div class="col-source">Fonti Ordini</div>
                <div class="col-product">Prodotto Principale</div>
                <div class="col-actions">Azioni</div>
            </div>

            <div class="table-body">
                <div class="table-row" *ngFor="let restaurant of restaurantStats">
                    <div class="col-name">{{ restaurant.name }}</div>
                    <div class="col-stats">{{ formatCurrency(restaurant.monthlyStats.currentMonth) }}</div>
                    <div class="col-stats" [class.positive]="restaurant.monthlyStats.percentageChange >= 0" [class.negative]="restaurant.monthlyStats.percentageChange < 0">
                        <i class="material-icons">
                            {{ restaurant.monthlyStats.percentageChange >= 0 ? 'trending_up' : 'trending_down' }}
                        </i>
                        {{ restaurant.monthlyStats.percentageChange | number:'1.1-1' }}%
                    </div>
                    <div class="col-stats">{{ formatCurrency(restaurant.monthlyStats.projected) }}</div>
                    <div class="col-source">
                        <div class="source-tags">
                            <span class="source-tag app" *ngIf="restaurant.ordersBySource?.app">
                                <i class="material-icons">smartphone</i> {{ restaurant.ordersBySource?.app }}
                            </span>
                            <span class="source-tag kiosk" *ngIf="restaurant.ordersBySource?.kiosk">
                                <i class="material-icons">tablet_mac</i> {{ restaurant.ordersBySource?.kiosk }}
                            </span>
                            <span class="source-tag pos" *ngIf="restaurant.ordersBySource?.pos">
                                <i class="material-icons">point_of_sale</i> {{ restaurant.ordersBySource?.pos }}
                            </span>
                        </div>
                    </div>
                    <div class="col-product">
                        <div class="top-product" *ngIf="restaurant.topProducts.length > 0">
                            <span class="product-name">{{ restaurant.topProducts[0].title }}</span>
                            <span class="product-revenue">{{ formatCurrency(restaurant.topProducts[0].revenue) }}</span>
                        </div>
                        <div class="no-product" *ngIf="restaurant.topProducts.length === 0">
                            Nessun prodotto
                        </div>
                    </div>
                    <div class="col-actions">
                        <button class="action-button" (click)="navigateToRestaurantDetails(restaurant.id)">
                            <i class="material-icons">visibility</i>
                            Dettagli
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Restaurant Cards Section for smaller screens -->
    <div class="restaurant-cards" *ngIf="!loading && !error">
        <div class="restaurant-card" *ngFor="let restaurant of restaurantStats">
            <div class="card-header">
                <h3>{{ restaurant.name }}</h3>
                <div class="trend-indicator" [class.positive]="restaurant.monthlyStats.percentageChange >= 0" [class.negative]="restaurant.monthlyStats.percentageChange < 0">
                    <i class="material-icons">
                        {{ restaurant.monthlyStats.percentageChange >= 0 ? 'trending_up' : 'trending_down' }}
                    </i>
                    {{ restaurant.monthlyStats.percentageChange | number:'1.1-1' }}%
                </div>
            </div>

            <div class="card-body">
                <div class="stat-row">
                    <span class="stat-label">{{ activePreset === 'today' ? 'Incasso oggi:' :
                        activePreset === 'yesterday' ? 'Incasso ieri:' :
                            activePreset === 'lastWeek' ? 'Incasso settimana:' :
                                activePreset === 'lastMonth' ? 'Incasso mensile:' :
                                    activePreset === 'lastYear' ? 'Incasso annuale:' :
                                        'Fatturato totale:' }}</span>
                    <span class="stat-value">{{ formatCurrency(restaurant.monthlyStats.currentMonth) }}</span>
                </div>
                <div class="stat-row">
                    <span class="stat-label">{{ activePreset === 'today' ? 'Proiezione giornaliera:' :
                        activePreset === 'yesterday' ? 'Proiezione (se oggi):' :
                            activePreset === 'lastWeek' ? 'Proiezione settimanale:' :
                                activePreset === 'lastMonth' ? 'Proiezione mensile:' :
                                    activePreset === 'lastYear' ? 'Proiezione annuale:' :
                                        'Proiezione periodo:' }}</span>
                    <span class="stat-value">{{ formatCurrency(restaurant.monthlyStats.projected) }}</span>
                </div>
                <div class="stat-row">
                    <span class="stat-label">Ordini:</span>
                    <div class="source-mini-tags">
                        <span class="mini-tag app" *ngIf="restaurant.ordersBySource?.app">
                            <i class="material-icons">smartphone</i> {{ restaurant.ordersBySource?.app }}
                        </span>
                        <span class="mini-tag kiosk" *ngIf="restaurant.ordersBySource?.kiosk">
                            <i class="material-icons">tablet_mac</i> {{ restaurant.ordersBySource?.kiosk }}
                        </span>
                        <span class="mini-tag pos" *ngIf="restaurant.ordersBySource?.pos">
                            <i class="material-icons">point_of_sale</i> {{ restaurant.ordersBySource?.pos }}
                        </span>
                    </div>
                </div>
                <div class="stat-row" *ngIf="restaurant.topProducts.length > 0">
                    <span class="stat-label">Prodotto Principale:</span>
                    <span class="stat-value">{{ restaurant.topProducts[0].title }}</span>
                </div>
            </div>

            <div class="card-footer">
                <button class="details-button" (click)="navigateToRestaurantDetails(restaurant.id)">
                    <i class="material-icons">visibility</i>
                    Visualizza Dettagli
                </button>
            </div>
        </div>
    </div>
</div>