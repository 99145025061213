import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

interface TavoliData {
    tavoli: any[];
}

@Injectable({
    providedIn: 'root',
})
export class TavoliService {
    private ristoranteRef = this.firestore.collection('ristoranti');

    constructor(private firestore: AngularFirestore) {}

    getTavoli(ristorante?: string): Observable<any[]> {
        console.log("ristoranrte: ", ristorante)
        return this.ristoranteRef.doc(ristorante).get().pipe(
            map((doc) => {
                const data = doc.data() as TavoliData | undefined;
                return data ? data.tavoli? data.tavoli: [] : [];
            })
        );
    }

    aggiornaTavoli(tavoli: any[], ristorante?: string): Promise<void> {
        console.log("ristoranrte: ", ristorante)

        return this.ristoranteRef.doc(ristorante).set({ tavoli }, { merge: true });
    }

    // Nuovo metodo per aggiornare lo stato di un singolo tavolo
    aggiornaTavoloStato(numeroTavolo: string, occupato: boolean): Promise<void> {
        return this.getTavoli().pipe(
            take(1),
            map(tavoli => {
                const tavoloIndex = tavoli.findIndex(t => t.numero === numeroTavolo);
                if (tavoloIndex !== -1) {
                    tavoli[tavoloIndex].occupato = occupato ? 1 : 0;
                    return tavoli;
                }
                throw new Error('Tavolo non trovato');
            }),
            switchMap(tavoliAggiornati => this.aggiornaTavoli(tavoliAggiornati))
        ).toPromise();
    }
}