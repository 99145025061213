<div class="row" style="width: 100% !important; padding-top: 20px">
    <div class="column">
        <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 20px">
            <div style="width: 24.25%; margin-right: 1%;">
                <div class="sb-example-1 shadow" style="margin-left: 10px; width: 98%">
                    <div class="search">
                        <input class="searchTerm" placeholder="Cerca..." (keyup)="applyFilter($event.target.value)">
                    </div>
                </div>
            </div>
        </div>


        <div>
            <h3>Valore totale dell'inventario: {{ getTotalInventoryValue() | currency }}</h3>

            <h3>Prodotti con scorte basse:</h3>
            <ul>
                <li *ngFor="let item of getLowStockItems()">
                    {{ item.title }} - Stock attuale: {{ item.actualStock }}, Punto di riordino: {{ item.reorderPoint }}
                </li>
            </ul>

            <h3>Prodotti più venduti:</h3>
            <ul>
                <li *ngFor="let item of getTopSellingItems()">
                    {{ item.title }} - Venduti: {{ item.quantityInStock - item.actualStock }}
                </li>
            </ul>
        </div>

        <div class="chart-container" *ngIf="pieChart !== {}" style="display: flex">
            <div style="width: 50%;">
                <canvas id="{{idPieChart}}"></canvas>
            </div>
            <div style="width: 50%;">
                <canvas id="{{idChart}}"></canvas>
            </div>
        </div>


        <div class="table-responsive" style="padding-bottom: 20px; margin-top: 20px; margin-left: 1%; width: 101%; overflow-y: auto; height: calc(100vh - 180px); border-radius: 20px; border: 1px solid #e3e2e2;">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.field | uppercase }}</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!isGroup(null, element); else groupTemplate">
                            {{ column.field === 'price' || column.field === 'variationCost' || column.field === 'productRevenue' || column.field === 'inventoryValue' ? (element[column.field] | currency:'EUR':'symbol':'1.2-2') : element[column.field] }}
                            <span *ngIf="column.field === ' '"
                                  [ngClass]="{'inner-circleRed': element['variationUnits'] < 0, 'inner-circleWhite': element['variationUnits'] == 0, 'inner-circleGreen': element['variationUnits'] > 0}">
              </span>
                        </ng-container>
                        <ng-template #groupTemplate>
              <span *ngIf="column.field === 'category' || column.field === 'ingredients'"
                    (click)="groupHeaderClick(element)"
                    style="cursor: pointer;">
                <mat-icon>{{ element.expanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
                  {{ element[column.field] }} ({{ element.totalCounts }})
              </span>
                        </ng-template>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'group-row': isGroup(null, row)}"
                    [style.display]="isGroup(null, row) || getDataRowVisible(row) ? '' : 'none'">
                </tr>
            </table>
        </div>

    </div>
</div>
