import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges, OnDestroy,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {AggiuntaPiattoTotem} from "../../aggiunta-piatto-totem/aggiunta-piatto-totem";
import {DialogPagamento} from "../../dialogPagamento/dialogPagamento";
import {Fidelity} from "../../fidelity/fidelity";
import {Fatturazione} from "../../fatturazione/fatturazione";
import {Subject, Subscription} from "rxjs";
import {UserDataService} from "../../../shared/services/firestore/userData.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import {CommonService} from "../../../shared/services/firestore/common.service";
import {PaymentCashmaticService} from "../../../shared/services/payment-cashmatic.service";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {TavoliService} from "../../../shared/services/tavoli.service";
import firebase from "firebase";
import {takeUntil} from "rxjs/operators";
import {SelezionaVarianti} from "../../seleziona-varianti/seleziona-varianti";


@Component({
  selector: 'app-categorie',
  templateUrl: './cassa-ordine.html',
  styleUrls: ['./cassa-ordine.css']
})
  export class CassaOrdine implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  currentOrder: any;
  portata = 0;
  sezione = -1;
  menu: any[] = [];
  imagePaths: { [key: string]: Promise<string> } = {};
  user: any;
  menuCategories: any[] = [];


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  private subscribeToOrder() {
    const orderId = this.route.snapshot.paramMap.get('id');
    console.log("current order: ", orderId)

    if (orderId) {
      this.commonService.getOrderById(orderId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(order => {
            this.currentOrder = order.payload.data();
            if (!this.currentOrder.carrello) {
              this.currentOrder.carrello = {};
            }
            if (!this.currentOrder.ordineInserimento) {
              this.currentOrder.ordineInserimento = [];
            }
            console.log("current order: ", this.currentOrder)
            console.log("current order: ", order)

          });
    }
  }


  getPrezzoPiatto(piatto)
  {
    if(piatto?.hasOwnProperty('aggiunte'))
    {
      return piatto?.prezzo + piatto?.aggiunte.length
    }
    else
    {
      return piatto?.prezzo
    }
  }

  tavoliVuoti = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]


  IMAGE_LINKS = [
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-back-arrow-96.png?alt=media&token=dd7434c9-74f8-426a-8118-175603db884a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-drawer-100.png?alt=media&token=1ca2af49-ad1b-4415-a2f7-642b3131d9db',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-loyalty-90.png?alt=media&token=bf60b26a-407d-41cc-9351-229c8e608c04',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
    'https://img.icons8.com/sf-black/64/000000/search.png',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-heart-100%20(1).png?alt=media&token=923b3ec1-6439-4656-947b-be258e03d9f6'
  ]


  private readonly IMAGE_NAMES = [
  ];



  imagePathsGeneric: { [key: string]: Promise<string> } = {};

  private initializeImagePaths() {
    // Supponiamo che 'items' sia il tuo array di elementi del menu

    this.menu.forEach(categoria => {
      console.log("categoria offline: ", categoria)
      categoria.menu.forEach(item => {
        console.log("item offline: ", item)
        this.imagePaths[item.title] = this.ipcService.getLocalImage(item.image_link);
      });
      console.log("itemPaths offline: ", this.imagePaths)

    })
  }

  /*
  initializeImagePaths2() {
    // Supponiamo che 'items' sia il tuo array di elementi del menu

    this.IMAGE_NAMES.forEach((categoria, i) => {
      this.imagePathsGeneric[categoria] = this.ipcService.getLocalImage2(categoria);
    })

    this.dataServiceGenerico.setImmagini(this.imagePathsGeneric)

    console.log("imagepaths offline: ", this.imagePaths)
  }

   */


  trackByFn(index: number, item: any): string {
    return item.title; // Assumi che ogni item abbia un id univoco
  }

  openDialog(piatto?: any, currentOrder?: any, portata?: any): any {

    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto,
        height: '70vh',

      }
    }
    console.log('abcu',currentOrder, piatto, portata);

    const modalRef = this.dialog.open(SelezionaVarianti, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.currentOrder = currentOrder;
    modalRef.componentInstance.portata = portata;
    modalRef.componentInstance.piattoRicevuto = piatto;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });
  }

  openDialogPagamento(): any {
    let filteredCarrello = {};

    if (this.currentOrder?.carrello) {
      Object.keys(this.currentOrder.carrello).forEach(key => {
        const keyWithoutSpaces = key.replace(/ /g, ''); // Rimuovi gli spazi dalla chiave
        const item = this.currentOrder.carrello[key];

        // Calcola la quantità rimanente da pagare
        const paidQuantity = item.isPaidQuantity || 0;
        const remainingQuantity = item.quantita - paidQuantity;

        // Se c'è almeno un elemento da pagare, includilo nel carrello filtrato
        if (remainingQuantity > 0) {
          filteredCarrello[keyWithoutSpaces] = {  // Usa la chiave senza spazi
            ...item,
            quantita: remainingQuantity,
            originalQuantity: item.quantita,
            isPaidQuantity: paidQuantity
          };
        }
      });
    }

    this.modalOptions.data = this.currentOrder.ordine;
    const modalRef = this.dialog.open(DialogPagamento, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.camerieri = this.currentOrder;
    modalRef.componentInstance.carrello = filteredCarrello;

    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
      this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
    });

    return modalRef;
  }

  openDialogFidelity(piatto?: any): any {

    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto
      }
    }

    const modalRef = this.dialog.open(Fidelity, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }


  openDialogFatturazione(piatto?: any): any {

    if (piatto !== undefined) {
      this.modalOptions.data = {
        dataKey: piatto
      }
    }

    const modalRef = this.dialog.open(Fatturazione, this.modalOptions);
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }


  getQuantita(prodotto: any) {
    if (!this.currentOrder?.carrello) {
      return 0;
    }

    // Nome base del prodotto senza spazi
    const baseName = prodotto.title.replace(/ /g, '');

    // Calcola il totale di tutte le varianti di questo prodotto
    let totalQuantity = 0;

    Object.keys(this.currentOrder.carrello).forEach(key => {
      // Verifica se la chiave inizia con il nome base del prodotto
      if (key === baseName || key.startsWith(`${baseName}_`)) {
        totalQuantity += this.currentOrder.carrello[key].quantita;
      }
    });

    return totalQuantity;
  }

  categorie = []
  link = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/gelati%2FIMG_0288-removebg-preview.png?alt=media&token=6fbce9c0-9667-4978-bb41-2b835c34cf85'

  width() {
    return (100 / (this.categorie.length + 1))
  }

  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';
  fonteSelected = 'Cassa';
  pagatoSelected = 'Non Pagato';


  lista = 1;
  pagamentoCartaAperto = 0;
  cashmaticAperto = 0;


  handleEvent(event: number, piatto: any, piatto2?: string) {
    if(this.currentOrder?.statoPagato !== undefined? this.currentOrder.statoPagato != 0: true) {
      console.log("handle event: ", event);
      let nomePiatto = ''
      console.log("")
      if(piatto2)
      {
        nomePiatto = piatto2
      }
      this.currentOrder.carrello[nomePiatto].quantita = event
      if (this.currentOrder.carrello[nomePiatto].quantita == 0) {
        delete this.currentOrder.carrello[nomePiatto]
        this.currentOrder['ordineInserimento'] = this.currentOrder['ordineInserimento'].filter(item => item !== nomePiatto);
      }
    }
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder)
  }

  indietro() {
    this.router.navigate(['/cassa'], { fragment: 'nonav' })
    //this.router.navigate(['/cassa'], )
  }


  menuBello = []

  retriveMenu(): void {
    const db = firebase.firestore();
    let restaurantName = this.dataServiceGenerico.getRistorante();

    if(this.dataServiceGenerico.getRistorante() == 'demo_1')
    {
      this.dataServiceGenerico.loadSelectedMenuFromFirebase().then( (value) => {
          restaurantName = this.dataServiceGenerico.getRistorante();
            const menuRef = db.collection('menu_test').doc(restaurantName);

            menuRef.get().then(doc => {
              if (doc.exists) {
                const data = doc.data();
                this.menuBello = data.items; // Recupero e assegno i dati del menu

                this.menuBello = this.menuBello.filter(elemento => elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0)
                this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

                console.log('Menu recuperato da Firebase:', this.menuBello);
                console.log('Menu recuperato da Firebasen 2:', this.categorie);

                this.menu = []

                this.categorie.forEach(categoria => this.menu.push({
                  testo: categoria,
                  menu: []
                }))

                this.menu.forEach((categoria, i) => {
                      this.menuBello.forEach((prodotto) => {
                        if (categoria.testo == prodotto.category) {
                          this.menu[i].menu.push(prodotto)
                        }
                      })
                    }
                )

                this.userService.getDoc(firebase.auth().currentUser.uid).get().pipe(takeUntil(this.destroy$)).subscribe(
                    data => {
                      console.log("!!!!!!!!!!!!!!!!!data preferiti: ", data.data())

                      if (data.data() !== undefined) {

                        this.user = data.data();

                        if (this.user.hasOwnProperty('preferiti')) {
                          console.log("!!!!!!!!!!!!!!!!!ciaociao preferiti: ", this.user['preferiti'])
                          this.user['preferiti'] = this.user['preferiti'].filter(preferito => this.menuBello.some(piatto => piatto.title == preferito.title))
                        }
                      }
                    }
                );

                this.initializeImagePaths()

                console.log('Menu recuperato da Firebasen 3:', this.menu);


              } else {
                console.log('Nessun documento trovato su Firebase.');
              }
            }).catch(error => {
              console.error('Errore nel recupero del menu da Firebase:', error);
            });
          }
      )
    }
    else {
      const menuRef = db.collection('menu_test').doc(restaurantName);

      menuRef.get().then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.menuBello = data.items; // Recupero e assegno i dati del menu

          this.menuBello = this.menuBello.filter(elemento => elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0)
          this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

          console.log('Menu recuperato da Firebase:', this.menuBello);
          console.log('Menu recuperato da Firebasen 2:', this.categorie);

          this.menu = []

          this.categorie.forEach(categoria => this.menu.push({
            testo: categoria,
            menu: []
          }))

          this.menu.forEach((categoria, i) => {
                this.menuBello.forEach((prodotto) => {
                  if (categoria.testo == prodotto.category) {
                    this.menu[i].menu.push(prodotto)
                  }
                })
              }
          )

          this.userService.getDoc(firebase.auth().currentUser.uid).get().pipe(takeUntil(this.destroy$)).subscribe(
              data => {
                console.log("!!!!!!!!!!!!!!!!!data preferiti: ", data.data())

                if (data.data() !== undefined) {

                  this.user = data.data();

                  if (this.user.hasOwnProperty('preferiti')) {
                    console.log("!!!!!!!!!!!!!!!!!ciaociao preferiti: ", this.user['preferiti'])
                    this.user['preferiti'] = this.user['preferiti'].filter(preferito => this.menuBello.some(piatto => piatto.title == preferito.title))
                  }
                }
              }
          );

          this.initializeImagePaths()

          console.log('Menu recuperato da Firebasen 3:', this.menu);


        } else {
          console.log('Nessun documento trovato su Firebase.');
        }
      }).catch(error => {
        console.error('Errore nel recupero del menu da Firebase:', error);
      });
    }


  }


  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function (element) {
      return element !== undefined;
    });
  }

  calcolaPaddingBottom(categoria: any) {
    let calcolo = categoria / 3;
    calcolo = Math.ceil(calcolo)
    //console.log("calcolo: ", calcolo)
    return calcolo * 360
  }



  modalOptions = {
    backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {}
  };


  display = 'none';
  schermata = 0;
  schermataIndex;


  tastierino = [7, 8, 9, 4, 5, 6, 1, 2, 3]
  closeResult = '';
  prezzoPiatto = '0';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;


  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    {
      nome: 'menu',
      descrizione: 'Available',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    },
    {
      nome: 'menu',
      descrizione: 'Ready',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    },
    {
      nome: 'menu',
      descrizione: 'Started',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
      private router: Router, private userService: UserDataService, private modalService: NgbModal, public dialog: MatDialog, private dataService: DataServiceGenerico,
      private commonService: CommonService, private dataServiceGenerico: DataServiceGenerico, private paymentCashmaticService: PaymentCashmaticService, private ipcService: IpcService, private posTransactionService: PosTransactionService,
      private tavoliService: TavoliService, private route: ActivatedRoute,
  ) {
    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }

  printReceipt(cassetto: string) {

    let stringa = []
    this.schermata = 1;

    this.getProperties(this.currentOrder?.carrello)?.forEach(
        key => stringa.push(this.currentOrder?.carrello[key])
    )

    this.stampaScontrino(stringa);
    this.ipcService.printReceipt(this.stampaScontrino(stringa), cassetto);

    if (cassetto != 'apriSolo' && cassetto != 'nonAprire') {
      this.currentOrder.statoPagato = 0;
    }
    if (cassetto == 'nonAprire') {
      this.currentOrder.statoPagato = 1;
    }
    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder)

  }

  sendTransaction(transactionAmount) {
    let stringa = []
    this.pagamentoCartaAperto = 1;
    this.getProperties(this.currentOrder?.carrello).forEach(
        key => stringa.push(this.currentOrder?.carrello[key])
    )
    this.posTransactionService.sendTransaction(transactionAmount, this.stampaScontrino(stringa));
  }

  stampaScontrino(menu: any[]): string {
    const intestazione = 'F.S. LA VALLETTA\n' +
        'di Vilson Coku & C. S.n.C.\n' +
        'Via Partinico n.2\n' +
        '47814 - Igea Marina - RN\n' +
        'IT04428530408\n' +
        'Tel. 0541330860\n \n                   PRECONTO\n \n'

    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;


    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------
      
      
      
         GRAZIE ED ARRIVEDERCI

  `;
    console.log(intestazione + header + scontrino + footer)
    return  header + scontrino + footer;
  }

  testPay() {
    this.cashmaticAperto = 1;
    let stringa = []
    this.getProperties(this.currentOrder?.carrello).forEach(
        key => stringa.push(this.currentOrder?.carrello[key])
    )

    this.paymentCashmaticService.testPay(this.calcolaTotale(), this.stampaScontrino(stringa));
  }

  annullaPagamento() {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  getTransaction() {
    this.paymentCashmaticService.getTransaction();
  }


  /*
  openDialog(): any {

    this.modalOptions.data = this.currentOrder.ordine
    const modalRef = this.dialog.open(DialogPagamento, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.carrello = this.currentOrder?.carrello
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
      this.dataServiceGenerico.updateCameriere2(this.currentOrder.ordine, receivedEntry)
    });

  }

   */


  aggiungiBirra() {
    let birra = {
      category: 'Bibite',
      menu: '0',
      prezzo: 5,
      price: 5,
      quantita: 1,
      title: 'Birra'
    }
    if (this.currentOrder.hasOwnProperty('carrello')) {
      if (this.currentOrder.carrello.hasOwnProperty('Birra')) {
        this.currentOrder.carrello['Birra'].quantita += 1;
      } else {
        this.currentOrder.carrello['Birra'] = birra;
      }
    } else {
      this.currentOrder['carrello'] = {}
      this.currentOrder['carrello']['Birra'] = birra;
    }

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder)

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  tavoli: Array<any> = [];


  ngOnInit(): void {

    this.subscribeToOrder()

    //this.initializeImagePaths2()



    console.log("user: ", this.user)

    this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        console.log(response.message);
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        this.currentOrder.statoPagato = 0;

        this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder)

        // Transazione avvenuta con successo
      } else if (response.status === 'failure') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Transazione fallita
      } else if (response.status === 'error') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Errore di connessione
      }
    });

    this.subscriptions.push(
        this.paymentCashmaticService.soldiInseriti$.pipe(takeUntil(this.destroy$)).subscribe(
            (soldiInseriti) => {
              this.soldiInseriti = soldiInseriti;
            }
        ),
        this.paymentCashmaticService.paymentSuccess$.pipe(takeUntil(this.destroy$)).subscribe(
            (data) => {
              this.paymentMessage = 'Pagamento completato con successo!';
              this.schermata = 1;
              this.cashmaticAperto = 0;
              this.currentOrder.statoPagato = 0;

              this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder)

            }
        ),
        this.paymentCashmaticService.paymentError$.pipe(takeUntil(this.destroy$)).subscribe(
            (error) => {
              this.paymentMessage = 'Errore durante il pagamento: ' + error;
            }
        )
    );


    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveMenu();

        this.userService.emitter.pipe(takeUntil(this.destroy$)).subscribe(
            () => {
              this.retriveMenu();


            }
        );
      } else {
        // No user is signed in.
      }
    });
  }

  tavoliDaUnire: string[] = [];
  modalitaUnione: boolean = false;

  gestioneClickTavolo(tavolo: any) {
    if (this.eliminazione) {
      this.liberaTavolo(tavolo.numero);
    } else if (this.modalitaUnione) {
      this.selezionaTavoloPerUnione(tavolo.numero);
    } else {
      this.aggiungiOrdine(tavolo);
    }
  }

  selezionaTavoloPerUnione(numeroTavolo: string) {
    const index = this.tavoliDaUnire.indexOf(numeroTavolo);
    if (index > -1) {
      this.tavoliDaUnire.splice(index, 1);
    } else if (this.tavoliDaUnire.length < 2) {
      this.tavoliDaUnire.push(numeroTavolo);
    }
    console.log('Tavoli selezionati per unione:', this.tavoliDaUnire);

    if (this.tavoliDaUnire.length === 2) {
      this.unisciTavoli();
    }
  }

  unisciTavoli() {
    if (this.tavoliDaUnire.length !== 2) {
      console.error('Seleziona esattamente due tavoli da unire');
      return;
    }

    const [primoTavolo, secondoTavolo] = this.tavoliDaUnire;
    const ordinePrimoTavolo = this.camerieri.find(c => c.tavolo === primoTavolo);
    const ordineSecondoTavolo = this.camerieri.find(c => c.tavolo === secondoTavolo);

    if (ordinePrimoTavolo && ordineSecondoTavolo) {
      // Unisci i carrelli
      if (!ordinePrimoTavolo.carrello) ordinePrimoTavolo.carrello = {};
      Object.assign(ordinePrimoTavolo.carrello, ordineSecondoTavolo.carrello);

      // Aggiorna l'ordine del primo tavolo
      this.commonService.updateOrdine(ordinePrimoTavolo.id, ordinePrimoTavolo);

      // Elimina l'ordine del secondo tavolo
      this.eliminaOrdine(ordineSecondoTavolo.ordine);

      // Aggiorna lo stato del secondo tavolo
      this.aggiornaTavolo(secondoTavolo, false);

      // Aggiorna l'array dei tavoli
      this.aggiornaDatiTavoli();

      console.log('Tavoli uniti con successo');
    } else {
      console.error('Uno o entrambi i tavoli selezionati non hanno ordini attivi');
    }

    // Resetta l'array dei tavoli da unire e disattiva la modalità unione
    this.tavoliDaUnire = [];
    this.modalitaUnione = false;
  }

  attivaModalitaUnione() {
    this.modalitaUnione = true;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
  }


  liberaTavolo(numeroTavolo: string) {
    const ordine = this.camerieri.find(c => c.tavolo === numeroTavolo);
    if (ordine) {
      this.eliminaOrdine(ordine.ordine);
      this.aggiornaTavolo(numeroTavolo, false);

      // Aggiorna l'array dei tavoli
      this.aggiornaDatiTavoli();

      console.log(`Tavolo ${numeroTavolo} liberato con successo`);
    } else {
      console.error(`Nessun ordine trovato per il tavolo ${numeroTavolo}`);
    }
  }

  aggiornaDatiTavoli() {
    this.tavoliService.getTavoli().pipe(takeUntil(this.destroy$)).subscribe(tavoliAggiornati => {
      this.tavoli = tavoliAggiornati;
    });
  }


  // Modifica la funzione eliminaOrdine esistente
  eliminaOrdine(ordine: any) {
    console.log("elimina ordine: ", ordine);
      this.commonService.deleteOrdine(this.currentOrder.id);
      this.router.navigate(['/cassa'], { fragment: 'nonav' });
  }

  idRistorante = '';

  camerieri = []
  carrello2 = {};


  getProperties(object: any) {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if (object !== undefined) {
      return Object.keys(object)
    }
  }

  getPropertiesPortata(object: any) {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    /*
    if (object !== undefined) {
      return Object.keys(object).filter((piatto) => this.currentOrder?.carrello[piatto].hasOwnProperty('portata')? this.currentOrder?.carrello[piatto]['portata'] == this.portata? true: this.portata == 4 : this.portata == 4)
    }
     */
    if (object !== undefined && this.currentOrder?.ordineInserimento) {
      return this.currentOrder.ordineInserimento.filter((piatto) => {
        const piattoObj = this.currentOrder?.carrello[piatto];
        return piattoObj.hasOwnProperty('portata')
            ? piattoObj['portata'] == this.portata || this.portata == 4
            : this.portata == 4;
      });
    }
    return [];
  }

  eliminazione = false;

  /*eliminaOrdine(ordine: any) {
    console.log("elimina ordine: ", ordine)

    this.commonService.deleteOrdine(this.camerieri[this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine)].id)

    this.camerieri = this.camerieri.filter(item => !(item.ordine == ordine))

    this.dataServiceGenerico.setCamerieri(this.camerieri)
  }*/

  aggiungiOrdine(tavolo?: any) {
    if (tavolo && tavolo.occupato === 1) {
      // Se il tavolo è occupato, trova l'ordine esistente e aprilo
      const ordineEsistente = this.camerieri.find(c => c.tavolo === tavolo.numero);
      if (ordineEsistente) {
        this.cambiaSchermata(1, this.camerieri.indexOf(ordineEsistente), ordineEsistente.ordine);
        return;
      }
    }

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    let filterderArray = this.camerieri.sort((a, b) => b.ordine - a.ordine);
    let nuovoOrdine = filterderArray.length > 0 ? +filterderArray[0].ordine + 1 : 1;

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: tavolo ? `Tavolo ${tavolo.numero}` : `Ordine 000${nuovoOrdine}`,
      ordine: nuovoOrdine,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      tavolo: tavolo ? tavolo.numero : null
    };

    this.camerieri.unshift(ordine);
    this.cambiaSchermata(1, 0, ordine.ordine);
    this.commonService.updateOrdine('c1' + psw, ordine);
    this.dataServiceGenerico.setCamerieri(this.camerieri);

    if (tavolo) {
      this.aggiornaTavolo(tavolo.numero, true);
    }
  }

  aggiornaTavolo(numeroTavolo: string, occupato: boolean) {
    this.tavoliService.aggiornaTavoloStato(numeroTavolo, occupato).then(() => {
      this.aggiornaDatiTavoli();
    });
  }

  getTitoloOrdine() {
    const ordineCorrente = this.currentOrder;
    return ordineCorrente?.tavolo ? `Tavolo ${ordineCorrente?.tavolo}` : `Scontrino #${ordineCorrente?.ordine}`;
  }

  getIndex(): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == this.ordineOrdine)
  }

  getIndexOrdine(ordine: any): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine)
  }

  calcolaTotale() {
    this.numeroElementi = 0;
    this.tot = 0;

      if (this.currentOrder !== undefined) {
        if (this.currentOrder.hasOwnProperty('carrello')) {
          this.getProperties(this.currentOrder?.carrello).forEach(piatto => {
            //console.log('piatto', piatto);
            this.numeroElementi = this.numeroElementi + this.currentOrder?.carrello[piatto].quantita;


            let prezzoPiatto = this.getPrezzoPiatto(this.currentOrder?.carrello[piatto]);
            this.tot = this.tot + (prezzoPiatto * this.currentOrder?.carrello[piatto].quantita);
            //console.log("tot nuovo: ", this.tot)
          });
        }
      }
    return this.tot
  }


  calcolaTotaleOrdine(ordine: any) {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if (this.camerieri[this.getIndexOrdine(ordine)].hasOwnProperty('carrello')) {
        this.getProperties(this.camerieri[this.getIndexOrdine(ordine)]?.carrello).forEach(piatto => {
          //console.log('piatto', piatto);
          this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita;

          let prezzoPiatto = this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].prezzo;
          this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita);
          //console.log("tot nuovo: ", this.tot)
        });
      }
    }
    return this.tot.toFixed(2)
  }

  modificaPrezzp(numerino: any): void {
    if (numerino == 'elimina') {
      this.prezzoPiatto = this.prezzoPiatto.slice(0, -1);
    } else {
      this.prezzoPiatto += numerino.toString();
    }
  }

  ordineOrdine = '';

  ordine = [];

  cambiaSchermata(schermo: number, index: any, index2: any): void {
    this.ordine = [];
    this.schermata = schermo;
    this.schermataIndex = index;
    this.ordineOrdine = index2;
    console.log("ordineOrdine: ", this.ordineOrdine, this.schermata, this.schermataIndex);
    this.calcolaTotale()
  }

  aggiungiPiatto(prezzo: any, index: number): void {
    this.tavoli[index].scontrino.push({piatto: "Piatto personalizzato", descrizione: "", quantita: 1, prezzo: prezzo},)
  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }

  cucina() {
    this.router.navigateByUrl('cucina#nonav');
  }


  piattoSelezionato2 = {}


  apriVarianti(piatto){
    if(piatto.variantIds != undefined && piatto.variantIds.length > 0) {
      this.openDialog(piatto, this.currentOrder, this.portata);
    }
  }




  updateOrdine(piatto) {
    console.log('abc', piatto.variantIds);

    if(piatto.variantIds?.length > 0) {
      const db = firebase.firestore();
      const restaurantName = this.dataServiceGenerico.getRistorante();
      const variantiRef = db.collection('varianti').doc(restaurantName);

      variantiRef.get().then(doc => {
        if (doc.exists) {
          const allVariants = doc.data()?.variants || [];
          const piattoVariants = allVariants.filter(v => piatto.variantIds.includes(v.id));
          const hasRequiredVariants = piattoVariants.some(v => v.isRequired);

          if (hasRequiredVariants) {
            this.openDialog(piatto, this.currentOrder, this.portata);
          } else {
            const firstVariant = piattoVariants[0];
            if (firstVariant?.options?.length > 0) {
              const nome = piatto.title.replace(/ /g,'');
              const uniqueId = `${nome}_${firstVariant.id}_${firstVariant.options[0].name}`;
              const totalPriceModifier = firstVariant.options[0].priceModifier || 0;

              // Verifica se esiste già nel carrello
              if (this.currentOrder.carrello[uniqueId]) {
                this.currentOrder.carrello[uniqueId].quantita++;
              } else {
                if (!this.currentOrder.carrello) {
                  this.currentOrder.carrello = {};
                }
                if (!this.currentOrder.ordineInserimento) {
                  this.currentOrder.ordineInserimento = [];
                }

                this.currentOrder.carrello[uniqueId] = {
                  ...piatto,
                  quantita: 1,
                  price: +piatto.price,
                  prezzo: +piatto.price + totalPriceModifier,
                  portata: this.portata == 4 ? 0 : this.portata,
                  variants: [{
                    variantId: firstVariant.id,
                    variantTitle: firstVariant.name,
                    name: firstVariant.options[0].name,
                    priceModifier: firstVariant.options[0].priceModifier || 0
                  }]
                };

                this.currentOrder.ordineInserimento.push(uniqueId);
              }

              this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
            } else {
              this.addToCartWithoutVariants(piatto);
            }
          }
        }
      });
    } else {
      this.addToCartWithoutVariants(piatto);
    }
  }

  private addToCartWithoutVariants(piatto) {
    const nome = piatto.title.replace(/ /g,'');

    if (!this.currentOrder.carrello) {
      this.currentOrder.carrello = {};
    }
    if (!this.currentOrder.ordineInserimento) {
      this.currentOrder.ordineInserimento = [];
    }

    if (this.currentOrder.carrello[nome]) {
      this.currentOrder.carrello[nome].quantita++;
    } else {
      this.currentOrder.carrello[nome] = {
        ...piatto,
        quantita: 1,
        price: +piatto.price,
        prezzo: +piatto.price,
        portata: this.portata == 4 ? 0 : this.portata
      };
      this.currentOrder.ordineInserimento.push(nome);
    }

    this.commonService.updateOrdine(this.currentOrder.id, this.currentOrder);
  }


// Helper per confrontare le varianti
  private haveSameVariants(variants1: any[] = [], variants2: any[] = []): boolean {
    if (!variants1 || !variants2) return false;
    if (variants1.length !== variants2.length) return false;

    return variants1.every(v1 =>
        variants2.some(v2 =>
            v2.variantId === v1.variantId &&
            v2.name === v1.name
        )
    );
  }


  testComande() {
    if (this.currentOrder?.carrello) {
      this.commonService.stampaComandePerDestinazioni(this.currentOrder)
          .then(success => {
            if (success) {
              console.log('Comande stampate con successo');
            } else {
              console.error('Errore nella stampa delle comande');
            }
          });
    }
  }


  isPaidItem(piatto: string): boolean {
    if (!this.currentOrder?.paidItems) return false;
    return this.currentOrder.paidItems.some(item => item.id === piatto);
  }


  avantiComanda()
  {
    console.log("Avanti "+ this.portata + " Portata")
  }
}
