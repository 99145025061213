<div class="row">
    <div class="column" style="height: 86vh;">
        <div style="display: flex; height: 100%;">
            <!-- Left Panel -->
            <div class="left-panel">
                <div class="sticky-container" style="margin-bottom: 20px; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem"
                                   [nome]="'Aggiungi Listino'"
                                   altezza="55"
                                   [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'"
                                   [bg]="'#33bfa3'"
                                   (click)="startEditing()">
                    </categorieCard>
                </div>

                <div class="price-lists-container">
                    <div class="price-list-card" *ngFor="let list of priceLists"
                         [class.selected]="selectedList?.id === list.id"
                         (click)="selectList(list)">
                        <div class="price-list-header">
                            <span class="price-list-name">{{list.name}}</span>
                            <div class="price-list-actions">
                                <!-- Duplicate button -->
                                <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-duplicate-64.png?alt=media&token=afa0cdd7-d7f3-47b5-9016-735c7a1954db"
                                     style="width: 20px; aspect-ratio: 1 / 1; margin-right: 5px;"
                                     title="Duplica Listino"
                                     (click)="duplicatePriceList(list.id, $event)">

                                <!-- Delete button -->
                                <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                                     style="width: 20px; aspect-ratio: 1 / 1;"
                                     title="Elimina Listino"
                                     (click)="deletePriceList(list.id, $event)">
                            </div>
                        </div>
                        <div class="price-list-details">
                            <div class="vat-info">
                                <span>IVA Counter: {{list.settings.counter.vatId === '1' ? '22%' : '10%'}}</span>
                                <span>IVA Table: {{list.settings.table.vatId === '1' ? '22%' : '10%'}}</span>
                                <span>IVA Takeaway: {{list.settings.takeaway.vatId === '1' ? '22%' : '10%'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Right Panel -->
            <div class="right-panel">
                <div *ngIf="selectedList" class="table-section">
                    <div class="container menu" style="background: white; border-radius: 25px; padding: 16px; height: 86vh; margin-top: 1vh; overflow-y: auto;">
                        <!-- Save Button -->
                        <div class="sticky-container mb-4">

                            <button type="button"
                                    class="lock-button"
                                    style="width: 100px"
                                    (click)="toggleDragLock()"
                                    [title]="isDragDisabled ? 'Sblocca ordinamento' : 'Blocca ordinamento'">
                                {{isDragDisabled ? 'Sblocca' : 'Blocca'}}
                            </button>
                            <!-- New A-Z Sort button -->
                            <button type="button"
                                    class="sort-button"
                                    (click)="sortAlphabetically()"
                                    title="Ordina alfabeticamente">
                                Az
                            </button>
                            <button type="button" class="save-button" (click)="saveAllPrices()">
                                <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ftags%2Ficons8-save-100.png?alt=media&token=9d158d4b-e034-4dea-bdf9-d1f1dd388580" style="width: 25px; aspect-ratio: 1 / 1;">
                            </button>
                        </div>

                        <!-- Table -->
                        <div class="table-responsive">
                            <mat-table [dataSource]="dataSource"
                                       cdkDropList
                                       [cdkDropListData]="dataSource.data"
                                       [cdkDropListDisabled]="isDragDisabled"
                                       (cdkDropListDropped)="onDrop($event)"
                                       class="mat-elevation-z8">


                                <!-- Image Column -->
                                <ng-container matColumnDef="image">
                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                    <mat-cell *matCellDef="let item">

                                        <div *ngIf="item.level" class="group-header" style="margin-left: 15px">{{item.category}}</div>
                                        <button *ngIf="!item.level"
                                                class="delete-button"
                                                (click)="removeProductFromList(item, $event)"
                                                style="background: none; border: none; padding: 4px;">
                                            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
                                                 style="width: 20px; aspect-ratio: 1 / 1;"
                                                 title="Rimuovi dal listino">
                                        </button>
                                        <img *ngIf="!item.level"
                                             [src]="item.image_link"
                                             [alt]="item.title"
                                             style="width: 40px; height: 40px; object-fit: cover; border-radius: 8px; margin-left: 15px">

                                    </mat-cell>
                                </ng-container>

                                <!-- Title Column -->
                                <ng-container matColumnDef="title">
                                    <mat-header-cell *matHeaderCellDef>Prodotto</mat-header-cell>
                                    <mat-cell *matCellDef="let item">
                                        <div class="d-flex align-items-center justify-content-between w-100">
                                            <span *ngIf="!item.level">{{item.title}}</span>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- Price and VAT columns -->
                                <ng-container *ngFor="let type of ['counter', 'table', 'takeaway']">
                                    <ng-container [matColumnDef]="type + '_price'">
                                        <mat-header-cell *matHeaderCellDef>Prezzo {{type}}</mat-header-cell>
                                        <mat-cell *matCellDef="let item">
                                            <input *ngIf="!item.level"
                                                   type="number"
                                                   [(ngModel)]="item[type + '_price']"
                                                   class="price-input">
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container [matColumnDef]="type + '_vat'">
                                        <mat-header-cell *matHeaderCellDef>IVA {{type}}</mat-header-cell>
                                        <mat-cell *matCellDef="let item">
                                            <mat-select *ngIf="!item.level"
                                                        [(ngModel)]="item[type + '_vat']"
                                                        class="vat-select">
                                                <mat-option value="1">22%</mat-option>
                                                <mat-option value="2">10%</mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"
                                         [class.group-row]="row.level"
                                         cdkDrag
                                         [cdkDragData]="row">
                                </mat-row>
                            </mat-table>
                        </div>
                    </div>
                </div>

                <!-- No list selected message -->
                <div *ngIf="!selectedList" class="empty-state">
                    Seleziona un listino per visualizzare i prodotti
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Edit Dialog -->
<div *ngIf="editingList" class="edit-dialog-overlay">
    <div class="edit-dialog">
        <fieldset class="custom-fieldset">
            <legend>{{editingList.id ? 'Modifica' : 'Nuovo'}} Listino</legend>
            <div class="chart-container">
                <input placeholder="Nome Listino"
                       class="custom-input mb-3"
                       [(ngModel)]="editingList.name"
                       (ngModelChange)="onFieldChange()">

                <div class="settings-grid">
                    <div class="setting-item">
                        <label>IVA Counter</label>
                        <mat-select class="custom-input"
                                    [(ngModel)]="editingList.settings.counter.vatId"
                                    (selectionChange)="onFieldChange()">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>

                    <div class="setting-item">
                        <label>IVA Table</label>
                        <mat-select class="custom-input"
                                    [(ngModel)]="editingList.settings.table.vatId"
                                    (selectionChange)="onFieldChange()">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>

                    <div class="setting-item">
                        <label>IVA Takeaway</label>
                        <mat-select class="custom-input"
                                    [(ngModel)]="editingList.settings.takeaway.vatId"
                                    (selectionChange)="onFieldChange()">
                            <mat-option value="1">22% IVA</mat-option>
                            <mat-option value="2">10% IVA</mat-option>
                        </mat-select>
                    </div>
                </div>

                <div class="buttons-container mt-3">
                    <button type="button"
                            class="save-button"
                            (click)="savePriceList()"
                            [disabled]="!editingList.name">
                        Salva Listino
                    </button>
                    <button type="button"
                            class="cancel-button"
                            (click)="cancelEdit()">
                        Annulla
                    </button>
                </div>
            </div>
        </fieldset>
    </div>
</div>