import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'navbar-report',
  styles: [`

    .menuItem{
      cursor: pointer !important;
    }

    .menuItem:hover {
      background-color: rgba(181,181,181,0.2) !important;
    }

    .sidebar {
      margin: 0;
      padding: 0;
      margin-top: 1%;
      margin-left: 1%;
      width: 220px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      height: 96%;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
      /*
      height: 100vh;
       */

    }

    .closed{
      width: 70px !important;
    }

    /* Sidebar links */
    .sidebar a {
      display: block;
      color: black;
      padding: 16px;
      text-decoration: none;
    }

    .sidebar b {
      display: block;
      color: #E65934;
      text-decoration: none;
      width: 220px;
      text-align: center;
      padding-top: 15%;
      padding-bottom: 30%;
      font-size: 30px;
    }

    /* Active/current link */
    .sidebar a.active {
      background-color: #FBFBFB;
      color: white;
    }

    /* Links on mouse-over */
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white;
    }

    /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
    div.content {
      margin-left: 200px;
      padding: 1px 16px;
      height: 1000px;
    }


    .bottom-elements {
      position: absolute;
      bottom: 0;
      margin-bottom: 7.5%;
      display: flex;
      width: 100%;
    }

    .block {
      width: calc(100% - 20px);
      height: 65px;
      margin: 2px 10px;
      display: inline-flex;
      border-radius: 20px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 580;
      color: #969696;

    //border-top: 1px solid #e3e2e2;


      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */


    }
    .cardSVG{
      margin-left: 10%;
      margin-bottom: 5px;
    }

    .selected {
      font-weight: 600;
      background-color: rgba(181,181,181,0.2) !important;
    //color: rgb(255, 102, 95);
      color: black;
    }

    .imgSelected{
    //filter: invert(66%) sepia(48%) saturate(1547%) hue-rotate(330deg) brightness(92%) contrast(88%);
      filter: brightness(0%);
    }

    .notSelected {
      color: #969696;
      fill: #969696;
    }

    /* On screens that are less than 700px wide, make the sidebar into a topbar */
    @media screen and (max-width: 700px) {
      .sidebar {
        width: 100%;
        height: auto;
        position: relative;
      }
      .sidebar a {float: left;}
      .sidebar b {
        float: left;
        width: 100%;
      }

      div.content {margin-left: 0;}
    }
    .reversed-a {
      display: inline-block;
      transform: rotate(180deg);
    }

    /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
    @media screen and (max-width: 400px) {
      .sidebar a {
        text-align: center;
        float: none;
      }
    }







  ` ],
  template: `
    <div class="sidebar shadow-sm" scroll="no" [ngClass]="{'closed': aperto === false}">

      <categorieCard class="col-12 col-md-12 " style="display: block; width: calc(100% - 20px); margin-bottom: 30%; margin-left: 10px; margin-top: 10px; cursor: pointer"
                     [nome]="'Home'"
                     link="assets/images/icons8-back-arrow-96.png"
                     [border]="'#e55933'"
                     (click)="cambiaRoute('magazzino')"
      >

      </categorieCard>


      <!--div class="menuItem"  [ngClass]="{'selected': this.posizione === 0, 'block': true}" (click)="cambiaRoute('home')">
        <svg class="cardSVG" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
          <g id="Iconly_Bold_Home" data-name="Iconly/Bold/Home" transform="translate(-2.5 -2)">
            <g id="Home">
              <path [ngClass]="{'selected': this.posizione === 0}" id="Vector" d="M6.635,18.773V15.716A1.419,1.419,0,0,1,8.058,14.3h2.874a1.429,1.429,0,0,1,1.007.414,1.408,1.408,0,0,1,.417,1v3.058a1.213,1.213,0,0,0,.356.867,1.231,1.231,0,0,0,.871.36h1.961a3.46,3.46,0,0,0,2.443-1A3.41,3.41,0,0,0,19,16.578V7.867a2.473,2.473,0,0,0-.9-1.9L11.434.676A3.1,3.1,0,0,0,7.485.747L.967,5.965A2.474,2.474,0,0,0,0,7.867v8.7A3.444,3.444,0,0,0,3.456,20H5.372a1.231,1.231,0,0,0,1.236-1.218Z" transform="translate(2.5 2)" fill="#969696"/>
            </g>
          </g>
        </svg>

        <div style="padding-left: 25px">Home</div>
      </div-->

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 0, 'block': true}" (click)="cambiaRoute('report')">
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 0}" src="assets/images/icons8-analyze-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


        <div style="padding-left: 25px">Riepilogo</div>
      </div>

      <div  class="menuItem"  [ngClass]="{'selected': this.posizione === 1, 'block': true}" (click)="cambiaRoute('costi-operativi')">
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 1}" src="assets/images/icons8-cost-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Costi Operativi</div>
      </div>

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 2, 'block': true}" (click)="cambiaRoute('foodcost')">

        <img [ngClass]="{'imgSelected': this.posizione === 2}" src="assets/images/icons8-trolley-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


        <div style="padding-left: 25px">Inventario</div>
      </div>

      <!--  #nonav   -->
      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 4, 'block': true}" (click)="cambiaRoute('incassi')" >
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 4}" src="assets/images/icons8-data-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Incassi</div>
      </div>



      <!--svg (click)="aperto = !aperto" style="bottom: 10px; position: absolute; right: 10px; cursor: pointer" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path d="M504.3 273.6c4.9-4.5 7.7-10.9 7.7-17.6s-2.8-13-7.7-17.6l-112-104c-7-6.5-17.2-8.2-25.9-4.4s-14.4 12.5-14.4 22l0 56-192 0 0-56c0-9.5-5.7-18.2-14.4-22s-18.9-2.1-25.9 4.4l-112 104C2.8 243 0 249.3 0 256s2.8 13 7.7 17.6l112 104c7 6.5 17.2 8.2 25.9 4.4s14.4-12.5 14.4-22l0-56 192 0 0 56c0 9.5 5.7 18.2 14.4 22s18.9 2.1 25.9-4.4l112-104z"/></svg-->

      <!--div  class="bottom-elements">
        <div class="menuItem" >
          <svg id="Setting" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Setting-2" data-name="Setting" width="24" height="24" fill="rgba(0,0,0,0)"/>
            <g id="Iconly_Light-Outline_Setting" data-name="Iconly/Light-Outline/Setting">
              <g id="Setting-3" data-name="Setting">
                <path id="Combined-Shape" d="M10.267,0A2.659,2.659,0,0,1,12.93,2.739a1.068,1.068,0,0,0,.151.51,1.153,1.153,0,0,0,1.583.415,2.671,2.671,0,0,1,3.64.977l.623,1.079a.85.85,0,0,1,.042.086,2.673,2.673,0,0,1-1.01,3.545,1.142,1.142,0,0,0-.424.421,1.174,1.174,0,0,0-.12.883,1.142,1.142,0,0,0,.54.7,2.654,2.654,0,0,1,.97,3.637L18.261,16.1a2.665,2.665,0,0,1-3.627.959,1.191,1.191,0,0,0-.558-.155H14.07a1.157,1.157,0,0,0-.8.338,1.143,1.143,0,0,0-.337.821,2.67,2.67,0,0,1-2.664,2.657H9.014A2.666,2.666,0,0,1,6.35,18.058a1.076,1.076,0,0,0-.151-.532A1.145,1.145,0,0,0,4.623,17.1,2.663,2.663,0,0,1,.98,16.07L.355,14.993a2.658,2.658,0,0,1,.97-3.625,1.163,1.163,0,0,0,0-2.015A2.668,2.668,0,0,1,.354,5.72l.678-1.113a2.661,2.661,0,0,1,3.622-.966,1.1,1.1,0,0,0,.552.157A1.167,1.167,0,0,0,6.36,2.652,2.651,2.651,0,0,1,9.014,0Zm0,1.5H9.014A1.151,1.151,0,0,0,7.86,2.659,2.677,2.677,0,0,1,5.2,5.3a2.58,2.58,0,0,1-1.3-.362,1.165,1.165,0,0,0-1.576.436L1.645,6.485a1.161,1.161,0,0,0,.432,1.57,2.663,2.663,0,0,1,0,4.612,1.155,1.155,0,0,0-.421,1.575l.631,1.088a1.166,1.166,0,0,0,1.594.464,2.652,2.652,0,0,1,2.005-.265A2.668,2.668,0,0,1,7.5,16.77a2.563,2.563,0,0,1,.355,1.28,1.166,1.166,0,0,0,1.164,1.171h1.253a1.165,1.165,0,0,0,1.164-1.157A2.653,2.653,0,0,1,14.1,15.405a2.723,2.723,0,0,1,1.282.354,1.171,1.171,0,0,0,1.59-.421l.664-1.107a1.156,1.156,0,0,0-.426-1.572,2.637,2.637,0,0,1-1.242-1.618,2.671,2.671,0,0,1,.271-2.019,2.639,2.639,0,0,1,.971-.969,1.166,1.166,0,0,0,.417-1.578.558.558,0,0,1-.035-.069L17,5.39a1.165,1.165,0,0,0-1.586-.429,2.653,2.653,0,0,1-3.628-.95,2.554,2.554,0,0,1-.359-1.286,1.193,1.193,0,0,0-.329-.874A1.168,1.168,0,0,0,10.267,1.5ZM9.645,6.974a3.386,3.386,0,1,1-3.386,3.387A3.391,3.391,0,0,1,9.645,6.974Zm0,1.5a1.886,1.886,0,1,0,1.886,1.887A1.889,1.889,0,0,0,9.645,8.474Z" transform="translate(2 2)" fill="#0b1023"/>
              </g>
            </g>
          </svg>


          <div class="menuItem"  style="padding-left: 30px; margin-bottom: 12%; margin-top: -13%">Settings</div>
        </div>


        <svg class="menuItem"  id="Logout" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <rect id="Logout-2" data-name="Logout" width="24" height="24" fill="rgba(0,0,0,0)"/>
          <g id="Iconly_Bold_Logout" data-name="Iconly/Bold/Logout">
            <g id="Logout-3" data-name="Logout">
              <path id="Vector" d="M9.493,0A4.48,4.48,0,0,1,14,4.44V9.23H7.9a.77.77,0,1,0,0,1.54H14v4.78A4.493,4.493,0,0,1,9.472,20H4.517A4.482,4.482,0,0,1,0,15.56V4.45A4.493,4.493,0,0,1,4.528,0ZM16.54,6.55a.764.764,0,0,1,1.09-.01l2.92,2.91a.764.764,0,0,1,.23.55.741.741,0,0,1-.23.54l-2.92,2.91a.771.771,0,0,1-1.09-1.09l1.6-1.59H14V9.23h4.14l-1.6-1.59A.773.773,0,0,1,16.54,6.55Z" transform="translate(2 2)" fill="rgba(230,89,52,0.8)"/>
            </g>
          </g>
        </svg>


        <div class="menuItem"  style="padding-left: 30px; margin-top: -13%; color: rgba(230,89,52,1)">LogOut</div>

      </div-->


    </div>
  `
})
export class NavbarReportComponent implements OnInit{

  aperto = true;

  posizione: any;

  private readonly canGoBack: boolean;

  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

    this.immagini = this.dataServiceGenerico.getImmagini()
  }
  constructor(
    // public authService: AuthService,
    private router: Router,
    private _location: Location,
    private  route: ActivatedRoute,
    private dataServiceGenerico: DataServiceGenerico
  ) {

    this.canGoBack = !!(this.router.getCurrentNavigation()?.previousNavigation);

    router.events.subscribe((val) => {
      // see also
      if ('url' in val)
      {
        //console.log("hdhdhdhdhdhdhhdhd", val.url);
        if (val.url.includes('report'))
        {
          this.posizione = 0;
          //console.log('home');
        }
        if (val.url.includes('costi-operativi'))
        {
          this.posizione = 1;
          //console.log('home');
        }
        if (val.url.includes('foodcost'))
        {
          this.posizione = 2;
          //console.log('home');
        }
        if (val.url.includes('operativita'))
        {
          this.posizione = 3;
          //console.log('home');
        }
        if (val.url.includes('incassi'))
        {
          this.posizione = 4;
          //console.log('home');
        }
      }
    });

  }


  /**
   *
   * Costo del venduto 6 [Costo dei prodotti dell'inventario venduti nell'intervallo]
   *
   *
   * sales mix giornaliero 46 [sales mix giornaliero nel periodo per categoria con ricavi]
   * sales journal 91 [Sales journal con vendite sconti addebiti riconciliazioni]
   * sales mix 1 [sales mix con quantità prezzo e ricavo]
   * sales mix forecast 56 [sales mix con quantità prezzo e ricavo]
   * sales mix percentuale 30 [sales mix con le percentuali di categoria e di menù]
   *
   *
   * 20 articoli più venduti 284 [articoli dell'inventario]
   * Forecast items usage bt day 249  [articoli inventario usage by day]
   *
   *
   * Inventario - Valore magazzino 245 [Inventario valore magazzino]
   * Inventario teorico attuale 40 [Inventario per pezzi]
   * Inventory changed 20 [Variazione assoluta tra due date del magazzino]
   *
   *
   * Menu items by category 58 [items by category cost]
   * raw material each item 49 [Raw material nelle preparazioni]
   * recipe cards 16 [raw material nelle preparazioni con il costo di vendita e il food cost percentuale]
   *
   *
   *
   * table of weights and measures 27 [Unità di misura americane] [Inutile]
   *
   * Discrepanze 116 [No idea cifre]
   *
   * Acquisti in dettaglio 99 [Vuoto]
   * Depositi 111 [Vuoti]
   * promozioni 306 [Vuoto]
   *
   * List raw material 120 [Non c'è]
   * vendor selection report 121 [Non presente]
   * vendor bid worksheet 120 [Non presente]
   *
   *
   *
   *
   *
   * NOSTRO
   * Costi operativi
   *
   * 188 [Riepilogo inventario Inizio periodo-acquisti-trasferimenti-finale / costo per unità / utilizzo] [mostra la varianza rispetto al teorico]
   * --- Food cost (Ripeilogo dell'inventaruio inclusi sprechi e correzioni dell'inventario) 188 [Riepilogo dell'inventario inclusi sprechi e correzioni] [Il più importante]
   * --- Food cost (attuale vs teorico) 278 [Vuoto] [Check]
   * --- Food cost (Inventario) 178 [articoli dell'inventario]
   * --- cost of goods in dollars 3 [Inventario prodotti iniziale-acquisti-trasferimenti-finale]
   *
   * Profit and loss [profit and losses]
   * --- New profit and loss recap by site 320 [Recap profit and loss by site] [Probabilmente nostro riepilogo]
   * --- profit and loss 104 [Profit and loss]
   *
   * Pasti dipendenti
   * --- pasti dipendenti 306 [Pasti dipendenti per categorie]
   *
   * Scarti
   * --- scarti 306 [Lista degli scarti per categoria con quantità, costo unitario e somma]
   *
   */

  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout() {
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }

  back()
  {
      this._location.back();
  }

  cambiaRoute(route): void {

    this.router.navigateByUrl(route);

  }
}
