
<div style="max-height: 300px; width: 600px !important">
  <img [src]=" data.dataKey? data.dataKey.image_link: linkImg" style="max-height: 310px; margin-top: 0px; background-color: transparent; margin-left: 145px; margin-right: 10px; scale: 70%; overflow-clip-margin: content-box">
</div>
<div style="text-align: center;border-radius: 20px; font-size: xx-large; font-weight: 900; width: 600px">
  {{ data.dataKey? data.dataKey.title: 'Titolo mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: x-large; max-width: 70%; margin-left: 15%; font-weight: 500">
  {{ data.dataKey? data.dataKey.descrizione: 'Descrizione mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: large; font-weight: 400">
  {{ data.dataKey? data.dataKey.price + '€': '0.00€' }}
</div>

<div class="container" style="padding-top: 15px" *ngIf="(primoClick == 0) && (data.dataKey? data.dataKey.menu : false) && (data.dataKey? data.dataKey.single : false) && (data.dataKey? !!!data.dataKey.bucket : false)">
  <div style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: x-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer" (click)="primoClick = 1">Solo Bucket
  </div>
  <div (click)="scegliPiatto(1)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: x-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer">Menu Piccolo
    <div style="font-size: large; margin-top: -40px; line-height: 0px; color: #d66c2c">
      +{{ (+data.dataKey.prezziMenu[0]).toFixed(2) }}€
    </div>
  </div>
  <div (click)="scegliPiatto(2)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: x-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer">Menu Medio
    <div style="font-size: large; margin-top: -40px; line-height: 0px; color: #d66c2c">
      +{{ (+data.dataKey.prezziMenu[1]).toFixed(2) }}€
    </div>
  </div>
</div>

<div class="container" style="padding-top: 15px" *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : false) && (data.dataKey? data.dataKey.single : false) && (data.dataKey? !!data.dataKey.bucket : true))">
  <div style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: x-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer" (click)="primoClick = 1">Solo Bucket
  </div>
  <div (click)="scegliPiatto(3)"  style="text-align: center; height: 200px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 200px; border: 1px solid #e3e2e2; cursor: pointer">Menu
    <div style="font-size: large; margin-top: -40px; line-height: 0px; color: #d66c2c">
      +{{ (+data.dataKey.prezziMenu[0]).toFixed(2) }}€
    </div>
  </div>
</div>

<div (click)="scegliPiatto(3)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: x-large; line-height: 150px; border: 1px solid #e3e2e2; cursor: pointer" *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : false) && (data.dataKey? data.dataKey.single : false) && (data.dataKey? !!!data.dataKey.bucket : false))">
  Menu Large
  <div style="font-size: large; margin-top: -40px; line-height: 0px; color: #157315">
    +{{ (+data.dataKey.prezziMenu[2]).toFixed(2) }}€
  </div>
</div>


<div *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : true) && (data.dataKey? !data.dataKey.single : true)) || (primoClick == 1) || (data.dataKey.menu == false)" style="height: 150px; margin: auto; overflow: hidden; padding-top: 50px">
  <div style="width: 220px">
    <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center">Scegli la quantità!</span>

    <div style="background-color: transparent; float: left; width: 400px; height: 50px !important; margin-left: 20px">
      <div style="background-color: white; cursor: pointer; height: 50px !important; width: 50px; float: left; border-radius: 20px; text-align: center; line-height: 50px; font-size: xx-large; font-weight: 600; margin: 10px; border: 1px solid #e3e2e2" (click)="quantita > 1? quantita = quantita - 1: ''">
        -
      </div>
      <span style="background-color: transparent; font-size: x-large; font-weight: 900; margin-left: 10px; margin-right: 10px; margin-top: 20px; float: left">{{quantita}}</span>
      <div style="background-color: white; cursor: pointer; height: 50px !important; width: 50px; float: left; border-radius: 20px; text-align: center; line-height: 50px; font-size: xx-large; font-weight: 600; margin: 10px; border: 1px solid #e3e2e2" (click)="quantita = quantita + 1">
        +
      </div>
    </div>
  </div>


</div>

<div (click)="aggiungiPiatto2()" *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : true) && (data.dataKey? !data.dataKey.single : true)) || (primoClick == 1) || (data.dataKey.menu == false)" style="text-align: center; height: 150px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 130px; border: 1px solid #e3e2e2; cursor: pointer">
  Aggiungi
  <div style="font-size: large; margin-top: -25px; line-height: 0px; color: #157315">{{(getPrezzo2()*quantita).toFixed(2)}}€</div>
</div>


