import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OnChanges, SimpleChanges } from '@angular/core';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import 'hammerjs';

@Component({
  selector: 'menuDetailCard-bar',
  styles: [`
    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      transition: 0.3s;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .background-allergia {
      background-color: rgba(252,232,232,0.6);
    }

    .background-stadard {
      background-color: white;
    }

    .last {
      max-height: 90px !important;
      border-bottom: solid 1px rgba(181,181,181, 0.3);
      border-bottom-right-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }

    .first {
      max-height: 90px !important;
      padding-top: 10px;
      border-top: solid 1px rgba(181,181,181, 0.3) !important;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
    }

    .solo {
      max-height: 90px !important;
      padding-top: 10px;
      border-top: solid 1px rgba(181,181,181, 0.3) !important;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;
      border-bottom: solid 1px rgba(181,181,181, 0.3);
      border-bottom-right-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    b, p {
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;
    }

    .aggiungi:hover, .aggiungi:active {
      filter: invert(100%) sepia(100%) saturate(500%) hue-rotate(90deg);
    }

    .rimuovi:hover, .rimuovi:active {
      filter: invert(48%) sepia(85%) saturate(7495%) hue-rotate(-15deg) brightness(92%);
    }

    .block {
      width: 100%;
      min-height: 50px;
      margin: 0px;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      color: black;
      background-color: white;
      transition: 0.3s;
      padding: 10px 0;
    }

    .content-row {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .details-column {
      display: flex;
      flex-direction: column;
      width: 74%;
      padding-left: 15px;
    }

    .verde {
      color: green;
      transition: color 0.3s ease;
    }

    .rosso {
      color: red;
      transition: color 0.3s ease;
    }

    .additions {
      color: green;
      font-size: 14px;
    }

    .removals {
      color: red;
      font-size: 14px;
    }

    .note {
      color: blue;
      font-size: 14px;
      font-style: italic;
    }
  `],
  template: `
    <div class="block" (swipeleft)="handleSwipe('left')" (swiperight)="handleSwipe('right')">
      <div class="content-row">
        <div [ngClass]="{'verde': isGreen, 'rosso': isRed}" style="width: 7%; text-align: center; font-weight: 580; margin: 0px 10px ;">
          {{this.quantita}}x
        </div>
        <div style="text-align: center; margin-right: 10px;">
          <img class="store" style="width: 40px; height: 40px; max-height: 40px; max-width: 40px; background-size: 100% auto; transform: scale(1)"
               [src]="this.link"
               onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';">
        </div>
        <div class="details-column">
          <div style="width: 100%; font-size: 17px; font-weight: normal; color: #4A4A4A">{{this.nome}}</div>
          <div style="width: 100%; font-size: 15px; font-weight: normal; color: gray">{{this.ingredienti}}</div>
          <div *ngIf="this.aggiunte && this.aggiunte.length > 0" class="additions">
            Aggiunte: {{this.aggiunte.join(', ')}}
          </div>
          <div *ngIf="this.rimozioni && this.rimozioni.length > 0" class="removals">
            Rimozioni: {{this.rimozioni.join(', ')}}
          </div>
          <div *ngIf="this.nota" class="note" >
            Nota: {{this.nota}}
          </div>
          <div *ngIf="this.variants && this.variants.length > 0" class="variants">
            <div *ngFor="let variant of variants" style="color: rgb(229, 89, 51); font-size: 14px;">
              {{variant.variantTitle}}: {{variant.name}} {{variant.priceModifier > 0 ? ' + ' + variant.priceModifier.toFixed(2) + '€' : ''}}
            </div>
          </div>
        </div>
        <div style="text-align: right; padding-right: 15px;font-weight: 580;">
          {{(this.prezzo * this.quantita).toFixed(2)}}€
        </div>
      </div>
      
    </div>
    <div *ngIf="!this.last" style="margin-left:15px; margin-right:15px; height: 1px; background-color: rgba(181,181,181,0.3);"></div>
  `
})
export class MenuDetailCardBarComponent implements OnChanges {

  @Input() id: string;
  @Input() nome: string;
  @Input() ingredienti: string;
  @Input() link: string;
  @Input() prezzo: number;
  @Input() quantita: number;
  @Input() allergene: string;
  @Input() piatto: any;
  @Input() user: any;
  @Input() last: boolean;
  @Input() first: boolean;
  @Input() pagato: number;
  @Input() carrello: boolean;
  @Input() lenght: number;
  @Input() aggiunte: string[];
  @Input() rimozioni: string[];
  @Input() nota: string;
  @Input() variants: any[];

  @Output() quantitaOut = new EventEmitter<number>();

  allergia = false;
  allergeni = '';


  isGreen = false;
  isRed = false;

  constructor(
      private router: Router,
      public dataServiceGenerico: DataServiceGenerico
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.allergia = false;
    this.allergeni = '';

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.ingredienti) {
          this.piatto.ingredienti.forEach(ingrediente => {
            ingrediente.allergeni.forEach(allergeneIngrediente => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneIngrediente === allergeneUser) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneIngrediente;
                }
              });
            });
          });
        }
      }
    }

    if (this.piatto !== undefined) {
      if (this.piatto.hasOwnProperty('ingredienti')) {
        if (this.piatto.opzioni) {
          this.piatto.opzioni.forEach(opzione => {
            opzione.allergeni.forEach(allergeneOpzione => {
              this.user.allergeni.forEach(allergeneUser => {
                if (allergeneUser === allergeneOpzione) {
                  this.allergia = true;
                  this.allergeni += ' ' + allergeneOpzione;
                }
              });
            });
          });
        }
      }
    }
  }

  handleSwipe(direction: string) {
    if(this.pagato !== 0) {
      if (direction === 'left') {
        this.rimuoviProdotto();
      } else if (direction === 'right') {
        this.aggiungiProdotto();
      }
    }
  }

  aggiungiProdotto() {
    this.triggerColorChange('green');
    this.quantita = this.quantita + 1;
    this.quantitaOut.emit(this.quantita);
  }

  rimuoviProdotto() {
    this.triggerColorChange('red');
    if (this.quantita > 0) {
      this.quantita = this.quantita - 1;
      this.quantitaOut.emit(this.quantita);
    }
  }

  private triggerColorChange(color: 'green' | 'red') {
    if (color === 'green') {
      this.isGreen = true;
    } else {
      this.isRed = true;
    }

    setTimeout(() => {
      this.isGreen = false;
      this.isRed = false;
    }, 700);
  }

  clicco(): void {
    console.log('ciaociaociaociao', this.nome);
  }

  logout(): void {
    this.router.navigateByUrl('login');
  }
}