import { Injectable } from '@angular/core';
import { Observable, from, BehaviorSubject, of, combineLatest } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataServiceGenerico } from "../data-service/data.service";

export interface InventoryLevel {
    quantita: string;
    um: string;
    barCode?: string;
}

export interface InventoryMovement {
    data: string;
    timestamp: number;
    quantitaPrec: string;
    quantitaAgg: string;
    lvl: InventoryLevel[];
    modalita: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento' | 'inventario' | 'vendita';
    userId?: string;
    note?: string;
}

// Interfaccia per i fornitori
export interface Fornitore {
    id: string;
    nome: string;
    partitaIva?: string;
    telefono?: string;
    email?: string;
    prodottiAssociati: ProdottoAssociato[];
}

// Interfaccia per i prodotti associati ai fornitori
export interface ProdottoAssociato {
    prodottoId: string;
    prezzoAcquisto: number;
    tempoDiConsegna: number;
    minOrdine: number;
    note: string;
}

// Interfaccia per il fornitore associato al prodotto nell'inventario
export interface FornitoreAssociato {
    id: string;
    nome: string;
    prezzoAcquisto: number;
    tempoDiConsegna?: number;
}

export interface InventoryItem {
    id: string;
    productId: string; // Reference to the product in menu_test
    productTitle: string; // For easy reference without joining
    category: string;
    lvl: InventoryLevel[];
    maxLvl: number;
    movimenti: InventoryMovement[];
    lastUpdated: number;
    // Campo opzionale per i fornitori associati
    fornitori?: FornitoreAssociato[];
}

@Injectable({
    providedIn: 'root'
})
export class InventoryService {
    private _inventoryData = new BehaviorSubject<InventoryItem[]>([]);
    public inventoryData$: Observable<InventoryItem[]> = this._inventoryData.asObservable();

    constructor(private dataService: DataServiceGenerico) {}

    /**
     * Initialize inventory collection if it doesn't exist
     */
    public async initInventoryCollection(): Promise<void> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        // Check if inventory collection exists for this restaurant
        const inventoryRef = db.collection('inventario').doc(restaurantId);
        const doc = await inventoryRef.get();

        if (!doc.exists) {
            // Create inventory document with empty items array
            await inventoryRef.set({
                ristorante: restaurantId,
                items: []
            });
            console.log('Inventario collection initialized for restaurant:', restaurantId);
        }
    }

    /**
     * Migrate existing inventory data from menu_test to inventario collection
     */
    public async migrateInventoryData(): Promise<void> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        try {
            console.log('Starting inventory migration for restaurant:', restaurantId);

            // Get existing menu data
            const menuRef = db.collection('menu_test').doc(restaurantId);
            const menuDoc = await menuRef.get();

            if (!menuDoc.exists) {
                console.error('No menu data found for migration');
                // Emit empty data to stop loading state
                this._inventoryData.next([]);
                return;
            }

            const menuData = menuDoc.data();
            const menuItems = menuData.items || [];

            console.log(`Found ${menuItems.length} menu items to check for inventory data`);

            // Filter items that have inventory data (lvl property)
            const itemsWithInventory = menuItems.filter(item => item.lvl && item.lvl.length > 0);

            console.log(`Found ${itemsWithInventory.length} items with inventory data (lvl property)`);

            if (itemsWithInventory.length === 0) {
                console.log('No inventory data to migrate');
                // Emit empty data so the UI updates
                this._inventoryData.next([]);
                return;
            }

            // Create inventory items from menu items
            const inventoryItems: InventoryItem[] = itemsWithInventory.map(item => {
                // Convert existing cronologia entries to the new format
                const movimenti: InventoryMovement[] = (item.cronologia || []).map(entry => {
                    try {
                        const dateParts = entry.data.split(', ');
                        const [day, month, year] = dateParts[0].split('/');
                        const timeParts = dateParts[1]?.split(':') || ['00', '00', '00'];

                        const timestamp = new Date(
                            parseInt(year),
                            parseInt(month) - 1,
                            parseInt(day),
                            parseInt(timeParts[0]),
                            parseInt(timeParts[1]),
                            parseInt(timeParts[2])
                        ).getTime();

                        return {
                            ...entry,
                            timestamp
                        };
                    } catch (error) {
                        console.error('Error parsing date in cronologia entry:', error, entry);
                        // Return entry with current timestamp as fallback
                        return {
                            ...entry,
                            timestamp: Date.now()
                        };
                    }
                });

                // Ensure lvl is properly structured
                console.log("item lvl: ", item.lvl)
                const validLvl = Array.isArray(item.lvl)
                    ? item.lvl.map(level => ({
                        quantita: level.quantita || '0',
                        um: level.um || '',
                        barCode: level.barCode || ''
                    }))
                    : item.lvl
                        ? [{
                            quantita: item.lvl.quantita || '0',
                            um: item.lvl.um || '',
                            barCode: item.lvl.barCode || ''
                        }]
                        : [];

                return {
                    id: db.collection('inventario').doc().id, // Generate new ID for inventory item
                    productId: item.id,
                    productTitle: item.title || 'Prodotto senza nome',
                    category: item.category || 'Senza categoria',
                    lvl: validLvl,
                    maxLvl: item.maxLvl || validLvl.length,
                    movimenti: movimenti || [],
                    lastUpdated: Date.now()
                };
            });

            console.log(`Created ${inventoryItems.length} inventory items, saving to Firestore...`);

            // Save to inventario collection
            const inventoryRef = db.collection('inventario').doc(restaurantId);
            await inventoryRef.set({
                ristorante: restaurantId,
                items: inventoryItems
            });

            console.log(`Successfully migrated ${inventoryItems.length} items to inventory collection`);

            // Update _inventoryData subject
            this._inventoryData.next(inventoryItems);

        } catch (error) {
            console.error('Error during inventory migration:', error);
            // Emit empty data to prevent UI from being stuck in loading state
            this._inventoryData.next([]);
            throw error;
        }
    }

    /**
     * Load all inventory items for the current restaurant
     */
    public loadInventoryData(): Observable<InventoryItem[]> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        console.log(`Loading inventory data for restaurant: ${restaurantId}`);

        return from(db.collection('inventario').doc(restaurantId).get()).pipe(
            map(doc => {
                if (!doc.exists) {
                    console.log('No inventory document exists for this restaurant, empty inventory');
                    return [];
                }
                const data = doc.data();
                const items = (data.items || []) as InventoryItem[];
                console.log(`Loaded ${items.length} inventory items`);
                return items;
            }),
            tap(items => {
                this._inventoryData.next(items);
            }),
            catchError(error => {
                console.error('Error loading inventory data:', error);
                // Make sure we emit something to update the UI state
                this._inventoryData.next([]);
                return of([]);
            })
        );
    }

    /**
     * Carica i fornitori dal Firestore
     */
    public loadFornitoriData(): Observable<Fornitore[]> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        return from(db.collection('fornitori').doc(restaurantId).get()).pipe(
            map(doc => {
                if (!doc.exists) {
                    console.log('Nessun fornitore trovato per questo ristorante');
                    return [];
                }
                const data = doc.data();
                return (data.fornitori || []) as Fornitore[];
            }),
            catchError(error => {
                console.error('Errore nel caricamento dei fornitori:', error);
                return of([]);
            })
        );
    }

    /**
     * Carica i dati dell'inventario arricchiti con informazioni sui fornitori
     */
    public loadInventoryWithFornitori(): Observable<InventoryItem[]> {
        return combineLatest([
            this.loadInventoryData(),
            this.loadFornitoriData()
        ]).pipe(
            map(([items, fornitori]) => {
                // Per ogni elemento dell'inventario, cerca i fornitori associati
                return items.map(item => {
                    const itemFornitori = fornitori
                        .filter(f => f.prodottiAssociati && f.prodottiAssociati.some(p => p.prodottoId === item.productId))
                        .map(f => {
                            const prodottoAssociato = f.prodottiAssociati.find(p => p.prodottoId === item.productId);
                            return {
                                id: f.id,
                                nome: f.nome,
                                prezzoAcquisto: prodottoAssociato?.prezzoAcquisto || 0,
                                tempoDiConsegna: prodottoAssociato?.tempoDiConsegna || 0
                            };
                        });

                    // Aggiungi i fornitori all'elemento dell'inventario
                    return {
                        ...item,
                        fornitori: itemFornitori
                    };
                });
            }),
            tap(enrichedItems => {
                this._inventoryData.next(enrichedItems);
            })
        );
    }

    /**
     * Ottieni tutti i fornitori per la selezione filtro
     */
    public getFornitoriPerFiltro(): Observable<{id: string, nome: string}[]> {
        return this.loadFornitoriData().pipe(
            map(fornitori => fornitori.map(f => ({ id: f.id, nome: f.nome })))
        );
    }

    /**
     * Get inventory item by product ID
     */
    public getInventoryItemByProductId(productId: string): Observable<InventoryItem | null> {
        return this.inventoryData$.pipe(
            map(items => items.find(item => item.productId === productId) || null)
        );
    }

    /**
     * Add a movement to an inventory item and update the current levels correctly
     */
    public async addMovement(
        productId: string,
        movement: Omit<InventoryMovement, 'timestamp'>,
        newLevels: InventoryLevel[]
    ): Promise<void> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();
        const inventoryRef = db.collection('inventario').doc(restaurantId);

        try {
            // Get current inventory data
            const doc = await inventoryRef.get();
            if (!doc.exists) {
                throw new Error('Inventory document not found');
            }

            const data = doc.data();
            const items = data.items || [];

            // Find the inventory item for this product
            const itemIndex = items.findIndex(item => item.productId === productId);
            if (itemIndex === -1) {
                throw new Error(`Inventory item not found for product ID: ${productId}`);
            }

            // Add timestamp to the movement and ensure no undefined values
            const timestampedMovement: InventoryMovement = {
                data: movement.data || '',
                quantitaPrec: movement.quantitaPrec || '{}',
                quantitaAgg: movement.quantitaAgg || '{}',
                lvl: movement.lvl || [],
                modalita: movement.modalita,
                timestamp: Date.now(),
                note: movement.note || ''  // Ensure note is never undefined
            };

            // Update the item with new levels - use the provided levels directly
            const updatedItem: InventoryItem = {
                ...items[itemIndex],
                lvl: newLevels,  // Use the new levels directly (already updated by the calling method)
                movimenti: [timestampedMovement, ...(items[itemIndex].movimenti || [])],
                lastUpdated: Date.now()
            };

            items[itemIndex] = updatedItem;

            // Create a clean object for Firestore update with no undefined values
            const updateData = {
                items: items.map(item => {
                    // Create a clean copy of each item
                    const cleanItem = { ...item };

                    // Ensure all properties have valid values
                    if (cleanItem.movimenti) {
                        cleanItem.movimenti = cleanItem.movimenti.map(mov => {
                            return {
                                data: mov.data || '',
                                timestamp: mov.timestamp || Date.now(),
                                quantitaPrec: mov.quantitaPrec || '{}',
                                quantitaAgg: mov.quantitaAgg || '{}',
                                lvl: (mov.lvl || []).map(l => ({
                                    quantita: l.quantita || '0',
                                    um: l.um || '',
                                    barCode: l.barCode || ''
                                })),
                                modalita: mov.modalita,
                                userId: mov.userId || '',
                                note: mov.note || ''
                            };
                        });
                    }

                    return cleanItem;
                })
            };

            // Update Firestore with the sanitized data
            await inventoryRef.update(updateData);

            // Update local data
            const currentItems = this._inventoryData.value;
            const updatedItems = [...currentItems];
            const localItemIndex = updatedItems.findIndex(item => item.productId === productId);

            if (localItemIndex !== -1) {
                updatedItems[localItemIndex] = updatedItem;
                this._inventoryData.next(updatedItems);
            }
        } catch (error) {
            console.error('Error updating inventory:', error);
            throw error;
        }
    }
    /**
     * Calculate current quantities for an inventory item based on movements
     */
    /**
     * Calculate current quantities for an inventory item based on the latest state
     * This revised version fixes the double-counting issue
     */
    public calculateCurrentQuantities(item: InventoryItem): Record<string, string> {
        if (!item || !item.lvl) return {};

        // Get quantities directly from the lvl property
        const quantities = item.lvl.map((level, index) => {
            return {
                level: `livello${index + 1}`,
                quantita: parseInt(level.quantita || '0', 10),
                um: level.um || ''
            };
        });

        // Format the results
        const result = quantities.reduce((acc, q) => {
            acc[q.level] = `${q.quantita} ${q.um}`;
            return acc;
        }, {});

        return result;
    }


    /**
     * Add a sales movement to the inventory when an order is processed
     */
    public async recordSale(
        productId: string,
        quantity: number,
        orderId: string
    ): Promise<void> {
        console.log(`Recording sale for product ${productId}, quantity: ${quantity}, order: ${orderId}`);

        // Get the inventory item
        const items = this._inventoryData.value;
        const item = items.find(i => i.productId === productId);

        if (!item || !item.lvl || item.lvl.length === 0) {
            console.warn(`No inventory item found for product ${productId}`);
            return;
        }

        // Get the current quantities
        const currentQuantities = this.calculateCurrentQuantities(item);
        console.log('Current quantities before sale:', currentQuantities);

        // Create a movement for the sale
        const movement: Omit<InventoryMovement, 'timestamp'> = {
            data: new Date().toLocaleString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }),
            quantitaPrec: JSON.stringify(currentQuantities),
            quantitaAgg: JSON.stringify({ livello1: quantity }),
            lvl: [
                {
                    quantita: quantity.toString(),
                    um: item.lvl[0].um || ''
                }
            ],
            modalita: 'vendita',
            note: `Ordine #${orderId}`
        };

        // Clone the levels
        const newLevels = [...item.lvl];

        try {
            // Add the movement
            await this.addMovement(productId, movement, newLevels);
            console.log(`Sale recorded successfully for product ${productId}`);
        } catch (error) {
            console.error(`Failed to record sale for product ${productId}:`, error);
            throw error;
        }
    }

    /**
     * Sync inventory with order data to record sales
     */
    public async syncInventoryWithOrders(): Promise<void> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        console.log(`Syncing inventory with orders for restaurant: ${restaurantId}`);

        try {
            // Check if there are any inventory items to sync with
            const inventoryDoc = await db.collection('inventario').doc(restaurantId).get();
            if (!inventoryDoc.exists) {
                console.log('No inventory document exists, nothing to sync');
                return;
            }

            const inventoryData = inventoryDoc.data();
            if (!inventoryData.items || inventoryData.items.length === 0) {
                console.log('Inventory exists but is empty, nothing to sync');
                return;
            }

            console.log('Querying for orders that need inventory processing...');

            // Get orders that haven't been processed for inventory
            const ordersSnapshot = await db.collection('ordini')
                .where('ristorante', '==', restaurantId)
                .where('statoPagato', '==', 0)  // Only completed orders
                .get();

            if (ordersSnapshot.empty) {
                console.log('No orders found for inventory processing');
                return;
            }

            // Filter orders that haven't been processed
            const unprocessedOrders = ordersSnapshot.docs.filter(doc => {
                const data = doc.data();
                return data.inventoryProcessed !== true;
            });

            if (unprocessedOrders.length === 0) {
                console.log('All orders have already been processed for inventory');
                return;
            }

            console.log(`Found ${unprocessedOrders.length} orders to process for inventory`);

            // Process each order
            const batch = db.batch();
            const processedPromises = unprocessedOrders.map(async (doc) => {
                const orderData = doc.data();
                const orderRef = doc.ref;

                if (!orderData.carrello) {
                    console.log(`Order ${doc.id} has no carrello, skipping`);
                    return;
                }

                // Process each item in the cart
                const items = Object.values(orderData.carrello) as Array<{ id: string, quantita: number }>;
                console.log(`Processing order ${doc.id} with ${items.length} items`);

                for (const item of items) {
                    try {
                        console.log(`Recording sale for item ${item.id}, quantity: ${item.quantita}`);
                        await this.recordSale(item.id, item.quantita, doc.id);
                    } catch (error) {
                        console.error(`Error recording sale for item ${item.id}:`, error);
                    }
                }

                // Mark order as processed for inventory
                batch.update(orderRef, { inventoryProcessed: true });
            });

            await Promise.all(processedPromises);

            // Only commit if there were orders to process
            if (unprocessedOrders.length > 0) {
                await batch.commit();
                console.log(`Successfully processed ${unprocessedOrders.length} orders`);
            }

            console.log('Inventory synced with orders');
        } catch (error) {
            console.error('Error syncing inventory with orders:', error);
            throw error; // Re-throw to allow proper error handling in the component
        }
    }

    /**
     * Check if there are any pending orders that need processing
     */
    public hasPendingOrders(): Observable<boolean> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        return from(
            db.collection('ordini')
                .where('ristorante', '==', restaurantId)
                .where('statoPagato', '==', 0)
                .limit(1) // Ottimizzazione: basta un solo risultato per verificare
                .get()
        ).pipe(
            map(snapshot => {
                // Se snapshot non è vuoto, controlla se ci sono ordini non processati
                if (!snapshot.empty) {
                    const doc = snapshot.docs[0];
                    const data = doc.data();
                    return data.inventoryProcessed !== true;
                }
                return false;
            }),
            catchError(error => {
                console.error('Error checking pending orders:', error);
                return of(false);
            })
        );
    }


    /**
     * Sincronizzare l'inventario con l'anagrafica prodotti,
     * aggiungendo i prodotti mancanti con quantità 0
     */
    public async syncWithRegistry(): Promise<void> {
        const db = firebase.firestore();
        const restaurantId = this.dataService.getRistorante();

        console.log(`Sincronizzazione inventario con anagrafica per ristorante: ${restaurantId}`);

        try {
            // Ottieni i dati dell'anagrafica
            const menuRef = db.collection('menu_test').doc(restaurantId);
            const menuDoc = await menuRef.get();

            if (!menuDoc.exists) {
                console.error('Documento menu_test non trovato');
                throw new Error('Anagrafica prodotti non trovata');
            }

            const menuData = menuDoc.data();
            const menuItems = menuData.items || [];

            console.log(`Trovati ${menuItems.length} prodotti nell'anagrafica`);

            // Ottieni i dati dell'inventario
            const inventoryRef = db.collection('inventario').doc(restaurantId);
            const inventoryDoc = await inventoryRef.get();

            if (!inventoryDoc.exists) {
                console.error('Documento inventario non trovato');
                throw new Error('Documento inventario non trovato');
            }

            const inventoryData = inventoryDoc.data();
            const inventoryItems = inventoryData.items || [];

            console.log(`Trovati ${inventoryItems.length} prodotti nell'inventario`);

            // Identifica i prodotti mancanti nell'inventario
            const existingProductIds = new Set(inventoryItems.map(item => item.productId));
            const newItems = [];

            for (const menuItem of menuItems) {
                // Verifica se il prodotto ha già un record nell'inventario
                if (!existingProductIds.has(menuItem.id)) {
                    // Determina il numero di livelli e le unità di misura
                    const hasLvl = menuItem.lvl && menuItem.lvl.length > 0;
                    const maxLvl = hasLvl ? menuItem.maxLvl || menuItem.lvl.length : 1;

                    // Crea livelli con quantità 0
                    const lvl = [];
                    for (let i = 0; i < maxLvl; i++) {
                        const um = hasLvl && menuItem.lvl[i] ? menuItem.lvl[i].um : 'pz';
                        const barCode = hasLvl && menuItem.lvl[i] ? menuItem.lvl[i].barCode || '' : '';
                        lvl.push({
                            quantita: '0',
                            um,
                            barCode
                        });
                    }

                    // Crea un nuovo elemento inventario
                    const newItem: InventoryItem = {
                        id: db.collection('inventario').doc().id,
                        productId: menuItem.id,
                        productTitle: menuItem.title || 'Prodotto senza nome',
                        category: menuItem.category || 'Senza categoria',
                        lvl,
                        maxLvl,
                        movimenti: [{
                            data: new Date().toLocaleString('it-IT', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            }),
                            timestamp: Date.now(),
                            quantitaPrec: '{}',
                            quantitaAgg: '{}',
                            lvl: lvl,
                            modalita: 'inventario',
                            note: 'Inizializzazione da anagrafica'
                        }],
                        lastUpdated: Date.now()
                    };

                    newItems.push(newItem);
                }
            }

            console.log(`Trovati ${newItems.length} nuovi prodotti da aggiungere all'inventario`);

            if (newItems.length === 0) {
                console.log('Nessun nuovo prodotto da aggiungere, inventario già sincronizzato');
                return;
            }

            // Aggiorna l'inventario con i nuovi prodotti
            const updatedItems = [...inventoryItems, ...newItems];
            await inventoryRef.update({
                items: updatedItems
            });

            console.log(`Aggiunti ${newItems.length} nuovi prodotti all'inventario`);

            // Aggiorna i dati locali
            this._inventoryData.next(updatedItems);

        } catch (error) {
            console.error('Errore durante la sincronizzazione con l\'anagrafica:', error);
            throw error;
        }
    }
}