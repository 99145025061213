<!-- Main Container -->
<div class="kitchen-container" [class.with-history]="showHistory" [class.with-total]="showTotalDishes">
    <!-- Header -->
    <div class="kitchen-header">
        <div class="header-item">
            <strong>{{ currentTime | date:'HH:mm' }}</strong>
        </div>
        <div class="header-item center">
            <strong>Completamento {{ getCompletionPercentage() }}%</strong>
        </div>
        <div class="header-item right">
            <strong>Tempo Medio {{ getElapsedTime() }}</strong>
        </div>
    </div>

    <!-- Main Content Area -->
    <div class="main-content">
        <!-- Filters Panel -->
        <div *ngIf="showFilters" class="filters-panel">
            <div class="filters-grid">
                <!-- Print Destinations Filter -->
                <div class="filter-section">
                    <h3>Destinazione Stampa</h3>
                    <div class="filter-options">
                        <div *ngFor="let dest of printDestinations"
                             [class.active]="selectedDestinazioni.includes(dest)"
                             class="filter-option"
                             (click)="toggleDestinazione(dest)">
                            {{ dest }}
                        </div>
                    </div>
                </div>

                <!-- Categories Filter -->
                <div class="filter-section">
                    <h3>Categorie</h3>
                    <div class="filter-options">
                        <div *ngFor="let cat of uniqueCategories"
                             [class.active]="selectedCategories.includes(cat)"
                             class="filter-option"
                             (click)="toggleCategory(cat)">
                            {{ cat }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Active Filters -->
            <div *ngIf="hasActiveFilters()" class="active-filters">
                <h4>Filtri attivi:</h4>
                <div class="active-filters-list">
                    <div *ngFor="let dest of selectedDestinazioni" class="active-filter">
                        {{ dest }}
                        <span class="remove-filter" (click)="toggleDestinazione(dest)">×</span>
                    </div>
                    <div *ngFor="let cat of selectedCategories" class="active-filter">
                        {{ cat }}
                        <span class="remove-filter" (click)="toggleCategory(cat)">×</span>
                    </div>
                </div>
                <button class="clear-filters" (click)="clearFilters()">Rimuovi Filtri</button>
            </div>
        </div>

        <!-- Orders Grid -->
        <div class="orders-grid">
            <div *ngFor="let colonna of getColonneOrdinate()" class="orders-column">
                <div *ngFor="let ordine of colonna"
                     class="order-card"
                     [class.completed]="ordine.statoPagato === 3"
                     [class.partial]="ordine.statoEvasione === 'parziale'"
                     (click)="completeOrder(ordine)">

                    <!-- Order Header -->
                    <div class="order-header"
                         [ngClass]="ordine.selectedPriceListType">
                        <div class="order-header-text">
                            <span>Ordine #{{ordine.ordine}}</span>
                            <span class="order-type-badge">
                                {{getOrderTypeLabel(ordine.selectedPriceListType)}}
                                <span *ngIf="ordine.tavolo">- Tavolo {{ordine.tavolo}}</span>
                                <span *ngIf="ordine.pager && ordine.pager !== 0" class="pager-badge">
                                    Pager: {{ordine.pager}}
                                </span>
                            </span>
                        </div>
                        <div class="order-time">
                            {{getOrderTime(ordine.data)}}
                        </div>
                    </div>

                    <!-- Order Content -->
                    <div class="order-content">
                        <div *ngFor="let prodottoId of ordine.ordineInserimento"
                             class="product-item">
                            <div class="product-status"
                                 [class.ready]="isPiattoReady(ordine, prodottoId)"
                                 (click)="togglePiattoPreparazione(ordine, prodottoId, $event)">
                                <mat-icon>{{ isPiattoReady(ordine, prodottoId) ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
                            </div>
                            <span class="product-quantity">
                                {{ordine.carrello[prodottoId].quantita}}×
                            </span>
                            <div class="product-details">
                                <div class="product-name">
                                    {{ordine.carrello[prodottoId].title}}
                                </div>
                                <div *ngIf="ordine.carrello[prodottoId].variants"
                                     class="product-variants">
                                    <div *ngFor="let variant of ordine.carrello[prodottoId].variants">
                                        → {{variant.name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="ordine.nota" class="order-notes">
                            <div class="order-notes-title">Note:</div>
                            <div class="order-notes-content">{{ordine.nota}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- History Sidebar -->
    <div class="history-sidebar" [class.visible]="showHistory">
        <div class="history-header">
            <h2>Cronologia Ordini</h2>
            <button class="close-history" (click)="toggleHistory()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="history-content">
            <div *ngFor="let ordine of ordiniEvasi"
                 class="order-card"
                 [class.partial]="ordine.statoEvasione === 'parziale'"
                 (click)="restoreOrder(ordine)">
                <div class="order-header" [ngClass]="ordine.selectedPriceListType">
                    <div class="order-header-text">
                        <span>Ordine #{{ordine.ordine}}</span>
                        <span class="order-type-badge">
                            {{getOrderTypeLabel(ordine.selectedPriceListType)}}
                            <span *ngIf="ordine.tavolo">- Tavolo {{ordine.tavolo}}</span>
                        </span>
                    </div>
                    <div class="order-time">
                        <div>Evaso: {{getOrderTime(ordine.timestampEvasione)}}</div>
                    </div>
                </div>

                <div class="order-content">
                    <div *ngFor="let prodottoId of ordine.ordineInserimento"
                         class="product-item">
                        <div class="product-status" [class.ready]="isPiattoReady(ordine, prodottoId)">
                            <mat-icon>{{ isPiattoReady(ordine, prodottoId) ? 'check_circle' : 'radio_button_unchecked' }}</mat-icon>
                        </div>
                        <span class="product-quantity" style="color: white !important;">
                            {{ordine.carrello[prodottoId].quantita}}×
                        </span>
                        <div class="product-name">
                            {{ordine.carrello[prodottoId].title}}
                        </div>
                    </div>

                    <div *ngIf="ordine.nota" class="order-notes">
                        <div class="order-notes-title">Note:</div>
                        <div class="order-notes-content">{{ordine.nota}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Total Dishes Sidebar -->
    <div class="total-dishes-sidebar" [class.visible]="showTotalDishes">
        <div class="total-dishes-header">
            <h2>Totale Piatti</h2>
            <button class="close-total" (click)="toggleTotalDishes()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="total-dishes-content">
            <div *ngFor="let dish of getFilteredTotalDishes()" class="total-dish-item">
                <div class="total-dish-header">
                    <span class="dish-quantity">{{dish.quantity}}×</span>
                    <span class="dish-title">{{dish.title}}</span>
                </div>

                <div class="total-dish-details">
                    <div class="dish-info">
                        <mat-icon>category</mat-icon>
                        <span>{{getDishCategory(dish)}}</span>
                    </div>
                    <div class="dish-info">
                        <mat-icon>print</mat-icon>
                        <span>{{getDishDestination(dish)}}</span>
                    </div>
                    <div class="dish-info" *ngIf="hasVariants(dish)">
                        <mat-icon>list</mat-icon>
                        <span>{{getVariantsText(dish)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Control Buttons -->
    <div class="control-buttons">
        <div class="control-button secondary" (click)="toggleFilters()">
            {{ showFilters ? 'Nascondi Filtri' : 'Mostra Filtri' }}
        </div>
        <div class="control-button history" (click)="toggleHistory()">
            {{ showHistory ? 'Nascondi Cronologia' : 'Mostra Cronologia' }}
        </div>
        <div class="control-button total" (click)="toggleTotalDishes()">
            {{ showTotalDishes ? 'Nascondi Totale' : 'Mostra Totale' }}
        </div>
    </div>
</div>