import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef} from '@angular/core';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DialogVariantiComponent } from "../dialog-varianti/dialog-varianti.component";
import firebase from 'firebase/app';

interface Variant {
    id: string;
    name: string;
    price: number;
    options: VariantOption[];
    isRequired: boolean;
    maxSelections?: number;
}

interface VariantOption {
    id: string;
    name: string;
    priceModifier: number;
}

interface PriceListSettings {
    counter: { vatId: string };
    table: { vatId: string };
    takeaway: { vatId: string };
}

interface PriceList {
    id: string;
    name: string;
    settings: PriceListSettings;
}

interface PriceListPrices {
    counter: { price: number; vatId: string };
    table: { price: number; vatId: string };
    takeaway: { price: number; vatId: string };
}

interface MenuSection {
    title: string;
    categories: string[];
    products: string[];
}

interface MenuConfig {
    sections: MenuSection[];
    prices: {
        [listId: string]: PriceListPrices;
    };
}

// Packaging level interface
interface PackagingLevel {
    quantita: string;
    um: string;
    barCode: string;
}

interface BillOfMaterial {
    type: 'product' | 'external' | 'process';
    productId?: string;
    name?: string;
    quantity: number;
    unit: string;
    notes?: string;
}


interface MenuItem {
    id: string;
    title: string;
    billOfMaterials?: BillOfMaterial[];
}

@Component({
    selector: 'menu-detail',
    templateUrl: './menu-detail.component.html',
    styleUrls: ['./menu-detail.component.css']
})
export class MenuDetailComponent implements OnChanges {
    @Input() item: any;
    @Output() itemUpdated = new EventEmitter<any>();
    @Output() itemDeleted = new EventEmitter<void>();

    // Basic fields
    title: string = '';
    price: string = '';
    selectedCategory: string[] = [];
    selectedImage: string = '';
    selectedIngredients: string[] = [];
    selectedAllergens: string[] = [];
    isMenuChecked = false;
    isSingleChecked = false;
    isDisponibileChecked = false;
    isDisponibileDeliveryChecked = false;
    selectedPrintDestination: string = '';
    reparto: number = 1;

    // Collections
    categories: string[] = [];
    ingredients: string[] = [];
    allergens: string[] = [];

    // Variants
    variants: Variant[] = [];
    selectedVariantIds: string[] = [];
    showNewVariantForm = false;
    newVariant: Variant = {
        id: '',
        name: '',
        price: 0,
        options: [],
        isRequired: false,
        maxSelections: 1
    };

    // Menu Configuration
    menuConfig: MenuConfig = {
        sections: [],
        prices: {}
    };
    selectedMenuPriceList: string = '';
    menuPrices: {[listId: string]: PriceListPrices} = {};
    menuProducts: any[] = [];

    // Price lists
    priceLists: PriceList[] = [];
    selectedPriceListId: string = '';
    isCreatingNewList = false;
    newList: PriceList = {
        id: '',
        name: '',
        settings: {
            counter: { vatId: '1' },
            table: { vatId: '1' },
            takeaway: { vatId: '1' }
        }
    };
    currentPrices: PriceListPrices = {
        counter: { price: 0, vatId: '1' },
        table: { price: 0, vatId: '1' },
        takeaway: { price: 0, vatId: '1' }
    };
    existingPriceLists: { [key: string]: PriceListPrices } = {};
    modifiedPriceLists: Map<string, PriceListPrices> = new Map();

    // Packaging level variables
    levels: PackagingLevel[] = [
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' },
        { quantita: '', um: '', barCode: '' }
    ];

    // Level visibility flags
    isLvl1: boolean = false;
    isLvl2: boolean = false;
    isLvl3: boolean = false;
    isLvl4: boolean = false;
    maxLvl: number = 0; // Default a nessun livello

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {}


    // Aggiungi queste nuove proprietà
    billOfMaterials: BillOfMaterial[] = [];
    availableProducts: MenuItem[] = [];
    newBomItem: BillOfMaterial = {
        type: 'product',
        quantity: 0,
        unit: 'pz',
        notes: ''
    };

    bomTypes = [
        { value: 'product', label: 'Prodotto Menu' },
        { value: 'external', label: 'Prodotto Esterno' },
        { value: 'process', label: 'Lavorazione' }
    ];

    unitsOfMeasure = [
        { value: 'pz', label: 'Pezzi' },
        { value: 'kg', label: 'Kg' },
        { value: 'g', label: 'Grammi' },
        { value: 'l', label: 'Litri' },
        { value: 'ml', label: 'Millilitri' },
        { value: 'h', label: 'Ore' },
        { value: 'min', label: 'Minuti' }
    ];

    availablePrinters: { value: string, label: string, name: string }[] = [
        { value: 'nessuna', label: 'Nessuna Stampa', name: 'Nessuna' }
    ];

    workstationPrinters: { value: string, label: string }[] = [];

    getBomTypeLabel(type: string): string {
        const bomType = this.bomTypes.find(t => t.value === type);
        return bomType ? bomType.label : '';
    }

    getUnitLabel(unitValue: string): string {
        const unit = this.unitsOfMeasure.find(u => u.value === unitValue);
        return unit ? unit.label : '';
    }



    // Aggiungi queste proprietà alla classe
    @ViewChild('barcodeInput') barcodeInput: ElementRef;
    barcode: string = '';

// Metodo per gestire il focus dell'input barcode
    onBarcodeFocus() {
        // Opzionale: puoi aggiungere logica specifica quando l'input riceve il focus
        console.log('Barcode input focused');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.item && changes.item.currentValue) {
            // Reset completo delle variabili relative ai price list
            this.selectedPriceListId = '';
            this.modifiedPriceLists = new Map();
            this.currentPrices = {
                counter: { price: 0, vatId: '1' },
                table: { price: 0, vatId: '1' },
                takeaway: { price: 0, vatId: '1' }
            };

            this.initializeFormData(changes.item.currentValue);
            this.loadData();
        }
    }

    private convertToArray(value: any): string[] {
        if (Array.isArray(value)) {
            return value.map(item => typeof item === 'string' ? item : item.name || '').filter(Boolean);
        }
        if (typeof value === 'string') {
            return value.split(',').map(item => item.trim()).filter(Boolean);
        }
        return [];
    }

    private initializeFormData(itemData: any) {
        this.title = itemData.title || '';
        this.selectedCategory = itemData.category || '';
        this.selectedImage = itemData.image_link || '';
        this.selectedIngredients = this.convertToArray(itemData.ingredients);
        this.selectedAllergens = this.convertToArray(itemData.allergens);
        this.price = itemData.price || '';
        this.isMenuChecked = !!itemData.menu;
        this.isSingleChecked = !!itemData.single;
        this.isDisponibileChecked = !!itemData.disponibilita;
        this.selectedPrintDestination = itemData.selectedPrintDestination || 'nessuna';
        this.reparto = itemData.reparto || 1;
        this.selectedVariantIds = itemData.variantIds || [];
        this.existingPriceLists = itemData.priceListPrices || {};
        this.barcode = itemData.barcode || '';


        // Initialize menu config if exists
        if (itemData.menuConfig) {
            this.menuConfig = itemData.menuConfig;
            if (itemData.menuConfig.prices){
                this.menuPrices=itemData.menuConfig.prices;
            }
        }

        // Set current prices if a price list is selected
        this.existingPriceLists = {};

        // Se ci sono price list esistenti, li copiamo
        if (itemData.priceListPrices) {
            this.existingPriceLists = JSON.parse(JSON.stringify(itemData.priceListPrices));

            // Reinizializziamo la mappa delle modifiche
            this.modifiedPriceLists = new Map();
            Object.entries(this.existingPriceLists).forEach(([listId, prices]) => {
                this.modifiedPriceLists.set(listId, JSON.parse(JSON.stringify(prices)));
            });
        }

        // Inizializza i livelli di imballaggio
        if (itemData.lvl) {
            this.levels = itemData.lvl.map((level, index) => ({
                quantita: level.quantita || '',
                um: level.um || '',
                barCode: level.barCode || ''
            }));
        }

        // Imposta la visibilità dei livelli in base a maxLvl
        const maxLvl = itemData.maxLvl || 0;
        this.maxLvl = maxLvl;
        this.isLvl1 = maxLvl === 1;
        this.isLvl2 = maxLvl === 2;
        this.isLvl3 = maxLvl === 3;
        this.isLvl4 = maxLvl === 4;


        this.billOfMaterials = (itemData.billOfMaterials || []).map(bom => ({
            ...bom,
            type: bom.type || 'product' // Assicura la retrocompatibilità
        }));
    }

    private loadData() {
        this.fetchDataFromFirestore();
        this.loadVariants();
        this.loadPriceLists();
        this.loadMenuProducts();
        this.loadAvailableProducts();
        this.loadWorkstationPrinters()


    }

    // Modifica il metodo loadWorkstationPrinters per includere l'indirizzo IP nella label
    private async loadWorkstationPrinters() {
        const restaurantId = await this.dataService.getCurrentRestaurantId();

        firebase.firestore()
            .collection('workstations')
            .where('ristoranteId', '==', restaurantId)
            .get()
            .then(snapshot => {
                const printers = [];

                // Per ogni workstation
                snapshot.forEach(doc => {
                    const workstation = doc.data();
                    const workstationName = workstation.name || 'Workstation';

                    // Aggiungi le stampanti con il loro nome e indirizzo IP
                    if (workstation.printers && Array.isArray(workstation.printers)) {
                        workstation.printers.forEach(printer => {
                            if (printer.name && printer.ipAddress) {
                                printers.push({
                                    // Usa solo l'indirizzo IP come valore da salvare
                                    value: printer.ipAddress,
                                    // Mantieni la label descrittiva per l'utente
                                    label: `${printer.name} (${workstationName}) - ${printer.ipAddress}`,
                                    name: printer.name
                                });
                            }
                        });
                    }
                });

                this.workstationPrinters = printers;

                // Aggiungi le stampanti della workstation alle opzioni disponibili
                this.availablePrinters = [
                    { value: 'nessuna', label: 'Nessuna Stampa', name: 'Nessuna' },
                    ...printers
                ];

                console.log('Stampanti disponibili:', this.availablePrinters);
            })
            .catch(error => {
                console.error('Errore nel caricamento delle stampanti:', error);
            });
    }

    private loadAvailableProducts() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    this.availableProducts = data?.items || [];
                }
            })
            .catch(error => {
                console.error('Error loading available products:', error);
                this.snackBar.open('Errore nel caricamento dei prodotti disponibili', 'Chiudi', { duration: 3000 });
            });
    }

    addBomItem() {
        if (this.validateBomItem()) {
            this.billOfMaterials.push({...this.newBomItem});
            this.resetNewBomItem();
        }
    }

    validateBomItem(): boolean {
        if (this.newBomItem.type === 'product' && !this.newBomItem.productId) {
            this.snackBar.open('Seleziona un prodotto dal menu', 'Chiudi', { duration: 3000 });
            return false;
        }
        if ((this.newBomItem.type === 'external' || this.newBomItem.type === 'process') && !this.newBomItem.name) {
            this.snackBar.open('Inserisci un nome', 'Chiudi', { duration: 3000 });
            return false;
        }
        if (this.newBomItem.quantity <= 0) {
            this.snackBar.open('Inserisci una quantità valida', 'Chiudi', { duration: 3000 });
            return false;
        }
        return true;
    }

    resetNewBomItem() {
        this.newBomItem = {
            type: 'product',
            quantity: 0,
            unit: 'pz',
            notes: ''
        };
    }

    getItemName(item: BillOfMaterial): string {
        if (item.type === 'product' && item.productId) {
            const product = this.availableProducts.find(p => p.id === item.productId);
            return product ? product.title : 'Prodotto non trovato';
        }
        return item.name || '';
    }
    removeBomItem(index: number) {
        this.billOfMaterials.splice(index, 1);
    }


    loadVariants() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('varianti')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.variants = doc.data()?.variants || [];
                }
            })
            .catch(error => {
                console.error('Error loading variants:', error);
                this.snackBar.open('Errore nel caricamento delle varianti', 'Chiudi', { duration: 3000 });
            });
    }

    private loadMenuProducts() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test') // o il percorso corretto
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.menuProducts = doc.data()?.items || [];
                }
            });
    }

    loadPriceLists() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('price_lists')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.priceLists = doc.data()?.priceLists || [];
                }
            });
    }

    fetchDataFromFirestore() {
        const restaurantName = this.dataService.getRistorante();
        firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    const items = data?.items || [];

                    const categoriesSet = new Set<string>();
                    const ingredientsSet = new Set<string>();

                    items.forEach(item => {
                        if (item.category) categoriesSet.add(item.category);
                        if (item.ingredients) {
                            item.ingredients.forEach(ingredient => ingredientsSet.add(ingredient.name));
                        }
                    });

                    this.categories = Array.from(categoriesSet);
                    this.ingredients = Array.from(ingredientsSet);
                    this.allergens = this.dataService.getAllergeni();
                }
            });
    }

    // Variant Management
    addCustomVariant() {
        this.showNewVariantForm = true;
        this.newVariant = {
            id: firebase.firestore().collection('temporary').doc().id,
            name: '',
            price: 0,
            options: [],
            isRequired: false,
            maxSelections: 1
        };
    }

    addOptionToNewVariant() {
        this.newVariant.options.push({
            id: firebase.firestore().collection('temporary').doc().id,
            name: '',
            priceModifier: 0
        });
    }

    removeOptionFromVariant(index: number) {
        this.newVariant.options.splice(index, 1);
    }

    async saveNewVariant() {
        if (!this.newVariant.name) {
            this.snackBar.open('Il nome della variante è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const variantiRef = firebase.firestore().collection('varianti').doc(restaurantName);

        try {
            const doc = await variantiRef.get();
            const existingVariants = doc.exists ? (doc.data()?.variants || []) : [];

            // Check if variant with same name exists
            const variantExists = existingVariants.some(v => v.name === this.newVariant.name);
            if (variantExists) {
                this.snackBar.open('Esiste già una variante con questo nome', 'Chiudi', { duration: 3000 });
                return;
            }

            // Add new variant to both lists
            const updatedVariants = [...existingVariants, this.newVariant];
            await variantiRef.set({ variants: updatedVariants });

            this.variants = updatedVariants;
            this.selectedVariantIds = [...this.selectedVariantIds, this.newVariant.id];

            this.showNewVariantForm = false;
            this.snackBar.open('Variante creata con successo', 'Chiudi', { duration: 2000 });
        } catch (error) {
            console.error('Error saving variant:', error);
            this.snackBar.open('Errore nel salvataggio della variante', 'Chiudi', { duration: 3000 });
        }
    }

    cancelNewVariant() {
        this.showNewVariantForm = false;
        this.newVariant = {
            id: '',
            name: '',
            price: 0,
            options: [],
            isRequired: false,
            maxSelections: 1
        };
    }

    async saveNewPriceList() {
        if (!this.newList.name) {
            this.snackBar.open('Il nome del listino è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const priceListsRef = firebase.firestore()
            .collection('price_lists')
            .doc(restaurantName);

        try {
            const doc = await priceListsRef.get();
            const existingLists = doc.exists ? (doc.data()?.priceLists || []) : [];

            // Check if list with same name exists
            if (existingLists.some(l => l.name === this.newList.name)) {
                this.snackBar.open('Esiste già un listino con questo nome', 'Chiudi', { duration: 3000 });
                return;
            }

            const listToSave = {
                ...this.newList,
                id: firebase.firestore().collection('temporary').doc().id
            };

            const updatedLists = [...existingLists, listToSave];
            await priceListsRef.set({ priceLists: updatedLists });

            this.priceLists = updatedLists;
            this.selectedPriceListId = listToSave.id;
            this.isCreatingNewList = false;

            this.currentPrices = {
                counter: { price: 0, vatId: listToSave.settings.counter.vatId },
                table: { price: 0, vatId: listToSave.settings.table.vatId },
                takeaway: { price: 0, vatId: listToSave.settings.takeaway.vatId }
            };

            this.snackBar.open('Listino creato con successo!', 'Chiudi', { duration: 2000 });
        } catch (error) {
            console.error('Error saving price list:', error);
            this.snackBar.open('Errore durante il salvataggio del listino', 'Chiudi', { duration: 3000 });
        }
    }

    cancelNewList() {
        this.isCreatingNewList = false;
        this.newList = {
            id: '',
            name: '',
            settings: {
                counter: { vatId: '1' },
                table: { vatId: '1' },
                takeaway: { vatId: '1' }
            }
        };
    }

    // File Management
    onFileSelected(event: any) {
        const file = event.target.files[0];
        if (file) {
            const storageRef = firebase.storage().ref();
            const filePath = `immaginiMenu/${Date.now()}_${file.name}`;

            storageRef.child(filePath).put(file)
                .then(snapshot => snapshot.ref.getDownloadURL())
                .then(url => {
                    this.selectedImage = url;
                    this.snackBar.open('Immagine caricata con successo', 'Chiudi', { duration: 2000 });
                })
                .catch(error => {
                    console.error('Error uploading image:', error);
                    this.snackBar.open('Errore nel caricamento dell\'immagine', 'Chiudi', { duration: 3000 });
                });
        }
    }

    removeImage() {
        this.selectedImage = '';
    }

    // Funzioni per gestire i livelli di imballaggio
    onMenuChangeLevel1() {
        this.maxLvl = 1;
        this.isLvl1 = true;
        this.isLvl2 = false;
        this.isLvl3 = false;
        this.isLvl4 = false;

        // Assicurati che l'oggetto per il primo livello esista
        this.levels[0] = this.levels[0] || { quantita: '', um: '', barCode: '' };

        // Resetta i livelli successivi
        for (let i = 1; i < 4; i++) {
            this.levels[i] = { quantita: '', um: '', barCode: '' };
        }
    }

    onMenuChangeLevel2() {
        this.maxLvl = 2;
        this.isLvl1 = false;
        this.isLvl2 = true;
        this.isLvl3 = false;
        this.isLvl4 = false;

        // Assicurati che gli oggetti per i primi due livelli esistano
        for (let i = 0; i < 2; i++) {
            this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
        }

        // Resetta i livelli successivi
        for (let i = 2; i < 4; i++) {
            this.levels[i] = { quantita: '', um: '', barCode: '' };
        }
    }

    onMenuChangeLevel3() {
        this.maxLvl = 3;
        this.isLvl1 = false;
        this.isLvl2 = false;
        this.isLvl3 = true;
        this.isLvl4 = false;

        // Assicurati che gli oggetti per i primi tre livelli esistano
        for (let i = 0; i < 3; i++) {
            this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
        }

        // Resetta l'ultimo livello
        this.levels[3] = { quantita: '', um: '', barCode: '' };
    }

    onMenuChangeLevel4() {
        this.maxLvl = 4;
        this.isLvl1 = false;
        this.isLvl2 = false;
        this.isLvl3 = false;
        this.isLvl4 = true;

        // Assicurati che tutti e quattro i livelli esistano
        for (let i = 0; i < 4; i++) {
            this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
        }
    }

    getMaxLevel(): number {
        if (this.isLvl4) return 4;
        if (this.isLvl3) return 3;
        if (this.isLvl2) return 2;
        if (this.isLvl1) return 1;
        return 0;
    }

    saveChanges() {
        if (!this.title) {
            this.snackBar.open('Il nome del prodotto è obbligatorio', 'Chiudi', { duration: 3000 });
            return;
        }

        // Get the current max level for packaging
        const maxLevel = this.getMaxLevel();

        const updatedItem = {
            id: this.item?.id || firebase.firestore().collection('temporary').doc().id,
            title: this.title,
            category: this.selectedCategory || '',
            image_link: this.selectedImage || '',
            ingredients: this.selectedIngredients.map(name => ({ name, quantity: 1 })) || [],
            allergens: this.selectedAllergens.map(name => ({ name, quantity: 1 })) || [],
            price: this.price || '0',
            menu: this.isMenuChecked || false,
            single: this.isSingleChecked || false,
            disponibilita: this.isDisponibileChecked || false,
            disponibilitaDelivery: this.isDisponibileDeliveryChecked || false,
            selectedPrintDestination: this.selectedPrintDestination || 'nessuna',
            reparto: this.reparto || 1,
            variantIds: this.selectedVariantIds || [],
            priceListPrices: {}, // Initialize price list object
            // Aggiungi i livelli di imballaggio
            lvl: this.levels.slice(0, maxLevel),
            maxLvl: this.maxLvl,
            barcode: this.barcode,
            billOfMaterials: this.billOfMaterials,
        };

        // Add menu configuration if menu is checked
        if (this.isMenuChecked) {
            updatedItem['menuConfig'] = {
                sections: this.menuConfig.sections,
                prices: this.menuPrices
            };
        }

        // Handle price lists
        const priceListPrices = {};

        // Copy existing price lists
        Object.assign(priceListPrices, this.existingPriceLists);

        // Add modified price lists
        this.modifiedPriceLists.forEach((prices, listId) => {
            if (prices) {
                priceListPrices[listId] = JSON.parse(JSON.stringify(prices));
            }
        });

        updatedItem.priceListPrices = priceListPrices;

        console.log('Saving item with print destination:', updatedItem.selectedPrintDestination); // Debug log

        const restaurantName = this.dataService.getRistorante();
        const menuRef = firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName);

        menuRef.get().then(doc => {
            if (doc.exists) {
                const data = doc.data();
                const items = data?.items || [];

                // Preserve the favorites data from the original document
                const favorites = data?.favorites || [];

                let updatedItems: any[];

                if (this.item?.id) {
                    updatedItems = items.map(item =>
                        item.id === this.item.id ? updatedItem : item
                    );
                } else {
                    updatedItems = [...items, updatedItem];
                }

                if (!updatedItems || updatedItems.length === 0) {
                    throw new Error('No items to update');
                }

                // Include the favorites in the update data
                const updateData = {
                    items: updatedItems,
                    ristorante: restaurantName,
                    favorites: favorites // Preserving favorites data
                };

                return menuRef.set(updateData)
                    .then(() => {
                        this.snackBar.open('Modifiche salvate con successo', 'Chiudi', { duration: 2000 });
                        this.itemUpdated.emit(updatedItem);
                    })
                    .catch(error => {
                        console.error('Error saving changes:', error);
                        this.snackBar.open('Errore nel salvataggio delle modifiche', 'Chiudi', { duration: 3000 });
                    });
            } else {
                const initialData = {
                    items: [updatedItem],
                    ristorante: restaurantName,
                    favorites: [{ name: 'Preferiti', items: [] }] // Initialize with default favorites structure
                };
                return menuRef.set(initialData)
                    .then(() => {
                        this.snackBar.open('Elemento creato con successo', 'Chiudi', { duration: 2000 });
                        this.itemUpdated.emit(updatedItem);
                    })
                    .catch(error => {
                        console.error('Error creating document:', error);
                        this.snackBar.open('Errore nella creazione del documento', 'Chiudi', { duration: 3000 });
                    });
            }
        }).catch(error => {
            console.error('Error getting document:', error);
            this.snackBar.open('Errore nel recupero del documento', 'Chiudi', { duration: 3000 });
        });
    }

    onPriceListChange(event: any) {
        const listId = event.value;
        this.selectedPriceListId = listId;

        if (!this.modifiedPriceLists.has(listId)) {
            // Se esistono prezzi precedenti per questo listino, li copiamo
            if (this.existingPriceLists[listId]) {
                this.modifiedPriceLists.set(listId, JSON.parse(JSON.stringify(this.existingPriceLists[listId])));
            } else {
                // Altrimenti inizializziamo nuovi prezzi
                this.modifiedPriceLists.set(listId, {
                    counter: { price: 0, vatId: '1' },
                    table: { price: 0, vatId: '1' },
                    takeaway: { price: 0, vatId: '1' }
                });
            }
        }

        this.currentPrices = this.modifiedPriceLists.get(listId);
    }

    deleteItem() {
        if (!this.item?.id) {
            // Just emit the itemDeleted event without doing any Firebase operations
            this.itemDeleted.emit();
            return;
        }

        const restaurantName = this.dataService.getRistorante();
        const menuRef = firebase.firestore()
            .collection('menu_test')
            .doc(restaurantName);

        menuRef.get().then(doc => {
            if (doc.exists) {
                const items = doc.data()?.items || [];
                const updatedItems = items.filter(item => item.id !== this.item.id);

                menuRef.update({ items: updatedItems })
                    .then(() => {
                        this.snackBar.open('Elemento eliminato con successo', 'Chiudi', { duration: 2000 });
                        this.itemDeleted.emit();
                    })
                    .catch(error => {
                        console.error('Error deleting item:', error);
                        this.snackBar.open('Errore nell\'eliminazione dell\'elemento', 'Chiudi', { duration: 3000 });
                    });
            }
        });
    }

    // Menu Configuration Methods
    addMenuSection() {
        if (this.menuConfig.sections.length < 2) {
            this.menuConfig.sections.push({
                title: '',
                categories: [],
                products: []
            });
        }
    }

    removeMenuSection(index: number) {
        this.menuConfig.sections.splice(index, 1);
    }

    onMenuPriceListChange(event: any) {
        const listId = event.value;
        if (!this.menuPrices[listId]) {
            this.menuPrices[listId] = {
                counter: { price: 0, vatId: '1' },
                table: { price: 0, vatId: '1' },
                takeaway: { price: 0, vatId: '1' }
            };
        }
    }
}