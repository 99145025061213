import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {IpcService} from "../../shared/services/ipc.service";

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'navbar',
  styles: [`

    .menuItem{
      cursor: pointer !important;
    }

    .menuItem:hover {
      background-color: rgba(181,181,181,0.2) !important;
    }

    .sidebar {
      margin: 0;
      padding: 0;
      margin-top: 1%;
      margin-left: 1%;
      width: 220px;
      background-color: white;
      position: fixed;
      top: 0px;
      left: 0px;
      overflow: hidden;
      height: 96%;
      border: 1px solid #e3e2e2;
      border-radius: 20px;
      /*
      height: 100vh;
       */

    }

    .closed{
      width: 70px !important;
    }

    /* Sidebar links */
    .sidebar a {
      display: block;
      color: black;
      padding: 16px;
      text-decoration: none;
    }

    .sidebar b {
      display: block;
      color: #E65934;
      text-decoration: none;
      width: 220px;
      text-align: center;
      padding-top: 15%;
      padding-bottom: 30%;
      font-size: 30px;
    }

    /* Active/current link */
    .sidebar a.active {
      background-color: #FBFBFB;
      color: white;
    }

    /* Links on mouse-over */
    .sidebar a:hover:not(.active) {
      background-color: #555;
      color: white;
    }

    /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
    div.content {
      margin-left: 200px;
      padding: 1px 16px;
      height: 1000px;
    }


    .bottom-elements {
      position: absolute;
      bottom: 0;
      margin-bottom: 7.5%;
      display: flex;
      width: 100%;
    }

    .block {
      width: calc(100% - 20px);
      height: 65px;
      margin: 2px 10px;
      display: inline-flex;
      border-radius: 20px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: 580;
      color: #969696;

      //border-top: 1px solid #e3e2e2;


      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      /*
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

       */


    }
    .cardSVG{
      margin-left: 10%;
      margin-bottom: 5px;
    }

    .selected {
      font-weight: 600;
      background-color: rgba(181,181,181,0.2) !important;
      //color: rgb(255, 102, 95);
      color: black;
    }

    .imgSelected{
      //filter: invert(66%) sepia(48%) saturate(1547%) hue-rotate(330deg) brightness(92%) contrast(88%);
      filter: brightness(0%);
    }

    .notSelected {
      color: #969696;
      fill: #969696;
    }

    /* On screens that are less than 700px wide, make the sidebar into a topbar */
    @media screen and (max-width: 700px) {
      .sidebar {
        width: 100%;
        height: auto;
        position: relative;
      }
      .sidebar a {float: left;}
      .sidebar b {
        float: left;
        width: 100%;
      }

      div.content {margin-left: 0;}
    }
    .reversed-a {
      display: inline-block;
      transform: rotate(180deg);
    }

    /* On screens that are less than 400px, display the bar vertically, instead of horizontally */
    @media screen and (max-width: 400px) {
      .sidebar a {
        text-align: center;
        float: none;
      }
    }







  ` ],
  template: `
    <div class="sidebar shadow-sm" scroll="no" [ngClass]="{'closed': aperto === false}">

      <b >FILL<span class="reversed-a" >A</span>PP</b>



      <!--div class="menuItem"  [ngClass]="{'selected': this.posizione === 0, 'block': true}" (click)="cambiaRoute('home')">
        <svg class="cardSVG" xmlns="http://www.w3.org/2000/svg" width="19" height="20" viewBox="0 0 19 20">
          <g id="Iconly_Bold_Home" data-name="Iconly/Bold/Home" transform="translate(-2.5 -2)">
            <g id="Home">
              <path [ngClass]="{'selected': this.posizione === 0}" id="Vector" d="M6.635,18.773V15.716A1.419,1.419,0,0,1,8.058,14.3h2.874a1.429,1.429,0,0,1,1.007.414,1.408,1.408,0,0,1,.417,1v3.058a1.213,1.213,0,0,0,.356.867,1.231,1.231,0,0,0,.871.36h1.961a3.46,3.46,0,0,0,2.443-1A3.41,3.41,0,0,0,19,16.578V7.867a2.473,2.473,0,0,0-.9-1.9L11.434.676A3.1,3.1,0,0,0,7.485.747L.967,5.965A2.474,2.474,0,0,0,0,7.867v8.7A3.444,3.444,0,0,0,3.456,20H5.372a1.231,1.231,0,0,0,1.236-1.218Z" transform="translate(2.5 2)" fill="#969696"/>
            </g>
          </g>
        </svg>

        <div style="padding-left: 25px">Home</div>
      </div-->

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 5, 'block': true}" (click)="cambiaRoute('report')">
        <img [ngClass]="{'imgSelected': this.posizione === 5}" src="assets/images/icons8-slice-90.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">

        <div style="padding-left: 25px">Reportistica</div>
      </div>



      <div  class="menuItem"  [ngClass]="{'selected': this.posizione === 1, 'block': true}" (click)="cambiaRoute('magazzino')">
        <img [ngClass]="{'imgSelected': this.posizione === 1}" src="assets/images/icons8-product-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Magazzino</div>
      </div>

      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 3, 'block': true}" (click)="cambiaRoute('menu')">
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 3}" src="assets/images/icons8-menu-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Menu</div>
      </div>

      <!--div class="menuItem"  [ngClass]="{'selected': this.posizione === 2, 'block': true}" (click)="cambiaRoute('ordini')">
        <img [ngClass]="{'imgSelected': this.posizione === 2}" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-order-96.png?alt=media&token=55a69c2e-a05d-4115-bd85-fa4454094c11" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">



        <div style="padding-left: 25px">Ordini</div>
      </div-->



      <!--  #nonav   -->
      <div class="menuItem"  [ngClass]="{'selected': this.posizione === 4, 'block': true}" (click)="navigateToCassa()" >
        <!--span class="store" [ngStyle]="{background: 'url(' + this.link + ')'}"></span-->
        <img [ngClass]="{'imgSelected': this.posizione === 4}" src="assets/images/icons8-cash-register-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


        <div style="padding-left: 25px">Cassa</div>
      </div>



      <!--svg (click)="aperto = !aperto" style="bottom: 10px; position: absolute; right: 10px; cursor: pointer" xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 512 512"><path d="M504.3 273.6c4.9-4.5 7.7-10.9 7.7-17.6s-2.8-13-7.7-17.6l-112-104c-7-6.5-17.2-8.2-25.9-4.4s-14.4 12.5-14.4 22l0 56-192 0 0-56c0-9.5-5.7-18.2-14.4-22s-18.9-2.1-25.9 4.4l-112 104C2.8 243 0 249.3 0 256s2.8 13 7.7 17.6l112 104c7 6.5 17.2 8.2 25.9 4.4s14.4-12.5 14.4-22l0-56 192 0 0 56c0 9.5 5.7 18.2 14.4 22s18.9 2.1 25.9-4.4l112-104z"/></svg-->

      <div class="bottom-elements" >

        <!--div (click)="logout()" class="menuItem" style="display: flex; align-items: center; margin-right: 20px;" >
          <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-log-out-100.png?alt=media&token=63460a1f-cbc8-4e65-ad04-d5e25e94c614" style="width: 25px; aspect-ratio: 1 / 1;">
        </div>

        <div class="menuItem" style="display: flex; align-items: center; " (click)="cambiaRoute('impostazioni')">
          <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-setting-100.png?alt=media&token=1b99a74f-92de-4e12-b828-5c19f99ea319" style="width: 25px; aspect-ratio: 1 / 1;">
        </div-->

        <div class="menuItem"  [ngClass]="{'selected': this.posizione === 7, 'block': true}" (click)="cambiaRoute('impostazioni')" >

          <img [ngClass]="{'imgSelected': this.posizione === 7}" src="assets/images/icons8-setting-100.png" style="width: 25px; aspect-ratio: 1 / 1; margin-left: 10%">


          <div style="padding-left: 25px">Impostazioni</div>
        </div>

      </div>



    </div>
  `
})
export class NavBarComponent implements OnInit{

  aperto = true;

  posizione: any;

  private readonly IMAGE_NAMES = [
    'icons8-setting-100.png',
    'icons8-cash-register-100.png',
    'icons8-order-96.png',
    'icons8-menu-100.png',
    'icons8-product-100.png',
    'icons8-slice-90.png',
    'icons8-edit-90.png',
  ];
  constructor(
    // public authService: AuthService,
    private router: Router, public authService: AuthService,
    private dataServiceGenerico: DataServiceGenerico,
    private ipcService: IpcService

  ) {

    router.events.subscribe((val) => {
      // see also
      if ('url' in val)
      {
        //console.log("hdhdhdhdhdhdhhdhd", val.url);
        if (val.url.includes('home'))
        {
          this.posizione = 0;
          //console.log('home');
        }
        if (val.url.includes('magazzino'))
        {
          this.posizione = 1;
          //console.log('home');
        }
        if (val.url.includes('ordini'))
        {
          this.posizione = 2;
          //console.log('home');
        }
        if (val.url.includes('menu'))
        {
          this.posizione = 3;
          //console.log('home');
        }
        if (val.url.includes('cassa'))
        {
          this.posizione = 4;
          //console.log('home');
        }
        if (val.url.includes('report'))
        {
          this.posizione = 5;
          //console.log('home');
        }

        if (val.url.includes('costi'))
        {
          this.posizione = 6;
          //console.log('home');
        }

        if (val.url.includes('impostazioni'))
        {
          this.posizione = 7;
          //console.log('home');
        }
      }
    });

  }



  /**
   * Simulate logout
   */

  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  cambiaRoute(route): void {

    this.router.navigateByUrl(route);

  }

  navigateToCassa(): void{
    this.router.navigate(['cassa'], { fragment: 'nonav' })
  }
  immagini = {}

  findPropertyInString(str: string): string | null {
    let result: string | null = null;
    Object.keys(this.immagini).some(prop => {
      if (str.includes(prop)) {
        result = prop;
        return true;
      }
      return false;
    });
    return result;
  }

  ngOnInit(): void {

   //this.initializeImagePaths2()

    //this.immagini = this.dataServiceGenerico.getImmagini()
  }

  /*

  initializeImagePaths2() {
    // Supponiamo che 'items' sia il tuo array di elementi del menu

    this.IMAGE_NAMES.forEach((categoria, i) => {
      this.immagini[categoria] = this.ipcService.getLocalImage2(categoria);
    })
  }

   */
}
