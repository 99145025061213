<div class="row">
  <div class="column">
      <div style="
      width: 100%;
      height: 60px;
      border: 1px solid #ece8e8;
      border-radius: 15px;
      background-color: white;
      margin-top: 1.5vh;
      display: flex;
      align-items: center;
        ">
          <div style="width: 70%; height: 100%; margin-left: 10px; display: flex">
              <div [ngStyle]="getTabStyle('Carrello')"
                   (click)="selezione = 'Carrello'">
                  Carrello
              </div>
              <div [ngStyle]="getTabStyle('Cronologia')"
                   (click)="selezione = 'Cronologia'">
                  Cronologia
              </div>
              <div [ngStyle]="getTabStyle('Calendario')"
                   (click)="selezione = 'Calendario'">
                  Calendario
              </div>


          </div>
          <div style="width: calc(30% - 10px);
                        height: 100%;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
">
              <div class="menu-options" >
                  <!-- Select for available menus -->
                  <div class="search">
                      <div>
                          <input type="text" placeholder="Cerca . . ." required>
                      </div>
                  </div>

                  <select  style=" height: 40px; border: none; padding: 5px 5px; font-weight: 600; font-size: 15px; font-family: 'Inter', sans-serif">
                      <option [value]="menu">Ristorante Test</option>
                  </select>

                  <!-- Button to create a new menu -->
                  <!--img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7"
                        style="width: 30px; aspect-ratio: 1 / 1; cursor: pointer" (click)="createNewMenu();"-->

              </div>
          </div>
      </div>






    <div class="container menu" id="menuListaPiatti" *ngIf="selezione == 'Carrello'">
      <div style="pointer-events: auto; margin-top: 1vh">
        <div style="padding-top: 0%; padding-left: 0px">
          <!-- Contenitore per la tabella con altezza fissa e scorrimento verticale -->
          <div class='table-responsive' style="height: 88vh; overflow-y: auto;">

            <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

              <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
                  <mat-header-cell *matHeaderCellDef [ngStyle]="{'justify-content': column.field === 'Aggiungi' ? 'center' : 'inherit'}">
                      <div *ngIf="column.field != 'Aggiungi'">
                          {{ column.field }}
                      </div>
                      <div *ngIf="column.field === 'Aggiungi'" style="display: flex; align-items: center; margin: 0px">
                          <categorieCard class="col-12 col-md-12 menuItem" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"

                                         [nome]="'Riepilogo: '+totalOrderAmount+'€'"
                                         altezza="45"
                                         [bg]="'#3498db'"
                                         [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-product-100.png?alt=media&token=e0dcb4a5-c4f4-4111-8b7f-ade6cb4cab62'"
                                         (click)="openDialogRiepilogo()"
                          >
                          </categorieCard>
                      </div>
                  </mat-header-cell>
                <mat-cell *matCellDef="let row" [ngStyle]="{'justify-content': column.field === 'Aggiungi' ? 'center ' : 'inherit'}">
                  <input *ngIf="column.field !== 'Categoria' && column.field !== 'Sync menu' && column.field !== 'Prezzo' && column.field !== 'Aggiungi'" style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px " type="text" class="searchTerm"  [(ngModel)]="row[column.field]" >

                    <ng-container *ngIf="column.field === 'Titolo'">
                      <span class="product-title">
                          <span *ngIf="cartItems.has(row.id)" class="red-dot"></span> <!-- Red dot if item is in cart -->
                      </span>
                    </ng-container>
                    <ng-container *ngIf="column.field === 'Prezzo'">
                    <span class="quantity" style="font-weight: bold">{{row.Prezzo}} €</span>

                  </ng-container>
                  <ng-container *ngIf="column.field === 'Aggiungi'">



                    <div style="width: 30px; margin-left: 10px">
                      <button class="add-button" *ngIf="!cartItems.has(row.id)">
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-64.png?alt=media&token=56c275a9-9eb6-4d1e-a1c7-20012437d442'" alt="Azione" style="max-width: 15px; max-height: 15px;" (click)="openDialogAdd(row)">
                      </button>
                        <button class="action-button" *ngIf="cartItems.has(row.id)">
                            <img [src]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-edit-90.png?alt=media&token=3975c982-b1e5-467a-92cd-0f81e5a30c5a'" alt="Azione" style="max-width: 15px; max-height: 15px;" (click)="openDialogAdd(row)">
                        </button>
                    </div>

                  </ng-container>
                </mat-cell>
              </ng-container>

              <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              <!-- Group header -->
              <ng-container matColumnDef="groupHeader">
                <mat-cell colspan="999" *matCellDef="let group">
                  <strong>{{group[groupByColumns[group.level-1]]}}</strong>

                </mat-cell>
              </ng-container>

              <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

            </mat-table>

          </div>
        </div>
      </div>
    </div>

      <div class="container menu" *ngIf="selezione == 'Cronologia'">
          <cronologia></cronologia>
      </div>

      <div class="container menu" *ngIf="selezione == 'Calendario'">
          <calendario></calendario>
          </div>

</div>
</div>
