import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges, ViewChild
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../router.module';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Menu} from "../menu/menu";
import {AggiuntaPiatto} from "../aggiunta-piatto/aggiunta-piatto";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from '@angular/material/table';
import {CommonService} from "../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {interval} from "rxjs";



@Component({
  selector: 'app-ordini',
  templateUrl: './schermo-cucina.html',
  styleUrls: ['./schermo-cucina.css']
})
  export class SchermoCucina implements OnInit{


  getOrdine(i: any)
  {
    //console.log("ordine: ", i)
    return i.ordine
  }
  sezioneNovita = 0;

  dataSourceDipendenti2= new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginatorDipendenti2: MatPaginator;
  @ViewChild(MatSort) sortDipendenti2: MatSort;

  displayedColumnsDipendenti2: string[] = ['nome', 'email', 'Action', 'Elimina'];


  modalOptions = { backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {} };


  display = 'none';

  closeResult = '';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';

  scontrini = [


    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import cvs',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import json',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png',
      nome: 'Import xml',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    }
  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    { nome: 'menu', descrizione: 'Available', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Ready', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' },
    { nome: 'menu', descrizione: 'Started', link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f' }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
    private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog,
    public commonService: CommonService,
    public dataServiceGenerico: DataServiceGenerico
  ){

    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }



  openDialog(): any {
    const modalRef = this.dialog.open(AggiuntaPiatto, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
    });

  }

  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  getProperties(object: any)
  {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if(object !== undefined)
    {
      return Object.keys(object)
    }
  }

  camerieri2 = []
  ngOnInit(): void {


    this.dataServiceGenerico.camerieriSubjectItem$
      .subscribe(item => {

        console.log("received camerieri subject")
        if(item.length()> 0)
        {
          this.camerieri2 = item.filter(cameriere => cameriere.statoPagato == 0)
        }
        else
        {
          this.camerieri2 = []
        }
        }
      )

    console.log('firebase: ', firebase);
    interval(10000).subscribe(x => {
      this.retriveCarrello();
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.userService.emitter.subscribe(
          () => {
            this.retriveCarrello();
          }
        );
      } else {
        // No user is signed in.
      }
    });


  }

  ordini = [];

  receipts = [];

  idRistorante = '';

  camerieri = [];


  cronologiaVar = false;
  cronologia()
  {
    this.cronologiaVar = !this.cronologiaVar;

    this.retriveCarrello();
  }

  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);


    let cronologiaNum = this.cronologiaVar == true? 3: 0;

    this.commonService.getOrdiniCucina(3,cronologiaNum).subscribe(
      (dataCamerieri) =>
      {
        if (dataCamerieri !== undefined) {

          if(dataCamerieri.length> 0)
          {
              this.camerieri2 = dataCamerieri.sort((a, b) => {
                if (a['bloccato'] && !b['bloccato']) {
                  return -1;
                } else if (!a['bloccato'] && b['bloccato']) {
                  return 1;
                } else {
                  return 0
                }
              })
              console.log("camerieri: ", this.camerieri2)
          }
          else
            this.camerieri2 = [];

        }
        else
        {
          this.camerieri2 = []

        }
      }
    )

  }

  ordiniSave: any[] = [
    {
      stato: 0,
      totale: '100,00',
      fonte: 0,
      nome: 'Alessandro',
      numero: '0011',
      statoPagato: 1
    },
    {
      stato: 1,
      totale: '24,00',
      fonte: 1,
      nome: 'Alessandro',
      numero: '0010',
      statoPagato: 0
    },
    {
      stato: 2,
      totale: '25,00',
      fonte: 0,
      nome: 'Alessandro',
      numero: '0009',
      statoPagato: 1
    },
    {
      stato: 0,
      totale: '32,00',
      fonte: 1,
      nome: 'Alessandro',
      numero: '0008',
      statoPagato: 0
    },
    {
      stato: 0,
      totale: '44,00',
      fonte: 2,
      nome: 'Alessandro',
      numero: '0007',
      statoPagato: 1
    },
    {
      stato: 0,
      totale: '16,00',
      fonte: 0,
      nome: 'Alessandro',
      numero: '0006',
      statoPagato: 0
    },
    {
      stato: 0,
      totale: '28,00',
      fonte: 2,
      nome: 'Alessandro',
      numero: '0005',
      statoPagato: 1
    },
    {
      stato: 0,
      totale: '1,00',
      fonte: 0,
      nome: 'Alessandro',
      numero: '0004',
      statoPagato: 0
    },
    {
      stato: 0,
      totale: '22,00',
      fonte: 1,
      nome: 'Alessandro',
      numero: '0003',
      statoPagato: 1
    },
    {
      stato: 0,
      totale: '211,00',
      fonte: 0,
      nome: 'Alessandro',
      numero: '0002',
      statoPagato: 0
    },
    {
      stato: 0,
      totale: '74,00',
      fonte: 2,
      nome: 'Alessandro',
      numero: '0001',
      statoPagato: 1
    },
  ];


  filtroSource = 3;
  filtroStato = 2;
  filtraApp()
  {
    this.filtroSource = 0;
    this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 0);
    this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);
  }
  filtraChiosco()
  {
    this.filtroSource = 1;
    this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 1);
    this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }
  filtraCassa()
  {
    this.filtroSource = 2;
    this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 2);
    this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }

  filtraTutto()
  {
    this.filtroSource = 3;
    this.ordini = this.filtroStato == 2? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.statoPagato === this.filtroStato);
  }

  filtraPagato() {

    this.filtroStato = 0;

    this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 0);
    this.ordini = this.filtroSource == 3? this.ordini: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraNonPagato()
  {
    this.filtroStato = 1;

    this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraStatoTutto()
  {
    this.filtroStato = 2;
    this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
  }

  filter = '';
  filtraCerca()
  {
    this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
    this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

    this.ordini = this.ordini.filter(data =>  Object.keys(data).some(k => this.filter.trim().split(" ").some(el => typeof data[k] !== 'string' && typeof data[k] !== 'number'? Object.keys(data[k]).some(property => data[k][property].toString().toLowerCase().includes(el.toLowerCase())) : data[k]?.toString().toLowerCase().includes(el.toLowerCase()))))
  }


  calcolaTotale(): void {

    this.numeroElementi = 0;
    this.tot = 0;

    this.carrelloTmp.piatti.forEach(piatto => {
      console.log('piatto', piatto);
      this.numeroElementi = this.numeroElementi + piatto.quantita;

      let prezzoPiatto = piatto.prezzo;

      if (piatto.opzioni) {
        piatto.opzioni.forEach(opzione => {

          prezzoPiatto = prezzoPiatto + opzione.prezzo;
        });
      }
      this.tot = this.tot + (prezzoPiatto * piatto.quantita);


    });
  }


  calcolaMancia(): number {


    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;
  }

  onSwipeRight(evt, cameriere) {
    console.log("swipe right: ", cameriere.carrello)
    if(this.cronologiaVar == false)
    {
      cameriere['statoPagato'] = 3;
      this.commonService.updateOrdine(cameriere.id, cameriere)
    }
  }

  onSwipeLeft(evt, cameriere) {
    console.log("swipe left: ", cameriere.carrello)
    if(this.cronologiaVar == false)
    {
      cameriere['bloccato'] = true;
      this.commonService.updateOrdine(cameriere.id, cameriere)
    }
  }
}
