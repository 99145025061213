

        <!-- Simple Search Bar Component -->
        <div class="simple-search-container" *ngIf="selezione == 'lista'">
            <div class="simple-search-input-container">
                <input type="text" class="simple-search-input" placeholder="Cerca fornitore..." [(ngModel)]="searchText" (keyup)="applyFilter()">
            </div>
        </div>

        <div class="content-container" *ngIf="selezione == 'lista'">
            <div class="table-section" [ngClass]="{'with-detail': selectedFornitore}">
                <div class="container menu" id="listaFornitori">
                    <div style="pointer-events: auto;">
                        <div style="padding-top: 0%; padding-left: 0px">
                            <div class='table-responsive' style="height: 89.5vh; overflow-y: auto;">
                                <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                                    <!-- Header con pulsante Aggiungi -->
                                    <ng-container *ngFor="let column of columns" [matColumnDef]="column.field">
                                        <mat-header-cell *matHeaderCellDef [ngStyle]="{'justify-content': column.field == 'telefono' ? 'flex-end' : 'inherit'}">
                                            <div *ngIf="column.field != 'telefono'">
                                                {{ column.field === 'nome' ? 'Nome' : column.field === 'email' ? 'Email' : column.field }}
                                            </div>
                                            <div *ngIf="column.field === 'telefono'" style="display: flex; align-items: center; margin: 0px">
                                                <categorieCard class="col-12 col-md-12 menuItem" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                                               [nome]="'Aggiungi Fornitore'"
                                                               altezza="45"
                                                               [link]=" 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7' "
                                                               [bg]="'#FF0000B3'"
                                                               (click)="openDialog()">
                                                </categorieCard>
                                            </div>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let row" (click)="selectFornitore(row)">
                                            <!-- Celle standard -->
                                            <input style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px;"
                                                   type="text" class="searchTerm" [value]="row[column.field]" readonly>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- Definizioni delle righe -->
                                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                                             [class.selected]="selectedFornitore?.id === row.id">
                                    </mat-row>
                                </mat-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Sezione Dettaglio -->
            <div class="detail-section" *ngIf="selectedFornitore">
                <fornitore-detail
                        [fornitore]="selectedFornitore"
                        (fornitoreUpdated)="onFornitoreUpdate($event)"
                        (fornitoreDeleted)="onFornitoreDeleted()">
                </fornitore-detail>
            </div>
        </div>