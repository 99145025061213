// menu-partial-confirm-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-menu-partial-confirm-dialog',
    template: `
        <div class="dialog-container">
            <div class="sticky-container">
                <div class="save-button" [ngClass]="{'disabled': !confirmForm.valid}" (click)="onSubmit()">
                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Conferma">
                </div>
                <div class="close-button" (click)="onCancel()">
                    <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                         style="width: 30px; aspect-ratio: 1 / 1;"
                         title="Chiudi">
                </div>
            </div>

            <div style="height: 80vh; overflow-y: auto; padding: 20px;">
                <form [formGroup]="confirmForm">
                    <div style="margin-bottom: 20px">
                        <div style="width: 100%; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
                            <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                                <!-- Nome -->
                                <div class="flex flex-col gap-2">
                                    <label class="font-bold text-gray-700 text-heading-sm">Nome Conferma *</label>
                                    <input
                                            placeholder="Inserisci un nome"
                                            class="custom-input"
                                            formControlName="name">
                                </div>

                                <!-- Upload Foto -->
                                <div class="flex flex-col gap-2">
                                    <label class="font-bold text-gray-700 text-heading-sm">Foto (Opzionale)</label>
                                    <input
                                            type="file"
                                            class="file-input"
                                            accept="image/*"
                                            (change)="onFileSelected($event)">

                                    <!-- Image Preview -->
                                    <div class="preview-container" *ngIf="imagePreview">
                                        <img [src]="imagePreview" class="image-preview" alt="Preview">
                                    </div>
                                </div>

                                <!-- Progress Bar (optional) -->
                                <div class="progress-bar" *ngIf="uploadProgress > 0">
                                    <div class="progress" [style.width.%]="uploadProgress"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `,
    styles: [`
        .dialog-container {
            position: relative;
            padding: 20px;
            max-width: 800px;
        }

        .sticky-container {
            position: sticky;
            top: 0;
            z-index: 1000;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin: -20px -20px 0;
            padding: 10px 20px;
            background-color: white;
        }

        .save-button,
        .close-button {
            width: 30px;
            height: 30px;
            cursor: pointer;
            transition: opacity 0.2s ease;
        }

        .save-button.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .custom-input {
            background-color: #f7f9fb;
            border-color: #d2d9e0 !important;
            border-width: 1px;
            border-radius: 10px;
            padding: 0.5rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5rem;
            width: 90%;
            border-style: solid;
        }

        .custom-input:focus {
            outline-color: rgb(0 125 104);
            border-color: rgb(0 125 104) !important;
        }

        .file-input {
            width: 90%;
            padding: 10px;
            border: 2px dashed #d2d9e0;
            border-radius: 10px;
            cursor: pointer;
            background-color: #f7f9fb;
        }

        .preview-container {
            max-width: 300px;
            margin: 10px 0;
        }

        .image-preview {
            width: 100%;
            height: auto;
            max-height: 200px;
            object-fit: contain;
            border-radius: 8px;
            border: 1px solid #d2d9e0;
        }

        .progress-bar {
            margin-top: 10px;
            width: 90%;
            height: 4px;
            background-color: #e5e7eb;
            border-radius: 2px;
            overflow: hidden;
        }

        .progress {
            height: 100%;
            background-color: rgb(0 125 104);
            transition: width 0.3s ease;
        }

        .flex-col {
            display: flex;
            flex-direction: column;
        }

        .gap-2 { gap: 0.5rem; }
        .gap-3 { gap: 0.75rem; }
        .gap-5 { gap: 1.25rem; }
        .gap-8 { gap: 2rem; }

        .justify-between {
            justify-content: space-between;
        }

        .font-bold {
            font-weight: 700;
        }

        .text-gray-700 {
            color: rgb(63 72 81);
        }

        .text-heading-sm {
            font-size: 14px;
            line-height: 24px;
        }
    `]
})
export class MenuPartialConfirmDialogComponent {
    confirmForm: FormGroup;
    selectedFile: File | null = null;
    imagePreview: string | null = null;
    uploadProgress: number = 0;

    constructor(
        private dialogRef: MatDialogRef<MenuPartialConfirmDialogComponent>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.confirmForm = this.fb.group({
            name: ['', Validators.required],
            photo: [null]
        });
    }

    onFileSelected(event: any): void {
        const file = event.target.files[0];
        if (file) {
            this.selectedFile = file;
            this.confirmForm.patchValue({
                photo: file
            });

            // Create image preview
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);

            // Simulate upload progress (remove in production)
            this.uploadProgress = 0;
            const interval = setInterval(() => {
                this.uploadProgress += 10;
                if (this.uploadProgress >= 100) {
                    clearInterval(interval);
                }
            }, 200);
        }
    }

    onSubmit(): void {
        if (this.confirmForm.valid) {
            this.dialogRef.close({
                confirmed: true,
                name: this.confirmForm.get('name')?.value,
                photo: this.selectedFile
            });
        }
    }

    onCancel(): void {
        this.dialogRef.close({ confirmed: false });
    }
}