import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';

interface ProductStats {
    id: string;
    title: string;
    category: string;
    image_link: string;
    quantitySold: number;
    totalRevenue: number;
    variant?: string; // Campo per la variante
}

@Component({
    selector: 'app-product-statistics',
    templateUrl: './product-statistics.component.html',
    styleUrls: ['./product-statistics.component.css']
})
export class ProductStatisticsComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    displayedColumns: string[] = ['image', 'title', 'category', 'quantitySold', 'totalRevenue'];
    dataSource: MatTableDataSource<ProductStats>;

    // Mantengo una copia completa dei dati originali filtrati solo per data
    private originalFilteredData: ProductStats[] = [];

    // Filtri di categoria
    selectedCategory = new FormControl('');
    categories: string[] = [];
    categoryColors: { [key: string]: { background: string, color: string } } = {};

    // Filtri data
    dateFilter = new FormControl('today');
    startDate = new FormControl(new Date());
    endDate = new FormControl(new Date());

    // Filtri orario
    useTimeFilter = new FormControl(false);
    startTimeHour = new FormControl('00');
    startTimeMinute = new FormControl('00');
    endTimeHour = new FormControl('23');
    endTimeMinute = new FormControl('59');

    // Ricerca prodotti
    searchInput = new FormControl('');

    // Preset date
    datePresets = [
        { value: 'today', label: 'Oggi' },
        { value: 'yesterday', label: 'Ieri' },
        { value: 'week', label: 'Ultima Settimana' },
        { value: 'month', label: 'Ultimo Mese' },
        { value: 'custom', label: 'Personalizzato' }
    ];

    // Dati statistici
    totalRevenue: number = 0;
    totalProducts: number = 0;
    bestSeller: string = '';

    // Dati originali
    allOrders: any[] = [];
    allOrderItems: any[] = [];

    // UI states
    loading = true;
    error = false;

    constructor(
        private dataService: DataServiceGenerico,
        private commonService: CommonService
    ) {
        this.dataSource = new MatTableDataSource<ProductStats>();
    }

    ngOnInit(): void {
        this.initializeFilters();
        this.loadProductStats();

        // Configura il filtro personalizzato per la ricerca
        this.dataSource.filterPredicate = (data: ProductStats, filter: string) => {
            // Se il filtro è vuoto, mostra tutto
            if (!filter) return true;

            const filterValue = filter.toLowerCase();

            // Crea un testo di ricerca combinato che include titolo + variante
            const searchText = data.title.toLowerCase() + ' ' +
                (data.variant ? data.variant.toLowerCase() : '');

            // Cerca nel testo combinato o nella categoria
            return searchText.includes(filterValue) ||
                data.category.toLowerCase().includes(filterValue);
        };

        // Sottoscrizione al cambio del valore di ricerca
        this.searchInput.valueChanges.subscribe(value => {
            this.applySearchFilter(value);
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.setupSortingBehavior();
    }

    private setupSortingBehavior(): void {
        this.dataSource.sortingDataAccessor = (item: ProductStats, property: string) => {
            switch (property) {
                case 'title': return item.title.toLowerCase();
                case 'category': return item.category.toLowerCase();
                case 'quantitySold': return item.quantitySold;
                case 'totalRevenue': return item.totalRevenue;
                default: return item[property as keyof ProductStats];
            }
        };
    }

    private initializeFilters(): void {
        // Set default date range to today
        const today = new Date();
        const startOfDay = new Date(today);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(today);
        endOfDay.setHours(23, 59, 59, 999);

        this.startDate.setValue(startOfDay);
        this.endDate.setValue(endOfDay);
    }

    async loadProductStats(): Promise<void> {
        try {
            this.loading = true;
            this.error = false;

            const restaurantId = await this.dataService.getCurrentRestaurantId();
            this.allOrders = await this.getOrders(restaurantId);

            // Estrai tutti gli elementi dai carrelli degli ordini
            this.extractOrderItems();

            // Applica i filtri iniziali
            await this.applyFilters();

            this.loading = false;
        } catch (error) {
            console.error('Error loading statistics:', error);
            this.error = true;
            this.loading = false;
        }
    }

    private async getOrders(restaurantId: string): Promise<any[]> {
        // Recupera gli ordini completati (statoPagato === 0)
        const ordersSnapshot = await firebase.firestore()
            .collection('ordini')
            .where('ristorante', '==', restaurantId)
            .where('statoPagato', '==', 0)
            .get();

        return ordersSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data,
                timestamp: data.data ? new Date(parseInt(data.data)) : new Date()
            };
        });
    }

    private extractOrderItems(): void {
        this.allOrderItems = [];

        this.allOrders.forEach(order => {
            if (!order.carrello) return;

            // Estrai tutti gli elementi dal carrello
            Object.keys(order.carrello).forEach(key => {
                const item = order.carrello[key];

                // Salta gli elementi che non hanno prezzo o sono sconti
                if (key === 'Sconto' || !item.prezzo) return;

                // Estrai le informazioni sulle varianti se presenti
                let variant = '';
                if (item.variants && item.variants.length > 0) {
                    // Estrai i nomi delle varianti considerando vari formati possibili
                    const variantTexts = item.variants.map((v: any) => {
                        if (v.variantTitle && v.name) {
                            return `${v.variantTitle}: ${v.name}`;
                        } else if (v.name) {
                            return v.name;
                        } else if (typeof v === 'string') {
                            return v;
                        } else if (v.value) {
                            return v.value;
                        }
                        return '';
                    }).filter(text => text && text.trim() !== '');

                    variant = variantTexts.join(', ');
                }

                this.allOrderItems.push({
                    orderId: order.id,
                    orderTimestamp: order.timestamp,
                    itemId: key,
                    variant: variant,
                    ...item
                });
            });
        });
    }

    private async applyFilters(): Promise<void> {
        // Filtra per data e ora
        let filteredItems = this.filterByDateTime(this.allOrderItems);

        // Processa gli elementi filtrati per ottenere le statistiche
        const stats = this.processItems(filteredItems);

        // Salva i dati originali filtrati solo per data/ora
        this.originalFilteredData = [...stats];

        // Aggiorna la sorgente dati
        this.dataSource.data = stats;

        // Aggiorna categorie
        this.categories = [...new Set(stats.map(item => item.category))].sort();
        this.categoryColors = this.generateCategoryColors(this.categories);

        // Calcola totali
        this.calculateTotals(stats);

        // Applica il filtro di categoria se presente
        if (this.selectedCategory.value) {
            this.onCategoryChange();
        }

        // Applica il filtro di ricerca se presente
        if (this.searchInput.value) {
            this.applySearchFilter(this.searchInput.value);
        }
    }

    private filterByDateTime(items: any[]): any[] {
        // Ottieni gli intervalli di data in base al filtro selezionato
        let startDateTime: Date, endDateTime: Date;

        // Applica il filtro della data
        const today = new Date();

        if (this.dateFilter.value === 'custom') {
            startDateTime = this.startDate.value;
            endDateTime = this.endDate.value;
        } else if (this.dateFilter.value === 'today') {
            startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
            endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        } else if (this.dateFilter.value === 'yesterday') {
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            startDateTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
            endDateTime = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59);
        } else if (this.dateFilter.value === 'week') {
            const lastWeek = new Date(today);
            lastWeek.setDate(lastWeek.getDate() - 7);
            startDateTime = new Date(lastWeek.getFullYear(), lastWeek.getMonth(), lastWeek.getDate());
            endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        } else if (this.dateFilter.value === 'month') {
            const lastMonth = new Date(today);
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            startDateTime = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), lastMonth.getDate());
            endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        } else {
            // All-time (default to 5 years)
            startDateTime = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
            endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        }

        // Se è attivo il filtro per orario, applicalo
        if (this.useTimeFilter.value) {
            const startHour = parseInt(this.startTimeHour.value);
            const startMinute = parseInt(this.startTimeMinute.value);
            const endHour = parseInt(this.endTimeHour.value);
            const endMinute = parseInt(this.endTimeMinute.value);

            // Applica l'orario alle date
            startDateTime.setHours(startHour, startMinute, 0);
            endDateTime.setHours(endHour, endMinute, 59);
        }

        // Filtra gli elementi per data e ora
        return items.filter(item => {
            const orderDate = item.orderTimestamp;

            if (!orderDate) return false;

            // Verifica se l'ordine è all'interno dell'intervallo di date
            if (orderDate < startDateTime || orderDate > endDateTime) {
                return false;
            }

            // Verifica l'ora se richiesto
            if (this.useTimeFilter.value) {
                const orderHour = orderDate.getHours();
                const orderMinutes = orderDate.getMinutes();
                const orderTimeValue = orderHour * 60 + orderMinutes;

                const startHour = parseInt(this.startTimeHour.value);
                const startMinute = parseInt(this.startTimeMinute.value);
                const endHour = parseInt(this.endTimeHour.value);
                const endMinute = parseInt(this.endTimeMinute.value);

                const startTimeValue = startHour * 60 + startMinute;
                const endTimeValue = endHour * 60 + endMinute;

                return orderTimeValue >= startTimeValue && orderTimeValue <= endTimeValue;
            }

            return true;
        });
    }

    private processItems(items: any[]): ProductStats[] {
        const statsMap = new Map<string, ProductStats>();

        items.forEach(item => {
            const price = Number(item.prezzo) || 0;
            const quantity = Number(item.quantita) || 1;

            // Crea una chiave unica che considera anche le varianti
            const variantKey = item.variant ? `-${item.variant}` : '';
            const itemId = `${item.id || item.itemId}${variantKey}`;

            const title = item.title || 'Prodotto sconosciuto';
            const category = item.category || 'Non categorizzato';

            if (!statsMap.has(itemId)) {
                statsMap.set(itemId, {
                    id: itemId,
                    title: title,
                    category: category,
                    image_link: item.image_link || '',
                    quantitySold: 0,
                    totalRevenue: 0,
                    variant: item.variant || ''
                });
            }

            const stats = statsMap.get(itemId)!;
            stats.quantitySold += quantity;
            stats.totalRevenue += (price * quantity);
        });

        return Array.from(statsMap.values());
    }

    private calculateTotals(stats: ProductStats[]): void {
        this.totalRevenue = stats.reduce((sum, item) => sum + item.totalRevenue, 0);
        this.totalProducts = stats.reduce((sum, item) => sum + item.quantitySold, 0);

        if (stats.length > 0) {
            const bestSeller = stats.reduce((best, current) =>
                    current.quantitySold > (best?.quantitySold || 0) ? current : best
                , stats[0]);

            this.bestSeller = bestSeller?.title || '';
        } else {
            this.bestSeller = 'Nessun prodotto';
        }
    }

    private generateCategoryColors(categories: string[]): { [key: string]: { background: string, color: string } } {
        const categoryColors: { [key: string]: { background: string, color: string } } = {};
        const hueStep = 360 / (categories.length || 1);

        categories.forEach((category, index) => {
            const hue = index * hueStep;
            categoryColors[category] = {
                background: `hsla(${hue}, 85%, 95%, 0.8)`,
                color: `hsl(${hue}, 85%, 35%)`
            };
        });

        return categoryColors;
    }

    // Metodi per i filtri
    onDateFilterChange(): void {
        if (this.dateFilter.value === 'custom') {
            // Non fare nulla, l'utente selezionerà le date
        } else {
            // Applica subito i filtri per i preset
            this.applyFilters();
        }
    }

    applyDateFilter(): void {
        this.applyFilters();
    }

    resetFilters(): void {
        this.dateFilter.setValue('today');
        this.selectedCategory.setValue('');
        this.useTimeFilter.setValue(false);
        this.startTimeHour.setValue('00');
        this.startTimeMinute.setValue('00');
        this.endTimeHour.setValue('23');
        this.endTimeMinute.setValue('59');
        this.searchInput.setValue('');
        this.applyFilters();
    }

    // UI Methods
    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.searchInput.setValue(filterValue);
    }

    applySearchFilter(value: string): void {
        this.dataSource.filter = value.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

        // Ricalcola i totali in base ai dati filtrati
        this.calculateTotals(this.dataSource.filteredData);
    }

    onCategoryChange(): void {
        // Ripristina i dati originali filtrati solo per data/ora
        this.dataSource.data = [...this.originalFilteredData];

        const category = this.selectedCategory.value;

        if (!category) {
            // Se non c'è categoria selezionata, applica solo il filtro di testo se presente
            if (this.searchInput.value) {
                this.applySearchFilter(this.searchInput.value);
            }
            return;
        }

        // Filtra per categoria
        this.dataSource.data = this.dataSource.data.filter(item => item.category === category);

        // Applica il filtro di testo se presente
        if (this.searchInput.value) {
            this.applySearchFilter(this.searchInput.value);
        }

        // Ricalcola i totali
        this.calculateTotals(this.dataSource.filteredData);
    }

    getCategoryStyle(category: string): object {
        return this.categoryColors[category] || {
            background: 'rgba(0, 0, 0, 0.1)',
            color: '#666'
        };
    }

    // Metodo per visualizzare il titolo con l'eventuale variante
    getItemTitle(product: ProductStats): string {
        if (product.variant && product.variant.trim() !== '') {
            return `${product.title} <span class="variant-tag">${product.variant}</span>`;
        }
        return product.title;
    }

    formatCurrency(value: number): string {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR'
        }).format(value);
    }

    formatDate(date: Date): string {
        return moment(date).format('DD/MM/YYYY');
    }

    exportToCSV(): void {
        // Get the current filtered data
        const filteredData = this.dataSource.filteredData;

        // Get date range description for filename
        let dateRangeText = 'tutti';
        if (this.dateFilter.value !== 'all') {
            const startDateStr = this.formatDate(this.startDate.value);
            const endDateStr = this.formatDate(this.endDate.value);
            dateRangeText = `${startDateStr}_${endDateStr}`;
        }

        // Prepare CSV content
        const headers = ['Prodotto', 'Variante', 'Categoria', 'Quantità Vendute', 'Fatturato'];
        const csvData = filteredData.map(product => [
            `"${product.title}"`,
            `"${product.variant || ''}"`,
            `"${product.category}"`,
            product.quantitySold,
            product.totalRevenue.toFixed(2)
        ]);

        // Combine headers and data
        const csvContent = [
            headers.join(','),
            ...csvData.map(row => row.join(','))
        ].join('\n');

        // Create and download the CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `statistiche_prodotti_${dateRangeText}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    retry(): void {
        this.loadProductStats();
    }

    // Metodi per la generazione di opzioni per i selettori di ore e minuti
    getHoursOptions(): string[] {
        const hours = [];
        for (let i = 0; i < 24; i++) {
            hours.push(i.toString().padStart(2, '0'));
        }
        return hours;
    }

    getMinutesOptions(): string[] {
        const minutes = [];
        for (let i = 0; i < 60; i++) {
            minutes.push(i.toString().padStart(2, '0'));
        }
        return minutes;
    }
}