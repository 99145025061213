import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { DataServiceGenerico } from '../../../shared/services/data-service/data.service';
import { AddRestaurantDialogComponent } from './dialog/add-restaurant-dialog';
import { AddGroupDialogComponent } from './dialog/add-group-dialog';

@Component({
  selector: 'impostazioni-ristoranti',
  templateUrl: './impostazioniRistoranti.html',
  styleUrls: ['./impostazioniRistoranti.css']
})
export class ImpostazioniRistoranti implements OnInit {
  displayedColumnsRistoranti: string[] = ['nome', 'piva', 'email', 'permessi'];
  displayedColumnsGruppi: string[] = ['nome', 'ristoranti'];

  ristoranti = [];
  gruppi = [];
  permessi = [
    'Menu',
    'Magazzino',
    'Report',
    'Preferiti',
    'Totem',
    'Gestione fidelity',
    'Fidelity',
    'Sconti'
  ];

  constructor(
      public dialog: MatDialog,
      private dataServiceGenerico: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.setupRealtimeListeners();
  }

  private async loadData() {
    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    try {
      const franchiseGroupDoc = await db.collection('gruppi')
          .where('admin', '==', currentUser.uid)
          .limit(1)
          .get();

      if (!franchiseGroupDoc.empty) {
        const groupData = franchiseGroupDoc.docs[0].data();

        // Get all restaurant IDs from franchise group
        const restaurantIds = new Set();
        if (Array.isArray(groupData.ristoranti)) {
          groupData.ristoranti.forEach(id => restaurantIds.add(id));
        }

        // Fetch restaurant details
        const restaurantPromises = Array.from(restaurantIds).map(async (id: string) => {
          const doc = await db.collection('ristoranti').doc(id).get();
          if (doc.exists) {
            return { id: doc.id, ...doc.data() };
          }
          return null;
        });

        this.ristoranti = (await Promise.all(restaurantPromises))
            .filter(restaurant => restaurant !== null);

        // Convert classification groups IDs to full restaurant objects
        this.gruppi = (groupData.gruppi || []).map(group => ({
          ...group,
          ristoranti: (group.ristoranti || [])
              .map(id => this.ristoranti.find(r => r.id === id))
              .filter(r => r !== undefined)
        }));

        this.dataServiceGenerico.setRistoranti(this.ristoranti);
        this.dataServiceGenerico.setGruppi(this.gruppi);
      }
    } catch (error) {
      console.error('Error loading data:', error);
    }
  }

  private setupRealtimeListeners() {
    const db = firebase.firestore();
    const currentUser = firebase.auth().currentUser;

    db.collection('gruppi')
        .where('admin', '==', currentUser.uid)
        .limit(1)
        .onSnapshot(async snapshot => {
          const changes = snapshot.docChanges();
          for (const change of changes) {
            if (change.type === 'modified') {
              const groupData = change.doc.data();

              // Get all restaurant IDs
              const restaurantIds = new Set();
              if (Array.isArray(groupData.ristoranti)) {
                groupData.ristoranti.forEach(id => restaurantIds.add(id));
              }

              // Fetch updated restaurant details
              const restaurantPromises = Array.from(restaurantIds).map(async (id: string) => {
                const doc = await db.collection('ristoranti').doc(id).get();
                if (doc.exists) {
                  return { id: doc.id, ...doc.data() };
                }
                return null;
              });

              this.ristoranti = (await Promise.all(restaurantPromises))
                  .filter(restaurant => restaurant !== null);

              // Update groups with full restaurant objects
              this.gruppi = (groupData.gruppi || []).map(group => ({
                ...group,
                ristoranti: (group.ristoranti || [])
                    .map(id => this.ristoranti.find(r => r.id === id))
                    .filter(r => r !== undefined)
              }));

              this.dataServiceGenerico.setRistoranti(this.ristoranti);
              this.dataServiceGenerico.setGruppi(this.gruppi);
            }
          }
        });
  }

  async openAddRestaurantDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AddRestaurantDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          const currentUser = firebase.auth().currentUser;

          const userQuery = await db
              .collection('users')
              .where('email', '==', result.email)
              .get();

          let adminUid;
          let secondaryApp;

          if (userQuery.empty) {
            secondaryApp = firebase.initializeApp({
              apiKey: "AIzaSyBYzhvaoK3MPFvrKSkEqTe8HTiLwcd-Pzo",
              authDomain: "deweats.firebaseapp.com",
              databaseURL: "https://deweats-default-rtdb.europe-west1.firebasedatabase.app",
              projectId: "deweats",
              storageBucket: "deweats.appspot.com",
              messagingSenderId: "157089671742",
              appId: "1:157089671742:web:899765e7a64cf907e03f6f",
              measurementId: "G-KCGE5JVRDK"
            }, 'Secondary');

            const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(
                result.email,
                result.password
            );

            adminUid = userCredential.user.uid;

            await db.collection('users').doc(adminUid).set({
              email: result.email,
              role: 'admin'
            });
          } else {
            adminUid = userQuery.docs[0].id;
          }

          const restaurantRef = await db.collection('ristoranti').add({
            nome: result.nome,
            piva: result.piva,
            email: result.email,
            admin: adminUid,
            permessi: [],
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdBy: currentUser.uid
          });

          const groupQuery = await db
              .collection('gruppi')
              .where('admin', '==', currentUser.uid)
              .limit(1)
              .get();

          if (!groupQuery.empty) {
            const groupDoc = groupQuery.docs[0];
            await groupDoc.ref.update({
              ristoranti: firebase.firestore.FieldValue.arrayUnion(restaurantRef.id)
            });
          }

          if (secondaryApp) {
            await secondaryApp.delete();
          }

        } catch (error) {
          console.error('Error creating restaurant:', error);
        }
      }
    });
  }

  async openAddGroupDialog(): Promise<void> {
    const dialogRef = this.dialog.open(AddGroupDialogComponent, {
      width: '500px',
      height: '80vh',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const db = firebase.firestore();
          const currentUser = firebase.auth().currentUser;

          const groupQuery = await db
              .collection('gruppi')
              .where('admin', '==', currentUser.uid)
              .limit(1)
              .get();

          if (!groupQuery.empty) {
            const groupDoc = groupQuery.docs[0];
            const currentGroups = groupDoc.data().gruppi || [];

            await groupDoc.ref.update({
              gruppi: [...currentGroups, {
                nomeGruppo: result.nome,
                ristoranti: []
              }]
            });
          }
        } catch (error) {
          console.error('Error creating group:', error);
        }
      }
    });
  }

  async updateGroupRestaurants(group: any): Promise<void> {
    try {
      const db = firebase.firestore();
      const currentUser = firebase.auth().currentUser;

      const groupQuery = await db
          .collection('gruppi')
          .where('admin', '==', currentUser.uid)
          .limit(1)
          .get();

      if (!groupQuery.empty) {
        const groupDoc = groupQuery.docs[0];
        const currentGroups = groupDoc.data().gruppi || [];

        const updatedGroups = currentGroups.map(g =>
            g.nomeGruppo === group.nomeGruppo
                ? {
                  ...g,
                  ristoranti: group.ristoranti.map(r => r.id)
                }
                : g
        );

        await groupDoc.ref.update({
          gruppi: updatedGroups,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    } catch (error) {
      console.error('Error updating group restaurants:', error);
    }
  }

  async updateRestaurantPermissions(restaurant: any): Promise<void> {
    try {
      const db = firebase.firestore();
      await db.collection('ristoranti').doc(restaurant.id).update({
        permessi: restaurant.permessi,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating permissions:', error);
    }
  }

  compareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) return false;
    return obj1.id === obj2.id;
  }
}