<!-- inventory-management.component.html -->
<div class="inventory-container">
    <!-- Migration alert for first-time setup -->
    <div class="migration-alert" *ngIf="isMigrationNeeded">
        <div class="alert-content">
            <h3>Inizializzazione inventario richiesta</h3>
            <p>L'inventario separato non è ancora stato configurato. È necessario migrare i dati dall'anagrafica prodotti.</p>
            <button class="migrate-button" (click)="migrateInventoryData()">
                <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                     style="width: 24px; height: 24px; margin-right: 8px;">
                Inizializza Inventario
            </button>
        </div>
    </div>

    <!-- Main inventory content when not in migration mode -->
    <ng-container *ngIf="!isMigrationNeeded">
        <!-- Header with tabs selector -->
        <div class="operation-selector">
            <div class="tab-selector">
                <div class="slider"
                     [class.active]="operationMode === 'aggiunta'"
                     (click)="onOperationModeChange('aggiunta')">
                    Carico
                </div>
                <div class="slider"
                     [class.active]="operationMode === 'scarico'"
                     (click)="onOperationModeChange('scarico')">
                    Scarico
                </div>
                <div class="slider"
                     [class.active]="operationMode === 'scarti'"
                     (click)="onOperationModeChange('scarti')">
                    Scarti
                </div>
                <div class="slider"
                     [class.active]="operationMode === 'trasferimento'"
                     (click)="onOperationModeChange('trasferimento')">
                    Trasferimento
                </div>
            </div>

            <!-- Action buttons section -->
            <div class="action-buttons">
                <categorieCard
                        class="col-12 col-md-12 menuItem"
                        style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                        [nome]="'Salva Modifiche'"
                        altezza="55"
                        [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e'"
                        [bg]="'#33bfa3'"
                        (click)="saveChanges()">
                </categorieCard>

                <categorieCard
                        class="col-12 col-md-12 menuItem"
                        style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-left: 10px;"
                        [nome]="'Sincronizza Anagrafica'"
                        altezza="55"
                        [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-synchronize-96.png?alt=media&token=15437aa5-28cd-4be1-8cf5-40fedc48553b'"
                        [bg]="'#4da6ff'"
                        (click)="syncWithRegistry()">
                </categorieCard>
            </div>
        </div>

        <!-- Filtri container con filtro fornitori e ricerca -->
        <div class="filters-container" *ngIf="!isMigrationNeeded">
            <div class="filter-row">
                <!-- Filtro per fornitore -->
                <div class="filter-group fornitore-filter">
                    <label>Filtra per Fornitore:</label>
                    <div class="select-container">
                        <select [(ngModel)]="selectedFornitoreId" (change)="onFornitoreFilterChange(selectedFornitoreId)"
                                class="fornitore-select">
                            <option value="">Tutti i fornitori</option>
                            <option *ngFor="let fornitore of fornitori" [value]="fornitore.id">
                                {{ fornitore.nome }}
                            </option>
                        </select>
                        <mat-icon class="select-icon">arrow_drop_down</mat-icon>
                    </div>
                </div>

                <!-- Il filtro di ricerca -->
                <div class="search-box">
                    <mat-icon class="search-icon">search</mat-icon>
                    <input
                            type="text"
                            placeholder="Cerca prodotto, categoria, fornitore..."
                            (keyup)="applyFilter($event)"
                            class="search-input"
                            autocomplete="off">
                    <button
                            *ngIf="searchTerm"
                            class="clear-button"
                            (click)="searchTerm = ''; dataSource.filter = performance.now().toString()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Loading indicator -->
        <div class="loading-overlay" *ngIf="isLoading">
            <div class="spinner"></div>
            <p>Caricamento in corso...</p>
        </div>

        <!-- Error message -->
        <div class="error-message" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>

        <!-- Main content container with table and history -->
        <div class="content-container" [class.with-history]="selectedItemForHistory">
            <!-- Inventory Table -->
            <div class="table-section" [class.with-history]="selectedItemForHistory">
                <div class="table-responsive">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                        <!-- Group Header Column -->
                        <ng-container matColumnDef="groupHeader">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let group">
                                <strong>{{group[groupByColumns[group.level-1]]}}</strong>
                            </mat-cell>
                        </ng-container>

                        <!-- Title Column - Modificata per visualizzare i fornitori -->
                        <ng-container matColumnDef="title">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>Prodotto</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <ng-container *ngIf="!isGroup(null, row) && !row.isEditRow">
                                    <div class="product-info">
                                        <div class="product-title">{{ row.productTitle }}</div>
                                        <div class="product-fornitori" *ngIf="row.fornitori && row.fornitori.length > 0">
                                            <span class="fornitore-badge" *ngFor="let fornitore of row.fornitori.slice(0, 2)">
                                                {{ fornitore.nome }}
                                            </span>
                                            <span class="more-fornitori" *ngIf="row.fornitori.length > 2">
                                                +{{ row.fornitori.length - 2 }}
                                            </span>
                                        </div>
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Livello1 Column -->
                        <ng-container matColumnDef="livello1">
                            <mat-header-cell *matHeaderCellDef>Livello 1</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <ng-container *ngIf="!isGroup(null, row) && row.lvl">
                                    <div class="quantity-with-sales" *ngIf="!row.isEditRow">
                                        <span class="current-quantity">{{ row.quantityTotals?.livello1 }}</span>
                                        <span class="sales-info" *ngIf="row.salesQuantity?.livello1 > 0">
                      (Vendite: {{ row.salesQuantity.livello1 }} {{ row.lvl[0].um }})
                    </span>
                                    </div>
                                    <div *ngIf="row.isEditRow && row.lvl[0]" class="quantity-input-container">
                                        <input type="number"
                                               [ngModel]="row.newQuantities && row.newQuantities[0]"
                                               (ngModelChange)="onQuantityChange(row, 0, $event)"
                                               class="quantity-input"
                                               [placeholder]="row.lvl[0].um">
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Livello2 Column -->
                        <ng-container matColumnDef="livello2">
                            <mat-header-cell *matHeaderCellDef>Livello 2</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 2">
                                    <div class="quantity-with-sales" *ngIf="!row.isEditRow">
                                        <span class="current-quantity">{{ row.quantityTotals?.livello2 }}</span>
                                        <span class="sales-info" *ngIf="row.salesQuantity?.livello2 > 0">
                      (Vendite: {{ row.salesQuantity.livello2 }} {{ row.lvl[1].um }})
                    </span>
                                    </div>
                                    <div *ngIf="row.isEditRow && row.lvl[1]" class="quantity-input-container">
                                        <input type="number"
                                               [ngModel]="row.newQuantities && row.newQuantities[1]"
                                               (ngModelChange)="onQuantityChange(row, 1, $event)"
                                               class="quantity-input"
                                               [placeholder]="row.lvl[1].um">
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Livello3 Column -->
                        <ng-container matColumnDef="livello3">
                            <mat-header-cell *matHeaderCellDef>Livello 3</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 3">
                                    <div class="quantity-with-sales" *ngIf="!row.isEditRow">
                                        <span class="current-quantity">{{ row.quantityTotals?.livello3 }}</span>
                                        <span class="sales-info" *ngIf="row.salesQuantity?.livello3 > 0">
                      (Vendite: {{ row.salesQuantity.livello3 }} {{ row.lvl[2].um }})
                    </span>
                                    </div>
                                    <div *ngIf="row.isEditRow && row.lvl[2]" class="quantity-input-container">
                                        <input type="number"
                                               [ngModel]="row.newQuantities && row.newQuantities[2]"
                                               (ngModelChange)="onQuantityChange(row, 2, $event)"
                                               class="quantity-input"
                                               [placeholder]="row.lvl[2].um">
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Livello4 Column -->
                        <ng-container matColumnDef="livello4">
                            <mat-header-cell *matHeaderCellDef>Livello 4</mat-header-cell>
                            <mat-cell *matCellDef="let row">
                                <ng-container *ngIf="!isGroup(null, row) && row.lvl && row.maxLvl >= 4">
                                    <div class="quantity-with-sales" *ngIf="!row.isEditRow">
                                        <span class="current-quantity">{{ row.quantityTotals?.livello4 }}</span>
                                        <span class="sales-info" *ngIf="row.salesQuantity?.livello4 > 0">
                      (Vendite: {{ row.salesQuantity.livello4 }} {{ row.lvl[3].um }})
                    </span>
                                    </div>
                                    <div *ngIf="row.isEditRow && row.lvl[3]" class="quantity-input-container">
                                        <input type="number"
                                               [ngModel]="row.newQuantities && row.newQuantities[3]"
                                               (ngModelChange)="onQuantityChange(row, 3, $event)"
                                               class="quantity-input"
                                               [placeholder]="row.lvl[3].um">
                                    </div>
                                </ng-container>
                            </mat-cell>
                        </ng-container>

                        <!-- Row Definitions -->
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                                 [class.edit-row]="row.isEditRow"
                                 [class.view-row]="!row.isEditRow && !isGroup(null, row)"
                                 (click)="!row.isEditRow && !isGroup(null, row) && showHistory(row)">
                        </mat-row>
                        <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup"
                                 (click)="groupHeaderClick(row)"
                                 class="group-row">
                        </mat-row>
                    </mat-table>

                    <!-- Pagination -->
                    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>

            <!-- History Panel -->
            <div class="history-section" *ngIf="selectedItemForHistory">
                <app-cronologia-menu [item]="selectedItemForHistory"></app-cronologia-menu>
            </div>
        </div>
    </ng-container>
</div>