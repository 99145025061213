<div class="cronologia-container">
    <div class="cronologia-header">
        <h3>Cronologia movimenti</h3>
        <div class="product-info">
            <div class="product-title">{{ item?.productTitle }}</div>
            <div class="product-code">N° {{ item?.productId }}</div>
        </div>
    </div>

    <div style="height: 90%; overflow-y: auto;">
        <div class="timeline">
            <div class="timeline-item" *ngFor="let movimento of item?.movimenti">
                <div class="timeline-dot"
                     [style.background-color]="getTagColor(movimento.modalita)"
                     [style.border-color]="getTagColor(movimento.modalita)">
                </div>
                <div class="timeline-content">
                    <div class="timeline-header">
                        <div class="tag"
                             [style.background-color]="getTagColor(movimento.modalita)"
                             [style.color]="getTagTextColor(movimento.modalita)"
                             [class.vendita-tag]="movimento.modalita === 'vendita'">
                            {{ movimento.modalita | titlecase }}
                        </div>
                        <div class="date">{{ movimento.data }}</div>
                    </div>
                    <div class="movement-details">
                        <div class="quantity">
                            <ng-container *ngIf="movimento.modalita === 'vendita'">
                                <div *ngFor="let level of movimento.lvl; let i = index" class="level-quantity">
                                    <span *ngIf="level.quantita !== '0'">
                                        {{ level.quantita }} {{ level.um }}
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="movimento.modalita !== 'vendita'">
                                <div *ngFor="let level of movimento.lvl; let i = index" class="level-quantity">
                                    <span *ngIf="level.quantita !== '0'">
                                        {{ level.quantita }} {{ level.um }}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="prev-quantity">
                            Quantità precedente: {{ formatPreviousQuantity(movimento.quantitaPrec) }}
                        </div>
                        <div class="note" *ngIf="movimento.note">
                            <strong>Nota:</strong> {{ movimento.note }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>