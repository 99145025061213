<!-- slider-images.html -->
<div class="row">
    <div class="content-wrapper">
        <div class="header-container">
            <div class="title">Immagini Totem</div>
            <button
                    type="button"
                    class="add-image-button"
                    (click)="addImage()">
                Aggiungi Immagine
            </button>
        </div>
        <div class="card-container">
            <form [formGroup]="sliderForm" (ngSubmit)="saveImages()">
                <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
                    <!-- Images Section -->
                    <div formArrayName="images">
                        <div *ngFor="let image of images.controls; let i=index" [formGroupName]="i" class="image-section">
                            <div class="image-header">
                                <label class="font-bold text-gray-700 text-heading-sm">Immagine {{i + 1}}</label>
                                <button type="button" class="remove-image-button" (click)="removeImage(i)">×</button>
                            </div>
                            <div class="image-inputs">
                                <div class="input-container">
                                    <input
                                            type="text"
                                            formControlName="name"
                                            placeholder="Nome immagine"
                                            [class.error]="image.get('name')?.invalid && image.get('name')?.touched">
                                    <div class="error-message" *ngIf="image.get('name')?.invalid && image.get('name')?.touched">
                                        {{ getErrorMessage('name', i) }}
                                    </div>
                                </div>

                                <div class="file-upload-container">
                                    <input
                                            type="file"
                                            accept="image/*"
                                            (change)="onFileSelected($event, i)"
                                            class="file-input"
                                            #fileInput>

                                    <div class="preview-container" *ngIf="image.get('url')?.value || image.get('file')?.value">
                                        <img
                                                [src]="(image.get('file')?.value ? (image.get('file')?.value | objectURL) : (image.get('url')?.value | objectURL)) | async"
                                                class="image-preview"
                                                alt="Preview"
                                        >
                                    </div>

                                    <div *ngIf="uploadProgress[i]" class="progress-bar">
                                        <div class="progress" [style.width.%]="uploadProgress[i]"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="save-button-container">
                    <button
                            type="submit"
                            [disabled]="!isFormValid() || isLoading">
                        {{ isLoading ? 'Salvataggio...' : 'Salva Modifiche' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>