import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '../../shared/services/firestore/common.service';
import { DataServiceGenerico } from '../../shared/services/data-service/data.service';
import { ConfirmDialogComponent } from "../ConfirmDialogData/confirmDialogData.component";
import firebase from 'firebase/app';
import 'firebase/firestore';
import {IpcService} from "../../shared/services/ipc.service";
import {FormGroup} from "@angular/forms";
import {NotificationService} from "../settings/stampanti/notificationService/notification.service";
import {HttpClient} from "@angular/common/http";

interface OrderItem {
  id: string;
  name: string;
  quantity: number;
  section: string;
  variants?: Array<{
    name: string;
    value: string;
  }>;
  completed?: boolean;
}

interface Order {
  id: string;
  ordine: string;
  data: string;
  type: 'counter' | 'table' | 'takeaway';
  selectedPriceListType: string;
  tavolo?: string;
  items: OrderItem[];
  nota?: string;
  statoPagato: number;
  bloccato?: boolean;
  timestampEvasione?: string;
  fonte: number;
  carrello: {
    [key: string]: CartProduct;  // Aggiorniamo il tipo del carrello
  };
  ordineInserimento: string[];
  consegnato?: boolean;
  pager?: number;
  statoEvasione?: 'completo' | 'parziale' | 'non_evaso';
}

interface TotalDishItem {
  title: string;
  quantity: number;
  category?: string;
  selectedPrintDestination?: string;
  variants?: Array<{
    name: string;
    variantTitle: string;
  }>;
}

interface CartProduct {
  title: string;
  quantita: number;
  variants?: Array<{
    name: string;
    variantTitle: string;
  }>;
  selectedPrintDestination?: string;
  category?: string;
  pronto: boolean;  // Aggiungiamo lo stato di preparazione come required
}

@Component({
  selector: 'app-schermo-cucina',
  templateUrl: './schermo-cucina.component.html',
  styleUrls: ['./schermo-cucina.component.css']
})
export class SchermoCucina implements OnInit, OnDestroy {
  currentTime: Date = new Date();
  showHistory: boolean = false;
  showTotalDishes: boolean = false;
  selectedSection: string = 'all';
  ordiniCucina: Order[] = [];
  ordiniEvasi: Order[] = [];
  ordiniOriginali: Order[] = [];
  tempoMedioEvasione: string = '0:00';
  showFilters: boolean = false;
  currentPage: number = 0;
  ordersPerPage: number = 8;

  sections = [
    { id: 'all', name: 'Tutti' },
    { id: 'fritti', name: 'Fritti' },
    { id: 'pita', name: 'Pita' },
    { id: 'pizza', name: 'Pizza' },
    { id: 'grill', name: 'Grill' }
  ];

  printDestinations: string[] = [];
  uniqueCategories: string[] = [];
  selectedDestinazioni: string[] = [];
  selectedCategories: string[] = [];
  totalDishes: TotalDishItem[] = [];

  private subscriptions: Subscription[] = [];
  private restaurantId: string = '';

  constructor(
      private commonService: CommonService,
      private dataServiceGenerico: DataServiceGenerico,
      private dialog: MatDialog,
      private ipcService: IpcService,
      private notificationService: NotificationService,
      private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
        interval(1000).subscribe(() => {
          this.currentTime = new Date();
        })
    );

    this.initializeKitchenMonitor();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private async initializeKitchenMonitor(): Promise<void> {
    try {
      await this.dataServiceGenerico.loadSelectedMenuFromFirebase();

      setTimeout(async () => {
        this.restaurantId = await this.dataServiceGenerico.getCurrentRestaurantId();

        if (!this.restaurantId) {
          throw new Error('Restaurant ID not available');
        }

        await this.loadPrintDestinationsAndCategories();
        this.setupOrdersListener();
      }, 1000);
    } catch (error) {
      console.error('Error initializing kitchen monitor:', error);
    }
  }

  private async loadPrintDestinationsAndCategories(): Promise<void> {
    try {
      const menuDoc = await firebase.firestore()
          .collection('menu_test')
          .doc(this.restaurantId)
          .get();

      if (menuDoc.exists) {
        const items = menuDoc.data()?.items || [];

        const destinationsSet = new Set<string>();
        const categoriesSet = new Set<string>();

        items.forEach(item => {
          if (item.selectedPrintDestination) {
            destinationsSet.add(item.selectedPrintDestination);
          }
          if (item.category?.trim()) {
            categoriesSet.add(item.category);
          }
        });

        this.printDestinations = Array.from(destinationsSet);
        this.uniqueCategories = Array.from(categoriesSet);
      }
    } catch (error) {
      console.error('Error loading menu data:', error);
      this.printDestinations = ['cucina', 'bar', 'pizzeria'];
      this.uniqueCategories = ['Primi', 'Secondi', 'Contorni'];
    }
  }

  private setupOrdersListener(): void {
    const today = new Date().setHours(0, 0, 0, 0);

    if (this.subscriptions.length > 1) {
      this.subscriptions[1].unsubscribe();
      this.subscriptions.pop();
    }

    this.subscriptions.push(
        this.commonService.getOrdiniByDate(this.restaurantId, today).subscribe(ordini => {
          if (ordini) {
            ordini.forEach(ordine => {
              if (ordine.carrello) {
                Object.values(ordine.carrello).forEach((prodotto: CartProduct) => {
                  if (typeof prodotto.pronto === 'undefined') {
                    prodotto.pronto = false;
                  }
                });
              }
            });

            const filteredOrders = ordini
                .filter(ordine => ordine.statoPagato !== 0) // Filtra gli ordini non pagati
                .sort((a, b) => parseInt(a.data) - parseInt(b.data));

            this.ordiniOriginali = [...filteredOrders];
            this.ordiniCucina = filteredOrders.filter(ordine => ordine.consegnato !== true);
            this.ordiniEvasi = filteredOrders.filter(ordine => ordine.consegnato === true);

            this.calcolaTempoMedioEvasione();
            this.applyFilters();
            this.calculateTotalDishes();
          }
        })
    );
  }

  calculateTotalDishes(): void {
    const dishesMap = new Map<string, TotalDishItem>();

    this.ordiniCucina
        .filter(ordine => ordine.consegnato !== true)
        .forEach(ordine => {
          ordine.ordineInserimento?.forEach(prodottoId => {
            const product = ordine.carrello[prodottoId];
            const key = this.getDishKey(product);

            if (dishesMap.has(key)) {
              const existingDish = dishesMap.get(key)!;
              existingDish.quantity += product.quantita;
            } else {
              dishesMap.set(key, {
                title: product.title,
                quantity: product.quantita,
                category: product.category,
                selectedPrintDestination: product.selectedPrintDestination,
                variants: product.variants
              });
            }
          });
        });

    this.totalDishes = Array.from(dishesMap.values())
        .sort((a, b) => b.quantity - a.quantity);
  }

  private getDishKey(product: CartProduct): string {
    let key = product.title;
    if (product.variants && product.variants.length > 0) {
      key += '-' + product.variants.map(v => v.name).join('-');
    }
    return key;
  }

  toggleTotalDishes(): void {
    this.showTotalDishes = !this.showTotalDishes;
    if (this.showTotalDishes) {
      this.calculateTotalDishes();
    }
  }

  getFilteredTotalDishes(): TotalDishItem[] {
    let filtered = [...this.totalDishes];

    if (this.selectedDestinazioni.length > 0) {
      filtered = filtered.filter(dish =>
          this.selectedDestinazioni.includes(dish.selectedPrintDestination)
      );
    }

    if (this.selectedCategories.length > 0) {
      filtered = filtered.filter(dish =>
          this.selectedCategories.includes(dish.category)
      );
    }

    return filtered;
  }

  getColonneOrdinate(): Order[][] {
    const ordersToShow = this.paginatedOrders;
    const ordersPerRow = 3; // Numero di ordini per riga
    const rows = Math.ceil(ordersToShow.length / ordersPerRow);
    const columns = ordersPerRow;
    const grid: Order[][] = Array(columns).fill(null).map(() => []);

    // Riempi prima per righe, poi per colonne
    for (let i = 0; i < ordersToShow.length; i++) {
      const row = Math.floor(i / ordersPerRow);
      const col = i % ordersPerRow;
      grid[col].push(ordersToShow[i]);
    }

    return grid;
  }

  get paginatedOrders(): Order[] {
    const startIndex = this.currentPage * this.ordersPerPage;
    return this.filteredOrders.slice(startIndex, startIndex + this.ordersPerPage);
  }

  get totalPages(): number {
    return Math.ceil(this.filteredOrders.length / this.ordersPerPage);
  }

  get filteredOrders(): Order[] {
    return this.ordiniCucina.filter(order => order.statoPagato !== 0);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 0) {
      this.currentPage--;
    }
  }


  restoreOrder(ordine: Order): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Ripristinare questo ordine?',
        confirmText: 'Ripristina',
        cancelText: 'Annulla',
        isRestore: true
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          const updatedOrder = {
            ...ordine,
            consegnato: false,
            timestampEvasione: null,
            statoEvasione: 'non_evaso'
          };
          await this.commonService.updateOrdine(ordine.id, updatedOrder);
        } catch (error) {
          console.error('Error restoring order:', error);
        }
      }
    });
  }

  async completeOrder(ordine: Order): Promise<void> {
    const tuttiPronti = Object.values(ordine.carrello)
        .every(prodotto => prodotto.pronto);

    let dialogData: any = {
      title: 'Evadere questo ordine?',
      confirmText: 'Evadi',
      cancelText: 'Annulla',
      isRestore: false
    };

    if (ordine.pager && ordine.pager !== 0) {
      dialogData.pager = ordine.pager;
    }

    if (!tuttiPronti) {
      dialogData.warning = 'Attenzione: non tutti i piatti sono pronti! Vuoi procedere comunque?';
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        try {
          const updatedOrder = {
            ...ordine,
            consegnato: true,
            timestampEvasione: new Date().getTime().toString(),
            statoEvasione: tuttiPronti ? 'completo' : 'parziale'
          };

          await this.commonService.updateOrdine(ordine.id, updatedOrder);

          if (ordine.pager && ordine.pager !== 0) {
            await this.ipcService.callPager(ordine.pager);
          }

          if (ordine['userId']) {
            await this.inviaNotifica(ordine);
          }


        } catch (error) {
          console.error('Error completing order:', error);
        }
      }
    });
  }

  notificheForm: FormGroup;
  isLoading = false;

  // URL della Cloud Function
  private backendUrl = 'https://us-central1-deweats.cloudfunctions.net/api/sendNotification';


  async inviaNotifica(ordine: any) {

    this.isLoading = true;
    try {
      const db = firebase.firestore();
      const userDoc = await db.collection('users').doc(ordine.userId).get();
      const userData = userDoc.data();

      if (!userData?.fcmToken) {
        throw new Error('Token FCM non trovato per questo utente');
      }

      const notificationData = {
        data: {
          token: userData.fcmToken,
          title: "Ordine pronto!",
          body: "Il tuo ordine #" + ordine.ordine + " da Milos è pronto"
        }
      };

      // Utilizzo di catchError per gestire errori HTTP specifici
      await this.http.post(this.backendUrl, notificationData, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).toPromise();

      this.notificationService.show('Notifica inviata con successo', 'success');
    } catch (error) {
      console.error('Errore invio notifica:', error);
      let errorMessage = 'Errore durante l\'invio della notifica';

      // Gestione più specifica degli errori
      if (error.status === 403) {
        errorMessage = 'Accesso non autorizzato';
      } else if (error.status === 404) {
        errorMessage = 'Servizio non trovato';
      }

      this.notificationService.show(errorMessage, 'error');
    } finally {
      this.isLoading = false;
    }
  }


  togglePiattoPreparazione(ordine: Order, prodottoId: string, event: Event): void {
    event.stopPropagation();  // Previene il trigger del click sull'ordine
    if (ordine.carrello[prodottoId]) {
      ordine.carrello[prodottoId].pronto = !ordine.carrello[prodottoId].pronto;
      this.commonService.updateOrdine(ordine.id, ordine);
    }
  }

  isPiattoReady(ordine: Order, prodottoId: string): boolean {
    return ordine.carrello[prodottoId]?.pronto || false;
  }

  getOrderStatus(ordine: Order): string {
    if (ordine.statoEvasione === 'parziale') {
      return 'partially-completed';
    }
    return ordine.statoPagato === 3 ? 'completed' : '';
  }

  getOrderTypeLabel(type: string): string {
    switch (type) {
      case 'counter': return 'Banco';
      case 'table': return 'Tavolo';
      case 'takeaway': return 'Asporto';
      default: return 'N/D';
    }
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  toggleHistory(): void {
    this.showHistory = !this.showHistory;
  }

  toggleDestinazione(destinazione: string): void {
    const index = this.selectedDestinazioni.indexOf(destinazione);
    if (index === -1) {
      this.selectedDestinazioni.push(destinazione);
    } else {
      this.selectedDestinazioni.splice(index, 1);
    }
    this.applyFilters();
  }

  toggleCategory(category: string): void {
    const index = this.selectedCategories.indexOf(category);
    if (index === -1) {
      this.selectedCategories.push(category);
    } else {
      this.selectedCategories.splice(index, 1);
    }
    this.applyFilters();
  }

  hasActiveFilters(): boolean {
    return this.selectedDestinazioni.length > 0 || this.selectedCategories.length > 0;
  }

  clearFilters(): void {
    this.selectedDestinazioni = [];
    this.selectedCategories = [];
    this.ordiniCucina = this.ordiniOriginali.filter(ordine => ordine.consegnato !== true);
    this.calculateTotalDishes();
  }

  applyFilters(): void {
    let filteredOrders = [...this.ordiniOriginali].filter(ordine => ordine.consegnato !== true);

    if (this.selectedDestinazioni.length > 0) {
      filteredOrders = filteredOrders.filter(ordine =>
          ordine.ordineInserimento.some(prodottoId =>
              this.selectedDestinazioni.includes(ordine.carrello[prodottoId].selectedPrintDestination)
          )
      );
    }

    if (this.selectedCategories.length > 0) {
      filteredOrders = filteredOrders.filter(ordine =>
          ordine.ordineInserimento.some(prodottoId =>
              this.selectedCategories.includes(ordine.carrello[prodottoId].category)
          )
      );
    }

    this.ordiniCucina = filteredOrders;
    this.calculateTotalDishes();
  }

  getOrderTime(timestamp: string): string {
    if (!timestamp) return '0:00';

    const orderTime = new Date(parseInt(timestamp));
    const now = new Date();
    const diffSeconds = Math.floor((now.getTime() - orderTime.getTime()) / 1000);

    const minutes = Math.floor(diffSeconds / 60);
    const seconds = diffSeconds % 60;

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }

  calcolaTempoMedioEvasione(): void {
    if (this.ordiniEvasi.length === 0) {
      this.tempoMedioEvasione = '0:00';
      return;
    }

    let totalSeconds = 0;
    let ordiniValidi = 0;

    this.ordiniEvasi.forEach(ordine => {
      if (ordine.data && ordine.timestampEvasione) {
        const timestampCreazione = parseInt(ordine.data);
        const timestampEvasione = parseInt(ordine.timestampEvasione);
        const differenzaSecondi = Math.floor((timestampEvasione - timestampCreazione) / 1000);

        if (differenzaSecondi > 0) {
          totalSeconds += differenzaSecondi;
          ordiniValidi++;
        }
      }
    });

    if (ordiniValidi > 0) {
      const mediaSecondi = Math.floor(totalSeconds / ordiniValidi);
      const minutes = Math.floor(mediaSecondi / 60);
      const seconds = mediaSecondi % 60;
      this.tempoMedioEvasione = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  }

  getCompletionPercentage(): number {
    const total = this.ordiniCucina.length + this.ordiniEvasi.length;
    if (total === 0) return 0;
    return Math.round((this.ordiniEvasi.length / total) * 100);
  }

  getElapsedTime(): string {
    return this.tempoMedioEvasione;
  }

  // Utility methods for total dishes view
  getDishCategory(dish: TotalDishItem): string {
    return dish.category || 'Non categorizzato';
  }

  getDishDestination(dish: TotalDishItem): string {
    return dish.selectedPrintDestination || 'Non specificato';
  }

  hasVariants(dish: TotalDishItem): boolean {
    return dish.variants && dish.variants.length > 0;
  }

  getVariantsText(dish: TotalDishItem): string {
    if (!dish.variants || dish.variants.length === 0) return '';
    return dish.variants.map(v => v.name).join(', ');
  }
}