<div class="dialog-container">
  <div class="sticky-container">
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-90.png?alt=media&token=8b94ad35-a258-48a3-99cc-c19fa32df646"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Elimina Piatto"
           (click)="deleteItemFromFirestore()">
    </div>
    <div class="save-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Salva Piatto"
           >
    </div>
    <div class="close-button">
      <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
           style="width: 30px; aspect-ratio: 1 / 1;"
           title="Chiudi"
           (click)="closeDialog()">
    </div>
  </div>
  <div style="height: 80vh; aspect-ratio: 1 / 1;">
    <div class="example-container" style="max-width: 100%;">

      <fieldset class="custom-fieldset">
        <legend>Titolo Sconto</legend>
        <div class="chart-container">
          <input placeholder="Titolo" class="custom-input" [(ngModel)]="title">
        </div>
      </fieldset>


        <fieldset class="custom-fieldset">
            <legend>Punti</legend>
            <div class="chart-container">
                <input placeholder="Punti" class="custom-input" [(ngModel)]="punti">
            </div>
        </fieldset>

        <fieldset class="custom-fieldset">
            <legend>Tipologia</legend>
            <div class="actions-container">
                <div class="action-item">
                    <span class="action-label">Sconto?</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-menu'"
                                [(ngModel)]="isMenuChecked"
                                (change)="onMenuChange($event)"
                        >
                        <label [for]="'checkbox-menu'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>

            <div class="actions-container">
                <div class="action-item">
                    <span class="action-label">Riutilizzabile?</span>
                    <div class="checkbox-wrapper-26" style="width: 30px;">
                        <input
                                type="checkbox"
                                [id]="'checkbox-single'"
                                [(ngModel)]="isSingleChecked"
                                (change)="isSingleChecked = !isSingleChecked"
                        >
                        <label [for]="'checkbox-single'">
                            <div class="tick_mark"></div>
                        </label>
                    </div>
                </div>
            </div>

        </fieldset>

        <fieldset class="custom-fieldset"  *ngIf="!isMenuChecked">
            <legend>Sconto</legend>
            <div class="chart-container">
                <input placeholder="Sconto" class="custom-input" [(ngModel)]="sconto">
            </div>
        </fieldset>


        <fieldset class="custom-fieldset"  *ngIf="isMenuChecked">
        <legend>Ingredienti</legend>
        <div class="chart-container">
          <mat-select placeholder="Seleziona ingredienti" multiple class="custom-input" [(ngModel)]="selectedIngredients">
            <mat-option *ngFor="let ingredient of menu" [value]="ingredient['title']">
              {{ingredient['title']}}
            </mat-option>
          </mat-select>
        </div>
      </fieldset>


    </div>
  </div>
</div>
