<!-- Versione Responsiva del componente -->
<div class="dashboard-container" [class.mobile]="isMobileDevice()">
    <!-- ======= INIZIO VERSIONE MOBILE ======= -->
    <!-- Header Mobile -->
    <div class="mobile-header">
        <h1 class="page-title">Scontrini</h1>

        <!-- Data Range Selector compatto -->
        <div class="date-selector">
            <button class="active-date-chip" (click)="toggleDateMenu()">
                <i class="material-icons">date_range</i>
                <span>{{getActiveDateLabel()}}</span>
                <i class="material-icons">expand_more</i>
            </button>

            <!-- Filtri data collassabili -->
            <div class="date-menu" *ngIf="showDateMenu">
                <div class="date-options">
                    <button *ngFor="let preset of datePresets"
                            class="date-chip"
                            [ngClass]="{'active': isActivePreset(preset.value)}"
                            (click)="setDatePreset(preset.value); showDateMenu = false">
                        {{preset.label}}
                    </button>
                    <button class="date-chip custom" (click)="openDateRangePicker(); showDateMenu = false">
                        <i class="material-icons">date_range</i>
                        Personalizzato
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Statistiche Cards scrollabili orizzontalmente -->



    <!-- Filtri a bottoni con icone -->
    <div class="mobile-filters">
        <!-- Tabs per filtri -->
        <div class="filter-tabs">
            <!--button class="filter-tab" [ngClass]="{'active': activeFilterTab === 'sources'}" (click)="setActiveFilterTab('sources')">
                <i class="material-icons">devices</i>
                <span>Fonti</span>
            </button-->
            <button class="filter-tab" [ngClass]="{'active': activeFilterTab === 'types'}" (click)="setActiveFilterTab('types')">
                <i class="material-icons">category</i>
                <span>Tipi</span>
            </button>
            <button class="filter-tab" [ngClass]="{'active': activeFilterTab === 'time'}" (click)="setActiveFilterTab('time')">
                <i class="material-icons">schedule</i>
                <span>Orario</span>
            </button>
            <button class="filter-tab" [ngClass]="{'active': activeFilterTab === 'search'}" (click)="setActiveFilterTab('search')">
                <i class="material-icons">search</i>
                <span>Cerca</span>
            </button>
        </div>

        <!-- Contenuto dei filtri (mostra solo il tab attivo) -->
        <div class="filter-content" *ngIf="activeFilterTab">
            <!-- Filtri Fonti Ordini -->
            <div class="filter-panel" *ngIf="activeFilterTab === 'sources'">
                <div class="type-filters">
                    <button *ngFor="let source of orderSourceFilters"
                            class="type-chip"
                            [ngClass]="{'active': filters.orderSources.includes(source.value)}"
                            (click)="toggleOrderSource(source.value)">
                        <i class="material-icons">{{source.icon}}</i>
                        {{source.label}}
                    </button>
                    <button class="type-chip"
                            [ngClass]="{'active': filters.showDeleted}"
                            data-type="deleted"
                            (click)="toggleDeletedOrders()">
                        <i class="material-icons">delete</i>
                        Eliminati
                    </button>
                </div>
            </div>

            <!-- Filtri Tipi Ordini -->
            <div class="filter-panel" *ngIf="activeFilterTab === 'types'">
                <div class="type-filters">
                    <button *ngFor="let type of orderTypeFilters"
                            class="type-chip"
                            [ngClass]="{'active': filters.orderTypes.includes(type.value)}"
                            (click)="toggleOrderType(type.value)">
                        <i class="material-icons">{{type.icon}}</i>
                        {{type.label}}
                    </button>
                </div>
                <button class="export-btn" (click)="exportToCSV()">
                    <i class="material-icons">download</i>
                    Esporta CSV
                </button>
            </div>

            <!-- Filtro Orario -->
            <div class="filter-panel" *ngIf="activeFilterTab === 'time'">
                <div class="time-filter-buttons">
                    <button class="time-chip" [ngClass]="{'active': timeRangeControl.value === 'all'}" (click)="timeRangeControl.setValue('all')">
                        Tutto il giorno
                    </button>
                    <button class="time-chip" [ngClass]="{'active': timeRangeControl.value === 'morning'}" (click)="timeRangeControl.setValue('morning')">
                        Mattino
                    </button>
                    <button class="time-chip" [ngClass]="{'active': timeRangeControl.value === 'lunch'}" (click)="timeRangeControl.setValue('lunch')">
                        Pranzo
                    </button>
                    <button class="time-chip" [ngClass]="{'active': timeRangeControl.value === 'afternoon'}" (click)="timeRangeControl.setValue('afternoon')">
                        Pomeriggio
                    </button>
                    <button class="time-chip" [ngClass]="{'active': timeRangeControl.value === 'dinner'}" (click)="timeRangeControl.setValue('dinner')">
                        Cena
                    </button>
                </div>
            </div>

            <!-- Filtro Ricerca -->
            <div class="filter-panel" *ngIf="activeFilterTab === 'search'">
                <div class="search-input">
                    <i class="material-icons">search</i>
                    <input type="text"
                           [(ngModel)]="filters.searchTerm"
                           (ngModelChange)="search($event)"
                           placeholder="Cerca prodotti, categorie...">
                    <button *ngIf="filters.searchTerm" (click)="clearSearch()" class="clear-search">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- ======= FINE VERSIONE MOBILE ======= -->

    <!-- Stats Grid (Versione Desktop) -->
    <div class="stats-grid">
        <div class="stats-section">
            <!-- Totale Ordini -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Scontrini Totali</h3>
                <p class="stat-value">{{stats.totalOrders}}</p>
                <div class="trend-indicator" [ngClass]="{'positive': stats.percentageChanges.orders >= 0, 'negative': stats.percentageChanges.orders < 0}">
                    <i class="material-icons">
                        {{stats.percentageChanges.orders >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.orders | number:'1.1-1'}}%</span>
                </div>
            </div>

            <!-- Fatturato -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Fatturato Totale</h3>
                <p class="stat-value">{{formatCurrency(stats.totalRevenue)}}</p>
                <div class="trend-indicator" [ngClass]="{'positive': stats.percentageChanges.revenue >= 0, 'negative': stats.percentageChanges.revenue < 0}">
                    <i class="material-icons">
                        {{stats.percentageChanges.revenue >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.revenue | number:'1.1-1'}}%</span>
                </div>
            </div>

            <!-- Scontrino Medio -->
            <div class="stat-card">
                <h3 class="stat-title">{{filters.showDeleted ? 'Eliminati: ' : ''}}Scontrino Medio</h3>
                <p class="stat-value">{{formatCurrency(stats.averageReceipt)}}</p>
                <div class="trend-indicator" [ngClass]="{'positive': stats.percentageChanges.average >= 0, 'negative': stats.percentageChanges.average < 0}">
                    <i class="material-icons">
                        {{stats.percentageChanges.average >= 0 ? 'trending_up' : 'trending_down'}}
                    </i>
                    <span>{{stats.percentageChanges.average | number:'1.1-1'}}%</span>
                </div>
            </div>
        </div>
    </div>

    <!-- Chart Section (Desktop) -->
    <div class="chart-section" *ngIf="!isMobileDevice()">
        <!-- Revenue Chart -->
        <div *ngIf="showRevenueChart" class="chart-card">
            <h3 class="section-title">Andamento Fatturato</h3>
            <div class="chart-container">
                <canvas #revenueChart></canvas>
            </div>
        </div>
    </div>

    <!-- Filters Section (Desktop) -->
    <div class="filters-section" *ngIf="!isMobileDevice()">
        <div class="filters-grid">
            <!-- Date Range Filters -->
            <div class="date-filters">
                <div class="date-range-picker">
                    <button *ngFor="let preset of datePresets"
                            class="date-chip"
                            [ngClass]="{'active': isActivePreset(preset.value)}"
                            (click)="setDatePreset(preset.value)">
                        {{preset.label}}
                    </button>
                    <button class="date-chip custom" (click)="openDateRangePicker()">
                        <i class="material-icons">date_range</i>
                        Periodo Personalizzato
                    </button>
                    <button class="date-chip export" (click)="exportToCSV()">
                        <i class="material-icons">download</i>
                        Esporta CSV
                    </button>
                </div>

                <mat-form-field *ngIf="showCustomDateRange" class="custom-date-range">
                    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Data inizio">
                        <input matEndDate formControlName="end" placeholder="Data fine">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>

            <!-- Order Type and Source Filters -->
            <div class="type-filters">
                <button *ngFor="let type of orderTypeFilters"
                        class="type-chip"
                        [ngClass]="{'active': filters.orderTypes.includes(type.value)}"
                        (click)="toggleOrderType(type.value)">
                    <i class="material-icons">{{type.icon}}</i>
                    {{type.label}}
                </button>

                <button *ngFor="let source of orderSourceFilters"
                        class="type-chip"
                        [ngClass]="{'active': filters.orderSources.includes(source.value)}"
                        (click)="toggleOrderSource(source.value)">
                    <i class="material-icons">{{source.icon}}</i>
                    {{source.label}}
                </button>

                <button class="type-chip"
                        [ngClass]="{'active': filters.showDeleted}"
                        data-type="deleted"
                        (click)="toggleDeletedOrders()">
                    <i class="material-icons">delete</i>
                    Ordini Eliminati
                </button>
            </div>

            <!-- Search and Additional Filters -->
            <div class="search-filters">
                <div class="search-input">
                    <i class="material-icons">search</i>
                    <input type="text"
                           [(ngModel)]="filters.searchTerm"
                           (ngModelChange)="search($event)"
                           placeholder="Cerca prodotti, categorie...">
                </div>

                <div class="time-range">
                    <mat-form-field>
                        <mat-label>Orario</mat-label>
                        <mat-select [formControl]="timeRangeControl">
                            <mat-option value="all">Tutto il giorno</mat-option>
                            <mat-option value="morning">Mattino (6:00-12:00)</mat-option>
                            <mat-option value="lunch">Pranzo (12:00-15:00)</mat-option>
                            <mat-option value="afternoon">Pomeriggio (15:00-19:00)</mat-option>
                            <mat-option value="dinner">Cena (19:00-23:00)</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <!-- Receipts Container (Comune a entrambe le versioni) -->
    <div class="receipts-container">
        <!-- Loading Spinner -->
        <div class="loading-overlay" *ngIf="loading">
            <mat-spinner diameter="50"></mat-spinner>
        </div>

        <!-- Error State -->
        <div class="error-state" *ngIf="error">
            <mat-icon color="warn">error_outline</mat-icon>
            <h3>Errore di caricamento</h3>
            <p>Non è stato possibile caricare gli scontrini.</p>
            <button mat-raised-button color="primary" (click)="loadOrders()">Riprova</button>
        </div>

        <!-- Receipts List -->
        <div class="receipts-list" *ngIf="!loading && !error">
            <div class="receipt-card" *ngFor="let order of filteredOrders">
                <div class="receipt-header">
                    <div class="receipt-info">
                        <h3>{{order.id}} - {{order.displayName}}</h3>
                        <span class="timestamp">{{formatDate(order.data)}}</span>
                    </div>
                    <div class="receipt-status" [ngClass]="{'paid': order.statoPagato === 0}">
                        <span>{{formatCurrency(getOrderTotal(order))}}</span>
                    </div>
                </div>

                <!-- Product Summary (visibile solo in mobile per toggle) -->
                <div class="product-summary" (click)="toggleProductList(order)" *ngIf="isMobileDevice()">
                    <span>{{getTotalProducts(order)}} prodotti</span>
                    <i class="material-icons">{{order.showProducts ? 'expand_less' : 'expand_more'}}</i>
                </div>

                <!-- Product List (in mobile si mostra solo quando si fa toggle) -->
                <div class="receipt-content">
                    <div class="product-list" *ngIf="!isMobileDevice() || order.showProducts">
                        <div class="product-item" *ngFor="let product of getOrderProducts(order)">
                            <span class="quantity">{{product.quantita}}x</span>
                            <span class="name">{{product.title}}</span>
                            <span class="price">{{formatCurrency(product.prezzo * product.quantita)}} - {{product.vatId === 2 ? '10%' : '22%'}}</span>
                        </div>
                    </div>
                </div>

                <div class="deletion-info" *ngIf="filters.showDeleted"
                     style="margin-top: 16px; padding: 12px; background-color: #fee2e2; border-radius: 8px;">
                    <strong style="color: #ef4444;">Motivo eliminazione:</strong>
                    <p style="margin: 8px 0 0; color: #374151;">{{order.motivazione}}</p>
                </div>

                <div class="receipt-footer">
                    <div class="payment-info">
                        <div class="payment-method" *ngIf="order.paymentMethod">
                            <i class="material-icons">{{getPaymentMethodIcon(order.paymentMethod)}}</i>
                            <span>{{getPaymentMethodLabel(order.paymentMethod)}}</span>
                        </div>
                        <div class="order-source" *ngIf="order.orderSource">
                            <i class="material-icons">{{getSourceIcon(order.orderSource)}}</i>
                            <span *ngIf="!isMobileDevice()">{{getSourceLabel(order.orderSource)}}</span>
                        </div>
                        <div class="order-stats" *ngIf="!isMobileDevice()">
                            <span>{{getTotalProducts(order)}} prodotti</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Empty State -->
            <div class="empty-state" *ngIf="filteredOrders.length === 0">
                <mat-icon>receipt_long</mat-icon>
                <h3>Nessuno scontrino trovato</h3>
                <p>Prova a modificare i filtri di ricerca</p>
            </div>
        </div>
    </div>
</div>