<div class="row" style="overflow-y: hidden !important; height: 100vh !important;">



  <div class="column" *ngIf="schermata > 0">


    <div style="width: 100%; display: flex; margin-top: 20px">
      <div style="width: 9%; margin-right: 1%">
        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;"

                       [link]="'assets/icons/icons8-back-arrow-96.png'"
                       [border]="'#e55933'"
                       (click)="save(); schermata = 0"
                        >
        </categorieCard>
      </div>
      <div style="width: 90%">
          <receiptOrderCard1 style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             [titolo]="getTitoloOrdine()"
                             [stato]="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? 'Pagato': 'Non Pagato': 'Non Pagato'"
                             [fonte]="camerieri[getIndex()]?.fonte">
          </receiptOrderCard1>
      </div>
       </div>


    <div class="container menu"  >
      <p style="text-align: left; width:80%; display: inline-block; background-color: #FBFBFB; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 20px; margin-bottom: 0px; margin-left: 0px; font-weight: normal">Dettagli Ordinazione:</p>

        <div class="row1" style="float: left; width: calc(100% - 10px); height: 60px; margin-top: 10px; margin-bottom: 10px; border-radius: 20px; background-color: white; border: solid 1px #e3e2e2;  padding:  0px 5px">


            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20% " [style.background-color]="portata == 0? 'rgba(181,181,181,0.2)': 'transparent'"  (click)="portata = 0 ">
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 13px; font-weight: 500">1</div>
                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20%" [style.background-color]="portata == 1? 'rgba(181,181,181,0.2)': 'transparent'"   (click)="portata = 1" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 13px; font-weight: 500">2</div>
                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center;width: 20% " [style.background-color]="portata == 2? 'rgba(181,181,181,0.2)': 'transparent'"   (click)="portata = 2" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 13px; font-weight: 500">3</div>

                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center;width: 20% " [style.background-color]="portata == 3? 'rgba(181,181,181,0.2)': 'transparent'" (click)="portata = 3" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 13px; font-weight: 500">4</div>

                </div>
            </div>
            <div  class="slider" style="float: left; cursor: pointer; height: 50px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 40px; font-weight: 500; display: flex; align-items: center; width: 20%" [style.background-color]="portata == 4? 'rgba(181,181,181,0.2)': 'transparent'"  (click)="portata = 4" >
                <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
                    <div style="width: 100%; font-size: 13px; font-weight: 500">Tutto</div>

                </div>
            </div>

        </div>

      <div style="pointer-events: auto; margin-top: 15px; overflow-y: scroll; height: 40vh; border-radius: 20px; border: solid 1px rgba(181,181,181, 0.3)">
        <div class="ciaociao" style="padding-top: 0%; padding-left: 0px; " *ngFor="let piatto of getPropertiesPortata(this.camerieri[this.schermataIndex]?.carrello); let last = last; let first = first">
            <menuDetailCard-bar
                    style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                    [nome]="this.camerieri[this.getIndex()]?.carrello[piatto]['title']"
                    [ingredienti]="this.camerieri[this.getIndex()]?.carrello[piatto]['category']"
                    [prezzo]="getPrezzoPiatto(this.camerieri[this.getIndex()]?.carrello[piatto])"
                    [quantita]="this.camerieri[this.getIndex()]?.carrello[piatto].quantita"
                    [id]="this.camerieri[this.getIndex()].ordine"
                    [pagato]="this.camerieri[this.getIndex()].statoPagato"
                    [carrello]="true"
                    [last]="last"
                    [first]="first"
                    [lenght]="getProperties(this.camerieri[this.getIndex()]?.carrello).length"
                    [link]="imagePaths[this.camerieri[this.getIndex()]?.carrello[piatto].title] | async"
                    [aggiunte]="this.camerieri[this.getIndex()]?.carrello[piatto].aggiunte"
                    [rimozioni]="this.camerieri[this.getIndex()]?.carrello[piatto].rimozioni"
                    [nota]="this.camerieri[this.getIndex()]?.carrello[piatto].note"
                    (quantitaOut)="handleEvent($event, this.camerieri[this.getIndex()]?.carrello[piatto], piatto)">
            </menuDetailCard-bar>
        </div>
      </div>


    </div>


    <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 15px">
      <div style="padding-top: 0%; padding-left: 0px">
        <div  style="flex-basis: 100%;  height: 1px; background-color: #e55933; "></div>

        <div style="text-align: right; font-weight: 600; margin-top: 10px; margin-right: 13px; color: #e55933; font-size: x-large">Totale:  {{calcolaTotale().toFixed(2)}}€</div>
      </div>

      <div style=" margin-top: 20px">




        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; margin-right: 2%; margin-bottom: 1%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                       [nome]="'Preconto Pagato'"
                       [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                       altezza="55"
                       (click)="printReceipt('apri')">

        </categorieCard>

        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%"
                       [nome]="'Preconto non Pagato'"
                       [link]="'assets/icons/icons8-drawer-100.png'"
                       altezza="55"
                       (click)="printReceipt('nonAprire')">
        </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; margin-right: 2%; margin-bottom: 1%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                         [nome]="'Invia Comanda'"
                         [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                         altezza="55"
                         (click)="testComande()">
          </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                         [nome]="'Avanti Comanda'"
                         [link]="'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7ElEQVR4nO2YPWsVQRSG149GBb/+gKVYW5rCwtbKQlGw0H8hyDYWam/wNyzBC/O8cz+8hKt7tbKxC4FUfoEQiI1gRN0wMBcuMYTNruPODfPCsLNTHM6zc87MOZtlSQsi4LWkNeCuG24ewfurA4NIqmIcWQuQGHZirTVIFomUQJR2JIiUQkuHPLQkTVveBdMaNqeLAFLWsFkGB+lKSiBKOxJEiiDZ/zqBFvXUKhuAlMFBupISiNKOBJEiSPZUa80rJbtSsoeR0j2iVGtVIWotYAW49f9iMjYpMpDBYHAReA6sA98kfZb0whhzvaqqIwsBIuk+8NP79EvSpqTfc6Foogex1t4A/nh/Hks659aHw+F54CHwwz0PDAK8nXVwIebz6vf7p4Gv3pcn2R6y1l4ZjUansobHbxlqvjuknA/AF2PMyayJYggtYNWDPG1spEsQ4F7NluC7y5WYQd7VAQGeRR9axpilmQ+TyeR4Y0MxgRRFcaKxoZQj/0AcphxxkvR+v8uwlmIAAR55Pz4URXGskZGWv3xaDWDV+3BB0rZfz/fys9frnZV0ab+v8aZLGEmXPUw+q3qBB+Px+Ixbd6U7cBXYAO5ksSvP86OSlndBfpK0NXu31t7u2s/aknTNtbu+odoGPgIvjTE33YW5Ax46bBsi/MF9AAAAAElFTkSuQmCC'"
                         altezza="55"
                         (click)="avantiComanda()">
          </categorieCard>

          <!--categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 100%; margin-bottom: 1%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                         [nome]="'Fidelity'"
                         [link]="'assets/icons/icons8-loyalty-90.png'"
                         [bg]="'rgb(52, 152, 219)'"
                         altezza="55"
                         (click)="openDialogFidelity()">
          </categorieCard-->


        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;  float: left; width: 49%; margin-right: 2%" *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true" (click)="eliminaOrdine(camerieri[getIndex()]?.ordine)"
                       [nome]="'Elimina '"
                       [link]="'assets/icons/icons8-delete-96.png'"
                       [bg]="'rgba(255,0,0,0.8)'"
                       altezza="55"
                       (click)="schermata = 0">

        </categorieCard>

        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; " *ngIf="camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true"
                       [nome]="'Pagamento'"
                       [link]="'assets/icons/icons8-cash-100.png'"
                       [bg]="'#34bfa3'"
                       altezza="55"
                       (click) = "openDialogPagamento()">

        </categorieCard>

          <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; float: left; width: 49%; " *ngIf="!(camerieri[getIndex()]?.statoPagato !== undefined? camerieri[getIndex()].statoPagato == 0? false: true: true)"
                         [nome]="'Fatturazione'"
                         [link]="'assets/icons/icons8-cash-100.png'"
                         [bg]="'#34bfa3'"
                         altezza="55"
                         (click) = "openDialogFatturazione()">

          </categorieCard>


      </div>
    </div>

    <!--div class="container2NotScrolled" style="pointer-events: auto; margin-top: 1px">
      <div style="padding-top: 0%; padding-left: 0px">
        <dettaglioScontrinoFinaleCard [nome]="this.nome2" [luogo]="this.luogo" [costo]="calcolaMancia()" [percentuale]="this.percentuale2"
        style="border-bottom-right-radius: 20px; border-bottom-left-radius: 20px"></dettaglioScontrinoFinaleCard>
      </div>
    </div-->


  </div>


  <div class="columnOrdini" *ngIf="schermata == 0" >

    <div class="sb-example-1 shadow" style="margin-top: 30px !important;  width: 100%">
      <div class="search">
        <button type="submit" class="search-button" style="margin-top: 7px !important">
          <img style="width: 100%" [src]="'assets/icons/search.png'" >
        </button>
        <input style="margin-left: 10px !important" type="text" class="searchTerm" placeholder="Cerca">
      </div>
    </div>



    <div class="row" style="float: left; width: 100%; height: 60px; margin-top: 20px; border-radius: 30px; background-color: white; border: 1px solid rgba(181,181,181,0.2); padding: 5px; ">
      <div  class="slider" style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="lista == 0? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 0">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Ordini</span>
      </div>
      <div class="slider" style="float: left; cursor: pointer; height: 100%; width: 50%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="lista == 1? 'rgba(181,181,181,0.2)': 'transparent'" (click)="lista = 1">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Tavoli</span>

      </div>
    </div>


    <!--p style="width: 75%; text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 22px; padding-left: 0px; margin: 0px; margin-top:20px; margin-left: 0%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; ">Ordini</p-->

    <div class="container" id="menuListaPiatti" *ngIf="lista == false" style="display: block; width: 100%; padding: 5px; height: 78vh; overflow-y: auto;">
      <div style="pointer-events: auto; margin-top: 15px; width: 99%">


          <div class="container" style="padding-top: 0px; ">
            <div class="row" style="padding-top: 0%" *ngFor="let scontrino of camerieri; index as i">
              <!--receipt class="col col-md" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" (click)=" this.router.navigate(['scontrini/dettaglioScontrino']); "></receipt-->

              <div style="float: right; background-color: rgba(255,0,0, 0.8); border: solid 2px red; color: white; width: 30px; height: 30px; border-radius: 20px; display: grid; place-items: center; font-weight: 300; margin-bottom: -25px; margin-right: -10px; position: relative; cursor: pointer;" *ngIf="((eliminazione == true))" (click)="eliminaOrdine(scontrino.ordine)">X</div>

              <!--div style="float: right; background-color: rgba(255,0,0, 0.8); border: solid 2px red; color: white; width: 30px; height: 30px; border-radius: 20px; display: grid; place-items: center; font-weight: 300; margin-bottom: -25px; margin-right: -10px; position: relative; cursor: pointer;" *ngIf="((eliminazione == true))" (click)="eliminaOrdine(scontrino.ordine)">X</div-->

              <ordini-card class="col col-md menuItem" stato="Apert" [totale]="calcolaTotaleOrdine(scontrino.ordine)" [fonte]="scontrino.fonte" [nome]="scontrino.displayName" [numero]="scontrino.ordine" [statoPagato]="scontrino.statoPagato" [bloccato]="scontrino.bloccato" (click)="cambiaSchermata(1, i, scontrino.ordine)">
              </ordini-card>

            </div>
          </div>



      </div>
    </div>


      <!--div *ngIf="lista == true">
          <div class="example-boundary2">
              <div class="example-boundary">
                  <div *ngFor="let tavolo of tavoli" class="example-box" cdkDragBoundary=".example-boundary" cdkDrag [cdkDragFreeDragPosition]="tavolo.posizione" cdkDragDisabled="true">
                      <app-table-number
                              [tableNumber]="tavolo.numero"
                              [coperti]="tavolo.coperti"
                              [occupato]="tavolo.occupato"
                              (click)="gestioneClickTavolo(tavolo)">
                      </app-table-number>
                  </div>
              </div>
          </div>
      </div-->

      <div *ngIf="lista == true" >
                  <div *ngFor="let scontrino of tavoliVuoti; index as i" style="height: 0px;">
                      <tab-card class="" stato="Apert" [totale]="calcolaTotaleOrdine2(findTavolo(i+1)?.ordine)" [fonte]="findTavolo(i+1)?.fonte" [nome]="findTavolo(i+1)?.displayName" [numero]="i+1" [statoPagato]="findTavolo(i+1)?.statoPagato" [bloccato]="findTavolo(i+1)?.bloccato" (click)="findTavolo(i+1) !== null? cambiaSchermata(1, findIndexTavolo(i+1), findTavolo(i+1)?.ordine): this.findIndexTavolo(i+1)">
                      </tab-card>
                  </div>
      </div>




  </div>


  <div class="column1Ordini" *ngIf="schermata == 0">

    <div class="row" style="margin-top: 8%">
    </div>

    <div class="line"></div>

    <p style="width: 75%; text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 22px; padding-left: 0px; margin: 0px; margin-top:15px; margin-left: 0%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; " *ngIf="lista == false">Azioni</p>

      <div class="container" style="padding-top: 15px" *ngIf="lista == false">
          <div class="row" style="padding-top: 0%; padding-left: 0px" >
              <categorieCard *ngIf="!lista" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             (click)="aggiungiOrdine();"
                             [nome]="'Aggiungi Ordine'"
                             [link]="'assets/icons/icons8-add-90.png'">
              </categorieCard>
              <categorieCard *ngIf="lista" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             (click)="attivaModalitaUnione()"
                             [nome]="'Unisci Tavoli'"
                             [link]="'assets/icons/icons8-add-90.png'">
              </categorieCard>
              <categorieCard *ngIf="!lista" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             (click)="eliminazione = !eliminazione; modalitaUnione = false;"
                             [nome]="'Rimuovi Ordine'"
                             [link]="'assets/icons/icons8-minus-90.png'">
              </categorieCard>
              <categorieCard *ngIf="lista" class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                             (click)="eliminazione = !eliminazione; modalitaUnione = false;"
                             [nome]="'Libera Tavolo'"
                             [link]="'assets/icons/icons8-minus-90.png'">
              </categorieCard>
          </div>
      </div>


    <p style="width: 75%; text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 22px; padding-left: 0px; margin: 0px; margin-top:15px; margin-left: 0%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; " *ngIf="lista == false">Fonte</p>


    <!--p style="text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: normal; font-size: 14px; padding-left: 0px; margin: 0px; margin-top:20px; margin-left: 4%; white-space: normal; color: #E65934; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky;">Apri Tutto</p-->


    <div style="background-color: white; border: 1px solid rgba(181,181,181,0.3); border-radius: 20px; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; display: grid; margin-top: 15px" *ngIf="lista == false">
      <div style="display: flex; cursor: pointer" (click)="filtraApp()">
        <div >
          <img *ngIf="filtroSource != 0" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroSource == 0" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroSource === 0 ? '#E55933' : 'black'}">App</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraChiosco()">
        <div>
          <img *ngIf="filtroSource != 1" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroSource == 1" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroSource === 1 ? '#E55933' : 'black'}">Kiosk</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraCassa()">
        <div>
          <img *ngIf="filtroSource != 2" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroSource == 2" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroSource === 2 ? '#E55933' : 'black'}">Cassa</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraTutto()">
        <div>
          <img *ngIf="filtroSource != 3" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroSource == 3" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroSource === 3 ? '#E55933' : 'black'}">Tutti</div>
      </div>
    </div>


    <p style="width: 75%; text-align: left; max-width: 95%; display: inline-block;  border: none; font-weight: bold; font-size: 22px; padding-left: 0px; margin: 0px; margin-top:15px; margin-left: 0%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; ">Stato</p>

    <div style="background-color: white; border: 1px solid rgba(181,181,181,0.3); border-radius: 20px; padding-top: 15px; padding-bottom: 15px; padding-left: 20px; padding-right: 20px; display: grid; margin-top: 15px">
      <div style="display: flex; cursor: pointer" (click)="filtraPagato()">
        <div >
          <img *ngIf="filtroStato != 0" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 0" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 0 ? '#E55933' : 'black'}">Pagato</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>
      <div style="display: flex; cursor: pointer" (click)="filtraNonPagato()">
        <div>
          <img *ngIf="filtroStato != 1" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 1" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 1 ? '#E55933' : 'black'}">Non Pagato</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>


      <div style="display: flex; cursor: pointer" (click)="filtraCompletato()">
        <div>
          <img *ngIf="filtroStato != 3" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 3" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 3 ? '#E55933' : 'black'}">Completato</div>
      </div>
      <div style=" margin-left:40px; width: calc(100% - 70px); height: 1px; background-color: rgba(181,181,181,0.3); margin-top: 10px; margin-bottom: 10px"></div>


      <div style="display: flex; cursor: pointer" (click)="filtraStatoTutto()">
        <div>
          <img *ngIf="filtroStato != 2" [src]="'assets/icons/icons8-radio-button-empty-96.png'" style="display: grid; place-items: center; width: 30px">
          <img *ngIf="filtroStato == 2" [src]="'assets/icons/icons8-radio-button-96.png'" style="display: grid; place-items: center; width: 30px">
        </div>
        <div style="font-size: 16px; font-weight: 600; display: grid; place-items: center; align-items: first; margin-left: 20px" [ngStyle]="{'color': filtroStato === 2 ? '#E55933' : 'black'}">Tutti</div>
      </div>
    </div>




  </div>

  <div class="column2" *ngIf="schermata > 0">

    <div class="row1" style="float: left; width: calc(100% - 10px); height: 90px; margin-top: 20px; border-radius: 20px; background-color: white; border: solid 1px #e3e2e2;  padding:  0px 5px">


      <div  class="slider" style="float: left; cursor: pointer; height: 80px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 60px; font-weight: 500; display: flex; align-items: center; " [style.background-color]="sezione == -1? 'rgba(181,181,181,0.2)': 'transparent'" [style.width]="width() + '%'"  (click)="sezione = -1" >

        <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
          <img class="store"
               style="width: 35px !important; height: 35px !important; max-height: 40px !important; max-width: 40px !important; background-size: 100% auto;"
               [src]="'assets/icons/icons8-heart-100.png'"
               >
            <div style="width: 100%; margin-top:-40px; font-size: 18px; font-weight: 500">Preferiti</div>

        </div>
      </div>

        <div *ngFor="let element of menu; let i = index" class="slider" style="float: left; cursor: pointer; height: 80px; margin-top: 5px;  border-radius: 15px; background-color: rgba(181,181,181,0.2); text-align: center; line-height: 60px; font-weight: 500; display: flex; align-items: center;" [style.background-color]="sezione == i? 'rgba(181,181,181,0.2)': 'transparent'" [style.width]="width() + '%'"  (click)="sezione = i" >


          <div style=" justify-content: center; align-items: center; width: 100%; height: 70%; position: relative; ">
            <img class="store"
                 style="width: 40px !important; height: 40px !important; max-height: 40px !important; max-width: 40px !important; background-size: 100% auto;"
                 src="{{imagePaths[element.menu[0]['title']] | async}}"
                 >
              <div style="width: 100%; margin-top:-40px; font-size: 18px; font-weight: 500; overflow: hidden; height: 40px">{{element.menu[0]['category']}}</div>

          </div>
        </div>


    </div>


    <div style="overflow-y: auto; float: left; width: 100%; height: 80vh; border-radius: 30px; background-color: transparent; padding-top: 10px; " *ngIf="sezione > -1">

      <div *ngFor="let piatto of menu[sezione]['menu'], let i = index, trackBy: trackByFn" >

        <div style="position: relative; float: left; cursor: pointer; width: 23%; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '200px' : '180px'" (click)="updateCameriere(piatto)">

          <img [src]="imagePaths[piatto.title] | async" style="height: 100px; margin-top: 15px; background-color: transparent;">

          <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 14px">{{piatto.title}}</span>
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
            <div style="padding-left: 10px; ">
              <div style="background-color: #34bfa3; font-size: small; border-radius: 12px; color: white; font-weight: bold; aspect-ratio: 1/1; padding: 3px 6px" *ngIf="getQuantita(piatto) > 0">
                {{getQuantita(piatto)}}x
              </div>
            </div>
            <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;">{{(+piatto.price).toFixed(2)}}€</span>
          </div>
        </div>
      </div>

    </div>


    <div style="overflow-y: auto; float: left; width: 100%; height: 80vh; border-radius: 30px; background-color: transparent; padding-top: 10px; " *ngIf="sezione == -1">

      <div *ngFor="let piatto of user['preferiti'], let i = index" >

        <div style="position: relative; float: left; cursor: pointer; width: 23%; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; display: flex; flex-direction: column; align-items: center;" [style.min-height]="piatto['descrizione'] !== '' ? '200px' : '180px'" (click)="updateCameriere(piatto)">

          <img [src]="imagePaths[piatto.title] | async" style="height: 100px; margin-top: 15px; background-color: transparent;">

          <span style="width: calc(100% - 20px); background-color: transparent; margin-top: 5px; text-align: center; margin-left: 10px; font-weight: 700; margin-right: 10px; font-size: 14px">{{piatto.title}}</span>
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-top: auto; padding-bottom: 10px;">
            <div style="padding-left: 10px; ">
              <div style="background-color: #34bfa3; font-size: small; border-radius: 12px; color: white; font-weight: bold; aspect-ratio: 1/1; padding: 3px 6px" *ngIf="getQuantita(piatto) > 0">
                {{getQuantita(piatto)}}x
              </div>
            </div>
            <span style="background-color: transparent; font-size: small; font-weight: bold; padding-right: 10px; text-align: right;">{{(+piatto.price).toFixed(2)}}€</span>
          </div>
        </div>
      </div>

    </div>




      <app-numeric-keypad></app-numeric-keypad>


  </div>

</div>
