// dialog-buoni-pasto.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


// alert-dialog.component.ts
@Component({
    selector: 'app-alert-dialog',
    template: `
    <h2 mat-dialog-title>{{data.title}}</h2>
    <mat-dialog-content>
      <p>{{data.message}}</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false" *ngIf="data.showCancel">
        Annulla
      </button>
      <button mat-button [mat-dialog-close]="true" color="primary">
        Procedi
      </button>
    </mat-dialog-actions>
  `,
    styles: [`
    :host {
      display: block;
      padding: 16px;
    }
  `]
})
export class AlertDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string;
            message: string;
            showCancel?: boolean;
        }
    ) {}
}