import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";
import {CommonService} from "../../shared/services/firestore/common.service";
import { MatDialog } from '@angular/material/dialog';
import {DialogVariantiComponent} from "../dialog-varianti/dialog-varianti.component";
import { MatSnackBar } from '@angular/material/snack-bar';

interface Variant {
  id: string;
  name: string;
  price: number;
  options: VariantOption[];
  isRequired: boolean;
  maxSelections?: number;
}

interface VariantOption {
  id: string;
  name: string;
  priceModifier: number;
}

interface PriceListSettings {
  counter: { vatId: string };
  table: { vatId: string };
  takeaway: { vatId: string };
}

interface PriceList {
  id: string;
  name: string;
  settings: PriceListSettings;
}

interface PriceListPrices {
  counter: { price: number; vatId: string };
  table: { price: number; vatId: string };
  takeaway: { price: number; vatId: string };
}

// Packaging level interface
interface PackagingLevel {
  quantita: string;
  um: string;
  barCode: string;
}

@Component({
  selector: 'edit-menu',
  templateUrl: './edit-menu.html',
  styleUrls: ['./edit-menu.css'],
})
export class EditMenu implements OnInit, OnDestroy {
  isMenuChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  title: string = '';
  id: string = '';
  selectedCategory: string[] = [];
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';
  reparto: number = 0;

  selectedPrintDestination: string = '';
  variants: Variant[] = [];
  predefinedVariants: Variant[] = [];
  selectedPredefinedVariants: string[] = [];
  newVariant: Variant | null = null;
  selectedVariantIds: string[] = [];

  // Variabili per i prezzi dei menu
  menuPiccolo: string = '';
  menuMedio: string = '';
  menuGrande: string = '';

  // Packaging level variables
  levels: PackagingLevel[] = [
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' },
    { quantita: '', um: '', barCode: '' }
  ];

  // Level visibility flags
  isLvl1: boolean = false;
  isLvl2: boolean = false;
  isLvl3: boolean = false;
  isLvl4: boolean = false;
  maxLvl: number = 0; // Default a nessun livello

  priceLists: PriceList[] = [];
  selectedPriceListId: string = '';
  isCreatingNewList = false;

  newList: PriceList = {
    id: '',
    name: '',
    settings: {
      counter: { vatId: '1' },
      table: { vatId: '1' },
      takeaway: { vatId: '1' }
    }
  };

  currentPrices: PriceListPrices = {
    counter: { price: 0, vatId: '1' },
    table: { price: 0, vatId: '1' },
    takeaway: { price: 0, vatId: '1' }
  };

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cdRef: ChangeDetectorRef,
      private dialogRef: MatDialogRef<EditMenu>,
      private dataService: DataServiceGenerico,
      private commonService: CommonService,
      private dialog: MatDialog,
      private snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: any  // Riceve i dati dal dialog
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  ngOnInit(): void {
    this.fetchDataFromFirestore();
    this.loadVariants();
    this.loadPriceLists();
    if (this.data) {
      // Popola i campi con i dati passati
      this.id = this.data.id || '';
      this.title = this.data.title || '';
      this.selectedCategory = Array.isArray(this.data.category) ? this.data.category : (this.data.category ? [this.data.category] : []);
      this.selectedImage = this.data.image_link || '';
      this.selectedIngredients = this.convertToArray(this.data.ingredients);
      this.selectedAllergens = this.convertToArray(this.data.allergens);
      this.price = this.data.price || '';
      this.isMenuChecked = !!this.data.menu;
      this.isSingleChecked = !!this.data.single;
      this.isDisponibileChecked = !!this.data.disponibilita;
      this.selectedPrintDestination = this.data.selectedPrintDestination || '';
      this.reparto = this.data.reparto || 1;

      // Inizializza le varianti
      this.variants = this.initializeVariants(this.data.variants);
      this.selectedVariantIds = this.data.variantIds || [];

      // Popola i prezzi del menu
      if (Array.isArray(this.data.prezziMenu)) {
        this.menuPiccolo = this.data.prezziMenu[0] || '';
        this.menuMedio = this.data.prezziMenu[1] || '';
        this.menuGrande = this.data.prezziMenu[2] || '';
      }

      // Inizializza i livelli di imballaggio
      if (this.data.lvl) {
        this.levels = this.data.lvl.map((level, index) => ({
          quantita: level.quantita || '',
          um: level.um || '',
          barCode: level.barCode || ''
        }));
      }

      // Imposta la visibilità dei livelli in base a maxLvl
      const maxLvl = this.data.maxLvl || 0;
      this.maxLvl = maxLvl;
      this.isLvl1 = maxLvl === 1;
      this.isLvl2 = maxLvl === 2;
      this.isLvl3 = maxLvl === 3;
      this.isLvl4 = maxLvl === 4;
    }

    this.cdRef.detectChanges();
  }

  ngOnDestroy(): any {
    this.passBack();
  }

  private convertToArray(value: any): string[] {
    if (Array.isArray(value)) {
      return value.map(item => typeof item === 'string' ? item : item.name || '').filter(Boolean);
    }
    if (typeof value === 'string') {
      return value.split(',').map(item => item.trim()).filter(Boolean);
    }
    return [];
  }

  loadVariants() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    variantiRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.variants = data?.variants || [];
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Errore nel caricamento delle varianti:', error);
      this.snackBar.open('Errore nel caricamento delle varianti', 'Chiudi', {
        duration: 3000
      });
    });
  }

  loadPredefinedVariants() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    variantiRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.predefinedVariants = data?.variants || [];
        this.cdRef.detectChanges();
      }
    });
  }

  openVariantiDialog() {
    const dialogRef = this.dialog.open(DialogVariantiComponent, {
      width: '45vw',
      height: '85vh',
      data: { variants: this.predefinedVariants }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadPredefinedVariants();
      }
    });
  }

  loadPriceLists() {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const priceListsRef = db.collection('price_lists').doc(restaurantName);

    priceListsRef.get().then(doc => {
      if (doc.exists) {
        this.priceLists = doc.data()?.priceLists || [];
        this.cdRef.detectChanges();
      }
    });
  }

  onPriceListChange(event: any) {
    const listId = event.value;
    this.selectedPriceListId = listId;

    // Get existing prices for this list if available
    const existingPrices = this.data?.priceListPrices?.[listId];

    // Initialize or update currentPrices
    this.currentPrices = {
      counter: {
        price: existingPrices?.counter?.price || 0,
        vatId: existingPrices?.counter?.vatId || '1'
      },
      table: {
        price: existingPrices?.table?.price || 0,
        vatId: existingPrices?.table?.vatId || '1'
      },
      takeaway: {
        price: existingPrices?.takeaway?.price || 0,
        vatId: existingPrices?.takeaway?.vatId || '1'
      }
    };

    this.cdRef.detectChanges();
  }

  async saveNewPriceList() {
    if (!this.newList.name) return;

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const priceListsRef = db.collection('price_lists').doc(restaurantName);

    try {
      const doc = await priceListsRef.get();
      const existingLists = doc.exists ? (doc.data()?.priceLists || []) : [];

      const listToSave = {
        ...this.newList,
        id: firebase.firestore().collection('temporary').doc().id
      };

      const updatedLists = [...existingLists, listToSave];

      await priceListsRef.set({ priceLists: updatedLists });

      this.priceLists = updatedLists;
      this.selectedPriceListId = listToSave.id;
      this.isCreatingNewList = false;

      // Initialize prices for the new list
      this.currentPrices = {
        counter: { price: 0, vatId: listToSave.settings.counter.vatId },
        table: { price: 0, vatId: listToSave.settings.table.vatId },
        takeaway: { price: 0, vatId: listToSave.settings.takeaway.vatId }
      };

      this.snackBar.open('Listino creato con successo!', 'Chiudi', {
        duration: 2000
      });

      this.cdRef.detectChanges();
    } catch (error) {
      console.error('Errore durante il salvataggio del listino:', error);
      this.snackBar.open('Errore durante il salvataggio', 'Chiudi', {
        duration: 3000
      });
    }
  }

  cancelNewList() {
    this.isCreatingNewList = false;
    this.newList = {
      id: '',
      name: '',
      settings: {
        counter: { vatId: '1' },
        table: { vatId: '1' },
        takeaway: { vatId: '1' }
      }
    };
  }

  private initializeVariants(variants: any[]): Variant[] {
    if (!Array.isArray(variants)) {
      return [];
    }

    return variants.map(variant => ({
      id: variant.id || firebase.firestore().collection('temporary').doc().id,
      name: variant.name || '',
      price: typeof variant.price === 'number' ? variant.price : 0, // Aggiunto il campo price
      options: Array.isArray(variant.options) ? variant.options.map(opt => ({
        id: opt.id || firebase.firestore().collection('temporary').doc().id,
        name: opt.name || '',
        priceModifier: typeof opt.priceModifier === 'number' ? opt.priceModifier : 0
      })) : [],
      isRequired: Boolean(variant.isRequired),
      maxSelections: typeof variant.maxSelections === 'number' ? variant.maxSelections : 1 // Cambiato undefined con 1 come valore di default
    }));
  }

  fetchDataFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();
        const ingredientsSet = new Set<string>();
        const allergensSet = new Set<string>();

        items.forEach(item => {
          if (item.category) {
            categoriesSet.add(item.category);
          }
          if (item.ingredients) {
            item.ingredients.forEach(ingredient => ingredientsSet.add(ingredient.name));
          }
          if (item.allergens) {
            item.allergens.forEach(allergen => allergensSet.add(allergen.name));
          }

          // Assuming you're fetching a specific item based on `id`
          if (item.id === this.id) {
            this.title = item.title;
            this.price = item.price;
            this.selectedCategory = [item.category];
            this.selectedImage = item.image_link;
            this.selectedIngredients = item.ingredients.map(i => i.name);
            this.selectedAllergens = item.allergens.map(a => a.name);
            this.isMenuChecked = item.menu;
            this.isSingleChecked = item.consigliato;
            this.isDisponibileChecked = item.disponibilita;
            this.menuPiccolo = item.prezziMenu ? item.prezziMenu[0] : '';
            this.menuMedio = item.prezziMenu ? item.prezziMenu[1] : '';
            this.menuGrande = item.prezziMenu ? item.prezziMenu[2] : '';
            this.selectedPrintDestination = item.selectedPrintDestination;
            this.reparto = item.reparto;

            // Carica i livelli di imballaggio
            if (item.lvl) {
              this.levels = item.lvl.map((level, index) => ({
                quantita: level.quantita || '',
                um: level.um || '',
                barCode: level.barCode || ''
              }));

              // Imposta la visibilità dei livelli
              const maxLvl = item.maxLvl || 0;
              this.maxLvl = maxLvl;
              this.isLvl1 = maxLvl === 1;
              this.isLvl2 = maxLvl === 2;
              this.isLvl3 = maxLvl === 3;
              this.isLvl4 = maxLvl === 4;
            }
          }
        });

        this.categories = Array.from(categoriesSet);
        this.ingredients = Array.from(ingredientsSet);
        this.allergens = Array.from(this.dataService.getAllergeni());

        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }

  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage() {
    this.selectedImage = '';
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file);
    }
  }

  uploadImageToFirebase(file: File) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // Optional: Monitor upload progress
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.error('Error uploading image:', error);
        },
        () => {
          // Handle successful uploads
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.selectedImage = downloadURL;
            this.cdRef.detectChanges();
          });
        }
    );
  }

  addCustomVariant() {
    this.newVariant = {
      id: firebase.firestore().collection('temporary').doc().id,
      name: '',
      price: 0,
      options: [],
      isRequired: false,
      maxSelections: 1
    };
    this.cdRef.detectChanges();
  }

  async saveNewVariant() {
    if (!this.newVariant) return;

    // Verifica se esiste già una variante con lo stesso nome
    const nameExists = this.variants.some(v =>
        v.name.toLowerCase() === this.newVariant?.name.toLowerCase()
    );

    if (nameExists) {
      this.snackBar.open('Esiste già una variante con questo nome!', 'Chiudi', {
        duration: 3000
      });
      return;
    }

    // Salva la nuova variante nella collezione varianti
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const variantiRef = db.collection('varianti').doc(restaurantName);

    try {
      const doc = await variantiRef.get();
      const existingVariants = doc.exists ? (doc.data()?.variants || []) : [];

      // Aggiungi la nuova variante
      const updatedVariants = [...existingVariants, this.newVariant];

      await variantiRef.set({ variants: updatedVariants });

      // Aggiorna la lista delle varianti disponibili
      this.variants = updatedVariants;

      // Seleziona automaticamente la nuova variante
      this.selectedVariantIds = [...this.selectedVariantIds, this.newVariant.id];

      // Resetta il form della nuova variante
      this.newVariant = null;

      this.snackBar.open('Variante salvata con successo!', 'Chiudi', {
        duration: 2000
      });

      this.cdRef.detectChanges();
    } catch (error) {
      console.error('Errore durante il salvataggio della variante:', error);
      this.snackBar.open('Errore durante il salvataggio della variante', 'Chiudi', {
        duration: 3000
      });
    }
  }

  cancelNewVariant() {
    this.newVariant = null;
    this.cdRef.detectChanges();
  }

  addOptionToNewVariant() {
    if (!this.newVariant) return;

    const newOption: VariantOption = {
      id: firebase.firestore().collection('temporary').doc().id,
      name: '',
      priceModifier: 0
    };

    this.newVariant = {
      ...this.newVariant,
      options: [...this.newVariant.options, newOption]
    };

    this.cdRef.detectChanges();
  }

  removeOptionFromNewVariant(optionId: string) {
    if (!this.newVariant) return;

    this.newVariant = {
      ...this.newVariant,
      options: this.newVariant.options.filter(o => o.id !== optionId)
    };

    this.cdRef.detectChanges();
  }

  saveChangesToFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = Array.isArray(data?.items) ? [...data.items] : [];

        // Get existing item and its price lists
        const existingItem = this.id ? items.find(item => item.id === this.id) : null;

        // Create new price list data object
        const newPriceListData = {
          counter: {
            price: this.currentPrices.counter.price || 0,
            vatId: this.currentPrices.counter.vatId || '1'
          },
          table: {
            price: this.currentPrices.table.price || 0,
            vatId: this.currentPrices.table.vatId || '1'
          },
          takeaway: {
            price: this.currentPrices.takeaway.price || 0,
            vatId: this.currentPrices.takeaway.vatId || '1'
          }
        };

        // Combine existing price lists with new data
        let updatedPriceListPrices = { ...existingItem?.priceListPrices || {} };
        if (this.selectedPriceListId) {
          updatedPriceListPrices[this.selectedPriceListId] = newPriceListData;
        }

        // Get the current max level
        const maxLevel = this.getMaxLevel();

        const itemData = {
          id: this.id || firebase.firestore().collection('temporary').doc().id,
          title: this.title || '',
          price: this.price || 0,
          category: this.selectedCategory[0] || '',
          image_link: this.selectedImage || '',
          ingredients: this.selectedIngredients.map(name => ({ name, quantity: 1 })) || [],
          allergens: this.selectedAllergens.map(name => ({ name, quantity: 1 })) || [],
          menu: this.isMenuChecked || false,
          consigliato: this.isSingleChecked || false,
          disponibilita: this.isDisponibileChecked || false,
          prezziMenu: [this.menuPiccolo || '', this.menuMedio || '', this.menuGrande || ''],
          selectedPrintDestination: this.selectedPrintDestination || '',
          reparto: this.reparto || 2,
          variantIds: this.selectedVariantIds || [],
          priceListPrices: updatedPriceListPrices,
          // Aggiungi i livelli di imballaggio
          lvl: this.levels.slice(0, maxLevel),
          maxLvl: this.maxLvl
        };

        let updatedItems: any[];
        if (this.id) {
          updatedItems = items.map(item =>
              item.id === this.id ? { ...item, ...itemData } : item
          );
        } else {
          updatedItems = [...items, itemData];
        }

        return menuRef.update({ items: updatedItems })
            .then(() => {
              this.snackBar.open('Menu aggiornato con successo!', 'Chiudi', {
                duration: 2000
              });
              this.dialogRef.close();
            })
            .catch(error => {
              console.error('Errore durante l\'aggiornamento del menu:', error);
              this.snackBar.open('Errore durante il salvataggio', 'Chiudi', {
                duration: 3000
              });
            });
      }
    });
  }

  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        // Filtra l'elemento da eliminare
        const updatedItems = items.filter(item => item.id !== this.id);

        // Aggiorna Firestore con l'array di elementi aggiornato
        menuRef.update({
          items: updatedItems
        })
            .then(() => {
              console.log('Elemento eliminato con successo da Firestore!');
              this.dialogRef.close();  // Chiudi il dialog dopo l'eliminazione
            })
            .catch((error) => {
              console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
            });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    const dataToPassBack = {
      title: this.title,
      category: this.selectedCategory.join(', '),
      image_link: this.selectedImage || '',
      ingredients: this.selectedIngredients.join(', '),
      allergens: this.selectedAllergens.join(', '),
      price: this.price,
      menu: this.isMenuChecked ? '1' : '0',
      single: this.isSingleChecked ? '1' : '0',
      disponibilita: this.isDisponibileChecked ? '1' : '0',
      prezziMenu: [this.menuPiccolo, this.menuMedio, this.menuGrande],
      lvl: this.levels.slice(0, this.getMaxLevel()),
      maxLvl: this.maxLvl
    };
    this.passEntry.emit(dataToPassBack);
  }

  // Funzioni per gestire i livelli di imballaggio
  onMenuChangeLevel1() {
    this.maxLvl = 1;
    this.isLvl1 = true;
    this.isLvl2 = false;
    this.isLvl3 = false;
    this.isLvl4 = false;

    // Assicurati che l'oggetto per il primo livello esista
    this.levels[0] = this.levels[0] || { quantita: '', um: '', barCode: '' };

    // Resetta i livelli successivi
    for (let i = 1; i < 4; i++) {
      this.levels[i] = { quantita: '', um: '', barCode: '' };
    }
  }

  onMenuChangeLevel2() {
    this.maxLvl = 2;
    this.isLvl1 = false;
    this.isLvl2 = true;
    this.isLvl3 = false;
    this.isLvl4 = false;

    // Assicurati che gli oggetti per i primi due livelli esistano
    for (let i = 0; i < 2; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }

    // Resetta i livelli successivi
    for (let i = 2; i < 4; i++) {
      this.levels[i] = { quantita: '', um: '', barCode: '' };
    }
  }

  onMenuChangeLevel3() {
    this.maxLvl = 3;
    this.isLvl1 = false;
    this.isLvl2 = false;
    this.isLvl3 = true;
    this.isLvl4 = false;

    // Assicurati che gli oggetti per i primi tre livelli esistano
    for (let i = 0; i < 3; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }

    // Resetta l'ultimo livello
    this.levels[3] = { quantita: '', um: '', barCode: '' };
  }

  onMenuChangeLevel4() {
    this.maxLvl = 4;
    this.isLvl1 = false;
    this.isLvl2 = false;
    this.isLvl3 = false;
    this.isLvl4 = true;

    // Assicurati che tutti e quattro i livelli esistano
    for (let i = 0; i < 4; i++) {
      this.levels[i] = this.levels[i] || { quantita: '', um: '', barCode: '' };
    }
  }

  getMaxLevel(): number {
    if (this.isLvl4) return 4;
    if (this.isLvl3) return 3;
    if (this.isLvl2) return 2;
    if (this.isLvl1) return 1;
    return 0;
  }
}