import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { MatDialog } from '@angular/material/dialog';
import { IpcService } from "../../../shared/services/ipc.service";
import { PosTransactionService } from "../../../shared/services/pos-transaction.service";
import { MatTableDataSource } from "@angular/material/table";
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { Subscription } from 'rxjs';
import { AddEmployeeDialogComponent } from "./dialog/add-employee-dialog.component";
import {AddRoleDialogComponent} from "./dialog/add-role-dialog.component";

@Component({
  selector: 'personale',
  templateUrl: './personale.html',
  styleUrls: ['./personale.css']
})
export class Personale implements OnInit, OnDestroy {
  displayedColumns = ['nome', 'permessi'];
  private personnelSubscription: Subscription;

  ruoli = [
    {
      nome: 'Admin',
      permessi: [
        'Menu',
        'Immagini Pubblicitarie',
        'Immagini Totem',
        'Fidelity',
        'Preferiti',
        'Preconto',
        'Elimina Ordine'
      ]
    },
    {
      nome: 'Cassiere',
      permessi: [
        'Preferiti',
      ]
    }
  ];

  permessi = [
    'Menu',
    'Immagini Pubblicitarie',
    'Immagini Totem',
    'Fidelity',
    'Preferiti',
    'Preconto',
      'Elimina Ordine',
      'Sconti',


    'Schermata Admin',
    'Dashboard',
    'Reportistica',
    'Anagrafica',
    'Cronologia Ordini',
    'Fidelity',
      'Annulla Scontrino',
      "Totali",
      "Chiusura Giornaliera",

    "Impostazioni"

  ];

  organico = [];
  dataSource = new MatTableDataSource<any>([]);
  immagini = {};

  columns = [
    { field: 'nome' },
    { field: 'cognome' },
    { field: 'ruolo' },
    { field: 'permessi' }
  ];

  displayedColumns2: string[] = [...this.columns.map(column => column.field), 'edit'];

  constructor(
      private router: Router,
      private _location: Location,
      private dialog: MatDialog,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private dataService: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    this.immagini = this.dataService.getImmagini();

    if (this.dataService.getRistorante1() === '') {
      this.dataService.loadSelectedMenuFromFirebase().then(() => {
        this.setupPersonnelListener();
      });
    } else {
      this.setupPersonnelListener();
    }
  }

  ngOnDestroy(): void {
    if (this.personnelSubscription) {
      this.personnelSubscription.unsubscribe();
    }
  }

  private setupPersonnelListener(): void {
    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();
    const ristoranteRef = db.collection('ristoranti').doc(ristoranteId);

    this.personnelSubscription = new Subscription();

    const docListener = ristoranteRef.onSnapshot(
        (docSnapshot) => {
          if (docSnapshot.exists) {
            const data = docSnapshot.data();
            if (data) {
              this.ruoli = data.ruoli || this.ruoli;
              this.organico = data.organico || this.organico;
              this.dataSource.data = this.organico;
            }
          }
        },
        (error) => {
          console.error('Errore nel listener dei dati del personale:', error);
        }
    );

    this.personnelSubscription.add(() => docListener());
  }

  async savePersonnelData(): Promise<void> {
    if (!this.dataService.getRistorante1()) {
      console.error('ID ristorante non disponibile');
      return;
    }

    const db = firebase.firestore();
    const ristoranteId = this.dataService.getRistorante1();
    const ristoranteRef = db.collection('ristoranti').doc(ristoranteId);

    try {
      const utentiUids = this.organico.map(dipendente => dipendente.uid);

      await ristoranteRef.set({
        ruoli: this.ruoli,
        organico: this.organico,
        utenti: utentiUids,
        lastUpdateOrganico: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true });
    } catch (error) {
      console.error('Errore nel salvataggio dei dati del personale:', error);
      throw error;
    }
  }

  openAddRoleDialog(): void {
    const dialogRef = this.dialog.open(AddRoleDialogComponent, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const roleExists = this.ruoli.some(role =>
              role.nome.toLowerCase() === result.nome.toLowerCase()
          );

          if (roleExists) {
            throw new Error('Esiste già un ruolo con questo nome');
          }

          this.ruoli.push(result);
          await this.savePersonnelData();
        } catch (error) {
          console.error('Errore nella creazione del ruolo:', error);
        }
      }
    });
  }

  openAddEmployeeDialog(): void {
    const dialogRef = this.dialog.open(AddEmployeeDialogComponent, {
      width: '600px',
      height: '80vh',
      panelClass: 'custom-dialog',
      disableClose: true
    });

    const employeeDialog = dialogRef.componentInstance;
    employeeDialog.setRuoli(this.ruoli);

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          // Commento temporaneamente la creazione dell'utente Firebase
          /*
          const secondaryApp = firebase.initializeApp({
            apiKey: "AIzaSyBYzhvaoK3MPFvrKSkEqTe8HTiLwcd-Pzo",
            authDomain: "deweats.firebaseapp.com",
            databaseURL: "https://deweats-default-rtdb.europe-west1.firebasedatabase.app",
            projectId: "deweats",
            storageBucket: "deweats.appspot.com",
            messagingSenderId: "157089671742",
            appId: "1:157089671742:web:899765e7a64cf907e03f6f",
            measurementId: "G-KCGE5JVRDK"
          }, 'secondary');

          const userCredential = await secondaryApp.auth().createUserWithEmailAndPassword(
            result.email,
            result.password
          );
          */

          const ruoloSelezionato = this.ruoli.find(r => r.nome === result.ruolo);

          const employeeData = {
            nome: result.nome,
            cognome: result.cognome,
            ruolo: result.ruolo,
            // uid: userCredential.user.uid,  // Temporaneamente commentato
            uid: Date.now().toString(), // Temporaneo UID univoco
            permessi: result.permessi || (ruoloSelezionato ? [...ruoloSelezionato.permessi] : []),
            username: result.username,
            passwordCassa: result.passwordCassa,
            ristorante: this.dataService.getRistorante1()
          };

          this.organico.push(employeeData);
          await this.savePersonnelData();
          // await secondaryApp.delete();  // Temporaneamente commentato

        } catch (error) {
          console.error('Errore nella creazione del dipendente:', error);
          throw error;
        }
      }
    });
  }


  editEmployee(employee: any): void {
    const dialogRef = this.dialog.open(AddEmployeeDialogComponent, {
      width: '600px',
      height: '80vh',
      panelClass: 'custom-dialog',
      disableClose: true
    });

    const employeeDialog = dialogRef.componentInstance;
    employeeDialog.setRuoli(this.ruoli);

    employeeDialog.employeeForm.patchValue({
      nome: employee.nome,
      cognome: employee.cognome,
      ruolo: employee.ruolo,
      permessi: employee.permessi,
      username: employee.username || '',
      passwordCassa: employee.passwordCassa || ''
    });

    const ruolo = this.ruoli.find(r => r.nome === employee.ruolo);
    if (ruolo) {
      employeeDialog.permessiDisponibili = [...ruolo.permessi];
    }

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const index = this.organico.findIndex(emp => emp.uid === employee.uid);
          if (index !== -1) {
            this.organico[index] = {
              ...this.organico[index],
              nome: result.nome,
              cognome: result.cognome,
              ruolo: result.ruolo,
              permessi: result.permessi,
              username: result.username,
              passwordCassa: result.passwordCassa || ''
            };

            await this.savePersonnelData();
          }
        } catch (error) {
          console.error('Errore nella modifica del dipendente:', error);
        }
      }
    });
  }

  async updatePermissions(user: any): Promise<void> {
    try {
      const index = this.organico.findIndex(u => u.mail === user.mail);
      if (index !== -1) {
        this.organico[index].permessi = Array.isArray(user.permessi) ? user.permessi : [];
        //await this.savePersonnelData();
      }
    } catch (error) {
      console.error('Errore nell\'aggiornamento dei permessi:', error);
      throw error;
    }
  }
}