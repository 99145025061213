import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  Inject,
  ChangeDetectorRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DataServiceGenerico } from "../../shared/services/data-service/data.service";
import { CommonService } from "../../shared/services/firestore/common.service";
import { IpcService } from "../../shared/services/ipc.service";
import firebase from 'firebase/app';

interface SelectedOption {
  name: string;
  priceModifier: number;
  variantId: string;
  variantTitle: string;
  quantity?: number;
}

interface VariantOption {
  id: string;
  name: string;
  priceModifier: number;
  imageUrl?: string;
}

interface Variant {
  id: string;
  name: string;
  price: number;
  options: VariantOption[];
  isRequired: boolean;
  maxSelections: number;
  domanda?: string;
}
interface CarrelloProdotto {
  title?: string;
  variants?: any[];
  [key: string]: any;
}

@Component({
  selector: 'app-seleziona-varianti-singolo',
  templateUrl: './seleziona-varianti-singolo.component.html',
  styleUrls: ['./seleziona-varianti-singolo.component.scss']
})
export class SelezionaVariantiSingoloComponent implements OnInit, OnDestroy {
  readonly defaultImage = 'assets/images/default-product.png';

  variants: Variant[] = [];
  currentVariantIndex = 0;
  selectedOptions: { [variantId: string]: { [optionId: string]: SelectedOption } } = {};
  isEditMode = false;
  originalProductKey = '';
  selectedPriceList = '';
  currentOrder: any;
  productKey: string | null = null;
  piattoRicevuto: any;
  user: any;
  portata: number;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
      private cdRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private dataServiceGenerico: DataServiceGenerico,
      private dialogRef: MatDialogRef<SelezionaVariantiSingoloComponent>,
      private commonService: CommonService,
      private ipcService: IpcService
  ) {
    this.currentOrder = this.data?.currentOrder || {};
    this.piattoRicevuto = this.data?.piattoRicevuto || this.data?.dataKey || {};
    this.user = this.data?.user;
    this.portata = this.data?.portata || 0;

    // Inizializza selectedOptions
    this.selectedOptions = {};
  }

  async ngOnInit() {
    try {
      this.selectedPriceList = await this.ipcService.getSelectedPriceList();
      if (!this.selectedPriceList) {
        this.selectedPriceList = '5Q1cRLWC2uvW8w0doJQ7';
      }
      await this.initializeComponent();
    } catch (error) {
      console.warn('Fallback al price list predefinito:', error);
      this.selectedPriceList = '5Q1cRLWC2uvW8w0doJQ7';
      await this.initializeComponent();
    }
  }



  ngOnDestroy() {
    if (this.data?.currentOrder) {
      this.passEntry.emit(this.data.currentOrder);
    }
  }

  private async initializeComponent() {
    if (this.data?.dataKey) {
      await this.loadVariants();
    }
  }

  private async setupEditMode() {
    this.isEditMode = true;
    this.originalProductKey = this.data.productKey;
    const existingProduct = this.currentOrder.carrello[this.originalProductKey];

    if (existingProduct?.variants) {
      existingProduct.variants.forEach((variant: any) => {
        if (!this.selectedOptions[variant.variantId]) {
          this.selectedOptions[variant.variantId] = {};
        }
        this.selectedOptions[variant.variantId][variant.name] = {
          name: variant.name,
          priceModifier: variant.priceModifier || 0,
          variantId: variant.variantId,
          variantTitle: variant.variantTitle,
          quantity: variant.quantity || 1
        };
      });
    }
  }

  private findExistingProductKey(): string | null {
    if (!this.currentOrder?.carrello) return null;

    const currentProduct = this.data.dataKey;

    return Object.entries(this.currentOrder.carrello).find(([key, product]: [string, any]) => {
      if (product.title !== currentProduct.title) return false;
      if (!product.variants || !product.variants.length) return false;

      // Verifica che tutte le varianti selezionate corrispondano
      const currentVariants = this.getSelectedVariantsArray();
      if (currentVariants.length !== product.variants.length) return false;

      return this.areVariantsEqual(currentVariants, product.variants);
    })?.[0] || null;
  }

  // Metodo per confrontare le varianti
  private areVariantsEqual(variants1: any[], variants2: any[]): boolean {
    if (!variants1 || !variants2) return false;
    if (variants1.length !== variants2.length) return false;

    return variants1.every(v1 =>
        variants2.some(v2 =>
            v1.variantId === v2.variantId &&
            v1.name === v2.name
        )
    );
  }

  private getSelectedVariantsArray(): SelectedOption[] {
    return Object.values(this.selectedOptions).reduce<SelectedOption[]>((acc, variantOptions) => {
      const options = Object.values(variantOptions) as SelectedOption[];
      return [...acc, ...options];
    }, []);
  }

  private async initializeExistingSelections() {
    if (this.currentOrder?.carrello && this.data.dataKey?.variants) {
      Object.entries(this.currentOrder.carrello).forEach(([key, product]: [string, any]) => {
        if (product.title === this.data.dataKey.title && product.variants) {
          this.productKey = key;
          product.variants.forEach((variant: any) => {
            if (!this.selectedOptions[variant.variantId]) {
              this.selectedOptions[variant.variantId] = {};
            }
            this.selectedOptions[variant.variantId][variant.name] = variant;
          });
        }
      });
    }
  }

  async loadVariants() {
    if (this.data.dataKey?.variantIds?.length > 0) {
      const db = firebase.firestore();
      const restaurantName = this.dataServiceGenerico.getRistorante();
      const variantiRef = db.collection('varianti').doc(restaurantName);

      const doc = await variantiRef.get();
      if (doc.exists) {
        const allVariants = doc.data()?.variants || [];
        this.variants = allVariants.filter(v =>
            this.data.dataKey.variantIds.includes(v.id)
        );

        // Debug log
        console.log('Variants loaded:', this.variants);
        console.log('Piatto ricevuto variants:', this.piattoRicevuto?.variants);

        // Gestisci le varianti pre-selezionate
        if (this.piattoRicevuto?.variants) {
          this.piattoRicevuto.variants.forEach(selectedVariant => {
            const variant = this.variants.find(v => v.id === selectedVariant.variantId);
            if (variant) {
              if (!this.selectedOptions[variant.id]) {
                this.selectedOptions[variant.id] = {};
              }

              const option = variant.options.find(o => o.name === selectedVariant.name);
              if (option) {
                this.selectedOptions[variant.id][option.id] = {
                  name: option.name,
                  priceModifier: option.priceModifier || 0,
                  variantId: variant.id,
                  variantTitle: variant.name,
                  quantity: selectedVariant.quantity || 1
                };
              }
            }
          });
        }

        // Forza l'aggiornamento della vista
        this.cdRef.detectChanges();
      }
    }
  }

  getBasePrice(): number {
    const piatto = this.data.dataKey;
    if (!piatto?.priceListPrices || !this.selectedPriceList) {
      return +piatto.price || 0;
    }

    let priceListType = this.currentOrder?.selectedPriceListType || 'counter';
    if (this.currentOrder?.displayName) {
      const name = this.currentOrder.displayName.toLowerCase();
      if( name.startsWith('tavolo') || name.startsWith('tav.')){
        priceListType = 'table';
      }
    }
    const priceData = piatto.priceListPrices[this.selectedPriceList][priceListType];

    return priceData?.price || +piatto.price || 0;
  }

  getTotalPrice(): number {
    const basePrice = this.getBasePrice();
    let modifiersTotal = 0;

    Object.values(this.selectedOptions).forEach(variantOptions => {
      Object.values(variantOptions).forEach((option: SelectedOption) => {
        modifiersTotal += (option.priceModifier || 0) * (option.quantity || 1);
      });
    });

    return basePrice + modifiersTotal;
  }

  selectVariant(index: number) {
    if (index >= 0 && index < this.variants.length) {
      this.currentVariantIndex = index;
      this.cdRef.detectChanges();
    }
  }

  isOptionSelected(option: VariantOption, variant: Variant): boolean {
    return !!(this.selectedOptions[variant.id] &&
        this.selectedOptions[variant.id][option.id]);
  }

  getOptionCount(option: VariantOption, variant: Variant): number {
    return this.selectedOptions[variant.id]?.[option.id]?.quantity || 0;
  }

  getSelectedCount(variantId: string): number {
    return Object.keys(this.selectedOptions[variantId] || {}).length;
  }

  toggleOption(option: VariantOption, variant: Variant) {
    if (!this.selectedOptions[variant.id]) {
      this.selectedOptions[variant.id] = {};
    }

    const variantOptions = this.selectedOptions[variant.id];
    const currentSelection = variantOptions[option.id];

    // Se la selezione multipla è disabilitata, deseleziona tutte le altre opzioni
    if (variant.maxSelections === 1) {
      // Rimuovi tutte le altre opzioni per questa variante
      Object.keys(variantOptions).forEach(key => {
        delete variantOptions[key];
      });
    }

    if (currentSelection) {
      // Se la selezione è già presente e le selezioni multiple sono consentite
      if (variant.maxSelections > 1) {
        if (currentSelection.quantity! > 1) {
          currentSelection.quantity!--;
        } else {
          delete variantOptions[option.id];
        }
      } else {
        // Per selezione singola, rimuovi l'opzione
        delete variantOptions[option.id];
      }
    } else {
      // Aggiungi nuova selezione
      const currentCount = Object.keys(variantOptions).length;
      if (currentCount < variant.maxSelections) {
        variantOptions[option.id] = {
          name: option.name,
          priceModifier: option.priceModifier || 0,
          variantId: variant.id,
          variantTitle: variant.name,
          quantity: 1
        };

        // Se è l'ultima selezione consentita, passa alla prossima variante
        if (currentCount + 1 === variant.maxSelections &&
            this.currentVariantIndex < this.variants.length - 1) {
          setTimeout(() => this.selectVariant(this.currentVariantIndex + 1), 300);
        }
      }
    }

    this.cdRef.detectChanges();
  }

  isValid(): boolean {
    return this.variants.every(variant => {
      const selectedCount = this.getSelectedCount(variant.id);
      return !variant.isRequired || selectedCount > 0;
    });
  }


  addToOrder() {
    if (!this.isValid() || !this.currentOrder) {
      return;
    }

    const piatto = this.data.dataKey;
    const selectedVariants = this.getSelectedVariantsArray();
    const basePrice = this.getBasePrice();
    const totalPrice = this.getTotalPrice();
    const priceListType = this.currentOrder?.selectedPriceListType || 'counter';
    const vatId = piatto.priceListPrices?.[this.selectedPriceList]?.[priceListType]?.vatId || '1';

    // Determina la portata, con fallback
    const portata = this.data.portata === 4 ? 0 : (this.data.portata || 0);

    if (!this.currentOrder.carrello) {
      this.currentOrder.carrello = {};
    }

    // Trova la chiave del prodotto da modificare
    const productKeyToModify = this.findProductKeyToModify();

    if (productKeyToModify) {
      // Modalità modifica: aggiorna le varianti del prodotto esistente
      const existingProduct = this.currentOrder.carrello[productKeyToModify];

      existingProduct.variants = selectedVariants.length > 0 ? selectedVariants : this.piattoRicevuto.variants;
      existingProduct.prezzo = totalPrice;
      existingProduct.price = basePrice;
      existingProduct.vatId = vatId;
      existingProduct.portata = portata;
    } else {
      // Modalità normale: aggiungi nuovo prodotto
      const nome = piatto.title.replace(/ /g, '');
      const uniqueIdentifier = `${nome}_${Date.now()}`;

      this.currentOrder.carrello[uniqueIdentifier] = {
        ...piatto,
        quantita: 1,
        price: basePrice,
        prezzo: totalPrice,
        portata: portata,
        variants: selectedVariants,
        vatId: vatId
      };

      this.currentOrder.ordineInserimento.push(uniqueIdentifier);
    }

    try {
      // Rimuovi eventuali campi undefined
      const sanitizedOrder = JSON.parse(JSON.stringify(this.currentOrder));
      this.commonService.updateOrdine(this.currentOrder.id, sanitizedOrder);
      this.dialogRef.close();
    } catch (error) {
      console.error('Error saving order:', error);
    }
  }

// Metodo per trovare la chiave del prodotto da modificare
  private findProductKeyToModify(): string | null {
    if (this.isEditMode && this.originalProductKey) {
      return this.originalProductKey;
    }

    if (!this.currentOrder?.carrello) return null;

    const piatto = this.data.dataKey;

    return Object.entries(this.currentOrder.carrello)
        .find(([key, product]: [string, CarrelloProdotto]) => {
          // Usa optional chaining per evitare errori
          if (product.title !== piatto.title) return false;

          // Se il piatto ha varianti, confrontale
          if (this.piattoRicevuto?.variants) {
            return this.areVariantsEqual(
                this.piattoRicevuto.variants,
                product.variants || []
            );
          }

          return true;
        })?.[0] || null;
  }


}