<div class="dialog-container">

    <!-- Vista PIN -->
    <div class="pin-container" *ngIf="isPinView">
        <div class="sticky-container">
            <button mat-icon-button class="close-button" aria-label="Chiudi" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="pin-dialog">
            <div class="pin-header">
                <h2>Inserisci PIN</h2>
                <div class="subtitle">PIN richiesto per accedere alle azioni</div>
            </div>

            <div class="pin-display">
                <div class="pin-dots">
                    <div class="pin-dot" *ngFor="let dot of [0,1,2,3]"
                         [class.filled]="pin.length > dot"></div>
                </div>
                <div *ngIf="showPinError" class="error-message">
                    PIN non corretto
                </div>
            </div>

            <div class="pin-keypad">
                <div class="keypad-row" *ngFor="let row of keypadRows">
                    <button class="keypad-button"
                            *ngFor="let key of row"
                            (click)="addPinDigit(key)"
                            [disabled]="key === null">
                        {{key !== null ? key : ''}}
                    </button>
                </div>

                <div class="keypad-row">
                    <button class="keypad-button cancel-button" (click)="closeDialog()">
                        Annulla
                    </button>
                    <button class="keypad-button" (click)="addPinDigit(0)">0</button>
                    <button class="keypad-button backspace-button" (click)="removePinDigit()">
                        <mat-icon>backspace</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Vista Principale (dopo autenticazione) -->
    <div *ngIf="!isPinView && isAuthorized">
        <div class="sticky-container">
            <button mat-icon-button class="close-button" aria-label="Chiudi" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <!-- Sezione Totali giornalieri -->
        <div class="sezione-totali" *ngIf="showTotalsSection">
            <h2 class="section-title">Totali Giornalieri</h2>

            <div class="totals-table">
                <div class="total-row">
                    <div class="total-label">Totale Preconti</div>
                    <div class="total-value">{{formatCurrency(totalPreconti)}}</div>
                </div>

                <div class="total-row">
                    <div class="total-label">Totale Fiscale</div>
                    <div class="total-value">{{formatCurrency(totalFiscaleNoPreconti)}}</div>
                </div>
            </div>

            <div class="actions-container">
                <button mat-raised-button class="cancel-button" type="button" (click)="showTotalsSection = false">
                    <span class="button-text">Chiudi</span>
                </button>
            </div>
        </div>

        <!-- Scelta iniziale tramite due pulsanti grandi -->
        <div class="opzioni-iniziali" *ngIf="!chiusuraGiornaliera && !annullaScontrino && !showTotalsSection">
            <div class="buttons-row"  *ngIf="hasPermission('Annulla Scontrino') || hasPermission('Totali')" >
                <button mat-raised-button class="square-button" (click)="selezionaChiusuraGiornaliera()" *ngIf="hasPermission('Annulla Scontrino')">
                    <span class="button-text">Chiusura Giornaliera</span>
                </button>
                <button mat-raised-button class="square-button" (click)="selezionaAnnullaScontrino()"  *ngIf="hasPermission('Totali')">
                    <span class="button-text">Annulla Scontrino</span>
                </button>
            </div>
            <div class="buttons-row">
                <button mat-raised-button class="square-button" (click)="showTotalsSection = true"  *ngIf="hasPermission('Chiusura Giornaliera')">
                    <span class="button-text">Totali</span>
                </button>
                <button mat-raised-button class="square-button logout" (click)="navigateToImpostazioni()" *ngIf="hasAnyPermission([
      'Schermata Admin',
      'Dashboard',
      'Reportistica',
      'Anagrafica',
      'Cronologia Ordini',
      'Fidelity',
      'Impostazioni',
    ])">
                    <span class="button-text">Backoffice</span>
                </button>
            </div>
        </div>

        <!-- Conferma per la chiusura giornaliera -->
        <div class="conferma-chiusura" *ngIf="chiusuraGiornaliera">
            <p>Vuoi procedere con la chiusura giornaliera?</p>
            <div class="actions-container">
                <button mat-raised-button class="confirm-button" (click)="confermaChiusuraGiornaliera()">
                    <span class="button-text">Conferma</span>
                </button>
                <button mat-raised-button class="cancel-button" (click)="annullaOperazione()">
                    <span class="button-text">Annulla</span>
                </button>
            </div>
        </div>

        <!-- Form per annullare lo scontrino -->
        <div class="form-annulla-scontrino" *ngIf="annullaScontrino">
            <form (ngSubmit)="annullaScontrinoSubmit()" class="form-container">
                <mat-form-field>
                    <mat-label>Numero Scontrino</mat-label>
                    <input matInput [(ngModel)]="nomeScontrino" name="nomeScontrino" required />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Chiusura Giornaliera</mat-label>
                    <input matInput [(ngModel)]="chiusuraGiornalieraInput" name="chiusuraGiornaliera" required />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Data (ggmmaaaa)</mat-label>
                    <input matInput [(ngModel)]="dataInput" name="data" required pattern="\d{8}" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Seriale</mat-label>
                    <input matInput [(ngModel)]="serialeInput" name="seriale" required />
                </mat-form-field>

                <div class="actions-container">
                    <button mat-raised-button class="confirm-button" type="submit" (click)="annullaScontrinoSubmit()" >
                        <span class="button-text" >Annulla Scontrino</span>
                    </button>
                    <button mat-raised-button class="cancel-button" type="button" (click)="annullaOperazione()">
                        <span class="button-text">Annulla</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>