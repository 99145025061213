import { Injectable } from '@angular/core';
import {catchError, map, shareReplay} from "rxjs/operators";
import {from, Observable, of, Subject} from "rxjs";
import {Router} from "@angular/router";
import firebase from 'firebase/app';
import 'firebase/firestore';
import {DataServiceGenerico} from "./data-service/data.service";
declare var window: any;


interface PriceList {
  id: string;
  name: string;
  settings: {
    counter: { vatId: string };
    table: { vatId: string };
    takeaway: { vatId: string };
  };
  orderProducts?: string[];
  orderCategories?: string[];
}

interface CachedImage {
  path: string;
  lastUsed: number;
}

@Injectable({
  providedIn: 'root'
})
export class IpcService {
  private configData: any = null;

  private pagerRemovedSubject = new Subject<{pagerId: string, timestamp: string}>();
  pagerRemoved$ = this.pagerRemovedSubject.asObservable();

  // Cache system for images
  private imageCache: Map<string, string> = new Map<string, string>();
  private imageCacheObservable: Map<string, Observable<string>> = new Map<string, Observable<string>>();

  // Cache cleanup parameters
  private readonly CACHE_MAX_SIZE = 50; // Maximum number of images to keep in cache
  private readonly CACHE_CLEANUP_INTERVAL = 300000; // Cleanup every 5 minutes (in ms)
  private cacheCleanupTimer: any;

  constructor(private router: Router, private dataService: DataServiceGenerico) {
    this.loadConfig();
    this.setupCacheCleanup();

    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');

      // Ascolta gli eventi di rimozione pager
      ipcRenderer.on('pager-removed', (_, data) => {
        console.log('Pager removed from base:', data);
        this.pagerRemovedSubject.next(data);
      });
    }
  }

  // Set up periodic cache cleanup
  private setupCacheCleanup() {
    this.cacheCleanupTimer = setInterval(() => {
      this.cleanupImageCache();
    }, this.CACHE_CLEANUP_INTERVAL);
  }

  // Clean up image cache when it exceeds maximum size
  private cleanupImageCache() {
    if (this.imageCache.size <= this.CACHE_MAX_SIZE) return;

    // Convert cache to array for sorting
    const cacheEntries: [string, string][] = Array.from(this.imageCache.entries());

    // Sort by last used time (we would need to track this separately)
    // For now, we'll just remove the oldest entries (first added)
    const entriesToRemove = cacheEntries.slice(0, cacheEntries.length - this.CACHE_MAX_SIZE);

    entriesToRemove.forEach(([key]) => {
      this.imageCache.delete(key);
      this.imageCacheObservable.delete(key);
    });

    console.log(`Cleaned up image cache: removed ${entriesToRemove.length} images`);
  }

  callPager(pagerId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (window && window.require) {
        const { ipcRenderer } = window.require('electron');

        // Invia la richiesta di chiamata pager
        ipcRenderer.send('call-pager', pagerId);

        // Gestisci la risposta
        ipcRenderer.once('call-pager-response', (_, response) => {
          if (response.success) {
            resolve(true);
          } else {
            reject(new Error(response.error));
          }
        });
      } else {
        reject(new Error('IPC non disponibile'));
      }
    });
  }


  printReceipt(stringa, cassetto, tipostampante = 'epson') {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      console.log('asdf rch', tipostampante);
      ipcRenderer.send('print-receipt', stringa, cassetto, tipostampante);
    }
  }

  printComanda(stringa, ipStampanteComanda) {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('print-comanda', stringa, ipStampanteComanda);
    }
  }

  printPreconto(stringa,  ipStampanteComanda) {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('print-preconto', stringa, ipStampanteComanda);
    }
  }

  PrintFiscalReceipt(xmlString: string, tipoStampante: string): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      console.log('print-fiscal-receipt', xmlString, tipoStampante);
      ipcRenderer.send('print-fiscal-receipt', xmlString, tipoStampante);  // Send XML string to main process
    }
  }

  private readonly IMAGE_NAMES = [
    'icons8-back-arrow-96.png',
    'icons8-drawer-100.png',
    'icons8-loyalty-90.png',
    'icons8-delete-96.png',
    'icons8-cash-100.png',
    'search.png',
    'icons8-add-90.png',
    'icons8-minus-90.png',
    'icons8-radio-button-empty-96.png',
    'icons8-radio-button-96.png',
    'icons8-heart-100 (1).png'
  ];

  // Optimized image path retrieval with caching
  async getImagePath2(url: string): Promise<string> {
    // Return cached value if it exists
    if (this.imageCache.has(url)) {
      return this.imageCache.get(url)!;
    }

    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      try {
        const fileName = this.getFileNameFromUrl(url);
        if (this.IMAGE_NAMES.includes(fileName)) {
          const localPath = await ipcRenderer.invoke('get-local-image-path', fileName);
          const formattedPath = `menuImages/${localPath}`;
          // Store in cache
          this.imageCache.set(url, formattedPath);
          return formattedPath;
        } else {
          const localPath = await ipcRenderer.invoke('get-image-path', url);
          let formattedPath: string;
          if (localPath.startsWith('file://')) {
            // Rimuovi eventuali spazi extra e assicurati che il percorso sia formattato correttamente
            formattedPath = encodeURI(localPath.trim());
          } else {
            // Se non inizia con 'file://', aggiungilo e formatta correttamente
            formattedPath = encodeURI(`menuImages/${localPath.trim()}`);
          }
          // Store in cache
          this.imageCache.set(url, formattedPath);
          return formattedPath;
        }
      } catch (error) {
        console.error('Errore nel recupero del percorso dell\'immagine:', error);
        return url; // Fallback all'URL originale in caso di errore
      }
    } else {
      return url; // Se non è un'app Electron, usa l'URL originale
    }
  }

  getImagePath(url: string): Observable<string> {
    // Return cached observable if it exists
    if (this.imageCacheObservable.has(url)) {
      return this.imageCacheObservable.get(url)!;
    }

    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      const fileName = this.getFileNameFromUrl(url);

      // Create and cache the observable
      const observable = ipcRenderer.invoke('get-image-path', url).pipe(
          map(localPath => localPath),
          catchError(() => from([''])),
          // shareReplay ensures that multiple subscribers get the same result
          shareReplay(1)
      );

      this.imageCacheObservable.set(url, observable);
      return observable;
    }

    return of(''); // Default return
  }

  async getLocalImage(imagePath: string): Promise<string> {
    // Return cached value if it exists
    if (this.imageCache.has(imagePath)) {
      return this.imageCache.get(imagePath)!;
    }

    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        const base64Image = await ipcRenderer.invoke('GET_LOCAL_IMAGE', imagePath);
        const dataUrl = `data:image/jpeg;base64,${base64Image}`;

        // Store in cache
        this.imageCache.set(imagePath, dataUrl);
        return dataUrl;
      } catch (error) {
        console.error('Errore nel recuperare l\'immagine locale:', error);
        return imagePath;
      }
    } else {
      console.warn('Questa funzione è disponibile solo nell\'app Electron');
      return imagePath;
    }
  }

  private getFileNameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0];
  }

  async getSelectedPriceList(): Promise<string> {
    // First try to get from Electron if available
    if (window && window.require) {
      try {
        const { ipcRenderer } = window.require('electron');
        const priceList = await ipcRenderer.invoke('get-selected-pricelist');
        if (priceList) {
          return priceList;
        }
      } catch (error) {
        console.warn('Error getting price list from Electron:', error);
      }
    }

    // If not in Electron or Electron failed, get first price list for restaurant
    try {
      const db = firebase.firestore();
      const restaurantId = await this.dataService.getCurrentRestaurantId();

      if (!restaurantId) {
        console.warn('No restaurant ID available');
        return '';
      }

      const priceListsDoc = await db
          .collection('price_lists')
          .doc(restaurantId)
          .get();

      if (priceListsDoc.exists) {
        const data = priceListsDoc.data();
        const priceLists: PriceList[] = data?.priceLists || [];

        if (priceLists.length > 0) {
          // Return the ID of the first price list
          return priceLists[0].id;
        }
      }

      console.warn('No price lists found for restaurant');
      return '';

    } catch (error) {
      console.error('Error fetching first price list:', error);
      return '';
    }
  }


  saveSelectedPriceList(priceListId: string): void {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('save-selected-pricelist', priceListId);
    }
  }

  async loadConfig(): Promise<void> {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      try {
        this.configData = await ipcRenderer.invoke('load-config');

        // Check if we're in cassa-ordini but config is set to cucina
        const workstationName = this.configData.workstationName?.toLowerCase();
        if (workstationName?.includes('cucina')) {
          const currentPath = window.location.pathname;
          if (currentPath.includes('cassa-ordini') || currentPath.includes('cassa/order') || currentPath.includes('')) {
            this.router.navigateByUrl('cucina#nonav');
          }
        }
      } catch (error) {
        console.error('Error loading config:', error);
      }
    }
  }

  getConfig() {
    return this.configData;
  }

  // Clear image cache when the service is destroyed
  ngOnDestroy() {
    if (this.cacheCleanupTimer) {
      clearInterval(this.cacheCleanupTimer);
    }
    this.imageCache.clear();
    this.imageCacheObservable.clear();
  }

  updateSecondaryDisplay(orderData: any) {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');

      const formattedOrder = {
        items: this.formatOrderItems(orderData?.carrello || {}),
        total: this.calculateTotal(orderData?.carrello || {}),
        orderNumber: orderData?.ordine || '',
        displayName: orderData?.displayName || '',
        status: orderData?.statoPagato
      };

      ipcRenderer.send('update-customer-display', formattedOrder);
    }
  }

  /**
   * Formatta gli elementi del carrello per il display secondario
   */
  private formatOrderItems(carrello: any) {
    return Object.entries(carrello).map(([key, item]: [string, any]) => ({
      name: item.title,
      quantity: item.quantita,
      price: item.prezzo || item.price,
      variants: item.variants || [],
      notes: item.note,
      category: item.category
    }));
  }

  /**
   * Calcola il totale dell'ordine
   */
  private calculateTotal(carrello: any): number {
    return <number>Object.values(carrello).reduce((total: number, item: any) => {
      const price = item.prezzo || item.price || 0;
      return total + (price * item.quantita);
    }, 0);
  }

  /**
   * Apre il display secondario su un altro schermo
   */
  openSecondaryDisplay() {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('open-secondary-display');
    }
  }

  /**
   * Chiude il display secondario
   */
  closeSecondaryDisplay() {
    if (window && window.require) {
      const { ipcRenderer } = window.require('electron');
      ipcRenderer.send('close-secondary-display');
    }
  }
}