

<div class="row" style="width: 100% !important; padding-top: 20px">
  <div class="column">

    <!--div class="row" style="float: left; width: 100%; height: 60px; border-radius: 30px; margin-left: 10px; background-color: rgba(181,181,181,0.2); border: 1px solid #e3e2e2; padding: 5px ">
      <div style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: white; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 0? 'white': 'transparent'" (click)="sezioneNovita = 0">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Panoramica</span>
      </div>
      <div style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 1? 'white': 'transparent'" (click)="sezioneNovita = 1">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Costi Operativi</span>

      </div>
      <div style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 2? 'white': 'transparent'" (click)="sezioneNovita = 2">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Inventario</span>

      </div>
      <div style="float: left; cursor: pointer; height: 100%; width: 25%; border-radius: 25px; background-color: transparent; text-align: center; line-height: 60px; font-weight: 500" [style.background-color]="sezioneNovita == 3? 'white': 'transparent'" (click)="sezioneNovita = 3">
        <span style="background-color: transparent; font-size: x-large; width: 100%; display: block">Operatività</span>
      </div>
    </div-->



    <fieldset style=" min-width: 99% !important; margin-left: 1%; margin-top: 20px !important; border-radius: 20px; height: 320px; border: 2px solid rgba(222,214,214,0.57); display: flex; background-color: #fdfae9">
      <legend style="font-weight: 600; font-size: xx-large"> Riepilogo </legend>

      <div class="chart-container" *ngIf="pieChart !== {}" style="display: flex">
        <canvas [id]="idPieChart" style="max-width: 45% !important; max-height: 100% !important; margin-top: 30px; float: left"></canvas>
        <canvas [id]="idChart" style="max-width: 55% !important; max-height: 100% !important; float: left"></canvas>

      </div>

    </fieldset>

    <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 20px">
      <div style="width: 24.25%; margin-right: 1%;">
        <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"

                       [nome]="'Seleziona Periodo'"
                       altezza="45"
                       [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7')] | async"
        >
        </categorieCard>
      </div>
      <div style="width: 24.25%;">
        <categorieCard class="col-12 col-md-12 menuItem" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                       [nome]="'Esportazione'"
                       altezza="45"
                       [link]="immagini[findPropertyInString('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-export-100.png?alt=media&token=ffe66f30-afb0-4cca-90d2-10135785c4d6')] | async"
                       [border]="'#2196F3'"
        >

        </categorieCard>
      </div>

    </div>

    <div class='table-responsive' style="padding-bottom: 20px; margin-top: 20px; margin-left: 1%; width: 100%">

      <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc"  [dataSource]="dataSource" class="mat-elevation-z8" style="width: 101%; overflow-y: scroll; display: table !important; border-radius: 20px; border: 1px solid #e3e2e2">

        <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
          <mat-header-cell *matHeaderCellDef>{{ column.field == 'prezzo'? 'INCASSI': column.field.toUpperCase()  }}
            <mat-menu #menu>
              <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
              <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
            </mat-menu>

          </mat-header-cell>
          <mat-cell *matCellDef="let row, let i = index">
            <span> {{ row[column.field] }}</span>
          </mat-cell>
        </ng-container>

        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <mat-cell colspan="999" *matCellDef="let group">
            <mat-icon *ngIf="group.expanded"></mat-icon>
            <mat-icon *ngIf="!group.expanded"></mat-icon>
            <strong>{{group[groupByColumns[group.level-1]]}}</strong>
            <strong style=" margin-right: 20px; margin-left: auto; padding: 10px;"> {{totali[group.categoria]}} </strong>
          </mat-cell>
        </ng-container>

        <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

      </mat-table>

    </div>
  </div>

</div>
