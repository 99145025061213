import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  OnDestroy,
  Optional,
  SimpleChanges
} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AppRoutingModule} from '../../../router.module';
import {UserDataService} from '../../../shared/services/firestore/userData.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {AggiuntaPiatto} from "../../aggiunta-piatto/aggiunta-piatto";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "../../../shared/services/auth.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.html',
  styleUrls: ['./impostazioni.css']
})
export class Impostazioni implements OnInit, OnDestroy {
  pagina = 1;
  dragPosition = {x: 253, y: 244};
  dragPosition2 = {x: 253, y: 244};
  form: FormGroup;

  // Proprietà per gestire i permessi
  permessiRistorante: string[] = [];
  permessiUnsubscribe: () => void;

  // Mapping delle pagine con i relativi permessi richiesti
  private paginaPermessiMap = {
    '1': 'Impostazioni Dati aziendali',
    '2': 'Impostazioni Tavoli',
    '11': 'Impostazioni Stampanti',
    '12': 'Impostazioni Immagini Pubblicitarie',
    '13': 'Impostazioni Totem',
    '4': 'Impostazioni Personale',
    '6': 'Impostazioni Fidelity',
    '10': 'Impostazioni Ristoranti',
    '14': 'Impostazioni Notifiche',
    '15': 'Impostazioni Fatturato'
  };

  constructor(
      private router: Router,
      private userService: UserDataService,
      private _location: Location,
      private modalService: NgbModal,
      public dialog: MatDialog,
      private ipcService: IpcService,
      private posTransactionService: PosTransactionService,
      private fb: FormBuilder,
      private authService: AuthService,
      private dataServiceGenerico: DataServiceGenerico,
      private cdr: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      // ... definizione dei controlli del form
    });
  }

  // Metodo per controllare se l'utente ha il permesso per una pagina
  hasPermessoForPagina(paginaId: number): boolean {

    if(this.permessiRistorante.length == 0)
    {
      return true;
    }
    const paginaIdStr = paginaId.toString();
    const permessoRichiesto = this.paginaPermessiMap[paginaIdStr];

    // Se non è definito un permesso per la pagina, permettiamo l'accesso
    if (!permessoRichiesto) return true;

    // Controlla se il permesso esiste nell'array dei permessi
    return this.permessiRistorante.includes(permessoRichiesto);
  }

  // Gestione della cache dei permessi
  private getPermessiFromCache(ristoranteId: string): string[] | null {
    const cachedData = localStorage.getItem('impostazioni_permessi_cache');

    if (!cachedData) return null;

    const cacheItem = JSON.parse(cachedData);

    // Verifica che i dati in cache siano per il ristorante corrente
    if (cacheItem.ristoranteId !== ristoranteId) return null;

    // Verifica della validità temporale (30 minuti)
    const cacheDuration = 30 * 60 * 1000; // 30 minuti in millisecondi
    const now = new Date().getTime();
    if (now - cacheItem.timestamp > cacheDuration) {
      localStorage.removeItem('impostazioni_permessi_cache');
      return null;
    }

    return cacheItem.permessi;
  }

  private savePermessiToCache(ristoranteId: string, permessi: string[]): void {
    const cacheItem = {
      ristoranteId,
      permessi,
      timestamp: new Date().getTime()
    };
    localStorage.setItem('impostazioni_permessi_cache', JSON.stringify(cacheItem));
  }

  // Carica i permessi dal database
  private loadPermessi(ristoranteId: string): void {
    // Prima controlla la cache
    const cachedPermessi = this.getPermessiFromCache(ristoranteId);

    if (cachedPermessi) {
      console.log('Usando permessi della cache per le impostazioni');
      this.permessiRistorante = cachedPermessi;

      // Assicuriamoci di visualizzare una pagina per cui abbiamo i permessi
      this.verificaPaginaSelezionata();
      //return;
    }


    // Se non ci sono dati in cache, carica da Firestore
    const db = firebase.firestore();

    // Cancella il vecchio listener se esiste
    if (this.permessiUnsubscribe) {
      this.permessiUnsubscribe();
    }

    // Crea un nuovo listener
    this.permessiUnsubscribe = db.collection('ristoranti').doc(ristoranteId)
        .onSnapshot((doc) => {
          if (doc.exists && doc.data().permessi) {
            const permessi = doc.data().permessi;
            this.permessiRistorante = permessi;
            this.savePermessiToCache(ristoranteId, permessi);
            console.log('Permessi impostazioni aggiornati:', permessi);

            // Verifica se la pagina corrente è ancora valida con i nuovi permessi
            this.verificaPaginaSelezionata();
            this.cdr.detectChanges();
          } else {
            this.permessiRistorante = [];
            console.log('Nessun permesso trovato per questo ristorante');

            // Reimposta alla prima pagina disponibile
            this.verificaPaginaSelezionata();
            this.cdr.detectChanges();
          }
        }, (error) => {
          console.error('Errore nel recupero dei permessi:', error);
          this.permessiRistorante = [];
        });
  }

  // Verifica che la pagina selezionata abbia i permessi necessari
  verificaPaginaSelezionata(): void {
    // Se la pagina corrente non ha i permessi necessari, trova la prima disponibile
    if (!this.hasPermessoForPagina(this.pagina)) {
      // Cerca la prima pagina con permessi
      const paginaIds = Object.keys(this.paginaPermessiMap);

      let paginaTrovata = false;
      for (const paginaId of paginaIds) {
        if (this.hasPermessoForPagina(parseInt(paginaId))) {
          this.pagina = parseInt(paginaId);
          paginaTrovata = true;
          break;
        }
      }

      // Se non trova nessuna pagina con permessi, imposta la prima pagina
      if (!paginaTrovata) {
        this.pagina = 1; // Default
      }
    }
  }

  dropped(e) {
    console.log("droppato: ", e);
    console.log("droppato 2: ", this.dragPosition);
  }

  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  ngOnInit(): void {
    // Sottoscriviti al ristorante selezionato e carica i permessi
    this.dataServiceGenerico.selectedRistorante$.subscribe(ristoranteId => {
      if (ristoranteId) {
        this.loadPermessi(ristoranteId);
      }
    });
  }

  ngOnDestroy(): void {
    // Cancella il listener dei permessi
    if (this.permessiUnsubscribe) {
      this.permessiUnsubscribe();
    }
  }
}