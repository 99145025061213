<div *ngIf="nuovoUtente == false && utenteSelezionato == undefined" style="height: 120px; margin: auto; overflow: hidden; padding-top: 50px">
    <div style="width: 400px">
        <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%; padding-bottom: 20px">Cerca l'utente per la fatturazione: </span>

        <div style="background-color: transparent; float: left; width: 400px; height: 50px !important; margin-left: 10px">
            <input type="text" [(ngModel)]="testo" (ngModelChange)="ricerca()">
        </div>
    </div>
</div>

<div style="height: 200px !important; overflow: scroll; margin-bottom: 50px" *ngIf="utentiFilter.length>0 && utenteSelezionato == undefined && testo !== ''">
    <div *ngFor="let utente of utentiFilter" >
        <div style="text-align: center; height: 50px; margin: 10px; border-radius: 20px; font-size: 20px; font-weight: 400; line-height: 50px; border: 1px solid #e3e2e2; cursor: pointer" (click)="utenteSelezionato = utente">
            {{utente.ragioneSociale}} | {{utente.partitaIVA}}
        </div>
    </div>
</div>

<div style="text-align: center; height: 50px; margin: 10px; border-radius: 20px; font-size: xx-large; line-height: 50px; border: 1px solid #e3e2e2; cursor: pointer" *ngIf="(utentiFilter.length < 1 && nuovoUtente==false) || (testo == '')" (click)="nuovoUtente = true">
    Registra Nuovo Utente
</div>

<div style="" *ngIf="nuovoUtente == true">
    <div style="height: 50px; line-height: 50px; font-weight: 700;">Registra Nuovo Utente per Fatturazione</div>
    <div style="width: 100%; height: auto; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
        <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">
            <div class="flex flex-col xl:flex-row gap-5">
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Ragione Sociale </label>
                    <input type="text" [(ngModel)]="utente.ragioneSociale">
                </div>
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Partita IVA </label>
                    <input type="text" [(ngModel)]="utente.partitaIVA">
                </div>
            </div>
            <div class="flex flex-col xl:flex-row gap-5">
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Codice Fiscale </label>
                    <input type="text" [(ngModel)]="utente.codiceFiscale">
                </div>
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Indirizzo </label>
                    <input type="text" [(ngModel)]="utente.indirizzo">
                </div>
            </div>
            <div class="flex flex-col xl:flex-row gap-5">
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> CAP </label>
                    <input type="text" [(ngModel)]="utente.cap">
                </div>
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Città </label>
                    <input type="text" [(ngModel)]="utente.citta">
                </div>
            </div>
            <div class="flex flex-col xl:flex-row gap-5">
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Provincia </label>
                    <input type="text" [(ngModel)]="utente.provincia">
                </div>
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Email </label>
                    <input type="email" [(ngModel)]="utente.email">
                </div>
            </div>
            <div class="flex flex-col xl:flex-row gap-5">
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> PEC </label>
                    <input type="email" [(ngModel)]="utente.pec">
                </div>
                <div class="flex flex-col gap-2 flex-1">
                    <label class="font-bold text-gray-700 text-heading-sm"> Codice SDI </label>
                    <input type="text" [(ngModel)]="utente.codiceSdi">
                </div>
            </div>
        </div>
        <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104); cursor: pointer;" (click)="salvaUtente()">
            Salva
        </div>
    </div>
</div>

<div *ngIf="utenteSelezionato !== undefined" style="height: 220px; margin: auto; overflow: hidden; padding-top: 50px">
    <div style="width: 500px">
    <span style="background-color: transparent; font-size: x-large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%;">
      {{utenteSelezionato.ragioneSociale}}
    </span>

        <span style="background-color: transparent; font-size: large; font-weight: 900; display: inline-block; margin-top: 20px; text-align: center; width: 100%; padding-bottom: 20px">
      P.IVA: {{utenteSelezionato.partitaIVA}} | CF: {{utenteSelezionato.codiceFiscale}}
    </span>
        <span style="background-color: transparent; font-size: medium; display: inline-block; margin-top: 10px; text-align: center; width: 100%;">
      {{utenteSelezionato.indirizzo}}, {{utenteSelezionato.cap}} {{utenteSelezionato.citta}} ({{utenteSelezionato.provincia}})
    </span>

        <span style="background-color: transparent; font-size: medium; display: inline-block; margin-top: 10px; text-align: center; width: 100%;">
      Email: {{utenteSelezionato.email}} | PEC: {{utenteSelezionato.pec}} | SDI: {{utenteSelezionato.codiceSdi}}
    </span>

        <span style="background-color: transparent; font-size: medium; display: inline-block; margin-top: 10px; text-align: center; width: 100%;">
      Telefono: {{utenteSelezionato.telefono}}
    </span>
    </div>
</div>

<div style="width: 100%; margin-top: 20px" *ngIf="utenteSelezionato !== undefined">
    <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: left; padding: 15px; color: white; background-color: rgb(188 72 94); cursor: pointer;" (click)="utenteSelezionato = undefined">
        Cambia Utente
    </div>
    <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; margin-top: 0px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104); cursor: pointer;" (click)="chiudi()">
        Conferma
    </div>
</div>