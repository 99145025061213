import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Group } from '../menu';
import { MenuPartialConfirmDialogComponent } from '../menuCronologia/dialog/partial-confirm-dialog.component';
import { InventoryItem, InventoryMovement, InventoryService } from "../../../shared/services/firestore/inventory.service";
import { ConfirmInventoryDialogComponent } from "../menuCronologia/dialog/confirm-inventory-dialog";

@Component({
  selector: 'app-menu-inventory',
  templateUrl: './menuInventory.component.html',
  styleUrls: ['./menuInventory.component.css']
})
export class MenuInventoryComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<any | Group>;
  _alldata: any[];
  displayedColumns = ['title', 'livello1', 'livello2', 'livello3', 'livello4'];
  groupByColumns: string[] = ['category'];
  operationMode: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento' = 'aggiunta';
  isLoading = false;
  errorMessage = '';
  isMigrationNeeded = false;

  // Lista dei fornitori per il filtro
  fornitori: {id: string, nome: string}[] = [];
  selectedFornitoreId: string = '';

  selectedItemForHistory: InventoryItem = null;
  isHistoryVisible = false;
  searchTerm: string = '';
  private subscriptions: Subscription[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
      private inventoryService: InventoryService,
      private cdr: ChangeDetectorRef,
      public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<any | Group>([]);
  }

  ngOnInit(): void {
    // Initialize inventory collection
    this.isLoading = true;
    this.inventoryService.initInventoryCollection()
        .then(() => this.loadInventoryData())
        .catch(error => {
          console.error('Error initializing inventory:', error);
          this.errorMessage = 'Errore durante l\'inizializzazione dell\'inventario';
          this.isLoading = false;
        });

    // Carica i fornitori per il filtro
    this.loadFornitori();

    // Subscribe to inventory data changes
    this.subscriptions.push(
        this.inventoryService.inventoryData$.subscribe(data => {
          if (data.length === 0) {
            this.isMigrationNeeded = true;
          } else {
            this.isMigrationNeeded = false;
            this._alldata = this.transformData(data);

            // Applica il filtro per fornitore se è selezionato
            if (this.selectedFornitoreId) {
              this._alldata = this.filterByFornitore(this._alldata, this.selectedFornitoreId);
            }

            this.dataSource.data = this.addGroups(this._alldata, this.groupByColumns);

            if (this.paginator && this.sort) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }

            this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
            this.dataSource.filter = performance.now().toString();

            // Update selected item if it exists
            if (this.selectedItemForHistory) {
              const updatedItem = data.find(item => item.id === this.selectedItemForHistory.id);
              if (updatedItem) {
                this.selectedItemForHistory = updatedItem;
              }
            }
          }

          this.isLoading = false;
          this.cdr.detectChanges();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // Nuovo metodo per caricare i fornitori
  loadFornitori(): void {
    this.inventoryService.getFornitoriPerFiltro().subscribe(
        fornitori => {
          this.fornitori = fornitori;
          this.cdr.detectChanges();
        },
        error => {
          console.error('Errore nel caricamento dei fornitori:', error);
        }
    );
  }

  // Nuovo metodo per filtrare gli elementi per fornitore
  filterByFornitore(items: any[], fornitoreId: string): any[] {
    if (!fornitoreId) return items;

    return items.filter(item => {
      // Per le righe di visualizzazione, controlla se il fornitore è associato
      if (!item.isEditRow && item.fornitori) {
        return item.fornitori.some(f => f.id === fornitoreId);
      }

      // Per le righe di modifica, trova la corrispondente riga di visualizzazione
      if (item.isEditRow) {
        const viewRow = items.find(r => !r.isEditRow && r.id === item.id);
        return viewRow && viewRow.fornitori &&
            viewRow.fornitori.some(f => f.id === fornitoreId);
      }

      return false;
    });
  }

  // Aggiungi questo metodo per gestire il cambio di fornitore selezionato
  onFornitoreFilterChange(fornitoreId: string): void {
    this.selectedFornitoreId = fornitoreId;

    // Riapplica i filtri ai dati correnti
    const filteredData = this.selectedFornitoreId ?
        this.filterByFornitore(this._alldata, this.selectedFornitoreId) :
        this._alldata;

    this.dataSource.data = this.addGroups(filteredData, this.groupByColumns);
    this.dataSource.filter = performance.now().toString();
    this.cdr.detectChanges();
  }

  loadInventoryData(): void {
    this.isLoading = true;
    this.errorMessage = '';

    console.log('Loading inventory data with fornitori info...');

    this.inventoryService.loadInventoryWithFornitori().subscribe(
        (items) => {
          // Data is handled by the subscription in ngOnInit
          console.log(`Loaded ${items.length} inventory items with fornitori info`);

          if (items.length > 0) {
            console.log('Checking for pending orders...');
            this.checkPendingOrders();
          } else {
            this.isLoading = false;
          }
        },
        error => {
          console.error('Error loading inventory data:', error);
          this.errorMessage = 'Errore nel caricamento dei dati: ' + (error.message || error);
          this.isLoading = false;
        },
        () => {
          // Ensure loading is set to false in complete handler
          if (!this.isLoading) {
            this.isLoading = false;
          }
        }
    );
  }

  // Nuovo metodo per verificare se ci sono ordini in sospeso senza chiedere conferma
  checkPendingOrders(): void {
    this.inventoryService.hasPendingOrders().subscribe(
        hasPending => {
          if (hasPending) {
            // Ora chiedi conferma solo se ci sono effettivamente ordini in sospeso
            this.isLoading = false;
            this.cdr.detectChanges();
          } else {
            // Non ci sono ordini in sospeso, termina il caricamento
            this.isLoading = false;
            this.cdr.detectChanges();
          }
        },
        error => {
          console.error('Error checking pending orders:', error);
          this.isLoading = false;
          this.cdr.detectChanges();
        }
    );
  }

  migrateInventoryData(): void {
    this.isLoading = true;
    this.errorMessage = '';

    console.log('Starting inventory migration process...');

    this.inventoryService.migrateInventoryData()
        .then(() => {
          console.log('Migration completed, loading inventory data');
          this.isMigrationNeeded = false;
          this.loadInventoryData();
        })
        .catch(error => {
          console.error('Error migrating inventory data:', error);
          this.errorMessage = 'Errore durante la migrazione dei dati: ' + (error.message || error);
          this.isLoading = false;

          // Try to load data anyway to update UI state
          setTimeout(() => {
            this.loadInventoryData();
          }, 1000);
        });
  }

  syncWithOrders(): void {
    this.isLoading = true;
    this.inventoryService.syncInventoryWithOrders()
        .then(() => {
          // Carica i dati ma salta la sincronizzazione ripetitiva
          this.loadInventoryData();
          this.cdr.detectChanges();
        })
        .catch(error => {
          console.error('Error syncing with orders:', error);
          this.errorMessage = 'Errore durante la sincronizzazione con gli ordini';
          this.isLoading = false;
          this.cdr.detectChanges();
        });
  }

  showHistory(item: InventoryItem): void {
    this.selectedItemForHistory = item;
    this.isHistoryVisible = true;
  }

  private transformData(items: InventoryItem[]): any[] {
    const transformedData = [];

    items.forEach(item => {
      // Calculate current quantities (using our fixed method)
      const quantityTotals = this.inventoryService.calculateCurrentQuantities(item);

      // View row with current totals
      transformedData.push({
        ...item,
        isEditRow: false,
        quantityTotals
      });

      // Edit row for the same item
      transformedData.push({
        ...item,
        isEditRow: true,
        newQuantities: new Array(item.maxLvl || 4).fill('0')
      });
    });

    return transformedData;
  }

  onOperationModeChange(mode: 'aggiunta' | 'scarico' | 'scarti' | 'trasferimento'): void {
    this.operationMode = mode;
  }

  onQuantityChange(row: any, levelIndex: number, value: string): void {
    if (row.isEditRow && row.newQuantities) {
      row.newQuantities[levelIndex] = value;
    }
  }

  async saveChanges(): Promise<void> {
    try {
      // Get edit rows with changes
      const editRows = this._alldata.filter(row =>
          row.isEditRow &&
          row.newQuantities?.some(q => parseInt(q) !== 0)
      );

      if (editRows.length === 0) {
        return;
      }

      // Prepare data for confirmation dialog
      const changedItems = editRows.map(row => {
        const currentRow = this._alldata.find(r => !r.isEditRow && r.id === row.id);
        return {
          productTitle: currentRow ? currentRow.productTitle : row.productTitle,
          quantities: row.newQuantities.map((qty, idx) => {
            if (qty && qty !== '0') {
              return `${qty} ${row.lvl[idx].um}`;
            }
            return null;
          }).filter(q => q !== null)
        };
      });

      // Open confirmation dialog
      const dialogRef = this.dialog.open(ConfirmInventoryDialogComponent, {
        width: '600px',
        disableClose: true,
        data: {
          operationType: this.operationMode,
          changedItems
        }
      });

      // Process result from dialog
      dialogRef.afterClosed().subscribe(async result => {
        if (result?.confirmed) {
          this.isLoading = true;
          this.cdr.detectChanges();

          // Process each edit row
          for (const row of editRows) {
            const currentRow = this._alldata.find(r => !r.isEditRow && r.id === row.id);
            if (!currentRow) continue;

            // Create new levels with updated quantities
            const newLevels = [...row.lvl]; // Clone the existing levels

            // Update quantities based on operation type
            for (let i = 0; i < newLevels.length; i++) {
              const currentQty = parseInt(newLevels[i].quantita || '0', 10);
              const changeQty = parseInt(row.newQuantities[i] || '0', 10);

              if (changeQty === 0) continue;

              let newQty = currentQty;

              switch (this.operationMode) {
                case 'aggiunta':
                  newQty = currentQty + changeQty;
                  break;
                case 'scarico':
                case 'scarti':
                case 'trasferimento':
                  newQty = Math.max(0, currentQty - changeQty);
                  break;
              }

              // Update the level with the new quantity
              newLevels[i] = {
                ...newLevels[i],
                quantita: newQty.toString()
              };
            }

            // Create movement record
            const movement: Omit<InventoryMovement, 'timestamp'> = {
              data: new Date().toLocaleString('it-IT', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
              }),
              quantitaPrec: JSON.stringify(currentRow.quantityTotals),
              quantitaAgg: JSON.stringify(row.newQuantities),
              lvl: row.newQuantities.map((qty, idx) => ({
                quantita: qty || '0',
                um: row.lvl[idx].um,
                barCode: row.lvl[idx].barCode
              })),
              modalita: this.operationMode,
              note: result.notes || ''
            };

            // Save the movement with the updated levels
            await this.inventoryService.addMovement(row.productId, movement, newLevels);
          }

          // Reload data after all changes
          this.loadInventoryData();
        }
      });

    } catch (error) {
      console.error('Error preparing changes:', error);
      this.errorMessage = 'Errore durante la preparazione delle modifiche';
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }
  confirmPartialOrder(): void {
    const dialogRef = this.dialog.open(MenuPartialConfirmDialogComponent, {
      width: '800px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed) {
        console.log('Conferma parziale con nome:', result.name);
        if (result.photo) {
          console.log('Foto da caricare:', result.photo.name);
        }
      }
    });
  }

  // Group handling methods (copied from original component)
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) return data;

    const groups = this.uniqueBy(
        data.map(row => {
          const result = new Group();
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }),
        JSON.stringify
    );

    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }

  uniqueBy(a, key) {
    const seen = {};
    return a.filter(item => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  groupHeaderClick(row) {
    row.expanded = !row.expanded;
    this.dataSource.filter = performance.now().toString();
  }

  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(row => {
      if (!(row instanceof Group)) return false;
      let match = true;
      this.groupByColumns.forEach(column => {
        if (!row[column] || !data[column] || row[column] !== data[column]) {
          match = false;
        }
      });
      return match;
    });

    if (groupRows.length === 0) return true;
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }

  // Aggiorna il metodo customFilterPredicate per considerare anche il filtro per fornitore
  customFilterPredicate(data: any | Group, filter: string): boolean {
    // Per le righe di gruppo
    if (data instanceof Group) {
      return data.visible;
    }

    // Per le righe di dati
    const visible = this.getDataRowVisible(data);
    if (!visible) return false;

    // Se non c'è termine di ricerca, mostra tutte le righe visibili
    if (!this.searchTerm) return true;

    // Non filtrare le righe di modifica
    if (data.isEditRow) {
      // Trova la riga di visualizzazione corrispondente
      const viewRow = this._alldata.find(r => !r.isEditRow && r.id === data.id);
      // Se la riga di visualizzazione è visibile, mostra anche la riga di modifica
      return viewRow ? this.matchesSearchTerm(viewRow) : false;
    }

    return this.matchesSearchTerm(data);
  }

  // Aggiorna il metodo matchesSearchTerm per cercare anche nei nomi dei fornitori
  private matchesSearchTerm(data: any): boolean {
    const searchableFields = [
      data.productTitle || '',
      data.category || '',
      data.productId || ''
    ];

    // Cerca nei livelli di quantità (se disponibili)
    if (data.quantityTotals) {
      Object.values(data.quantityTotals).forEach((qty: any) => {
        if (qty) searchableFields.push(String(qty));
      });
    }

    // Aggiungi i nomi dei fornitori ai campi ricercabili
    if (data.fornitori && data.fornitori.length > 0) {
      data.fornitori.forEach(f => {
        searchableFields.push(f.nome || '');
      });
    }

    // Verifica se il termine di ricerca è presente in qualsiasi campo
    return searchableFields.some(field =>
        String(field).toLowerCase().includes(this.searchTerm)
    );
  }

  // Aggiungi questo metodo alla classe
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm = filterValue.trim().toLowerCase();

    if (this.dataSource) {
      // Applica il filtro solo dopo che i gruppi sono stati creati
      this.dataSource.filter = performance.now().toString();
    }
  }

  /**
   * Sincronizza l'inventario con l'anagrafica prodotti,
   * aggiungendo i prodotti mancanti con quantità iniziale 0
   */
  syncWithRegistry(): void {
    this.isLoading = true;
    this.errorMessage = '';

    console.log('Avvio sincronizzazione con anagrafica prodotti...');

    this.inventoryService.syncWithRegistry()
        .then(() => {
          console.log('Sincronizzazione con anagrafica completata con successo');
          this.loadInventoryData();
          this.cdr.detectChanges();
        })
        .catch(error => {
          console.error('Errore durante la sincronizzazione con anagrafica:', error);
          this.errorMessage = 'Errore durante la sincronizzazione con l\'anagrafica: ' + (error.message || error);
          this.isLoading = false;
          this.cdr.detectChanges();
        });
  }
}