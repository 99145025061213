
<div style="max-height: 150px; width: 100% !important; display: flex; align-items: center; justify-content: center;">
  <img [src]=" data.dataKey? data.dataKey.image_link: linkImg" style=" aspect-ratio: 1 / 1; width: 20%;  background-color: transparent; overflow-clip-margin: content-box">
</div>
<div style="text-align: center;border-radius: 20px; font-size: xx-large; font-weight: 900; width: 600px">
  {{ data.dataKey? data.dataKey.title: 'Titolo mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: x-large; max-width: 70%; margin-left: 15%; font-weight: 500">
  {{ data.dataKey? data.dataKey.descrizione: 'Descrizione mancante' }}
</div>

<div style="text-align: center;border-radius: 20px; font-size: large; font-weight: 400">
  {{ data.dataKey? data.dataKey.price + '€': '0.00€' }}
</div>


<!--div style="margin-top: 20px; max-height: 530px !important; overflow: scroll; transition: max-height 0.5s" [style.max-height]="
       aggiungiIngredienti == true && index < 3
       ? '530px' : '0px'">
    <div (click)="selezionaGusto(piatto)" style="float: left; width: 31%; height: 250px; border-radius: 20px; background-color: white; margin-left: 1%; border: 1px solid #e3e2e2; text-align: left; margin-top: 10px; cursor: pointer" *ngFor="let piatto of extraIngredients[index]?.items"
         [style.border]="
       selezionatoInterno(piatto.title)
       ? '1px solid #9f9f9f' : '1px solid #e3e2e2'"
    >
        <img [src]="piatto.image_link" style="height: 150px; margin-top: 30px; background-color: transparent; margin-left: 10px; margin-right: 10px">

        <span style="background-color: transparent; margin-top: 10px; display: inline-block; margin-left: 20px; font-weight: 800; width: 100%">{{piatto.title}}</span>
        <span style="background-color: transparent; margin-top: 0px; display: inline-block; margin-left: 20px; font-weight: 400; font-size: small">{{piatto.prezzo}}</span>

    </div>
</div-->

<!-- Dialog content -->
<div class="row slider-container" *ngIf="variants.length > 1">
    <div *ngFor="let variant of variants; let i = index"
         class="slider"
         [style.width]="100/variants.length + '%'"
         [style.background-color]="currentVariantIndex === i ? 'rgba(181,181,181,0.2)': 'transparent'"
         (click)="selectVariant(i)">
        <span>{{variant.name}}</span>
    </div>
</div>

<!-- Contenuto della variante corrente -->
<div class="variant-content" *ngIf="variants[currentVariantIndex]" style="height: 37vh; overflow-y: auto">
    <div class="variant-header mb-4">
        <h3 class="text-lg font-semibold">{{variants[currentVariantIndex].domanda}}</h3>
        <span *ngIf="variants[currentVariantIndex].isRequired" class="required-badge">
      Obbligatorio
    </span>
    </div>

    <div class="options-grid">
        <div *ngFor="let option of variants[currentVariantIndex].options"
             class="option-card"
             [class.selected]="selectedOptions[variants[currentVariantIndex].id]?.name === option.name"
             style="display: flex; align-items: center; justify-content: center"
             (click)="selectOption(option, variants[currentVariantIndex])">
            <div class="option-content">
                <span class="option-name">{{option.name}}</span>
                <span *ngIf="option.priceModifier > 0" class="price">
                +{{option.priceModifier.toFixed(2)}}€
            </span>
            </div>
        </div>
    </div>
</div>


<div style="width: 100%; display: flex; align-items: center; justify-content: center;">
    <div (click)="updateOrdine(piattoRicevuto, currentOrder)"
         *ngIf="((primoClick == 0) && (data.dataKey? data.dataKey.menu : true) && (data.dataKey? !data.dataKey.single : true)) || (primoClick == 1) || (data.dataKey.menu == false)"
         style=" height: 50px; padding: 20px; border-radius: 20px; font-size: large;  border: 1px solid #e3e2e2; cursor: pointer; display: inline-grid; align-items: center; justify-content: center; width: 85%">
        <div style="width: 100%; text-align: center;">Aggiungi</div>
        <div style="text-align: center;width: 100%; font-size: large; color: #157315">{{(getPrezzo2()*quantita).toFixed(2)}}€</div>
    </div>

</div>


