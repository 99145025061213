import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import firebase from 'firebase/app';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from "rxjs";
import {CommonService} from "../../../shared/services/firestore/common.service";
import {DataServiceGenerico} from "../../../shared/services/data-service/data.service";
import {PaymentCashmaticService} from "../../../shared/services/payment-cashmatic.service";
import {IpcService} from "../../../shared/services/ipc.service";
import {PosTransactionService} from "../../../shared/services/pos-transaction.service";
import {TavoliService} from "../../../shared/services/tavoli.service";
import {UserDataService} from "../../../shared/services/firestore/userData.service";
import {AuthService} from "../../../shared/services/auth.service";
import {AzioniStampanteComponent} from "../../azioniStampante/azioniStampante";

@Component({
  selector: 'app-categorie',
  templateUrl: './cassa-ordini.html',
  styleUrls: ['./cassa-ordini.css']
})
export class CassaOrdini implements OnInit {

  getPrezzoPiatto(piatto)
  {
    if(piatto?.hasOwnProperty('aggiunte'))
    {
      return piatto?.prezzo + piatto?.aggiunte.length
    }
    else
    {
      return piatto?.prezzo
    }
  }

  tavoliVuoti = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]


  findTavolo(tavolo)
  {
    //console.log("this.camerieri: ", this.camerieri)
    // @ts-ignore
    if(this.camerieri !== 0) {
      let index = this.camerieri.findIndex(c => c.tavolo == tavolo)
      if (index > -1) {
        return this.camerieri[index]
      } else {
        return null
      }
    }
  }

  findIndexTavolo(tavolo) {
    let index = this.camerieri.findIndex(c => c.tavolo == tavolo);
    if(index > -1) {
      return index;
    } else {
      // Chiama aggiungiOrdine e aspetta che finisca
      this.aggiungiOrdine({numero: tavolo});
    }
  }

  calcolaTotaleOrdine2(ordine: any) {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    //console.log("receive calcola totale: ", ordine)

    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if(ordine !== null) {
        if (this.camerieri[this.getIndexOrdine(ordine)].hasOwnProperty('carrello')) {
          this.getProperties(this.camerieri[this.getIndexOrdine(ordine)]?.carrello).forEach(piatto => {
            //console.log('piatto', piatto);
            this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita;

            let prezzoPiatto = this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].prezzo;
            this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita);
            //console.log("tot nuovo: ", this.tot)
          });
        }
      }

    }
    return this.tot.toFixed(2)
  }


  portata = 0;
  IMAGE_LINKS = [
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-back-arrow-96.png?alt=media&token=dd7434c9-74f8-426a-8118-175603db884a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-drawer-100.png?alt=media&token=1ca2af49-ad1b-4415-a2f7-642b3131d9db',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FnavBar%2Ficons8-loyalty-90.png?alt=media&token=bf60b26a-407d-41cc-9351-229c8e608c04',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-cash-100.png?alt=media&token=63406757-66a9-40d2-a7ac-645ea432260c',
    'https://img.icons8.com/sf-black/64/000000/search.png',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-minus-90.png?alt=media&token=4caf0ec0-c55f-42aa-afe0-83a66b4f97bd',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-empty-96.png?alt=media&token=55f2a274-4848-457d-96ab-697e620b22e5',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-radio-button-96.png?alt=media&token=860666ad-6d37-4457-be44-18b5b442d2ae',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-heart-100%20(1).png?alt=media&token=923b3ec1-6439-4656-947b-be258e03d9f6'
  ]


  private readonly IMAGE_NAMES = [
  ];


  imagePaths: { [key: string]: Promise<string> } = {};

  imagePathsGeneric: { [key: string]: Promise<string> } = {};

  private initializeImagePaths() {
    // Supponiamo che 'items' sia il tuo array di elementi del menu

    this.menu.forEach(categoria => {
      console.log("categoria offline: ", categoria)
      categoria.menu.forEach(item => {
        console.log("item offline: ", item)
        this.imagePaths[item.title] = this.ipcService.getLocalImage(item.image_link);
      });
      console.log("itemPaths offline: ", this.imagePaths)

    })
  }

  /*
  initializeImagePaths2() {
    // Supponiamo che 'items' sia il tuo array di elementi del menu

    this.IMAGE_NAMES.forEach((categoria, i) => {
      this.imagePathsGeneric[categoria] = this.ipcService.getLocalImage2(categoria);
    })

    this.dataServiceGenerico.setImmagini(this.imagePathsGeneric)

    console.log("imagepaths offline: ", this.imagePaths)
  }

   */


  trackByFn(index: number, item: any): string {
    return item.title; // Assumi che ogni item abbia un id univoco
  }



  getQuantita(prodotto: any) {
    if ((this.camerieri[this.schermataIndex]?.carrello !== undefined) && (this.camerieri[this.schermataIndex]?.carrello.hasOwnProperty(prodotto.title.replace(/ /g, '')))) {
      return this.camerieri[this.schermataIndex]?.carrello[prodotto.title.replace(/ /g, '')].quantita;
    } else {
      return 0
    }
  }


  categorie = []
  link = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/gelati%2FIMG_0288-removebg-preview.png?alt=media&token=6fbce9c0-9667-4978-bb41-2b835c34cf85'

  width() {
    return (100 / (this.categorie.length + 1))
  }

  soldiInseriti: number = 0;
  paymentMessage: string = '';
  private subscriptions: Subscription[] = [];
  memoLink = 'https://cdn.icon-icons.com/icons2/1875/PNG/512/fileimport_120157.png';
  fonteSelected = 'Cassa';
  pagatoSelected = 'Non Pagato';


  lista = 1;
  pagamentoCartaAperto = 0;
  cashmaticAperto = 0;


  handleEvent(event: number, piatto: any, piatto2?: string) {
    if(this.camerieri[this.getIndex()]?.statoPagato !== undefined? this.camerieri[this.getIndex()].statoPagato != 0: true) {
      console.log("handle event: ", event);
      let nomePiatto = ''
      /*
      if (piatto.hasOwnProperty('dimensioneMenu')) {
        nomePiatto = piatto['title'].replace(/ /g, '') + piatto['contorno'].title.replace(/ /g, '') + piatto['bibita'].title.replace(/ /g, '') + piatto['salsa'].title.replace(/ /g, '') + piatto['dimensioneMenu']
      } else {
        nomePiatto = piatto.title.replace(/ /g, '')
      }

       */
      console.log("")
      if(piatto2)
      {
        nomePiatto = piatto2
      }
      this.camerieri[this.getIndex()].carrello[nomePiatto].quantita = event
      if (this.camerieri[this.getIndex()].carrello[nomePiatto].quantita == 0) {
        delete this.camerieri[this.getIndex()].carrello[nomePiatto]
        this.camerieri[this.getIndex()]['ordineInserimento'] = this.camerieri[this.getIndex()]['ordineInserimento'].filter(item => item !== nomePiatto);
      }
    }
    this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, this.camerieri[this.getIndex()].carrello)
  }

  save() {
    if (this.camerieri[this.schermataIndex].carrello !== undefined) {
      this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, this.camerieri[this.schermataIndex].carrello)

    }
  }

  //fonte
  //0 = app
  //1 = kiosk
  //2 = cassa
  //3 = tutto

  //pagato
  //0 = pagato
  //1 = non pagato
  //2 = tutto
  //3 = completato

  filtroSource = 2;
  filtroStato = 1;


  filtraApp() {
    this.filtroSource = 0;
    this.retriveCarrello()
    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 0);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);
  }

  filtraChiosco() {
    this.filtroSource = 1;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 1);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }

  filtraCassa() {
    this.filtroSource = 2;
    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.fonte === 2);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

  }

  filtraTutto() {
    this.filtroSource = 3;
    this.retriveCarrello()

    //this.ordini = this.filtroStato == 2? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.statoPagato === this.filtroStato);
  }

  filtraPagato() {

    this.filtroStato = 0;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 0);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraNonPagato() {
    this.filtroStato = 1;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraCompletato() {
    this.filtroStato = 3;

    this.retriveCarrello()

    //this.ordini = this.ordiniSave.filter(ordine => ordine.statoPagato === 1);
    //this.ordini = this.filtroSource == 3? this.ordini: this.ordini.filter(ordine => ordine.fonte === this.filtroStato);

  }

  filtraStatoTutto() {
    this.filtroStato = 2;
    this.retriveCarrello()

    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
  }

  filter = '';

  filtraCerca() {
    //this.ordini = this.filtroSource == 3? this.ordiniSave: this.ordiniSave.filter(ordine => ordine.fonte === this.filtroStato);
    //this.ordini = this.filtroStato == 2? this.ordini: this.ordini.filter(ordine => ordine.statoPagato === this.filtroStato);

    //this.ordini = this.ordini.filter(data =>  Object.keys(data).some(k => this.filter.trim().split(" ").some(el => typeof data[k] !== 'string' && typeof data[k] !== 'number'? Object.keys(data[k]).some(property => data[k][property].toString().toLowerCase().includes(el.toLowerCase())) : data[k]?.toString().toLowerCase().includes(el.toLowerCase()))))
  }


  menuBello = []

  retriveMenu(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataServiceGenerico.getRistorante();
    const menuRef = db.collection('menu_test').doc(restaurantName);

    menuRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        this.menuBello = data.items; // Recupero e assegno i dati del menu

        this.menuBello = this.menuBello.filter(elemento => elemento.hasOwnProperty('image_link') && elemento.image_link?.length > 0)
        this.categorie = this.filterUniqueCategories(this.menuBello); // Filtro le categorie uniche

        console.log('Menu recuperato da Firebase:', this.menuBello);
        console.log('Menu recuperato da Firebasen 2:', this.categorie);

        this.menu = []

        this.categorie.forEach(categoria => this.menu.push({
          testo: categoria,
          menu: []
        }))

        this.menu.forEach((categoria, i) => {
              this.menuBello.forEach((prodotto) => {
                if (categoria.testo == prodotto.category) {
                  this.menu[i].menu.push(prodotto)
                }
              })
            }
        )

        this.initializeImagePaths()

        console.log('Menu recuperato da Firebasen 3:', this.menu);


      } else {
        console.log('Nessun documento trovato su Firebase.');
      }
    }).catch(error => {
      console.error('Errore nel recupero del menu da Firebase:', error);
    });
  }


  filterUniqueCategories(menuItems) {
    const seenCategories = new Set();
    return menuItems.filter(item => {
      if (seenCategories.has(item.category)) {
        return false;
      } else {
        seenCategories.add(item.category);
        return true;
      }
    }).map(x => x.category).filter(function (element) {
      return element !== undefined;
    });
  }

  calcolaPaddingBottom(categoria: any) {
    let calcolo = categoria / 3;
    calcolo = Math.ceil(calcolo)
    //console.log("calcolo: ", calcolo)
    return calcolo * 360
  }

  sezione = -1;


  modalOptions = {
    backdrop: true, keyboard: false, focus: true, show: false, scroll: true,
    ignoreBackdropClick: false, class: 'app-modal-window', containerClass: '', animated: true, data: {}
  };


  display = 'none';
  schermata = 0;
  schermataIndex;


  tastierino = [7, 8, 9, 4, 5, 6, 1, 2, 3]
  closeResult = '';
  prezzoPiatto = '0';

  temp = [
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Stato Ordine',
      messaggio2: 'In Corso',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = 0;

  percentuale2 = 10;

  user;

  ristorante;

  ifCarrello;

  carrelloTmp;

  carrello;

  numeroElementi = 0;

  tot = 0;

  isOver = false;


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a',
      nome: 'Pizza',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
      nome: 'Burger',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpinched-fingers_light-skin-tone_1f90c-1f3fb_1f3fb.png?alt=media&token=2b9874ce-8e1f-4ece-8ab3-ce5492e3972e',
      nome: 'Italiano',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fsushi_1f363.png?alt=media&token=4744cf56-186d-42a8-ad9f-89404b7d0578',
      nome: 'Sushi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fstuffed-flatbread_1f959.png?alt=media&token=b31a6859-6221-4a19-80c5-572217c3334d',
      nome: 'Poke',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    }

  ];

  ristoranti: { nome: string, descrizione: string, link: string }[] = [
    {
      nome: 'menu',
      descrizione: 'Available',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    },
    {
      nome: 'menu',
      descrizione: 'Ready',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    },
    {
      nome: 'menu',
      descrizione: 'Started',
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f'
    }
  ];

  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fbento-box_1f371_adobespark.jpeg?alt=media&token=2ce95e68-dbef-43c6-bda0-be3eb468bdf1',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fburrito_1f32f_adobespark.jpeg?alt=media&token=0eb53ba3-ac6d-4dca-87dc-9c1f11874c0d',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcocktail-glass_1f378_adobespark.jpeg?alt=media&token=e32f1177-0be0-40fb-b7fd-84c8df59cde2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fcut-of-meat_1f969_adobespark.jpeg?alt=media&token=979d5ab5-481f-4cf4-9d0b-a78ba336ad51',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fdoughnut_1f369_adobespark.jpeg?alt=media&token=1466db98-0ee9-49d5-aa69-ba5a347904a0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Ffrench-fries_1f35f_adobespark.jpeg?alt=media&token=c9e0fcd3-c9dd-4427-b8f7-3ac3c5a85358',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fgreen-salad_1f957_adobespark.jpeg?alt=media&token=2067325f-1ada-498f-99de-ca63f0b7cfac',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhamburger_1f354_adobespark.jpeg?alt=media&token=99c3dd2d-3c90-4197-aee3-eee89b2d603a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-beverage_2615_adobespark.jpeg?alt=media&token=16bf475f-9976-4be0-b630-55987e9dcf7a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fhot-dog_1f32d_adobespark.jpeg?alt=media&token=50787984-3564-4fe2-982e-c6102250bd2a',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Flobster_1f99e_adobespark.jpeg?alt=media&token=26d73b2e-1fd7-4b33-8002-80b31da0ee1b'];


  constructor(
      private router: Router, private userService: UserDataService, private _location: Location, private modalService: NgbModal, public dialog: MatDialog, private dataService: DataServiceGenerico,
      private commonService: CommonService, private dataServiceGenerico: DataServiceGenerico, private paymentCashmaticService: PaymentCashmaticService, private ipcService: IpcService, private posTransactionService: PosTransactionService,
      private tavoliService: TavoliService,
      private authService: AuthService
  ) {
    //console.log(this.router.url); //  /routename

    //console.log('porcodioooo');

  }

  printReceipt(cassetto: string) {

    let stringa = []
    this.schermata = 1;

    this.getProperties(this.camerieri[this.getIndex()]?.carrello)?.forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )

    this.stampaScontrino(stringa);
    this.ipcService.printReceipt(this.stampaScontrino(stringa), cassetto);

    if (cassetto != 'apriSolo' && cassetto != 'nonAprire') {
      this.camerieri[this.getIndex()].statoPagato = 0;
    }
    if (cassetto == 'nonAprire') {
      this.camerieri[this.getIndex()].statoPagato = 1;
    }
    this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

  }

  sendTransaction(transactionAmount) {
    let stringa = []
    this.pagamentoCartaAperto = 1;
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )
    this.posTransactionService.sendTransaction(transactionAmount, this.stampaScontrino(stringa));
  }

  stampaScontrino(menu: any[]): string {
    const intestazione = 'F.S. LA VALLETTA\n' +
        'di Vilson Coku & C. S.n.C.\n' +
        'Via Partinico n.2\n' +
        '47814 - Igea Marina - RN\n' +
        'IT04428530408\n' +
        'Tel. 0541330860\n \n                   PRECONTO\n \n'

    const header = 'Qta  Prodotto                     IVA   Prezzo\n';
    const ivaRate = 0.10;
    const maxLength = 28; // Lunghezza massima del campo "Prodotto"
    const spaceBetweenFields = ' '.repeat(2); // Spazio tra i campi

    let totale = 0;


    const scontrino = menu.map(item => {
      const totalePrezzo = item.quantita * this.getPrezzoPiatto(item);
      totale += totalePrezzo;
      const prodotto = item.title.length > maxLength
          ? item.title.slice(0, maxLength)
          : item.title.padEnd(maxLength); // Piatto allineato a sinistra e di lunghezza fissa
      const quantita = item.quantita.toString().padStart(3); // Quantità allineata a destra
      const prezzo = totalePrezzo.toFixed(2).padStart(6); // Prezzo totale allineato a destra
      const ivaField = (ivaRate * 100).toFixed(0).padStart(3) + '%'; // IVA al 10%

      return `${quantita}${spaceBetweenFields}${prodotto}${spaceBetweenFields}${ivaField}${spaceBetweenFields}${prezzo}`;
    }).join('\n');

    const iva = totale * ivaRate;
    const subtotale = totale - iva;

    const footer = `

      -------------------------------

  * SUBTOTALE${subtotale.toFixed(2).padStart(34)}
  * IVA${iva.toFixed(2).padStart(40)}
  * TOTALE${totale.toFixed(2).padStart(37)}

      -------------------------------
      
      
      
         GRAZIE ED ARRIVEDERCI

  `;
    console.log(intestazione + header + scontrino + footer)
    return  header + scontrino + footer;
  }

  testPay() {
    this.cashmaticAperto = 1;
    let stringa = []
    this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(
        key => stringa.push(this.camerieri[this.getIndex()]?.carrello[key])
    )

    this.paymentCashmaticService.testPay(this.calcolaTotale(), this.stampaScontrino(stringa));
  }

  annullaPagamento() {
    this.cashmaticAperto = 0;
    this.paymentCashmaticService.annullaPagamento();
  }

  getTransaction() {
    this.paymentCashmaticService.getTransaction();
  }


  /*
  openDialog(): any {

    this.modalOptions.data = this.camerieri[this.schermataIndex].ordine
    const modalRef = this.dialog.open(DialogPagamento, this.modalOptions);
    modalRef.componentInstance.user = this.user;
    modalRef.componentInstance.carrello = this.camerieri[this.getIndex()]?.carrello
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      console.log('received entry: ', receivedEntry);
      this.dataServiceGenerico.updateCameriere2(this.camerieri[this.schermataIndex].ordine, receivedEntry)
    });

  }

   */


  aggiungiBirra() {
    let birra = {
      category: 'Bibite',
      menu: '0',
      prezzo: 5,
      price: 5,
      quantita: 1,
      title: 'Birra'
    }
    if (this.camerieri[this.schermataIndex].hasOwnProperty('carrello')) {
      if (this.camerieri[this.schermataIndex].carrello.hasOwnProperty('Birra')) {
        this.camerieri[this.schermataIndex].carrello['Birra'].quantita += 1;
      } else {
        this.camerieri[this.schermataIndex].carrello['Birra'] = birra;
      }
    } else {
      this.camerieri[this.schermataIndex]['carrello'] = {}
      this.camerieri[this.schermataIndex]['carrello']['Birra'] = birra;
    }

    this.commonService.updateOrdine(this.camerieri[this.schermataIndex].id, this.camerieri[this.schermataIndex])

  }


  scroll(id: any): void {
    console.log(`scrolling to ${id}`);
  }

  tavoli: Array<any> = [];


  ngOnInit(): void {

    //this.initializeImagePaths2()


    this.tavoliService.getTavoli().subscribe((tavoli) => {
      this.tavoli = tavoli;
    });


    if(this.dataService.getRistorante1() == 'demo_1')
    {
      this.dataServiceGenerico.loadSelectedMenuFromFirebase().then((ristorante) => {
        this.tavoliService.getTavoli(this.dataService.getRistorante1()).subscribe((tavoli) => {
          this.tavoli = tavoli;
        });
      })
    }
    else
    {
      this.tavoliService.getTavoli(this.dataService.getRistorante1()).subscribe((tavoli) => {
        this.tavoli = tavoli;
      });
    }




    this.user = this.dataServiceGenerico.getUser()

    console.log("user: ", this.user)

    this.posTransactionService.onTransactionResult((event, response) => {
      if (response.status === 'success') {
        console.log(response.message);
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        this.camerieri[this.getIndex()].statoPagato = 0;

        this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

        // Transazione avvenuta con successo
      } else if (response.status === 'failure') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Transazione fallita
      } else if (response.status === 'error') {
        this.schermata = 1;
        this.pagamentoCartaAperto = 0;
        // Errore di connessione
      }
    });

    this.subscriptions.push(
        this.paymentCashmaticService.soldiInseriti$.subscribe(
            (soldiInseriti) => {
              this.soldiInseriti = soldiInseriti;
            }
        ),
        this.paymentCashmaticService.paymentSuccess$.subscribe(
            (data) => {
              this.paymentMessage = 'Pagamento completato con successo!';
              this.schermata = 1;
              this.cashmaticAperto = 0;
              this.camerieri[this.getIndex()].statoPagato = 0;

              this.commonService.updateOrdine(this.camerieri[this.getIndex()].id, this.camerieri[this.getIndex()])

            }
        ),
        this.paymentCashmaticService.paymentError$.subscribe(
            (error) => {
              this.paymentMessage = 'Errore durante il pagamento: ' + error;
            }
        )
    );


    this.dataServiceGenerico.camerieriSubjectItem$
        .subscribe(item => {

              console.log("received camerieri subject")
              this.camerieri = item
            }
        )

    this.dataServiceGenerico.OrdineSubjectItem$
        .subscribe(item => {
              console.log("received camerieri 2 salvare: ", item)
              if (item !== 0) {
                this.commonService.updateOrdine(item.id, item)
              }
            }
        )


    console.log('firebase: ', firebase);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveCarrello();
        this.retriveMenu();
        this.userService.emitter.subscribe(
            () => {
              this.retriveCarrello();
              this.retriveMenu();
            }
        );
      } else {
        // No user is signed in.
      }
    });
  }

  tavoliDaUnire: string[] = [];
  modalitaUnione: boolean = false;

  gestioneClickTavolo(tavolo: any) {
    if (this.eliminazione) {
      this.liberaTavolo(tavolo.numero);
    } else if (this.modalitaUnione) {
      this.selezionaTavoloPerUnione(tavolo.numero);
    } else {
      this.aggiungiOrdine(tavolo);
    }
  }

  getTavoloBorder(tavolo: any): string {
    if (this.tavoliDaUnire.includes(tavolo.numero.toString())) {
      return '2px solid #E55933';
    }
    return tavolo.unito ? '1px solid red' : '1px solid #ccc';
  }

  selezionaTavoloPerUnione(numeroTavolo: string) {
    const index = this.tavoliDaUnire.indexOf(numeroTavolo);
    if (index > -1) {
      this.tavoliDaUnire.splice(index, 1);
    } else if (this.tavoliDaUnire.length < 2) {
      this.tavoliDaUnire.push(numeroTavolo);
    }

    if (this.tavoliDaUnire.length === 2) {
      this.unisciTavoli();
    }
  }



  unisciTavoli() {
    if (this.tavoliDaUnire.length !== 2) {
      console.error('Seleziona esattamente due tavoli da unire');
      return;
    }

    const [tavoloDestinazione, tavoloOrigine] = this.tavoliDaUnire
        .map(numero => parseInt(numero))
        .sort((a, b) => a - b)
        .map(numero => numero.toString());

    const ordineDestinazione = this.camerieri.find(c => c.tavolo === tavoloDestinazione);
    const ordineOrigine = this.camerieri.find(c => c.tavolo === tavoloOrigine);

    if (ordineDestinazione && ordineOrigine) {
      if (!ordineDestinazione.carrello) {
        ordineDestinazione.carrello = {};
      }

      if (ordineOrigine.carrello) {
        Object.keys(ordineOrigine.carrello).forEach(prodottoKey => {
          if (ordineDestinazione.carrello[prodottoKey]) {
            ordineDestinazione.carrello[prodottoKey].quantita +=
                ordineOrigine.carrello[prodottoKey].quantita;
          } else {
            ordineDestinazione.carrello[prodottoKey] = {
              ...ordineOrigine.carrello[prodottoKey]
            };
          }
        });

        if (ordineOrigine.ordineInserimento) {
          if (!ordineDestinazione.ordineInserimento) {
            ordineDestinazione.ordineInserimento = [];
          }
          ordineOrigine.ordineInserimento.forEach(prodotto => {
            if (!ordineDestinazione.ordineInserimento.includes(prodotto)) {
              ordineDestinazione.ordineInserimento.push(prodotto);
            }
          });
        }
      }

      this.commonService.updateOrdine(ordineDestinazione.id, ordineDestinazione);
      this.eliminaOrdine(ordineOrigine.ordine);

      this.tavoliDaUnire = [];
      this.modalitaUnione = false;

      console.log(`Tavoli ${tavoloDestinazione} e ${tavoloOrigine} uniti con successo`);
    } else {
      console.error('Uno o entrambi i tavoli selezionati non hanno ordini attivi');
      this.tavoliDaUnire = [];
      this.modalitaUnione = false;
    }
  }


  attivaModalitaUnione() {
    this.modalitaUnione = true;
    this.eliminazione = false;
    this.tavoliDaUnire = [];
  }


  liberaTavolo(numeroTavolo: string) {
    const ordine = this.camerieri.find(c => c.tavolo === numeroTavolo);
    if (ordine) {
      this.eliminaOrdine(ordine.ordine);
      console.log(`Tavolo ${numeroTavolo} liberato con successo`);
    } else {
      console.error(`Nessun ordine trovato per il tavolo ${numeroTavolo}`);
    }
  }

  /*
  aggiornaDatiTavoli() {
    this.tavoliService.getTavoli().subscribe(tavoliAggiornati => {
      this.tavoli = tavoliAggiornati;
    });
  }

   */


  // Modifica la funzione eliminaOrdine esistente
  eliminaOrdine(ordine: any) {
    console.log("elimina ordine: ", ordine);
    const index = this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine);
    if (index !== -1) {
      this.commonService.deleteOrdine(this.camerieri[index].id);
      this.camerieri.splice(index, 1);
      this.dataServiceGenerico.setCamerieri(this.camerieri);
    }
  }

  idRistorante = '';

  camerieri = []
  menu = [];
  carrello2 = {};


  getProperties(object: any) {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    if (object !== undefined) {
      return Object.keys(object)
    }
  }

  getPropertiesPortata(object: any) {
    //console.log("oggetto: ", object)
    //console.log("oggetto 2: ", Object.keys(object))
    /*
    if (object !== undefined) {
      return Object.keys(object).filter((piatto) => this.camerieri[this.schermataIndex]?.carrello[piatto].hasOwnProperty('portata')? this.camerieri[this.schermataIndex]?.carrello[piatto]['portata'] == this.portata? true: this.portata == 4 : this.portata == 4)
    }
     */
    if (object !== undefined && this.camerieri[this.schermataIndex]?.ordineInserimento) {
      return this.camerieri[this.schermataIndex].ordineInserimento.filter((piatto) => {
        const piattoObj = this.camerieri[this.schermataIndex]?.carrello[piatto];
        return piattoObj.hasOwnProperty('portata')
            ? piattoObj['portata'] == this.portata || this.portata == 4
            : this.portata == 4;
      });
    }
    return [];
  }

  eliminazione = false;

  /*eliminaOrdine(ordine: any) {
    console.log("elimina ordine: ", ordine)

    this.commonService.deleteOrdine(this.camerieri[this.camerieri.findIndex((cameriere) => cameriere.ordine == ordine)].id)

    this.camerieri = this.camerieri.filter(item => !(item.ordine == ordine))

    this.dataServiceGenerico.setCamerieri(this.camerieri)
  }*/

  aggiungiOrdine(tavolo?: any) {
    if (tavolo && tavolo.occupato === 1) {
      // Se il tavolo è occupato, trova l'ordine esistente e aprilo
      const ordineEsistente = this.camerieri.find(c => c.tavolo === tavolo.numero);
      if (ordineEsistente) {
        this.cambiaSchermata(ordineEsistente.id);
        return;
      }
    }

    let current = new Date();
    let timestamp = current.getTime();
    let psw = timestamp.toString();

    let filterderArray = this.camerieri.sort((a, b) => b.ordine - a.ordine);
    let nuovoOrdine = filterderArray.length > 0 ? +filterderArray[0].ordine + 1 : 1;

    let ordine = {
      ristorante: this.dataServiceGenerico.getRistorante(),
      id: 'c1' + psw,
      displayName: tavolo ? `Tavolo ${tavolo.numero}` : `Ordine 000${nuovoOrdine}`,
      ordine: nuovoOrdine,
      fonte: 2,
      statoPagato: 1,
      data: psw,
      tavolo: tavolo ? tavolo.numero.toString() : null
    };

    this.camerieri.unshift(ordine);

    // Aggiorna il database
    this.commonService.updateOrdine('c1' + psw, ordine);

    // Aggiorna i camerieri e naviga immediatamente
    this.dataServiceGenerico.setCamerieri(this.camerieri);
    this.cambiaSchermata(ordine.id);
  }

  /*
  aggiornaTavolo(numeroTavolo: string, occupato: boolean) {
    this.tavoliService.aggiornaTavoloStato(numeroTavolo, occupato).then(() => {
      this.aggiornaDatiTavoli();
    });
  }

   */

  getTitoloOrdine() {
    const ordineCorrente = this.camerieri[this.getIndex()];
    return ordineCorrente.tavolo ? `Tavolo ${ordineCorrente.tavolo}` : `Ordine #${ordineCorrente.ordine}`;
  }


  retriveCarrello(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);


    this.userService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
        data => {
          console.log("!!!!!!!!!!!!!!!!!data preferiti: ", data.data())

          if (data.data() !== undefined) {

            this.user = data.data();

            if (this.user.hasOwnProperty('preferiti')) {
              console.log("!!!!!!!!!!!!!!!!!ciaociao preferiti: ", this.user['preferiti'])
              this.user['preferiti'] = this.user['preferiti'].filter(preferito => this.menuBello.some(piatto => piatto.title == preferito.title))
            }
          }
        }
    );

    console.log("ristorante 1: ", this.dataServiceGenerico.getRistorante())
    console.log("ristorante 2: ", this.filtroSource)
    console.log("ristorante 3: ", this.filtroStato)



    if(this.dataServiceGenerico.getRistorante() == 'demo_1')
    {
      this.dataServiceGenerico.loadSelectedMenuFromFirebase().then( (value) =>
          this.commonService.getOrdini(this.dataServiceGenerico.getRistorante(), this.filtroSource, this.filtroStato).subscribe(
              (dataCamerieri) => {
                console.log("dataCameriere: x", dataCamerieri)
                if (dataCamerieri !== undefined) {
                  console.log("dataCameriere: ", dataCamerieri)

                  //fonte
                  //0 = app
                  //1 = kiosk
                  //2 = cassa
                  //3 = tutto

                  this.camerieri = dataCamerieri
                  this.dataServiceGenerico.setCamerieri(this.camerieri)

                  if (this.camerieri && this.camerieri.length > 0 && !this.selectedOrder) {
                    this.selectedOrder = this.camerieri[0];
                  }

                  this.menu.forEach(categoria => {
                    console.log("categoria: ", categoria)
                    if (categoria.hasOwnProperty('menu')) {
                      console.log("carrello: ", categoria.menu)
                      categoria.menu.forEach(prodotto => {
                        if (prodotto.hasOwnProperty('id')) {
                          if (this.carrello !== undefined) {
                            this.carrello['carrello'][prodotto.id] = {
                              quantita: 0
                            };
                            console.log("carrello 2: ", this.carrello['carrello'])
                          }
                        }
                      })
                    }
                  })
                } else {
                  this.camerieri = []
                  this.dataServiceGenerico.setCamerieri(this.camerieri)

                  if (this.camerieri && this.camerieri.length > 0 && !this.selectedOrder) {
                    this.selectedOrder = this.camerieri[0];
                  }

                  this.menu.forEach(categoria => {
                    console.log("categoria: ", categoria)
                    if (categoria.hasOwnProperty('menu')) {
                      console.log("carrello: ", categoria.menu)
                      categoria.menu.forEach(prodotto => {
                        if (prodotto.hasOwnProperty('id')) {
                          this.carrello['carrello'][prodotto.id] = {
                            quantita: 0
                          };
                          console.log("carrello 2: ", this.carrello['carrello'])
                        }
                      })
                    }
                  })
                }
              }
          )
      )
    }
    else {
      this.commonService.getOrdini(this.dataServiceGenerico.getRistorante(), this.filtroSource, this.filtroStato).subscribe(
          (dataCamerieri) => {
            console.log("dataCameriere: x", dataCamerieri)
            if (dataCamerieri !== undefined) {
              console.log("dataCameriere: ", dataCamerieri)

              //fonte
              //0 = app
              //1 = kiosk
              //2 = cassa
              //3 = tutto

              this.camerieri = dataCamerieri
              this.dataServiceGenerico.setCamerieri(this.camerieri)

              if (this.camerieri && this.camerieri.length > 0 && !this.selectedOrder) {
                this.selectedOrder = this.camerieri[0];
              }

              this.menu.forEach(categoria => {
                console.log("categoria: ", categoria)
                if (categoria.hasOwnProperty('menu')) {
                  console.log("carrello: ", categoria.menu)
                  categoria.menu.forEach(prodotto => {
                    if (prodotto.hasOwnProperty('id')) {
                      if (this.carrello !== undefined) {
                        this.carrello['carrello'][prodotto.id] = {
                          quantita: 0
                        };
                        console.log("carrello 2: ", this.carrello['carrello'])
                      }
                    }
                  })
                }
              })
            } else {
              this.camerieri = []
              this.dataServiceGenerico.setCamerieri(this.camerieri)

              if (this.camerieri && this.camerieri.length > 0 && !this.selectedOrder) {
                this.selectedOrder = this.camerieri[0];
              }

              this.menu.forEach(categoria => {
                console.log("categoria: ", categoria)
                if (categoria.hasOwnProperty('menu')) {
                  console.log("carrello: ", categoria.menu)
                  categoria.menu.forEach(prodotto => {
                    if (prodotto.hasOwnProperty('id')) {
                      this.carrello['carrello'][prodotto.id] = {
                        quantita: 0
                      };
                      console.log("carrello 2: ", this.carrello['carrello'])
                    }
                  })
                }
              })
            }
          }
      )
    }

    console.log("!!!!!!!!!!!!!!!!!before preferiti: ")

  }

  getIndex(): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == this.ordineOrdine)
  }

  getIndexOrdine(ordine: any): number {
    return this.camerieri.findIndex(cameriere => cameriere.ordine == ordine)
  }

  calcolaTotale() {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if (this.camerieri[this.getIndex()] !== undefined) {
        if (this.camerieri[this.getIndex()].hasOwnProperty('carrello')) {
          this.getProperties(this.camerieri[this.getIndex()]?.carrello).forEach(piatto => {
            //console.log('piatto', piatto);
            this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndex()]?.carrello[piatto].quantita;


            let prezzoPiatto = this.getPrezzoPiatto(this.camerieri[this.getIndex()]?.carrello[piatto]);
            this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndex()]?.carrello[piatto].quantita);
            //console.log("tot nuovo: ", this.tot)
          });
        }
      }
    }
    return this.tot
  }


  calcolaTotaleOrdine(ordine: any) {

    //console.log("receive calcola index: ", this.getIndex())

    //console.log("receive calcola totale: ", this.camerieri)
    this.numeroElementi = 0;
    this.tot = 0;

    if (this.camerieri.length > 0) {
      if (this.camerieri[this.getIndexOrdine(ordine)].hasOwnProperty('carrello')) {
        this.getProperties(this.camerieri[this.getIndexOrdine(ordine)]?.carrello).forEach(piatto => {
          //console.log('piatto', piatto);
          this.numeroElementi = this.numeroElementi + this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita;

          let prezzoPiatto = this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].prezzo;
          this.tot = this.tot + (prezzoPiatto * this.camerieri[this.getIndexOrdine(ordine)]?.carrello[piatto].quantita);
          //console.log("tot nuovo: ", this.tot)
        });
      }
    }
    return this.tot.toFixed(2)
  }

  modificaPrezzp(numerino: any): void {
    if (numerino == 'elimina') {
      this.prezzoPiatto = this.prezzoPiatto.slice(0, -1);
    } else {
      this.prezzoPiatto += numerino.toString();
    }
  }

  ordineOrdine = '';

  ordine = [];
  selectedOrder: any = null;

  openOrderDetail(order: any) {
    this.selectedOrder = order;
  }

  getStatusClass(status: number): string {
    switch(status) {
      case 0: return 'status-paid';
      case 1: return 'status-unpaid';
      default: return 'status-processing';
    }
  }

  getStatusText(status: number): string {
    switch(status) {
      case 0: return 'Pagato';
      case 1: return 'Non Pagato';
      default: return 'In Lavorazione';
    }
  }

  getFonteText(fonte: number): string {
    switch(fonte) {
      case 0: return 'App';
      case 1: return 'Kiosk';
      case 2: return 'Cassa';
      default: return 'Altro';
    }
  }

  // Modifica il metodo esistente per gestire sia il click sul pannello che la navigazione
  cambiaSchermata(idOrdine: any): void {
    if (this.selectedOrder) {
      this.router.navigate(['/cassa/order', idOrdine], { fragment: 'nonav' });
    }
  }

  aggiungiPiatto(prezzo: any, index: number): void {
    this.tavoli[index].scontrino.push({piatto: "Piatto personalizzato", descrizione: "", quantita: 1, prezzo: prezzo},)
  }

  calcolaMancia(): number {
    return this.tot / 10;
  }

  calcolaPrezzo(piatto: any): number {

    let prezzoPiatto = piatto.prezzo;

    if (piatto.opzioni) {
      piatto.opzioni.forEach(opzione => {

        prezzoPiatto = prezzoPiatto + opzione.prezzo;
      });
    }
    return prezzoPiatto;

  }

  cucina() {
    this.router.navigateByUrl('cucina#nonav');
  }


  piattoSelezionato2 = {}

  updateCameriere(i) {


    if(this.camerieri[this.getIndex()]?.statoPagato !== undefined? this.camerieri[this.getIndex()].statoPagato == 0? false: true: true) {

      console.log("piatto selezionato: ", i)

      console.log("cameriere: ", this.camerieri[this.schermataIndex])

      this.dataServiceGenerico.updateCameriere(this.camerieri[this.schermataIndex].ordine, i, this.portata)
      /*
      if ((this.camerieri[this.schermataIndex]?.carrello !== undefined) && (this.camerieri[this.schermataIndex]?.carrello.hasOwnProperty(i.title.replace(/ /g, '')))) {
        this.camerieri[this.schermataIndex].carrello[i.title.replace(/ /g, '')].quantita++;
      } else {
        if (this.camerieri[this.schermataIndex]?.carrello == undefined) {
          let nome = i.title.replace(/ /g, '');

          this.camerieri[this.schermataIndex].carrello = {}
          this.camerieri[this.schermataIndex].carrello[nome] = i;

          this.camerieri[this.schermataIndex].carrello[nome].quantita = 1;
          this.camerieri[this.schermataIndex].carrello[nome].price = +this.camerieri[this.schermataIndex]?.carrello[nome].price;
          this.camerieri[this.schermataIndex].carrello[nome].prezzo = +this.camerieri[this.schermataIndex]?.carrello[nome].price
        } else {
          let nome = i.title.replace(/ /g, '');

          this.camerieri[this.schermataIndex].carrello[nome] = i;
          console.log("received ciaociao3")

          this.camerieri[this.schermataIndex].carrello[nome].quantita = 1;
          this.camerieri[this.schermataIndex].carrello[nome].price = +this.camerieri[this.schermataIndex]?.carrello[nome].price;
          this.camerieri[this.schermataIndex].carrello[nome].prezzo = +this.camerieri[this.schermataIndex]?.carrello[nome].price
        }

      }

       */
    }


  }


  testComande() {
    let cose = [];
    console.log('comanda: ', this.camerieri[this.schermataIndex]?.carrello);

    if (this.camerieri[this.schermataIndex]?.carrello !== undefined) {
      this.getProperties(this.camerieri[this.schermataIndex]?.carrello).forEach((piatto) => {
        if (this.camerieri[this.schermataIndex]?.carrello[piatto].hasOwnProperty('portata')
            ? this.camerieri[this.schermataIndex]?.carrello[piatto]['portata'] == this.portata || this.portata == 4
            : this.portata == 4) {
          cose.push(this.camerieri[this.schermataIndex]?.carrello[piatto]);
        }
      });
    }

    // Filter items based on destination
    const cucinaItems = cose.filter(item => item.selectedPrintDestination === 'cucina');
    const pizzeriaItems = cose.filter(item => item.selectedPrintDestination === 'pizzeria');
    const barItems = cose.filter(item => item.selectedPrintDestination === 'bar');

    // Get the table number
    const tavolo = this.camerieri[this.schermataIndex]?.tavolo;

    // Helper function to format items by portata, including bold flag for destination items
    const formatPortata = (items, otherItems, portataLabel) => {
      let portataMessage = `\n \n---${portataLabel}---\n \n`;

      items.forEach(item => {
        const itemText = `${item.quantita} x ${item.title}`;
        portataMessage += `<b>${itemText}</b>\n`;  // Bold for current destination
      });

      otherItems.forEach(item => {
        const itemText = `${item.quantita} x ${item.title}`;
        portataMessage += `${itemText}\n`;  // Normal for items from the other destination
      });

      return portataMessage;
    };

    // Function to combine both pizzeria and cucina items into the same message with proper formatting
    const generateUnifiedMessage = (destinationName, items, otherItems, printerIp) => {
      let message = `${destinationName} \nTavolo: ${tavolo}\n `;

      // Group items by portata
      const firstCourseItems = items.filter(item => item.portata === 0);
      const secondCourseItems = items.filter(item => item.portata === 1);
      const thirdCourseItems = items.filter(item => item.portata === 2);
      const fourthCourseItems = items.filter(item => item.portata === 3);

      const firstOtherItems = otherItems.filter(item => item.portata === 0);
      const secondOtherItems = otherItems.filter(item => item.portata === 1);
      const thirdOtherItems = otherItems.filter(item => item.portata === 2);
      const fourthOtherItems = otherItems.filter(item => item.portata === 3);

      if (firstCourseItems.length > 0 || firstOtherItems.length > 0) {
        message += formatPortata(firstCourseItems, firstOtherItems, 'Prima portata');
      }
      if (secondCourseItems.length > 0 || secondOtherItems.length > 0) {
        message += formatPortata(secondCourseItems, secondOtherItems, 'Seconda portata');
      }
      if (thirdCourseItems.length > 0 || thirdOtherItems.length > 0) {
        message += formatPortata(thirdCourseItems, thirdOtherItems, 'Terza portata');
      }
      if (fourthCourseItems.length > 0 || fourthOtherItems.length > 0) {
        message += formatPortata(fourthCourseItems, fourthOtherItems, 'Quarta portata');
      }

      console.log(message);  // Log message to check the output

      // Send the message to the printer using ipcService
      this.ipcService.printReceipt(message, printerIp);
    };

    // Generate message for pizzeria (including cucina items), and send to the pizzeria printer
    if (pizzeriaItems.length > 0 || cucinaItems.length > 0) {
      generateUnifiedMessage('pizzeria', pizzeriaItems, cucinaItems, '192.168.123.103');
    }

    // Generate message for cucina (including pizzeria items), and send to the cucina printer
    if (cucinaItems.length > 0 || pizzeriaItems.length > 0) {
      generateUnifiedMessage('cucina', cucinaItems, pizzeriaItems, '192.168.123.102');
    }

    // Generate message for bar (only bar items, no mixing with others), and send to the bar printer
    if (barItems.length > 0) {
      generateUnifiedMessage('bar', barItems, [], '192.168.123.101');  // Bar only has its own items, no need for other destination
    }
  }


  logout() {
    this.authService.SignOut();
    this.router.navigateByUrl('login');
  }

  apriazioniStampanteDialog(): void {
    const dialogRef = this.dialog.open(AzioniStampanteComponent, {
      width: '400px', // Puoi personalizzare la larghezza del dialog
      disableClose: true // Rende il dialog non chiudibile cliccando fuori dalla finestra
    });

    dialogRef.afterClosed().subscribe(result => {
      // Aggiungi la logica che serve dopo la chiusura del dialog (se necessario)
      console.log('Dialog chiuso', result);
    });
  }

  apricassetto(): void{
    this.ipcService.printReceipt('', 'apriSolo');
  }
}