<div class="dialog-container">
    <div class="sticky-container">
        <div class="save-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-download-96.png?alt=media&token=8b317d4f-8e36-4056-a392-9083a88fd44e"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Salva Prodotto"
                 (click)="saveChangesToFirestore()">
        </div>
        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>

    <!-- Sezione titolo e informazioni del prodotto -->
    <div class="product-info">
        <h2>{{ product.Titolo }}</h2>
        <p class="categoria">{{ product.Categoria }}</p>
        <p class="price">Prezzo base: {{ originalPrice | currency:'EUR' }}</p>
    </div>

    <!-- Selettore livelli di imballaggio -->
    <div class="packaging-selector">
        <h3>Livelli di imballaggio</h3>
        <div class="level-tabs">
            <div class="level-tab" [class.active]="isLvl1" (click)="onLevelSelect(1)">
                <span>1</span>
                <div class="level-icon" [class.active]="isLvl1">
                    <i *ngIf="isLvl1" class="check-icon"></i>
                </div>
            </div>
            <div class="level-tab" [class.active]="isLvl2" (click)="onLevelSelect(2)">
                <span>2</span>
                <div class="level-icon" [class.active]="isLvl2">
                    <i *ngIf="isLvl2" class="check-icon"></i>
                </div>
            </div>
            <div class="level-tab" [class.active]="isLvl3" (click)="onLevelSelect(3)">
                <span>3</span>
                <div class="level-icon" [class.active]="isLvl3">
                    <i *ngIf="isLvl3" class="check-icon"></i>
                </div>
            </div>
            <div class="level-tab" [class.active]="isLvl4" (click)="onLevelSelect(4)">
                <span>4</span>
                <div class="level-icon" [class.active]="isLvl4">
                    <i *ngIf="isLvl4" class="check-icon"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- Livelli di imballaggio -->
    <div class="packaging-levels">
        <!-- Primo Livello -->
        <div class="level-item">
            <div class="level-title">Primo Livello</div>
            <div class="level-fields">
                <div class="field-group">
                    <label>Quantità</label>
                    <input
                            type="number"
                            [(ngModel)]="levels[0].quantita"
                            (input)="calculateTotalQuantityAndPrice()"
                            min="0"
                            class="quantity-input">
                </div>
                <div class="field-group">
                    <label>UM</label>
                    <input
                            type="text"
                            [(ngModel)]="levels[0].um"
                            readonly
                            class="um-input">
                </div>
            </div>
        </div>

        <!-- Secondo Livello (visibile solo se maxLvl >= 2) -->
        <div class="level-item" *ngIf="maxLvl >= 2">
            <div class="level-title">Secondo Livello</div>
            <div class="level-fields">
                <div class="field-group">
                    <label>Quantità</label>
                    <input
                            type="number"
                            [(ngModel)]="levels[1].quantita"
                            (input)="calculateTotalQuantityAndPrice()"
                            min="0"
                            class="quantity-input">
                </div>
                <div class="field-group">
                    <label>UM</label>
                    <input
                            type="text"
                            [(ngModel)]="levels[1].um"
                            readonly
                            class="um-input">
                </div>
            </div>
        </div>

        <!-- Terzo Livello (visibile solo se maxLvl >= 3) -->
        <div class="level-item" *ngIf="maxLvl >= 3">
            <div class="level-title">Terzo Livello</div>
            <div class="level-fields">
                <div class="field-group">
                    <label>Quantità</label>
                    <input
                            type="number"
                            [(ngModel)]="levels[2].quantita"
                            (input)="calculateTotalQuantityAndPrice()"
                            min="0"
                            class="quantity-input">
                </div>
                <div class="field-group">
                    <label>UM</label>
                    <input
                            type="text"
                            [(ngModel)]="levels[2].um"
                            readonly
                            class="um-input">
                </div>
            </div>
        </div>

        <!-- Quarto Livello (visibile solo se maxLvl = 4) -->
        <div class="level-item" *ngIf="maxLvl >= 4">
            <div class="level-title">Quarto Livello</div>
            <div class="level-fields">
                <div class="field-group">
                    <label>Quantità</label>
                    <input
                            type="number"
                            [(ngModel)]="levels[3].quantita"
                            (input)="calculateTotalQuantityAndPrice()"
                            min="0"
                            class="quantity-input">
                </div>
                <div class="field-group">
                    <label>UM</label>
                    <input
                            type="text"
                            [(ngModel)]="levels[3].um"
                            readonly
                            class="um-input">
                </div>
            </div>
        </div>
    </div>

    <!-- Riepilogo -->
    <div class="summary-section">
        <div class="summary-row">
            <span class="label">Quantità totale:</span>
            <span class="value">{{ totalQuantity }}</span>
        </div>
        <div class="summary-row">
            <span class="label">Prezzo totale:</span>
            <span class="value price">{{ totalPrice | currency:'EUR' }}</span>
        </div>
    </div>
</div>