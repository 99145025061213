import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {DataServiceGenerico} from "../../shared/services/data-service/data.service";

@Component({
  selector: 'add-ordine-magazzino',
  templateUrl: './add-ordine-magazzino.html',
  styleUrls: ['./add-ordine-magazzino.css'],
})
export class AddOrdineMagazzino implements OnInit, OnDestroy {
  isMenuChecked = false;
  isSingleChecked = false;
  isDisponibileChecked = false;
  ristorante = 'menu';

  categories: string[] = [];
  ingredients: string[] = [];
  allergens: string[] = [];

  // Variabili per gestire i valori dei campi
  Titolo: string = '';
  id: string = '';
  selectedCategoria: string = '';
  selectedImage: string = '';
  selectedIngredients: string[] = [];
  selectedAllergens: string[] = [];
  price: string = '';
  quantity: string = '';
  supplierCode: string = '';
  supplier: string = '';
  expiryDate: string = '';
  um: string = '';
  maxLvl: number = 1; // Default a 1 livello
  totalPrice: number = 0;
  originalPrice: string = '';


  // Variabili per i livelli di imballaggio
  levels: { quantita: string, um: string, barCode: string }[] = [
    { quantita: '', um: '', barCode: '' }, // Primo livello
    { quantita: '', um: '', barCode: '' }, // Secondo livello
    { quantita: '', um: '', barCode: '' }, // Terzo livello
    { quantita: '', um: '', barCode: '' }  // Quarto livello
  ];

  originalLevels: { quantita: string, um: string, barCode: string }[] = [
    { quantita: '', um: '', barCode: '' }, // Primo livello
    { quantita: '', um: '', barCode: '' }, // Secondo livello
    { quantita: '', um: '', barCode: '' }, // Terzo livello
    { quantita: '', um: '', barCode: '' }  // Quarto livello
  ];



  // Variabili per la cronologia
  cronologia: any[] = [];

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  isLvl1 = true;
  isLvl2 = false;
  isLvl3 = false;
  isLvl4 = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<AddOrdineMagazzino>,
    private dataService: DataServiceGenerico,
    @Inject(MAT_DIALOG_DATA) public data: any  // Riceve i dati dal dialog
  ) {
    this.route.params.subscribe(params => {
      if (params.ristorante) {
        this.ristorante = 'menu';
      }
    });
  }

  ngOnInit(): void {
    this.fetchCategoriesFromFirestore();

    if (this.data) {
      // Popola i campi con i dati passati
      this.id = this.data.id || '';
      this.Titolo = this.data.Titolo || '';
      this.selectedCategoria = this.data.Categoria || '';
      this.selectedImage = this.data.image_link || '';
      this.supplierCode = this.data.CodiceFornitore || '';
      this.supplier = this.data.Fornitore || '';
      this.quantity = this.data.Quantita || '';
      this.originalPrice = this.data.Prezzo || '';
      this.price = this.data.Prezzo || '';
      this.expiryDate = this.data.Scadenza || '';
      this.um = this.data.UM || '';
      this.maxLvl = this.data.maxLvl || 1;

      // Inizializza i livelli con quantità zero
      this.levels = this.data.lvl?.map((level) => ({ ...level, quantita: '0' })) || [
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' }
      ];

      this.originalLevels = this.data.lvl?.map((level) => ({ ...level })) || [
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' },
        { quantita: '0', um: '', barCode: '' }
      ];

      this.checkCartForProduct();

      this.isMenuChecked = this.data.menu;
      this.isSingleChecked = this.data.single;
      this.isDisponibileChecked = this.data.disponibilita;

      // Imposta i livelli in base a maxLvl
      this.isLvl1 = this.maxLvl >= 1;
      this.isLvl2 = this.maxLvl >= 2;
      this.isLvl3 = this.maxLvl >= 3;
      this.isLvl4 = this.maxLvl >= 4;

      this.cdRef.detectChanges();
    }
  }


  ngOnDestroy(): any {
    this.passBack();
  }

  checkCartForProduct(): void {
    const db = firebase.firestore();
    const cartRef = db.collection('ristoranti').doc(this.dataService.getRistorante1());

    cartRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.carrelloMagazzino || [];
        const existingProduct = items.find((item: any) => item.id === this.id);

        if (existingProduct) {
          // Populate levels with existing quantities and units of measure from the cart
          this.levels = existingProduct.QuantitaPerUM.map((quantita: string, index: number) => ({
            quantita: quantita,
            um: existingProduct.UM,
            barCode: this.levels[index]?.barCode || ''
          }));
          this.totalPrice = parseFloat(existingProduct.Prezzo);
          this.cdRef.detectChanges();
        }
      }
    }).catch((error) => {
      console.error('Error checking cart for product:', error);
    });
    }

  fetchCategoriesFromFirestore(): void {
    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const collectionRef = db.collection('magazzino_test').doc(restaurantName);

    collectionRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const categoriesSet = new Set<string>();
        const ingredientsSet = new Set<string>();

        items.forEach(item => {
          if (item.Categoria) {
            categoriesSet.add(item.Categoria);
          }
          if (item.Titolo) {
            ingredientsSet.add(item.Titolo); // Usa i titoli dei prodotti come ingredienti
          }
        });

        this.categories = Array.from(categoriesSet);
        this.ingredients = Array.from(ingredientsSet);
        this.cdRef.detectChanges();
      }
    }).catch(error => {
      console.error('Error fetching data from Firestore:', error);
    });
  }


  saveChangesToFirestore(): void {
    const db = firebase.firestore();
    const cartRef = db.collection('ristoranti').doc(this.dataService.getRistorante1());

    // Initialize total price and quantities
    let totalPrice = parseFloat(this.price);
    let totalQuantity = 0;
    let quantitiesArray: number[] = [];

    // Calculate total quantity and quantities per level
    for (let i = 0; i < this.maxLvl; i++) {
      // Ensure each level quantity is multiplied only by its corresponding original value
      const levelQuantity = parseInt(this.levels[i].quantita || '1', 10);
      quantitiesArray.push(levelQuantity); // Store per level
      totalQuantity += levelQuantity * parseInt(this.originalLevels[i].quantita || '1', 10); // Aggregate for total
    }

    // Calculate the final price based on the total quantity
    totalPrice *= totalQuantity;

    // Prepare the product data with updated quantities
    const productData = {
      Categoria: this.selectedCategoria,
      CodiceFornitore: this.supplierCode,
      Fornitore: this.supplier,
      Lvl: this.maxLvl,
      Prezzo: totalPrice.toFixed(2),
      Quantita: totalQuantity.toString(),
      QuantitaPerUM: quantitiesArray, // Store per-unit quantities correctly
      Scadenza: this.expiryDate,
      SyncMenu: this.data.SyncMenu || '0',
      Titolo: this.Titolo,
      UM: this.um,
      id: this.id,
    };

    // Save or update the data in Firestore
    cartRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.carrelloMagazzino || [];
        const existingProductIndex = items.findIndex((item: any) => item.id === productData.id);

        if (existingProductIndex !== -1) {
          // If product exists, replace the item directly
          items[existingProductIndex] = productData;
        } else {
          // If product is new, add to items array
          items.push(productData);
        }

        // Update Firestore with the modified items array
        cartRef.set({carrelloMagazzino: items },{merge: true})
            .then(() => {
              console.log('Product updated successfully in Firestore!');
            })
            .catch((error) => {
              console.error('Error updating product in Firestore:', error);
            });
      } else {
        // If document doesn’t exist, create it with initial product
        cartRef.set({
          carrelloMagazzino: [productData]
        },{merge: true}).then(() => {
          console.log('Cart document created and product added successfully in Firestore!');
        }).catch((error) => {
          console.error('Error creating cart document in Firestore:', error);
        });
      }
    }).catch((error) => {
      console.error('Error checking document existence in Firestore:', error);
    });

    this.passBack();
    this.dialogRef.close();
  }



  calculateTotalPrice(): void {
    this.totalPrice = 0;
    let levelQuantity = 0;
    for (let i = 0; i < this.maxLvl; i++) {
      levelQuantity += parseInt(this.levels[i].quantita || '1', 10) *  parseInt(this.originalLevels[i].quantita || '0', 10);
      console.log('a!1 2 ',i, levelQuantity, parseInt(this.levels[i].quantita || '1', 10), parseInt(this.originalLevels[i].quantita || '0', 10))
    }
    console.log('a!1',this.price, levelQuantity)
    this.totalPrice =parseFloat(this.price) * levelQuantity ;
  }


  onMenuChange(event: any) {
    this.isMenuChecked = event.target.checked;
  }

  onMenuChangeLevel1(event: any) {
    this.isLvl1 = event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl1 ? 1 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel2(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl2 ? 2 : 1;  // Aggiorna maxLvl
  }

  onMenuChangeLevel3(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = event.target.checked;
    this.isLvl4 = !event.target.checked;
    this.maxLvl = this.isLvl3 ? 3 : 2;  // Aggiorna maxLvl
  }

  onMenuChangeLevel4(event: any) {
    this.isLvl1 = !event.target.checked;
    this.isLvl2 = !event.target.checked;
    this.isLvl3 = !event.target.checked;
    this.isLvl4 = event.target.checked;
    this.maxLvl = this.isLvl4 ? 4 : 3;  // Aggiorna maxLvl
  }


  aggiunta = true;
  inventario = false;
  spreco = false;
  trasferimento = false;

  onModalitaChangeAggiunta(event: any)
  {
    this.aggiunta = true;
    this.spreco = false;
    this.trasferimento = false;
    this.inventario = false;
  }

  onModalitaChangeSpreco(event: any)
  {
    this.aggiunta = false;
    this.spreco = true;
    this.trasferimento = false;
    this.inventario = false;
  }

  onModalitaChangeTrasferimento(event: any)
  {
    this.aggiunta = false;
    this.spreco = false;
    this.trasferimento = true;
    this.inventario = false;
  }

  onModalitaChangeInventario(event: any)
  {
    this.aggiunta = false;
    this.spreco = false;
    this.trasferimento = false;
    this.inventario = true;
  }

  closeDialog() {
    this.passBack();
    this.dialogRef.close();
  }

  removeImage(levelIndex: number) {
    this.levels[levelIndex].barCode = '';
    this.cdRef.detectChanges();
  }

  onFileSelected(event: any, levelIndex: number) {
    const file = event.target.files[0];
    if (file) {
      this.uploadImageToFirebase(file, levelIndex);
    }
  }

  uploadImageToFirebase(file: File, levelIndex: number) {
    const storageRef = firebase.storage().ref();
    const filePath = `immaginiMenu/${file.name}`;
    const uploadTask = storageRef.child(filePath).put(file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Error uploading image:', error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.levels[levelIndex].barCode = downloadURL;
          this.cdRef.detectChanges();
        });
      }
    );
  }



  deleteItemFromFirestore(): void {
    if (!this.id) {
      console.error('ID mancante, impossibile eliminare l\'elemento.');
      return;
    }

    const db = firebase.firestore();
    const restaurantName = this.dataService.getRistorante();
    const magazzinoRef = db.collection('magazzino_test').doc(restaurantName);

    magazzinoRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        const items = data?.items || [];

        const updatedItems = items.filter(item => item.id !== this.id);

        magazzinoRef.update({
          items: updatedItems
        })
          .then(() => {
            console.log('Elemento eliminato con successo da Firestore!');
            this.dialogRef.close();
          })
          .catch((error) => {
            console.error('Errore durante l\'eliminazione dell\'elemento su Firestore: ', error);
          });

      } else {
        console.error('Documento non trovato');
      }
    }).catch((error) => {
      console.error('Errore nel recupero del documento:', error);
    });
  }

  passBack() {
    // Potresti voler restituire dati al chiamante, ad esempio:
    // this.passEntry.emit({ ... });
  }
}
