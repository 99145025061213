<div class="row" style=" height: 95vh; width:calc(100vw - 270px); margin-left: 30px; margin-top: 15px; border-radius: 20px; overflow-y: hidden ">
  <div style="height: 50px; border-bottom: 1px solid #dadada; line-height: 50px; padding-left: 20px; font-weight: 500;">Impostazioni account</div>


  <div class="sidebar shadow-sm" scroll="no" style="float: left">

    <div class="menuItem block" (click)="pagina = 0"  [ngClass]="{'selected': this.pagina === 0}">
      <div style="padding-left: 25px">Profilo</div>
    </div>

    <div class="menuItem block" (click)="pagina = 1"  [ngClass]="{'selected': this.pagina === 1}">
      <div style="padding-left: 25px">Dati aziendali</div>
    </div>

    <div class="menuItem block" (click)="pagina = 2"  [ngClass]="{'selected': this.pagina === 2}">
      <div style="padding-left: 25px">Tavoli</div>
    </div>

      <div class="menuItem block" (click)="pagina =11"  [ngClass]="{'selected': this.pagina === 11}">
          <div style="padding-left: 25px">Stampanti</div>
      </div>

      <div class="menuItem block" (click)="pagina = 12" [ngClass]="{'selected': this.pagina === 12}">
          <div style="padding-left: 25px">Immagini Pubblicitarie</div>
      </div>

      <div class="menuItem block" (click)="pagina = 13" [ngClass]="{'selected': this.pagina === 13}">
          <div style="padding-left: 25px">Immagini Totem</div>
      </div>

    <!--div class="menuItem block" (click)="pagina = 3"  [ngClass]="{'selected': this.pagina === 3}">

      <div style="padding-left: 25px">Costi</div>
    </div-->

    <div class="menuItem block" (click)="pagina = 4"  [ngClass]="{'selected': this.pagina === 4}">

      <div style="padding-left: 25px;" class="maxWidth">Personale</div>
    </div>


      <!--div class="menuItem block" (click)="pagina = 5"  [ngClass]="{'selected': this.pagina === 5}">

          <div style="padding-left: 25px">Menu</div>
      </div-->


    <div class="menuItem block" (click)="pagina = 6"  [ngClass]="{'selected': this.pagina === 6}">

      <div style="padding-left: 25px">Fidelity</div>
    </div>

    <!--div class="menuItem block" (click)="pagina = 7"  [ngClass]="{'selected': this.pagina === 7}">

      <div style="padding-left: 25px">Cassa</div>
    </div-->

    <div class="menuItem block" (click)="pagina = 8"  [ngClass]="{'selected': this.pagina === 8}">

      <div style="padding-left: 25px">Preferiti</div>
    </div>

    <!--div class="menuItem block" (click)="pagina = 9"  [ngClass]="{'selected': this.pagina === 9}">

      <div style="padding-left: 25px">Totem</div>
    </div-->

      <div class="menuItem block" (click)="pagina = 10"  [ngClass]="{'selected': this.pagina === 10}">

          <div style="padding-left: 25px">Ristoranti</div>
      </div>

      <div class="menuItem block" (click)="pagina = 14"  [ngClass]="{'selected': this.pagina === 14}">

          <div style="padding-left: 25px">Notifiche</div>
      </div>




      <div class=" block" (click)="logout()"  style="cursor: pointer; font-weight: 600">
          <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-log-out-100.png?alt=media&token=63460a1f-cbc8-4e65-ad04-d5e25e94c614" style="padding-left: 22px; width: 20px; aspect-ratio: 1 / 1;">
          <div style="padding-left: 5px; color: #ff655d">Esci</div>
      </div>

  </div>

  <account style="float: left; margin-left: 40px" *ngIf="pagina == 0"></account>

  <ristorante style="float: left; margin-left: 40px" *ngIf="pagina == 1"></ristorante>

  <tavoli style="float: left; margin-left: 40px" *ngIf="pagina == 2"></tavoli>

  <personale style="float: left; margin-left: 40px" *ngIf="pagina == 4"></personale>

    <impostazioni-menu style="float: left; margin-left: 40px" *ngIf="pagina == 5"></impostazioni-menu>

    <fidelity style="float: left; margin-left: 40px" *ngIf="pagina == 6"></fidelity>

    <preferiti style="float: left; margin-left: 40px" *ngIf="pagina == 8"></preferiti>

    <impostazioni-ristoranti style="float: left; margin-left: 40px" *ngIf="pagina == 10"></impostazioni-ristoranti>

    <stampanti style="float: left; margin-left: 40px" *ngIf="pagina == 11"></stampanti>

    <slider-images style="float: left; margin-left: 40px" *ngIf="pagina == 12"></slider-images>
    <slider-totem style="float: left; margin-left: 40px" *ngIf="pagina == 13"></slider-totem>
    <notifiche style="float: left; margin-left: 40px" *ngIf="pagina == 14"></notifiche>












</div>
