<div class="container">
    <!-- Selezione categoria per cui configurare l'upselling -->
    <div class="category-selector">
        <h3>Seleziona la categoria da configurare:</h3>
        <div class="categories-row">
            <div *ngFor="let category of categorie; let i = index"
                 class="category-item"
                 [class.selected]="selectedCategory === category"
                 (click)="onCategorySelect(category)">
                <span>{{category}}</span>
            </div>
        </div>
    </div>

    <!-- Griglia prodotti selezionabili -->
    <div class="products-section">
        <h4>Seleziona i prodotti da suggerire quando viene scelto un prodotto della categoria "{{selectedCategory}}"</h4>

        <!-- Navigation tabs per le categorie dei prodotti -->
        <div class="categories-tabs">
            <div *ngFor="let element of menu; let i = index"
                 class="tab"
                 [class.active]="productSezione === i"
                 (click)="productSezione = i">
                <div class="tab-content">
                    <img class="category-icon"
                         [src]="element.menu[0]['image_link']"
                         onerror="if (this.src != 'error.jpg') this.src = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fpizza_1f355.png?alt=media&token=5733f1fb-f87b-498f-adf3-f899a736071a';">
                    <span>{{element.testo}}</span>
                </div>
            </div>
        </div>

        <!-- Products grid -->
        <div class="products-grid" *ngIf="menu.hasOwnProperty(productSezione)">
            <div *ngFor="let piatto of menu[productSezione]['menu']" class="product-card">
                <div class="product-content"
                     [class.selected]="isUpsellingProduct(piatto)"
                     (click)="toggleUpselling(piatto)">
                    <img [src]="piatto.image_link" class="product-image">
                    <span class="product-title">{{piatto.title}}</span>
                    <div class="product-footer">
                        <div class="selection-indicator">
                            <img *ngIf="!isUpsellingProduct(piatto)"
                                 src="assets/icons/image-removebg-preview.png">
                            <img *ngIf="isUpsellingProduct(piatto)"
                                 src="assets/icons/image-removebg-preview-filled.png">
                        </div>
                        <span class="product-price">{{(+piatto.price).toFixed(2)}}€</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>