<!-- Sostituisci l'intero contenuto con questo -->
<div class="fixed inset-0 bg-black p-4">
    <div class="bg-white rounded-3xl w-full h-full flex flex-col overflow-hidden relative" style="background: #fbfbfb">
        <div class="flex flex-1 overflow-hidden">
            <!-- Colonna sinistra -->
            <div class="column">
                <div class="p-4">
                    <!-- Header -->
                    <div class="header-container">
                        <div class="header-left">
                            <button class="back-button" (click)="indietro()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <polyline points="15 18 9 12 15 6"></polyline>
                                </svg>
                            </button>
                            <h2 class="table-title">{{getTitoloOrdine()}}</h2>
                        </div>

                        <div class="header-right">
                            <div class="status-badges">
                                <tagComponent [fonte]="currentOrder?.fonte"></tagComponent>
                                <div class="status-badge"
                                     [class.status-paid]="currentOrder?.statoPagato !== undefined ? currentOrder?.statoPagato == 0 : false"
                                     [class.status-unpaid]="currentOrder?.statoPagato !== undefined ? currentOrder?.statoPagato != 0 : true">
                                    {{currentOrder?.statoPagato !== undefined ? currentOrder?.statoPagato == 0 ? 'Pagato' : 'Non Pagato' : 'Non Pagato'}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Selettore tipo ordine -->
                    <div class="order-type-selector" *ngIf="!isTable()">
                        <button
                                class="order-type-button"
                                [class.active]="orderType === 'counter'"
                                (click)="setOrderType('counter')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                                <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                                <line x1="6" y1="1" x2="6" y2="4"></line>
                                <line x1="10" y1="1" x2="10" y2="4"></line>
                                <line x1="14" y1="1" x2="14" y2="4"></line>
                            </svg>
                            Da Banco
                        </button>
                        <button
                                class="order-type-button"
                                [class.active]="orderType === 'takeaway'"
                                (click)="setOrderType('takeaway')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                            </svg>
                            Da Asporto
                        </button>
                    </div>

                    <!-- Selettore Portate -->
                    <div class="course-selector">
                        <div class="course-selector-title">
                            Portate:
                        </div>
                        <div class="course-tabs">
                            <button class="course-tab"
                                    [class.active]="selectedPortata === 4"
                                    (click)="setPortata(4)">
                                Tutte
                            </button>
                            <button class="course-tab"
                                    [class.active]="selectedPortata === 0"
                                    (click)="setPortata(0)">
                                Prima
                            </button>
                            <button class="course-tab"
                                    [class.active]="selectedPortata === 1"
                                    (click)="setPortata(1)">
                                Seconda
                            </button>
                            <button class="course-tab"
                                    [class.active]="selectedPortata === 2"
                                    (click)="setPortata(2)">
                                Terza
                            </button>
                            <button class="course-tab"
                                    [class.active]="selectedPortata === 3"
                                    (click)="setPortata(3)">
                                Quarta
                            </button>
                        </div>
                    </div>

                    <!-- Contenitore ordini -->
                    <div class="container">
                        <p class="order-details">Dettagli Ordinazione: <b>{{getElapsedTime(currentOrder?.data, currentOrder?.closureOrderTime)}}</b></p>

                        <div class="order-items-container">
                            <div class="ciaociao" *ngFor="let piatto of getPropertiesPortata(currentOrder?.carrello); let last = last; let first = first">
                                <menuDetailCard-bar
                                        [nome]="currentOrder?.carrello[piatto]['title']"
                                        [ingredienti]="currentOrder?.carrello[piatto]['category']"
                                        [prezzo]="getPrezzoPiatto(currentOrder?.carrello[piatto])"
                                        [quantita]="currentOrder?.carrello[piatto].quantita"
                                        [id]="currentOrder.ordine"
                                        [pagato]="currentOrder.statoPagato"
                                        [carrello]="true"
                                        [last]="last"
                                        [first]="first"
                                        [lenght]="getProperties(currentOrder?.carrello).length"
                                        [link]="currentOrder?.carrello[piatto].image_link"
                                        [aggiunte]="currentOrder?.carrello[piatto].aggiunte"
                                        [rimozioni]="currentOrder?.carrello[piatto].rimozioni"
                                        [nota]="currentOrder?.carrello[piatto].note"
                                        [variants]="currentOrder?.carrello[piatto].variants"
                                        [isPaidQuantity]="currentOrder?.carrello[piatto].isPaidQuantity"
                                        [isSelected]="currentOrder?.carrello[piatto].isSelected"
                                        [currentOrder]="currentOrder"
                                        [currentProductKey]="piatto"
                                        [piatto]="currentOrder?.carrello[piatto]"
                                        [user]="user"
                                        (quantitaOut)="handleEvent($event, currentOrder?.carrello[piatto], piatto)"
                                        (deleteItem)="handleDeleteItem(piatto)"
                                        (titleChanged)="handleTitleChanged(piatto, $event.title)"
                                        (productUpdated)="handleProductUpdated($event)"
                                        [hasRequiredVariants]="hasRequiredVariants(currentOrder?.carrello[piatto])"
                                        (click)="selectProductInCart(piatto, $event)"
                                        (openVariants)="apriVarianti(currentOrder?.carrello[piatto], piatto)"
                                        [tastierino]="selectedPrice || pendingQuantity"
                                        (checkLastProduct)="checkIfLastProduct(piatto, $event, variabile_eliminazione)">
                                </menuDetailCard-bar>
                            </div>
                        </div>
                    </div>

                    <!-- Note e totale -->
                    <div class="container2NotScrolled">
                        <div>
                            <p class="nota" *ngIf="currentOrder?.nota"><b>Nota:</b>{{currentOrder?.nota}}</p>
                            <p class="nota" *ngIf="currentOrder?.motivazione"><b>Motivo eliminazione:</b>{{currentOrder?.motivazione}}</p>

                            <div class="divider"></div>

                            <div class="total">Totale:  {{calcolaTotale().toFixed(2)}}€</div>
                        </div>

                        <!-- Sezione bottoni -->
                        <div class="actions">
                            <button class="action-button" *ngIf="!currentOrder?.statoPagato || (currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)"
                                    (click)="aggiungiNota()">
                                Aggiungi nota
                            </button>
                            <button class="action-button" *ngIf="!currentOrder?.statoPagato || (currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)"
                                    (click)="apriTastierino()">
                                {{this.keypadText}}
                            </button>


                            <button class="action-button" *ngIf="!currentOrder?.statoPagato || (currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)"
                                    (click)="avanzaPortata()"
                                    [disabled]="selectedPortata >= 3 || selectedPortata === 4">
                                Avanza Portata
                            </button>

                            <button class="action-button"
                                    *ngIf="!currentOrder?.statoPagato || (currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)"
                                    (click)="inviaPortataSelezionata()"
                                    >
                                Invia {{selectedPortata === 4 ? 'Tutte le portate' : (selectedPortata === 0 ? 'Prima portata' : selectedPortata === 1 ? 'Seconda portata' : selectedPortata === 2 ? 'Terza portata' : 'Quarta portata')}}
                            </button>


                            <!--button class="action-button" *ngIf="!currentOrder?.pager"
                                    (click)="assignPager()">
                                Assegna Pager
                            </button-->

                            <!--button class="action-button" *ngIf="currentOrder?.pager"
                                    (click)="callPager(+currentOrder.pager)">
                                Chiama pager: {{currentOrder.pager}}
                            </button-->

                            <!--button class="action-button" *ngIf="!currentOrder?.statoPagato || (currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)"
                                    (click)="openDialogFidelity()">
                                Fidelity
                            </button-->

                            <!--button class="action-button"
                                    (click)="openDialogFatturazione()">
                                Emetti fattura
                            </button-->

                            <button class="action-button delete-button" 
                                    *ngIf="(((currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)) && hasPermission('Elimina Ordine'))"
                                    (click)="eliminaOrdine(currentOrder?.ordine, variabile_eliminazione)">
                                Elimina
                            </button>

                            <button class="action-button payment-button" 
                                    *ngIf="((currentOrder.statoPagato !== 0 && currentOrder.statoPagato !== 3)) && calcolaTotale() > 0"
                                    (click)="openDialogPagamento()">
                                Pagamento
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Colonna destra -->
            <div class="column2">
                <div class="p-4">
                    <!-- Categorie -->

                    <div class="categories-wrapper">
                        <!-- Aggiungi la classe "expanded" quando è espanso -->
                        <div class="categories-container" [class.expanded]="isCategoriesExpanded" #categoriesContainer>

                            <div class="category-item search-category"
                                 [class.active]="sezione == -999"
                                 (click)="sezione = -999;">
                                <div class="category-content">
                                    <img class="category-icon"
                                         src="assets/icons/icons8-search-60.png"
                                         alt="Cerca">
                                    <div class="category-title">Cerca</div>
                                </div>
                            </div>

                            <!-- Favorite Sections -->
                            <ng-container *ngFor="let section of favorites; let i = index">
                                <div class="category-item"
                                     [class.active]="sezione == (-i - 1)"
                                     (click)="sezione = -i - 1; currentFavoriteSection = i">
                                    <div class="category-content">
                                        <img class="category-icon"
                                             src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2Ficons8-favorites-100.png?alt=media&token=a45fb4f4-ad8f-4ecc-b2a7-b45ca776d4bb"
                                             alt="{{section.name}}">
                                        <div class="category-title">{{section.name}}</div>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- Regular Categories -->
                            <ng-container *ngFor="let element of menu; let i = index">
                                <div class="category-item"
                                     [class.active]="sezione == i"
                                     (click)="sezione = i"
                                     *ngIf="hasCategoryItems(element.menu)">
                                    <div class="category-content">
                                        <img class="category-icon"
                                             [src]="imagePaths[element.menu[0]['title']] | async"
                                             [alt]="element.menu[0]['category']">
                                        <div class="category-title">{{element.menu[0]['category']}}</div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- Il pulsante è ora posizionato all'interno del container -->
                        <button class="expansion-toggle" (click)="toggleCategoriesExpansion()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <g *ngIf="isCategoriesExpanded">
                                    <polyline points="18 15 12 9 6 15"></polyline>
                                </g>
                                <g *ngIf="!isCategoriesExpanded">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </g>
                            </svg>
                        </button>
                    </div>

                    <!-- Menu Items Container -->
                    <div class="menu-items-container" *ngIf="sezione >= 0">
                        <div class="menu-items">
                            <div *ngFor="let piatto of getFilteredMenuItems(menu[sezione]?.menu)">
                                <div class="menu-item"
                                     [class.selected]="getQuantita(piatto) > 0"
                                     (click)="updateOrdine(piatto);">
                                    <div class="quantity-badge" *ngIf="getQuantita(piatto) > 0">
                                        {{getQuantita(piatto)}}
                                    </div>
                                    <div class="menu-image-container">
                                        <img [src]="imagePaths[piatto.title] | async"
                                             class="menu-item-image">
                                    </div>
                                    <div class="menu-item-details">
                                        <span class="menu-item-title">{{piatto.title}}</span>
                                        <div class="menu-item-price">
                                            <div class="price-tag">{{getPriceFromPriceList(piatto)}}€</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Favorites Container -->
                    <div class="menu-items-container" *ngIf="sezione < 0 && sezione != -999">
                        <div class="menu-items">
                            <div *ngFor="let piatto of getFavoriteItems()">
                                <div class="menu-item"
                                     [class.selected]="getQuantita(piatto) > 0"
                                     (click)="updateOrdine(piatto);">
                                    <div class="quantity-badge" *ngIf="getQuantita(piatto) > 0">
                                        {{getQuantita(piatto)}}
                                    </div>
                                    <div class="menu-image-container">
                                        <img [src]="imagePaths[piatto.title] | async"
                                             class="menu-item-image">
                                    </div>
                                    <div class="menu-item-details">
                                        <span class="menu-item-title">{{piatto.title}}</span>
                                        <div class="menu-item-price">
                                            <div class="price-tag">{{getPriceFromPriceList(piatto)}}€</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Empty State -->
                            <div *ngIf="getFavoriteItems().length === 0"
                                 style="text-align: center; padding: 20px; color: #666;">
                                Nessun preferito in questa sezione
                            </div>
                        </div>
                    </div>

                    <!-- Search Container -->
                    <div class="menu-items-container" *ngIf="sezione === -999">
                        <div class="search-container">
                            <input type="text"
                                   class="search-input"
                                   placeholder="Cerca prodotto..."
                                   [(ngModel)]="searchQuery"
                                   (ngModelChange)="onSearchChange()"
                                   autofocus>
                            <img src="assets/icons/icons8-search-60.png" class="search-icon" alt="Cerca">
                        </div>

                        <div class="menu-items">
                            <div *ngFor="let piatto of searchResults">
                                <div class="menu-item"
                                     [class.selected]="getQuantita(piatto) > 0"
                                     (click)="updateOrdine(piatto);">
                                    <div class="quantity-badge" *ngIf="getQuantita(piatto) > 0">
                                        {{getQuantita(piatto)}}
                                    </div>
                                    <div class="menu-image-container">
                                        <img [src]="imagePaths[piatto.title] | async"
                                             class="menu-item-image">
                                    </div>
                                    <div class="menu-item-details">
                                        <span class="menu-item-title">{{piatto.title}}</span>
                                        <div class="menu-item-price">
                                            <div class="price-tag">{{getPriceFromPriceList(piatto)}}€</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Empty Search Results -->
                            <div *ngIf="searchQuery && searchResults.length === 0" class="search-results-empty">
                                Nessun prodotto trovato per "{{searchQuery}}"
                            </div>

                            <div *ngIf="!searchQuery" class="search-results-empty">
                                Digita per cercare un prodotto
                            </div>
                        </div>
                    </div>

                    <!-- Tastiera numerica -->
                    <app-numeric-keypad #keypad
                                        *ngIf="keypadVisible"
                                        (valueEntered)="onKeypadValue($event)"
                                        (addGenericProduct)="addGenericProductWithVat($event)"
                                        (applyDiscountToOrder)="handleDiscount($event)"
                                        (resetPrintedQuantities)="resetAllPrintedQuantities()"
                                        [reset]="resetCounter"
                                        [orderTotal]="calcolaTotale()">
                    </app-numeric-keypad>
                </div>
            </div>
        </div>
    </div>
</div>