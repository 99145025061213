<div class="row">
    <div class="sticky-container">

        <div class="close-button">
            <img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-close-96.png?alt=media&token=4254b5ce-0add-4aae-8063-fa5f0464169a"
                 style="width: 30px; aspect-ratio: 1 / 1;"
                 title="Chiudi"
                 (click)="closeDialog()">
        </div>
    </div>
  <div class="column">

      <div style="display: flex">
          <div style="font-weight: bold; padding-left: 3px; font-size: 20px; margin: 2vh 0; width: calc(100% - 33%)">
              Riepilogo - Totale: {{ totalCartPrice | currency:'EUR' }}
          </div>
          <div style="width: 33%; align-items: end">
              <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"

                             *ngIf="!isViewMode"
                             [nome]="'Invia Ordine'"
                             altezza="55"
                             [bg]="'#3498db'"
                             [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-send-100.png?alt=media&token=57f05f45-61cc-49ce-ade2-60fb4abbc3ee'"
              (click)="saveOrder()"
              >
              </categorieCard>
          </div>
      </div>


    <div class="container menu" id="menuListaPiatti" style="margin-bottom: 2vh">
      <div style="pointer-events: auto; margin-top: 1vh">
        <div style="padding-top: 0%; padding-left: 0px">
          <!-- Contenitore per la tabella con altezza fissa e scorrimento verticale -->
          <div class='table-responsive' style="height: 70vh; overflow-y: auto;">

            <mat-table id="tabellaDipendenti" matSort matSortActive="nome" matSortDisableClear matSortDirection="desc" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

              <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
                <mat-header-cell *matHeaderCellDef >{{ column.field.toUpperCase()  }}
                  <mat-menu #menu>
                    <button mat-menu-item (click)="groupBy($event, column);">Group By This Field</button>
                    <button mat-menu-item (click)="unGroupBy($event, column);" >Ungroup</button>
                  </mat-menu>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <input *ngIf="column.field !== 'Categoria' && column.field !== 'Sync menu' && column.field !== 'Prezzo' && column.field !== 'Rimuovi'" style="margin-left: 10px; border: 0px !important; height: 30px; font-size: 14px; color: black; max-width: 150px " type="text" class="searchTerm"  [(ngModel)]="row[column.field]" >

                  <ng-container *ngIf="column.field === 'Prezzo'">
                    <span class="quantity" style="font-weight: bold">{{row.Prezzo}} €</span>

                  </ng-container>
                  <ng-container *ngIf="column.field === 'Rimuovi'">

                    <div style="width: 30px; margin-left: 10px">
                      <button class="remove-button" >
                        <img [src]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-delete-96.png?alt=media&token=a5eaf571-9519-474b-8d33-853cbe4a9e43'" alt="Azione"
                             style="max-width: 15px; max-height: 15px; filter: brightness(0) invert(1);;"
                             (click)="removeItem(row.id)">
                      </button>
                    </div>

                  </ng-container>
                </mat-cell>
              </ng-container>

              <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

              <!-- Group header -->
              <ng-container matColumnDef="groupHeader">
                <mat-cell colspan="999" *matCellDef="let group">
                  <strong>{{group[groupByColumns[group.level-1]]}}</strong>

                </mat-cell>
              </ng-container>

              <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>

            </mat-table>

          </div>
        </div>
      </div>
    </div>



</div>
</div>
