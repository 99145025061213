<div class="row" style="height: 95vh; width:calc(100vw - 570px); margin-top: 15px; border-radius: 20px; overflow-y: scroll">



    <!--div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Menu diretto</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


            <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

                <div class="flex flex-col xl:flex-row gap-5">
                    <div class="" style="">
                        <label class="container">Menu diretto (Magazzino direttamente aggiunto come prodotto)
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

            </div>

            <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
                Salva Modifiche
            </div>

        </div>

    </div-->


    <div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Menu Differenti per ristorante</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


            <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

                <div class="flex flex-col xl:flex-row gap-5">
                    <div class="" style="">
                        <label class="container">Menu differente per ristorante
                            <input type="checkbox" checked="checked" [(ngModel)]="differente">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

            </div>

            <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
                Salva Modifiche
            </div>

        </div>

    </div>


    <!--div style="">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Menu Differenti per orari</div>
        <div style="width: 90%; height: 230px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">


            <div class="flex flex-col justify-between gap-8" style="padding: 2.5rem">

                <div class="flex flex-col xl:flex-row gap-5">
                    <div class="" style="">
                        <label class="container">Menu differente per orario
                            <input type="checkbox" checked="checked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>

            </div>

            <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    margin-top: 0px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
                Salva Modifiche
            </div>

        </div>

    </div-->


    <div style="margin-bottom: 100px" *ngIf="differente == true">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Menu ristoranti</div>
        <div style="width: 100%; height: 420px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">

            <!--div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Collegamento'"
                                   altezza="50"
                                   [link]="'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/Icons%2FRight-tab%2Ficons8-add-90.png?alt=media&token=b8394f2d-6fa6-4c11-87fc-91f5e894f4b7'">
                    </categorieCard>
                </div>


            </div-->

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">

                <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="collegamenti" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">

                    <ng-container matColumnDef="menu" style="width: 50%">
                        <th mat-header-cell *matHeaderCellDef> Menu </th>
                        <td mat-cell *matCellDef="let user"> {{user.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="gruppo" style="width: 50%">
                        <th mat-header-cell *matHeaderCellDef> Gruppi </th>
                        <td mat-cell *matCellDef="let user">
                            <mat-select placeholder="Seleziona Gruppo" multiple class="custom-input" [(ngModel)]="user.gruppo" [compareWith]="compareObjects">
                                <mat-option *ngFor="let Categoria of gruppi" [value]="Categoria">
                                    {{Categoria.nome}}
                                </mat-option>
                            </mat-select>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ristorante" style="width: 50%">
                        <th mat-header-cell *matHeaderCellDef> Ristoranti </th>
                        <td mat-cell *matCellDef="let user">
                            <mat-select placeholder="Seleziona Ristoranti" multiple class="custom-input" [(ngModel)]="user.ristoranti" [compareWith]="compareObjects">
                                <mat-option *ngFor="let Categoria of ristoranti" [value]="Categoria">
                                    {{Categoria.nome}}
                                </mat-option>
                            </mat-select>

                        </td>
                    </ng-container>



                    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>


                </table>

            </div>

            <div style="
    font-size: 14px;
    text-align: center;
    border-radius: 7px;
    margin: 20px;
    font-weight: 700;
    float: right;
    padding: 15px;
    color: white;
    background-color: rgb(0 125 104);">
                Salva Modifiche
            </div>

        </div>

    </div>




</div>


