// tavoli.ts - Versione aggiornata
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { TavoliService } from '../../../shared/services/tavoli.service';
import { DataServiceGenerico } from "../../../shared/services/data-service/data.service";
import { TavoloDialogComponent } from "./edit-tavolo/tavolo-dialog.component";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Dialog Component per la creazione o modifica di una sala
@Component({
  selector: 'sala-dialog',
  template: `
    <h2 style="font-weight: 500; margin-bottom: 16px;">{{data.isEdit ? 'Modifica Sala' : 'Nuova Sala'}}</h2>
    <div style="margin-bottom: 20px;">
      <label style="display: block; margin-bottom: 8px; font-weight: 400;">Nome Sala</label>
      <input
          style="width: 100%; padding: 8px; border: 1px solid #ccc; border-radius: 4px;"
          [(ngModel)]="data.nomeSala">
    </div>
    <div style="display: flex; justify-content: flex-end; gap: 8px;">
      <button
          style="padding: 8px 16px; background: #f5f5f5; border: none; border-radius: 4px; cursor: pointer;"
          (click)="onNoClick()">Annulla</button>
      <button
          style="padding: 8px 16px; background: #34bfa3; color: white; border: none; border-radius: 4px; cursor: pointer;"
          [disabled]="!data.nomeSala"
          (click)="onConfirm()">Conferma</button>
    </div>
  `
})
export class SalaDialogComponent {
  constructor(
      public dialogRef: MatDialogRef<SalaDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: { nomeSala: string, id?: string, isEdit?: boolean }
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    if (this.data.nomeSala && this.data.nomeSala.trim() !== '') {
      this.dialogRef.close(this.data);
    }
  }
}

@Component({
  selector: 'tavoli',
  templateUrl: './tavoli.html',
  styleUrls: ['./tavoli.css']
})

export class Tavoli implements OnInit {
  tavoli: Array<any> = [];
  sale: Array<any> = [];
  salaSelezionata: string = '';
  unisciTavoli = false;
  isDragging = false;
  private dragStartTime: number = 0;

  constructor(
      public dialog: MatDialog,
      private tavoliService: TavoliService,
      private dataService: DataServiceGenerico
  ) {}

  ngOnInit(): void {
    if(this.dataService.getRistorante1() == '') {
      this.dataService.loadSelectedMenuFromFirebase().then((ristorante) => {
        this.caricaSale();
      })
    } else {
      this.caricaSale();
    }
  }

  caricaSale(): void {
    this.tavoliService.getSale(this.dataService.getRistorante1()).subscribe((sale) => {
      this.sale = sale || [];

      // Se non ci sono sale, creane una predefinita
      if (this.sale.length === 0) {
        const salaPredefinita = {
          id: this.generateUniqueId(),
          nome: 'Sala principale'
        };
        this.sale.push(salaPredefinita);
        this.tavoliService.aggiornaSale(this.sale, this.dataService.getRistorante1());
        this.salaSelezionata = salaPredefinita.id;
      } else if (!this.salaSelezionata || !this.sale.some(s => s.id === this.salaSelezionata)) {
        // Se non c'è una sala selezionata o la sala selezionata non esiste più, seleziona la prima
        this.salaSelezionata = this.sale[0].id;
      }
      // Altrimenti, mantiene la sala selezionata corrente

      this.caricaTavoli();
    });
  }

  caricaTavoli(): void {
    this.tavoliService.getTavoli(this.dataService.getRistorante1(), this.salaSelezionata).subscribe((tavoli) => {
      this.tavoli = tavoli || [];
    });
  }

  cambiareSala(idSala: string): void {
    this.salaSelezionata = idSala;
    this.caricaTavoli();
    this.unisciTavoli = false; // Reset modalità unione tavoli quando cambia sala
  }

  aprirDialogNuovaSala(): void {
    const dialogRef = this.dialog.open(SalaDialogComponent, {
      width: '300px',
      data: { nomeSala: '', isEdit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result && result.nomeSala) {
        const nuovaSala = {
          id: this.generateUniqueId(),
          nome: result.nomeSala,
          tavoli: []
        };

        this.sale.push(nuovaSala);
        this.tavoliService.aggiornaSale(this.sale, this.dataService.getRistorante1()).then(() => {
          this.salaSelezionata = nuovaSala.id;
          this.caricaTavoli();
        }).catch(error => {
          console.error('Errore durante il salvataggio della sala:', error);
        });
      }
    });
  }

  modificaSala(event: MouseEvent, sala: any): void {
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.dialog.open(SalaDialogComponent, {
      width: '300px',
      data: {
        nomeSala: sala.nome,
        id: sala.id,
        isEdit: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.nomeSala) {
        // Trova e aggiorna la sala nell'array
        const index = this.sale.findIndex(s => s.id === sala.id);
        if (index !== -1) {
          // Salva l'id della sala corrente per mantenerla selezionata dopo l'aggiornamento
          const currentSalaId = this.salaSelezionata;

          this.sale[index].nome = result.nomeSala;

          this.tavoliService.aggiornaSale(this.sale, this.dataService.getRistorante1()).then(() => {
            console.log('Sala rinominata con successo');
            // Mantieni la sala corrente selezionata
            this.salaSelezionata = currentSalaId;
          }).catch(error => {
            console.error('Errore durante il salvataggio della sala:', error);
          });
        }
      }
    });
  }

  // Genera un ID unico per le sale
  generateUniqueId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  aggiungiTavolo(): void {
    const dialogRef = this.dialog.open(TavoloDialogComponent, {
      width: '250px',
      data: { numero: null, coperti: null, isEdit: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.delete) {
        // Salva l'id della sala corrente
        const currentSalaId = this.salaSelezionata;

        // Aggiungi il nuovo tavolo all'array locale
        this.tavoli.push({
          posizione: { x: 100, y: 100 },  // Posizione di default
          numero: result.numero,
          coperti: result.coperti,
          occupato: 0,
          salaId: currentSalaId
        });

        // Aggiorna Firebase con l'intero array
        this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1(), currentSalaId).then(() => {
          console.log("Tavoli aggiornati su Firebase");
          // Mantieni la sala corrente selezionata
          this.salaSelezionata = currentSalaId;
        });
      }
    });
  }

  uniscitavoli() {
    this.unisciTavoli = !this.unisciTavoli;
    if (!this.unisciTavoli) { // Se stiamo confermando l'unione
      // Salva l'id della sala corrente
      const currentSalaId = this.salaSelezionata;

      let sum = 0;
      let indice = 0;
      let indici: number[] = [];

      this.tavoli.forEach((tavolo, i) => {
        if (tavolo.unito === true) {
          if (sum === 0) {
            indice = i;
            sum = sum + tavolo.coperti;
            tavolo.unito = false;
          } else {
            sum = sum + tavolo.coperti;
            indici.push(i);
          }
        }
      });

      if (sum > 0) {
        this.tavoli[indice].coperti = sum;
      }

      // Rimuovi i tavoli uniti
      for (let i = indici.length - 1; i >= 0; i--) {
        this.tavoli.splice(indici[i], 1);
      }

      // Aggiorna Firebase
      this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1(), currentSalaId).then(() => {
        console.log("Tavoli uniti aggiornati su Firebase");
        // Mantieni la sala corrente selezionata
        this.salaSelezionata = currentSalaId;
      });
    }
  }

  onDragStarted(event: CdkDragStart): void {
    this.isDragging = true;
    this.dragStartTime = Date.now();
  }

  dropped(event: CdkDragEnd, tavolo: any): void {
    const dragEndTime = Date.now();
    const dragDuration = dragEndTime - this.dragStartTime;

    // Update position only if actually dragged
    if (dragDuration > 100) {
      const newPosition = event.source.getFreeDragPosition();
      tavolo.posizione = newPosition;

      // Mantieni il riferimento alla sala corrente
      const currentSalaId = this.salaSelezionata;

      this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1(), currentSalaId).then(() => {
        console.log("Posizione aggiornata su Firebase");
        // Assicurati che la sala corrente rimanga selezionata
        this.salaSelezionata = currentSalaId;
      });
    }

    // Reset dragging state after a short delay
    setTimeout(() => {
      this.isDragging = false;
    }, 100);
  }

  handleTableClick(tavolo: any): void {
    if (!this.isDragging) {
      if (this.unisciTavoli) {
        tavolo.unito = !tavolo.unito;
      } else {
        this.modificaTavolo(tavolo);
      }
    }
  }

  modificaTavolo(tavolo: any): void {
    const dialogRef = this.dialog.open(TavoloDialogComponent, {
      width: '250px',
      data: {
        numero: tavolo.numero,
        coperti: tavolo.coperti,
        isEdit: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Salva l'id della sala corrente
        const currentSalaId = this.salaSelezionata;

        if (result.delete) {
          // Elimina il tavolo dall'array
          this.tavoli = this.tavoli.filter(t => t !== tavolo);
        } else {
          // Aggiorna i dati del tavolo
          tavolo.numero = result.numero;
          tavolo.coperti = result.coperti;
        }

        // Aggiorna Firebase con l'intero array
        this.tavoliService.aggiornaTavoli(this.tavoli, this.dataService.getRistorante1(), currentSalaId).then(() => {
          console.log("Tavoli aggiornati su Firebase");
          // Mantieni la sala corrente selezionata
          this.salaSelezionata = currentSalaId;
        });
      }
    });
  }
}