import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector: 'app-cover-dialog',
    template: `
        <div class="cover-dialog">
            <div class="cover-header">
                <h2>Numero di coperti</h2>
                <div class="table-info">
                    <div class="table-icon">
                        <img src="assets/icons/icons8-account-96.png" alt="Coperti" height="30">
                    </div>
                    <div class="table-name">{{tableName}}</div>
                </div>
            </div>

            <div class="cover-display">
                <div class="cover-number">{{coverCount}}</div>
            </div>

            <div class="cover-keypad">
                <!-- Prima riga cifre 1-3 -->
                <div class="keypad-row">
                    <button class="keypad-button" *ngFor="let key of [1, 2, 3]" (click)="addDigit(key)">
                        {{key}}
                    </button>
                </div>

                <!-- Seconda riga cifre 4-6 -->
                <div class="keypad-row">
                    <button class="keypad-button" *ngFor="let key of [4, 5, 6]" (click)="addDigit(key)">
                        {{key}}
                    </button>
                </div>

                <!-- Terza riga cifre 7-9 -->
                <div class="keypad-row">
                    <button class="keypad-button" *ngFor="let key of [7, 8, 9]" (click)="addDigit(key)">
                        {{key}}
                    </button>
                </div>

                <!-- Quarta riga con Cancella, 0, Backspace -->
                <div class="keypad-row">
                    <button class="keypad-button cancel-button" (click)="cancel()">
                        Annulla
                    </button>
                    <button class="keypad-button" (click)="addDigit(0)">0</button>
                    <button class="keypad-button backspace-button" (click)="removeDigit()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <path d="M20 5H9l-7 7 7 7h11a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2z"/>
                            <line x1="18" y1="9" x2="12" y2="15"/>
                            <line x1="12" y1="9" x2="18" y2="15"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div class="action-buttons">
                <button class="delete-button" (click)="deleteOrder()">
                    Elimina ordine
                </button>
                <button class="confirm-button" [disabled]="coverCount === 0" (click)="confirm()">
                    Conferma
                </button>
            </div>
        </div>
    `,
    styles: [`
        .cover-dialog {
            background-color: #fff;
            border-radius: 24px;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .cover-header {
            text-align: center;
            margin-bottom: 1.5rem;
            width: 100%;
        }

        .cover-header h2 {
            font-size: 1.8rem;
            font-weight: 600;
            margin: 0 0 1rem 0;
            color: #333;
        }

        .table-info {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .table-icon {
            margin-right: 0.75rem;
            display: flex;
            align-items: center;
        }

        .table-name {
            font-size: 1.1rem;
            font-weight: 500;
            color: #353555;
        }

        .cover-display {
            margin-bottom: 2rem;
            text-align: center;
        }

        .cover-number {
            font-size: 3rem;
            font-weight: 700;
            color: #353555;
            margin-bottom: 0.5rem;
            min-height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .cover-keypad {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
        }

        .keypad-row {
            display: flex;
            justify-content: space-between;
            gap: 12px;
        }

        .keypad-button {
            flex: 1;
            height: 65px;
            border-radius: 16px;
            border: none;
            background-color: #f5f5f7;
            font-size: 1.5rem;
            font-weight: 600;
            color: #333;
            cursor: pointer;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .keypad-button:hover:not(:disabled) {
            background-color: #e5e5ea;
        }

        .keypad-button:active:not(:disabled) {
            transform: scale(0.95);
            background-color: #d1d1d6;
        }

        .cancel-button {
            font-size: 1rem;
            color: #e53935;
        }

        .backspace-button {
            color: #333;
        }

        .action-buttons {
            margin-top: 1.5rem;
            width: 100%;
            display: flex;
            gap: 12px;
        }

        .confirm-button {
            flex: 2;
            height: 50px;
            border-radius: 16px;
            border: none;
            background-color: #353555;
            color: white;
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease;
        }

        .confirm-button:hover:not(:disabled) {
            background-color: #2a2a44;
        }

        .confirm-button:disabled {
            background-color: #d1d1d6;
            cursor: not-allowed;
        }

        .delete-button {
            flex: 1;
            height: 50px;
            border-radius: 16px;
            border: none;
            background-color: #FFEDED;
            color: #C54D4D;
            font-size: 1.1rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease;
        }

        .delete-button:hover {
            background-color: #FFE5E5;
        }
    `]
})
export class CoverDialogComponent implements OnInit {
    coverCount: number = 0;
    tableName: string = '';
    orderId: string = '';

    constructor(
        public dialogRef: MatDialogRef<CoverDialogComponent>,
        private router: Router
    ) {}

    ngOnInit(): void {
        // La tabella viene passata dai dati del dialog
        if (this.dialogRef.componentInstance['data']) {
            if (this.dialogRef.componentInstance['data'].tableName) {
                this.tableName = this.dialogRef.componentInstance['data'].tableName;
            }
            if (this.dialogRef.componentInstance['data'].orderId) {
                this.orderId = this.dialogRef.componentInstance['data'].orderId;
            }
        }
    }

    addDigit(digit: number): void {
        // Limita a due cifre (max 99 coperti)
        if (this.coverCount < 10) {
            this.coverCount = this.coverCount * 10 + digit;
        } else if (this.coverCount < 99) {
            // Se è già a due cifre, sostituisci la seconda cifra
            this.coverCount = Math.floor(this.coverCount / 10) * 10 + digit;
        }
    }

    removeDigit(): void {
        this.coverCount = Math.floor(this.coverCount / 10);
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    confirm(): void {
        if (this.coverCount > 0) {
            this.dialogRef.close(this.coverCount);
        }
    }

    deleteOrder(): void {
        // Chiudi il dialog con 'deleted' per indicare che l'ordine va eliminato
        this.dialogRef.close('deleted');
    }
}