<div class="row" style="height: 95vh; width:calc(100vw - 570px); margin-top: 15px; border-radius: 20px; overflow: scroll">
    <!--div>
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Ruoli</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Ruolo'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'"
                                   (click)="openAddRoleDialog()">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaDipendenti2" matSortActive="nome" [dataSource]="ruoli" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome" style="width: 50%">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let user"> {{user.nome}} </td>
                    </ng-container>

                    <ng-container matColumnDef="permessi" style="width: 50%">
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let user">
                            <mat-select placeholder="Seleziona Categoria" multiple class="custom-input" [(ngModel)]="user.permessi">
                                <mat-option *ngFor="let Categoria of permessi" [value]="Categoria">
                                    {{Categoria}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </table>
            </div>

            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="savePersonnelData()">
                Salva Modifiche
            </div>
        </div>
    </div-->
    <div style="margin-bottom: 100px">
        <div style="height: 50px; line-height: 50px; font-weight: 700;">Ruoli</div>
        <div style="width: 100%; height: 490px; background-color: white; box-shadow: 0 0 #0000, 0 0 #0000, 0px 16px 48px rgba(191, 194, 205, .2); border-radius: 20px">
            <div style="width: 100%; display: flex; margin-top: 20px; margin-left: 0px">
                <div style="width: 27%; margin-right: 1%; margin-left: 1%; margin-top: 10px">
                    <categorieCard class="col-12 col-md-12 menuItem" style="padding-left: 0px; margin-left: 0px; padding-right: 0px;"
                                   [nome]="'Aggiungi Ruolo'"
                                   altezza="50"
                                   [link]="'assets/icons/icons8-add-90.png'"
                                   (click)="openAddEmployeeDialog()">
                    </categorieCard>
                </div>
            </div>

            <div class='table-responsive' style="margin-top: 20px; margin-left: 1%; width: 98%; overflow-y: scroll; border-radius: 20px; border: 1px solid #e3e2e2;">
                <table mat-table id="tabellaDipendenti" matSortActive="nome" [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%; overflow-y: scroll; display: table !important; border-radius: 20px; height: 300px !important">
                    <ng-container matColumnDef="nome" style="width: 20%">
                        <th mat-header-cell *matHeaderCellDef> Nome </th>
                        <td mat-cell *matCellDef="let row"> {{row.nome}} </td>
                    </ng-container>

                    <!--ng-container matColumnDef="cognome" style="width: 20%">
                        <th mat-header-cell *matHeaderCellDef> Cognome </th>
                        <td mat-cell *matCellDef="let row"> {{row.cognome}} </td>
                    </ng-container-->

                    <!--ng-container matColumnDef="mail" style="width: 20%">
                        <th mat-header-cell *matHeaderCellDef> Mail </th>
                        <td mat-cell *matCellDef="let row"> {{row.mail}} </td>
                    </ng-container-->

                    <!--ng-container matColumnDef="ruolo" style="width: 15%">
                        <th mat-header-cell *matHeaderCellDef> Ruolo </th>
                        <td mat-cell *matCellDef="let row"> {{row.ruolo}} </td>
                    </ng-container-->

                    <ng-container matColumnDef="permessi" style="width: 15%">
                        <th mat-header-cell *matHeaderCellDef> Permessi </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-select placeholder="Seleziona un permesso" multiple class="custom-input" [(ngModel)]="row.permessi" (ngModelChange)="updatePermissions(row)">
                                <mat-option *ngFor="let Categoria of permessi" [value]="Categoria">
                                    {{Categoria}}
                                </mat-option>
                            </mat-select>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="edit" style="width: 10%">
                        <th mat-header-cell *matHeaderCellDef> Edit </th>
                        <td mat-cell *matCellDef="let sconto">
                            <div style="width: 30px; margin-left: 10px">
                                <button class="action-button" (click)="editEmployee(sconto)">
                                    <img [src]="'assets/icons/icons8-edit-90.png'" alt="Azione" style="max-width: 15px; max-height: 15px;">
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns2;"></mat-row>
                </table>
            </div>

            <div style="font-size: 14px; text-align: center; border-radius: 7px; margin: 20px; font-weight: 700; float: right; padding: 15px; color: white; background-color: rgb(0 125 104);" (click)="savePersonnelData()">
                Salva Modifiche
            </div>
        </div>
    </div>

</div>