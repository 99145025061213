<div class="dialog-container">
    <!-- Selettore in alto per scegliere tra Varianti e Opzioni -->
    <div class="variant-selector">
        <div class="selector-option" [class.active]="currentView === 'varianti'" (click)="setCurrentView('varianti')">Varianti (Obbligatorie)</div>
        <div class="selector-option" [class.active]="currentView === 'opzioni'" (click)="setCurrentView('opzioni')">Opzioni (Non Obbligatorie)</div>
    </div>

    <!-- Pulsanti di azione in alto a destra -->
    <div class="action-buttons">
        <button class="add-variant-button" (click)="addVariant()">
            <i class="material-icons">add</i> {{ currentView === 'varianti' ? 'Aggiungi Variante' : 'Aggiungi Opzione' }}
        </button>
        <button class="save-button" (click)="saveVariants()">
            <i class="material-icons">save</i> Salva
        </button>
    </div>

    <!-- Contenitore principale con le varianti -->
    <div class="variants-container">
        <!-- Lista delle varianti visibili in base alla selezione -->
        <div *ngFor="let variant of getCurrentViewVariants(); let i = index" class="variant-card">
            <div class="variant-header">
                <input [placeholder]="currentView === 'varianti' ? 'Nome variante' : 'Nome opzione'"
                       class="variant-name-input"
                       [(ngModel)]="variant.name">

                <div class="max-selections-container">
                    <span class="max-label">Max selezionabili:</span>
                    <input type="number"
                           class="max-selections-input"
                           [(ngModel)]="variant.maxSelections"
                           min="1">
                </div>

                <button class="remove-button" (click)="removeVariant(variant.id)">
                    <i class="material-icons">close</i>
                </button>
            </div>

            <!-- Lista delle opzioni per questa variante -->
            <div class="options-list">
                <div *ngFor="let option of variant.options; let j = index" class="option-row">
                    <div class="option-image">
                        <img *ngIf="option.imageUrl" [src]="option.imageUrl" alt="Option image" class="option-img">
                        <div *ngIf="!option.imageUrl" class="placeholder-img">
                            <i class="material-icons">image</i>
                        </div>
                        <div class="image-overlay">
                            <button mat-icon-button (click)="fileInput.click()" class="img-button">
                                <i class="material-icons">{{ option.imageUrl ? 'edit' : 'add' }}</i>
                            </button>
                            <button mat-icon-button *ngIf="option.imageUrl" (click)="removeImage(variant, option)" class="img-button">
                                <i class="material-icons">delete</i>
                            </button>
                        </div>
                        <input #fileInput type="file" (change)="onFileSelected($event, variant, option)" style="display: none" accept="image/*">
                    </div>

                    <input placeholder="Nome opzione" class="option-name-input" [(ngModel)]="variant.options[j].name">

                    <div class="price-input-container">
                        <span class="euro-symbol">€</span>
                        <input type="number"
                               placeholder="0"
                               class="price-input"
                               [(ngModel)]="variant.options[j].priceModifier"
                               step="0.01">
                    </div>

                    <button class="remove-option-button" (click)="removeOption(variant, option.id)">
                        <i class="material-icons">close</i>
                    </button>
                </div>

                <!-- Pulsante per aggiungere nuove opzioni alla variante -->
                <button class="add-option-button" (click)="addOptionToVariant(variant)">
                    <i class="material-icons">add</i> Aggiungi Opzione
                </button>
            </div>
        </div>
    </div>
</div>