import { Component, Input } from '@angular/core';

@Component({
  selector: 'ordini-card-magazzino',
  template: `
    <div class="ordine-card" [class.selected]="selected">
      <div class="ordine-header">
        <div class="ordine-info">
          <div class="ordine-title">Ordine: {{ numero || 'N/A' }}</div>
          <div class="ordine-total">Totale: {{ totale }}€</div>
        </div>
      </div>
      <div class="ordine-details">
        <div class="ordine-date">{{ formatDate(data) }}</div>
        <div class="ordine-status" [ngClass]="{
          'status-waiting': stato === 'in_attesa' || !stato,
          'status-confirmed': stato === 'confermato',
          'status-partial': stato === 'confermato_parziale',
          'status-processing': stato === 'in_preparazione'
        }">
          {{ getStatusText(stato) }}
        </div>
      </div>
    </div>
  `,
  styles: [`
    .ordine-card {
      background-color: white;
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 12px;
      box-shadow: 0 2px 6px rgba(0,0,0,0.1);
      cursor: pointer;
      transition: all 0.2s ease;
      border-left: 3px solid transparent;
    }

    .ordine-card:hover {
      transform: translateX(2px);
      box-shadow: 0 4px 8px rgba(0,0,0,0.15);
    }

    .ordine-card.selected {
      border-left-color: #E55933;
      background-color: rgba(229, 89, 51, 0.05);
    }

    .ordine-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .ordine-title {
      font-weight: 600;
      font-size: 16px;
    }

    .ordine-total {
      font-weight: 600;
      color: #E55933;
    }

    .ordine-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ordine-date {
      color: #666;
      font-size: 14px;
    }

    .ordine-status {
      padding: 6px 12px;
      border-radius: 16px;
      font-size: 13px;
      font-weight: 500;
    }

    .status-waiting {
      background-color: #FFE5E5;
      color: #E53935;
    }

    .status-confirmed {
      background-color: #E8F5E9;
      color: #43A047;
    }

    .status-partial {
      background-color: #FFF3E0;
      color: #FB8C00;
    }

    .status-processing {
      background-color: #E8EAF6;
      color: #3F51B5;
    }
  `]
})
export class OrdiniCardMagazzinoComponent {
  @Input() nome: string;
  @Input() numero: string;
  @Input() totale: string;
  @Input() fonte: any;
  @Input() data: any;
  @Input() stato: string;
  @Input() bloccato: boolean;
  @Input() selected: boolean;

  formatDate(dateValue: any): string {
    if (!dateValue) return '';

    try {
      // Se è un timestamp Firestore
      if (dateValue && typeof dateValue.toDate === 'function') {
        const date = dateValue.toDate();
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }

      // Se è un timestamp numerico
      if (typeof dateValue === 'number' || typeof dateValue === 'string') {
        const date = new Date(dateValue);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      }

      return '';
    } catch (error) {
      console.error('Errore nella formattazione della data:', error);
      return '';
    }
  }

  getStatusText(stato: string): string {
    if (!stato || stato === 'in_attesa') {
      return 'In Attesa';
    } else if (stato === 'confermato') {
      return 'Confermato';
    } else if (stato === 'confermato_parziale') {
      return 'Parziale';
    } else if (stato === 'in_preparazione') {
      return 'In preparazione';
    }
    return stato;
  }
}